const pricing = {
    attendance: {
        name: 'Presença',
        price: '40',
        subLine: 'Melhor para Monitorar Horas de Trabalho',
        features: [
            'Registro Biométrico',
            'Registro com Base em Geo-localização',
            'Opção de Meio Período',
            'Logout Automático',
            'Contador de Atraso',
            'Tempo Médio de Registro',
            'Total de Horas Trabalhadas',
            'Assinatura Automática para 1 Relatório',
            'Presença, Média, Registro/ Desregistro',
            'Relatórios',
            'Incluso Todos os Recursos Básicos',
            'Biométrico',
            'Reconhecimento Facial por IA',
            'Quiosque',
            'Opção de Múltiplas Filiais',
            'Formulário para Registro e Desregistro',
            'Incluso Todos os Recursos Básicos e Premium',
        ],
    },
    task: {
        name: 'Tarefa',
        price: '100',
        subLine: 'Melhor para gerenciar tarefas',
        features: [
            'Tarefa de Campo',
            'Tarefa de Serviço',
            'Gerenciamento de Tarefas com Base em Geofence',
            'Registro de Entrada e Saída',
            'Tempo para Saída',
            'Notificação Prévia',
        ],
    },
    tracking: {
        name: 'Rastreamento',
        price: '300',
        subLine: 'Melhor para rastreamento em tempo real',
        features: [
            'Tarefa de Campo',
            'Tarefa de Serviço',
            'Gerenciamento de Tarefas com Base em Geofence',
            'Registro de Entrada e Saída',
            'Tempo para Saída',
            'Rastreamento Ao Vivo',
        ],
    },
    customer: {
        name: 'Cliente',
        price: '80',
        subLine: 'Gerenciar relacionamentos com clientes',
        features: [
            'Comunicar-se via Whatsapp, Email e Mensagem',
            'Atribuir Tags',
            'Lembretes Baseados em Intervalo',
            'Visitas Baseadas em Geofence',
            'Histórico de Visitas',
            'Compartilhar Cartão de Visita',
            'Anúncios',
            'Incluso Todos os Recursos Básicos',
            'Rota de Visitas Baseada em IA',
            'Scanner de Cartão de Visita',
            'Modelos Pré-feitos para Comunicação',
            'Gerenciar Lembretes',
        ],
    },
    reimbursement: {
        name: 'Reembolso',
        price: '30',
        subLine: 'Automatize pagamentos precisos e pontuais',
        features: [
            'Reembolso com Base na Distância do Veículo',
            'Análises',
            'Com Base na Filial',
            'Com Base no Mês',
            'Categoria',
        ],
    },
    leave: {
        name: 'Licença',
        price: '30',
        subLine: 'Gestores aprovam facilmente',
        features: [
            'Licenças Acumuladas',
            'Gerenciar Feriados',
            'Verificar Licenças Acumuladas',
            'Meio Período e Trabalho Remoto',
            'Aprovar Licença',
            'Personalizar Política para Cada Indivíduo',
        ],
    },
    payroll: {
        name: 'Folha de Pagamento',
        price: '40',
        subLine: 'Cálculos de salário precisos',
        features: [
            'Política de Folha de Pagamento',
            'Cálculo de Salário Adiantado',
            'Resumo de Pagamento',
            'Cálculo com Um Clique',
            'Cálculo de Salário Horário e Mensal',
            'Cálculo de Horas Extras',
        ],
    },
    faceID: {
        name: 'Face ID',
        price: '25',
        subLine: 'Melhor para segurança aprimorada',
        features: ['Adicionar Face ID na Presença como um Adicional'],
    },
    forms: {
        name: 'Formulários',
        price: '30',
        subLine: 'Criar e gerenciar formulários digitais',
        features: [
            'Formulários Dinâmicos',
            'Criação de Formulários Personalizados',
            'Análises Baseadas em Formulários',
            'Formulários para Eventos',
            'Pagamento através de Formulários',
            'Criar Formulários Personalizados',
        ],
    },
};
export default pricing;
