const homePage = {
    heroHeader: 'Excellence en ventes et efficacité opérationnelle grâce à',
    heroHeaderTypist: ['Gestion des Présences', 'Suivi de Terrain', 'CRM'],
    heroHeaderSubText:
        'Présence basée sur la localisation en temps réel, Suivi en direct, Gestion des tâches et des commandes, Insights et services API',
    moreProductivityLessExpenses: 'Plus de Productivité, Moins de Dépenses',
    keyFeatures: [
        {
            title: 'Lens',
            description:
                'Pointage de présence sans contact avec assistance AI. Marquez votre présence en utilisant la caméra de votre mobile.',
        },
        {
            title: 'Web App',
            description:
                'Tableau de bord convivial, les équipes RH et les gestionnaires peuvent facilement accéder aux rapports détaillés, gérer les dossiers des employés, suivre les performances et vérifier la paie.',
        },
        {
            title: 'Contrôle basé sur la hiérarchie',
            description:
                'Système de contrôle basé sur la hiérarchie pour accommoder divers niveaux organisationnels, y compris les administrateurs, les responsables RH, les superviseurs et les employés.',
        },
        {
            title: 'Optimisation des itinéraires',
            description:
                'Améliorez l’efficacité et réduisez les coûts opérationnels avec des calculs d’itinéraires dynamiques, une optimisation des arrêts multiples, une estimation des coûts et des mises à jour en temps réel.',
        },
    ],
    features: 'Fonctionnalités',
    ourFeatures:
        'Nos fonctionnalités sont simples, puissantes et s’adaptent facilement à tout secteur pour accroître la productivité de l’organisation.',
    ourFeaturesObject: [
        {
            title: 'Suivi des Présences',
            description: 'Pointage avec localisation et suivi des heures de travail',
        },
        {
            title: 'Gestion des Dépenses',
            description: 'Les dépenses sont suivies et enregistrées de manière précise',
        },
        {
            title: "Suivi de la Main-d'Œuvre",
            description: 'Surveillance et enregistrement des activités des employés, productivité',
        },
        {
            title: 'Gestion des Paies',
            description: 'Optimisez le traitement des paies et les paiements précis',
        },
        {
            title: 'Gestion des Commandes',
            description:
                'Processus de gestion des commandes/collections à travers plusieurs stratégies personnalisables',
        },
        {
            title: 'Optimisation des Itinéraires',
            description: 'Trouver le chemin le plus efficace pour économiser du temps, du carburant et des ressources.',
        },
    ],
    whatWeDoQuestion: 'Que fait TraceForce ?',
    whatWeDoCards: [
        {
            title: 'Analyse des Activités des Employés en Temps Réel',
            description: 'Obtenez une analyse détaillée des activités de l’équipe sur une base quotidienne ou horaire.',
        },
        {
            title: 'Présence basée sur la Géolocalisation et Rapports de Check-in',
            description:
                'Renforcez l’efficacité de l’équipe avec une gestion des présences et des congés standardisée.',
        },
        {
            title: 'Gestion Efficace des Commandes/Collections',
            description:
                'Minimisez les erreurs tout en boostant la productivité et la satisfaction client avec des stratégies de commande intelligentes et des collections ponctuelles.',
        },
        {
            title: 'Gestion des Tâches de A à Z',
            description:
                'Rationalisez la planification des projets, assignez des tâches sans effort et favorisez la collaboration en temps réel entre les membres de l’équipe.',
        },
        {
            title: 'Insights Basés sur des Rapports Automatisés',
            description: 'Visualisez l’engagement des employés et réalisez une analyse parfaite de l’équipe',
        },
        {
            title: 'Gestion des Dépenses Basée sur les Allocations',
            description:
                'Gérez les systèmes RH pour distribuer les bulletins de paie en ligne selon les enregistrements des feuilles de présence.',
        },
    ],
    whatWeDoList: [
        'Journaux de Présence',
        'Traceur de Localisation Géographique',
        'Stockage en Cloud',
        'Alertes et Notifications',
        'Gestion de Projet',
        'Gestion du Funnel de Ventes',
    ],
    featureTitle: 'Fonctionnalités',
    featureSubTitle:
        'Nos fonctionnalités sont simples, puissantes et s’adaptent facilement à tout secteur pour accroître la productivité de l’organisation.',
    featureCards: [
        {
            title: 'Suivi des Présences',
            description: 'Pointage avec localisation et suivi des heures de travail',
        },
        {
            title: 'Gestion des Dépenses',
            description: 'Les dépenses sont suivies et enregistrées de manière précise',
        },
        {
            title: "Suivi de la Main-d'Œuvre",
            description: 'Surveillance et enregistrement des activités des employés, productivité',
        },
        {
            title: 'Analyse des Ventes sur le Terrain',
            description: 'Recueillir des données de vente et évaluer la performance des ventes',
        },
        {
            title: 'Gestion des Commandes',
            description:
                'Processus de gestion des commandes/collections à travers plusieurs stratégies personnalisables',
        },
        {
            title: 'Reporting en Direct',
            description: 'Savoir où se trouvent vos employés sur le terrain et quel travail ils accomplissent',
        },
    ],
    integrationTitle: 'INDUSTRIES',
    integrationSubTitle: "Relier la Gestion de la Main-d'Œuvre et la Productivité pour Tous les Secteurs",
    orderManagementSubtitle: 'Plus qu’un simple système de gestion des commandes',

    orderManagementTitle:
        'Automatisez, suivez et livrez avec une gestion précise des commandes et des collections pour votre équipe de ventes.',
    orderManagementAccordian: [
        {
            title: 'Saisie des Commandes Électronique',
            points: [
                'Suivi en temps réel de l’état des commandes depuis la passation jusqu’à la livraison.',
                'Numérisez le processus en capturant les commandes et en réduisant les erreurs manuelles.',
            ],
        },
        {
            title: 'Facturation Automatisée',
            points: [
                'Générez automatiquement des factures en fonction des détails des commandes.',
                'Envoyez les factures électroniquement pour un traitement et un paiement plus rapides.',
                'Vérification par OTP du paiement effectué par rapport à la facturation.',
            ],
        },
        {
            title: 'Objectifs de Vente',
            points: [
                'Suivez les progrès par rapport aux objectifs avec des rapports détaillés et des analyses.',
                'Définissez et surveillez les objectifs de vente pour les équipes en fonction des rôles et des responsabilités.',
            ],
        },
        {
            title: 'Suivi des Inventaires en Temps Réel',
            points: [
                'Maintenez un compte précis des niveaux de stock à chaque fois qu’une commande est générée et complétée.',
                'Définissez des points de réapprovisionnement pour générer automatiquement des commandes d’achat en cas de faible stock.',
            ],
        },
        {
            title: 'Distribution des Commandes',
            points: [
                'Surveillez le mouvement des marchandises de l’entrepôt au client, en assurant responsabilité et transparence.',
                'Optimisez le processus de distribution entre l’équipe de ventes en fonction de la priorité et de la localisation.',
                'Optimisation des itinéraires basée sur l’IA pour une livraison plus rapide et une optimisation des coûts.',
            ],
        },
    ],
    whyChooseTraceforce: 'Pourquoi Choisir TraceForce',
    whyChooseTraceforceSubtitle:
        'Révolutionner la gestion des ressources humaines en automatisant les tâches administratives, en rationalisant les processus et en améliorant l’engagement des employés.',
    whyChooseTraceForceList: [
        'Amélioration de la Productivité',
        'Efficacité Temporelle',
        'Réclamations avec Précision',
        'Surveillance en Temps Réel',
        'Entièrement Réactif',
        'Optimisation Basée sur l’IA',
        'Personnalisation Basée sur les Critères',
        'Support pour Plusieurs Succursales',
        'Service de Support 24x7',
    ],
    calculatorTitle: 'Calculatrice',
    calculatorSubtitle:
        'Nos fonctionnalités sont simples, puissantes et s’adaptent facilement à tout secteur pour accroître la productivité de l’organisation.',
    workingHours: 'Heures de Travail',
    uncountedHours: 'Heures Non Comptabilisées',
    dailySavings: 'Économies Quotidiennes',
    monthlySavings: 'Économies Mensuelles',
    productivityGrowth: 'Croissance de la Productivité',
    numberOfEmployees: 'Nombre d’Employés',
    faqs: 'FAQs',
    frequentlyAskedQuestions: 'Questions Fréquemment Posées',
    faqLine: 'Voici quelques-uns des types de questions de nos clients',
    faqAccordian: [
        {
            question: 'Quels sont les différents moyens d’authentifier ma connexion ?',
            ans: 'TraceForce vous offre plusieurs options de pointage. Par exemple, nous avons l’option de connexion mobile par défaut, l’option basée sur les selfies et, enfin, la méthode d’authentification faciale AI la plus sécurisée.',
        },
        {
            question: 'Comment marquer la présence ?',
            ans: 'L’utilisateur peut marquer sa présence uniquement lorsqu’il se trouve dans le périmètre géographique qui lui est assigné. De plus, pour éviter les abus, nous avons mis en place différentes méthodes d’authentification.',
        },
        {
            question:
                'Peut-on simuler une localisation ou changer l’heure pour les tâches accomplies ou la présence marquée ?',
            ans: 'TraceForce détecte toute activité suspecte ou non sollicitée et désactivera l’application tout en notifiant l’utilisateur.',
        },
        {
            question: 'TraceForce prend-il en charge les employés à distance ?',
            ans: 'TraceForce prend en charge les employés sous contrat à base horaire et calcule les paies en fonction des heures fixes ou des shifts flexibles.',
        },
        {
            question: 'Puis-je suivre ma main-d’œuvre via mobile ?',
            ans: 'TraceForce vous aide à suivre et à gérer votre main-d’œuvre à la fois via mobile et web, offrant ainsi une vue d’ensemble complète de la journée.',
        },
        {
            question:
                'Quels sont les autres services que TraceForce propose en dehors de la gestion des ressources humaines ?',
            ans: 'TraceForce propose une liste très complète de services pour faciliter le travail des gestionnaires ou des propriétaires d’entreprises et leur fournir uniquement les notifications nécessaires.',
        },
    ],
};
export default homePage;
