const pricing = {
    attendance: {
        name: 'उपस्थिति',
        price: '40',
        subLine: 'कार्य समय की निगरानी के लिए सर्वोत्तम',
        features: [
            'बायोमेट्रिक आधारित क्लॉक-इन',
            'जियो आधारित क्लॉक-इन',
            'आधा दिन विकल्प',
            'ऑटो लॉगआउट',
            'लेट काउंटर',
            'औसत क्लॉक-इन समय',
            'कुल कार्य समय',
            '1 रिपोर्ट पर स्वचालित सदस्यता',
            'उपस्थिति, औसत, चेक-इन/चेक-आउट',
            'रिपोर्ट्स',
            'सभी बुनियादी सुविधाओं सहित',
            'बायोमेट्रिक',
            'चेहरा एआई',
            'कियोस्क',
            'एकाधिक शाखा विकल्प',
            'क्लॉक-इन और क्लॉक-आउट के लिए फॉर्म',
            'सभी बुनियादी और प्रीमियम सुविधाओं सहित',
        ],
    },
    task: {
        name: 'कार्य',
        price: '100',
        subLine: 'कार्य प्रबंधन के लिए सर्वोत्तम',
        features: [
            'फील्ड कार्य',
            'सेवा कार्य',
            'जियोफेंस आधारित कार्य प्रबंधन',
            'चेक-इन चेक-आउट',
            'छोड़ने का समय',
            'पूर्व सूचना',
        ],
    },
    tracking: {
        name: 'ट्रैकिंग',
        price: '300',
        subLine: 'वास्तविक समय ट्रैकिंग के लिए सर्वोत्तम',
        features: [
            'फील्ड कार्य',
            'सेवा कार्य',
            'जियोफेंस आधारित कार्य प्रबंधन',
            'चेक-इन चेक-आउट',
            'छोड़ने का समय',
            'लाइव ट्रैकिंग',
        ],
    },
    customer: {
        name: 'ग्राहक',
        price: '80',
        subLine: 'ग्राहक संबंधों को प्रबंधित करें',
        features: [
            'व्हाट्सएप, ईमेल और संदेश का उपयोग करके संवाद करें',
            'टैग असाइन करें',
            'अंतराल के आधार पर अनुस्मारक',
            'जियोफेंस आधारित यात्रा असाइन करें',
            'यात्राओं का इतिहास',
            'व्यवसाय कार्ड साझा करें',
            'घोषणा',
            'सभी बुनियादी सुविधाओं सहित',
            'एआई आधारित बीट रूट',
            'व्यवसाय कार्ड स्कैनर',
            'संचार के लिए पूर्व-निर्मित टेम्पलेट्स',
            'अनुस्मारक प्रबंधन',
            'व्हाट्सएप, ईमेल और संदेश का उपयोग करके संवाद करें',
            'टैग असाइन करें',
            'अंतराल के आधार पर अनुस्मारक',
            'जियोफेंस आधारित यात्रा असाइन करें',
            'यात्राओं का इतिहास',
            'व्यवसाय कार्ड साझा करें',
            'घोषणा',
        ],
    },
    reimbursement: {
        name: 'प्रतिपूर्ति',
        price: '30',
        subLine: 'सटीक और समय पर भुगतान स्वचालित करें',
        features: [
            'वाहन की दूरी के आधार पर प्रतिपूर्ति',
            'विश्लेषिकी (उपबिंदु)',
            'शाखा के आधार पर',
            'मासिक आधार पर',
            'श्रेणी',
        ],
    },
    leave: {
        name: 'अवकाश',
        price: '30',
        subLine: 'प्रबंधकों को इसे सरलता से स्वीकृत करें',
        features: [
            'अवकाश आगे बढ़ाएं',
            'छुट्टियों का प्रबंधन करें',
            'जमा अवकाश की जांच करें',
            'आधा दिन और घर से काम',
            'अवकाश स्वीकृत करें',
            'प्रत्येक व्यक्ति के लिए नीति अनुकूलित करें',
        ],
    },
    payroll: {
        name: 'पेरोल',
        price: '40',
        subLine: 'सटीक वेतन गणना',
        features: [
            'पेरोल नीति',
            'अग्रिम वेतन गणना',
            'भुगतान सारांश',
            '1-क्लिक गणना',
            'घंटे और मासिक वेतन गणना',
            'अतिरिक्त समय की गणना',
        ],
    },
    faceID: {
        name: 'चेहरा आईडी',
        price: '25',
        subLine: 'सुरक्षा बढ़ाने के लिए सर्वोत्तम',
        features: ['उपस्थिति में चेहरा आईडी जोड़ें (एड-ऑन)'],
    },
    forms: {
        name: 'फॉर्म',
        price: '30',
        subLine: 'डिजिटल फॉर्म बनाएँ और प्रबंधित करें',
        features: [
            'डायनामिक फॉर्म',
            'कस्टम फॉर्म निर्माण',
            'फॉर्म के आधार पर विश्लेषिकी',
            'इवेंट्स के लिए फॉर्म',
            'फॉर्म के माध्यम से भुगतान',
            'व्यक्तिगत फॉर्म बनाएँ',
        ],
    },
};
export default pricing;
