const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'Planes flexibles que crecen contigo',
    subFlexiblePlansThatGrowsWithYou:
        'Elige el plan de precios que se ajuste a las necesidades de tu negocio. Traceforce ofrece paquetes de precios óptimos con un valor superior.',
    pricingPlans: 'Planes de precios',
    pricingThatWorksForEveryOne: 'Precios que funcionan para todos',
    monthly: 'Mensual',
    annualy: 'Anual',
    international: 'Internacional',
    domestic: 'Nacional',
    month: 'Mes',
    benifits: 'Beneficios',
    allPlanIncludeTheseBenifits: 'Todos los planes incluyen estos beneficios',
    someBenfitsOfMonthlyYearlySubscription: 'Algunos beneficios de la suscripción mensual/anual',
    benifitsArr: [
        {
            title: 'Soporte técnico',
            description: 'Nuestro equipo de soporte técnico profesional te asistirá en cada paso del camino.',
        },
        {
            title: 'Tecnología',
            description: 'Entrenamiento especial para comenzar con la plataforma de la mano de profesionales.',
        },
        {
            title: 'Análisis de crecimiento',
            description: 'Un equipo dedicado para proporcionarte información sobre tu crecimiento mensual.',
        },
        {
            title: 'Recompensa',
            description: 'Una recompensa especial para la cuenta de mejor desempeño cada mes.',
        },
    ],
    fAQs: 'Preguntas Frecuentes',
    frequentlyAskedQuestions: 'Preguntas Frecuentes',
    someBaTypeOfQuestions: 'Aquí tienes algunos tipos comunes de preguntas de nuestros clientes',
    fAQArr: [
        {
            question: '¿Cómo puedo registrarme para mi empresa?',
            ans: 'Sí, la licencia del mercado te permite usar este tema en cualquier producto final. Para más información sobre la licencia, por favor revisa los términos de la licencia en el mercado.',
        },
        {
            question: '¿Dónde puedo agregar mi vehículo de fuerza laboral?',
            ans: 'No, este es un template HTML. No funcionará directamente con WordPress, aunque puedes convertirlo en un tema compatible con WordPress.',
        },
        {
            question: '¿Cómo inicia sesión mi fuerza laboral en Traceforce?',
            ans: 'Envía tus problemas o comentarios a nuestro correo de soporte dedicado (support@coderthemes.com). Estamos disponibles en cualquier momento para asistirte.',
        },
        {
            question: '¿Puedo rastrear mi fuerza laboral a través de móvil?',
            ans: 'Sí, actualizaremos regularmente los mensajes. Todas las actualizaciones futuras estarán disponibles de forma gratuita.',
        },
        {
            question: '¿Cómo marcar asistencia?',
            ans: 'Sí, la licencia del mercado te permite usar este tema en cualquier producto final. Para más información sobre la licencia, por favor revisa los términos de la licencia en el mercado.',
        },
        {
            question: '¿Cómo desactivar las opciones para desarrolladores?',
            ans: 'No, este es un template HTML. No funcionará directamente con WordPress, aunque puedes convertirlo en un tema compatible con WordPress.',
        },
        {
            question: '¿Qué características ofrece Traceforce?',
            ans: 'Envía tus problemas o comentarios a nuestro correo de soporte dedicado (support@coderthemes.com). Estamos disponibles en cualquier momento para asistirte.',
        },
        {
            question: '¿Necesito instalar el software de Traceforce?',
            ans: 'Sí, actualizaremos regularmente los mensajes. Todas las actualizaciones futuras estarán disponibles de forma gratuita.',
        },
        {
            question: '¿Cuál es el proceso de implementación del software Traceforce?',
            ans: 'Sí, la licencia del mercado te permite usar este tema en cualquier producto final. Para más información sobre la licencia, por favor revisa los términos de la licencia en el mercado.',
        },
        {
            question: '¿Qué opciones de formación y soporte están disponibles con el software Traceforce?',
            ans: 'No, este es un template HTML. No funcionará directamente con WordPress, aunque puedes convertirlo en un tema compatible con WordPress.',
        },
        {
            question: '¿Puede personalizarse el software Traceforce según nuestros procesos específicos de RRHH?',
            ans: 'Envía tus problemas o comentarios a nuestro correo de soporte dedicado (support@coderthemes.com). Estamos disponibles en cualquier momento para asistirte.',
        },
        {
            question: '¿Cómo puede ayudar el software de RRHH con el cumplimiento y la elaboración de informes?',
            ans: 'Sí, actualizaremos regularmente los mensajes. Todas las actualizaciones futuras estarán disponibles de forma gratuita.',
        },
        {
            question: '¿El software de RRHH soporta trabajo remoto y acceso móvil?',
            ans: 'Sí, actualizaremos regularmente los mensajes. Todas las actualizaciones futuras estarán disponibles de forma gratuita.',
        },
    ],
};
export default pricingPage;
