const pricing = {
    attendance: {
        name: 'Attendance',
        price: '40',
        subLine: 'Best For Monitor Working Hours',
        features: [
            'Biometric based Clockin',
            'Geo based Clockin',
            'Half Day Option',
            'Auto Logout',
            'Late Counter',
            'Avg Clock In Time',
            'Total Working Hours',
            'Auto Subscribe to 1 Report',
            'Attendance, Avg, Checkin/ Check out',
            'Reports',
            'Incl of All Basic Features',
            'Biometric',
            'Face AI',
            'Kiosk',
            'Multiple Branch Option',
            'Form for Clock in and Clock Out',
            'Inclusive of All basic and Premium Features',
        ],
    },
    task: {
        name: 'Task',
        price: '100',
        subLine: 'Best for managing tasks',
        features: [
            'Field Task',
            'Service Task',
            'Geofence based Task Management',
            'Check In Check Out',
            'Time to Leave',
            'Prior Notification',
        ],
    },
    tracking: {
        name: 'Tracking',
        price: '300',
        subLine: 'Best for real-time tracking',
        features: [
            'Field Task',
            'Service Task',
            'Geofence based Task Management',
            'Check In Check Out',
            'Time to Leave',
            'Live Tracking',
        ],
    },
    customer: {
        name: 'Customer',
        price: '80',
        subLine: 'Manage customer relationships',
        features: [
            'Communicate using Whatsapp, Email, and Message',
            'Assign Tags',
            'Reminders based on Interval',
            'Assign Geo Fence based Visit',
            'History of Visits',
            'Share Business Card',
            'Announcement',
            'Inclusive Of All Basic Feature',
            'AI based Beat Route',
            'Business Card Scanner',
            'Pre made Templates for Communication',
            'Manage Reminders',
            'Communicate using Whatsapp, Email, and Message',
            'Assign Tags',
            'Reminders based on Interval',
            'Assign Geo Fence based Visit',
            'History of Visits',
            'Share Business Card',
            'Announcement',
        ],
    },
    reimbursement: {
        name: 'Reimbursement',
        price: '30',
        subLine: 'Automate accurate & timely payments',
        features: [
            'Reimbursement based on Vehicle Distance',
            'Analytics(Subpoint)',
            'Based on Branch',
            'Based onMonthly',
            'Category',
        ],
    },
    leave: {
        name: 'Leave',
        price: '30',
        subLine: 'Managers to approve it effortlessly',
        features: [
            'Carry Forward Leaves',
            'Manage Holidays',
            'Check Accrued Leaves',
            'Half Day and Work from Home',
            'Approve Leave',
            'Customise Policy for each Individual',
        ],
    },
    payroll: {
        name: 'Payroll',
        price: '40',
        subLine: 'Accurate salary calculations',
        features: [
            'Payroll Policy',
            'Advance Salary Calculation',
            'Payment Summary',
            '1- Click Calculation',
            'Hourly & Monthly Salary Calculation',
            'Overtime Calculation',
        ],
    },
    faceID: {
        name: 'Face ID',
        price: '25',
        subLine: 'Best for enhance security',
        features: ['Add Face ID in attendance as an (Add on)'],
    },
    forms: {
        name: 'Forms',
        price: '30',
        subLine: 'Create and manage digital forms',
        features: [
            'Dynamic Forms',
            'Custom Form Creation',
            'Analytics based on Forms',
            'Forms for Events',
            'Payment through Forms',
            'Create Personalized Forms',
        ],
    },
};
export default pricing;
