const navbar = {
    industries: 'Industrias',
    pharmaceuticals: 'Farmacéutico',
    fieldSales: 'Ventas en Campo',
    fmcg: 'FMCG',
    service: 'Servicios',
    fieldOperations: 'Operaciones de Campo',
    logisticsAndTransportation: 'Logística y Transporte',
    education: 'Educación',
    products: 'Productos',
    pricing: 'Precios',
    attendanceManagement: 'Gestión de Asistencia',
    fieldForceManagement: 'Gestión de Fuerza de Campo',
    company: 'Compañía',
    ourStory: 'Nuestra Historia',
    joinUs: 'Únete a Nosotros',
    contactUs: 'Contáctanos',
    integration: 'Integración',
    becomeAPartner: 'Conviértete en Socio',
    blog: 'Blog',
};
export default navbar;
