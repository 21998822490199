const footer = {
    requestACallBack: 'कॉलबैक का अनुरोध करें',
    freeAccountMessage: 'अपना मुफ्त 14-दिन का खाता अभी बनाएं',
    footerMessage:
        'कर्मचारी प्रबंधन और बिक्री संचालन में क्रांति लाना, प्रशासनिक कार्यों को स्वचालित करना, प्रक्रियाओं को सुव्यवस्थित करना और कर्मचारी सहभागिता को बढ़ाना। हमारी विशेषताएं सरल, शक्तिशाली और किसी भी उद्योग के लिए आसानी से फिट होती हैं, जिससे संगठन की उत्पादकता बढ़ती है।',
    address: `922, ए विंग, न्याटी एम्प्रेस,
बाकर एंड गेजेस इंडिया प्राइवेट लिमिटेड के पास,
विमान नगर रोड के पास, विमान नगर, 411014, पुणे।`,
    rightsReserved: 'ट्रेसफोर्स सर्वाधिकार सुरक्षित',
};
export default footer;
