const footer = {
    requestACallBack: 'Demander un rappel',
    freeAccountMessage: 'Créez votre compte gratuit de 14 jours dès maintenant',
    footerMessage:
        'Révolutionner la gestion des employés et les opérations de vente en automatisant les tâches administratives, en rationalisant les processus et en améliorant l’engagement des employés. Nos fonctionnalités sont simples, puissantes et s’adaptent facilement à tout secteur pour accroître la productivité de l’organisation.',
    address: `922, A Wing, Nyati Empress,
À côté de Baker and Guages India Pvt Ltd,
Off Viman Nagar Road, Viman Nagar, 411014, Pune.`,
    rightsReserved: 'TraceForce Tous droits réservés',
};

export default footer;
