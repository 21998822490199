const footer = {
    requestACallBack: 'Solicitar uma Retorno de Chamada',
    freeAccountMessage: 'Crie sua conta gratuita de 14 dias agora',
    footerMessage:
        'Revolucionando a gestão de funcionários e operações de vendas ao automatizar tarefas administrativas, simplificar processos e melhorar o engajamento dos funcionários. Nossos recursos são simples, poderosos e facilmente adaptáveis a qualquer setor para aumentar a produtividade da organização.',
    address:
        '922, A Wing, Nyati Empress, Próximo à Baker e Guages India Pvt Ltd, Off Viman Nagar Road, Viman Nagar, 411014, Pune.',
    rightsReserved: 'TraceForce Todos os Direitos Reservados',
};
export default footer;
