const pricing = {
    attendance: {
        name: 'હાજરી',
        price: '40',
        subLine: 'કામની કલાકોને મોનિટર કરવા માટે શ્રેષ્ઠ',
        features: [
            'બાયોમેટ્રિક આધારિત ક્લોકિન',
            'જીઓ આધારિત ક્લોકિન',
            'અડધા દિવસની વિકલ્પ',
            'ઓટો લૉગઆઉટ',
            'લેટ કાઉન્ટર',
            'સરેરાશ ક્લોક ઇન સમય',
            'કુલ કામકાજના કલાકો',
            'ઓટોમેટિક 1 રિપોર્ટ માટે સબસ્ક્રાઇબ',
            'હાજરી, સરેરાશ, ચેકિન/ચેક આઉટ',
            'રિપોર્ટ્સ',
            'બેસિક ફીચર્સની તમામ સમાવિષ્ટ',
            'બાયોમેટ્રિક',
            'ફેસ એઆઈ',
            'કિયૉસ્ક',
            'અડધા શાખાની વિકલ્પ',
            'ક્લોક ઇન અને ક્લોક આઉટ માટે ફોર્મ',
            'બેસિક અને પ્રીમિયમ ફીચર્સની તમામ સમાવિષ્ટ',
        ],
    },
    task: {
        name: 'ટાસ્ક',
        price: '100',
        subLine: 'ટાસ્ક મેનેજમેન્ટ માટે શ્રેષ્ઠ',
        features: [
            'ફીલ્ડ ટાસ્ક',
            'સેવા ટાસ્ક',
            'જીઓફેન્સ આધારિત ટાસ્ક મેનેજમેન્ટ',
            'ચેક ઇન ચેક આઉટ',
            'પ્રસ્થાનનો સમય',
            'પૂર્વ સૂચના',
        ],
    },
    tracking: {
        name: 'ટ્રેકિંગ',
        price: '300',
        subLine: 'રિયલ-ટાઈમ ટ્રેકિંગ માટે શ્રેષ્ઠ',
        features: [
            'ફીલ્ડ ટાસ્ક',
            'સેવા ટાસ્ક',
            'જીઓફેન્સ આધારિત ટાસ્ક મેનેજમેન્ટ',
            'ચેક ઇન ચેક આઉટ',
            'પ્રસ્થાનનો સમય',
            'લાઇવ ટ્રેકિંગ',
        ],
    },
    customer: {
        name: 'ગ્રાહક',
        price: '80',
        subLine: 'ગ્રાહક સંબંધો મેનેજ કરો',
        features: [
            'વ્હોટ્સએપ, ઈમેલ, અને મેસેજ દ્વારા સંચાલિત',
            'ટેગ્સ ફાળવવી',
            'વિશિષ્ટ સમયાંતરે રીમાઇન્ડર્સ',
            'જીઓફેન્સ આધારિત મુલાકાત ફાળવવી',
            'મુલાકાતોની ઇતિહાસ',
            'બિઝનેસ કાર્ડ શેર કરવું',
            'વિજ્ઞાપન',
            'બેસિક ફીચર્સની તમામ સમાવેશ',
            'એઆઈ આધારિત બીટ રૂટ',
            'બિઝનેસ કાર્ડ સ્કેનર',
            'સંવાદ માટે પૂર્વ-બનાવેલા સ્નેપલેટ',
            'રીમાઇન્ડર્સ મેનેજ કરો',
            'વ્હોટ્સએપ, ઈમેલ, અને મેસેજ દ્વારા સંચાલિત',
            'ટેગ્સ ફાળવવી',
            'વિશિષ્ટ સમયાંતરે રીમાઇન્ડર્સ',
            'જીઓફેન્સ આધારિત મુલાકાત ફાળવવી',
            'મુલાકાતોની ઇતિહાસ',
            'બિઝનેસ કાર્ડ શેર કરવું',
            'વિજ્ઞાપન',
        ],
    },
    reimbursement: {
        name: 'રિઅંબર્સમેન્ટ',
        price: '30',
        subLine: 'સાચી અને સમયસર ચુકવણી ઓટોમેટ કરો',
        features: [
            'વાહન અંતર પર આધારિત રિઅંબર્સમેન્ટ',
            'એનાલિટિક્સ (ઉપવિભાગ)',
            'શાખા પર આધારિત',
            'માસિક આધારિત',
            'શ્રેણી',
        ],
    },
    leave: {
        name: 'ઉછાળું',
        price: '30',
        subLine: 'મૅનેજરો માટે સરળતા સાથે મંજૂરી આપવી',
        features: [
            'કેરી ફોરવર્ડ છુટા',
            'અવકાશોને મેનેજ કરો',
            'અક્યુમ્યુલેટેડ છુટા તપાસો',
            'અડધા દિવસ અને કામથી ઘરેલું',
            'છુટાની મંજૂરી આપવી',
            'પ્રત્યેક વ્યક્તિ માટે નીતિને કસ્ટમાઇઝ કરો',
        ],
    },
    payroll: {
        name: 'પેરૂલ',
        price: '40',
        subLine: 'શ્રેષ્ઠ પગાર ગણનાઓ',
        features: [
            'પેરૂલ નીતિ',
            'એડવાન્સ પગાર ગણના',
            'ચુકવણી સંક્ષેપ',
            '1-ક્લિક ગણના',
            'કલાકની અને માસિક પગાર ગણના',
            'ઓવર્ટાઇમ ગણના',
        ],
    },
    faceID: {
        name: 'ફેસ આઈડી',
        price: '25',
        subLine: 'સુરક્ષા વધારવા માટે શ્રેષ્ઠ',
        features: ['હાજરીમાં ફેસ આઈડી ઉમેરવું (એડ ઓન તરીકે)'],
    },
    forms: {
        name: 'ફોર્મ્સ',
        price: '30',
        subLine: 'ડિજિટલ ફોર્મ્સ બનાવો અને મેનેજ કરો',
        features: [
            'ડાયનામિક ફોર્મ્સ',
            'કસ્ટમ ફોર્મ બનાવવું',
            'ફોર્મ્સ પર આધારિત એનાલિટિક્સ',
            'ઈવેન્ટ્સ માટે ફોર્મ્સ',
            'ફોર્મ્સ મારફતે ચૂકવણી',
            'વ્યક્તિગત ફોર્મ્સ બનાવો',
        ],
    },
};
export default pricing;
