const pricing = {
    attendance: {
        name: 'தாராளம்',
        price: '40',
        subLine: 'உரிய நேரத்தை கண்காணிக்க சிறந்தது',
        features: [
            'உயிர்மூல புள்ளி அடையாளம் அடித்த clockin',
            'ஜியோ அடிப்படையிலான clockin',
            'அரை நாள் விருப்பம்',
            'தானாக பின்வரவு',
            'தாமதக் கணக்கு',
            'சராசரி clock in நேரம்',
            'மொத்த வேலை நேரம்',
            'ஒரு அறிக்கைக்கு தானாக சந்தா',
            'தாராளம், சராசரி, நுழைவு/வெளியேறுதல்',
            'அறிக்கைகள்',
            'அனைத்து அடிப்படைக் கட்டளைகள் உடன்',
            'உயிர்மூல புள்ளி அடையாளம்',
            'முகம் எய்ஐ',
            'கியோஸ்க்',
            'பல கிளை விருப்பம்',
            'நுழைவு மற்றும் வெளியேற்றம் மூலம் படிவம்',
            'அனைத்து அடிப்படைக் மற்றும் பிரீமியம் அம்சங்களையும் உட்கொள்கிறது',
        ],
    },
    task: {
        name: 'பணி',
        price: '100',
        subLine: 'பணிகளை மேலாண்மை செய்ய சிறந்தது',
        features: [
            'துறை பணி',
            'சேவை பணி',
            'ஜியோபென்ஸ் அடிப்படையிலான பணி மேலாண்மை',
            'நுழைவு நுழைவு',
            'செலையிலிருந்து நேரம்',
            'முந்தைய அறிவிப்பு',
        ],
    },
    tracking: {
        name: 'கண்காணிப்பு',
        price: '300',
        subLine: 'உண்மையான நேரத்தில் கண்காணிக்க சிறந்தது',
        features: [
            'துறை பணி',
            'சேவை பணி',
            'ஜியோபென்ஸ் அடிப்படையிலான பணி மேலாண்மை',
            'நுழைவு நுழைவு',
            'செலையிலிருந்து நேரம்',
            'உயிர்நேர கண்காணிப்பு',
        ],
    },
    customer: {
        name: 'வாடிக்கையாளர்',
        price: '80',
        subLine: 'வாடிக்கையாளர் உறவுகளை மேலாண்மை செய்யவும்',
        features: [
            'வாடிக்கையாளர் Whatsapp, மின்னஞ்சல் மற்றும் செய்தி மூலம் தொடர்பு கொள்ளவும்',
            'ட்டாக்களை ஒதுக்கவும்',
            'இருக்கையின் அடிப்படையில் நினைவுறுத்திகள்',
            'ஜியோபென்ஸ் அடிப்படையிலான பார்வை ஒதுக்கவும்',
            'பார்வை வரலாறு',
            'வணிக அட்டை பகிரவும்',
            'அறிக்கையிடல்',
            'அனைத்து அடிப்படைக் அம்சங்களை உட்கொள்கிறது',
            'எய்ஐ அடிப்படையிலான பீட் பாதை',
            'வணிக அட்டை ஸ்கேனர்',
            'தொடர்பு கொள்ள முந்தைய அட்டவணைகள்',
            'நினைவுறுத்திகளை மேலாண்மை செய்யவும்',
            'Whatsapp, மின்னஞ்சல் மற்றும் செய்தி மூலம் தொடர்பு கொள்ளவும்',
            'ட்டாக்களை ஒதுக்கவும்',
            'இருக்கையின் அடிப்படையில் நினைவுறுத்திகள்',
            'ஜியோபென்ஸ் அடிப்படையிலான பார்வை ஒதுக்கவும்',
            'பார்வை வரலாறு',
            'வணிக அட்டை பகிரவும்',
            'அறிக்கையிடல்',
        ],
    },
    reimbursement: {
        name: 'திரும்பப் பெறுதல்',
        price: '30',
        subLine: 'துல்லியமான மற்றும் நேர்மையான கட்டணங்களை தானாக செய்யவும்',
        features: [
            'வாகன இடைவெளி அடிப்படையிலான திரும்பப் பெறுதல்',
            'அணலிடிக்ஸ் (அந்தப் புள்ளி)',
            'அடிப்படையிலான கிளை',
            'மாதாந்திர அடிப்படையிலான',
            'வகை',
        ],
    },
    leave: {
        name: 'அனுமதி',
        price: '30',
        subLine: 'மேலாளர்கள் அதை எளிதாக ஒப்புக்கொள்ள வேண்டும்',
        features: [
            'மேலாக கொண்டு செல்லும் விடுப்புகள்',
            'தரவேண்டிய விடுமுறைகளை மேலாண்மை செய்யவும்',
            'அடிப்படையில் விடுப்புகளைச் சரிபார்க்கவும்',
            'அரை நாள் மற்றும் வீட்டு வேலை',
            'விடுப்புகளை ஒப்புக்கொள்ளவும்',
            'ஒவ்வொரு தனிநபருக்கும் தனிப்பயனாக்கும் கொள்கை',
        ],
    },
    payroll: {
        name: 'மூலோபயபம்',
        price: '40',
        subLine: 'துல்லியமான சம்பள கணக்கீடுகள்',
        features: [
            'மூலோபயபம் கொள்கை',
            'முன்முதலீட்டு சம்பள கணக்கீடு',
            'பணம் சுருக்கம்',
            'ஒரு கிளிக் கணக்கீடு',
            'அஞ்சலியுள்ள மற்றும் மாதாந்திர சம்பள கணக்கீடு',
            'அடிக்கடி கணக்கீடு',
        ],
    },
    faceID: {
        name: 'முகம் அடையாளம்',
        price: '25',
        subLine: 'முன்மையிலான பாதுகாப்புக்காக சிறந்தது',
        features: ['அதிகரிக்கப்பட்ட பாதுகாப்புக்கான முகம் அடையாளத்தை தாராளமாக சேர்க்கவும்'],
    },
    forms: {
        name: 'படிவங்கள்',
        price: '30',
        subLine: 'டிஜிட்டல் படிவங்களை உருவாக்கவும் மற்றும் மேலாண்மை செய்யவும்',
        features: [
            'சரியான படிவங்கள்',
            'தனிப்பயன் படிவ உருவாக்கம்',
            'படிவங்களை அடிப்படையிலான கணிப்பியல்',
            'நிகழ்வுகளுக்கான படிவங்கள்',
            'படிவங்கள் மூலம் பணம்',
            'தனிப்பயனாக்கப்பட்ட படிவங்களை உருவாக்கவும்',
        ],
    },
};
export default pricing;
