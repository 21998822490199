const homePage = {
    heroHeader: 'Достигайте выдающихся результатов в продажах и операционной эффективности с помощью',
    heroHeaderTypist: ['Управление посещаемостью', 'Мониторинг полевых данных', 'CRM'],
    heroHeaderSubText:
        'Посещаемость на основе местоположения в реальном времени, Живое отслеживание, Управление задачами и заказами, Анализ данных и API услуги',
    moreProductivityLessExpenses: 'Больше продуктивности, меньше затрат',
    keyFeatures: [
        {
            title: 'Lens',
            description:
                'Безконтактный учет посещаемости с помощью AI-помощи. Отмечайте посещаемость, используя камеру вашего мобильного телефона.',
        },
        {
            title: 'Веб-приложение',
            description:
                'Удобная панель управления, команды HR и менеджеров могут легко получать подробные отчеты, управлять записями сотрудников, отслеживать производительность и проверять расчеты по зарплате.',
        },
        {
            title: 'Контроль на основе иерархии',
            description:
                'Система контроля на основе иерархии для учета различных организационных уровней, включая административных, HR-менеджеров, супервизоров и сотрудников.',
        },
        {
            title: 'Оптимизация маршрута',
            description:
                'Повышение эффективности и снижение операционных затрат с помощью динамических расчетов маршрута, оптимизации с несколькими остановками, оценки затрат и обновлений в реальном времени.',
        },
    ],
    features: 'Функции',
    ourFeatures:
        'Наши функции просты, мощны и легко подходят для любой отрасли, чтобы повысить продуктивность организации.',
    ourFeaturesObject: [
        {
            title: 'Мониторинг посещаемости',
            description: 'Отметка посещаемости с учетом местоположения и отслеживание рабочего времени.',
        },
        {
            title: 'Управление расходами',
            description: 'Расходы отслеживаются и фиксируются точно.',
        },
        {
            title: 'Отслеживание рабочей силы',
            description: 'Мониторинг и запись деятельности сотрудников, производительности.',
        },
        {
            title: 'Управление зарплатой',
            description: 'Оптимизация обработки заработной платы и точные выплаты.',
        },
        {
            title: 'Управление заказами',
            description: 'Процесс управления заказами/сбором с помощью нескольких настраиваемых стратегий.',
        },
        {
            title: 'Оптимизация маршрута',
            description: 'Поиск наиболее эффективного пути для экономии времени, топлива и ресурсов.',
        },
    ],
    whatWeDoQuestion: 'Что делает TraceForce?',
    whatWeDoCards: [
        {
            title: 'Анализ активности сотрудников в реальном времени',
            description: 'Получите подробный анализ активности команды на ежедневной или почасовой основе.',
        },
        {
            title: 'Посещаемость на основе GeoFence и отчеты о проверках',
            description: 'Повышайте эффективность команды с помощью стандартного управления посещаемостью и отпусками.',
        },
        {
            title: 'Эффективное управление заказами/сбором',
            description:
                'Минимизируйте ошибки, увеличьте производительность и удовлетворенность клиентов с помощью умных стратегий заказа и своевременного сбора.',
        },
        {
            title: 'Управление задачами от начала до конца',
            description:
                'Оптимизируйте планирование проектов, легко назначайте задачи и способствуйте реальному сотрудничеству среди членов вашей команды.',
        },
        {
            title: 'Анализ на основе автоматических отчетов',
            description: 'Визуализируйте вовлеченность сотрудников и проводите безупречный анализ команды.',
        },
        {
            title: 'Управление расходами на основе пособий',
            description:
                'Управляйте системами HR для распределения онлайн-расчетных листов в соответствии с записями в листах посещаемости.',
        },
    ],
    whatWeDoList: [
        'Журналы посещаемости',
        'Отслеживание местоположения',
        'Облачное хранилище',
        'Оповещения и уведомления',
        'Управление проектами',
        'Управление воронкой продаж',
    ],
    featureTitle: 'Функции',
    featureSubTitle:
        'Наши функции просты, мощны и легко подходят для любой отрасли, чтобы повысить продуктивность организации.',
    featureCards: [
        {
            title: 'Мониторинг посещаемости',
            description: 'Отметка посещаемости с учетом местоположения и отслеживание рабочего времени.',
        },
        {
            title: 'Управление расходами',
            description: 'Расходы отслеживаются и фиксируются точно.',
        },
        {
            title: 'Отслеживание рабочей силы',
            description: 'Мониторинг и запись деятельности сотрудников, производительности.',
        },
        {
            title: 'Анализ продаж в полевых условиях',
            description: 'Сбор данных о продажах и оценка производительности продаж.',
        },
        {
            title: 'Управление заказами',
            description: 'Процесс управления заказами/сбором с помощью нескольких настраиваемых стратегий.',
        },
        {
            title: 'Живое отчетирование',
            description: 'Знайте, где находятся ваши полевые сотрудники и чем они занимаются.',
        },
    ],
    integrationTitle: 'ОТРАСЛИ',
    integrationSubTitle: 'Соединение управления рабочей силой и продуктивности для всех отраслей',
    orderManagementSubtitle: 'Больше, чем просто система управления заказами',
    orderManagementTitle:
        'Автоматизируйте, отслеживайте и выполняйте точное управление заказами и сборами для вашей команды продаж.',
    orderManagementAccordian: [
        {
            title: 'Электронный ввод заказов',
            points: [
                'Отслеживание статуса заказа в реальном времени от размещения до доставки.',
                'Цифровизация процесса путем захвата заказов и уменьшения ручных ошибок.',
            ],
        },
        {
            title: 'Автоматизированные счета',
            points: [
                'Автоматическое создание счетов на ходу на основе деталей заказа.',
                'Отправка счетов в электронном виде для более быстрой обработки и оплаты.',
                'Верификация оплаты с помощью OTP на основе счета.',
            ],
        },
        {
            title: 'Цели продаж',
            points: [
                'Отслеживание прогресса в отношении целей с помощью подробных отчетов и аналитики.',
                'Установка и мониторинг целей продаж для команд на основе ролей и обязанностей.',
            ],
        },
        {
            title: 'Отслеживание инвентаря в реальном времени',
            points: [
                'Поддержание точного учета уровня запасов каждый раз, когда создается и завершается заказ.',
                'Установка точек повторного заказа для автоматического создания заказов на покупку при низком уровне запасов.',
            ],
        },
        {
            title: 'Распределение заказа',
            points: [
                'Мониторинг движения товаров от склада к клиенту, обеспечение ответственности и прозрачности.',
                'Оптимизация процесса распределения между командами продаж на основе приоритета и местоположения.',
                'AI-оптимизация маршрутов для более быстрой доставки и оптимизации затрат.',
            ],
        },
    ],
    whyChooseTraceforce: 'Почему выбрать TraceForce',
    whyChooseTraceforceSubtitle:
        'Революционизируем управление человеческими ресурсами, автоматизируя административные задачи, упрощая процессы и повышая вовлеченность сотрудников.',
    whyChooseTraceforceList: [
        'Улучшение продуктивности',
        'Эффективность времени',
        'Точные расчеты',
        'Мониторинг в реальном времени',
        'Полностью адаптивный',
        'AI-оптимизация',
        'Настройка на основе критериев',
        'Поддержка нескольких филиалов',
        'Круглосуточная поддержка',
    ],
    calculatorTitle: 'Калькулятор',
    calculatorSubtitle:
        'Наши функции просты, мощны и легко подходят для любой отрасли, чтобы повысить продуктивность организации.',
    workingHours: 'Рабочие часы',
    uncountedHours: 'Неучтенные часы',
    dailySavings: 'Ежедневная экономия',
    monthlySavings: 'Ежемесячная экономия',
    productivityGrowth: 'Рост продуктивности',
    numberOfEmployees: 'Количество сотрудников',
    faqs: 'Часто задаваемые вопросы',
    frequentlyAskedQuestions: 'Часто задаваемые вопросы',
    faqLine: 'Вот некоторые основные вопросы для наших клиентов',
    faqAccordian: [
        {
            question: 'Как мне зарегистрировать свою фирму?',
            ans: 'Вы можете зарегистрироваться на TraceForce за 3 простых шага через наше мобильное или веб-приложение. Просто добавьте свое имя, электронную почту и GST номер, и вы готовы начать.',
        },
        {
            question: 'Какие способы аутентификации я могу использовать для входа?',
            ans: 'TraceForce предоставляет несколько вариантов входа. Например, у нас есть стандартный вариант входа через мобильное приложение, опция на основе селфи и, наконец, наиболее безопасная AI-аутентификация по лицу.',
        },
        {
            question: 'Как отметить посещаемость?',
            ans: 'Пользователь может отметить посещаемость только тогда, когда находится в назначенной зоне GeoFence. Также для предотвращения прокси мы используем различные методы аутентификации.',
        },
        {
            question:
                'Можно ли подделать местоположение или изменить время для завершенных задач или отмеченной посещаемости?',
            ans: 'TraceForce обнаруживает любые подозрительные или нежелательные действия и отключает приложение, а также уведомляет пользователя.',
        },
        {
            question: 'Поддерживает ли TraceForce удаленных сотрудников?',
            ans: 'TraceForce поддерживает сотрудников по контракту на почасовой основе, поэтому расчет заработной платы производится на основе фиксированных или гибких смен.',
        },
        {
            question: 'Могу ли я отслеживать свою рабочую силу через мобильное приложение?',
            ans: 'TraceForce помогает вам отслеживать и управлять вашей рабочей силой через мобильное приложение и веб-версию, предоставляя вам подробные данные по каждому дню.',
        },
        {
            question: 'Какие другие услуги предлагает TraceForce, кроме HRMS?',
            ans: 'TraceForce предлагает широкий спектр услуг, чтобы упростить работу менеджеров и владельцев бизнеса, предоставляя только необходимые уведомления.',
        },
    ],
};
export default homePage;
