const industries = {
    pharmaceutical: {
        industryHeading:
            'Контролируйте протоколы безопасности, отслеживайте время, посещаемость и контролируйте доступ к чувствительным зонам, обеспечивая эффективное управление проектами, деятельностью вне офиса и оценку производительности.',
        featureHeader: 'Фармацевтика',
        featureParagraph:
            'Программное обеспечение для управления посещаемостью и отслеживания в полевых условиях, ориентированное на фармацевтические компании/медицинские учреждения, упрощает управление рабочей силой с функциями отслеживания времени, мобильного доступа для сотрудников в полевых условиях, отчетности по соблюдению операций в полевых условиях и интеграции с различными системами для генерации продаж и повышения продуктивности.',
        keyPoints: [
            {
                header: 'Интеграция систем отслеживания сотрудников с HR-системами, связанными с записями сотрудников, расчетом заработной платы и отчетностью по соблюдению.',
                paragraph: 'Мониторинг рабочего времени на основе ежедневных, недельных и ежемесячных отчетов.',
            },
            {
                header: 'Использование систем управления сменами для оптимизации расписания рабочей силы.',
                paragraph: 'Удаленный доступ к отчетам о посещаемости нескольких филиалов.',
            },
            {
                header: 'Мониторинг расписаний производственных и производственных зон, где непрерывные операции имеют решающее значение.',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
            {
                header: 'Обеспечение соблюдения всех систем отслеживания сотрудников в соответствии с действующими нормативными актами и стандартами в фармацевтической отрасли.',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
        ],
    },
    education: {
        industryHeading:
            'Комплексная CRM для приема в учебные заведения для бесшовного сотрудничества со студентами и управления перспективами.',
        featureHeader: 'Образование',
        featureParagraph:
            'Набор студентов и прием \n Взаимодействие со студентами и удержание \n Управление приемом в нескольких местах \n Персонализированная поддержка студентов',
        keyPoints: [
            {
                header: 'Отслеживайте и управляйте потенциальными студентами с первого контакта до процесса подачи заявки.',
                paragraph: 'Мониторинг рабочего времени на основе ежедневных, недельных и ежемесячных отчетов.',
            },
            {
                header: 'Идентифицируйте и управляйте потенциальными студентами, которые проявляют интерес к поступлению. Отслеживайте коммуникацию и прогресс, чтобы преобразовать запросы в зачисленных студентов.',
                paragraph: 'Удаленный доступ к отчетам о посещаемости нескольких филиалов.',
            },
            {
                header: 'Визуализируйте и управляйте различными этапами процесса зачисления студентов. Перемещайте заявителей через этапы, такие как запрос, заявка, собеседование, прием и зачисление.',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
            {
                header: 'Упростите процесс подачи заявки с помощью настраиваемых онлайн-форм. Эффективно собирайте необходимую информацию от потенциальных студентов.',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
        ],
    },
    fieldAndSales: {
        industryHeading:
            'Оптимизируйте работу команд продаж в движении, обеспечивая отслеживание местоположения в реальном времени, эффективное планирование маршрутов и своевременное взаимодействие с клиентами.',
        featureHeader: 'Полевые продажи',
        featureParagraph:
            'Ускорьте рост и улучшите работу вашей полевой команды с помощью правильных инструментов для планирования, конверсии и выполнения. TraceForce упрощает отслеживание полевых продаж и управление лидерами и CRM.',
        keyPoints: [
            {
                header: 'Посещаемость в реальном времени',
                paragraph:
                    'Информация в реальном времени о присутствии или отсутствии лиц в зоне GeoFence или филиале.',
            },
            {
                header: 'Расчет общего рабочего времени',
                paragraph: 'Мониторинг рабочего времени на основе ежедневных, недельных и ежемесячных отчетов.',
            },
            {
                header: 'Удаленный доступ к отчетам о посещаемости',
                paragraph: 'Удаленный доступ к отчетам о посещаемости нескольких филиалов.',
            },
            {
                header: 'Централизованное управление посещаемостью',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
        ],
    },
    fmcg: {
        featureHeader: 'FMCG',
        industryHeading:
            'Метрики производительности, управление расходами и отслеживание соблюдения стандартов эффективно для управления рабочей силой, улучшая взаимодействие с клиентами с помощью функций коммуникации и сотрудничества.',
        featureParagraph:
            'Отслеживание посещаемости в реальном времени позволяет быстро корректировать уровни персонала в зависимости от рыночного спроса, улучшая гибкость операций FMCG.',
        keyPoints: [
            {
                header: 'Точное отслеживание посещаемости помогает',
                paragraph:
                    'Информация в реальном времени о присутствии или отсутствии лиц в зоне GeoFence или филиале.',
            },
            {
                header: 'Точный расчет заработной платы, снижение ошибок и минимизация финансовых расхождений.',
                paragraph: 'Мониторинг рабочего времени на основе ежедневных, недельных и ежемесячных отчетов.',
            },
            {
                header: 'Обеспечение соблюдения трудового законодательства и нормативных актов.',
                paragraph: 'Удаленный доступ к отчетам о посещаемости нескольких филиалов.',
            },
            {
                header: 'Обеспечение эффективного управления отпусками, предотвращение проблем с недоукомплектованием.',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
            {
                header: 'Обеспечение непрерывного производства и распределения.',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
            {
                header: 'Обеспечение точности процессов возмещения.',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
            {
                header: 'Отслеживание заказов на ходу и завершений.',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
        ],
    },
    services: {
        industryHeading:
            'Упростите управление проектами и задачами, оптимизируйте распределение ресурсов, обеспечивая точные записи времени и посещаемости, помогая в обработке заработной платы и соблюдении стандартов.',
        featureHeader: 'Услуги',
        featureParagraph:
            'Функции, проверенные в полевых условиях, повышают продуктивность, минимизируют сложность и автоматизируют задачи, экономя время и ресурсы для вашего бизнеса.',
        keyPoints: [
            {
                header: 'GPS отслеживание сотрудников в полевых условиях в реальном времени',
                paragraph:
                    'Информация в реальном времени о присутствии или отсутствии лиц в зоне GeoFence или филиале.',
            },
            {
                header: 'Настраиваемые формы и фотографии с геотегами',
                paragraph: 'Мониторинг рабочего времени на основе ежедневных, недельных и ежемесячных отчетов.',
            },
            {
                header: 'Интуитивная панель и загружаемые отчеты',
                paragraph: 'Удаленный доступ к отчетам о посещаемости нескольких филиалов.',
            },
            {
                header: 'Управление и мониторинг сборов',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
        ],
    },
    fieldOperations: {
        industryHeading:
            'Обеспечьте принятие решений на основе данных и улучшите коммуникацию между полевыми командами и штаб-квартирой.',
        featureHeader: 'Полевые операции',
        featureParagraph:
            'Попрощайтесь с неэффективными полевыми операциями и управлением услугами с помощью комплексного решения TraceForce. Оптимизируйте управление задачами, отслеживайте посещаемость на ходу, используйте AI для оптимизации маршрутов, чтобы повысить продуктивность и доходы от продаж.',
        keyPoints: [
            {
                header: 'Расписание и мониторинг задач',
                paragraph:
                    'Информация в реальном времени о присутствии или отсутствии лиц в зоне GeoFence или филиале.',
            },
            {
                header: 'Отслеживание реального местоположения всех полевых сотрудников',
                paragraph: 'Мониторинг рабочего времени на основе ежедневных, недельных и ежемесячных отчетов.',
            },
            {
                header: 'Подтверждение и обработка заявок на возмещение расходов',
                paragraph: 'Удаленный доступ к отчетам о посещаемости нескольких филиалов.',
            },
            {
                header: 'Установка целей SLA и мониторинг задержек',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
            {
                header: 'Интуитивная панель и загружаемые отчеты',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
            {
                header: 'Завершение задач с подтверждением OTP',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
        ],
    },
    logisticsAndTransportation: {
        industryHeading:
            'Обеспечьте принятие решений на основе данных и улучшите коммуникацию между полевыми командами и штаб-квартирой.',
        featureHeader: 'Логистика и транспорт',
        featureParagraph:
            'Упростите и организуйте логистику и доставку более простым способом. Управляйте операциями логистики большой рабочей силы с помощью его мощных и передовых функций.',
        keyPoints: [
            {
                header: 'Получите отслеживание местоположения доставщика в реальном времени.',
                paragraph:
                    'Информация в реальном времени о присутствии или отсутствии лиц в зоне GeoFence или филиале.',
            },
            {
                header: 'Улучшите операционную эффективность, отслеживая действия и рабочие процессы.',
                paragraph: 'Мониторинг рабочего времени на основе ежедневных, недельных и ежемесячных отчетов.',
            },
            {
                header: 'Упростите управление задачами рабочей силы.',
                paragraph: 'Удаленный доступ к отчетам о посещаемости нескольких филиалов.',
            },
            {
                header: 'Анализируйте исторические данные и информацию о трафике в реальном времени для оптимизации маршрутов доставки.',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
            {
                header: 'Автоматизированная обработка возмещений и заработной платы.',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
            {
                header: 'Лучшее обслуживание клиентов с автоматизированными полевыми работами.',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с посещаемостью, в одной централизованной платформе или системе.',
            },
        ],
    },
};
export default industries;
