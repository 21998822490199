const products = {
    attendanceManagement: {
        productName: 'অ্যাটেনডেন্স ম্যানেজমেন্ট',
        titleDescription: 'সহজ সেটআপ, স্বয়ংক্রিয় চেক ইন এবং চেক আউট, রিপোর্ট',
        productCards: [
            {
                title: 'ওয়ার্ক-লাইফ ব্যালান্স',
                data: 'ট্রেসফোর্সের স্মরণকারীগুলির মাধ্যমে আপনার কর্মচারীদের কর্মজীবন এবং ব্যক্তিগত জীবনের ব্যালান্স নিশ্চিত করুন।',
            },
            {
                title: 'সদস্যের ধরন',
                data: 'এক্সিকিউটিভ ম্যানেজার, প্রজেক্ট ম্যানেজার, টিম ম্যানেজার, এবং প্রজেক্ট ভিউয়ারদের মতো সদস্যের ভূমিকা সহ কর্মস্থল ব্যবস্থাপনা সহজ করুন।',
            },
            {
                title: 'সাহায্য ট্র্যাক করতে',
                data: 'ট্রেসফোর্সের স্মার্ট টাইমশীটগুলি কাজের সময়, অলস সময়, এবং উৎপাদনশীল সময়ের বিবরণ সহ একটি বিস্তারিত শিট স্টাইলে ট্র্যাকিং সহজ করে তোলে।',
            },
        ],
        whyChooseSubCaption:
            'একটি অত্যন্ত দক্ষ কর্মক্ষেত্র পরিবেশের দিকে পরিবর্তন করুন এবং উন্নত উৎপাদনশীলতা এবং সহযোগিতা অনুভব করুন। কার্ডে তথ্য কেন্দ্রিত করুন।',
        headerCaption: 'কেন ট্রেসফোর্স অ্যাটেনডেন্স ম্যানেজমেন্ট নির্বাচন করবেন',
        headerT1: 'অ্যাটেনডেন্স ট্র্যাকিং বৈশিষ্ট্য যা আরও অনেক কিছু করে',
        subCaptionT1:
            'কর্মচারী ট্র্যাকিং, দূরবর্তী কাজ মনিটরিং, উৎপাদনশীলতা মনিটরিং, অ্যাটেনডেন্স ম্যানেজমেন্ট, টাইমশীট এবং আরও অনেক কিছু অভিজ্ঞতা লাভ করুন — উন্নত কার্যকারিতার স্তরের সাথে।',
        features: {
            attendanceMonitoring: {
                featureHeader: 'অ্যাটেনডেন্স মনিটরিং',
                featureDescription:
                    'জিওফেন্স এলাকা বা শাখার মধ্যে ব্যক্তিদের উপস্থিতি বা অনুপস্থিতি সম্পর্কে রিয়েল-টাইম clock in/clock out তথ্য।',
                featureParagraph:
                    'আর সময়কার্ড, অতিরিক্ত কাগজপত্র এবং বিভিন্ন ডকুমেন্টস চেক করার প্রয়োজন নেই। ওয়েবওয়ার্ক টাইম ট্র্যাকার সহ অ্যাটেনডেন্স মনিটরিং প্রক্রিয়া স্বয়ংক্রিয় করুন এবং একটি সু-সংগঠিত ফর্ম্যাটে আপনার কর্মচারীদের কর্মঘণ্টার শুরু এবং শেষ দেখুন।',
                fIIPoints: [
                    'জিওফেন্স এলাকা বা শাখার মধ্যে ব্যক্তিদের উপস্থিতি বা অনুপস্থিতি সম্পর্কে রিয়েল-টাইম তথ্য',
                    'দৈনিক, সাপ্তাহিক এবং মাসিক ভিত্তিতে কাজের ঘন্টাগুলি মনিটর করুন',
                    'বহু শাখার অ্যাটেনডেন্স রিপোর্ট দূরবর্তীভাবে অ্যাক্সেস করুন',
                    'একক, কেন্দ্রীভূত প্ল্যাটফর্মে অ্যাটেনডেন্স সম্পর্কিত তথ্য, প্রক্রিয়া, এবং সিস্টেমের একীকরণ',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'রিয়েল টাইম অ্যাটেনডেন্স',
                        paragraph:
                            'জিওফেন্স এলাকা বা শাখার মধ্যে ব্যক্তিদের উপস্থিতি বা অনুপস্থিতি সম্পর্কে রিয়েল-টাইম তথ্য',
                    },
                    totalWorkingHoursCalculation: {
                        header: 'মোট কর্মঘণ্টার হিসাব',
                        paragraph: 'দৈনিক, সাপ্তাহিক এবং মাসিক ভিত্তিতে কাজের ঘন্টাগুলি মনিটর করুন',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'দূরবর্তী অ্যাক্সেস অ্যাটেনডেন্স রিপোর্ট',
                        paragraph: 'বহু শাখার অ্যাটেনডেন্স রিপোর্ট দূরবর্তীভাবে অ্যাক্সেস করুন',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'অ্যাটেনডেন্স মনিটরিং এক',
                        description: [
                            'কর্মচারীদের সময়মত উপস্থিতির স্বচ্ছতা লাভ করুন',
                            'অতিরিক্ত কোন পদক্ষেপ ছাড়াই সময় বাঁচান এবং কর্মচারীর অ্যাটেনডেন্স সংগঠিত করুন।',
                            'সহজে মনিটর করুন আপনার কর্মচারীরা কর্মস্থলে সময়মতো উপস্থিত হয়েছেন কিনা এবং তাদের কর্মঘণ্টা সম্পন্ন করেছেন কিনা।',
                            'আপনার কর্মস্থল অপারেশনগুলি আরও সংগঠিত রাখুন এবং একটি উচ্চ দায়িত্বশীল কাজের পরিবেশ নিশ্চিত করুন।',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'অ্যাটেনডেন্স মনিটরিং দুই',
                        description: [
                            'অ্যাটেনডেন্স ডেটার বুদ্ধিমান বিশ্লেষণ',
                            'প্রাথমিকভাবে আসা, দেরি করা, এবং কম ট্র্যাকিং ফিল্টার করুন যা আপনি ড্রপ-ডাউন তালিকা থেকে নির্বাচন করতে পারেন।',
                            'এটি আপনাকে স্বয়ংক্রিয়ভাবে আপনার পছন্দসই তথ্য তৈরি করতে সহায়তা করবে যা আপনাকে ম্যানুয়ালি অনুসন্ধান করতে হবে না।',
                            'আপনি দৈনিক ভিত্তিতে অ্যাটেনডেন্স ট্র্যাক করতে পারেন অথবা ক্যালেন্ডার থেকে একটি ইন্টারভ্যাল নির্বাচন করতে পারেন।',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'অ্যাটেনডেন্স মনিটরিং তিন',
                        description: [
                            'একটি ১০০% টাচলেস সিস্টেম সহ নিরাপদ এবং স্বচ্ছভাবে কর্মচারীর অ্যাটেনডেন্স রিয়েল-টাইমে ট্র্যাক করুন।',
                            'সহজ সেটআপ',
                            'স্বয়ংক্রিয় চেক ইন এবং আউটের বিস্তারিত রিপোর্ট',
                            'ট্র্যাকিং টাইম সহ স্ক্রীনশট',
                            'ক্রিয়াকলাপ স্তরের রেকর্ডিং',
                            'অ্যাপস এবং ওয়েবসাইট ব্যবহারের মনিটরিং',
                        ],
                    },
                },
            },
            shiftManagement: {
                featureHeader: 'শিফট ম্যানেজমেন্ট',
                featureDescription:
                    'উৎপাদনশীলতা বাড়াতে, অপারেশনাল চাহিদা পূরণ করতে, এবং বিভিন্ন সময়ের মধ্যে কভারেজ নিশ্চিত করতে কাজের সময় সংগঠিত করুন এবং সূচী তৈরি করুন।',
                fIIPoints: [
                    'একটি পরিষ্কার এবং ভাল-সংগঠিত শিফট সূচী তৈরি করুন। আপনার ব্যবসার প্রয়োজনীয়তার উপর ভিত্তি করে বিভিন্ন ধরনের শিফট নির্ধারণ করুন।',
                    'বিভিন্ন শিফটে প্রয়োজনীয় কর্মচারীর সংখ্যা নির্ধারণ করতে কাজের বোঝা এবং কাজ বিশ্লেষণ করুন',
                    'একটি কেন্দ্রীভূত প্ল্যাটফর্ম যেখানে কর্মচারীরা তাদের সময়সূচী অ্যাক্সেস করতে পারে, এবং কোনো আপডেট বা পরিবর্তনের জন্য একটি নির্ভরযোগ্য যোগাযোগ চ্যানেল স্থাপন করুন।',
                    'একক প্ল্যাটফর্ম থেকে কর্মচারীর সময়সূচী নিয়ন্ত্রণ এবং তত্ত্বাবধানের কেন্দ্রীভূত নিয়ন্ত্রণ, যা ম্যানেজারদের বিভিন্ন অবস্থানে শিফট বরাদ্দ, অ্যাটেনডেন্স ট্র্যাক এবং কর্মশক্তির সম্পদ পরিচালনা করতে সক্ষম করে।',
                ],
            },
            geoFenceBasedManagement: {
                featureHeader: 'জিও ফেন্স ভিত্তিক অ্যাটেনডেন্স',
                featureDescription:
                    'পূর্বনির্ধারিত ভৌগলিক সীমানার মধ্যে কর্মচারীর উপস্থিতি মনিটর এবং যাচাই করার জন্য অবস্থান-ভিত্তিক প্রযুক্তি।',
                fIIPoints: [
                    'সঠিক অ্যাটেনডেন্স রেকর্ড নিশ্চিত করতে কর্মচারীরা তাদের নির্ধারিত কাজের অবস্থানে উপস্থিত আছেন কিনা তা নিশ্চিত করুন।',
                    'কর্মচারীদের অবস্থান মনিটর করতে রিয়েল-টাইম ট্র্যাকিং প্রয়োগ করুন।',
                    'কর্মস্থল বা নির্ধারিত উপস্থিতি অবস্থানের চারপাশে নির্দিষ্ট ভৌগলিক সীমানা, যা জিও-ফেন্স নামে পরিচিত, স্থাপন করুন।',
                    'যখন কর্মচারীরা নির্ধারিত জিও-ফেন্স সীমানা প্রবেশ বা ত্যাগ করে তখন ম্যানেজার বা এইচআর কর্মীদের জানাতে সতর্কতা বা বিজ্ঞপ্তি সেট আপ করুন।',
                ],
            },
            multipleClockInOptions: {
                featureHeader: 'একাধিক ক্লক ইন বিকল্প',
                featureDescription:
                    'বায়োমেট্রিক স্ক্যানার, মোবাইল অ্যাপস, টাইম ক্লক এবং ওয়েব-ভিত্তিক প্ল্যাটফর্ম ব্যবহার করে কর্মচারীদের তাদের কাজের সময় রেকর্ড করার জন্য নমনীয়তা প্রদান করুন।',
                fIIPoints: [
                    'একাধিক অবস্থানের সাথে সংগঠনের জন্য উপযুক্ত নমনীয় ক্লক ইন বিকল্পের জন্য মাঠে এবং মাঠের বাইরে বিভিন্ন শাখার অ্যাটেনডেন্স ব্যবস্থাপনা',
                    'কর্মচারীদের একটি সেলফ-সার্ভিস পোর্টালে অ্যাটেনডেন্স রেকর্ড অ্যাক্সেস প্রদান করুন এবং জিওফেন্সের মধ্যে তাদের চেক-ইন এবং চেক-আউট পরিচালনা করুন।',
                    'নমনীয় ক্লক-ইন বিকল্পগুলি বায়োমেট্রিক স্ক্যান, মোবাইল অ্যাপস, আরএফআইডি কার্ড এবং ফেসিয়াল রিকগনিশন অন্তর্ভুক্ত করে, বিভিন্ন কাজের পরিবেশ জুড়ে সহজ সময় ট্র্যাকিং নিশ্চিত করে।',
                    'অফিসের premises এর পরিসরের মধ্যে কর্মচারীদের জন্য অটো ক্লক ইন অ্যাটেনডেন্স মার্কিং সহ সতর্কতা বিজ্ঞপ্তি।',
                ],
            },
            attendanceReport: {
                featureHeader: 'অ্যাটেনডেন্স রিপোর্ট',
                featureDescription:
                    'একটি নির্দিষ্ট সময়সীমার মধ্যে কর্মচারীর অ্যাটেনডেন্স তথ্য, কাজের ঘন্টা, অনুপস্থিতি, দেরি আগমন এবং প্রারম্ভিক প্রস্থান সারাংশ প্রদান করুন।',
                fIIPoints: [
                    'মোট কাজের দিন, মোট কাজের ঘন্টা এবং কোনো অতিরিক্ত ঘন্টা সহ অ্যাটেনডেন্সের একটি সামগ্রিক সারাংশ প্রদান করুন।',
                    'স্বনির্ধারণযোগ্য বিভাগ সহ একাধিক শাখার অ্যাটেনডেন্স রিপোর্ট দূরবর্তীভাবে অ্যাক্সেস করুন।',
                    'প্রতি কর্মচারীর জন্য ছুটির ব্যালেন্স প্রদর্শন করুন, যেমন ছুটি, অসুস্থ ছুটি, এবং অন্যান্য প্রকারের ছুটি যা সংগ্রহ করা এবং ব্যবহার করা হয়েছে।',
                    'বিভাগ, টিম বা পৃথক কর্মচারী হিসাবে নির্দিষ্ট মানদণ্ডের উপর ভিত্তি করে রিপোর্ট কাস্টমাইজ করার অনুমতি দিন।',
                ],
            },
        },
    },
    fieldTrackingManagement: {
        productName: 'ফিল্ড ফোর্স ম্যানেজমেন্ট',
        titleDescription: 'বিক্রয় এবং ফিল্ড সার্ভিস অপারেশনস লাইভ রিপোর্টিং এবং ভিজিট রাউটিং সহ',
        productCards: [
            {
                title: 'অপারেশনাল খরচ কমানো',
                data: 'কর্মচারীদের সময়সূচি স্বয়ংক্রিয় করে অপারেশনাল খরচ কমান। কর্মচারীর সময়, কাজ এবং খরচ ট্র্যাক করুন যাতে আপনি অর্থ সঞ্চয়ের সুযোগ চিহ্নিত করতে পারেন।',
            },
            {
                title: 'গ্রাহকের জন্য অসাধারণ মূল্য',
                data: 'একটি সময়সূচী এবং পরিষেবা দক্ষতা গ্যারান্টি নির্ধারণ করুন। গ্রাহকের সাথে যোগাযোগ এবং প্রতিক্রিয়া ট্র্যাক করুন যাতে সহজেই গ্যাপ এবং উন্নতির ক্ষেত্র চিহ্নিত করতে পারেন।',
            },
            {
                title: 'পারফরমেন্স অ্যানালিসিস',
                data: 'একত্রিত ড্যাশবোর্ডে বিস্তৃত রিপোর্ট তৈরি করুন, যা আপনার কর্মশক্তির পারফরম্যান্স, কাজের সময়সূচী, গ্রাহক সন্তুষ্টি এবং উন্নতির ক্ষেত্র সম্পর্কে মূল্যবান অন্তর্দৃষ্টি প্রদান করে।',
            },
        ],
        whyChooseSubCaption:
            'ফিল্ড ফোর্স ম্যানেজমেন্ট সফটওয়্যার দিয়ে সর্বাধিক দক্ষতা\nউপশিরোনাম - আপনার মাঠ কর্মীদের রিয়েল-টাইমে ট্র্যাক করুন, কাজের অগ্রগতি পর্যবেক্ষণ করুন, এবং তাদের অবস্থান, কার্যকলাপ এবং পারফরম্যান্স সম্পর্কে অন্তর্দৃষ্টি অর্জন করুন। দায়িত্ব বাড়ান এবং ডেটা-চালিত সিদ্ধান্ত গ্রহণ ব্যবহার করুন।',
        headerCaption: 'ট্রেসফোর্স ফিল্ড ফোর্স ম্যানেজমেন্ট কেন বেছে নেবেন',
        headerT1: 'ফিল্ড ফোর্স ম্যানেজমেন্ট সিস্টেমের প্রধান বৈশিষ্ট্য',
        subCaptionT1:
            'রিয়েল-টাইম কর্মচারী অবস্থান ট্র্যাকিং এবং AI-ভিত্তিক অপ্টিমাইজেশন ব্যবহার করে অপারেশনস সরলীকরণ করুন, তাত্ক্ষণিক অন্তর্দৃষ্টির জন্য লাইভ রিপোর্টিং এবং পারফরম্যান্স বাড়ানোর জন্য ফিল্ড সেলস অ্যানালিসিস ব্যবহার করুন। কার্যকরী সম্পদের বরাদ্দ নিশ্চিত করুন, উৎপাদনশীলতা বৃদ্ধি করুন এবং ডেটা-চালিত সিদ্ধান্ত গ্রহণ নিশ্চিত করুন।',
        features: {
            workforceTracking: {
                featureHeader: 'কর্মশক্তি ট্র্যাকিং',
                featureDescription:
                    'কর্মচারীর কার্যক্রম, উৎপাদনশীলতা এবং উপস্থিতির সিস্টেম্যাটিক মনিটরিং এবং ব্যবস্থাপনা।',
                featureParagraph:
                    'আরও সময়কার্ড, অতিরিক্ত কাগজপত্র, এবং কর্মচারীর উপস্থিতি পরীক্ষা করার জন্য বিভিন্ন নথির প্রয়োজন নেই। WebWork Time Tracker দিয়ে উপস্থিতি মনিটরিং প্রক্রিয়া স্বয়ংক্রিয় করুন এবং আপনার কর্মচারীদের কাজের সময় শুরু এবং শেষ একটি সুশৃঙ্খল বিন্যাসে দেখুন।',
                fIIPoints: [
                    'ইভেন্ট যেমন চেক-ইন এবং চেক-আউট মনিটর করুন এবং কর্মচারীদের তাদের কার্যক্রম, কর্মক্ষমতার মেট্রিক্স, বা ইভেন্ট সম্পর্কে অন্তর্দৃষ্টি প্রদান করুন।',
                    'মিটিং এবং কাজের জন্য ডেটা সংরক্ষণ করুন যাতে ট্রেন্ড ট্র্যাক করা যায় এবং মিটিং মিনিটের জন্য ডেটা-চালিত সিদ্ধান্ত গ্রহণ করা যায়।',
                    'ক্লায়েন্ট এবং লিড ব্যবস্থাপনা কর্মচারীদের উৎপাদনশীলতা এবং কর্মচারীদের দ্বারা সম্পন্ন কাজের পরিমাণের উপর ভিত্তি করে মূল পারফরম্যান্স সূচক ট্র্যাক করতে।',
                    'দৈনিক, সাপ্তাহিক এবং মাসিক ভিত্তিতে ফিল্ড কাজের জন্য কর্মচারীদের দ্বারা চলা সঠিক দূরত্ব ট্র্যাক করুন এবং দূরত্ব এবং ভ্রমণ স্থান অনুযায়ী খরচ ফেরত দিন।',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'রিয়েল-টাইম টাইমলাইন-ভিত্তিক উপস্থিতি ট্র্যাকিং',
                        paragraph:
                            'কর্মচারীর উপস্থিতি এবং গতিবিধি মনিটর করুন, চেক-ইন এবং চেক-আউটের মতো ইভেন্ট, এবং তাদের কার্যক্রম, কর্মক্ষমতার মেট্রিক্স, বা ইভেন্ট সম্পর্কে অন্তর্দৃষ্টি প্রদান করুন।',
                    },
                    customFormsforDataManagement: {
                        header: 'ডেটা ব্যবস্থাপনার জন্য কাস্টম ফর্ম',
                        paragraph: 'দৈনিক, সাপ্তাহিক এবং মাসিক ভিত্তিতে কাজের সময় ট্র্যাক করুন।',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'রিমোট অ্যাক্সেস উপস্থিতি রিপোর্ট',
                        paragraph: 'একাধিক শাখা থেকে দূরবর্তীভাবে উপস্থিতি রিপোর্ট অ্যাক্সেস করুন।',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'উপস্থিতি মনিটরিং এক',
                        description: [
                            'কর্মচারী সময়ানুবর্তিতা সম্পর্কে স্বচ্ছতা অর্জন করুন',
                            'অতিরিক্ত পদক্ষেপ ছাড়াই সময় বাঁচান এবং কর্মচারী উপস্থিতি সংগঠিত করুন।',
                            'সহজেই মনিটর করুন আপনার কর্মচারীরা সময়মতো কাজে পৌঁছেছে কিনা এবং তাদের কাজের সময় সম্পন্ন করেছে কিনা।',
                            'আপনার কাজের কার্যক্রম আরও সুসংগঠিত রাখুন এবং একটি অত্যন্ত দায়িত্বশীল কাজের পরিবেশ বজায় রাখুন।',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'উপস্থিতি মনিটরিং দুই',
                        description: [
                            'উপস্থিতি ডেটার বুদ্ধিমান বিশ্লেষণ',
                            'এয়ারলি এক্সিট, লেট অ্যারাইভাল, এবং নিম্ন ট্র্যাকিং এর জন্য ড্রপডাউন তালিকা থেকে নির্বাচন করার জন্য ফিল্টার।',
                            'এটি আপনাকে দ্রুত প্রয়োজনীয় তথ্য তৈরি করতে সাহায্য করবে যা আপনাকে ম্যানুয়ালি খুঁজতে হবে না।',
                            'আপনি দৈনিক উপস্থিতি ট্র্যাক করতে পারেন বা ক্যালেন্ডার থেকে একটি পরিসর নির্বাচন করতে পারেন।',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'উপস্থিতি মনিটরিং তিন',
                        description: [
                            'একটি নিরাপদ এবং স্বচ্ছ পদ্ধতি রিয়েল-টাইমে কর্মচারী উপস্থিতি মনিটর এবং ট্র্যাক করার জন্য একটি ১০০% টাচলেস সিস্টেমের সাথে।',
                            'সহজ সেটআপ',
                            'অটোমেটেড বিস্তারিত চেক-ইন এবং চেক-আউট',
                            'ট্র্যাকিং টাইম সহ স্ক্রীনশট',
                            'ক্রিয়াকলাপ স্তরের রেকর্ডিং',
                            'অ্যাপ্লিকেশন এবং ওয়েবসাইট ব্যবহারের মনিটরিং',
                        ],
                    },
                },
            },
            fieldSalesAnalytics: {
                featureHeader: 'ফিল্ড সেলস অ্যানালিটিক্স',
                featureDescription:
                    'ফিল্ড সেলস দলের প্রধান মেট্রিক্স ট্র্যাক এবং বিশ্লেষণ করুন, যেমন বিক্রয় রাজস্ব, গ্রাহক যোগাযোগ, এবং ভৌগলিক প্রবণতা।',
                featureParagraph:
                    'আরও সময়কার্ড, অতিরিক্ত কাগজপত্র, এবং কর্মচারীর উপস্থিতি পরীক্ষা করার জন্য বিভিন্ন নথির প্রয়োজন নেই। WebWork Time Tracker দিয়ে উপস্থিতি মনিটরিং প্রক্রিয়া স্বয়ংক্রিয় করুন এবং আপনার কর্মচারীদের কাজের সময় শুরু এবং শেষ একটি সুশৃঙ্খল বিন্যাসে দেখুন।',
                fIIPoints: [
                    'মূল পারফরম্যান্স সূচকগুলি ডেটা-চালিত সিদ্ধান্ত গ্রহণে সহায়তা করতে পারে, যা পরবর্তী সময়ের জন্য কার্যকরী সিদ্ধান্ত নিতে বা কৌশল সংশোধন করতে সহায়ক হতে পারে।',
                    'বিক্রয়, লিড এবং গ্রাহক অধিগ্রহণ সম্পর্কে দৈনিক, সাপ্তাহিক বা মাসিক ভিত্তিতে রিপোর্ট অ্যাক্সেস করা যেতে পারে, যা কর্মচারীদের উৎপাদনশীলতা এবং দক্ষতায় সহায়ক হতে পারে।',
                    'সঠিকতার জন্য জিওফেন্সিং-ভিত্তিক চেক-ইন দ্বারা পরিদর্শন যাচাই করুন। শেয়ার করার সময় অবস্থান কনফারেন্সগুলি একটি ক্যামেরা ফটোতে সংযুক্ত করা যেতে পারে।',
                    'ভিজিট রাউটিং ব্যবহার করে ভিজিট পরিকল্পনা করুন বা কর্মচারীদের ক্লায়েন্টদের জন্য ভিজিট পরিকল্পনা তৈরি করুন। অপটিমাইজড নেভিগেশন জন্য আপনার ভিজিট পরিকল্পনাটি Maps-এ সরাসরি ইন্টিগ্রেট করুন।',
                ],
            },
            locationBasedAttendance: {
                featureHeader: 'অবস্থানভিত্তিক উপস্থিতি',
                featureDescription:
                    'জিওফেন্সিং-ভিত্তিক উপস্থিতি নিশ্চিত করতে যে কর্মচারীরা কেবলমাত্র পূর্বনির্ধারিত ভৌগলিক সীমানার মধ্যে থাকা অবস্থায় ক্লক ইন বা আউট করতে পারে।',
                fIIPoints: [
                    'নির্দিষ্ট অফিস অবস্থানগুলির চারপাশে জিওফেন্সড ভার্চুয়াল সীমা প্রয়োগ করুন। একটি নির্ধারিত এলাকা থেকে কর্মচারী প্রবেশ বা বেরিয়ে যাওয়ার ভিত্তিতে উপস্থিতি স্বয়ংক্রিয়করণে সহায়তা।',
                    'উপস্থিতি ডেটার সঠিকতা এবং নিরাপত্তা নিশ্চিত করতে PIN, বায়োমেট্রিক্স বা মুখমণ্ডল স্বীকৃতি ব্যবহার করে প্রমাণীকরণ পদ্ধতি।',
                    'উপস্থিতি রেকর্ডগুলি দ্রুত আপডেট নিশ্চিত করুন, যাতে সময়মতো ব্যবস্থাপনার সিদ্ধান্ত নেওয়া যায়।',
                    'দূরবর্তী কর্মীদের জন্য উপস্থিতি ট্র্যাকিংয়ের বিকল্প পদ্ধতি বিবেচনা করুন।',
                ],
            },
            liveReporting: {
                featureHeader: 'লাইভ রিপোর্টিং',
                featureDescription:
                    'কাজের বিভিন্ন দিক যেমন উপস্থিতি, সম্পন্ন কাজ, বা প্রকল্পের অগ্রগতি সম্পর্কে রিয়েল-টাইম ট্র্যাকিং এবং বিশ্লেষণ।',
                fIIPoints: [
                    'অ্যাডমিন বা ম্যানেজার তাদের প্রাপ্ত বা বাতিল করার জন্য বিজ্ঞপ্তি সাবস্ক্রাইব বা অপ্ট-আউট করতে পারেন। এটি আপনাকে এমন বিজ্ঞপ্তিগুলিতে মনোযোগ কেন্দ্রীভূত করতে সাহায্য করবে যা আপনি সত্যিই পেতে চান।',
                    'উপস্থিতি এবং অনুপস্থিতির বিষয়ে সচেতন থাকুন, এক ধাপ এগিয়ে থাকুন এবং কর্মী সংখ্যা পূর্বেই জানুন যাতে দিনের পরিকল্পনা আরও উৎপাদনশীল এবং কার্যকরী হতে পারে।',
                    'সমস্ত অনুমোদনের ট্র্যাকিং বজায় রাখুন, সমস্ত অনুমোদন ট্র্যাক করুন, প্রতিস্থাপন থেকে ছুটি পর্যন্ত এবং পরে সেগুলি দেখুন।',
                    'ফর্ম এবং মিটিং মিনিটের মাধ্যমে রিমোট ট্র্যাকিং, কাস্টম ফর্ম বিশেষ ডেটা অর্জনে সাহায্য করতে পারে যা দূরবর্তীভাবে অ্যাক্সেস করা যেতে পারে।',
                ],
            },
            aIBasedOptimization: {
                featureHeader: 'AI-ভিত্তিক অপ্টিমাইজেশন',
                featureDescription:
                    'AI-অপ্টিমাইজড রুট পরিকল্পনার মাধ্যমে ফিল্ড প্রতিনিধিরা বা বিক্রয় কর্মীদের জন্য রুট পরিকল্পনা অপ্টিমাইজ এবং স্বয়ংক্রিয় করুন।',
                fIIPoints: [
                    'লাইভ ট্রাফিক আপডেট, আবহাওয়ার অবস্থার এবং রাস্তার বন্ধের মতো রিয়েল-টাইম ডেটা সোর্স ব্যবহার করে বর্তমান পরিস্থিতির উপর ভিত্তি করে রুটগুলি গতিশীলভাবে সামঞ্জস্য করুন, দৈনন্দিন পরিকল্পনায় সহায়তা করুন।',
                    'অপ্টিমাইজড রুটগুলির ভিজুয়ালাইজেশন করুন এবং প্রয়োজনীয় পরিবর্তনগুলি করুন। এটি একটি মোবাইল অ্যাপ্লিকেশন বা ওয়েব-ভিত্তিক প্ল্যাটফর্মের অংশ হতে পারে।',
                    'AI সিস্টেম বিভিন্ন রুট বা পথের অনন্য বৈশিষ্ট্যগুলি বিবেচনায় নিতে পারে।',
                    'ম্যাপিং সার্ভিস এবং ভৌগলিক ডেটা ব্যবহার করে রোড নেটওয়ার্ক, ট্রাফিক শর্ত এবং অন্যান্য অবস্থান-সম্পর্কিত তথ্য বুঝতে পারেন।',
                ],
            },
            taskManagement: {
                featureHeader: 'টাস্ক ম্যানেজমেন্ট',
                featureDescription:
                    'নির্দিষ্ট লক্ষ্য বা উদ্দেশ্য অর্জনের জন্য কাজগুলি সংগঠিত, অগ্রাধিকার এবং কার্যকরভাবে সম্পাদন করুন।',
                featureParagraph:
                    'আরও সময়কার্ড, অতিরিক্ত কাগজপত্র, এবং কর্মচারীর উপস্থিতি পরীক্ষা করার জন্য বিভিন্ন নথির প্রয়োজন নেই। WebWork Time Tracker দিয়ে উপস্থিতি মনিটরিং প্রক্রিয়া স্বয়ংক্রিয় করুন এবং আপনার কর্মচারীদের কাজের সময় শুরু এবং শেষ একটি সুশৃঙ্খল বিন্যাসে দেখুন।',
                fIIPoints: [
                    'টাস্ক বোর্ডগুলি দেখে, টাস্ক স্ট্যাটাস চেক করে এবং ব্যয় করা সময় ট্র্যাক করে বাস্তব-সময়ে অগ্রগতি পর্যবেক্ষণ করুন।',
                    'টাস্ক ম্যানেজমেন্টে সহযোগিতা বৈশিষ্ট্যগুলি দলের সদস্যদের সাথে রিয়েল-টাইমে যোগাযোগ করার জন্য। টাস্কে মন্তব্য করুন, প্রশ্ন জিজ্ঞাসা করুন এবং আপডেট প্রদান করুন যাতে সবাই অবহিত থাকে।',
                    'ক্লায়েন্ট এবং লিড ব্যবস্থাপনা কর্মচারীদের উৎপাদনশীলতা এবং কর্মচারীদের দ্বারা সম্পন্ন কাজের পরিমাণের উপর ভিত্তি করে মূল পারফরম্যান্স সূচক ট্র্যাক করতে।',
                    'লক্ষ্য, প্রকল্পের উদ্দেশ্য, বিভাগীয় লক্ষ্য, ব্যক্তিগত পারফরম্যান্স লক্ষ্য, কৌশলগত উদ্যোগ ইত্যাদি সংগঠিত করার জন্য ঐতিহাসিক কাঠামো।',
                ],
            },
        },
    },

    payrollAndLeaveManagement: {
        productName: 'পেরোল এবং ছুটি ব্যবস্থাপনা',
        titleDescription: 'সহজ এবং দক্ষভাবে কর্মচারীদের পেরোল এবং ছুটি পরিচালনা করুন',
        productCards: [
            {
                title: 'স্বয়ংক্রিয় পেরোল প্রসেসিং',
                data: 'সঠিক পেরোল গণনা এবং ত্রুটিহীন পরিশোধ নিশ্চিত করতে স্বয়ংক্রিয় পেরোল প্রসেসিং ব্যবহার করুন। ট্যাক্স এবং অন্যান্য কর্তনগুলির অটোমেশন সহজ করে তোলে।',
            },
            {
                title: 'উন্নত ছুটি ব্যবস্থাপনা',
                data: 'কর্মচারীদের ছুটির প্রার্থনা এবং অনুমোদন কার্যকরভাবে পরিচালনা করুন, এবং সুস্পষ্টভাবে ট্র্যাক করুন ছুটির ধরণ ও লেনদেনের জন্য।',
            },
            {
                title: 'সম্পূর্ণ রিপোর্টিং এবং অ্যানালিটিক্স',
                data: 'বিস্তারিত রিপোর্ট এবং বিশ্লেষণ তৈরি করুন যা পেরোল এবং ছুটির ব্যবস্থাপনা সম্পর্কিত মূল্যবান অন্তর্দৃষ্টি প্রদান করে।',
            },
        ],
        whyChooseSubCaption:
            'পেরোল এবং ছুটি ব্যবস্থাপনা সফটওয়্যার দিয়ে প্রক্রিয়া সহজ করুন\nউপশিরোনাম - কর্মচারীদের পেরোল এবং ছুটি পরিচালনার জন্য স্বয়ংক্রিয়তা এবং দক্ষতা নিশ্চিত করুন। সঠিক তথ্য, রিপোর্ট এবং বিশ্লেষণের মাধ্যমে সিদ্ধান্ত গ্রহণকে সহজ করুন।',
        headerCaption: 'পেরোল এবং ছুটি ব্যবস্থাপনা কেন বেছে নেবেন',
        headerT1: 'পেরোল এবং ছুটি ব্যবস্থাপনা সিস্টেমের প্রধান বৈশিষ্ট্য',
        subCaptionT1:
            'স্বয়ংক্রিয় পেরোল প্রসেসিং, উন্নত ছুটি ব্যবস্থাপনা এবং বিস্তারিত রিপোর্টিংয়ের সাথে আপনার মানব সম্পদ প্রক্রিয়া সুশৃঙ্খল করুন। দক্ষতা বৃদ্ধি করুন এবং সঠিক সিদ্ধান্ত গ্রহণের জন্য প্রয়োজনীয় অন্তর্দৃষ্টি পান।',
        features: {
            payrollAutomation: {
                featureHeader: 'পেরোল অটোমেশন',
                featureDescription: 'সঠিক এবং সময়মত পেরোল গণনা নিশ্চিত করতে অটোমেটেড সিস্টেম ব্যবহার করুন।',
                featureParagraph:
                    'পেরোল প্রসেসিংকে স্বয়ংক্রিয় করুন এবং ট্যাক্স, বোনাস, এবং অন্যান্য কর্তনগুলির সাথে ভুল এড়িয়ে যান। সঠিক এবং কার্যকরী পেরোল পরিচালনা নিশ্চিত করুন।',
                fIIPoints: [
                    'স্বয়ংক্রিয় গণনা এবং পেমেন্ট নিশ্চিত করতে পেরোল প্রসেসিং অটোমেট করুন।',
                    'ব্যক্তিগতকৃত ট্যাক্স কাটা এবং বোনাস হিসাব করুন।',
                    'অভ্যন্তরীণ অডিটের জন্য রিপোর্ট তৈরি করুন।',
                    'পেরোল সম্পর্কিত সমস্ত ডেটা নিরাপদে সংরক্ষণ করুন।',
                ],
            },
            leaveManagement: {
                featureHeader: 'ছুটি ব্যবস্থাপনা',
                featureDescription:
                    'কর্মচারীদের ছুটির আবেদন এবং অনুমোদন প্রক্রিয়া স্বয়ংক্রিয় করুন এবং তাদের ছুটির ব্যালেন্স ট্র্যাক করুন।',
                featureParagraph:
                    'ছুটির আবেদন এবং অনুমোদনের প্রক্রিয়া সহজ করুন এবং কর্মচারীদের ছুটির ব্যালেন্স, প্রকার এবং অনুরোধের রেকর্ড রাখুন। দক্ষ এবং সঠিক ছুটি ব্যবস্থাপনার জন্য একটি পূর্ণাঙ্গ সিস্টেম ব্যবহার করুন।',
                fIIPoints: [
                    'ছুটির আবেদন এবং অনুমোদন স্বয়ংক্রিয় করুন।',
                    'ছুটির ব্যালেন্স এবং টাইপ ট্র্যাক করুন।',
                    'ছুটির রেকর্ড সহজে অ্যাক্সেস করুন এবং আপডেট করুন।',
                    'মিটিং বা অন্যান্য কার্যকলাপের সাথে ছুটি মেলান।',
                ],
            },
            reportingAnalytics: {
                featureHeader: 'রিপোর্টিং এবং অ্যানালিটিক্স',
                featureDescription:
                    'বিস্তারিত রিপোর্ট তৈরি করুন এবং পেরোল এবং ছুটি ব্যবস্থাপনা সম্পর্কিত অন্তর্দৃষ্টি পান।',
                featureParagraph:
                    'পেরোল এবং ছুটির ব্যবস্থাপনা সম্পর্কিত বিশ্লেষণাত্মক রিপোর্ট তৈরি করুন যা কার্যকর সিদ্ধান্ত গ্রহণে সহায়তা করবে। সমস্ত প্রয়োজনীয় ডেটা এক জায়গায় সংগৃহীত করুন।',
                fIIPoints: [
                    'বিস্তারিত পেরোল এবং ছুটি রিপোর্ট তৈরি করুন।',
                    'ডেটার উপর ভিত্তি করে বিশ্লেষণ করুন এবং সিদ্ধান্ত গ্রহণ করুন।',
                    'নিরাপদ এবং সহজে অ্যাক্সেসযোগ্য রিপোর্ট সিস্টেম ব্যবহার করুন।',
                    'অভ্যন্তরীণ এবং বাহ্যিক অডিটের জন্য রিপোর্ট প্রস্তুত করুন।',
                ],
            },
        },
    },

    orderManagement: {
        productName: 'অর্ডার ব্যবস্থাপনা',
        titleDescription: 'স্কেলেবল এবং স্বয়ংক্রিয় সমাধানের মাধ্যমে অর্ডার/সংগ্রহ কার্যক্রম সহজ করুন।',
        headerCaption: 'কেন ট্রেসফোর্স লিড ম্যানেজমেন্ট CRM বেছে নেবেন',
        productCards: [
            {
                title: 'ব্যবস্থাপনার সহজতা',
                data: 'গ্রাহক তথ্য কেন্দ্রীভূত করে, রুটিন কাজ অটোমেট করে, এবং যোগাযোগ সহজ করে ব্যবস্থাপনা সহজ করুন।',
            },
            {
                title: 'কাস্টমাইজযোগ্যতা',
                data: 'কাস্টমাইজযোগ্যতা',
            },
            {
                title: 'ইন্টিগ্রেশন',
                data: 'ডেটা শেয়ারিং, কাজের স্বয়ংক্রিয়করণ, এবং দলের মধ্যে উন্নত সহযোগিতার জন্য ইন্টিগ্রেশন সক্ষম করুন, যা আরও কার্যকর এবং দক্ষ CRM বাস্তবায়নে সহায়ক।',
            },
        ],
        whyChooseSubCaption:
            'কার্যকরভাবে লিড ট্র্যাক এবং পুষ্ট করুন, নিশ্চিত করুন যে কোন সুযোগ মিস না হয় এবং রূপান্তর হার উন্নত করুন।',
        headerT1: 'ট্রেসফোর্স লিড ম্যানেজমেন্ট সিস্টেমের সুবিধাগুলি',
        subCaptionT1:
            'লিড ক্যাপচার অটোমেশন এবং বিতরণ\nরিয়েল-টাইম ডেটা ইন্টিগ্রেশন এবং রিপোর্ট\nবিক্রয় এবং বিপণন টুলস অটোমেশন',
        features: {
            digitizationOfOrder: {
                featureHeader: 'অর্ডারের ডিজিটাইজেশন',
                featureDescription:
                    'অর্ডার ব্যবস্থাপনাকে এক প্রান্ত থেকে অন্য প্রান্তে সহজ করুন এবং ম্যানুয়াল ভুল কমান',
                featureParagraph:
                    'আর সময়কার্ড, অতিরিক্ত কাগজপত্র, এবং কর্মচারীদের উপস্থিতি যাচাই করার জন্য বিভিন্ন ডকুমেন্টের প্রয়োজন নেই। WebWork Time Tracker এর মাধ্যমে উপস্থিতি পর্যবেক্ষণ প্রক্রিয়া স্বয়ংক্রিয় করুন এবং আপনার কর্মচারীদের কাজের সময়ের শুরু এবং শেষ একটি সু-সংগঠিত ফরম্যাটে দেখুন।',
                fIIPoints: [
                    'অর্ডার প্রসেসিং পারফরম্যান্স এবং কর্মচারীদের উৎপাদনশীলতা পর্যবেক্ষণ করতে বিস্তারিত রিপোর্ট এবং অ্যানালিটিকস তৈরি করুন।',
                    'কর্মচারী বা গ্রাহকদের দ্বারা প্রবেশ করা অর্ডার বিশদ স্বয়ংক্রিয়ভাবে ক্যাপচার এবং যাচাই করুন।',
                    'অর্ডার স্ট্যাটাস এবং শিপমেন্ট ট্র্যাকিং সম্পর্কিত রিয়েল-টাইম আপডেট প্রদান করুন।',
                    'অর্ডার অনুমোদন প্রক্রিয়া স্বয়ংক্রিয় করুন এবং উপযুক্ত কর্মচারীদের কাজ বরাদ্দ করুন।',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'টাইম লাইন ভিত্তিক উপস্থিতি ট্র্যাকিং',
                        paragraph:
                            'পূর্ববর্তী এবং পরবর্তীতে কর্মচারী কর্মক্ষমতা বা ইভেন্টের অন্তর্দৃষ্টি যেমন ক্লক ইন এবং ক্লক আউট পর্যবেক্ষণ করুন।',
                    },
                    customFormsforDataManagement: {
                        header: 'ডেটা ব্যবস্থাপনার জন্য কাস্টম ফর্ম',
                        paragraph: 'ডেইলি, উইকলি, এবং মনের জন্য কাজের সময় পর্যবেক্ষণ করুন।',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'রিমোট অ্যাক্সেস উপস্থিতি রিপোর্ট',
                        paragraph: 'একাধিক শাখার উপস্থিতি রিপোর্ট রিমোটলি অ্যাক্সেস করুন।',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'উপস্থিতি মনিটরিং ওয়ান',
                        description: [
                            'কর্মচারীদের সময়মত উপস্থিতির স্বচ্ছতা অর্জন করুন',
                            'কোনও অতিরিক্ত পদক্ষেপ ছাড়াই সময় বাঁচান এবং কর্মচারীদের উপস্থিতি সংগঠিত করুন।',
                            'সহজেই পর্যবেক্ষণ করুন যে আপনার কর্মচারীরা কর্মস্থলে সময়মতো পৌঁছেছে কিনা এবং তাদের কাজের সময় সম্পন্ন করেছে কিনা।',
                            'আপনার কর্মস্থলের কার্যক্রম আরও সংগঠিত রাখুন এবং একটি অত্যন্ত দায়ী কর্মপরিবেশ তৈরি করুন।',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'উপস্থিতি মনিটরিং টু',
                        description: [
                            'উপস্থিতির ডেটার বুদ্ধিমান বিশ্লেষণ',
                            'শুরু, শেষ সময় এবং কম ট্র্যাকিং সহ ফিল্টারগুলি ড্রপ-ডাউন তালিকায় নির্বাচন করুন।',
                            'এটি আপনাকে দ্রুত প্রয়োজনীয় তথ্য তৈরি করতে সাহায্য করবে যেন আপনাকে ম্যানুয়ালি খুঁজতে না হয়।',
                            'আপনি প্রতিদিনের ভিত্তিতে উপস্থিতি ট্র্যাক করতে পারেন অথবা ক্যালেন্ডার থেকে একটি সময়সীমা নির্বাচন করতে পারেন।',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'উপস্থিতি মনিটরিং থ্রি',
                        description: [
                            'একটি 100% টাচলেস সিস্টেমের মাধ্যমে রিয়েল-টাইমে কর্মচারীদের উপস্থিতি পর্যবেক্ষণ এবং ট্র্যাক করার একটি নিরাপদ এবং স্বচ্ছ উপায়।',
                            'সহজ সেটআপ',
                            'স্বয়ংক্রিয় চেক-ইন এবং চেক-আউট, বিস্তারিত রিপোর্ট',
                            'টাইম ট্র্যাকিং সহ স্ক্রিনশট',
                            'কার্যকলাপের স্তরের রেকর্ডিং',
                            'অ্যাপস এবং ওয়েবসাইট ব্যবহারের পর্যবেক্ষণ',
                        ],
                    },
                },
            },
            targetManagement: {
                featureHeader: 'টার্গেট ব্যবস্থাপনা',
                featureDescription:
                    'প্রত্যেক কর্মচারীর ভূমিকা এবং দক্ষতার ভিত্তিতে স্পষ্ট এবং অর্জনযোগ্য লক্ষ্য সেট করুন।',
                featureParagraph:
                    'আর সময়কার্ড, অতিরিক্ত কাগজপত্র, এবং কর্মচারীদের উপস্থিতি যাচাই করার জন্য বিভিন্ন ডকুমেন্টের প্রয়োজন নেই। WebWork Time Tracker এর মাধ্যমে উপস্থিতি পর্যবেক্ষণ প্রক্রিয়া স্বয়ংক্রিয় করুন এবং আপনার কর্মচারীদের কাজের সময়ের শুরু এবং শেষ একটি সু-সংগঠিত ফরম্যাটে দেখুন।',
                fIIPoints: [
                    'প্রতিদিন, সাপ্তাহিক, মাসিক লক্ষ্য অর্জন করুন এবং লাইভ ফিল্ড ট্র্যাকিংয়ের মাধ্যমে কর্মচারীদের অর্জনের রেকর্ড রাখুন।',
                    'রিয়েল-টাইম প্রোগ্রেস (অর্জিত লক্ষ্য সংখ্যা বনাম অবশিষ্ট), চেক-ইন/চেক-আউট টাইম, প্রতি কাজের জন্য সময় (স্থায়িত্ব) এবং অবস্থান ট্র্যাকিং।',
                    'পারফরম্যান্স মেট্রিক্স (সম্পন্ন হার, সময় নেওয়া, গ্রাহক সন্তুষ্টি স্কোর), লক্ষ্য বনাম বাস্তব কর্মক্ষমতা (বাস্তব পারফরম্যান্স বনাম লক্ষ্য), ঐতিহাসিক ডেটা (পুরোনো পারফরম্যান্স প্রবণতা)।',
                    'টার্গেট অর্জন সতর্কতা, পারফরম্যান্স রিমাইন্ডার, সময়সীমার সতর্কতার জন্য স্বয়ংক্রিয় নোটিফিকেশন পাঠান।',
                ],
            },
            invoiceManagement: {
                featureHeader: 'ইনভয়েস ব্যবস্থাপনা',
                featureDescription:
                    'স্বয়ংক্রিয় সতর্কতা এবং বিজ্ঞপ্তির সাথে রিয়েল-টাইম ইনভয়েস স্ট্যাটাস এবং প্রক্রিয়াকরণ তৈরি করুন এবং ট্র্যাক করুন।',
                fIIPoints: [
                    'ইনভয়েস স্ট্যাটাস ট্র্যাক করতে এবং পাওনাদার এবং অতিরিক্ত পেমেন্টের জন্য স্বয়ংক্রিয় রিমাইন্ডার এবং সতর্কতা পাঠানোর জন্য সিস্টেম সেট আপ করুন।',
                    'সব প্রয়োজনীয় বিবরণ যেমন গ্রাহক তথ্য, অর্ডার বিস্তারিত, ট্যাক্স, ছাড় এবং পেমেন্ট শর্তাদি সহ মানক ইনভয়েস টেমপ্লেট।',
                    'ইনভয়েস প্রক্রিয়ার অংশ হিসাবে ডিজিটাল স্বাক্ষর এবং OTP ভিত্তিক ডেলিভারি প্রমাণ সংগ্রহ করুন।',
                    'সেন্ট্রাল সিস্টেমের সাথে স্বয়ংক্রিয় সিঙ্ক (ক্লাউড-ভিত্তিক স্টোরেজ) এবং ইনভয়েস স্ট্যাটাসে রিয়েল-টাইম আপডেট।',
                ],
            },
            distributorOrder: {
                featureHeader: 'বিতরণ অর্ডার',
                featureDescription:
                    'পণ্য/সংগ্রহ পরিচালনা করুন এবং ইনভেন্টরির রিয়েল-টাইম আপডেটের সাথে কাজের অগ্রাধিকার পরিচালনা করুন।',
                fIIPoints: [
                    'প্রতিটি বিতরণের জন্য সমস্ত প্রাসঙ্গিক বিবরণ সহ অর্ডার তৈরি করুন এবং প্রক্রিয়া করুন এবং উপলব্ধতার ভিত্তিতে কাজ বরাদ্দ করুন।',
                    'সঠিক স্টক বরাদ্দ নিশ্চিত করতে এবং রিয়েল-টাইমে ইনভেন্টরি স্তর আপডেট করতে ইনভেন্টরি ব্যবস্থাপনার সাথে ইন্টিগ্রেট করুন।',
                    'অর্ডার প্রক্রিয়ার প্রধান পর্যায়ে স্বয়ংক্রিয় নোটিফিকেশন পাঠানোর জন্য সিস্টেম কনফিগার করুন।',
                    'একটি ড্যাশবোর্ড অ্যাক্সেস করুন যেখানে কর্মচারীরা অর্ডার চেক করতে, অগ্রগতি ট্র্যাক করতে এবং প্রয়োজনীয় ডকুমেন্টগুলি ডাউনলোড করতে পারে।',
                ],
            },
            inventoryManagement: {
                featureHeader: 'ইনভেন্টরি ব্যবস্থাপনা',
                featureDescription:
                    'স্টকের সঠিক ভারসাম্য রিয়েল-টাইম আপডেট এবং ইনভেন্টরি ট্র্যাকিংয়ের মাধ্যমে সঠিক ডেটা।',
                fIIPoints: [
                    'ইনভেন্টরি এবং অর্ডার ব্যবস্থাপনা সিস্টেমের মধ্যে নিরবচ্ছিন্ন ইন্টিগ্রেশন নিশ্চিত করুন যাতে আসা এবং যাওয়া অর্ডারের সাথে স্টক স্তরগুলি সমন্বিত থাকে।',
                    'বিভিন্ন গুদাম, স্টোর, বা অবস্থানের মধ্যে ইনভেন্টরি ট্র্যাক করুন এবং কেন্দ্রীভূত নিয়ন্ত্রণ অ্যাক্সেস করুন, স্টক বিতরণ অপটিমাইজ করুন এবং দৃশ্যমানতা উন্নত করুন।',
                ],
            },
            orderCollectionManagement: {
                featureHeader: 'অর্ডার / সংগ্রহ ব্যবস্থাপনা',
                featureDescription: 'স্কেলেবল এবং স্বয়ংক্রিয় সমাধানের মাধ্যমে অর্ডার/সংগ্রহ কার্যক্রম সহজ করুন।',
                fIIPoints: [
                    'একটি কেন্দ্রীভূত সিস্টেম যা স্থানীয় এবং দূরবর্তী কর্মচারীদের দ্বারা অ্যাক্সেসযোগ্য অর্ডার পরিচালনা করতে, অগ্রগতি ট্র্যাক করতে এবং স্ট্যাটাসগুলি রিয়েল-টাইমে আপডেট করতে।',
                    'ডেটা সুরক্ষা এবং যথাযথ অ্যাক্সেস নিশ্চিত করতে ভূমিকার উপর ভিত্তি করে বিভিন্ন স্তরের অ্যাক্সেস এবং অনুমতি।',
                    'নিশ্চিত করুন যে সমস্ত অর্ডার ডেটা রিয়েল-টাইমে সমস্ত ডিভাইস এবং অবস্থানে সমন্বিত থাকে, যাতে সবাই সর্বশেষ তথ্য অ্যাক্সেস করতে পারে।',
                    'অ্যাপ-ভিত্তিক মেসেজিং এবং বিজ্ঞপ্তির মাধ্যমে স্থানীয় এবং দূরবর্তী কর্মচারীদের মধ্যে যোগাযোগ সহজ করুন।',
                ],
            },
        },
    },
};
export default products;
