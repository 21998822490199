const features = {
    attendanceMonitoring: {
        featureSubcaption: 'Eficiência em cada passo - Soluções inovadoras para sistemas de gestão de funcionários',
        featureHeader: 'Monitoramento de Frequência',
        featureDescription:
            'Informações em tempo real de entrada/saída sobre a presença ou ausência de indivíduos dentro da área de Geofence ou Filial',
        featureParagraph:
            'Chega de cartões de ponto, excesso de papelada e diferentes documentos para verificar a presença dos funcionários. Automatize o processo de monitoramento de frequência com o WebWork Time Tracker e veja o início e fim das horas de trabalho dos seus funcionários em um formato bem estruturado',
        featureCaption:
            'Gerencie a frequência de suas múltiplas filiais para seus funcionários de campo e funcionários internos',
        keyPoints: [
            {
                header: 'Frequência em Tempo Real',
                paragraph:
                    'Informações em tempo real sobre a presença ou ausência de indivíduos dentro da área de Geofence ou Filial',
            },
            {
                header: 'Cálculo Total de Horas Trabalhadas',
                paragraph: 'Monitore as horas trabalhadas com base em Diários, Semanais e Mensais',
            },
            {
                header: 'Relatórios de Frequência com Acesso Remoto',
                paragraph: 'Acesse relatórios de frequência de múltiplas filiais remotamente',
            },
            {
                header: 'Gestão Centralizada de Frequência',
                paragraph:
                    'Consolidação de dados, processos e sistemas relacionados à frequência em uma única plataforma ou sistema centralizado',
            },
        ],
        softwareDetails: [
            {
                title: 'Obtenha insights sobre o rastreamento da frequência dos funcionários',
                description: [
                    'Ganhe transparência sobre a pontualidade dos funcionários',
                    'Economize tempo e organize a frequência dos funcionários sem qualquer ação adicional.',
                    'Monitore facilmente se seus funcionários chegaram ao local de trabalho no horário e completaram suas horas de trabalho.',
                    'Mantenha suas operações no local de trabalho mais organizadas e tenha um ambiente de trabalho altamente responsável.',
                ],
                sDKeys: [
                    'Registre automaticamente a entrada e saída dos funcionários no local ao configurar o perímetro do geofence.',
                    'Receba alertas de notificação para funcionários que esquecem ou não registram entrada ou saída.',
                    'Localize e rastreie os movimentos de sua equipe de campo em tempo real logo após registrarem sua frequência.',
                ],
            },
            {
                title: 'Obtenha relatórios detalhados de monitoramento de frequência',
                description: [
                    'Análise inteligente de dados de frequência',
                    'Filtra saídas antecipadas, chegadas tardias e menos rastreamento que você pode selecionar na lista suspensa.',
                    'Isso ajudará a gerar instantaneamente as informações preferidas sem a necessidade de procurá-las manualmente.',
                    'Você pode monitorar a frequência diariamente ou escolher um intervalo no calendário.',
                ],
                sDKeys: [
                    'Obtenha resumos de frequência para sua organização.',
                    'Gere e utilize relatórios para visualizar tendências virais dentro de sua equipe, departamentos ou filiais.',
                    'Receba relatórios em intervalos regulares em sua caixa de correio. (Hora de entrada, hora de saída, total de horas trabalhadas, total de sessões)',
                    'Dados informativos para uma melhor tomada de decisões.',
                ],
            },
            {
                title: 'Explore outros recursos de tempo e produtividade.',
                description: [
                    'Forma segura e transparente de monitorar e rastrear a frequência dos funcionários em tempo real com um sistema 100% sem toque.',
                    'Configuração Fácil',
                    'Relatórios detalhados de entrada e saída automatizadas',
                    'Capturas de tela com tempo de rastreamento',
                    'Gravação de Nível de Atividade',
                    'Monitoramento de uso de Aplicativos e Sites',
                ],
                sDKeys: [
                    'A frequência dos funcionários demonstrou ajudar na gestão do tempo e melhorar a produtividade.',
                    'Elimine papelada e colete registros precisos de frequência dos funcionários.',
                ],
            },
        ],
    },
    leaveManagement: {
        featureHeader: 'Gestão de Licenças',
        feaatureSubcaption:
            'Coordene as ausências da equipe enquanto mantém os níveis de produtividade e continuidade operacional.',
        featureDescription:
            'Gerencie e acompanhe eficientemente ausências e presenças, garantindo a devida documentação, conformidade com as políticas da empresa e a continuidade do fluxo de trabalho.',
        featureParagraph:
            'Chega de cartões de ponto, excesso de papelada e diferentes documentos para verificar a frequência dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e fim das horas de trabalho dos seus funcionários em um formato bem estruturado.',
        featureCaption:
            'Fique informado sobre as futuras licenças dos seus funcionários com antecedência e planeje o seu dia de acordo.',
        keyPoints: [
            {
                header: 'Cálculo Automático de Acúmulos e Saldos de Licença',
                paragraph:
                    'Gerencie o acúmulo de férias, garantindo que os funcionários não excedam os valores permitidos e evite saldos negativos de licença.',
            },
            {
                header: 'Políticas de Licença Flexíveis e Fluxos de Aprovação',
                paragraph:
                    'Configure políticas de licença flexíveis na organização definindo o calendário de licenças. Permita a configuração hierárquica paralela ou sequencial para aprovação ou rejeição de solicitações de licença.',
            },
            {
                header: 'Conformidade com Leis Trabalhistas e Políticas da Empresa',
                paragraph:
                    'Fique informado sobre as leis trabalhistas e regulamentos relacionados aos direitos de licença. Garanta que suas políticas de gestão de licenças estejam em conformidade com as políticas da empresa.',
            },
            {
                header: 'Opções de Autoatendimento',
                paragraph:
                    'Ofereça opções de autoatendimento para os funcionários, permitindo que eles verifiquem seus saldos de licença, enviem solicitações e acompanhem o status de suas solicitações.',
            },
        ],
        softwareDetails: [
            {
                title: 'Múltiplas e flexíveis políticas de licença para sua equipe',
                description: [
                    'Políticas de licença flexíveis para atender às suas necessidades com parâmetros configuráveis',
                    'Múltiplos esquemas de licença e políticas adaptadas para diferentes grupos de funcionários',
                    'Tipos de licença ilimitados e transações de licença suportadas',
                ],
                sDKeys: [
                    'Essas políticas incluem tipos de licença como férias anuais, licenças por doença, licenças parentais, licenças por luto, etc.',
                    'Definir critérios de elegibilidade para políticas',
                    'Taxas de acúmulo, regras de uso, documentações necessárias',
                ],
            },
            {
                title: 'Autoatendimento para solicitação de licença e fluxo de aprovação',
                description: [
                    'A automação reduz os esforços dos funcionários. Os funcionários solicitam licença pelo portal web ou aplicativo móvel',
                    'Rastreador de licença para saldos e transações de licença',
                    'Visualização do gestor sobre dados de licença da equipe e padrões de licença para melhores insights',
                    'Fluxos de trabalho com escalonamentos levam a um sistema responsivo e uma ótima experiência para o funcionário',
                    'Notificações e alertas por e-mail',
                ],
                sDKeys: [
                    'Os funcionários solicitam licença pelo portal web ou aplicativo móvel',
                    'Rastreador de licença para saldos e transações de licença',
                    'Visualização do gestor sobre dados de licença da equipe e padrões de licença para melhores insights',
                    'Fluxos de trabalho com escalonamentos levam a um sistema responsivo e uma ótima experiência para o funcionário',
                    'Notificações e alertas por e-mail',
                ],
            },
            {
                title: 'Conformidade com políticas da empresa e leis trabalhistas',
                description: [
                    'A gestão eficaz de licenças de funcionários não só garante a conformidade com as leis trabalhistas, mas também contribui para uma cultura organizacional positiva. Ao implementar políticas de licença eficientes e utilizar a tecnologia, as organizações podem otimizar o processo e apoiar a necessidade de tempo livre de seus funcionários.',
                ],
                sDKeys: [
                    'Gerenciar responsabilidades de licença no orçamento de forma eficaz e minimizar riscos financeiros',
                    'Gerar dados estatísticos para relatórios individuais e acompanhar o desempenho mensal, trimestral ou anual',
                    'Fique informado sobre o planejamento da força de trabalho, alocação de recursos e estratégias de engajamento de funcionários',
                    'Registros detalhados e relatórios conforme as regras governamentais',
                    'Lista abrangente de feriados personalizada para uma força de trabalho diversificada',
                ],
            },
        ],
    },
    shiftManagement: {
        featureHeader: 'Gestão de Turnos',
        feaatureSubcaption:
            'Agende a força de trabalho com atividades de vendas, garanta o nível ideal de pessoal para atender às demandas dos clientes e maximize a produtividade.',
        featureDescription:
            'Organize e agende horas de trabalho que otimizem a produtividade, atendam às necessidades operacionais e garantam cobertura em diferentes períodos.',
        featureParagraph:
            'Chega de cartões de ponto, excesso de papelada e diferentes documentos para verificar a frequência dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e fim das horas de trabalho dos seus funcionários em um formato bem estruturado.',
        featureCaption:
            'Acompanhe os turnos dos seus funcionários com base nas horas totais de trabalho e nas múltiplas sessões agregadas.',
        keyPoints: [
            {
                header: 'Gerenciar Horários de Trabalho dos Funcionários',
                paragraph:
                    'Desenvolva um cronograma de turnos claro e bem organizado. Defina diferentes tipos de turnos com base na natureza do seu negócio.',
            },
            {
                header: 'Definir Turnos, Atribuir Funções, Configurar Horários Recorrentes ou Personalizados',
                paragraph:
                    'Analise a carga de trabalho e as tarefas para determinar o número de funcionários necessários durante diferentes turnos.',
            },
            {
                header: 'Ferramentas de Comunicação Integradas',
                paragraph:
                    'Plataforma centralizada para os funcionários acessarem seus horários e estabelecerem um canal de comunicação confiável para quaisquer atualizações ou mudanças.',
            },
            {
                header: 'Gestão Centralizada de Turnos com Horários em Múltiplas Localidades',
                paragraph:
                    'Supervisão e controle centralizados dos horários dos funcionários, permitindo que os gerentes alocem turnos de forma eficiente, acompanhem a presença e gerenciem os recursos da força de trabalho em várias localizações a partir de uma única plataforma.',
            },
        ],
        softwareDetails: [
            {
                title: 'Automatize o processo de agendamento com base na localização e turno',
                description: [
                    'Analisando a carga de trabalho e as tarefas para determinar o número de funcionários necessários durante diferentes turnos.',
                    'Considere os horários de pico, variações sazonais e quaisquer requisitos específicos para cada turno. Isso pode incluir turnos diurnos, noturnos, turnos rotativos, turnos divididos ou horários flexíveis.',
                ],
                sDKeys: [
                    'Troca de turnos entre funcionários.',
                    'Os funcionários podem trocar ou trocar seus turnos com outros funcionários com a funcionalidade/opção disponível.',
                    'Os funcionários podem escolher seu turno e dias de folga que melhor funcionem para eles.',
                ],
            },
            {
                title: 'Quadro de turnos para agendamento dinâmico de turnos',
                description: [
                    'Garanta conformidade com as leis trabalhistas em relação a intervalos e períodos de descanso.',
                    'Inclua pausas no cronograma de turnos para evitar a fadiga dos funcionários e manter a produtividade.',
                    'Garanta que suas políticas de gestão de turnos estejam alinhadas com os requisitos legais.',
                ],
                sDKeys: [
                    'Crie facilmente cronogramas de trabalho eficientes para sua equipe usando ferramentas de agendamento fáceis de usar.',
                    'Adapte os turnos para atender às demandas operacionais do seu negócio e à disponibilidade dos funcionários.',
                    'Garanta a distribuição justa dos turnos, considerando as preferências e a carga de trabalho dos funcionários.',
                ],
            },
            {
                title: 'Incentive os funcionários com adicionais de turno por trabalhar em horários incomuns',
                description: [
                    'Integre sistemas de gestão de turnos com processos de folha de pagamento para garantir uma compensação e deduções precisas com base nos turnos trabalhados.',
                    'Defina procedimentos para horas extras e garanta que as políticas de horas extras estejam em conformidade com as regulamentações trabalhistas.',
                ],
                sDKeys: [
                    'Aprove as solicitações de turnos dos funcionários com um único clique.',
                    'Os funcionários podem escolher seu próprio turno no início da semana/mês e enviar solicitações pelo portal conforme sua conveniência.',
                    'Solução rentável pagando adicionais de turno com base na taxa de pagamento por hora ou na política da empresa.',
                ],
            },
        ],
    },
    geoFenceBasedManagement: {
        featureHeader: 'Presença Baseada em Geo Fence',
        feaatureSubcaption:
            'Maximizando a precisão e a responsabilidade, rastreie a presença dos funcionários com limites designados.',
        featureDescription:
            'Tecnologia baseada em localização para monitorar e verificar a presença dos funcionários dentro de limites geográficos predefinidos.',
        featureCaption:
            'A presença baseada em Geo Fence aproveita a precisão do seu rastreamento de presença, pois os funcionários são marcados como presentes apenas quando estão na área designada.',
        keyPoints: [
            {
                header: 'Localize Funcionários com Precisão Dentro da Área Designada de Geo Fence',
                paragraph:
                    'Registros precisos de presença para garantir que os funcionários estejam presentes nos locais de trabalho designados.',
            },
            {
                header: 'Monitoramento em Tempo Real das Localizações dos Funcionários',
                paragraph:
                    'Implemente rastreamento em tempo real para monitorar a localização dos funcionários dentro da geo-fence.',
            },
            {
                header: 'Definir Limites Geográficos Específicos para Registrar Entrada ou Saída',
                paragraph:
                    'Estabeleça limites geográficos específicos, conhecidos como geo-fences, ao redor do local de trabalho ou dos locais de presença designados.',
            },
            {
                header: 'Alertas de Geo Fence',
                paragraph:
                    'Configure alertas ou notificações para informar gerentes ou o RH quando os funcionários entrarem ou saírem dos limites de geo-fence definidos.',
            },
        ],
        softwareDetails: [
            {
                title: 'Restrição de Localização no Registro de Presença',
                description: [
                    'Estabelecendo limites geográficos específicos usando coordenadas de GPS ou tecnologias baseadas em localização. Esses limites representam as áreas onde os funcionários devem estar presentes para trabalhar.',
                ],
                sDKeys: [
                    'Recurso opcional de restrição de usuário para permitir presença a partir de um endereço IP específico, garantindo a presença nas proximidades do escritório.',
                    'Facilidade de comunicação com usuários específicos ou todos os usuários usando o recurso de Notificações Push.',
                    'Vincule tarefas com a presença dos funcionários para visualizar tarefas diárias e cronogramas.',
                ],
            },
            {
                title: 'Defina Restrições de Presença e Autenticação Biométrica',
                description: [
                    'Implementando rastreamento em tempo real para monitorar a localização dos funcionários dentro das áreas de geo-fence. Essas informações são usadas para confirmar a presença e garantir que os funcionários estejam dentro dos locais de trabalho designados durante as horas programadas.',
                ],
                sDKeys: [
                    'Os funcionários só podem registrar sua presença após chegarem à sua primeira localização de tarefa.',
                    'Os funcionários podem marcar sua presença remotamente; sem mais presenças incorretas ou viagens ao escritório apenas para marcar a presença.',
                    'Os gerentes podem rastrear a localização em tempo real e receber atualizações remotamente através de aplicativos móveis ou da web.',
                ],
            },
            {
                title: 'Aprimore a Segurança e a Autorização',
                description: [
                    'Implemente medidas de autenticação seguras para evitar entradas fraudulentas de presença, usando códigos PIN, autenticação biométrica e reconhecimento facial.',
                    'Configure notificações móveis ou alertas para os funcionários, lembrando-os de registrar a presença quando entrarem na área de geo-fence. Isso garante que os funcionários estejam cientes do sistema de rastreamento de presença.',
                ],
            },
        ],
    },
    multipleClockInOptions: {
        featureHeader: 'Múltiplas Opções de Registro de Ponto',
        feaatureSubcaption:
            'Proporcionando flexibilidade e conveniência, múltiplas opções de registro de ponto permitem que os funcionários gerenciem seu tempo de forma eficiente.',
        featureDescription:
            'Ofereça flexibilidade aos funcionários para registrar suas horas de trabalho usando scanners biométricos, aplicativos móveis, relógios de ponto e plataformas baseadas na web.',
        featureCaption:
            'Facilite o uso para funcionários que trabalham remotamente e podem registrar múltiplos horários em diferentes sessões.',
        keyPoints: [
            {
                header: 'Adequado para Organizações com Múltiplas Localizações',
                paragraph:
                    'Opções de registro de ponto flexíveis para gerenciamento de presença em campo e fora de campo em diferentes filiais.',
            },
            {
                header: 'Portal de Autoatendimento do Funcionário',
                paragraph:
                    'Forneça aos funcionários acesso a um portal de autoatendimento para registros de presença e gerenciamento de check-ins e check-outs dentro da geo-fence.',
            },
            {
                header: 'Múltiplas Opções de Registro de Ponto',
                paragraph:
                    'As opções flexíveis de registro de ponto incluem escaneamento biométrico, aplicativos móveis, cartões RFID e reconhecimento facial, garantindo um rastreamento de tempo contínuo em vários ambientes de trabalho.',
            },
            {
                header: 'Registro de Ponto Preciso Baseado em Localização',
                paragraph:
                    'Registro automático de presença para funcionários dentro do alcance das instalações do escritório com notificação de alerta.',
            },
        ],
        softwareDetails: [
            {
                title: 'Registro de Ponto no Local e Fora do Local',
                description: [
                    'Atendendo a diversos ambientes de trabalho, nosso sistema de gerenciamento de presença de funcionários oferece várias opções de registro de ponto.',
                    'Seja no local, em campo ou remotamente, os funcionários podem registrar seu tempo de forma conveniente por meio de escaneamentos biométricos, reconhecimento facial e registro automático de presença baseado em geo-fence.',
                ],
            },
            {
                title: 'Portal de Autoatendimento para Funcionários Verificarem Seus Registros',
                description: [
                    'O portal de autoatendimento do funcionário oferece controle sobre múltiplos check-ins e check-outs.',
                    'Com recursos fáceis de usar, os funcionários podem gerenciar facilmente suas horas de trabalho, seja durante a transição entre turnos, locais ou tarefas.',
                    'Isso dá aos funcionários flexibilidade, ao mesmo tempo que mantém registros de tempo precisos, aumentando a eficiência e simplificando os processos administrativos.',
                ],
            },
            {
                title: 'Múltiplos Registros de Ponto para Múltiplas Filiais',
                description: [
                    'Acomode as necessidades dinâmicas da sua empresa em várias localizações e garanta um rastreamento de presença preciso e eficiência operacional.',
                ],
            },
        ],
    },
    attendanceReport: {
        featureHeader: 'Relatórios de Presença',
        feaatureSubcaption:
            'Um reflexo perspicaz do comprometimento e pontualidade da equipe, capturado em um único relatório.',
        featureDescription:
            'Resuma os dados de presença dos funcionários, horas trabalhadas, ausências, chegadas tardias e saídas antecipadas dentro de um período específico.',
        featureCaption:
            'Gere relatórios automatizados para diferentes filiais e receba-os diariamente, semanalmente, mensalmente com mais de 40+ templates.',
        keyPoints: [
            {
                header: 'Automatize Detalhes de Presença: Hora de Entrada, Atrasos, Total de Horas Trabalhadas, Total de Sessões',
                paragraph:
                    'Forneça um resumo geral da presença, incluindo o número total de dias trabalhados, horas trabalhadas e qualquer hora extra.',
            },
            {
                header: 'Relatórios de Múltiplas Filiais',
                paragraph:
                    'Acesse remotamente os relatórios de presença de várias filiais com categorias personalizáveis.',
            },
            {
                header: 'Horários Extras e Saldos de Licenças',
                paragraph:
                    'Exiba os saldos de licenças de cada funcionário, incluindo dias de férias, licenças médicas e qualquer outro tipo de licença acumulada e utilizada.',
            },
            {
                header: 'Relatório de Presença Personalizado',
                paragraph:
                    'Permita que os usuários personalizem os relatórios com base em critérios específicos, como departamento, equipe ou funcionário individual.',
            },
        ],
        softwareDetails: [
            {
                title: 'Gere relatórios com base na estrutura da sua organização.',
                description: [
                    'Relatórios de presença gerados e analisados regularmente podem fornecer insights valiosos para melhorar o gerenciamento da força de trabalho, identificar tendências e garantir conformidade com as políticas organizacionais.',
                    'Os detalhes do relatório dependerão das necessidades e da estrutura da organização.',
                ],
                sDKeys: [
                    'As informações coletadas de vários usuários são coligidas para fornecer análises automatizadas, reduzindo o tempo e os recursos gastos na geração manual de relatórios.',
                    'Os usuários podem selecionar as análises necessárias com apenas alguns cliques e podem se inscrever a qualquer momento.',
                    'O relatório de análise de amostra pode ser baixado e visualizado como uma representação real do relatório desejado.',
                ],
            },
            {
                title: 'Obtenha facilmente relatórios personalizáveis.',
                description: [
                    'Identifique e destaque quaisquer exceções ou irregularidades na presença, como ausências não autorizadas, atrasos excessivos ou casos de horas extras não aprovadas.',
                    'Use gráficos ou tabelas para representar visualmente as tendências e padrões de presença. Isso pode facilitar a identificação de outliers e tendências rapidamente.',
                ],
                sDKeys: [
                    'Os usuários podem se beneficiar dos insights de dados gerados de várias maneiras, como processamento de folha de pagamento, avaliações de desempenho, reembolsos, etc.',
                    'Relatórios podem ser compartilhados com outros usuários com base no fluxo hierárquico de autoridade.',
                    'A empresa pode estar coletivamente ciente do status de trabalho e do desempenho de sua equipe da maneira mais fácil, sem intervenções manuais.',
                ],
            },
            {
                title: 'Reduza o tempo gasto em relatórios manuais.',
                description: [
                    'Inclui detalhes fundamentais como nomes de funcionários, números de identificação e afiliações de departamento para contextualizar os dados de presença.',
                    'Especificar claramente o intervalo de data e hora coberto pelo relatório. Isso ajuda os usuários a entender o escopo temporal dos dados.',
                    'Fornecer um resumo geral da presença, incluindo o número total de dias trabalhados, horas trabalhadas e quaisquer horas extras.',
                ],
            },
        ],
    },
    workforceTracking: {
        featureHeader: 'Rastreamento da Força de Trabalho',
        featureDescription:
            'Monitoramento sistemático e gestão das atividades, produtividade e presença dos funcionários.',
        featureParagraph:
            'Chega de cartões de ponto, papelada excessiva e diferentes documentos para verificar a presença dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e o fim das horas de trabalho de seus funcionários em um formato bem estruturado.',
        featureCaption:
            'Monitore e gerencie vários aspectos da força de trabalho de uma organização, incluindo presença dos funcionários, horas de trabalho, produtividade e desempenho.',
        keyPoints: [
            {
                header: 'Rastreamento de Presença e Movimentação com Linha do Tempo',
                paragraph:
                    'Monitore eventos como entrada e saída, além de fornecer insights sobre operações, métricas de desempenho ou eventos dos funcionários.',
            },
            {
                header: 'Formulários Personalizados para Gestão de Dados',
                paragraph:
                    'Para atas de reuniões, armazene dados de reuniões e tarefas para acompanhar tendências e decisões baseadas em dados.',
            },
            {
                header: 'Gestão de Clientes e Leads',
                paragraph:
                    'Acompanhe a produtividade e meça indicadores-chave de desempenho com base na quantidade de trabalho concluído pelos funcionários.',
            },
            {
                header: 'Cálculos de Distância Total para Reembolsos Precisos',
                paragraph:
                    'Acompanhe a distância precisa percorrida pelos funcionários para trabalhos externos com base em métricas diárias, semanais e mensais, e reembolse despesas conforme a distância e os locais percorridos.',
            },
        ],
        softwareDetails: [
            {
                title: 'Implemente sistemas de rastreamento de tempo para monitorar horas de trabalho.',
                description: [
                    'Implementar soluções de rastreamento de tempo para monitorar o tempo que os funcionários gastam em tarefas e projetos.',
                    'Isso ajuda a entender a utilização de recursos e melhorar a gestão do tempo.',
                    'Utilize sistemas de gestão de projetos para acompanhar o progresso dos projetos, atribuir tarefas e monitorar prazos.',
                    'Isso ajuda a otimizar os fluxos de trabalho do projeto e garantir a conclusão no prazo.',
                ],
            },
            {
                title: 'Monitore indicadores-chave de desempenho relevantes.',
                description: [
                    'Definir e acompanhar indicadores-chave de desempenho relevantes para os objetivos da sua organização.',
                    'Isso pode incluir metas de vendas, métricas de satisfação do cliente ou outros indicadores de desempenho.',
                ],
                sDKeys: [
                    'Desempenho de Vendas: Receita Mensal/Trimestral, número de Negócios Fechados, Taxa de Conversão de Vendas, tamanho médio dos Negócios, valor do Pipeline de Vendas.',
                    'Atendimento ao Cliente: Pontuação de satisfação do cliente (CSAT), pontuação do promotor líquido (NPS), tempo de primeira resposta, tempo médio de resolução, taxa de retenção de clientes, volume de tickets.',
                    'Operações/Produção: Produção, métricas de qualidade (taxa de defeitos, taxa de erros), Desempenho de Entrega no Prazo, giro de estoque, tempo de inatividade de equipamentos, taxa de utilização de funcionários.',
                    'Geral: Produtividade do funcionário (produção por hora), Presença e pontualidade, Taxa de atingimento de metas, Feedback dos funcionários e avaliações de desempenho.',
                ],
            },
            {
                title: 'Gere relatórios das tendências da sua força de trabalho.',
                description: [
                    'Integrar recursos de análise e relatórios para obter insights sobre tendências da força de trabalho, desempenho e áreas para melhoria.',
                    'Relatórios personalizados podem ajudar na tomada de decisões.',
                ],
                sDKeys: [
                    'Comparação da composição demográfica com a força de trabalho geral ou referência do setor e identificação de quaisquer tendências ou mudanças demográficas ao longo do tempo.',
                    'Comparação de métricas de desempenho entre diferentes equipes de campo, regiões ou períodos de tempo.',
                    'Mapeamento geoespacial das atividades da força de campo para visualizar a distribuição e as áreas de cobertura.',
                    'Análise de desempenho de vendas ou serviços por região ou território geográfico. Identificação de áreas com alto potencial para expansão ou otimização.',
                ],
            },
        ],
    },
    fieldSalesAnalytics: {
        featureHeader: 'Análise de Vendas Externas',
        featureDescription:
            'Acompanhe e analise as principais métricas da equipe de vendas em campo, como receita de vendas, interações com clientes e tendências geográficas.',
        featureParagraph:
            'Chega de cartões de ponto, papelada excessiva e diferentes documentos para verificar a presença dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e o fim das horas de trabalho de seus funcionários em um formato bem estruturado.',
        featureCaption:
            'Analise a coleta, análise e interpretação de dados relacionados às atividades de vendas externas para obter insights e tomar decisões informadas.',
        keyPoints: [
            {
                header: 'Indicadores-Chave de Desempenho para Vendas e Leads',
                paragraph:
                    'Indicadores-Chave de Desempenho podem ajudar a tomar decisões baseadas em dados, o que pode melhorar a produtividade e eficiência na próxima estratégia ou reformulação da atual.',
            },
            {
                header: 'Relatórios Personalizados',
                paragraph:
                    'Relatórios podem ser acessados diariamente, semanalmente ou mensalmente sobre Vendas, Leads e Aquisição de Clientes, ajudando na produtividade e eficiência da equipe.',
            },
            {
                header: 'Valide Visitas de Vendas e Alinhe com Geofence',
                paragraph:
                    'Valide a visita com check-ins baseados em Geofence para garantir a precisão. As coordenadas da localização podem ser anexadas a uma foto tirada pela câmera ao compartilhar.',
            },
            {
                header: 'Meça a Efetividade da Rota de Visitas',
                paragraph:
                    'A Rota de Visitas pode ser usada para planejar visitas ou roteiros de vendas para os funcionários em suas visitas aos clientes. Integre seu plano de rota diretamente em Mapas para otimizar a navegação.',
            },
        ],
        softwareDetails: [
            {
                title: 'Insights diários em campo para melhorar o desempenho de vendas.',
                description: [
                    'Geração de relatórios eletrônicos orientada por análises de dados. Funcionalidade confiável e amigável de relatórios rápidos para fácil acesso e uso.',
                    'Como gerente de vendas, você terá uma visão clara de vários relatórios que serão enviados ao seu e-mail ou poderão ser visualizados no painel de insights.',
                    'Alguns dos relatórios notáveis são: relatórios mensais, relatórios por localização de filial, relatórios específicos de funcionários, entre outros.',
                ],
            },
            {
                title: 'Visão geral sobre a presença em campo.',
                description: [
                    'Visão geral da presença e visitas de campo dos funcionários.',
                    'Obtenha uma visão completa da presença e das reuniões da força de trabalho em campo.',
                    'Nos relatórios de presença em campo, você terá dados sobre o horário de marcação de presença, localização, atrasos na entrada, horas de trabalho, entre outros.',
                    'Da mesma forma, fornecemos relatórios de check-in/out de visitas de campo, incluindo informações como hora de login/out, check-in/out, local das reuniões, distância percorrida, notas dos resultados das reuniões, entre outros.',
                ],
            },
            {
                title: 'Relatórios gerados com foco em análise de dados.',
                description: [
                    'Assine e baixe relatórios eletrônicos mensais.',
                    'Você pode assinar e baixar os relatórios mensais eletrônicos sempre que quiser.',
                    'Disponível nos formatos pdf e excel, oferecendo uma visão detalhada das ações externas dos funcionários, o que manualmente seria uma tarefa difícil.',
                ],
            },
        ],
    },
    locationBasedAttendance: {
        featureHeader: 'Presença Baseada em Localização',
        featureDescription:
            'Presença baseada em geofencing para garantir que os funcionários só possam registrar entrada ou saída quando estiverem dentro dos limites geográficos pré-definidos.',
        featureCaption:
            'A presença baseada em localização garante precisão no monitoramento e registro da presença dos funcionários com base em sua localização física.',
        keyPoints: [
            {
                header: 'Geofencing',
                paragraph:
                    'Implemente limites virtuais georreferenciados ao redor de locais específicos das instalações do escritório. Isso ajuda a automatizar a presença com base no ingresso ou saída de um funcionário de uma área designada.',
            },
            {
                header: 'Autenticação e Segurança',
                paragraph:
                    'Métodos de autenticação para garantir a precisão e a segurança dos dados de presença, usando PINs, biometria ou reconhecimento facial.',
            },
            {
                header: 'Atualizações em Tempo Real',
                paragraph:
                    'Garanta que os registros de presença sejam atualizados prontamente, permitindo decisões de gerenciamento oportunas.',
            },
            {
                header: 'Considerações para Trabalho Remoto',
                paragraph: 'Considere métodos alternativos para rastreamento de presença para funcionários remotos.',
            },
        ],
        softwareDetails: [
            {
                title: 'Tempo e localização da força de trabalho.',
                description: [
                    'Saiba quando e onde seus funcionários estão trabalhando.',
                    'Instrua seus funcionários a conceder permissão em seus celulares para que o sistema possa rastrear o tempo e a localização.',
                    'Uma vez concedida a permissão, o rastreador pode começar a funcionar com um toque.',
                ],
            },
            {
                title: 'Relatório ao vivo dois',
                description: [
                    'A implementação de um sistema de presença de funcionários baseado em localização pode melhorar a precisão e simplificar o gerenciamento de presença. No entanto, é crucial equilibrar os benefícios com as considerações de privacidade dos funcionários e comunicar de forma transparente sobre a implementação e uso desses sistemas.',
                ],
            },
            {
                title: 'Alerta baseado em geofencing',
                description: [
                    'Configure alertas automáticos para padrões incomuns de presença ou discrepâncias. Isso ajuda a identificar possíveis problemas, como check-ins ou check-outs não autorizados.',
                    'Implemente recursos de relatórios e análises para obter insights sobre padrões, tendências e exceções de presença.',
                ],
                sDKeys: [
                    'Receba alertas quando ativos valiosos, como veículos ou equipamentos, entrarem ou saírem de áreas designadas, ajudando a prevenir roubo ou uso não autorizado.',
                    'Garanta a segurança dos trabalhadores de campo configurando geofences em torno de áreas perigosas ou locais de trabalho remotos, disparando alertas se os funcionários entrarem em zonas restritas.',
                    'Quando um dispositivo entra ou sai de uma área geofenciada, o sistema aciona alertas ou notificações predefinidos.',
                    'O sistema monitora continuamente a localização dos dispositivos rastreados em tempo real e verifica se eles cruzam quaisquer geofences definidos.',
                ],
            },
        ],
    },
    liveReporting: {
        featureHeader: 'Relatórios em Tempo Real',
        featureDescription:
            'Monitoramento e análise em tempo real de vários aspectos do trabalho, como presença, tarefas concluídas ou progresso de projetos.',
        featureCaption:
            'Mantenha-se informado sobre as tarefas dos funcionários, atas de reuniões, valide visitas e tome decisões baseadas nos dados.',
        keyPoints: [
            {
                header: 'Notificações Baseadas em Eventos',
                paragraph:
                    'O administrador ou gerente pode se inscrever ou optar por notificações que deseja receber. Isso ajuda a focar nas notificações realmente importantes.',
            },
            {
                header: 'Fique Notificado Sobre Presença e Férias',
                paragraph:
                    'Esteja sempre à frente e saiba o número de funcionários disponíveis para planejar o dia de forma mais produtiva e eficiente.',
            },
            {
                header: 'Acompanhe Todas as Aprovações',
                paragraph:
                    'Acompanhe todas as aprovações, desde reembolsos até licenças, e visualize-as posteriormente.',
            },
            {
                header: 'Fique Atualizado com Formulários e Atas de Reunião (MOM)',
                paragraph:
                    'Formulários personalizados podem ajudar a obter dados específicos para uma reunião que podem ser acessados remotamente.',
            },
        ],
        softwareDetails: [
            {
                title: 'Localize a força de trabalho em campo em tempo real.',
                description: [
                    'Localize sua equipe de campo em tempo real.',
                    'Obtenha detalhes instantâneos da localização da sua equipe de campo enquanto eles se deslocam em tempo real.',
                    'O Treant permite localizar e rastrear seus funcionários de campo usando o GPS de seus celulares, tanto online quanto offline. Mantenha-se atualizado sobre todos os movimentos da equipe e mantenha um ambiente de trabalho transparente.',
                ],
            },
            {
                title: 'Mantenha-se atualizado com as informações de campo de seus funcionários.',
                description: [
                    'Mantenha-se atualizado com as informações de campo de seus funcionários.',
                    'As informações no campo serão automaticamente sincronizadas com seu painel de controle.',
                    'Detalhes como percentual de bateria, GPS, tempo de internet ligado/desligado e tempo ocioso que seus funcionários passam no campo serão notificados instantaneamente em tempo real.',
                ],
            },
            {
                title: 'Automatize os dados de reembolso e subsídio dos funcionários.',
                description: [
                    'Histórico de localização impulsionado por IA para dados precisos.',
                    'Os dados de localização de seus funcionários serão impulsionados por Inteligência Artificial para fornecer informações precisas de rota e rastreamento. Os dados de rastreamento ao vivo e outros históricos de localização serão automatizados para sua caixa de entrada em intervalos regulares para uma análise mais detalhada do que está acontecendo no campo.',
                ],
            },
        ],
    },
    aIBasedOptimization: {
        featureHeader: 'Otimização Baseada em IA',
        featureDescription:
            'Otimizar e automatizar o planejamento de rotas para representantes de campo ou pessoal de vendas com base na rota de batida da IA.',
        featureCaption:
            'Otimize as rotas do dia e compare os relatórios com sua temporada anterior usando nosso algoritmo baseado em IA.',
        keyPoints: [
            {
                header: 'Integração de Dados em Tempo Real',
                paragraph:
                    'Fontes de dados em tempo real, como atualizações de tráfego ao vivo, condições meteorológicas e fechamento de estradas, para ajustar dinamicamente as rotas com base nas circunstâncias atuais, ajudando no planejamento diário eficiente.',
            },
            {
                header: 'Interface do Usuário para Planejamento de Rotas',
                paragraph:
                    'Visualize rotas otimizadas e faça ajustes necessários. Isso pode fazer parte de um aplicativo móvel ou de uma plataforma baseada na web.',
            },
            {
                header: 'Métricas de Produtividade',
                paragraph: 'Sistema de IA para considerar as características únicas de diferentes rotas ou batidas.',
            },
            {
                header: 'Mapeamento e Dados Geoespaciais',
                paragraph:
                    'Utilize serviços de mapeamento e dados geoespaciais para entender a rede de estradas, condições de tráfego e outras informações relacionadas à localização.',
            },
        ],
        softwareDetails: [
            {
                title: 'Otimização de Rotas com Atualizações em Tempo Real',
                description: [
                    'Otimize e planeje as rotas mais eficientes para indivíduos ou veículos, como caminhões de entrega, representantes de vendas ou técnicos de campo, que seguem rotas específicas como parte de suas atividades regulares.',
                    'Este aplicativo pode ajudar a melhorar a eficiência, reduzir o tempo de viagem e minimizar os custos operacionais.',
                ],
                sDKeys: [
                    'Coleta de dados de várias fontes, incluindo dispositivos GPS, relatórios de tráfego, atualizações meteorológicas e sensores de veículos.',
                    'Tempos de resposta mais rápidos e chegadas pontuais aumentam a satisfação do cliente, o que é crucial para empresas orientadas para serviços.',
                ],
            },
            {
                title: 'Necessita Consideração Cuidadosa das Questões de Privacidade e Conformidade',
                description: [
                    'Garantir que o sistema baseado em IA esteja em conformidade com as regulamentações locais, especialmente aquelas relacionadas à privacidade, segurança de dados e leis de transporte.',
                    'Atualizar e melhorar regularmente os algoritmos de IA com base em novos dados e feedback, visando a otimização contínua das rotas.',
                ],
            },
            {
                title: 'Integração com Outros Sistemas para Eficiência no Planejamento de Rotas',
                description: [
                    'Use algoritmos de aprendizado de máquina para analisar dados históricos e prever padrões futuros.',
                    'Isso pode ajudar a antecipar condições de tráfego, tempos de entrega e outros fatores que influenciam o planejamento de rotas.',
                ],
            },
        ],
    },
    leadManagement: {
        featureHeader: 'Gerenciamento de Leads',
        featureDescription:
            'Resuma os dados de presença dos funcionários, horas trabalhadas, ausências, chegadas tardias e saídas antecipadas dentro de um período específico.',
        featureCaption:
            'Gere relatórios automatizados para diferentes filiais e receba-os diariamente, semanalmente ou mensalmente com mais de 40 modelos.',
        keyPoints: [
            {
                header: 'Automatizar Detalhes de Presença: Horário de Entrada, Horário de Atraso, Total de Horas Trabalhadas, Total de Sessões',
                paragraph:
                    'Forneça um resumo geral da presença, incluindo o número total de dias trabalhados, horas totais trabalhadas e qualquer hora extra.',
            },
            {
                header: 'Relatórios de Múltiplas Filiais',
                paragraph:
                    'Acesse remotamente relatórios de presença de várias filiais com categorias personalizáveis.',
            },
            {
                header: 'Horas Extras e Saldo de Licença',
                paragraph:
                    'Exiba saldos de licença para cada funcionário, incluindo dias de férias, licença médica e qualquer outro tipo de licença acumulada e utilizada.',
            },
            {
                header: 'Relatório de Presença Personalizado',
                paragraph:
                    'Permita que os usuários personalizem relatórios com base em critérios específicos, como departamento, equipe ou funcionário individual.',
            },
        ],
        softwareDetails: [
            {
                title: 'Gere relatórios com base na estrutura da sua organização.',
                description: [
                    'Relatórios de presença gerados e analisados regularmente podem fornecer informações valiosas para melhorar a gestão da força de trabalho, identificar tendências e garantir conformidade com as políticas organizacionais.',
                    'Os detalhes do relatório dependerão das necessidades e da estrutura da organização.',
                ],
            },
            {
                title: 'Obtenha facilmente relatórios personalizáveis.',
                description: [
                    'Identifique e destaque quaisquer exceções ou irregularidades na presença, como ausências não autorizadas, atrasos excessivos ou casos de horas extras não aprovadas.',
                    'Use gráficos ou tabelas para representar visualmente as tendências e padrões de presença. Isso pode facilitar a identificação de discrepâncias e tendências rapidamente.',
                ],
            },
            {
                title: 'Reduza o tempo gasto em relatórios manuais.',
                description: [
                    'Inclui detalhes fundamentais como nomes dos funcionários, números de identificação e afiliações departamentais para fornecer contexto aos dados de presença.',
                    'Especifica claramente o intervalo de datas e horários cobertos pelo relatório. Isso ajuda os usuários a entender o escopo temporal dos dados.',
                    'Fornece um resumo geral da presença, incluindo o número total de dias trabalhados, horas totais trabalhadas e qualquer hora extra.',
                ],
            },
        ],
    },
    taskManagement: {
        featureHeader: 'Gerenciamento de Tarefas',
        featureDescription:
            'Monitoramento e gerenciamento sistemático das atividades, produtividade e presença dos funcionários.',
        featureParagraph:
            'Chega de cartões de ponto, papelada excessiva e documentos diversos para verificar a presença dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e o fim das horas de trabalho dos seus funcionários em um formato bem estruturado.',
        featureCaption:
            'Monitore e gerencie vários aspectos da força de trabalho de uma organização, incluindo presença dos funcionários, horas de trabalho, produtividade e desempenho.',
        keyPoints: [
            {
                header: 'Monitore em tempo real',
                paragraph:
                    'Acompanhe o progresso em tempo real visualizando painéis de tarefas, verificando o status das tarefas e rastreando o tempo gasto.',
            },
            {
                header: 'Monitore todos os seus fluxos de trabalho em um só lugar para toda a organização',
                paragraph:
                    'Recursos de colaboração em gerenciamento de tarefas para se comunicar com membros da equipe em tempo real. Comente em tarefas, faça perguntas e forneça atualizações para manter todos informados.',
            },
            {
                header: 'Monitore o desempenho',
                paragraph:
                    'Gerenciamento de Clientes e Leads para rastrear a produtividade. A produtividade dos funcionários e os principais indicadores de desempenho podem ser medidos com base na quantidade de trabalho concluído pelos funcionários.',
            },
            {
                header: 'Padronize a definição de metas',
                paragraph:
                    'Estrutura hierárquica para organizar metas: metas de projeto, objetivos departamentais, metas de desempenho individual, iniciativas estratégicas, etc.',
            },
        ],
        softwareDetails: [
            {
                title: 'Implemente sistemas de rastreamento de tempo para monitorar horas de trabalho.',
                description: [
                    'Implementar soluções de rastreamento de tempo para monitorar o tempo que os funcionários gastam em tarefas e projetos.',
                    'Isso pode ajudar a entender a utilização de recursos e melhorar a gestão do tempo.',
                    'Utilize sistemas de gerenciamento de projetos para acompanhar o progresso do projeto, atribuir tarefas e monitorar prazos.',
                    'Isso ajuda a otimizar os fluxos de trabalho do projeto e garantir a conclusão pontual.',
                ],
            },
            {
                title: 'Monitore indicadores-chave de desempenho relevantes.',
                description: [
                    'Definir e acompanhar indicadores-chave de desempenho relevantes para as metas da sua organização.',
                    'Isso pode incluir metas de vendas, métricas de satisfação do cliente ou outros indicadores de desempenho.',
                ],
            },
            {
                title: 'Gere relatórios sobre as tendências da sua força de trabalho.',
                description: [
                    'Integrar recursos de análise e relatórios para obter insights sobre tendências da força de trabalho, desempenho e áreas para melhoria.',
                    'Relatórios personalizados podem auxiliar na tomada de decisões.',
                ],
            },
        ],
    },
    salesPipelineMonitoring: {
        featureHeader: 'Monitoramento do Pipeline de Vendas',
        featureDescription:
            'Acompanhe e analise métricas-chave da equipe de vendas no campo, como receita de vendas, interações com clientes e tendências geográficas.',
        featureParagraph:
            'Chega de cartões de ponto, papelada excessiva e documentos diversos para verificar a presença dos funcionários. Automatize o processo de monitoramento de presença com o WebWork Time Tracker e veja o início e o fim das horas de trabalho dos seus funcionários em um formato bem estruturado.',
        featureCaption:
            'Analise a coleta, análise e interpretação de dados relacionados às atividades de vendas no campo para obter insights e tomar decisões informadas.',
        keyPoints: [
            {
                header: 'Fluxos de Trabalho Automatizados',
                paragraph:
                    'Aproveite o sistema para simplificar e otimizar as várias etapas do processo de vendas, como captura de leads, qualificação, nutrição, gerenciamento de pipeline, rastreamento de atividades e relatórios.',
            },
            {
                header: 'Integração com CRM',
                paragraph:
                    'Simplifique o gerenciamento de leads, rastreamento de contatos, monitoramento de negócios e sincronização de dados entre as duas plataformas.',
            },
            {
                header: 'Facilitar Colaboração e Comunicação',
                paragraph:
                    'Canais de comunicação centralizados, agendamento de reuniões de vendas, revisões colaborativas de negócios, documentação de processos, colaboração entre funções e promoção da comunicação aberta.',
            },
            {
                header: 'Insights sobre o Desempenho Futuro de Vendas',
                paragraph:
                    'Aproveite a análise de dados e modelagem preditiva para prever resultados e tendências potenciais.',
            },
        ],
        softwareDetails: [
            {
                title: 'Insights Diários do Campo para um Melhor Desempenho de Vendas',
                description: [
                    'Geração de relatórios e relatórios analíticos em tempo real. Recurso de relatório confiável e fácil de usar para acesso e uso.',
                    'Como gerente de vendas, você terá uma visão clara sobre vários relatórios que serão enviados para seu e-mail ou poderão ser visualizados no seu painel de controle.',
                    'Alguns dos relatórios notáveis são relatórios mensais, relatórios por localização de filiais, relatórios específicos de funcionários, etc.',
                ],
            },
            {
                title: 'Visão Geral da Frequência de Trabalho no Campo',
                description: [
                    'Visão geral sobre a frequência de trabalho dos funcionários e visitas de campo.',
                    'Tenha uma visão abrangente sobre a frequência da força de trabalho em campo e reuniões.',
                    'No relatório de frequência de campo, você obterá dados sobre o tempo de marcação de presença, localização, horário de atraso, horas de trabalho, etc.',
                    'Da mesma forma, os relatórios de check-in/check-out de visitas de campo incluem dados relacionados a visitas de campo, como horário de login/logout, horário de check-in/check-out, localização das reuniões, distância percorrida, notas dos resultados das reuniões, etc.',
                ],
            },
            {
                title: 'Geração de Relatórios Baseada em Análise de Dados',
                description: [
                    'Assine e baixe relatórios e-relatórios mensais.',
                    'Você pode assinar e baixar os e-relatórios mensais sempre que desejar.',
                    'Disponível em formato PDF e Excel. Um conjunto completo de relatórios para fornecer informações detalhadas sobre as ações externas de um funcionário fora da organização, o que tende a ser uma tarefa difícil manualmente.',
                ],
            },
        ],
    },
    workflowAutomation: {
        featureHeader: 'Automação de Fluxo de Trabalho',
        featureDescription:
            'Presença baseada em geofencing para garantir que os funcionários possam registrar entrada ou saída apenas quando estiverem dentro dos limites geográficos pré-definidos.',
        featureCaption:
            'A presença baseada em localização garante precisão para monitorar e registrar a presença dos funcionários com base em sua localização física.',
        keyPoints: [
            {
                header: 'Atribuição Automática de Tarefas',
                paragraph:
                    'Automação de fluxo de trabalho para atribuir automaticamente tarefas aos indivíduos mais adequados, considerando fatores como habilidades, disponibilidade, carga de trabalho e níveis de prioridade.',
            },
            {
                header: 'Roteamento, Qualificação e Pontuação de Leads',
                paragraph:
                    'Roteamento, qualificação e pontuação de leads para atribuir leads recebidos aos representantes de vendas apropriados, avaliar sua qualidade e priorizar ações de acompanhamento.',
            },
            {
                header: 'Integração com Sistemas Externos',
                paragraph:
                    'Facilitar uma colaboração mais fluida, melhorar a precisão dos dados e garantir um fluxo de trabalho coeso entre várias funções empresariais.',
            },
            {
                header: 'Monitoramento e Relatórios de Desempenho',
                paragraph:
                    'Acompanhe métricas como tempos de conclusão de processos, taxas de erro, utilização de recursos e produtividade geral.',
            },
        ],
        softwareDetails: [
            {
                title: 'Tempo e localização da força de trabalho.',
                description: [
                    'Saiba Quando e Onde Seus Funcionários Trabalham',
                    'Instrua seus funcionários a conceder permissão em seus telefones móveis para que o rastreamento de tempo e localização possa ser realizado.',
                    'Uma vez concedida a permissão, o rastreador pode começar a funcionar com um toque.',
                ],
            },
            {
                title: 'Relatórios ao Vivo',
                description: [
                    'Implementar um sistema de presença de funcionários baseado em localização pode melhorar a precisão e agilizar o gerenciamento de presença. No entanto, é crucial equilibrar os benefícios com as considerações de privacidade dos funcionários e comunicar-se de forma transparente sobre a implementação e o uso de tais sistemas.',
                ],
            },
            {
                title: 'Alerta Baseado em Geofencing',
                description: [
                    'Configure alertas automatizados para padrões de presença ou discrepâncias incomuns. Isso ajuda a identificar possíveis problemas, como check-ins ou check-outs não autorizados.',
                    'Implemente recursos de relatórios e análises para obter insights sobre padrões, tendências e exceções de presença.',
                ],
            },
        ],
    },
    aIBasedAssistance: {
        featureHeader: 'Assistência Baseada em IA',
        featureDescription:
            'Acompanhamento e análise em tempo real de vários aspectos do trabalho, como presença, tarefas concluídas ou progresso de projetos.',
        featureCaption:
            'Mantenha-se informado sobre as tarefas dos funcionários, atas de reuniões, valide visitas e tome decisões com base nos dados.',
        keyPoints: [
            {
                header: 'Notificações Baseadas em Eventos',
                paragraph:
                    'O administrador ou gerente pode se inscrever ou optar pelas notificações que deseja receber. Isso ajuda a focar nas notificações realmente importantes.',
            },
            {
                header: 'Fique Notificado sobre Presença e Licença',
                paragraph:
                    'Receba notificações sobre presença e licença. Mantenha-se à frente e conheça o número de funcionários antes para que o planejamento do dia seja mais produtivo e eficiente.',
            },
            {
                header: 'Acompanhe Todas as Aprovações',
                paragraph:
                    'Acompanhe todas as aprovações. Monitore todas as aprovações, desde reembolsos até licenças, e visualize-as posteriormente.',
            },
            {
                header: 'Mantenha-se Atualizado com Formulários e Ata de Reuniões',
                paragraph:
                    'Rastreamento remoto através de formulários e atas de reuniões. Formulários personalizados podem ajudar a obter dados específicos para uma reunião que podem ser acessados remotamente.',
            },
        ],
        softwareDetails: [
            {
                title: 'Localize a força de campo em tempo real.',
                description: [
                    'Localize sua equipe de campo em tempo real.',
                    'Obtenha detalhes de localização instantâneos de sua força de trabalho enquanto ela viaja em tempo real.',
                    'Treant permite que você localize e rastreie seus funcionários de campo usando o GPS móvel deles, tanto online quanto offline. Mantenha-se atualizado sobre cada movimento de sua equipe de campo e mantenha um ambiente de trabalho transparente.',
                ],
            },
            {
                title: 'Mantenha-se atualizado com as informações de campo de seus funcionários',
                description: [
                    'Mantenha-se atualizado com as informações de campo de seus funcionários.',
                    'As informações de campo da equipe serão sincronizadas automaticamente com seu painel.',
                    'Detalhes como porcentagem da bateria, GPS, visibilidade de internet ligada/desligada e o tempo ideal que eles passam em campo serão notificados a você instantaneamente em tempo real.',
                ],
            },
            {
                title: 'Automatize dados de reembolsos e subsídios dos funcionários.',
                description: [
                    'Histórico de localização impulsionado por IA para dados precisos.',
                    'Os dados de localização dos seus funcionários serão alimentados por Inteligência Artificial para fornecer informações precisas sobre rotas e rastreamento. Dados de rastreamento ao vivo e outros históricos de localização serão automatizados para sua caixa de entrada em intervalos regulares para uma análise mais profunda do que está acontecendo no campo e fora dele.',
                ],
            },
        ],
    },
    digitizationOfOrder: {
        featureHeader: 'Digitalização de Pedidos',
        featureDescription:
            'Rastreamento e análise em tempo real de vários aspectos do trabalho, como presença, tarefas concluídas ou progresso de projetos.',
        featureCaption: 'Otimize a gestão de pedidos de ponta a ponta e reduza erros manuais.',
        keyPoints: [
            {
                header: 'Relatórios e Análises',
                paragraph:
                    'Gere relatórios detalhados e análises para monitorar o desempenho do processamento de pedidos e a produtividade dos funcionários.',
            },
            {
                header: 'Entrada de Pedidos Automatizada',
                paragraph:
                    'Capture e valide automaticamente os detalhes dos pedidos inseridos por funcionários ou clientes.',
            },
            {
                header: 'Rastreamento e Atualizações de Status de Pedidos em Tempo Real',
                paragraph:
                    'Forneça atualizações em tempo real sobre o status dos pedidos e rastreamento de remessas para funcionários e clientes.',
            },
            {
                header: 'Automação de Fluxos de Trabalho e Atribuição de Tarefas',
                paragraph:
                    'Automatize os processos de aprovação de pedidos e atribua tarefas aos funcionários apropriados.',
            },
        ],
    },
    targetManagement: {
        featureHeader: 'Gestão de Metas',
        featureDescription:
            'Rastreamento e análise em tempo real de vários aspectos do trabalho, como presença, tarefas concluídas ou progresso de projetos.',
        featureCaption:
            'Mantenha-se informado sobre as tarefas dos funcionários, minutos de reuniões, valide visitas e tome decisões com base nos dados.',
        keyPoints: [
            {
                header: 'Integração com Rastreamento de Campo',
                paragraph:
                    'Alcançe metas diárias, semanais e mensais e mantenha registros das conquistas dos funcionários com rastreamento de campo em tempo real.',
            },
            {
                header: 'Atualizações e Sincronização em Tempo Real',
                paragraph:
                    'Progresso em tempo real (número de metas alcançadas vs. restantes), horários de check-in/check-out, número de horas (duração) gastas em cada tarefa e rastreamento de localização.',
            },
            {
                header: 'Desempenho e Análises',
                paragraph:
                    'Métricas de desempenho (taxa de conclusão, tempo gasto, pontuações de satisfação do cliente), comparação com metas (desempenho real vs. meta), dados históricos (tendências de desempenho passadas).',
            },
            {
                header: 'Notificações e Alertas Automatizados',
                paragraph:
                    'Envie notificações automatizadas para manter os funcionários informados sobre alertas de conquista de metas, lembretes de desempenho e avisos de prazos.',
            },
        ],
    },
    invoiceManagement: {
        featureHeader: 'Gestão de Faturas',
        featureDescription:
            'Rastreamento e análise em tempo real de vários aspectos do trabalho, como presença, tarefas concluídas ou progresso de projetos.',
        featureCaption:
            'Crie, acompanhe o status das faturas em tempo real e processe com alertas e notificações automatizados.',
        keyPoints: [
            {
                header: 'Notificações e Lembretes Automatizados',
                paragraph:
                    'Configure sistemas para rastrear o status das faturas e enviar lembretes e alertas automatizados para pagamentos pendentes e vencidos.',
            },
            {
                header: 'Modelos de Faturas',
                paragraph:
                    'Modelos de faturas padronizados que incluem todos os detalhes necessários, como informações do cliente, detalhes do pedido, impostos, descontos e termos de pagamento.',
            },
            {
                header: 'Assinatura Digital e Comprovante de Entrega',
                paragraph:
                    'Coleta de assinaturas digitais e comprovantes de entrega baseados em OTP como parte do processo de fatura.',
            },
            {
                header: 'Sincronização de Faturas e Atualizações em Tempo Real',
                paragraph:
                    'Sincronização automática com o sistema central (armazenamento em nuvem) e atualizações em tempo real sobre o status das faturas.',
            },
        ],
    },
    distributorOrder: {
        featureHeader: 'Pedido de Distribuidor',
        featureDescription:
            'Rastreamento e análise em tempo real de vários aspectos do trabalho, como presença, tarefas concluídas ou progresso de projetos.',
        featureCaption:
            'Mantenha-se informado sobre as tarefas dos funcionários, atas de reunião, valide visitas e tome decisões com base nos dados.',
        keyPoints: [
            {
                header: 'Informações de Distribuição',
                paragraph:
                    'Crie e processe pedidos com todos os detalhes relevantes para cada distribuição e atribua tarefas com base na disponibilidade.',
            },
            {
                header: 'Integração em Tempo Real com Inventário',
                paragraph:
                    'Integre-se com a gestão de inventário para garantir alocação precisa de estoque e atualizar os níveis de inventário em tempo real.',
            },
            {
                header: 'Automatizar Notificações e Alertas',
                paragraph:
                    'Configure o sistema para enviar notificações automáticas em etapas-chave do processo de pedido.',
            },
            {
                header: 'Painel de Funcionários',
                paragraph:
                    'Acesse um painel onde os funcionários podem verificar pedidos, acompanhar o progresso e baixar documentos necessários.',
            },
        ],
    },

    inventoryManagement: {
        featureHeader: 'Gestão de Inventário',
        featureDescription:
            'Rastreamento e análise em tempo real de vários aspectos do trabalho, como presença, tarefas concluídas ou progresso de projetos.',
        featureCaption:
            'Equilíbrio certo de estoque com atualizações em tempo real e rastreamento de inventário para dados precisos.',
        keyPoints: [
            {
                header: 'Integração com Gestão de Pedidos',
                paragraph:
                    'Garanta a integração perfeita entre os sistemas de inventário e gestão de pedidos para sincronizar os níveis de estoque com os pedidos de entrada e saída.',
            },
            {
                header: 'Gestão Multi-localização',
                paragraph:
                    'Rastreie o inventário em vários armazéns, lojas ou locais e acesse o controle centralizado, otimize a distribuição de estoque e melhore a visibilidade.',
            },
        ],
    },

    orderCollectionManagement: {
        featureHeader: 'Gestão de Pedidos/Coleta',
        featureDescription: 'Simplifique as operações de pedidos/coleta com uma solução escalável e automatizada.',
        featureCaption: 'Simplifique as operações de pedidos/coleta com uma solução escalável e automatizada.',
        keyPoints: [
            {
                header: 'Sistema Unificado de Gestão de Pedidos',
                paragraph:
                    'Um sistema centralizado acessível tanto para funcionários no local quanto para funcionários externos para gerenciar pedidos, acompanhar o progresso e atualizar status em tempo real.',
            },
            {
                header: 'Controle de Acesso Baseado em Funções',
                paragraph:
                    'Níveis diferentes de acesso e permissões baseados em funções (por exemplo, gerentes, funcionários no local, funcionários externos) para garantir a segurança dos dados e o acesso apropriado.',
            },
            {
                header: 'Sincronização em Tempo Real',
                paragraph:
                    'Garantir que todos os dados de pedidos sejam sincronizados em tempo real em todos os dispositivos e locais, para que todos tenham acesso às informações mais recentes.',
            },
            {
                header: 'Comunicação e Coordenação',
                paragraph:
                    'Facilitar a comunicação entre funcionários no local e funcionários externos através de mensagens e notificações no aplicativo.',
            },
        ],
    },
};

export default features;
