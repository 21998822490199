const joinUsPage = {
    joinUsString: 'અમારા સાથે જોડાઓ',
    joinUsHeroPara:
        'TraceForce માં અમારી ટીમનો ભાગ બની શકો છો! જ્યાં ટીમવર્ક અને ક્રિએટિવિટી માર્ગદર્શન આપે છે તે એવા ઉત્સાહભર્યા અવસરોને શોધો. સમજણદાર સોફ્ટવેર સોલ્યુશન્સ દ્વારા વ્યવસાયોকে સશક્ત બનાવવાની અમારી મિશન માટે તમે કેવી રીતે યોગદાન આપી શકો તે શોધો. તમારા અનોખા પ્રતિભા અને વિચારોને મૂલ્ય આપતી સમર્થક વાતાવરણમાં તમારા કારકિર્દીનો વિકાસ કરો.',
    ourRecruitmentProcess: 'અમારા ભરતી પ્રક્રિયા',
    ourBenifitsString: 'અમારા લાભો',
    ourRecruitmentProcessObjects: [
        {
            title: 'ટેકનિકલ ઇન્ટરવ્યૂ',
            description:
                'આ ઇન્ટરવ્યૂ તમારા ટેકનિકલ કુશળતાઓ, સમસ્યા-સમાધાનની ક્ષમતા, અને તમે કેવી રીતે તમારી જાણકારીને વાસ્તવિક-વિશ્વની પરિસ્થિતિમાં લાગુ કરતાં છો તે મૂલવવા માટે ડિઝાઇન કરવામાં આવી છે. અમે તમારા ટેકનિકલ કુશળતા, કોડિંગ ક્ષમતાઓ, અને પડકારોને ઠાલવવા માટે તમારા અભિગમ પર દૃષ્ટિ મેળવવા માંગીએ છીએ. આ રાઉન્ડ મીટ/સ્કાઇપ કોલ દ્વારા કરવામાં આવે છે.',
        },
        {
            title: 'પ્રેક્ટિસ ટેસ્ટ (ઍપ્ટિટ્યુડ)',
            description:
                'અમે તમારા કોડિંગ લોજિક અને કોડ ઑપ્ટિમાઇઝેશનને મૂલવીએ છીએ, જેમાં તમારા પ્રથમ રાઉન્ડ પછી તમારું ઇન્ટરવ્યૂ આધારિત કાર્ય ફાળવવામાં આવશે. તે નિર્ધારિત સમયસીમા અંદર પૂરી કરવા માટે ઉમેદવારને આપવામાં આવશે.',
        },
        {
            title: 'મેનેજમેન્ટ ઇન્ટરવ્યૂ',
            description:
                'અમારા મેનેજમેન્ટ સાથે ઝડપી વાતચીત કરીને તમારી કારકિર્દી દૃષ્ટિ અને અમારી સંસ્થામાં સાંસ્કૃતિક સુસંગતતા સમજવા માટે. અમે સોલિડ વિષય વિશેષજ્ઞાન અને મજબૂત કાર્ય નૈતિકતા ધરાવતી વ્યક્તિને શોધીએ છીએ.',
        },
        {
            title: 'ફાઇનલ સિલેક્શન',
            description:
                'એચઆર ટીમ સાથે ઓફર ફાઇનલાઇઝેશન કરવામાં આવશે. અમે તમામ ઇન્ટરવ્યૂ રાઉન્ડ્સમાં ઉમેદવારના કાર્યની વિગતવાર સમીક્ષાની સાથે ફાઇનલ સિલેક્શન કરીએ છીએ. પારસ્પરિક નિર્ણય આધારે જોડણી તારીખોને સુનિશ્ચિત કરવામાં આવશે, અમે તમને પ્રથમ દિવસે શ્રેષ્ઠ ટૂલ્સ આપીએ છીએ.',
        },
    ],
    ourBenifitsObjects: [
        {
            name: 'ક્યાંથી પણ કાર્ય કરો!',
            description: 'અમે તમને દરરોજ જોવાનું પસંદ કરીએ છીએ, આથી, કાર્ય માટે ક્યાંથી પણ ઉપલબ્ધ નથી.',
        },
        {
            name: 'પરસ્પર અઠવાડિયાઓ',
            description:
                'તમે જ્યાં તમારા મૂડ સ્વિંગ્સની જેમ બદલાતા અઠવાડિયાઓ અનુભવતા હોય તે જગ્યાએ. એક અઠવાડિયે, તમે મોજમસ્તી ભરી યાત્રાની યોજના બનાવતાં હશો અથવા નિંદ્રા લેવા, અને બીજા અઠવાડિયે, તમે ડેડલાઇનને હેરાન કરતા અને ઇમેઇલ્સનો જવાબ આપતા હશો. તે તમામ કાર્ય અને રમતો વચ્ચે સંતુલન બનાવવાનો ભાગ છે.',
        },
        {
            name: 'સ્વાસ્થ્ય બીમો',
            description:
                "આ ‘હું આ માટે ડોક્ટર જોઈ શકું છું’ પળો માટે. વ્યાપક કવરેજ સાથે, તમે 'શું જો' વિષે ચિંતા કરવાનું નથી—ફક્ત સુધારવા અને તમારી શ્રેષ્ઠ જાતિ પર પાછા આવવા પર ધ્યાન કેન્દ્રિત કરો. કારણ કે જ્યારે તમે તમારી શ્રેષ્ઠ લાગતા નથી, ત્યારે તમે શ્રેષ્ઠ કાળજી લાયક છો.",
        },
        {
            name: 'સૂચન બોનસ',
            description:
                'ફ્રેન્ડ્સ છે? બોનસ મેળવો! આ એક જીત-જીત છે. કલ્પના કરો: તમારી પાસે તમારા મિત્રોનો દાયરો છે જેઓ તે જે કરે છે તેમાં અદ્ભુત છે અને નવી તક શોધી રહ્યા છે. તેમને અમારી કંપની તરફ સૂચવ્યા દ્વારા, તમે તેમને એક અદ્ભુત નોકરી શોધવામાં મદદ કરી રહ્યા છો, પરંતુ તમે પણ એક મીઠી સુચન બોનસ મેળવી રહ્યા છો.',
        },
        {
            name: 'કર્મચારી કીટ',
            description:
                'કર્મચારી કીટ - તે બધા નમ્ર અથવા પ્રેરણાદાયક વસ્તુઓ સાથે, જે કાર્યદિનને પરાજિત કરવા માટે જરૂરી છે. પેન જરૂર છે? ચેક. તમારી તેજસ્વી વિચારાઓ માટે નોટબુક? ચેક.',
        },
        {
            name: 'લવચીક કાર્ય કલાકો',
            description:
                'હવે તમારા ઓફિસને તમારા સુયોજીત સ્થળમાં બદલવામાં આવે છે, અને તમારા બ્રેક સમયને પ્રાઇમ ટાઇમ બનાવવામાં આવે છે. લવચીક કલાકો સાથે, તમે સરળતાથી સંમેલન કોલ્સથી ક્લિફહેંગર સુધી, સ્પ્રેડશીટ્સથી નેટફ્લિક્સ શોની બinge-વેચિંગ સુધી પરિવર્તન કરી શકો છો.',
        },
        {
            name: 'સબેટિકલ લીવ',
            description: 'સબેટિકલ લીવ',
        },
        {
            name: 'પિતૃત્વ/માતૃત્વ લિવ',
            description: 'પિતૃત્વ/માતૃત્વ લિવ',
        },
        {
            name: 'કામ-જીવન સંતુલન',
            description: 'કામ-જીવન સંતુલન',
        },
        {
            name: 'આફિસ ઉજવણી',
            description: 'આફિસ ઉજવણી',
        },
    ],
    jobsAtTraceForce: 'TraceForce ખાતે નોકરીઓ',
};
export default joinUsPage;
