import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import arebic from './locale/arabic/data';
import hindi from './locale/hindi/data';
import english from './locale/english/data';
import french from './locale/french/data';
import spanish from './locale/spanish/data';
import indonesian from './locale/indonesian/data';
import tamil from './locale/tamil/data';
import portuguese from './locale/portuguese/data';
import bengali from './locale/bengali/data';
import russian from './locale/russian/data';
import gujarati from './locale/gujarati/data';
const resources = {
    en: {
        translation: english,
    },
    hi: {
        translation: hindi,
    },
    ar: {
        translation: arebic,
    },
    fr: {
        translation: french,
    },
    sp: {
        translation: spanish,
    },
    id: {
        translation: indonesian,
    },
    ta: {
        translation: tamil,
    },
    pt: {
        translation: portuguese,
    },
    bn: {
        translation: bengali,
    },
    rn: {
        translation: russian,
    },
    gu: {
        translation: gujarati,
    },
};

i18next.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    fallbackLng: 'en',
    // lng: 'en',
    resources: resources,
});
