const products = {
    attendanceManagement: {
        productName: 'હાજરી વ્યવસ્થા',
        titleDescription: 'આસાન સેટઅપ, આપમેળે ચેક ઇન અને ચેક આઉટ, રિપોર્ટ્સ',
        productCards: [
            {
                title: 'કાર્યજીવન સંતુલન',
                data: 'ટ્રેસફોર્સની યાદીઓથી તમારા કર્મચારીઓના કાર્યજીવન સંતુલનને જાળવો.',
            },
            {
                title: 'સદસ્ય પ્રકારો',
                data: 'કાર્યસ્થળના સંચાલનને સરળ બનાવો જેમ કે કાર્યકારી મેનેજર, પ્રોજેક્ટ મેનેજર, ટીમ મેનેજર અને પ્રોજેક્ટ દ્રષ્ટાગોચક.',
            },
            {
                title: 'ટ્રેક કરવામાં મદદ કરે છે',
                data: 'ટ્રેસફોર્સના સ્માર્ટ ટાઇમશીટ્સ કાર્ય સંચાલનને સરળ બનાવે છે જે એક વિગતવાર શીટ સ્ટાઇલમાં સક્રિય કલાકો, નિષ્ક્રિય કલાકો અને ઉત્પાદન કલાકોનો વિઘટન દર્શાવે છે.',
            },
        ],
        whyChooseSubCaption:
            'અત્યંત કાર્યક્ષમ કાર્યસ્થળ વાતાવરણ તરફ પ્રવૃત્તિ કરો અને વધારેલા ઉત્પાદન અને સહકારનો અનુભવ કરો. કાર્ડોમાં માહિતી કેન્દ્રિત કરો.',
        headerCaption: 'ટ્રેસફોર્સ હાજરી વ્યવસ્થાનો કેમ પસંદ કરવો',
        headerT1: 'હાજરી ટ્રેકિંગ સુવિધાઓ જે વધુ કામ કરે છે',
        subCaptionT1:
            'કર્મચારી ટ્રેકિંગ, દૂરસ્થ કાર્ય નિરીક્ષણ, ઉત્પાદન નિરીક્ષણ, હાજરી વ્યવસ્થાપન, ટાઇમશીટ્સ, અને વધુનો અનુભવ કરો - એડવાન્સ્ડ ફંક્શનલિટીસ સાથે.',
        features: {
            attendanceMonitoring: {
                featureHeader: 'હાજરી મોનિટરિંગ',
                featureDescription:
                    'જિયોફેન્સ ક્ષેત્ર અથવા બ્રાન્ચની અંદર વ્યક્તિઓની હાજરી અથવા ગેરહાજરી વિશે વાસ્તવિક સમયની માહિતી.',
                featureParagraph:
                    'કર્મચારીની હાજરી તપાસવા માટે હવે વધુ ટાઇમકાર્ડ્સ, વધારે કાગળપત્ર અને અલગ અલગ દસ્તાવેજો નથી. વેબવર્ક ટાઇમ ટ્રેકર સાથે હાજરી મોનિટરિંગ પ્રક્રિયાને આપમેળે બનાવો અને તમારા કર્મચારીઓના કાર્યકાળની શરૂઆત અને અંત એક સારું-સ્થાપિત સ્વરૂપમાં જુઓ.',
                fIIPoints: [
                    'જિયોફેન્સ ક્ષેત્ર અથવા બ્રાન્ચની અંદર વ્યક્તિઓની હાજરી અથવા ગેરહાજરી વિશે વાસ્તવિક સમયની માહિતી',
                    'દૈનિક, સાપ્તાહિક અને માસિકના આધારે કાર્યકાળને મોનિટર કરો',
                    'બહુવિધ બ્રાન્ચોના હાજરીના રિપોર્ટો remotely ઍક્સેસ કરો',
                    'હાજરી સંબંધિત માહિતી, પ્રક્રિયાઓ અને સિસ્ટમોને એક એકીકૃત પ્લેટફોર્મ અથવા સિસ્ટમમાં સંકલિત કરો',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'વાસ્તવિક સમયની હાજરી',
                        paragraph:
                            'જિયોફેન્સ ક્ષેત્ર અથવા બ્રાન્ચની અંદર વ્યક્તિઓની હાજરી અથવા ગેરહાજરી વિશે વાસ્તવિક સમયની માહિતી',
                    },
                    totalWorkingHoursCalculation: {
                        header: 'કુલ કાર્યકાળની ગણતરી',
                        paragraph: 'દૈનિક, સાપ્તાહિક અને માસિકના આધારે કાર્યકાળને મોનિટર કરો',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'દૂરસ્થ ઍક્સેસ હાજરી રિપોર્ટ્સ',
                        paragraph: 'બહુવિધ બ્રાન્ચોના હાજરીના રિપોર્ટો remotely ઍક્સેસ કરો',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'હાજરી મોનિટરિંગ એક',
                        description: [
                            'કર્મચારીઓની સુઝતા પર પારદર્શિતા મેળવો',
                            'કર્મચારી હાજરીને કોઈપણ વધારાની ક્રિયા વિના બચાવો અને સંચાલિત કરો.',
                            'આસાનીથી મોનિટર કરો કે તમારા કર્મચારીઓ કાર્યસ્થળ પર સમયસર આવ્યા છે કે નહીં અને તેમના કાર્યકાળ પૂરા કર્યા છે કે નહીં.',
                            'તમારા કાર્યસ્થળના ઓપરેશન્સને વધુ સુવિધિત બનાવો અને એક ઉચ્ચ જવાબદાર કાર્ય વાતાવરણ જાળવો.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'હાજરી મોનિટરિંગ બે',
                        description: [
                            'હાજરી માહિતીના બુદ્ધિશાળી વિશ્લેષણ',
                            'પ્રારંભિક છૂટું, મોડું આવવું, અને ઓછી ટ્રેકિંગ જે તમે ડ્રોપ-ડાઉન યાદીમાંથી પસંદ કરી શકો છો.',
                            'આ તમારું પસંદગી કરેલું માહિતી તરત જ જનરેટ કરવામાં મદદ કરશે, જેથી તમારે તેને મેન્યુઅલી શોધવું ન પડે.',
                            'તમે દૈનિક આધાર પર હાજરીને ટ્રેક કરી શકો છો અથવા કેલેન્ડરમાંથી અંતર પસંદ કરી શકો છો.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'હાજરી મોનિટરિંગ ત્રણ',
                        description: [
                            '100% ટચલેસ સિસ્ટમ સાથે કર્મચારીની હાજરીને વાસ્તવિક સમયમાં મોનિટર અને ટ્રેક કરવાનો સુરક્ષિત અને પારદર્શી રસ્તો.',
                            'આસાનીથી સેટઅપ',
                            'આપમેળે ચેક ઇન અને આઉટ્સ વિગતવાર રિપોર્ટ્સ',
                            'ટ્રેકિંગટાઇમ સાથે સ્ક્રીનશોટ',
                            'કૃયાપ્રવૃત્તિ સ્તરની રેકોર્ડિંગ',
                            'એપ્સ અને વેબસાઇટ ઉપયોગ મોનિટરિંગ',
                        ],
                    },
                },
            },
            shiftManagement: {
                featureHeader: 'શિફ્ટ મેનેજમેન્ટ',
                featureDescription:
                    'ઉત્પાદકતા વધારવા માટે, કાર્યકારી જરૂરિયાતોને પૂરી કરવા માટે, અને વિવિધ સમયગાળામાં કવરેજ સુનિશ્ચિત કરવા માટે કાર્યકાળને સંગઠિત અને શેડ્યૂલ કરો.',
                fIIPoints: [
                    'સફળ અને સારી રીતે સંસ્થિત શિફ્ટ શેડ્યૂલ વિકસાવો. તમારા વ્યવસાયની જાતિ આધારિત વિવિધ પ્રકારના શિફ્ટ્સ વ્યાખ્યાયિત કરો.',
                    'વિશિષ્ટ શિફ્ટ્સ દરમિયાન કેટલા કર્મચારીઓની જરૂર છે તે નક્કી કરવા માટે કાર્યभार અને કાર્યોનું વિશ્લેષણ કરો',
                    'કર્મચારીઓ માટે તેમના શેડ્યૂલ્સ ઍક્સેસ કરવા માટે એક કેન્દ્રિત પ્લેટફોર્મ, અને કોઈપણ અપડેટ્સ અથવા બદલાવ માટે વિશ્વસનીય સંચાર ચેનલ સ્થાપિત કરો.',
                    'કર્મચારીઓના શેડ્યૂલ્સની કેન્દ્રિત નિરીક્ષણ અને નિયંત્રણ, મેનેજરોને કાર્યક્ષમ રીતે શિફ્ટ્સ ફાળવવા, હાજરી ટ્રેક કરવા, અને મલ્ટિ-લોકેશન્સમાં કામદારોના સંસાધનોને સંચાલિત કરવાની પરવાનગી આપે છે.',
                ],
            },
            geoFenceBasedManagement: {
                featureHeader: 'જિયોફેન્સ આધારિત હાજરી',
                featureDescription:
                    'નિર્ધારિત ભૂગોળિક સીમાઓની અંદર કર્મચારીની હાજરી મોનિટર અને માન્ય કરવા માટે સ્થાન આધારિત ટેકનોલોજી.',
                fIIPoints: [
                    'કર્મચારીઓના નિર્ધારિત કાર્યસ્થળો પર હાજરીની ચોકસાઈ.',
                    'કર્મચારીઓના સ્થાનને જિયોફેન્સની અંદર મોનિટર કરવા માટે વાસ્તવિક સમય ટ્રેકિંગ અમલમાં લાવવું.',
                    'કામની જગ્યાની આસપાસ અથવા નિર્ધારિત હાજરી સ્થળો પર ખાસ ભૂગોળિક સીમાઓની સ્થાપના કરો.',
                    'કર્મચારીઓdefined.geoFence સીમાઓને પ્રવેશ અથવા બહાર જવાની મૉનિટર કરવા માટે એલર્ટ અથવા સૂચનાઓ સેટ અપ કરો.',
                ],
            },
            multipleClockInOptions: {
                featureHeader: 'બહુવિધ ક્લોક ઇન વિકલ્પો',
                featureDescription:
                    'કર્મચારીઓને બાયોમેટ્રિક સ્કેનર્સ, મોબાઇલ એપ્લિકેશન્સ, ટાઇમ ક્લોક્સ અને વેબ આધારિત પ્લેટફોર્મનો ઉપયોગ કરીને તેમના કાર્યકાળને રેકોર્ડ કરવાની લવચીકતા પ્રદાન કરો.',
                fIIPoints: [
                    'અનુકૂળClockIn વિકલ્પો સાથે અનેક સ્થળો સાથે સંસ્થાઓ માટે યોગ્યુ',
                    'કર્મચારીઓને તેમની હાજરી નોંધાણીઓ માટે સ્વ-સેવા પોર્ટલ ઍક્સેસ પ્રદાન કરો અને જિયોફેન્સની અંદર ચેક-ઇન્સ અને ચેક-આઉટ્સ મેનેજ કરો.',
                    'ફ્લેક્સીબલ ક્લોક-ઇન વિકલ્પોમાં બાયોમેટ્રિક સ્કેન, મોબાઇલ એપ્લિકેશન્સ, RFID કાર્ડ અને ચહેરા ઓળખવા શક્યતાઓનો સમાવેશ થાય છે.',
                    'દફતર સીમાના વિસ્તારની અંદર કર્મચારીઓ માટે આપમેળે ક્લોક-ઇન હાજરી નિશાન બનાવવું સાથે એલર્ટ સૂચનાઓ.',
                ],
            },
            attendanceReport: {
                featureHeader: 'હાજરી રિપોર્ટ્સ',
                featureDescription:
                    'કર્મચારીની હાજરીની માહિતી, કાર્યકાળ, ગેરહાજરી, મોડું આવવું, અને વહેલું નિકાસને ચોક્કસ સમયગાળામાં સંક્ષિપ્ત રૂપમાં દર્શાવો.',
                fIIPoints: [
                    'હાજરીનો સંપૂર્ણ સારાંશ પ્રદાન કરો, જેમાં કુલ કામકાજનાં દિવસો, કુલ કલાકો, અને કોઈપણ ઓવરટાઇમ કલાકોનો સમાવેશ થાય છે.',
                    'બહુવિધ બ્રાન્ચના હાજરીના રિપોર્ટોને કસ્ટમાઇઝેબલ શ્રેણીઓ સાથે remotely ઍક્સેસ કરો.',
                    'પ્રતિ વ્યક્તિ, વિભાગ, અથવા ટીમ માટે ઉપલબ્ધ વેકેશન દિવસો, બીમારી છૂટ, અને અન્ય પ્રકારની છૂટના સંતુલનો દર્શાવો.',
                    'વિશિષ્ટ માનદંડો મુજબ રિપોર્ટ્સને કસ્ટમાઇઝ કરવા માટે વપરાશકર્તાઓને મંજૂરી આપો, જેમ કે વિભાગ, ટીમ, અથવા વ્યક્તિગત કર્મચારી.',
                ],
            },
        },
    },
    fieldTrackingManagement: {
        productName: 'ફીલ્ડ ફોર્સ મેનેજમેન્ટ',
        titleDescription: 'ફીલ્ડ સેલ્સ અને સર્વિસ ઓપરેશન લાઇવ રિપોર્ટિંગ અને બીટ રાઉટ સાથે',
        productCards: [
            {
                title: 'ઘટાડેલા ઓવરહેડ્સ',
                data: 'કર્મચારીના શેડ્યૂલ્સને આપમેળે કરીને ઓપરેશનલ ખર્ચને ઘટાડો. કર્મચારીઓના સમય, કાર્ય અને ખર્ચને ટ્રેક કરો અને તે વિસ્તારોને ઓળખો જ્યાં તમે પૈસા બચાવી શકો.',
            },
            {
                title: 'ઉત્તમ ગ્રાહક મૂલ્ય',
                data: 'શેડ્યૂલ ગેરંટી અને કાર્યક્ષમ સેવા સુનિશ્ચિત કરો. ગ્રાહકની ક્રિયાઓ અને પ્રતિસાદને ટ્રેક કરો, ખોટ અને સુધારણા માટે વિસ્તારોને સરળતાથી ઓળખવા માટે.',
            },
            {
                title: 'કર્મક્ષમતા વિશ્લેષણ',
                data: 'એકસાથે ડેશબોર્ડ પર વ્યાપક રિપોર્ટ્સને સરળતાથી જનરેટ કરો, જે તમારા કાર્યફોર્સની કામગીરી, નોકરી સમયરેખા, ગ્રાહક સંતોષ અને સુધારણા માટે વિસ્તારોમાં મૂલ્યવાન માહિતી પ્રદાન કરે છે.',
            },
        ],
        whyChooseSubCaption:
            'ફીલ્ડ ફોર્સ મેનેજમેન્ટ સોફ્ટવેર સાથે પીક-એફિશિયન્સી\nઉપશીર્ષક - તમારા ફીલ્ડ કર્મચારીઓને વાસ્તવિક સમયમાં ટ્રેક કરો, કાર્ય પ્રગતિ મોનિટર કરો, અને તેમની સ્થિતિ, પ્રવૃતિ અને કામગીરી વિશેની માહિતી મેળવો. જવાબદારીમાં સુધારો કરો અને ડેટા-આધારિત નિર્ણયો કરવા માટે ઉપયોગ કરો.',
        headerCaption: 'ટ્રેસફોર્સ ફીલ્ડ ફોર્સ મેનેજમેન્ટને કેમ પસંદ કરવું',
        headerT1: 'ફીલ્ડ ફોર્સ મેનેજમેન્ટ સિસ્ટમ્સની હાઇલાઇટ્સ',
        subCaptionT1:
            'વિશિષ્ટ મેટ્રિક્સને ફીલ્ડ સેલ્સ એનાલિટિક્સ સાથે સંયોજન કરો જે ઓપરેશન્સ, લાઇવ રિપોર્ટિંગ માટે તરત જ જાણકારી પ્રદાન કરે છે, અને પરફોર્મન્સને વધારવા માટે ફીલ્ડ સેલ્સ એનાલિટિક્સનો ઉપયોગ કરો. કાર્યક્ષમ સંસાધન ફાળવણી, સુધારેલી ઉત્પાદનક્ષમતા, અને ડેટા-આધારિત નિર્ણય-મેકિંગ સુનિશ્ચિત કરો.',

        features: {
            workforceTracking: {
                featureHeader: 'કર્મશક્તિ ટ્રેકિંગ',
                featureDescription: 'કર્મચારીઓની પ્રવૃતિઓ, ઉત્પાદકતા અને હાજરીનું આયોજિત મોનિટરિંગ અને મેનેજમેન્ટ.',
                featureParagraph:
                    'કર્મચારીઓની હાજરી તપાસવા માટે હવે વધુ ટાઇમકાર્ડ્સ, વધારે કાગળપત્ર અને અલગ-अलग દસ્તાવેજો નથી. વેબવર્ક ટાઇમ ટ્રેકર સાથે હાજરી મોનિટરિંગ પ્રક્રિયાને આપમેળે બનાવો અને તમારા કર્મચારીઓના કાર્યકાળની શરૂઆત અને અંત એક સારી રીતે આયોજન કરેલા સ્વરૂપમાં જુઓ.',
                fIIPoints: [
                    'ક્લોક ઇન અને ક્લોક આઉટ જેવી ઘટનાઓ મોનિટર કરો અને કર્મચારીઓની પ્રવૃત્તિઓ, કાર્યસાધન મેટ્રિક્સ, અથવા ઘટનાઓ પર જુઓ.',
                    'સેમિનાર અને કાર્યો માટે ડેટા સંગ્રહો, ટ્રેન્ડ્સ અને ડેટા-આધારિત નિર્ણય-મેકિંગ માટે મિનિટ્સ ઓફ મીટિંગને ટ્રેક કરો.',
                    'ગ્રાહક અને લીડ મેનેજમેન્ટથી ઉત્પાદકતાનો ટ્રેક રાખો, કર્મચારીઓની ઉત્પાદકતા અને મુખ્ય કામગીરી સૂચકાંકો કાર્ય પૂર્ણ થેલા કામની જમાણે માપી શકાય છે.',
                    'ફીલ્ડ વર્ક માટે કર્મચારીઓના ચોક્કસ અંતર ટ્રેક કરો, દૈનિક, સાપ્તાહિક અને માસિક આધાર પર, અને અંતર અને પ્રવાસિત સ્થળોને અનુરૂપ ખર્ચ ચૂકવો.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'સમયરેખા આધારિત હાજરી ટ્રેકિંગ',
                        paragraph:
                            'હાજરી અને હળવાવિહોણા ઘટનાઓ, જેમ કે ક્લોક ઇન અને ક્લોક આઉટ, તેમજ કર્મચારીઓની પ્રવૃત્તિઓ, કાર્યસાધન મેટ્રિક્સ, અથવા ઘટનાઓને મોનિટર કરો.',
                    },
                    customFormsforDataManagement: {
                        header: 'ડેટા મેનેજમેન્ટ માટે કસ્ટમ ફોર્મ્સ',
                        paragraph: 'દૈનિક, સાપ્તાહિક, અને માસિક આધાર પર કાર્યકાળને મોનિટર કરો.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'દૂરસ્થ ઍક્સેસ હાજરી રિપોર્ટ્સ',
                        paragraph: 'બહુવિધ બ્રાન્ચોના હાજરીના રિપોર્ટો remotely ઍક્સેસ કરો.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'હાજરી મોનિટરિંગ એક',
                        description: [
                            'કર્મચારીઓની સમયપાલન પર પારદર્શિતા મેળવો',
                            'કર્મચારીની હાજરીને કોઈપણ વધારાની ક્રિયા વિના બચાવો અને સંચાલિત કરો.',
                            'આસાનીથી મોનિટર કરો કે તમારા કર્મચારીઓ કાર્યસ્થળ પર સમયસર આવ્યા છે કે નહીં અને તેમના કાર્યકાળ પૂરા કર્યા છે કે નહીં.',
                            'તમારા કાર્યસ્થળના ઓપરેશન્સને વધુ સુવિધિત બનાવો અને એક ઉચ્ચ જવાબદાર કાર્ય વાતાવરણ જાળવો.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'હાજરી મોનિટરિંગ બે',
                        description: [
                            'હાજરી ડેટાનું બુદ્ધિશાળી વિશ્લેષણ',
                            'પ્રારંભિક છૂટું, મોડું આવવું, અને ઓછી ટ્રેકિંગ જે તમે ડ્રોપ-ડાઉન યાદીમાંથી પસંદ કરી શકો છો.',
                            'આ તમારું પસંદગી કરેલું માહિતી તરત જ જનરેટ કરવામાં મદદ કરશે, જેથી તમારે તેને મેન્યુઅલી શોધવું ન પડે.',
                            'તમે દૈનિક આધાર પર હાજરીને ટ્રેક કરી શકો છો અથવા કેલેન્ડરમાંથી અંતર પસંદ કરી શકો છો.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        imagePath: '',
                        title: 'હાજરી મોનિટરિંગ ત્રણ',
                        description: [
                            '100% ટચલેસ સિસ્ટમ સાથે કર્મચારીની હાજરીને વાસ્તવિક સમયે મોનિટર અને ટ્રેક કરવાનો સુરક્ષિત અને પારદર્શી રસ્તો.',
                            'આસાનીથી સેટઅપ',
                            'આપમેળે ચેક ઇન અને આઉટ્સ વિગતવાર રિપોર્ટ્સ',
                            'ટ્રેકિંગટાઇમ સાથે સ્ક્રીનશોટ',
                            'કૃયાપ્રવૃત્તિ સ્તરની રેકોર્ડિંગ',
                            'એપ્સ અને વેબસાઇટ ઉપયોગ મોનિટરિંગ',
                        ],
                    },
                },
            },
            fieldSalesAnalytics: {
                featureHeader: 'ફીલ્ડ સેલ્સ એનાલિટિક્સ',
                featureDescription:
                    'ફીલ્ડમાં સેલ્સ ટીમના મુખ્ય મેટ્રિક્સને ટ્રેક અને વિશ્લેષણ કરો જેમ કે સેલ્સ આવક, ગ્રાહક ક્રિયાઓ, અને ભૂગોળિક ટ્રેન્ડ્સ.',
                featureParagraph:
                    'કર્મચારીઓની હાજરી તપાસવા માટે હવે વધુ ટાઇમકાર્ડ્સ, વધારે કાગળપત્ર અને અલગ અલગ દસ્તાવેજો નથી. વેબવર્ક ટાઇમ ટ્રેકર સાથે હાજરી મોનિટરિંગ પ્રક્રિયાને આપમેળે બનાવો અને તમારા કર્મચારીઓના કાર્યકાળની શરૂઆત અને અંત એક સારી રીતે આયોજન કરેલા સ્વરૂપમાં જુઓ.',
                fIIPoints: [
                    'મુખ્ય કામગીરી સૂચકાંકો ડેટા આધારિત નિર્ણય-મેકિંગમાં મદદ કરે છે, જે આગામી સમયે અથવા વ્યૂહને પુનઃલેખિત કરવા માટે ઉત્પાદનક સત્તાવાર નિર્ણય લેવાની મદદ કરે છે.',
                    'સેલ્સ, લીડ અને ગ્રાહક વિમોચન પર દૈનિક, સાપ્તાહિક અથવા માસિક આધાર પર રિપોર્ટ્સ ઍક્સેસ કરી શકાય છે, આ વ્યક્તિની ઉત્પાદનક્ષમતા અને કાર્યક્ષમતા માટે મદદરૂપ છે.',
                    'જીઓફેન્સ આધારિત ચેક-ઇન્સથી મુલાકાતને માન્ય કરો. સ્થાનના સંકલનોને ફોટા સાથે જોડાવી શકાય છે.',
                    'કર્મચારીઓ માટે ક્લાયન્ટ મુલાકાત માટે બીટ પ્લાન અથવા બીટ પ્લાનની યોજના માટે બીટ રાઉટનો ઉપયોગ કરી શકાય છે. નેવિગેશનને ઑપ્ટિમાઇઝ કરવા માટે સીધા મેપ્સમાં તમારા બીટ પ્લાનને એકત્રિત કરો.',
                ],
            },
            locationBasedAttendance: {
                featureHeader: 'સ્થાન આધારિત હાજરી',
                featureDescription:
                    'જીયો ફેન્સ આધારિત હાજરી સુનિશ્ચિત કરે છે કે કર્મચારીઓ ફક્ત તે સમયે ક્લોક ઇન અથવા આઉટ કરી શકે છે જ્યારે તેઓ પૂર્વનિર્ધારિત ભૂગોળની મર્યાદાઓની અંદર હોય.',
                fIIPoints: [
                    'વિશિષ્ટ કાર્યસ્થળ અથવા ઓફિસના પ્રદેશોની આસપાસના જીયો ફેન્સ ઇન વિરુદ્ધથી ઓટોમેટેડ હાજરી સુનિશ્ચિત કરો.',
                    'હાજરી ડેટાના ચોકસાઈ અને સુરક્ષાને સુનિશ્ચિત કરવા માટે PINs, બાયોઇમેટ્રિક અથવા ચહેરા હાજરીનો ઉપયોગ કરીને સત્તાવિહિત પદ્ધતિઓ.',
                    'હાજરીના રેકોર્ડોને સમયસર અપડેટ કરો, વ્યવસ્થાપન નિર્ણયોને સમયસર બનાવવાની પરવાનગી આપે છે.',
                    'દૂરના કર્મચારીઓ માટે હાજરી ટ્રેકિંગ માટે વિકલ્પ વિધિઓને વિચારણા કરો.',
                ],
            },
            liveReporting: {
                featureHeader: 'લાઇવ રિપોર્ટિંગ',
                featureDescription:
                    'કર્મચારીઓની હાજરી, પૂર્ણ થયેલ કાર્યો અથવા પ્રોજેક્ટ પ્રગતિ જેવા વિવિધ પાસાઓની વાસ્તવિક સમય ટ્રેકિંગ અને વિશ્લેષણ.',
                fIIPoints: [
                    'એડમિન અથવા મેનેજર તે સૂચનાઓને સબસ્ક્રાઇબ કરી શકે છે અથવા પસંદ કરી શકે છે જે તેઓ પ્રાપ્ત કરવા માંગે છે. આ સૂચનાઓ પર ધ્યાન કેન્દ્રિત કરવામાં મદદ કરશે.',
                    'હાજરી અને છૂટ અંગે અપડેટ રહેવું, અને દિવસની યોજના વધુ ઉત્પાદનક્ષમ અને કાર્યક્ષમ બની શકે છે.',
                    'બધા મંજૂર ટ્રેક કરો, રિંબરસમેન્ટથી લઈને છૂટ સુધી, અને પછીથી તેમને જુઓ.',
                    'ફોર્મ્સ અને મિનિટ્સ ઓફ મીટિંગ દ્વારા રીમોટ ટ્રેકિંગ, કસ્ટમ ફોર્મ્સ ખાસ ડેટા મેળવવામાં મદદ કરી શકે છે જે દૂરસ્થ રીતે ઍક્સેસ કરી શકાય છે.',
                ],
            },
            aIBasedOptimization: {
                featureHeader: 'AI આધારિત ઓપ્ટિમાઇઝેશન',
                featureDescription:
                    'ફીલ્ડ પ્રતિનિધિઓ અથવા સેલ્સ કર્મચારીઓ માટે બીટ રાઉટ આધારિત AI દ્વારા માર્ગોની યોજના અને ઓટોમેટ કરો.',
                fIIPoints: [
                    'જીવન ટ્રાફિક અપડેટ્સ, હવા ની સ્થિતિ, અને માર્ગબંધનો જેવા રિયલ-ટાઇમ ડેટા સ્ત્રોતોનો ઉપયોગ કરીને હાલની પરિસ્થિતિઓના આધારે માર્ગોને ગતિશીલ રીતે એડજસ્ટ કરો, દિવસની યોજના વધુ કાર્યક્ષમ બનાવે છે.',
                    'ઓપ્ટિમાઇઝ કરેલ માર્ગો જુઓ અને જરૂરી ફેરફારો કરો. આ મોબાઇલ એપ્લિકેશન અથવા વેબ-આધારિત પ્લેટફોર્મનો ભાગ હોઈ શકે છે.',
                    'AI સિસ્ટમ બીટ્સ અથવા માર્ગોની અનન્ય વિશેષતાઓને ધ્યાનમાં રાખે છે.',
                    'મૅપિંગ સેવાઓ અને ભૂગોળ માહિતીનો ઉપયોગ કરીને માર્ગ નેટવર્ક, ટ્રાફિક સ્થિતિ, અને અન્ય સ્થાન સંબંધિત માહિતી સમજવામાં મદદ કરો.',
                ],
            },
            taskManagement: {
                featureHeader: 'કાર્ય વ્યવસ્થાપન',
                featureDescription:
                    'વિશિષ્ટ લક્ષ્યો અથવા લક્ષ્યો પ્રાપ્ત કરવા માટે પ્રવૃત્તિઓને આયોજન, પ્રાધાન્ય આપવું, અને અસરકારક રીતે અમલમાં લાવવું.',
                featureParagraph:
                    'કર્મચારીઓની હાજરી તપાસવા માટે હવે વધુ ટાઇમકાર્ડ્સ, વધારે કાગળપત્ર અને અલગ-अलग દસ્તાવેજો નથી. વેબવર્ક ટાઇમ ટ્રેકર સાથે હાજરી મોનિટરિંગ પ્રક્રિયાને આપમેળે બનાવો અને તમારા કર્મચારીઓના કાર્યકાળની શરૂઆત અને અંત એક સારી રીતે આયોજન કરેલા સ્વરૂપમાં જુઓ.',
                fIIPoints: [
                    'કાર્ય બોર્ડ્સ જોવું, કાર્ય સ્થિતિ તપાસવું અને ખર્ચ કરેલો સમય ટ્રેક કરીને વાસ્તવિક સમયમાં પ્રગતિ મોનિટર કરો.',
                    'ટાસ્ક મેનેજમેન્ટ સહયોગી લક્ષણો રિયલ-ટાઇમમાં ટીમના સભ્યો સાથે વાતચીત કરવા માટે. કાર્યો પર ટિપ્પણીઓ કરો, પ્રશ્નો પુછો અને અપડેટ્સ પ્રદાન કરો જેથી બધા જાણકારીમાં રહે છે.',
                    'ગ્રાહક અને લીડ મેનેજમેન્ટને ટ્રેક કરીને ઉત્પાદકતાને અને મુખ્ય કામગીરી સૂચકાંકોને માપી શકાય છે.',
                    'લક્ષ્યોને સંગઠિત કરવા માટે હાયરાર્કીકલ સ્ટ્રક્ચર. પ્રોજેક્ટ લક્ષ્યો, વિભાગીય લક્ષ્યો, વ્યક્તિગત કાર્યક્ષમતા લક્ષ્યો, વ્યૂહાત્મક પહેલો વગેરે.',
                ],
            },
        },
    },
    payrollAndLeaveManagement: {
        productName: 'પેરોલ અને લિવ મેનેજમેન્ટ',
        titleDescription: 'પેરોલ પ્રોસેસિંગ અને લિવ એડમિનિસ્ટ્રેશનને ઑપ્ટિમાઇઝ કરો',
        headerCaption: 'ટ્રેસફોર્સ લીડ મેનેજમેન્ટ CRM કેમ પસંદ કરવું',
        productCards: [
            {
                title: 'વ્યવસ્થાપનની સરળતા',
                data: 'ગ્રાહક માહિતીનું કેન્દ્રિયીકરણ કરીને, રૂટિન કાર્યોને આપમેળે કરીને, અને સંચારને સરળ બનાવવાથી વ્યવસ્થાપનને સરળ બનાવવું.',
            },
            {
                title: 'કસ્ટમાઇઝેબલિટી',
                data: 'કસ્ટમાઇઝેબલિટી',
            },
            {
                title: 'ઇન્ટેગ્રેશન્સ',
                data: 'ડેટા શેરિંગ, કાર્યઓની આપમેળે પ્રક્રિયા, અને ટીમોમાં સુધારેલી સહયોગને સક્ષમ કરવા માટે ઇન્ટેગ્રેશન્સને સક્ષમ બનાવવું, જે વધુ કાર્યક્ષમ અને અસરકારક CRM અમલ માટે નેતૃત્વ કરે છે.',
            },
        ],
        whyChooseSubCaption:
            'લીડ્સને કાર્યક્ષમ રીતે ટ્રેક કરો અને nurture કરો, કોઈપણ અવસરો ગુમાવા જતાં અને રૂપાંતરણ દરમાં સુધારો કરો.',
        headerT1: 'ટ્રેસફોર્સ લીડ મેનેજમેન્ટ સિસ્ટમના લાભો',
        subCaptionT1:
            'લીડ કેપ્ચર ઑટોમેશન અને ડિસ્ટ્રિબ્યુશન\nવાસ્તવિક સમય ડેટા ઇન્ટેગ્રેશન અને રિપોર્ટ્સ\nસેલ્સ અને માર્કેટિંગ ટૂલ્સ ઑટોમેશન',

        features: {
            leadManagement: {
                featureHeader: 'પેરોલ પ્રોસેસિંગ',
                featureDescription: 'સમયસર અનુસરણ, વ્યક્તિગત ક્રિયાઓ, અને વધુથી વધુ રૂપાંતરણ દર.',
                featureParagraph:
                    'કર્મચારીઓની હાજરી તપાસવા માટે હવે વધુ ટાઇમકાર્ડ્સ, વધારે કાગળપત્ર અને અલગ-अलग દસ્તાવેજો નથી. વેબવર્ક ટાઇમ ટ્રેકર સાથે હાજરી મોનિટરિંગ પ્રક્રિયાને આપમેળે બનાવો અને તમારા કર્મચારીઓના કાર્યકાળની શરૂઆત અને અંત એક સારી રીતે આયોજન કરેલા સ્વરૂપમાં જુઓ.',
                fIIPoints: [
                    'તમારા લીડ મેનેજમેન્ટ પ્રક્રિયાને સ્‍ટ્રીમલાઇન કરો, વિકસિત સેલ્સ ફોર્સના સમય અને સ્થાન પર આધારિત, જે ટૂંકા સમયની અંદર લક્ષ્યો પ્રાપ્ત કરવું સરળ બનાવે છે.',
                    'વેબસાઇટ મુલાકાતો, ઇમેઇલ ક્રિયાઓ, અને વધુના આધારે સેંગમેન્ટ બનાવવા માટે સંપર્કોના ઇતિહાસનો ઉપયોગ કરો. આ ડેટા આધારિત સેલ્સ ફોર્સને લીડ્સ મેનેજ કરવા માટે સેંગમેન્ટ બનાવો.',
                    'લિડ સ્કોરનો ઉપયોગ કરીને prospectsને તમારા સેલ્સ ટીમમાં સોંપો, અથવા વર્કફ્લોને સેટ કરો જેથી કરીને રેપ્સને સૂચના મળે જ્યારે તેમના prospects રૂપાંતરણ માટે તૈયાર હોય.',
                    'પ્રત્યેક લીડના ઇતિહાસને સરળતાથી ઍક્સેસ કરો, કંપનીની માહિતી અને તમારા વ્યવસાય અને સંપર્ક વચ્ચે દરેક ટચપોઇન્ટનો સમયરેખા સહિત.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'સમયરેખા આધારિત હાજરી ટ્રેકિંગ',
                        paragraph:
                            'હાજરી અને પ્રવૃતિ મોનિટર ઘટનાઓ જેમ કે ક્લોક ઇન અને ક્લોક આઉટ અને પણ કર્મચારીઓના તેમના ઓપરેશન્સ, પ્રદર્શન મેટ્રિક્સ, અથવા ઘટનાઓ વિશેની જાણકારી મેળવો.',
                    },
                    customFormsforDataManagement: {
                        header: 'ડેટા મેનેજમેન્ટ માટે કસ્ટમ ફોર્મ્સ',
                        paragraph: 'દૈનિક, સાપ્તાહિક, અને માસિક આધાર પર કાર્યકાળને મોનિટર કરો.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'દૂરસ્થ ઍક્સેસ હાજરી રિપોર્ટ્સ',
                        paragraph: 'બહુવિધ બ્રાન્ચોના હાજરીના રિપોર્ટો remotely ઍક્સેસ કરો.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'હાજરી મોનિટરિંગ એક',
                        description: [
                            'કર્મચારીઓની સમયસરતા પર પારદર્શિતા મેળવો',
                            'કર્મચારીની હાજરીને કોઈપણ વધારાની ક્રિયા વિના બચાવો અને સંચાલિત કરો.',
                            'આસાનીથી મોનિટર કરો કે તમારા કર્મચારીઓ કાર્યસ્થળ પર સમયસર આવ્યા છે કે નહીં અને તેમના કાર્યકાળ પૂરા કર્યા છે કે નહીં.',
                            'તમારા કાર્યસ્થળના ઓપરેશન્સને વધુ સુવિધિત બનાવો અને એક ઉચ્ચ જવાબદાર કાર્ય વાતાવરણ જાળવો.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'હાજરી મોનિટરિંગ બે',
                        description: [
                            'હાજરી ડેટાનું બુદ્ધિશાળી વિશ્લેષણ',
                            'પ્રારંભિક છૂટું, મોડું આવવું, અને ઓછી ટ્રેકિંગ જે તમે ડ્રોપ-ડાઉન યાદીમાંથી પસંદ કરી શકો છો.',
                            'આ તમારું પસંદગી કરેલું માહિતી તરત જ જનરેટ કરવામાં મદદ કરશે, જેથી તમારે તેને મેન્યુઅલી શોધવું ન પડે.',
                            'તમે દૈનિક આધાર પર હાજરીને ટ્રેક કરી શકો છો અથવા કેલેન્ડરમાંથી અંતર પસંદ કરી શકો છો.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'હાજરી મોનિટરિંગ ત્રણ',
                        description: [
                            '100% ટચલેસ સિસ્ટમ સાથે કર્મચારીની હાજરીને વાસ્તવિક સમયમાં મોનિટર અને ટ્રેક કરવાનો સુરક્ષિત અને પારદર્શી રસ્તો.',
                            'આસાનીથી સેટઅપ',
                            'આપમેળે ચેક ઇન અને આઉટ્સ વિગતવાર રિપોર્ટ્સ',
                            'ટ્રેકિંગટાઇમ સાથે સ્ક્રીનશોટ',
                            'કૃયાપ્રવૃત્તિ સ્તરની રેકોર્ડિંગ',
                            'એપ્સ અને વેબસાઇટ ઉપયોગ મોનિટરિંગ',
                        ],
                    },
                },
            },
            leaveManagement: {
                featureHeader: 'લિવ મેનેજમેન્ટ',
                featureDescription:
                    'હેડકાઉન્ટને અસરકારક રીતે સંભાળો અને ટ્રેક કરો, યોગ્ય ડોક્યુમેન્ટેશન, કંપનીની નીતિઓની અનુસરણી, અને પ્રવાહને જાળવો.',
                featureParagraph:
                    'કર્મચારીઓની હાજરી તપાસવા માટે હવે વધુ ટાઇમકાર્ડ્સ, વધારે કાગળપત્ર અને અલગ-अलग દસ્તાવેજો નથી. વેબવર્ક ટાઇમ ટ્રેકર સાથે હાજરી મોનિટરિંગ પ્રક્રિયાને આપમેળે બનાવો અને તમારા કર્મચારીઓના કાર્યકાળની શરૂઆત અને અંત એક સારી રીતે આયોજન કરેલા સ્વરૂપમાં જુઓ.',
                fIIPoints: [
                    'વેકેશન એક્ક્ર્યુઅલ્સ મેનેજ કરો, ખાતરી કરો કે કર્મચારીઓ સંકેતિત પ્રમાણોને પાર નહીં કરે અને નકારાત્મક લિવ બેલેન્સને અટકાવશે.',
                    'સંસ્થાની નીતિઓને સેટ કરી વિકલ્પ માટે લિવ કેલેન્ડર સેટઅપ કરો. લિવ રિક્વેસ્ટ મંજૂરી અથવા અસ્વીકરણ માટે પેરલલ અથવા અનુક્રમણિકા હાયરાર્ક સેટઅપને સક્ષમ બનાવવો.',
                    'લિવ એનટાઇટલમેન્ટ સાથે સંકળાયેલી મજૂર કાયદાઓ અને નિયમોને જાણકાર રહો. ખાતરી કરો કે તમારી લિવ મેનેજમેન્ટ નીતિઓ કંપનીની નીતિઓને અનુરૂપ છે.',
                    'કર્મચારીઓને સ્વ-સેવા વિકલ્પો પ્રદાન કરો, તેમને તેમની લિવ બેલેન્સ તપાસવાની, વિનંતીઓ સબમિટ કરવા, અને તેમની વિનંતીઓની સ્થિતિ જોવામાં સહાય કરો.',
                ],
            },
        },
    },
    orderManagement: {
        productName: 'ઓર્ડર મેનેજમેન્ટ',
        titleDescription: 'સ્કેલેબલ અને આપમેળે સોલ્યુશન સાથે ઓર્ડર/કોલેકશન ઓપરેશન્સને સરળ બનાવો.',
        headerCaption: 'ટ્રેસફોર્સ લીડ મેનેજમેન્ટ CRM કેમ પસંદ કરવું',
        productCards: [
            {
                title: 'વ્યવસ્થાપનની સરળતા',
                data: 'ગ્રાહક માહિતીનું કેન્દ્રિયીકરણ કરીને, રૂટિન કાર્યોને આપમેળે કરીને, અને સંચારને સરળ બનાવવાથી વ્યવસ્થાપનને સરળ બનાવવું.',
            },
            {
                title: 'કસ્ટમાઇઝેબલિટી',
                data: 'કસ્ટમાઇઝેબલિટી',
            },
            {
                title: 'ઇન્ટેગ્રેશન્સ',
                data: 'ડેટા શેરિંગ, કાર્યઓની આપમેળે પ્રક્રિયા, અને ટીમોમાં સુધારેલી સહયોગને સક્ષમ કરવા માટે ઇન્ટેગ્રેશન્સને સક્ષમ બનાવવું, જે વધુ કાર્યક્ષમ અને અસરકારક CRM અમલ માટે નેતૃત્વ કરે છે.',
            },
        ],
        whyChooseSubCaption:
            'લીડ્સને કાર્યક્ષમ રીતે ટ્રેક કરો અને nurture કરો, કોઈપણ અવસરો ગુમાવા જતાં અને રૂપાંતરણ દરમાં સુધારો કરો.',
        headerT1: 'ટ્રેસફોર્સ લીડ મેનેજમેન્ટ સિસ્ટમના લાભો',
        subCaptionT1:
            'લીડ કેપ્ચર ઑટોમેશન અને ડિસ્ટ્રિબ્યુશન\nવાસ્તવિક સમય ડેટા ઇન્ટેગ્રેશન અને રિપોર્ટ્સ\nસેલ્સ અને માર્કેટિંગ ટૂલ્સ ઑટોમેશન',
        features: {
            digitizationOfOrder: {
                featureHeader: 'ઓર્ડરનું ડિજિટાઇઝેશન',
                featureDescription: 'ઓર્ડર મેનેજમેન્ટને અંત સુધી સરળ બનાવવું અને મેન્યુઅલ ત્રુટિઓ ઘટાડવું',
                featureParagraph:
                    'કર્મચારીઓની હાજરી તપાસવા માટે હવે વધુ ટાઇમકાર્ડ્સ, વધારે કાગળપત્ર અને અલગ-अलग દસ્તાવેજો નથી. વેબવર્ક ટાઇમ ટ્રેકર સાથે હાજરી મોનિટરિંગ પ્રક્રિયાને આપમેળે બનાવો અને તમારા કર્મચારીઓના કાર્યકાળની શરૂઆત અને અંત એક સારી રીતે આયોજન કરેલા સ્વરૂપમાં જુઓ.',
                fIIPoints: [
                    'ઓર્ડર પ્રોસેસિંગ પ્રદર્શન અને કર્મચારીના ઉત્પાદકતાનું મોનિટરિંગ કરવા માટે વિગતવાર રિપોર્ટ અને એનાલિટિક્સ જનરેટ કરો.',
                    'કર્મચારીઓ અથવા ગ્રાહકો દ્વારા દાખલ કરાયેલા ઓર્ડર વિગતોને આપમેળે કેબ્ચર અને માન્ય બનાવવું.',
                    'કર્મચારીઓ અને ગ્રાહકોને ઓર્ડરની સ્થિતિ અને શિપમેન્ટ ટ્રેકિંગ પર વાસ્તવિક સમયના અપડેટ્સ પ્રદાન કરો.',
                    'ઓર્ડર મંજુરી પ્રક્રિયાઓને આપમેળે બનાવવું અને યોગ્ય કર્મચારીઓને કાર્ય સોંપવું.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'સમયરેખા આધારિત હાજરી ટ્રેકિંગ',
                        paragraph:
                            'હાજરી અને પ્રવૃતિ મોનિટર ઘટનાઓ જેમ કે ક્લોક ઇન અને ક્લોક આઉટ અને પણ કર્મચારીઓના તેમના ઓપરેશન્સ, પ્રદર્શન મેટ્રિક્સ, અથવા ઘટનાઓ વિશેની જાણકારી મેળવો.',
                    },
                    customFormsforDataManagement: {
                        header: 'ડેટા મેનેજમેન્ટ માટે કસ્ટમ ફોર્મ્સ',
                        paragraph: 'દૈનિક, સાપ્તાહિક, અને માસિક આધાર પર કાર્યકાળને મોનિટર કરો.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'દૂરસ્થ ઍક્સેસ હાજરી રિપોર્ટ્સ',
                        paragraph: 'બહુવિધ બ્રાન્ચોના હાજરીના રિપોર્ટો remotely ઍક્સેસ કરો.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'હાજરી મોનિટરિંગ એક',
                        description: [
                            'કર્મચારીઓની સમયસરતા પર પારદર્શિતા મેળવો',
                            'કર્મચારીની હાજરીને કોઈપણ વધારાની ક્રિયા વિના બચાવો અને સંચાલિત કરો.',
                            'આસાનીથી મોનિટર કરો કે તમારા કર્મચારીઓ કાર્યસ્થળ પર સમયસર આવ્યા છે કે નહીં અને તેમના કાર્યકાળ પૂરા કર્યા છે કે નહીં.',
                            'તમારા કાર્યસ્થળના ઓપરેશન્સને વધુ સુવિધિત બનાવો અને એક ઉચ્ચ જવાબદાર કાર્ય વાતાવરણ જાળવો.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'હાજરી મોનિટરિંગ બે',
                        description: [
                            'હાજરી ડેટાનું બુદ્ધિશાળી વિશ્લેષણ',
                            'પ્રારંભિક છૂટું, મોડું આવવું, અને ઓછી ટ્રેકિંગ જે તમે ડ્રોપ-ડાઉન યાદીમાંથી પસંદ કરી શકો છો.',
                            'આ તમારું પસંદગી કરેલું માહિતી તરત જ જનરેટ કરવામાં મદદ કરશે, જેથી તમારે તેને મેન્યુઅલી શોધવું ન પડે.',
                            'તમે દૈનિક આધાર પર હાજરીને ટ્રેક કરી શકો છો અથવા કેલેન્ડરમાંથી અંતર પસંદ કરી શકો છો.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'હાજરી મોનિટરિંગ ત્રણ',
                        description: [
                            '100% ટચલેસ સિસ્ટમ સાથે કર્મચારીની હાજરીને વાસ્તવિક સમયમાં મોનિટર અને ટ્રેક કરવાનો સુરક્ષિત અને પારદર્શી રસ્તો.',
                            'આસાનીથી સેટઅપ',
                            'આપમેળે ચેક ઇન અને આઉટ્સ વિગતવાર રિપોર્ટ્સ',
                            'ટ્રેકિંગટાઇમ સાથે સ્ક્રીનશોટ',
                            'કૃયાપ્રવૃત્તિ સ્તરની રેકોર્ડિંગ',
                            'એપ્સ અને વેબસાઇટ ઉપયોગ મોનિટરિંગ',
                        ],
                    },
                },
            },
            targetManagement: {
                featureHeader: 'લક્ષ્ય મેનેજમેન્ટ',
                featureDescription:
                    'પ્રત્યેક કર્મચારીએ તેમની ભૂમિકા અને ક્ષમતાઓના આધારે સ્પષ્ટ અને પ્રાપ્ત કરી શકાય એવા લક્ષ્યો સેટ કરો.',
                featureParagraph:
                    'કર્મચારીઓની હાજરી તપાસવા માટે હવે વધુ ટાઇમકાર્ડ્સ, વધારે કાગળપત્ર અને અલગ-अलग દસ્તાવેજો નથી. વેબવર્ક ટાઇમ ટ્રેકર સાથે હાજરી મોનિટરિંગ પ્રક્રિયાને આપમેળે બનાવો અને તમારા કર્મચારીઓના કાર્યકાળની શરૂઆત અને અંત એક સારી રીતે આયોજન કરેલા સ્વરૂપમાં જુઓ.',
                fIIPoints: [
                    'દૈનિક, સાપ્તાહિક, માસિક લક્ષ્યો પ્રાપ્ત કરો અને જીવંત ફીલ્ડ ટ્રેકિંગ સાથે કર્મચારીની સિદ્ધિઓનો રેકોર્ડ રાખો.',
                    'વાસ્તવિક-સમયની પ્રગતિ (પ્રાપ્ત લક્ષ્યોની સંખ્યા વિસ. બાકી), ચેક-ઇન/ચેક-આઉટ સમય, દરેક કાર્ય પર વિતાવેલ કલાકોની સંખ્યા, અને સ્થાન ટ્રેકિંગ.',
                    'પ્રદર્શન મેટ્રિક્સ (પૂર્ણતાની દર, સમય લીધેલ, ગ્રાહક સંતોષ સ્કોર્સ), લક્ષ્યો સામે તુલના (વાસ્તવિક પ્રદર્શન વિસ. લક્ષ્ય), ઐતિહાસિક ડેટા (ભૂતકાળની પ્રદર્શન ટ્રેન્ડ્સ).',
                    'લક્ષ્ય સિદ્ધિ સૂચનાઓ, પ્રદર્શન યાદવહણીઓ, અંતિમ મર્યાદા ચેતવણીઓ વિશે કર્મચારીઓને આપમેળે સૂચનાઓ મોકલો.',
                ],
            },
            invoiceManagement: {
                featureHeader: 'ઇન્વોઇસ મેનેજમેન્ટ',
                featureDescription:
                    'ઇન્વોઇસ બનાવો, રીયલ-ટાઇમ ઇન્વોઇસ સ્થિતિ અને પ્રક્રિયા પર ટ્રેક કરો, આપમેળે અલર્ટ અને સૂચનાઓ સાથે.',
                fIIPoints: [
                    'ઇન્વોઇસ સ્થિતિને ટ્રેક કરવા માટે સિસ્ટમ સેટ કરો અને બાકી અને મિયાદ પસાર થયેલ ચુકવણી માટે આપમેળે યાદવહણીઓ અને અલર્ટ મોકલો.',
                    'મુખ્ય વિગતો સાથે સ્ટાન્ડર્ડાઇઝડ ઇન્વોઇસ ટેમ્પ્લેટ્સ જેમ કે ગ્રાહક માહિતી, ઓર્ડર વિગતો, કર, ડિસ્કાઉન્ટ્સ, અને ચુકવણી શરતો.',
                    'ઇન્વોઇસ પ્રક્રિયા એક ભાગ તરીકે ડિજિટલ સહી અને OTP આધારિત ડિલિવરી પુરાવા સંગ્રહિત કરો.',
                    'સેન્ટ્રલ સિસ્ટમ (ક્લાઉડ આધારિત સ્ટોરેજ) સાથે આપમેળે સમન્વય અને ઇન્વોઇસ સ્થિતિ પર વાસ્તવિક સમયના અપડેટ્સ.',
                ],
            },
            distributorOrder: {
                featureHeader: 'ડિસ્ટ્રિબ્યુટર ઓર્ડર',
                featureDescription:
                    'ઉત્પાદનો/કોલેકશનને મેનેજ કરો અને વાસ્તવિક-સમયની અપડેટ્સ સાથે કાર્ય પ્રાથમિકતા સંભાળો.',
                fIIPoints: [
                    'પ્રતિષ્ઠાન માટે તમામ સંબંધિત વિગતો સાથે ઓર્ડર બનાવો અને પ્રક્રિયા કરો અને ઉપલબ્ધતા આધારિત કાર્ય સોંપો.',
                    'અચુક સ્ટોક મંજૂરીને સુનિશ્ચિત કરવા માટે ઇન્વેન્ટરી મેનેજમેન્ટ સાથે ઇન્ટિગ્રેટ કરો અને વાસ્તવિક-સમયમાં ઇન્વેન્ટરી સ્તરોને અપડેટ કરો.',
                    'ઓર્ડર પ્રક્રિયાના મુખ્ય તબક્કાઓ પર આપમેળે સૂચનાઓ મોકલવા માટે સિસ્ટમને રૂપરેખાંકિત કરો.',
                    'ડેશબોર્ડ ઍક્સેસ કરો જ્યાં કર્મચારીઓ ઓર્ડરો ચકાસી શકે છે, પ્રગતિ ટ્રેક કરી શકે છે, અને જરૂરી દસ્તાવેજો ડાઉનલોડ કરી શકે છે.',
                ],
            },
            inventoryManagement: {
                featureHeader: 'ઇન્વેન્ટરી મેનેજમેન્ટ',
                featureDescription: 'અચુક ડેટા માટે સ્ટોકનો યોગ્ય બેલેન્સ અને વાસ્તવિક-સમયની અપડેટ્સ અને ટ્રેકિંગ.',
                fIIPoints: [
                    'ઇન્વેન્ટરી અને ઓર્ડર મેનેજમેન્ટ સિસ્ટમ વચ્ચે અસંલગ્નતા સુનિશ્ચિત કરો, આવનારા અને જતાં ઓર્ડરો સાથે સ્ટોક સ્તરોને સમન્વયિત કરો.',
                    'બહુવિધ વેરહાઉસ, સ્ટોર્સ, અથવા સ્થાનોએ ઇન્વેન્ટરીને ટ્રેક કરે છે અને કેન્દ્રિત નિયંત્રણને ઍક્સેસ આપે છે, સ્ટોક વિતરણને ઑપ્ટિમાઇઝ કરે છે અને દ્રષ્ટિ સુધારે છે.',
                ],
            },
            orderCollectionManagement: {
                featureHeader: 'ઓર્ડર / કલેકશન મેનેજમેન્ટ',
                featureDescription: 'સ્કેલેબલ અને આપમેળે સોલ્યુશન સાથે ઓર્ડર/કોલેકશન ઓપરેશન્સને સરળ બનાવવો.',
                fIIPoints: [
                    'ઓર્ડર્સને મેનેજ કરવા, પ્રગતિ ટ્રેક કરવા, અને વાસ્તવિક-સમયમાં સ્થિતિઓ અપડેટ કરવા માટે સાઇટ પર અને સાઇટથી બહારના કર્મચારીઓ દ્વારા ઍક્સેસ કરી શકાય તેવી કેન્દ્રિત સિસ્ટમ.',
                    'ડેટા સુરક્ષા અને યોગ્ય ઍક્સેસ સુનિશ્ચિત કરવા માટે ભૂમિકા (જેમ કે મેનેજર, સાઇટ પરના કર્મચારીઓ, સાઇટથી બહારના કર્મચારીઓ) પર આધારિત ઍક્સેસ અને પરવાનગીઓના વિવિધ સ્તરો.',
                    'સુનિશ્ચિત કરો કે તમામ ઓર્ડર ડેટા દરેક ડિવાઇસ અને સ્થાન પર વાસ્તવિક-સમયમાં સમન્વયિત છે, જેથી દરેકને નવીનતમ માહિતી પ્રાપ્ત થાય.',
                    'ઍપ્લિકેશન મેસેજિંગ અને સૂચનાઓ દ્વારા સાઇટ પર અને સાઇટથી બહારના કર્મચારીઓ વચ્ચે સંચારને સરળ બનાવવું.',
                ],
            },
        },
    },
};
export default products;
