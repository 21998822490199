const products = {
    attendanceManagement: {
        productName: 'उपस्थिति प्रबंधन',
        titleDescription: 'आसान सेटअप, स्वचालित चेक इन और चेक आउट, रिपोर्ट्स',
        productCards: [
            {
                title: 'कार्य-जीवन संतुलन',
                data: 'ट्रेसफोर्स रिमाइंडर्स के साथ अपने कर्मचारियों के कार्य और व्यक्तिगत जीवन का ध्यान रखें।',
            },
            {
                title: 'सदस्य प्रकार',
                data: 'कार्यक्षेत्र प्रबंधन को सरल बनाएं, जैसे प्रबंधक, परियोजना प्रबंधक, टीम प्रबंधक और परियोजना दर्शक।',
            },
            {
                title: 'ट्रैक करने में मदद करता है',
                data: 'ट्रेसफोर्स के स्मार्ट टाइमशीट्स से कार्य प्रबंधन को सरल बनाएं, जो आपको सक्रिय घंटे, निष्क्रिय घंटे और उत्पादक घंटों का विवरण शीट शैली में दिखाता है।',
            },
        ],
        whyChooseSubCaption:
            'एक अत्यधिक कुशल कार्यस्थल वातावरण की ओर संक्रमण करें और उत्पादकता और सहयोग में वृद्धि का अनुभव करें। डेटा को कार्ड्स में केंद्रीकृत करें।',
        headerCaption: 'ट्रेसफोर्स उपस्थिति प्रबंधन क्यों चुनें',
        headerT1: 'उपस्थिति ट्रैकिंग सुविधाएँ जो बहुत कुछ करती हैं',
        subCaptionT1:
            'कर्मचारी ट्रैकिंग, दूरस्थ कार्य निगरानी, उत्पादकता निगरानी, उपस्थिति प्रबंधन, टाइमशीट्स और अधिक का अनुभव करें — सभी उन्नत कार्यक्षमता की परतों के साथ।',
        features: {
            attendanceMonitoring: {
                featureHeader: 'उपस्थिति निगरानी',
                featureDescription:
                    'जियोफेंस क्षेत्र या शाखा के भीतर व्यक्तियों की उपस्थिति या अनुपस्थिति की वास्तविक समय की जानकारी।',
                featureParagraph:
                    'समय कार्ड, अत्यधिक कागजी कार्यवाही और विभिन्न दस्तावेजों की आवश्यकता नहीं। वेबवर्क टाइम ट्रैकर के साथ उपस्थिति निगरानी प्रक्रिया को स्वचालित करें और अपने कर्मचारियों के कामकाजी घंटे की शुरुआत और अंत को एक सुव्यवस्थित प्रारूप में देखें।',
                fIIPoints: [
                    'जियोफेंस क्षेत्र या शाखा के भीतर व्यक्तियों की उपस्थिति या अनुपस्थिति की वास्तविक समय की जानकारी',
                    'दैनिक, साप्ताहिक और मासिक आधार पर कार्य घंटे की निगरानी करें',
                    'कई शाखाओं की उपस्थिति रिपोर्ट तक दूरस्थ पहुंच',
                    'उपस्थिति से संबंधित डेटा, प्रक्रियाओं और प्रणालियों को एक एकल, केंद्रीकृत प्लेटफ़ॉर्म में समेकित करें',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'वास्तविक समय की उपस्थिति',
                        paragraph:
                            'जियोफेंस क्षेत्र या शाखा के भीतर व्यक्तियों की उपस्थिति या अनुपस्थिति की वास्तविक समय की जानकारी।',
                    },
                    totalWorkingHoursCalculation: {
                        header: 'कुल कार्य घंटे की गणना',
                        paragraph: 'दैनिक, साप्ताहिक और मासिक आधार पर कार्य घंटे की निगरानी करें।',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'दूरस्थ पहुंच उपस्थिति रिपोर्ट',
                        paragraph: 'कई शाखाओं की उपस्थिति रिपोर्ट तक दूरस्थ पहुंच।',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'उपस्थिति निगरानी एक',
                        description: [
                            'कर्मचारियों की समय पर उपस्थिति की पारदर्शिता प्राप्त करें',
                            'समय बचाएं और कर्मचारियों की उपस्थिति को बिना किसी अतिरिक्त कार्रवाई के व्यवस्थित करें।',
                            'आसानी से मॉनिटर करें कि आपके कर्मचारी समय पर कार्यालय पहुंचे और कामकाजी घंटे पूरे किए या नहीं।',
                            'अपने कार्यस्थल संचालन को अधिक व्यवस्थित रखें और अत्यधिक जिम्मेदार कार्य वातावरण रखें।',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'उपस्थिति निगरानी दो',
                        description: [
                            'उपस्थिति डेटा का बुद्धिमान विश्लेषण',
                            'ड्रॉपडाउन सूची से जल्दी निकलना, देर से आना और कम ट्रैकिंग फ़िल्टर करें।',
                            'यह आपको मैन्युअल रूप से खोज किए बिना अपनी पसंदीदा जानकारी तुरंत उत्पन्न करने में मदद करेगा।',
                            'आप दैनिक आधार पर उपस्थिति ट्रैक कर सकते हैं या आप कैलेंडर से एक अंतराल चुन सकते हैं।',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'उपस्थिति निगरानी तीन',
                        description: [
                            'एक 100% टचलेस सिस्टम के साथ वास्तविक समय में कर्मचारी उपस्थिति की निगरानी और ट्रैकिंग का सुरक्षित और पारदर्शी तरीका।',
                            'आसान सेटअप',
                            'स्वचालित चेक इन और आउट्स विस्तृत रिपोर्ट',
                            'ट्रैकिंगटाइम के साथ स्क्रीनशॉट',
                            'गतिविधि स्तर रिकॉर्डिंग',
                            'एप्स और वेबसाइट उपयोग की निगरानी',
                        ],
                    },
                },
            },
            shiftManagement: {
                featureHeader: 'शिफ्ट प्रबंधन',
                featureDescription:
                    'काम के घंटों का आयोजन और समय निर्धारण करें, जो उत्पादकता को अनुकूलित करता है, संचालन आवश्यकताओं को पूरा करता है, और विभिन्न समय अवधि में कवरेज सुनिश्चित करता है।',
                fIIPoints: [
                    'एक स्पष्ट और संगठित शिफ्ट शेड्यूल विकसित करें। अपने व्यवसाय की प्रकृति के आधार पर विभिन्न प्रकार की शिफ्ट्स को परिभाषित करें।',
                    'काम के बोझ और कार्यों का विश्लेषण करें ताकि विभिन्न शिफ्ट्स के दौरान कर्मचारियों की संख्या निर्धारित की जा सके।',
                    'कर्मचारियों को उनके शेड्यूल तक पहुंच के लिए एक केंद्रीकृत प्लेटफार्म प्रदान करें, और किसी भी अपडेट या परिवर्तनों के लिए एक विश्वसनीय संचार चैनल स्थापित करें।',
                    'कर्मचारियों के शेड्यूल का केंद्रीकृत निरीक्षण और नियंत्रण, जो प्रबंधकों को शिफ्ट्स को कुशलतापूर्वक आवंटित करने, उपस्थिति को ट्रैक करने, और कई स्थानों पर कार्यबल संसाधनों का प्रबंधन करने की अनुमति देता है।',
                ],
            },
            geoFenceBasedManagement: {
                featureHeader: 'जियो फेंस आधारित उपस्थिति',
                featureDescription:
                    'स्थान आधारित तकनीक कर्मचारियों की उपस्थिति की निगरानी और सत्यापन के लिए जो पहले से परिभाषित भौगोलिक सीमाओं के भीतर हो।',
                fIIPoints: [
                    'सटीक उपस्थिति रिकॉर्ड सुनिश्चित करें कि कर्मचारी अपने निर्दिष्ट कार्य स्थलों पर उपस्थित हैं।',
                    'कर्मचारियों की स्थानों की निगरानी करने के लिए वास्तविक समय ट्रैकिंग लागू करें।',
                    'कार्यस्थल या निर्दिष्ट उपस्थिति स्थानों के चारों ओर विशिष्ट भौगोलिक सीमाओं, जिन्हें जियो-फेंस के रूप में जाना जाता है, की स्थापना करें।',
                    'कर्मचारियों के जियो-फेंस सीमाओं के भीतर प्रवेश करने या छोड़ने पर प्रबंधकों या एचआर कर्मियों को सूचित करने के लिए अलर्ट या सूचनाएं सेट करें।',
                ],
            },
            multipleClockInOptions: {
                featureHeader: 'कई घड़ी-इन विकल्प',
                featureDescription:
                    'कर्मचारियों को अपने काम के घंटों को बायोमेट्रिक स्कैनर, मोबाइल एप्स, टाइम क्लॉक्स और वेब-आधारित प्लेटफार्मों का उपयोग करके रिकॉर्ड करने के लिए लचीलापन प्रदान करें।',
                fIIPoints: [
                    'कई स्थानों के साथ संगठनों के लिए उपयुक्त विभिन्न शाखाओं के ऑन-फील्ड और ऑफ-फील्ड उपस्थिति प्रबंधन के लिए लचीले घड़ी-इन विकल्प।',
                    'कर्मचारियों को उपस्थिति रिकॉर्ड तक पहुंच और जियो-फेंस के भीतर अपने चेक-इन और चेक-आउट प्रबंधित करने के लिए स्व-सेवा पोर्टल प्रदान करें।',
                    'बायोमेट्रिक स्कैन, मोबाइल एप्स, आरएफआईडी कार्ड और चेहरे की पहचान सहित लचीले घड़ी-इन विकल्प विभिन्न कार्य वातावरण में सहज समय ट्रैकिंग सुनिश्चित करते हैं।',
                    'ऑफिस परिसर की सीमा के भीतर कर्मचारियों के लिए ऑटो घड़ी-इन उपस्थिति अंकन अलर्ट सूचना के साथ।',
                ],
            },
            attendanceReport: {
                featureHeader: 'उपस्थिति रिपोर्ट्स',
                featureDescription:
                    'एक विशिष्ट समय सीमा के भीतर कर्मचारियों की उपस्थिति डेटा, काम के घंटे, अनुपस्थिति, देर से आने और जल्दी जाने का सारांश।',
                fIIPoints: [
                    'उपस्थिति का कुल सारांश प्रदान करें, जिसमें कुल कामकाजी दिनों की संख्या, कुल काम के घंटे, और कोई भी ओवरटाइम घंटे शामिल हों।',
                    'कई शाखाओं की उपस्थिति रिपोर्ट को अनुकूलन योग्य श्रेणियों के साथ दूरस्थ रूप से एक्सेस करें।',
                    'प्रत्येक कर्मचारी के लिए छुट्टी शेष दिखाएं, जिसमें छुट्टी के दिन, बीमारी की छुट्टी, और कोई भी अन्य प्रकार की छुट्टियाँ शामिल और उपयोग की गई हों।',
                    'उपयोगकर्ताओं को विशिष्ट मानदंडों जैसे विभाग, टीम, या व्यक्तिगत कर्मचारी के आधार पर रिपोर्ट को अनुकूलित करने की अनुमति दें।',
                ],
            },
        },
    },
    fieldTrackingManagement: {
        productName: 'फील्ड फोर्स प्रबंधन',
        titleDescription: 'लाइव रिपोर्टिंग और बीट रूट के साथ फील्ड बिक्री और सेवा संचालन',
        productCards: [
            {
                title: 'कम लागत',
                data: 'कर्मचारी शेड्यूल को स्वचालित करके परिचालन लागत कम करें। कर्मचारियों के समय, कार्यों और खर्चों को ट्रैक करें ताकि आप यह पहचान सकें कि आप कहां पैसे बचा सकते हैं।',
            },
            {
                title: 'उत्कृष्ट ग्राहक मूल्य',
                data: 'एक शेड्यूल गारंटी और कुशल सेवा सेट करें। ग्राहक इंटरैक्शन और फीडबैक को ट्रैक करें, जिससे आसानी से अंतराल और सुधार के क्षेत्रों की पहचान हो सके।',
            },
            {
                title: 'प्रदर्शन विश्लेषिकी',
                data: 'एकीकृत डैशबोर्ड पर व्यापक रिपोर्ट आसानी से उत्पन्न करें, जो आपके कार्यबल के प्रदर्शन, नौकरी की समयरेखा, ग्राहक संतुष्टि और सुधार के क्षेत्रों में मूल्यवान अंतर्दृष्टि प्रदान करें।',
            },
        ],
        whyChooseSubCaption:
            'फील्ड फोर्स प्रबंधन सॉफ़्टवेयर के साथ उच्च दक्षता\nसब कैप्शन - अपने फील्ड कर्मचारियों को वास्तविक समय में ट्रैक करें, कार्य प्रगति की निगरानी करें, और उनके स्थान, गतिविधि और प्रदर्शन में अंतर्दृष्टि प्राप्त करें। जवाबदेही में सुधार करें और डेटा-आधारित निर्णय लेने का उपयोग करें।',
        headerCaption: 'ट्रेसफोर्स फील्ड फोर्स प्रबंधन क्यों चुनें',
        headerT1: 'फील्ड फोर्स प्रबंधन प्रणालियों की मुख्य विशेषताएं',
        subCaptionT1:
            'कर्मचारियों के वास्तविक समय स्थान ट्रैकिंग का लाभ उठाएं, एआई-आधारित अनुकूलन के साथ संचालन को सुव्यवस्थित करें, तत्काल अंतर्दृष्टि के लिए लाइव रिपोर्टिंग का उपयोग करें, और फील्ड बिक्री विश्लेषिकी का उपयोग करके प्रदर्शन में सुधार करें। संसाधन आवंटन में दक्षता सुनिश्चित करें, उत्पादकता में सुधार करें, और डेटा-संचालित निर्णय लेने को सक्षम करें।',

        features: {
            workforceTracking: {
                featureHeader: 'कार्यबल ट्रैकिंग',
                featureDescription: 'कर्मचारियों की गतिविधियों, उत्पादकता और उपस्थिति की व्यवस्थित निगरानी और प्रबंधन।',
                featureParagraph:
                    'समय कार्ड, अत्यधिक कागजी कार्रवाई और कर्मचारियों की उपस्थिति की जांच करने के लिए अलग-अलग दस्तावेज़ों की कोई आवश्यकता नहीं है। वेबवर्क टाइम ट्रैकर के साथ उपस्थिति निगरानी प्रक्रिया को स्वचालित करें और अपने कर्मचारियों के काम के घंटों की शुरुआत और अंत को एक सुव्यवस्थित प्रारूप में देखें।',
                fIIPoints: [
                    'क्लॉक इन और क्लॉक आउट जैसे इवेंट्स की निगरानी करें और कर्मचारियों के संचालन, प्रदर्शन मेट्रिक्स या इवेंट्स में अंतर्दृष्टि प्राप्त करें।',
                    'बैठक और कार्य के लिए डेटा स्टोर करें ताकि मीटिंग के मिनटों के लिए रुझानों और डेटा-संचालित निर्णय लेने को ट्रैक किया जा सके।',
                    'ग्राहक और लीड प्रबंधन के माध्यम से कर्मचारियों की उत्पादकता और प्रदर्शन मेट्रिक्स को मापा जा सकता है।',
                    'फील्ड कार्य के लिए दैनिक, साप्ताहिक, और मासिक आधार पर कर्मचारियों द्वारा यात्रा की गई सटीक दूरी को ट्रैक करें और यात्रा की गई दूरी और स्थानों के अनुसार खर्च की प्रतिपूर्ति करें।',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'उपस्थिति का समयरेखा आधारित ट्रैकिंग',
                        paragraph:
                            'क्लॉक इन और क्लॉक आउट जैसे इवेंट्स की निगरानी करें और कर्मचारियों के संचालन, प्रदर्शन मेट्रिक्स या इवेंट्स में अंतर्दृष्टि प्राप्त करें।',
                    },
                    customFormsforDataManagement: {
                        header: 'डेटा प्रबंधन के लिए कस्टम फॉर्म',
                        paragraph: 'दैनिक, साप्ताहिक, और मासिक आधार पर कार्य घंटों की निगरानी करें।',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'रिमोट एक्सेस उपस्थिति रिपोर्ट्स',
                        paragraph: 'कई शाखाओं की उपस्थिति रिपोर्ट्स को दूरस्थ रूप से एक्सेस करें।',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'उपस्थिति निगरानी एक',
                        description: [
                            'कर्मचारियों की समय की पाबंदी की पारदर्शिता प्राप्त करें।',
                            'कर्मचारी उपस्थिति को व्यवस्थित करें और समय बचाएं।',
                            'आसानी से यह निगरानी करें कि आपके कर्मचारी समय पर कार्यस्थल पर पहुँचे हैं और अपने काम के घंटे पूरे किए हैं या नहीं।',
                            'अपने कार्यस्थल के संचालन को अधिक संगठित रखें और एक जिम्मेदार कार्य वातावरण सुनिश्चित करें।',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'उपस्थिति निगरानी दो',
                        description: [
                            'उपस्थिति डेटा का बुद्धिमान विश्लेषण।',
                            'आप जल्दी जाने, देर से आने, और कम ट्रैकिंग फ़िल्टर कर सकते हैं।',
                            'इससे आप बिना मैन्युअल खोज के अपनी पसंदीदा जानकारी तुरंत उत्पन्न कर सकते हैं।',
                            'आप दैनिक आधार पर या कैलेंडर से एक अंतराल चुन सकते हैं।',
                        ],
                    },
                    attendanceMonitoringThree: {
                        imagePath: '',
                        title: 'उपस्थिति निगरानी तीन',
                        description: [
                            'कर्मचारी उपस्थिति की रीयल-टाइम निगरानी और ट्रैकिंग के लिए सुरक्षित और पारदर्शी तरीका।',
                            'आसान सेटअप।',
                            'स्वचालित चेक-इन और आउट्स विस्तृत रिपोर्ट्स।',
                            'ट्रैकिंगटाइम के साथ स्क्रीनशॉट।',
                            'गतिविधि स्तर रिकॉर्डिंग।',
                            'ऐप्स और वेबसाइट उपयोग की निगरानी।',
                        ],
                    },
                },
            },
            fieldSalesAnalytics: {
                featureHeader: 'फील्ड बिक्री विश्लेषिकी',
                featureDescription:
                    'फील्ड पर बिक्री टीम के प्रमुख मेट्रिक्स जैसे बिक्री राजस्व, ग्राहक इंटरैक्शन और भौगोलिक रुझानों को ट्रैक और विश्लेषण करें।',
                featureParagraph:
                    'समय कार्ड, अत्यधिक कागजी कार्रवाई और कर्मचारियों की उपस्थिति की जांच के लिए अलग-अलग दस्तावेज़ों की कोई आवश्यकता नहीं है। वेबवर्क टाइम ट्रैकर के साथ उपस्थिति निगरानी प्रक्रिया को स्वचालित करें और अपने कर्मचारियों के काम के घंटों की शुरुआत और अंत को एक सुव्यवस्थित प्रारूप में देखें।',
                fIIPoints: [
                    'प्रमुख प्रदर्शन संकेतक डेटा-संचालित निर्णय लेने में मदद कर सकते हैं, जो अगली बार के लिए उत्पादक निर्णय लेने या रणनीति को पुनः व्यवस्थित करने में सहायक होते हैं।',
                    'रिपोर्ट्स को दैनिक, साप्ताहिक या मासिक आधार पर एक्सेस किया जा सकता है जिससे बिक्री, लीड और ग्राहक अधिग्रहण पर व्यक्ति की उत्पादकता और दक्षता में सुधार हो सकता है।',
                    'यात्रा की सटीकता के लिए जियोफेंस आधारित चेक-इन का उपयोग करें। स्थान के निर्देशांक कैमरा पिक के साथ संलग्न किए जा सकते हैं।',
                    'बीट रूट का उपयोग कर्मचारी से ग्राहक यात्रा के लिए योजना बनाने के लिए किया जा सकता है। अपने बीट प्लान को सीधे मैप्स में एकीकृत करें ताकि नेविगेशन को अनुकूलित किया जा सके।',
                ],
            },
            locationBasedAttendance: {
                featureHeader: 'स्थान आधारित उपस्थिति',
                featureDescription:
                    'जियोफेंस आधारित उपस्थिति सुनिश्चित करें कि कर्मचारी केवल तभी घड़ी लगा सकते हैं या हटा सकते हैं जब वे परिभाषित भौगोलिक सीमाओं के भीतर हों।',
                fIIPoints: [
                    'कार्यालय परिसर के विशिष्ट स्थानों के आसपास जियोफेंसड वर्चुअल सीमाओं को लागू करें। यह कर्मचारी के निर्दिष्ट क्षेत्र में प्रवेश करने या छोड़ने पर उपस्थिति को स्वचालित करने में मदद करता है।',
                    'उपस्थिति डेटा की सटीकता और सुरक्षा सुनिश्चित करने के लिए पिन, बायोमेट्रिक या चेहरे की उपस्थिति जैसी प्रमाणीकरण विधियों का उपयोग करें।',
                    'उपस्थिति रिकॉर्ड समय पर अपडेट किए जाएं, जिससे प्रबंधन निर्णय समय पर लिए जा सकें।',
                    'रिमोट कर्मचारियों के लिए वैकल्पिक उपस्थिति ट्रैकिंग विधियों पर विचार करें।',
                ],
            },
            liveReporting: {
                featureHeader: 'लाइव रिपोर्टिंग',
                featureDescription:
                    'उपस्थिति, पूरी की गई कार्य या परियोजना की प्रगति जैसे विभिन्न पहलुओं का वास्तविक समय में ट्रैकिंग और विश्लेषण।',
                fIIPoints: [
                    'प्रशासक या प्रबंधक उन सूचनाओं की सदस्यता ले सकते हैं या चुन सकते हैं जो वे प्राप्त करना चाहते हैं। यह उन सूचनाओं पर ध्यान केंद्रित करने में मदद करेगा जो वे वास्तव में प्राप्त करना चाहते हैं।',
                    'उपस्थिति और अवकाश के बारे में सूचित रहें, पहले से आगे रहें और कर्मचारियों की संख्या जानें ताकि दिन की योजना अधिक उत्पादक और कुशल हो सके।',
                    'सभी अनुमोदनों का ट्रैक रखें, प्रतिपूर्ति से लेकर अवकाश तक सभी अनुमोदनों को ट्रैक करें और बाद में उन्हें देखें।',
                    'मिनट ऑफ़ मीटिंग और कस्टम फॉर्म के माध्यम से रिमोट ट्रैकिंग; कस्टम फॉर्म बैठक के लिए विशिष्ट डेटा प्राप्त करने में मदद कर सकते हैं जिसे दूरस्थ रूप से एक्सेस किया जा सकता है।',
                ],
            },
            aIBasedOptimization: {
                featureHeader: 'एआई आधारित अनुकूलन',
                featureDescription:
                    'फील्ड प्रतिनिधियों या बिक्री कर्मियों के लिए एआई बीट रूट पर आधारित मार्गों की योजना को अनुकूलित और स्वचालित करें।',
                fIIPoints: [
                    'वास्तविक समय के डेटा स्रोत जैसे लाइव ट्रैफिक अपडेट, मौसम की स्थिति और सड़क बंद होने की जानकारी का उपयोग करके वर्तमान परिस्थितियों के आधार पर मार्गों को गतिशील रूप से समायोजित करें, जिससे दैनिक योजना अधिक कुशल हो सके।',
                    'अनुकूलित मार्ग देखें और आवश्यकतानुसार समायोजन करें। यह मोबाइल ऐप या वेब-आधारित प्लेटफ़ॉर्म का हिस्सा हो सकता है।',
                    'एआई प्रणाली विभिन्न बीट्स या मार्गों की विशिष्ट विशेषताओं को ध्यान में रखती है।',
                    'सड़क नेटवर्क, ट्रैफिक की स्थिति और अन्य स्थान-विशिष्ट जानकारी को समझने के लिए मैपिंग सेवाओं और भू-स्थानिक डेटा का उपयोग करें।',
                ],
            },
            taskManagement: {
                featureHeader: 'कार्य प्रबंधन',
                featureDescription:
                    'विशिष्ट लक्ष्यों या उद्देश्यों को प्राप्त करने के लिए गतिविधियों को संगठित करना, प्राथमिकता देना और कुशलता से निष्पादित करना।',
                featureParagraph:
                    'अब कोई टाइमकार्ड, अत्यधिक कागजी कार्रवाई और कर्मचारियों की उपस्थिति की जांच के लिए अलग-अलग दस्तावेज़ों की आवश्यकता नहीं है। वेबवर्क टाइम ट्रैकर के साथ उपस्थिति की निगरानी प्रक्रिया को स्वचालित करें और कर्मचारियों के कामकाजी घंटों की शुरुआत और अंत को एक सुव्यवस्थित प्रारूप में देखें।',
                fIIPoints: [
                    'कार्य बोर्ड देखकर, कार्य की स्थिति की जांच करके और खर्च किए गए समय को ट्रैक करके वास्तविक समय में प्रगति की निगरानी करें।',
                    'कार्य प्रबंधन सहयोग सुविधाएं टीम के सदस्यों के साथ वास्तविक समय में संवाद करने के लिए। कार्यों पर टिप्पणी करें, प्रश्न पूछें, और सभी को सूचित रखने के लिए अपडेट प्रदान करें।',
                    'उत्पादकता ट्रैक करने के लिए ग्राहक और लीड प्रबंधन; कर्मचारियों की उत्पादकता और प्रमुख प्रदर्शन संकेतकों को कर्मचारियों द्वारा पूरे किए गए कार्य की मात्रा के आधार पर मापा जा सकता है।',
                    'लक्ष्यों को व्यवस्थित करने के लिए पदानुक्रमित संरचना; परियोजना लक्ष्यों, विभागीय उद्देश्यों, व्यक्तिगत प्रदर्शन लक्ष्यों, रणनीतिक पहलों आदि के लिए।',
                ],
            },
        },
    },
    payrollAndLeaveManagement: {
        productName: 'पेरोल और छुट्टी प्रबंधन',
        titleDescription: 'पेरोल प्रोसेसिंग और छुट्टी प्रशासन को अनुकूलित करें',
        headerCaption: 'ट्रेसफोर्स लीड प्रबंधन सीआरएम क्यों चुनें',
        productCards: [
            {
                title: 'प्रबंधन में आसानी',
                data: 'ग्राहक जानकारी को केंद्रीकृत करके, नियमित कार्यों को स्वचालित करके और संचार को सुव्यवस्थित करके प्रबंधन को सरल बनाएं।',
            },
            {
                title: 'कस्टमाइजेबिलिटी',
                data: 'कस्टमाइजेबिलिटी',
            },
            {
                title: 'एकीकरण',
                data: 'डेटा साझाकरण, कार्यों के स्वचालन और टीमों के बीच बेहतर सहयोग के लिए एकीकरण सक्षम करें, जिससे अधिक कुशल और प्रभावी सीआरएम कार्यान्वयन हो।',
            },
        ],
        whyChooseSubCaption:
            'लीड्स को प्रभावी ढंग से ट्रैक और पोषण करें, यह सुनिश्चित करते हुए कि कोई भी अवसर न छूटे और रूपांतरण दरों में सुधार हो।',
        headerT1: 'ट्रेसफोर्स लीड प्रबंधन प्रणाली के लाभ',
        subCaptionT1: 'लीड कैप्चर ऑटोमेशन और वितरण\nरियल-टाइम डेटा एकीकरण और रिपोर्ट\nबिक्री और विपणन उपकरण स्वचालन',

        features: {
            leadManagement: {
                featureHeader: 'पेरोल प्रोसेसिंग',
                featureDescription: 'समय पर फॉलो-अप, व्यक्तिगत इंटरैक्शन, और अधिकतम रूपांतरण दरें।',
                featureParagraph:
                    'अब कोई टाइमकार्ड, अत्यधिक कागजी कार्रवाई और कर्मचारियों की उपस्थिति की जांच के लिए अलग-अलग दस्तावेज़ों की आवश्यकता नहीं है। वेबवर्क टाइम ट्रैकर के साथ उपस्थिति की निगरानी प्रक्रिया को स्वचालित करें और कर्मचारियों के कामकाजी घंटों की शुरुआत और अंत को एक सुव्यवस्थित प्रारूप में देखें।',
                fIIPoints: [
                    'बिक्री बल के समय और स्थान के आधार पर अपने लीड प्रबंधन प्रक्रिया को सुव्यवस्थित करें, जिससे कम समय में लक्ष्यों को प्राप्त करना आसान हो जाता है।',
                    'वेबसाइट विज़िट, ईमेल इंटरैक्शन आदि के आधार पर खंड बनाने के लिए संपर्कों के इतिहास का उपयोग करें। इस डेटा के आधार पर बिक्री बल का विभाजन करें।',
                    'लीड स्कोर का उपयोग करके संभावनाओं को अपनी बिक्री टीम को सौंपें, या वर्कफ़्लो सेट करें ताकि प्रतिनिधियों को सूचित किया जा सके जब उनके संभावनाएं रूपांतरण के लिए तैयार हों।',
                    'प्रत्येक लीड का इतिहास आसानी से एक्सेस करें, जिसमें कंपनी की जानकारी और आपके व्यवसाय और संपर्क के बीच हर टचपॉइंट की समयरेखा शामिल हो।',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'उपस्थिति की समय-रेखा आधारित ट्रैकिंग',
                        paragraph:
                            'क्लॉक इन और क्लॉक आउट जैसी घटनाओं की उपस्थिति और मूवमेंट मॉनिटर करें और साथ ही कर्मचारियों की उनके संचालन, प्रदर्शन मीट्रिक्स या घटनाओं में अंतर्दृष्टि प्राप्त करें।',
                    },
                    customFormsforDataManagement: {
                        header: 'डेटा प्रबंधन के लिए कस्टम फॉर्म',
                        paragraph: 'दैनिक, साप्ताहिक और मासिक आधार पर काम के घंटों की निगरानी करें।',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'दूरस्थ रूप से उपस्थिति रिपोर्ट्स एक्सेस करें',
                        paragraph: 'विभिन्न शाखाओं की उपस्थिति रिपोर्ट्स को दूरस्थ रूप से एक्सेस करें।',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'उपस्थिति मॉनिटरिंग वन',
                        description: [
                            'कर्मचारियों की समयपालन की पारदर्शिता प्राप्त करें।',
                            'समय बचाएं और बिना किसी अतिरिक्त कार्रवाई के कर्मचारी उपस्थिति को व्यवस्थित करें।',
                            'आसानी से देखें कि क्या आपके कर्मचारी समय पर कार्यस्थल पर पहुंचे हैं और अपने काम के घंटे पूरे किए हैं या नहीं।',
                            'अपने कार्यस्थल संचालन को अधिक संगठित रखें और एक अत्यधिक जिम्मेदार कार्य वातावरण बनाएं।',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'उपस्थिति मॉनिटरिंग टू',
                        description: [
                            'उपस्थिति डेटा का बुद्धिमान विश्लेषण।',
                            'ड्रॉप-डाउन सूची से आप जल्दी जाने, देर से आने और कम ट्रैकिंग जैसे विकल्पों का चयन कर सकते हैं।',
                            'यह आपको मैन्युअल रूप से खोजे बिना अपनी पसंदीदा जानकारी तुरंत उत्पन्न करने में मदद करेगा।',
                            'आप दैनिक आधार पर उपस्थिति को ट्रैक कर सकते हैं या आप कैलेंडर से एक अंतराल चुन सकते हैं।',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'उपस्थिति मॉनिटरिंग थ्री',
                        description: [
                            '100% टचलेस सिस्टम के साथ वास्तविक समय में कर्मचारी उपस्थिति की निगरानी और ट्रैक करने का एक सुरक्षित और पारदर्शी तरीका।',
                            'आसान सेटअप।',
                            'स्वचालित चेक इन और आउट्स की विस्तृत रिपोर्ट।',
                            'ट्रैकिंग समय के साथ स्क्रीनशॉट।',
                            'गतिविधि स्तर रिकॉर्डिंग।',
                            'ऐप्स और वेबसाइट उपयोग की निगरानी।',
                        ],
                    },
                },
            },
            leaveManagement: {
                featureHeader: 'छुट्टी प्रबंधन',
                featureDescription:
                    'कर्मचारियों की संख्या को प्रभावी ढंग से संभालें और ट्रैक करें, यह सुनिश्चित करें कि उचित दस्तावेज़ीकरण, कंपनी नीतियों का पालन और कार्यप्रवाह की निरंतरता बनी रहे।',
                featureParagraph:
                    'अब कोई टाइमकार्ड, अत्यधिक कागजी कार्रवाई और कर्मचारियों की उपस्थिति की जांच के लिए अलग-अलग दस्तावेज़ों की आवश्यकता नहीं है। वेबवर्क टाइम ट्रैकर के साथ उपस्थिति की निगरानी प्रक्रिया को स्वचालित करें और कर्मचारियों के कामकाजी घंटों की शुरुआत और अंत को एक सुव्यवस्थित प्रारूप में देखें।',
                fIIPoints: [
                    'अवकाश की संचयीता को प्रबंधित करें, यह सुनिश्चित करते हुए कि कर्मचारी आवंटित मात्रा से अधिक न जाएं और नकारात्मक छुट्टी संतुलन से बचें।',
                    'संस्था की लचीली छुट्टी नीतियों को कॉन्फ़िगर करें, छुट्टी कैलेंडर सेट करके छुट्टी अनुरोधों के अनुमोदन या अस्वीकृति के लिए समानांतर या क्रमिक पदानुक्रम सेटअप सक्षम करें।',
                    'अवकाश अधिकारों से संबंधित श्रम कानूनों और विनियमों के बारे में सूचित रहें। सुनिश्चित करें कि आपकी छुट्टी प्रबंधन नीतियां कंपनी की नीतियों का अनुपालन करती हैं।',
                    'कर्मचारियों को सेल्फ-सर्विस विकल्प प्रदान करें, जिससे वे अपने अवकाश बैलेंस की जांच कर सकें, अनुरोध सबमिट कर सकें, और अपने अनुरोधों की स्थिति देख सकें।',
                ],
            },
        },
    },
    orderManagement: {
        productName: 'ऑर्डर प्रबंधन',
        titleDescription: 'एक स्केलेबल और स्वचालित समाधान के साथ आदेश/संग्रह संचालन को सरल बनाएं।',
        headerCaption: 'ट्रेसफोर्स लीड प्रबंधन CRM क्यों चुनें',
        productCards: [
            {
                title: 'प्रबंधन की सरलता',
                data: 'ग्राहक जानकारी को केंद्रीकृत करके, नियमित कार्यों को स्वचालित करके, और संचार को सुव्यवस्थित करके प्रबंधन को सरल बनाएं।',
            },
            {
                title: 'कस्टमाइज़ेबिलिटी',
                data: 'कस्टमाइज़ेबिलिटी',
            },
            {
                title: 'इंटीग्रेशंस',
                data: 'डेटा साझाकरण, कार्यों के स्वचालन, और टीमों के बीच बेहतर सहयोग के लिए इंटीग्रेशंस सक्षम करें, जो CRM कार्यान्वयन को अधिक कुशल और प्रभावी बनाता है।',
            },
        ],
        whyChooseSubCaption:
            'लीड्स को कुशलतापूर्वक ट्रैक और पोषण करें, सुनिश्चित करें कि कोई भी अवसर न छूटे और रूपांतरण दरों में सुधार करें।',
        headerT1: 'ट्रेसफोर्स लीड प्रबंधन सिस्टम के लाभ',
        subCaptionT1:
            'लीड कैप्चर स्वचालन और वितरण\nरियल टाइम डेटा इंटीग्रेशन और रिपोर्ट्स\nविपणन और बिक्री उपकरण स्वचालन',
        features: {
            digitizationOfOrder: {
                featureHeader: 'ऑर्डर का डिजिटलीकरण',
                featureDescription: 'ऑर्डर प्रबंधन को एंड-टू-एंड सुव्यवस्थित करें और मैनुअल त्रुटियों को कम करें',
                featureParagraph:
                    'अब समयकार्ड, अत्यधिक कागजी कार्रवाई और विभिन्न दस्तावेजों की आवश्यकता नहीं है। वेबवर्क टाइम ट्रैकर के साथ उपस्थिति निगरानी प्रक्रिया को स्वचालित करें और अपने कर्मचारियों के कामकाजी घंटों की शुरुआत और अंत को एक सुव्यवस्थित प्रारूप में देखें।',
                fIIPoints: [
                    'ऑर्डर प्रोसेसिंग प्रदर्शन और कर्मचारी उत्पादकता की निगरानी के लिए विस्तृत रिपोर्ट और एनालिटिक्स उत्पन्न करें।',
                    'कर्मचारियों या ग्राहकों द्वारा दर्ज किए गए ऑर्डर विवरण को स्वचालित रूप से कैप्चर और मान्य करें।',
                    'कर्मचारियों और ग्राहकों को ऑर्डर स्थिति और शिपमेंट ट्रैकिंग पर वास्तविक समय में अपडेट प्रदान करें।',
                    'ऑर्डर स्वीकृति प्रक्रियाओं को स्वचालित करें और उचित कर्मचारियों को कार्य सौंपें।',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'समय आधारित उपस्थिति ट्रैकिंग',
                        paragraph:
                            'उपस्थिति और मूवमेंट मॉनिटर इवेंट्स जैसे कि क्लॉक इन और क्लॉक आउट और कर्मचारियों के उनके संचालन, प्रदर्शन मैट्रिक्स, या इवेंट्स में अंतर्दृष्टि।',
                    },
                    customFormsforDataManagement: {
                        header: 'डेटा प्रबंधन के लिए कस्टम फॉर्म',
                        paragraph: 'डेली, वीकली, और मंथली के आधार पर कामकाजी घंटों की निगरानी करें।',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'रिमोट एक्सेस उपस्थिति रिपोर्ट्स',
                        paragraph: 'कई शाखाओं की उपस्थिति रिपोर्ट्स को रिमोटली एक्सेस करें।',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'उपस्थिति निगरानी एक',
                        description: [
                            'कर्मचारियों की समय पर उपस्थिति की पारदर्शिता प्राप्त करें',
                            'समय और बिना किसी अतिरिक्त कार्रवाई के कर्मचारी उपस्थिति को व्यवस्थित करें।',
                            'आसानी से निगरानी करें कि आपके कर्मचारी समय पर कार्यस्थल पर पहुंचे हैं या नहीं और उन्होंने अपने कामकाजी घंटे पूरे किए हैं या नहीं।',
                            'अपने कार्यस्थल की प्रक्रियाओं को अधिक व्यवस्थित रखें और एक जिम्मेदार कार्य वातावरण बनाए रखें।',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'उपस्थिति निगरानी दो',
                        description: [
                            'उपस्थिति डेटा का बुद्धिमान विश्लेषण',
                            'जल्दी जाने, देर से आने, और कम ट्रैकिंग को फ़िल्टर करें, जिसे आप ड्रॉप-डाउन सूची से चुन सकते हैं।',
                            'यह आपको आपकी पसंदीदा जानकारी तुरंत उत्पन्न करने में मदद करेगा, बिना मैनुअल खोज के।',
                            'आप उपस्थिति को दैनिक आधार पर ट्रैक कर सकते हैं या आप कैलेंडर से एक अंतराल चुन सकते हैं।',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'उपस्थिति निगरानी तीन',
                        description: [
                            'वास्तविक समय में 100% टचलेस सिस्टम के साथ कर्मचारी उपस्थिति की निगरानी और ट्रैकिंग करने का सुरक्षित और पारदर्शी तरीका।',
                            'आसान सेटअप',
                            'स्वचालित चेक-इन और चेक-आउट, विस्तृत रिपोर्ट्स',
                            'ट्रैकिंग टाइम के साथ स्क्रीनशॉट्स',
                            'गतिविधि स्तर रिकॉर्डिंग',
                            'ऐप्स और वेबसाइट उपयोग की निगरानी',
                        ],
                    },
                },
            },
            targetManagement: {
                featureHeader: 'लक्ष्य प्रबंधन',
                featureDescription:
                    'प्रत्येक कर्मचारी के लिए उनके रोल और क्षमताओं के आधार पर स्पष्ट और प्राप्त करने योग्य लक्ष्य सेट करें।',
                featureParagraph:
                    'अब समयकार्ड, अत्यधिक कागजी कार्रवाई, और विभिन्न दस्तावेजों की आवश्यकता नहीं है। वेबवर्क टाइम ट्रैकर के साथ उपस्थिति निगरानी प्रक्रिया को स्वचालित करें और अपने कर्मचारियों के कामकाजी घंटों की शुरुआत और अंत को एक सुव्यवस्थित प्रारूप में देखें।',
                fIIPoints: [
                    'डेली, वीकली, मंथली लक्ष्यों को प्राप्त करें और लाइव फील्ड ट्रैकिंग के साथ कर्मचारी उपलब्धियों के रिकॉर्ड रखें।',
                    'रियल-टाइम प्रगति (लक्ष्यों की संख्या जो प्राप्त की गई बनाम शेष), चेक-इन/चेक-आउट समय, प्रत्येक कार्य पर बिताए गए घंटे (अवधि), और स्थान ट्रैकिंग।',
                    'प्रदर्शन मैट्रिक्स (पूर्णता दर, समय लिया गया, ग्राहक संतोष स्कोर), लक्ष्यों की तुलना (वास्तविक प्रदर्शन बनाम लक्ष्य), ऐतिहासिक डेटा (पिछले प्रदर्शन रुझान)।',
                    'लक्ष्य प्राप्ति अलर्ट, प्रदर्शन रिमाइंडर, और डेडलाइन चेतावनियों के बारे में सूचित करने के लिए स्वचालित नोटिफिकेशन भेजें।',
                ],
            },
            invoiceManagement: {
                featureHeader: 'चालान प्रबंधन',
                featureDescription: 'स्वचालित अलर्ट और सूचनाओं के साथ चालान की स्थिति और प्रोसेसिंग को ट्रैक करें।',
                fIIPoints: [
                    'चालान की स्थिति को ट्रैक करने और देय और ओवरड्यू भुगतान के लिए स्वचालित रिमाइंडर और अलर्ट भेजने के लिए सिस्टम सेट करें।',
                    'मानकीकृत चालान टेम्पलेट्स जो ग्राहक जानकारी, आदेश विवरण, कर, छूट, और भुगतान शर्तों जैसे सभी आवश्यक विवरण शामिल हैं।',
                    'चालान प्रक्रिया के हिस्से के रूप में डिजिटल हस्ताक्षर और OTP-आधारित डिलीवरी प्रमाण एकत्र करें।',
                    'केंद्रीय सिस्टम (क्लाउड-आधारित स्टोरेज) के साथ स्वचालित समन्वय और चालान की स्थिति पर वास्तविक समय में अपडेट।',
                ],
            },
            distributorOrder: {
                featureHeader: 'वितरक आदेश',
                featureDescription:
                    'उत्पाद/संग्रह को प्रबंधित करें और वास्तविक समय में इन्वेंट्री पर अपडेट के साथ कार्य प्राथमिकता को संभालें।',
                fIIPoints: [
                    'प्रत्येक वितरण के लिए सभी संबंधित विवरणों के साथ आदेश बनाएं और प्रोसेस करें और उपलब्धता के आधार पर कार्य सौंपें।',
                    'सटीक स्टॉक आवंटन सुनिश्चित करने और वास्तविक समय में इन्वेंट्री स्तर अपडेट करने के लिए इन्वेंट्री प्रबंधन के साथ इंटीग्रेट करें।',
                    'आदेश प्रक्रिया के प्रमुख चरणों पर स्वचालित सूचनाएं भेजने के लिए सिस्टम को कॉन्फ़िगर करें।',
                    'डैशबोर्ड एक्सेस करें जहाँ कर्मचारी आदेश देख सकते हैं, प्रगति ट्रैक कर सकते हैं, और आवश्यक दस्तावेज़ डाउनलोड कर सकते हैं।',
                ],
            },
            inventoryManagement: {
                featureHeader: 'इन्वेंट्री प्रबंधन',
                featureDescription:
                    'सही स्टॉक संतुलन से वास्तविक समय में अपडेट और इन्वेंट्री ट्रैकिंग के लिए सटीक डेटा।',
                fIIPoints: [
                    'इन्वेंट्री और ऑर्डर प्रबंधन सिस्टम के बीच निर्बाध एकीकरण सुनिश्चित करें ताकि स्टॉक स्तरों को आने वाले और जाने वाले आदेशों के साथ समन्वयित किया जा सके।',
                    'कई गोदामों, स्टोर्स, या स्थानों में इन्वेंट्री को ट्रैक करता है और केंद्रीकृत नियंत्रण तक पहुंच प्रदान करता है, स्टॉक वितरण को अनुकूलित करता है और दृश्यता में सुधार करता है।',
                ],
            },
            orderCollectionManagement: {
                featureHeader: 'ऑर्डर / संग्रह प्रबंधन',
                featureDescription: 'एक स्केलेबल और स्वचालित समाधान के साथ आदेश/संग्रह संचालन को सरल बनाएं।',
                fIIPoints: [
                    'एक केंद्रीकृत सिस्टम जो ऑन-साइट और ऑफ-साइट दोनों कर्मचारियों द्वारा आदेशों का प्रबंधन, प्रगति ट्रैकिंग, और वास्तविक समय में स्थिति अपडेट करने के लिए सुलभ है।',
                    'भिन्न-भिन्न स्तरों की पहुंच और अनुमतियाँ भूमिकाओं (जैसे, प्रबंधक, ऑन-साइट कर्मचारी, ऑफ-साइट कर्मचारी) के आधार पर सुनिश्चित करें ताकि डेटा सुरक्षा और उचित पहुंच सुनिश्चित की जा सके।',
                    'सुनिश्चित करें कि सभी आदेश डेटा सभी डिवाइसों और स्थानों में वास्तविक समय में समन्वयित है, ताकि सभी को नवीनतम जानकारी तक पहुंच प्राप्त हो।',
                    'ऑन-साइट और ऑफ-साइट कर्मचारियों के बीच इन-ऐप मैसेजिंग और सूचनाओं के माध्यम से संचार को सुगम बनाएं।',
                ],
            },
        },
    },
};
export default products;
