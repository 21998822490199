const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'Planos Flexíveis que Crescem com Você',
    subFlexiblePlansThatGrowsWithYou:
        'Escolha o plano de preços que atende às necessidades do seu negócio. A Traceforce oferece pacotes de preços com valores otimizados e superior valor.',
    pricingPlans: 'Planos de Preços',
    pricingThatWorksForEveryOne: 'Preços que Funcionam para Todos',
    monthly: 'Mensal',
    annualy: 'Anual',
    international: 'Internacional',
    domestic: 'Nacional',
    month: 'Mês',
    benifits: 'Benefícios',
    allPlanIncludeTheseBenifits: 'Todos os planos incluem estes benefícios',
    someBenfitsOfMonthlyYearlySubscription: 'Alguns Benefícios da Assinatura Mensal/Anual',
    benifitsArr: [
        {
            title: 'Suporte Técnico',
            description: 'Nossa equipe profissional de suporte técnico ajudará você em cada etapa do processo.',
        },
        {
            title: 'Tecnologia',
            description: 'Treinamento especial para começar com a plataforma a partir de profissionais.',
        },
        {
            title: 'Análise de Crescimento',
            description: 'Uma equipe dedicada para fornecer insights sobre seu crescimento mensal.',
        },
        {
            title: 'Recompensa',
            description: 'Uma recompensa especial para a conta com o melhor desempenho a cada mês.',
        },
    ],
    fAQs: 'Perguntas Frequentes',
    frequentlyAskedQuestions: 'Perguntas Frequentes',
    someBaTypeOfQuestions: 'Aqui estão alguns tipos comuns de perguntas de nossos clientes',
    fAQArr: [
        {
            question: 'Como posso me inscrever para minha empresa?',
            ans: 'Sim, a licença do marketplace permite usar este tema em qualquer produto final. Para mais informações sobre a licença, consulte os termos da licença no marketplace.',
        },
        {
            question: 'Onde posso adicionar meu veículo de força de trabalho?',
            ans: 'Não, este é um modelo HTML. Ele não funcionará diretamente com o WordPress, embora você possa convertê-lo em um tema compatível com o WordPress.',
        },
        {
            question: 'Como minha força de trabalho faz login no Traceforce?',
            ans: 'Envie suas questões ou feedback para nosso e-mail de suporte dedicado (support@coderthemes.com). Estamos disponíveis a qualquer momento para ajudar você.',
        },
        {
            question: 'Posso rastrear minha força de trabalho pelo celular?',
            ans: 'Sim, atualizaremos regularmente os prompts. Todas as futuras atualizações estarão disponíveis gratuitamente.',
        },
        {
            question: 'Como marcar presença?',
            ans: 'Sim, a licença do marketplace permite usar este tema em qualquer produto final. Para mais informações sobre a licença, consulte os termos da licença no marketplace.',
        },
        {
            question: 'Como desativar opções de desenvolvedor?',
            ans: 'Não, este é um modelo HTML. Ele não funcionará diretamente com o WordPress, embora você possa convertê-lo em um tema compatível com o WordPress.',
        },
        {
            question: 'Quais recursos o Traceforce oferece?',
            ans: 'Envie suas questões ou feedback para nosso e-mail de suporte dedicado (support@coderthemes.com). Estamos disponíveis a qualquer momento para ajudar você.',
        },
        {
            question: 'Preciso instalar o software Traceforce?',
            ans: 'Sim, atualizaremos regularmente os prompts. Todas as futuras atualizações estarão disponíveis gratuitamente.',
        },
        {
            question: 'Qual é o processo de implementação do software Traceforce?',
            ans: 'Sim, a licença do marketplace permite usar este tema em qualquer produto final. Para mais informações sobre a licença, consulte os termos da licença no marketplace.',
        },
        {
            question: 'Quais opções de treinamento e suporte estão disponíveis com o software Traceforce?',
            ans: 'Não, este é um modelo HTML. Ele não funcionará diretamente com o WordPress, embora você possa convertê-lo em um tema compatível com o WordPress.',
        },
        {
            question: 'O software Traceforce pode ser personalizado de acordo com nossos processos específicos de RH?',
            ans: 'Envie suas questões ou feedback para nosso e-mail de suporte dedicado (support@coderthemes.com). Estamos disponíveis a qualquer momento para ajudar você.',
        },
        {
            question: 'Como o software HRMS pode ajudar com conformidade e relatórios?',
            ans: 'Sim, atualizaremos regularmente os prompts. Todas as futuras atualizações estarão disponíveis gratuitamente.',
        },
        {
            question: 'O software HRMS suporta trabalho remoto e acesso móvel?',
            ans: 'Sim, atualizaremos regularmente os prompts. Todas as futuras atualizações estarão disponíveis gratuitamente.',
        },
    ],
};
export default pricingPage;
