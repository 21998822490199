const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'लचीले योजनाएं जो आपके साथ बढ़ती हैं',
    subFlexiblePlansThatGrowsWithYou:
        'उस मूल्य निर्धारण योजना का चयन करें जो आपकी व्यावसायिक आवश्यकताओं को पूरा करती है। ट्रेसफोर्स आदर्श मूल्य पर मूल्य पैकेज प्रदान करता है, जो उत्कृष्ट मूल्य के साथ है।',
    pricingPlans: 'मूल्य निर्धारण योजनाएं',
    pricingThatWorksForEveryOne: 'हर किसी के लिए काम करने वाली मूल्य निर्धारण',
    monthly: 'मासिक',
    annualy: 'वार्षिक',
    international: 'अंतर्राष्ट्रीय',
    domestic: 'घरेलू',
    month: 'महीना',
    benifits: 'लाभ',
    allPlanIncludeTheseBenifits: 'सभी योजनाओं में ये लाभ शामिल हैं',
    someBenfitsOfMonthlyYearlySubscription: 'मासिक/वार्षिक सदस्यता के कुछ लाभ',
    benifitsArr: [
        {
            title: 'तकनीकी सहायता',
            description: 'हमारी पेशेवर तकनीकी सहायता टीम हर कदम पर आपकी मदद करेगी',
        },
        {
            title: 'प्रौद्योगिकी',
            description: 'प्लेटफ़ॉर्म के साथ शुरुआत करने के लिए पेशेवरों द्वारा एक विशेष प्रशिक्षण',
        },
        {
            title: 'विकास विश्लेषण',
            description: 'हर महीने आपकी वृद्धि के बारे में अंतर्दृष्टि प्राप्त करने के लिए एक समर्पित टीम',
        },
        {
            title: 'इनाम',
            description: 'हर महीने सबसे अधिक प्रदर्शन करने वाले खाते के लिए एक विशेष इनाम',
        },
    ],
    fAQs: 'पूछे जाने वाले प्रश्न',
    frequentlyAskedQuestions: 'अक्सर पूछे जाने वाले प्रश्न',
    someBaTypeOfQuestions: 'यहाँ हमारे ग्राहकों के लिए कुछ सामान्य प्रकार के प्रश्न दिए गए हैं',
    fAQArr: [
        {
            question: 'मैं अपनी फर्म का साइन अप कैसे कर सकता हूं?',
            ans: 'हाँ, मार्केटप्लेस लाइसेंस आपको इस थीम का उपयोग किसी भी अंतिम उत्पाद में करने की अनुमति देता है। लाइसेंस की अधिक जानकारी के लिए कृपया मार्केटप्लेस पर लाइसेंस शर्तों को देखें।',
        },
        {
            question: 'मैं अपनी वर्कफोर्स-वाहन कहाँ जोड़ सकता हूँ?',
            ans: 'नहीं, यह एक HTML टेम्पलेट है। यह सीधे वर्डप्रेस के साथ काम नहीं करेगा, हालाँकि आप इसे वर्डप्रेस संगत थीम में बदल सकते हैं।',
        },
        {
            question: 'मेरा वर्कफोर्स Traceforce में कैसे लॉग इन करता है?',
            ans: 'हमारी समर्पित समर्थन ईमेल (support@coderthemes.com) का उपयोग करके अपने मुद्दों या फ़ीडबैक को भेजें। हम किसी भी समय मदद के लिए उपलब्ध हैं।',
        },
        {
            question: 'क्या मैं मोबाइल के माध्यम से अपनी वर्कफोर्स को ट्रैक कर सकता हूँ?',
            ans: 'हाँ, हम नियमित रूप से प्रॉम्प्ट को अपडेट करेंगे। सभी भविष्य के अपडेट मुफ्त में उपलब्ध होंगे।',
        },
        {
            question: 'उपस्थिति कैसे चिह्नित करें?',
            ans: 'हाँ, मार्केटप्लेस लाइसेंस आपको इस थीम का उपयोग किसी भी अंतिम उत्पाद में करने की अनुमति देता है। लाइसेंस की अधिक जानकारी के लिए कृपया मार्केटप्लेस पर लाइसेंस शर्तों को देखें।',
        },
        {
            question: 'डेवलपर विकल्पों को कैसे निष्क्रिय करें?',
            ans: 'नहीं, यह एक HTML टेम्पलेट है। यह सीधे वर्डप्रेस के साथ काम नहीं करेगा, हालाँकि आप इसे वर्डप्रेस संगत थीम में बदल सकते हैं।',
        },
        {
            question: 'Traceforce क्या सुविधाएँ प्रदान करता है?',
            ans: 'हमारी समर्पित समर्थन ईमेल (support@coderthemes.com) का उपयोग करके अपने मुद्दों या फ़ीडबैक को भेजें। हम किसी भी समय मदद के लिए उपलब्ध हैं।',
        },
        {
            question: 'क्या मुझे Traceforce सॉफ़्टवेयर स्थापित करने की आवश्यकता है?',
            ans: 'हाँ, हम नियमित रूप से प्रॉम्प्ट को अपडेट करेंगे। सभी भविष्य के अपडेट मुफ्त में उपलब्ध होंगे।',
        },
        {
            question: 'Traceforce सॉफ़्टवेयर के कार्यान्वयन की प्रक्रिया क्या है?',
            ans: 'हाँ, मार्केटप्लेस लाइसेंस आपको इस थीम का उपयोग किसी भी अंतिम उत्पाद में करने की अनुमति देता है। लाइसेंस की अधिक जानकारी के लिए कृपया मार्केटप्लेस पर लाइसेंस शर्तों को देखें।',
        },
        {
            question: 'Traceforce सॉफ़्टवेयर के साथ कौन-कौन से प्रशिक्षण और समर्थन विकल्प उपलब्ध हैं?',
            ans: 'नहीं, यह एक HTML टेम्पलेट है। यह सीधे वर्डप्रेस के साथ काम नहीं करेगा, हालाँकि आप इसे वर्डप्रेस संगत थीम में बदल सकते हैं।',
        },
        {
            question: 'क्या Traceforce सॉफ़्टवेयर को हमारे विशिष्ट HR प्रक्रियाओं के अनुसार अनुकूलित किया जा सकता है?',
            ans: 'हमारी समर्पित समर्थन ईमेल (support@coderthemes.com) का उपयोग करके अपने मुद्दों या फ़ीडबैक को भेजें। हम किसी भी समय मदद के लिए उपलब्ध हैं।',
        },
        {
            question: 'HRMS सॉफ़्टवेयर अनुपालन और रिपोर्टिंग में कैसे मदद कर सकता है?',
            ans: 'हाँ, हम नियमित रूप से प्रॉम्प्ट को अपडेट करेंगे। सभी भविष्य के अपडेट मुफ्त में उपलब्ध होंगे।',
        },
        {
            question: 'क्या HRMS सॉफ़्टवेयर दूरस्थ कार्य और मोबाइल एक्सेस का समर्थन करता है?',
            ans: 'हाँ, हम नियमित रूप से प्रॉम्प्ट को अपडेट करेंगे। सभी भविष्य के अपडेट मुफ्त में उपलब्ध होंगे।',
        },
    ],
};

export default pricingPage;
