const features = {
    attendanceMonitoring: {
        featureSubcaption: 'Эффективность в каждом моменте - Инновационные решения для систем управления сотрудниками',
        featureHeader: 'Мониторинг присутствия',
        featureDescription:
            'Информация о входе/выходе в реальном времени о присутствии или отсутствии лиц в пределах геозоны или филиала',
        featureParagraph:
            'Больше не нужно карточек времени, избыточной документации и различных документов для проверки присутствия сотрудников. Автоматизируйте процесс мониторинга присутствия с помощью WebWork Time Tracker и отслеживайте начало и конец рабочего времени ваших сотрудников в структурированном формате.',
        featureCaption: 'Управляйте присутствием ваших сотрудников на нескольких филиалах и в офисе',
        keyPoints: [
            {
                header: 'Присутствие в реальном времени',
                paragraph: 'Информация о присутствии или отсутствии лиц в пределах геозоны или филиала',
            },
            {
                header: 'Расчет общего рабочего времени',
                paragraph: 'Мониторинг рабочего времени на основе ежедневных, недельных и месячных отчетов',
            },
            {
                header: 'Удаленный доступ к отчетам о присутствии',
                paragraph: 'Доступ к отчетам о присутствии из нескольких филиалов удаленно',
            },
            {
                header: 'Централизованное управление присутствием',
                paragraph:
                    'Консолидация данных, процессов и систем, связанных с присутствием, в одной централизованной платформе или системе',
            },
        ],
        softwareDetails: [
            {
                title: 'Получите инсайты по отслеживанию присутствия сотрудников',
                description: [
                    'Получите прозрачность по пунктуальности сотрудников',
                    'Экономьте время и организуйте присутствие сотрудников без дополнительных действий.',
                    'Легко отслеживайте, пришли ли ваши сотрудники на рабочее место вовремя и выполнили ли свои рабочие часы.',
                    'Сделайте свои рабочие операции более организованными и создайте ответственный рабочий климат.',
                ],
                sDKeys: [
                    'Автоматически регистрируйте вход и выход сотрудников на месте, настроив периметр геозоны.',
                    'Получайте уведомления для сотрудников, которые забыли или не смогли зарегистрироваться на работу или выйти с работы.',
                    'Находите и отслеживайте перемещения ваших сотрудников в поле в реальном времени сразу после их регистрации присутствия.',
                ],
            },
            {
                title: 'Получите подробные отчеты по мониторингу присутствия',
                description: [
                    'Интеллектуальный анализ данных о присутствии',
                    'Фильтры раннего ухода, опозданий и менее частого отслеживания, которые можно выбрать из выпадающего списка.',
                    'Это поможет вам мгновенно получить нужную информацию без необходимости искать ее вручную.',
                    'Вы можете отслеживать присутствие ежедневно или выбрать интервал в календаре.',
                ],
                sDKeys: [
                    'Получите сводки по присутствию для вашей организации.',
                    'Создавайте и используйте отчеты для анализа трендов в вашей команде, отделах или филиалах.',
                    'Получайте отчеты в регулярные интервалы на вашу электронную почту (время входа, время выхода, общее рабочее время, количество сеансов).',
                    'Полезные данные для принятия обоснованных решений.',
                ],
            },
            {
                title: 'Изучите другие функции времени и продуктивности',
                description: [
                    'Безопасный и прозрачный способ мониторинга и отслеживания присутствия сотрудников в реальном времени с помощью системы на 100% без прикосновений.',
                    'Легкая настройка',
                    'Автоматизированный вход и выход, детализированные отчеты',
                    'Скриншоты с отслеживанием времени',
                    'Запись уровня активности',
                    'Мониторинг использования приложений и веб-сайтов',
                ],
                sDKeys: [
                    'Мониторинг присутствия сотрудников помогает управлять временем и повышать продуктивность.',
                    'Исключите бумажную работу и соберите точные записи присутствия сотрудников.',
                ],
            },
        ],
    },
    leaveManagement: {
        featureHeader: 'Управление отпусками',
        feaatureSubcaption:
            'Координируйте отсутствие сотрудников, поддерживая уровень производительности и операционную непрерывность.',
        featureDescription:
            'Эффективно управляйте и отслеживайте отсутствия и присутствия, обеспечивая надлежащую документацию, соблюдение корпоративных политик и поддержание гладкого рабочего процесса.',
        featureParagraph:
            'Больше не нужно карточек времени, избыточной документации и различных документов для проверки присутствия сотрудников. Автоматизируйте процесс мониторинга присутствия с помощью WebWork Time Tracker и отслеживайте начало и конец рабочего времени ваших сотрудников в структурированном формате.',
        featureCaption:
            'Оставайтесь в курсе предстоящих отпусков ваших сотрудников заранее и планируйте свой день соответственно.',
        keyPoints: [
            {
                header: 'Автоматический расчет начислений и остатков отпусков',
                paragraph:
                    'Управляйте начислением отпусков, чтобы сотрудники не превышали установленный лимит и предотвращайте отрицательные остатки отпуска.',
            },
            {
                header: 'Гибкие политики отпуска и рабочие процессы согласования',
                paragraph:
                    'Настройте гибкие политики отпуска организации, установив календарь отпусков. Включите параллельную или последовательную иерархию для одобрения или отклонения запросов на отпуск.',
            },
            {
                header: 'Соблюдение трудового законодательства и корпоративных политик',
                paragraph:
                    'Будьте в курсе трудового законодательства и нормативных актов, связанных с правом на отпуск. Убедитесь, что ваши политики управления отпуском соответствуют корпоративным политикам.',
            },
            {
                header: 'Опции самообслуживания',
                paragraph:
                    'Предоставьте сотрудникам опции самообслуживания, позволяя им проверять остатки отпусков, подавать запросы и просматривать статус их запросов.',
            },
        ],
        softwareDetails: [
            {
                title: 'Множественные и гибкие политики отпусков для вашей команды',
                description: [
                    'Гибкие политики отпусков для удовлетворения ваших конкретных потребностей с настраиваемыми параметрами',
                    'Множественные схемы и политики отпусков, адаптированные для различных групп сотрудников',
                    'Поддержка неограниченных типов отпусков и транзакций отпусков',
                ],
                sDKeys: [
                    'Эти политики включают такие виды отпусков, как ежегодный отпуск, больничный, родительский отпуск, отпуск по случаю утраты и т.д.',
                    'Установите критерии eligibility для политик',
                    'Темпы накопления, правила использования, необходимые документы',
                ],
            },
            {
                title: 'Самообслуживание для подачи заявлений на отпуск и рабочего процесса одобрения',
                description: [
                    'Автоматизация снижает усилия сотрудников. Сотрудники подают заявки на отпуск через веб-портал или мобильное приложение',
                    'Трекер отпусков для остатков и транзакций отпусков',
                    'Видимость данных по отпускам команды для менеджеров и шаблоны отпуска для лучших инсайтов',
                    'Рабочие процессы с эскалациями приводят к отзывчивой системе и отличному опыту сотрудников',
                    'Уведомления и оповещения по электронной почте',
                ],
                sDKeys: [
                    'Сотрудники подают заявки на отпуск через веб-портал или мобильное приложение',
                    'Трекер отпусков для остатков и транзакций отпусков',
                    'Видимость данных по отпускам команды для менеджеров и шаблоны отпуска для лучших инсайтов',
                    'Рабочие процессы с эскалациями приводят к отзывчивой системе и отличному опыту сотрудников',
                    'Уведомления и оповещения по электронной почте',
                ],
            },
            {
                title: 'Соблюдение корпоративных политик и трудового законодательства',
                description: [
                    'Эффективное управление отпусками сотрудников не только обеспечивает соблюдение трудового законодательства, но и способствует положительной корпоративной культуре. Внедряя эффективные политики отпусков и используя технологии, организации могут оптимизировать процесс и поддерживать потребности сотрудников в отдыхе.',
                ],
                sDKeys: [
                    'Эффективное управление обязательствами по отпускам для бюджета и минимизация финансовых рисков',
                    'Генерация статистических данных для индивидуальных отчетов для отслеживания производительности на основе месячных, квартальных или годовых периодов',
                    'Будьте в курсе планирования рабочей силы, распределения ресурсов и стратегий вовлеченности сотрудников',
                    'Подробные записи и отчеты в соответствии с государственными правилами',
                    'Индивидуальный полный список праздников для разнородной рабочей силы',
                ],
            },
        ],
    },
    shiftManagement: {
        featureHeader: 'Управление сменами',
        feaatureSubcaption:
            'Планируйте рабочую силу с учетом продаж, обеспечьте оптимальный уровень персонала для удовлетворения потребностей клиентов и максимизируйте продуктивность.',
        featureDescription:
            'Организуйте и планируйте рабочие часы, чтобы оптимизировать продуктивность, удовлетворить операционные потребности и обеспечить покрытие в разные временные периоды.',
        featureParagraph:
            'Больше не нужно карточек времени, избыточной документации и различных документов для проверки присутствия сотрудников. Автоматизируйте процесс мониторинга присутствия с помощью WebWork Time Tracker и отслеживайте начало и конец рабочего времени ваших сотрудников в структурированном формате.',
        featureCaption:
            'Отслеживайте смены ваших сотрудников на основе общего рабочего времени и агрегированных данных по нескольким сессиям.',
        keyPoints: [
            {
                header: 'Управление рабочими графиками сотрудников',
                paragraph:
                    'Разработайте четкий и хорошо организованный график смен. Определите различные типы смен в зависимости от характера вашего бизнеса.',
            },
            {
                header: 'Определение смен, назначение ролей, установка повторяющихся или пользовательских графиков',
                paragraph:
                    'Анализируйте объем работы и задачи, чтобы определить количество сотрудников, необходимых для различных смен.',
            },
            {
                header: 'Встроенные инструменты связи',
                paragraph:
                    'Централизованная платформа для сотрудников, чтобы получить доступ к своим графикам и установить надежный канал связи для любых обновлений или изменений.',
            },
            {
                header: 'Централизованное управление сменами с графиками для нескольких локаций',
                paragraph:
                    'Централизованный контроль и управление графиками сотрудников, позволяющий менеджерам эффективно распределять смены, отслеживать присутствие и управлять ресурсами рабочей силы в нескольких локациях с одной платформы.',
            },
        ],
        softwareDetails: [
            {
                title: 'Автоматизация процесса планирования на основе локации и смен',
                description: [
                    'Анализ объема работы и задач для определения количества сотрудников, необходимых в различные смены.',
                    'Учитывайте часы пиковой нагрузки, сезонные колебания и любые специфические требования для каждой смены. Это может включать дневные смены, ночные смены, смены с ротацией, раздельные смены или гибкие графики.',
                ],
                sDKeys: [
                    'Обмен сменами между сотрудниками',
                    'Сотрудники могут обмениваться или менять свои смены с другими сотрудниками с помощью доступной функции/опции.',
                    'Сотрудники могут выбирать свои смены и время отдыха, которые наиболее подходят для них.',
                ],
            },
            {
                title: 'Доска смен для динамического планирования смен',
                description: [
                    'Обеспечьте соблюдение трудового законодательства в отношении перерывов и периодов отдыха.',
                    'Включите перерывы в график смен, чтобы предотвратить усталость сотрудников и поддерживать продуктивность.',
                    'Убедитесь, что ваши политики управления сменами соответствуют законодательным требованиям.',
                ],
                sDKeys: [
                    'Легко создавайте эффективные рабочие графики для вашей команды с помощью удобных инструментов планирования.',
                    'Настраивайте смены в соответствии с операционными требованиями вашего бизнеса и доступностью сотрудников.',
                    'Обеспечьте справедливое распределение смен, учитывая предпочтения сотрудников и объем работы.',
                ],
            },
            {
                title: 'Поощряйте сотрудников надбавками за работу в неурочные часы',
                description: [
                    'Интегрируйте системы управления сменами с процессами расчета заработной платы, чтобы обеспечить точную компенсацию и вычеты на основе отработанных смен.',
                    'Определите процедуры для сверхурочных и убедитесь, что политики по сверхурочным соответствуют трудовому законодательству.',
                ],
                sDKeys: [
                    'Одобрение запроса сотрудника на смену одним кликом.',
                    'Сотрудники могут выбрать свою смену в начале недели/месяца и отправить запросы через свой портал по мере удобства.',
                    'Выгодное решение с выплатой надбавок за смены на основе почасовой ставки или политики компании.',
                ],
            },
        ],
    },
    geoFenceBasedManagement: {
        featureHeader: 'Присутствие на основе геозоны',
        feaatureSubcaption:
            'Максимизация точности и подотчетности, отслеживание присутствия сотрудников в заданных границах',
        featureDescription:
            'Технология на основе местоположения для мониторинга и проверки присутствия сотрудников в заранее определенных географических границах.',
        featureCaption:
            'Присутствие на основе геозоны использует точность вашего отслеживания присутствия, так как сотрудники отмечаются как присутствующие только тогда, когда они находятся в заданной зоне.',
        keyPoints: [
            {
                header: 'Точное определение местоположения сотрудников в заданной геозоне',
                paragraph:
                    'Точные записи о присутствии, чтобы убедиться, что сотрудники находятся в своих назначенных рабочих местах.',
            },
            {
                header: 'Мониторинг местоположения сотрудников в реальном времени',
                paragraph:
                    'Реализуйте отслеживание в реальном времени для мониторинга местоположения сотрудников внутри геозоны.',
            },
            {
                header: 'Определение конкретных географических границ для входа или выхода',
                paragraph:
                    'Установите конкретные географические границы, известные как геозоны, вокруг рабочего места или назначенных мест присутствия.',
            },
            {
                header: 'Оповещения геозоны',
                paragraph:
                    'Настройте оповещения или уведомления, чтобы информировать менеджеров или HR-персонал, когда сотрудники входят в или покидают определенные границы геозоны.',
            },
        ],
        softwareDetails: [
            {
                title: 'Ограничение местоположения для отметки присутствия',
                description: [
                    'Установите конкретные географические границы с помощью GPS-координат или технологий на основе местоположения. Эти границы представляют собой зоны, где сотрудники должны находиться для работы.',
                ],
                sDKeys: [
                    'Дополнительная функция ограничения пользователей, позволяющая отмечать присутствие с конкретного IP-адреса для обеспечения присутствия в офисе.',
                    'Удобное общение с конкретными или всеми пользователями с помощью функции Push Notifications.',
                    'Связывайте задачи с присутствующими сотрудниками для просмотра ежедневных задач и графиков.',
                ],
            },
            {
                title: 'Установите ограничения на присутствие и биометрическую аутентификацию',
                description: [
                    'Реализация отслеживания местоположения в реальном времени для мониторинга местоположения сотрудников в пределах геозон. Эта информация используется для подтверждения присутствия и обеспечения того, чтобы сотрудники находились на назначенных рабочих местах в течение установленного времени.',
                ],
                sDKeys: [
                    'Сотрудники могут отмечать свое присутствие только после того, как они достигнут первого места задачи.',
                    'Сотрудники могут отмечать свое присутствие удаленно; больше не нужно неверное присутствие или ежедневные поездки в офис только для отметки.',
                    'Менеджеры могут отслеживать местоположение в реальном времени и получать обновления удаленно через мобильные приложения или веб.',
                ],
            },
            {
                title: 'Увеличьте безопасность и авторизацию',
                description: [
                    'Реализуйте меры безопасной аутентификации для предотвращения мошеннических записей о присутствии, используя PIN-коды, биометрическую аутентификацию и распознавание лиц.',
                    'Настройте мобильные уведомления или оповещения для сотрудников, напоминая им проверить присутствие при входе в геозону. Это гарантирует, что сотрудники осведомлены о системе отслеживания присутствия.',
                ],
            },
        ],
    },
    multipleClockInOptions: {
        featureHeader: 'Множественные варианты входа в систему',
        feaatureSubcaption:
            'Предоставляя гибкость и удобство, множественные варианты входа в систему позволяют сотрудникам эффективно управлять своим временем.',
        featureDescription:
            'Предоставьте сотрудникам гибкость для записи их рабочего времени с использованием биометрических сканеров, мобильных приложений, временных часов и веб-платформ.',
        featureCaption:
            'Обеспечьте удобство для сотрудников, работающих удаленно, которые могут отмечать время с разными сеансами.',
        keyPoints: [
            {
                header: 'Подходит для организаций с несколькими локациями',
                paragraph:
                    'Гибкие варианты входа для управления посещаемостью на местах и вне офиса различных филиалов.',
            },
            {
                header: 'Портал самообслуживания сотрудников',
                paragraph:
                    'Предоставьте сотрудникам доступ к порталу самообслуживания для записи посещаемости и управления их входами и выходами в пределах геозоны.',
            },
            {
                header: 'Множественные варианты входа',
                paragraph:
                    'Гибкие варианты входа включают биометрическое сканирование, мобильные приложения, RFID-карты и распознавание лиц, обеспечивая бесшовное отслеживание времени в различных рабочих условиях.',
            },
            {
                header: 'Точный гео-базированный вход',
                paragraph:
                    'Автоматическая отметка посещаемости для сотрудников в пределах офисных помещений с уведомлением.',
            },
        ],
        softwareDetails: [
            {
                title: 'Вход на месте и вне места',
                description: [
                    'Наше решение для управления посещаемостью сотрудников предлагает несколько вариантов входа, адаптированных к различным рабочим условиям.',
                    'Будь то сотрудники на месте, в поле или работающие удаленно, они могут удобно записывать свое время через биометрическое сканирование, распознавание лиц и автоматический вход на основе геозоны.',
                ],
            },
            {
                title: 'Портал самообслуживания сотрудников для проверки своих записей',
                description: [
                    'Портал самообслуживания сотрудников предоставляет контроль над множественными входами и выходами.',
                    'С удобными функциями сотрудники могут легко управлять своим рабочим временем, будь то переход между сменами, локациями или задачами.',
                    'Это дает сотрудникам гибкость при поддержании точных записей времени, повышая эффективность и упрощая административные процессы.',
                ],
            },
            {
                title: 'Множественные входы для нескольких филиалов',
                description: [
                    'Учитывайте динамические потребности вашей компании, охватывающей несколько локаций, и обеспечьте точное отслеживание посещаемости и операционную эффективность.',
                ],
            },
        ],
    },
    attendanceReport: {
        featureHeader: 'Отчеты о посещаемости',
        feaatureSubcaption:
            'Проникновенное отражение приверженности команды и пунктуальности, зафиксированное в одном отчете.',
        featureDescription:
            'Сводка данных о посещаемости сотрудников, рабочем времени, отсутствиях, опозданиях и ранних уходах в течение определенного времени.',
        featureCaption:
            'Создавайте автоматические отчеты для различных филиалов и получайте их ежедневно, еженедельно, ежемесячно с более чем 40 шаблонами.',
        keyPoints: [
            {
                header: 'Автоматизация деталей посещаемости: время входа, время опоздания, общее рабочее время, общее количество сеансов',
                paragraph:
                    'Предоставляет общую сводку посещаемости, включая общее количество рабочих дней, общее количество отработанных часов и любые часы сверхурочной работы.',
            },
            {
                header: 'Отчеты по нескольким филиалам',
                paragraph:
                    'Удаленный доступ к отчетам о посещаемости нескольких филиалов с настраиваемыми категориями.',
            },
            {
                header: 'Часы сверхурочной работы и балансы отпусков',
                paragraph:
                    'Отображает балансы отпусков для каждого сотрудника, включая дни отпуска, больничные дни и любые другие виды отпусков, накопленные и использованные.',
            },
            {
                header: 'Настраиваемый отчет о посещаемости',
                paragraph:
                    'Позволяет пользователям настраивать отчеты на основе конкретных критериев, таких как отдел, команда или отдельный сотрудник.',
            },
        ],
        softwareDetails: [
            {
                title: 'Создавайте отчеты на основе структуры вашей организации.',
                description: [
                    'Регулярно создаваемые и анализируемые отчеты о посещаемости могут предоставить ценные инсайты для улучшения управления персоналом, выявления трендов и обеспечения соблюдения организационных политик.',
                    'Специфика отчета будет зависеть от потребностей и структуры организации.',
                ],
                sDKeys: [
                    'Информация, собранная с различных пользовательских терминалов, сводится в автоматическую аналитику, что снижает время и ресурсы, затрачиваемые на ручное создание отчетов.',
                    'Пользователи могут выбрать необходимые аналитические данные за несколько кликов и подписаться на них в любое время.',
                    'Пример отчета аналитики можно скачать и просмотреть как реальное представление желаемого отчета.',
                ],
            },
            {
                title: 'Легко получайте настраиваемые отчеты.',
                description: [
                    'Выявляйте и подчеркивайте любые исключения или нарушения в посещаемости, такие как несанкционированные отсутствия, чрезмерные опоздания или случаи неутвержденного сверхурочного времени.',
                    'Используйте диаграммы или графики для визуального представления трендов и паттернов посещаемости. Это может облегчить выявление аномалий и трендов на первый взгляд.',
                ],
                sDKeys: [
                    'Пользователи могут извлекать выгоду из данных, предоставленных различными способами, такими как обработка заработной платы, оценка производительности, возмещение и т.д.',
                    'Отчеты могут быть переданы другим пользователям в зависимости от иерархического уровня полномочий.',
                    'Компания может коллективно отслеживать рабочий статус и производительность своей команды наиболее удобным способом без ручного вмешательства.',
                ],
            },
            {
                title: 'Сократите время, затрачиваемое на ручные отчеты.',
                description: [
                    'Включает основные детали, такие как имена сотрудников, идентификационные номера и принадлежность к отделам, чтобы предоставить контекст к данным о посещаемости.',
                    'Четко указывайте диапазон дат и времени, охватываемый отчетом. Это помогает пользователям понять временные рамки данных.',
                    'Предоставляет общую сводку посещаемости, включая общее количество рабочих дней, общее количество отработанных часов и любые часы сверхурочной работы.',
                ],
            },
        ],
    },
    workforceTracking: {
        featureHeader: 'Отслеживание Рабочей Силы',
        featureDescription:
            'Систематическое отслеживание и управление деятельностью сотрудников, производительностью и посещаемостью.',
        featureParagraph:
            'Больше никаких карточек времени, чрезмерной бумажной работы и различных документов для проверки посещаемости сотрудников. Автоматизируйте процесс мониторинга посещаемости с помощью WebWork Time Tracker и отслеживайте начало и окончание рабочего времени ваших сотрудников в хорошо структурированном формате.',
        featureCaption:
            'Мониторинг и управление различными аспектами рабочей силы организации, включая посещаемость сотрудников, рабочие часы, производительность и эффективность.',
        keyPoints: [
            {
                header: 'Отслеживание посещаемости и передвижений на основе временной шкалы',
                paragraph:
                    'Мониторинг таких событий, как вход и выход, а также представление сотрудниками информации о своей деятельности, показателях производительности или событиях.',
            },
            {
                header: 'Настраиваемые формы для управления данными',
                paragraph:
                    'Для протоколов собраний храните данные о встречах и задачах для отслеживания трендов и принятия решений на основе данных.',
            },
            {
                header: 'Управление клиентами и лидами',
                paragraph:
                    'Отслеживайте производительность и измеряйте ключевые показатели эффективности на основе объема выполненной работы сотрудниками.',
            },
            {
                header: 'Общий расчет расстояний для точных заявок на возмещение',
                paragraph:
                    'Отслеживайте точное расстояние, пройденное сотрудниками в полевых условиях, на основе дневных, недельных и месячных метрик, и компенсируйте расходы в зависимости от расстояния и мест, которые они посетили.',
            },
        ],
        softwareDetails: [
            {
                title: 'Реализуйте системы отслеживания времени для мониторинга рабочего времени.',
                description: [
                    'Внедрение решений для отслеживания времени, затраченного сотрудниками на задачи и проекты.',
                    'Это поможет понять использование ресурсов и улучшить управление временем.',
                    'Используйте системы управления проектами для отслеживания хода проектов, назначения задач и контроля сроков.',
                    'Это помогает оптимизировать рабочие процессы проектов и обеспечить своевременное завершение.',
                ],
            },
            {
                title: 'Отслеживайте соответствующие ключевые показатели эффективности.',
                description: [
                    'Определение и отслеживание ключевых показателей эффективности, соответствующих целям вашей организации.',
                    'Это может включать целевые показатели продаж, метрики удовлетворенности клиентов или другие показатели эффективности.',
                ],
                sDKeys: [
                    'Производительность продаж: месячный/квартальный доход от продаж, количество закрытых сделок, коэффициент конверсии продаж, средний размер сделки, стоимость воронки продаж.',
                    'Обслуживание клиентов: балл удовлетворенности клиентов (CSAT), индекс потребительской лояльности (NPS), время первого ответа, среднее время разрешения, коэффициент удержания клиентов, объем заявок.',
                    'Операции/Производство: объем производства/производительность, качество (уровень дефектов, уровень ошибок), производительность по срокам доставки, коэффициент оборачиваемости запасов, простой оборудования, коэффициент использования сотрудников.',
                    'Общее: производительность сотрудников (выход на час), посещаемость и пунктуальность, уровень достижения целей, отзывы сотрудников и оценки производительности.',
                ],
            },
            {
                title: 'Создавайте отчеты о тенденциях рабочей силы.',
                description: [
                    'Интеграция аналитики и функций отчетности для получения информации о тенденциях рабочей силы, производительности и областях для улучшения.',
                    'Настраиваемые отчеты могут способствовать принятию решений.',
                ],
                sDKeys: [
                    'Сравнение демографического состава с общей рабочей силой или отраслевым эталоном и выявление любых демографических трендов или изменений со временем.',
                    'Сравнение показателей производительности между различными полевыми командами, регионами или периодами времени.',
                    'Геопространственное отображение деятельности полевых сотрудников для визуализации распределения и охвата.',
                    'Анализ показателей продаж или обслуживания по географическим регионам или территориям. Выявление высокопотенциальных областей для расширения или оптимизации.',
                ],
            },
        ],
    },
    fieldSalesAnalytics: {
        featureHeader: 'Аналитика Полевых Продаж',
        featureDescription:
            'Отслеживайте и анализируйте ключевые метрики команды продаж в поле, такие как доход от продаж, взаимодействие с клиентами и географические тренды.',
        featureParagraph:
            'Больше никаких карточек времени, чрезмерной бумажной работы и различных документов для проверки посещаемости сотрудников. Автоматизируйте процесс мониторинга посещаемости с помощью WebWork Time Tracker и отслеживайте начало и окончание рабочего времени ваших сотрудников в хорошо структурированном формате.',
        featureCaption:
            'Анализ сбора, обработки и интерпретации данных, связанных с полевыми продажами, для получения инсайтов и принятия обоснованных решений.',
        keyPoints: [
            {
                header: 'Ключевые показатели эффективности продаж и лидов',
                paragraph:
                    'Ключевые показатели эффективности могут помочь в принятии решений на основе данных, что может способствовать продуктивности и изменению стратегии.',
            },
            {
                header: 'Настраиваемые отчеты',
                paragraph:
                    'Отчеты могут быть получены на ежедневной, недельной или месячной основе по продажам, лидам и привлечению клиентов. Это может помочь в повышении продуктивности и эффективности сотрудников.',
            },
            {
                header: 'Подтверждение визитов с помощью Геозон',
                paragraph:
                    'Подтверждайте визиты с помощью геозон для обеспечения точности. Координаты местоположения могут быть прикреплены к фотографии, сделанной камерой, при отправке отчета.',
            },
            {
                header: 'Оценка эффективности маршрутов',
                paragraph:
                    'Маршрут можно использовать для планирования визитов или маршрутов сотрудников к клиентам. Интегрируйте свой план маршрута в карты для оптимизации навигации.',
            },
        ],
        softwareDetails: [
            {
                title: 'Ежедневные полевые инсайты для улучшения производительности продаж.',
                description: [
                    'Анализ данных и генерация отчетов в режиме реального времени. Надежные и удобные в использовании отчеты для легкого доступа.',
                    'Вы, как менеджер по продажам, получите четкое представление о различных отчетах, которые будут отправлены на вашу электронную почту или будут доступны в вашем информационном панели.',
                    'Некоторые из заметных отчетов: месячные отчеты, отчеты по местоположению филиалов, отчеты по конкретным сотрудникам и т.д.',
                ],
            },
            {
                title: 'Общий обзор посещаемости рабочего поля.',
                description: [
                    'Общий обзор посещаемости сотрудников в поле и полевых визитов.',
                    'Полный обзор посещаемости рабочей силы в поле и встреч.',
                    'В отчете о посещаемости поля вы получите данные о времени маркировки посещаемости в поле, местоположении, времени опоздания, рабочем времени и т.д.',
                    'Таким же образом предоставляем отчеты о входе/выходе из поля. Эти отчеты содержат данные о полевых визитах, таких как время входа/выхода, время входа/выхода, местоположение встреч, расстояние, пройденное, заметки о результатах встреч и т.д.',
                ],
            },
            {
                title: 'Генерация отчетов на основе аналитики данных.',
                description: [
                    'Подписка и загрузка ежемесячных электронных отчетов.',
                    'Вы можете подписаться и загрузить ежемесячные отчеты в любое время.',
                    'Доступно в формате PDF и Excel. Полный набор отчетов для получения детальной информации о действиях сотрудников вне организации, что в ручном режиме было бы сложной задачей.',
                ],
            },
        ],
    },
    locationBasedAttendance: {
        featureHeader: 'Посещаемость на основе местоположения',
        featureDescription:
            'Посещаемость на основе геозон для обеспечения того, чтобы сотрудники могли зарегистрироваться или выйти только когда они находятся в пределах предопределенных географических границ.',
        featureCaption:
            'Посещаемость на основе местоположения обеспечивает точность мониторинга и записи посещаемости сотрудников на основе их физического местоположения.',
        keyPoints: [
            {
                header: 'Геозоны',
                paragraph:
                    'Реализуйте виртуальные границы вокруг конкретных местоположений офисных помещений. Это поможет автоматизировать посещаемость на основе того, что сотрудник входит или покидает назначенную зону.',
            },
            {
                header: 'Аутентификация и безопасность',
                paragraph:
                    'Методы аутентификации для обеспечения точности и безопасности данных посещаемости с использованием PIN-кодов, биометрии или распознавания лиц.',
            },
            {
                header: 'Обновления в реальном времени',
                paragraph:
                    'Обеспечьте оперативное обновление записей посещаемости, что позволяет своевременно принимать управленческие решения.',
            },
            {
                header: 'Учет удаленной работы',
                paragraph: 'Рассмотрите альтернативные методы для отслеживания посещаемости удаленных сотрудников.',
            },
        ],
        softwareDetails: [
            {
                title: 'Время и местоположение рабочей силы.',
                description: [
                    'Знайте, когда и где ваши сотрудники работают.',
                    'Попросите сотрудников предоставить разрешение на их мобильных телефонах, чтобы можно было отслеживать время и местоположение.',
                    'После предоставления разрешения трекер может начать функционировать одним касанием.',
                ],
            },
            {
                title: 'Живые отчеты',
                description: [
                    'Внедрение системы учета посещаемости на основе местоположения может повысить точность и упростить управление посещаемостью. Однако важно сбалансировать преимущества с учетом конфиденциальности сотрудников и открыто сообщать о внедрении и использовании таких систем.',
                ],
            },
            {
                title: 'Оповещения на основе геозон',
                description: [
                    'Настройте автоматические оповещения о необычных паттернах посещаемости или несоответствиях. Это поможет выявить потенциальные проблемы, такие как несанкционированные входы или выходы.',
                    'Внедрите функции отчетности и аналитики для получения инсайтов о паттернах посещаемости, трендах и исключениях.',
                ],
                sDKeys: [
                    'Получайте оповещения, когда ценные активы, такие как автомобили или оборудование, входят или покидают определенные зоны, что помогает предотвратить кражу или несанкционированное использование.',
                    'Обеспечьте безопасность сотрудников в поле, установив геозоны вокруг опасных зон или удаленных рабочих мест, срабатывающие оповещения, если сотрудники входят в ограниченные зоны.',
                    'Когда устройство входит или выходит из геозоны, система генерирует предопределенные оповещения или уведомления.',
                    'Система непрерывно отслеживает местоположение отслеживаемых устройств в реальном времени и проверяет, пересекаются ли они с какими-либо определенными геозонами.',
                ],
            },
        ],
    },
    liveReporting: {
        featureHeader: 'Живое Reporting',
        featureDescription:
            'Отслеживание и анализ различных аспектов работы в реальном времени, таких как посещаемость, выполненные задачи или прогресс проектов.',
        featureCaption:
            'Будьте в курсе задач сотрудников, протоколов встреч, проверяйте визиты и принимайте решения на основе данных.',
        keyPoints: [
            {
                header: 'Оповещения на основе событий',
                paragraph:
                    'Администратор или менеджер могут подписаться или выбрать уведомления, которые они хотят получать. Это помогает сосредоточиться на уведомлениях, которые действительно важны.',
            },
            {
                header: 'Оповещения о посещаемости и отпусках',
                paragraph:
                    'Будьте на шаг впереди и знайте численность персонала, чтобы планирование дня было более продуктивным и эффективным.',
            },
            {
                header: 'Отслеживание всех одобрений',
                paragraph: 'Отслеживайте все одобрения, от возмещения расходов до отпусков, и просматривайте их позже.',
            },
            {
                header: 'Будьте в курсе форм и протоколов встреч',
                paragraph:
                    'Пользовательские формы помогут получить конкретные данные для встречи, которые можно будет получить удаленно.',
            },
        ],
        softwareDetails: [
            {
                title: 'Реальное местоположение полевого персонала.',
                description: [
                    'Локализуйте ваш полевой персонал в реальном времени.',
                    'Получайте мгновенные данные о местоположении вашего полевого персонала по мере их перемещения в реальном времени.',
                    'Treant позволяет вам отслеживать и находить ваших полевых сотрудников с помощью их мобильного GPS как онлайн, так и офлайн. Будьте в курсе каждого шага вашего полевого персонала и поддерживайте прозрачную рабочую среду.',
                ],
            },
            {
                title: 'Будьте в курсе информации о полевых сотрудниках.',
                description: [
                    'Будьте в курсе информации о полевых сотрудниках.',
                    'Информация о полевых сотрудниках будет автоматически синхронизироваться с вашей панелью управления.',
                    'Такие данные, как уровень заряда батареи, GPS, состояние интернета, идеальное время, проведенное на поле, будут уведомлены вам мгновенно в реальном времени.',
                ],
            },
            {
                title: 'Автоматизация данных о возмещении расходов и пособиях сотрудников.',
                description: [
                    'Данные о местоположении сотрудников, основанные на искусственном интеллекте.',
                    'Данные о местоположении ваших сотрудников будут обработаны искусственным интеллектом для предоставления точной информации о маршруте и отслеживании. Данные о живом отслеживании и другой истории местоположения будут автоматически отправляться на вашу почту через регулярные интервалы для более глубокого анализа происходящего на поле и вне его.',
                ],
            },
        ],
    },
    aIBasedOptimization: {
        featureHeader: 'Оптимизация на основе ИИ',
        featureDescription:
            'Оптимизация и автоматизация планирования маршрутов для полевых представителей или торгового персонала на основе ИИ алгоритмов маршрутов.',
        featureCaption:
            'Оптимизируйте маршруты на день и сравните отчеты с предыдущими периодами с помощью нашего алгоритма на основе ИИ.',
        keyPoints: [
            {
                header: 'Интеграция данных в реальном времени',
                paragraph:
                    'Используйте источники данных в реальном времени, такие как обновления о дорожном движении, погодные условия и дорожные закрытия, чтобы динамически корректировать маршруты в зависимости от текущих обстоятельств, что помогает в эффективном планировании дня.',
            },
            {
                header: 'Интерфейс для планирования маршрутов',
                paragraph:
                    'Просматривайте оптимизированные маршруты и вносите необходимые корректировки. Это может быть частью мобильного приложения или веб-платформы.',
            },
            {
                header: 'Метрики производительности',
                paragraph: 'Система ИИ учитывает уникальные характеристики различных маршрутов или участков.',
            },
            {
                header: 'Картографические и геопространственные данные',
                paragraph:
                    'Используйте картографические сервисы и геопространственные данные для понимания дорожной сети, условий дорожного движения и другой информации о местоположении.',
            },
        ],
        softwareDetails: [
            {
                title: 'Оптимизация маршрутов с обновлениями в реальном времени',
                description: [
                    'Оптимизируйте и планируйте наиболее эффективные маршруты для индивидуумов или транспортных средств, таких как грузовики для доставки, торговые представители или техники по обслуживанию на поле, которые следуют определенным маршрутам в рамках своей регулярной деятельности.',
                    'Это приложение может помочь улучшить эффективность, сократить время в пути и минимизировать операционные затраты.',
                ],
                sDKeys: [
                    'Сбор данных из различных источников, включая GPS-устройства, отчеты о движении, обновления погоды и датчики транспортных средств.',
                    'Быстрое реагирование и своевременные прибытия повышают удовлетворенность клиентов, что крайне важно для ориентированных на услуги компаний.',
                ],
            },
            {
                title: 'Необходимость тщательного рассмотрения вопросов конфиденциальности и соблюдения норм',
                description: [
                    'Убедитесь, что система на основе ИИ соответствует местным нормативам, особенно в отношении конфиденциальности, безопасности данных и транспортных законов.',
                    'Регулярно обновляйте и улучшайте алгоритмы ИИ на основе новых данных и отзывов, стремясь к постоянной оптимизации маршрутов.',
                ],
            },
            {
                title: 'Интеграция с другими системами для повышения эффективности планирования маршрутов',
                description: [
                    'Используйте алгоритмы машинного обучения для анализа исторических данных и прогнозирования будущих тенденций.',
                    'Это может помочь в предсказании условий дорожного движения, времени доставки и других факторов, влияющих на планирование маршрутов.',
                ],
            },
        ],
    },
    leadManagement: {
        featureHeader: 'Управление лидами',
        featureDescription:
            'Подведение итогов данных о посещаемости сотрудников, рабочем времени, отсутствиях, опозданиях и ранних выходах в определенный период времени.',
        featureCaption:
            'Создавайте автоматизированные отчеты для различных филиалов и получайте их ежедневно, еженедельно или ежемесячно с более чем 40 шаблонами.',
        keyPoints: [
            {
                header: 'Автоматизация данных о посещаемости: время входа, время опоздания, общее рабочее время, общее количество сеансов',
                paragraph:
                    'Предоставьте общий обзор посещаемости, включая общее количество рабочих дней, общее количество отработанных часов и любые часы сверхурочной работы.',
            },
            {
                header: 'Отчеты по нескольким филиалам',
                paragraph:
                    'Удаленный доступ к отчетам о посещаемости нескольких филиалов с настраиваемыми категориями.',
            },
            {
                header: 'Часы сверхурочной работы и балансы отпусков',
                paragraph:
                    'Отображайте балансы отпусков для каждого сотрудника, включая дни отпуска, больничные дни и любые другие виды отпуска, которые были накоплены и использованы.',
            },
            {
                header: 'Индивидуализированные отчеты о посещаемости',
                paragraph:
                    'Позвольте пользователям настраивать отчеты на основе конкретных критериев, таких как отдел, команда или отдельный сотрудник.',
            },
        ],
        softwareDetails: [
            {
                title: 'Создание отчетов на основе структуры вашей организации.',
                description: [
                    'Регулярно создаваемые и анализируемые отчеты о посещаемости могут предоставить ценную информацию для улучшения управления персоналом, выявления тенденций и обеспечения соблюдения организационных политик.',
                    'Специфика отчета будет зависеть от потребностей и структуры организации.',
                ],
            },
            {
                title: 'Легкий доступ к индивидуализированным отчетам.',
                description: [
                    'Выявляйте и подчеркивайте любые исключения или несоответствия в посещаемости, такие как несанкционированные отсутствия, чрезмерные опоздания или случаи неоплаченных сверхурочных.',
                    'Используйте диаграммы или графики для визуального представления тенденций и паттернов посещаемости. Это поможет легче выявить выбросы и тенденции на первый взгляд.',
                ],
            },
            {
                title: 'Сократите время, затрачиваемое на ручные отчеты.',
                description: [
                    'Включает основные детали, такие как имена сотрудников, идентификационные номера и принадлежность к отделу, чтобы предоставить контекст данным о посещаемости.',
                    'Четко указывает дату и временной диапазон, охватываемый отчетом. Это помогает пользователям понять временные рамки данных.',
                    'Предоставляет общий обзор посещаемости, включая общее количество рабочих дней, общее количество отработанных часов и любые часы сверхурочной работы.',
                ],
            },
        ],
    },
    taskManagement: {
        featureHeader: 'Управление задачами',
        featureDescription:
            'Систематический мониторинг и управление деятельностью сотрудников, продуктивностью и посещаемостью.',
        featureParagraph:
            'Больше никакой необходимости в карточках времени, чрезмерной документации и различных документах для проверки посещаемости сотрудников. Автоматизируйте процесс мониторинга посещаемости с помощью WebWork Time Tracker и увидите начало и окончание рабочего времени ваших сотрудников в структурированном формате.',
        featureCaption:
            'Контролируйте и управляйте различными аспектами рабочей силы организации, включая посещаемость сотрудников, рабочие часы, продуктивность и производительность.',
        keyPoints: [
            {
                header: 'Мониторинг в реальном времени',
                paragraph:
                    'Контролируйте процесс выполнения задач в реальном времени, просматривая доски задач, проверяя статус задач и отслеживая затраченное время.',
            },
            {
                header: 'Мониторинг всех рабочих процессов в одном месте для всей организации',
                paragraph:
                    'Функции совместной работы по управлению задачами для общения с членами команды в реальном времени. Комментируйте задачи, задавайте вопросы и предоставляйте обновления, чтобы все были в курсе.',
            },
            {
                header: 'Мониторинг производительности',
                paragraph:
                    'Управление клиентами и лидами для отслеживания продуктивности. Продуктивность сотрудников и ключевые показатели эффективности могут быть измерены на основе объема выполненной работы.',
            },
            {
                header: 'Стандартизация постановки целей',
                paragraph:
                    'Иерархическая структура для организации целей: цели проектов, departmental objectives, индивидуальные цели производительности, стратегические инициативы и т. д.',
            },
        ],
        softwareDetails: [
            {
                title: 'Реализуйте системы учета времени для мониторинга рабочего времени.',
                description: [
                    'Реализация решений по отслеживанию времени для мониторинга времени, которое сотрудники тратят на задачи и проекты.',
                    'Это поможет понять использование ресурсов и улучшить управление временем.',
                    'Используйте системы управления проектами для отслеживания хода выполнения проектов, назначения задач и мониторинга сроков.',
                    'Это помогает оптимизировать рабочие процессы проектов и обеспечить своевременное завершение.',
                ],
            },
            {
                title: 'Мониторинг соответствующих ключевых показателей эффективности.',
                description: [
                    'Определение и отслеживание ключевых показателей эффективности, соответствующих целям вашей организации.',
                    'Это могут быть целевые показатели продаж, метрики удовлетворенности клиентов или другие показатели эффективности.',
                ],
            },
            {
                title: 'Создание отчетов о тенденциях рабочей силы.',
                description: [
                    'Интеграция аналитики и функций отчетности для получения информации о тенденциях рабочей силы, производительности и областях для улучшения.',
                    'Индивидуализированные отчеты могут помочь в принятии решений.',
                ],
            },
        ],
    },
    salesPipelineMonitoring: {
        featureHeader: 'Мониторинг воронки продаж',
        featureDescription:
            'Отслеживайте и анализируйте ключевые метрики работы продаж на поле, такие как доходы от продаж, взаимодействие с клиентами и географические тенденции.',
        featureParagraph:
            'Больше никакой необходимости в карточках времени, чрезмерной документации и различных документах для проверки посещаемости сотрудников. Автоматизируйте процесс мониторинга посещаемости с помощью WebWork Time Tracker и увидите начало и окончание рабочего времени ваших сотрудников в структурированном формате.',
        featureCaption:
            'Анализируйте сбор, анализ и интерпретацию данных, связанных с деятельностью полевых продаж, чтобы получить инсайты и принимать обоснованные решения.',
        keyPoints: [
            {
                header: 'Автоматизированные рабочие процессы',
                paragraph:
                    'Используйте систему для упрощения и оптимизации различных этапов процесса продаж, таких как захват лидов, квалификация, развитие, управление воронкой, отслеживание активности и отчетность.',
            },
            {
                header: 'Интеграция с CRM',
                paragraph:
                    'Упростите управление лидами, отслеживание контактов, мониторинг сделок и синхронизацию данных между двумя платформами.',
            },
            {
                header: 'Обеспечение сотрудничества и коммуникации',
                paragraph:
                    'Централизованные каналы связи, планирование встреч по продажам, совместные обзоры сделок, документирование процессов, межфункциональное сотрудничество, содействие открытому общению.',
            },
            {
                header: 'Инсайты в будущее выполнение продаж',
                paragraph:
                    'Используйте анализ данных и предсказательное моделирование для прогнозирования потенциальных результатов и тенденций.',
            },
        ],
        softwareDetails: [
            {
                title: 'Ежедневные полевые инсайты для лучшей производительности продаж',
                description: [
                    'Аналитика данных и генерация отчетов в реальном времени. Надежная и удобная функция отчетов для легкого доступа и использования.',
                    'Как менеджер по продажам, вы будете иметь четкое представление о различных отчетах, которые будут отправляться на вашу электронную почту или доступны на вашей панели инструментов.',
                    'Некоторые из заметных отчетов: ежемесячные отчеты, отчеты по филиалам, отчеты по сотрудникам и т. д.',
                ],
            },
            {
                title: 'Общий обзор посещаемости на поле',
                description: [
                    'Общий обзор посещаемости сотрудников на поле и полевых визитов.',
                    'Имеет полный обзор посещаемости рабочей силы на поле и встреч.',
                    'В отчете по посещаемости на поле вы получите данные о времени отметки посещаемости на поле, местоположении, времени опоздания, рабочих часах и т. д.',
                    'Аналогично, отчеты по проверке посещений на поле включают данные, связанные с полевыми визитами, такими как время входа/выхода, местоположение встреч, расстояние, время в пути, заметки о результатах встреч и т. д.',
                ],
            },
            {
                title: 'Генерация отчетов, ориентированных на аналитику данных',
                description: [
                    'Подпишитесь и скачайте ежемесячные электронные отчеты.',
                    'Вы можете подписаться и скачать ежемесячные отчеты в любое время.',
                    'Доступны в формате PDF и Excel. Полный набор отчетов для подробной информации о внешних действиях сотрудника за пределами организации, что сложно сделать вручную.',
                ],
            },
        ],
    },
    workflowAutomation: {
        featureHeader: 'Автоматизация рабочих процессов',
        featureDescription:
            'Посещение по геозональным границам для обеспечения того, чтобы сотрудники могли отмечать вход или выход только когда они находятся в пределах предопределенных географических границ.',
        featureCaption:
            'Посещение по местоположению обеспечивает точность для мониторинга и записи посещаемости сотрудников на основе их физического местоположения.',
        keyPoints: [
            {
                header: 'Автоматическое назначение задач',
                paragraph:
                    'Автоматизация рабочих процессов для автоматического назначения задач наиболее подходящим лицам, учитывая такие факторы, как навыки, доступность, нагрузка и уровни приоритетов.',
            },
            {
                header: 'Маршрутизация, квалификация и оценка лидов',
                paragraph:
                    'Маршрутизация лидов, их квалификация и оценка для назначения входящих лидов соответствующим торговым представителям, оценка их качества и приоритизация последующих действий.',
            },
            {
                header: 'Интеграция с внешними системами',
                paragraph:
                    'Содействие более плавному взаимодействию, повышение точности данных и обеспечение согласованности рабочих процессов в различных бизнес-функциях.',
            },
            {
                header: 'Отслеживание производительности и отчетность',
                paragraph:
                    'Отслеживание таких метрик, как время завершения процессов, уровень ошибок, использование ресурсов и общая производительность.',
            },
        ],
        softwareDetails: [
            {
                title: 'Время и местоположение рабочей силы.',
                description: [
                    'Знайте, когда и где работают ваши сотрудники.',
                    'Попросите сотрудников разрешить отслеживание времени и местоположения на их мобильных телефонах.',
                    'Как только разрешение будет предоставлено, трекер сможет начать работу с помощью одного касания.',
                ],
            },
            {
                title: 'Живое Reporting Two',
                description: [
                    'Реализация системы посещаемости сотрудников на основе местоположения может повысить точность и упростить управление посещаемостью. Однако важно сбалансировать преимущества с учетом конфиденциальности сотрудников и открыто коммуницировать о внедрении и использовании таких систем.',
                ],
            },
            {
                title: 'Оповещение по геозоне',
                description: [
                    'Настройте автоматические оповещения для необычных шаблонов посещаемости или несоответствий. Это поможет в выявлении потенциальных проблем, таких как несанкционированные отметки входа или выхода.',
                    'Реализуйте функции отчетности и аналитики для получения инсайтов о шаблонах посещаемости, тенденциях и исключениях.',
                ],
            },
        ],
    },
    aIBasedAssistance: {
        featureHeader: 'Помощь на основе ИИ',
        featureDescription:
            'Отслеживание и анализ различных аспектов работы в реальном времени, таких как посещаемость, выполненные задачи или прогресс проектов.',
        featureCaption:
            'Будьте в курсе задач сотрудников, протоколов встреч, проверяйте посещения и принимайте решения на основе данных.',
        keyPoints: [
            {
                header: 'Оповещения на основе событий',
                paragraph:
                    'Администратор или менеджер могут подписаться на уведомления, которые они хотят получать. Это поможет сосредоточиться на уведомлениях, которые действительно важны.',
            },
            {
                header: 'Будьте уведомлены о посещаемости и отпусках',
                paragraph:
                    'Будьте уведомлены о посещаемости и отпусках. Останьтесь на шаг впереди и узнайте количество сотрудников, чтобы планирование дня было более продуктивным и эффективным.',
            },
            {
                header: 'Отслеживание всех одобрений',
                paragraph:
                    'Отслеживайте все одобрения. Отслеживайте все одобрения, от возмещений до отпусков, и просматривайте их позднее.',
            },
            {
                header: 'Будьте в курсе форм и протоколов встреч',
                paragraph:
                    'Удаленное отслеживание через формы и протоколы встреч. Пользовательские формы могут помочь в сборе конкретных данных для встречи, которые можно получить удаленно.',
            },
        ],
        softwareDetails: [
            {
                title: 'Определите местоположение полевых сотрудников в реальном времени.',
                description: [
                    'Определите местоположение вашего полевого персонала в реальном времени.',
                    'Получите мгновенные данные о местоположении ваших полевых сотрудников по мере их перемещения в реальном времени.',
                    'Treant позволяет отслеживать местоположение ваших полевых сотрудников с помощью их мобильного GPS как в онлайн, так и в офлайн-режимах. Оставайтесь в курсе каждого их движения и поддерживайте прозрачную рабочую среду.',
                ],
            },
            {
                title: 'Оставайтесь в курсе информации о ваших полевых сотрудниках.',
                description: [
                    'Оставайтесь в курсе информации о ваших полевых сотрудниках.',
                    'Информация о полевых сотрудниках будет автоматически синхронизирована с вашей панелью управления.',
                    'Данные о ваших сотрудниках, такие как заряд батареи, GPS, видимость включения/выключения интернета, время, проведенное на поле, будут немедленно уведомлены вам в реальном времени.',
                ],
            },
            {
                title: 'Автоматизируйте данные о возмещениях и надбавках сотрудников.',
                description: [
                    'История местоположений на основе ИИ для точных данных.',
                    'Данные о местоположении ваших сотрудников будут обработаны искусственным интеллектом для предоставления точной информации о маршрутах и отслеживании. Данные о живом отслеживании и другая история местоположений будут автоматически отправляться на ваш почтовый ящик с регулярными интервалами для более глубокого анализа происходящего на поле и вне его.',
                ],
            },
        ],
    },
    digitizationOfOrder: {
        featureHeader: 'Цифровизация заказов',
        featureDescription:
            'Отслеживание и анализ различных аспектов работы в реальном времени, таких как посещаемость, выполненные задачи или прогресс проектов.',
        featureCaption:
            'Оптимизируйте управление заказами от начала до конца и сократите количество ошибок в ручном вводе данных.',
        keyPoints: [
            {
                header: 'Отчеты и аналитика',
                paragraph:
                    'Создавайте детализированные отчеты и аналитику для мониторинга производительности обработки заказов и продуктивности сотрудников.',
            },
            {
                header: 'Автоматизированный ввод заказов',
                paragraph:
                    'Автоматически захватывайте и проверяйте детали заказов, введенные сотрудниками или клиентами.',
            },
            {
                header: 'Отслеживание заказов в реальном времени и обновления статуса',
                paragraph:
                    'Предоставляйте обновления о статусе заказов и отслеживании отправлений в реальном времени для сотрудников и клиентов.',
            },
            {
                header: 'Автоматизированные рабочие процессы и назначение задач',
                paragraph:
                    'Автоматизируйте процессы утверждения заказов и назначайте задачи соответствующим сотрудникам.',
            },
        ],
    },
    targetManagement: {
        featureHeader: 'Управление Целями',
        featureDescription:
            'Отслеживание и анализ различных аспектов работы в реальном времени, таких как посещаемость, выполненные задачи или прогресс проектов.',
        featureCaption:
            'Будьте в курсе задач сотрудников, минут встреч, проверяйте посещения и принимайте решения на основе данных.',
        keyPoints: [
            {
                header: 'Интеграция с отслеживанием на месте',
                paragraph:
                    'Достигайте ежедневных, недельных и месячных целей и сохраняйте записи о достижениях сотрудников с помощью отслеживания на месте в реальном времени.',
            },
            {
                header: 'Обновления и синхронизация в реальном времени',
                paragraph:
                    'Прогресс в реальном времени (достигнутые цели против оставшихся), время входа/выхода, количество часов (продолжительность), затраченных на каждую задачу, и отслеживание местоположения.',
            },
            {
                header: 'Производительность и аналитика',
                paragraph:
                    'Метрики производительности (коэффициент выполнения, время затраченное, оценки удовлетворенности клиентов), сравнение с целями (фактические результаты против целей), исторические данные (тренды прошлых показателей).',
            },
            {
                header: 'Автоматизированные уведомления и оповещения',
                paragraph:
                    'Отправляйте автоматизированные уведомления, чтобы держать сотрудников в курсе достижений целей, напоминаний о производительности и предупреждений о сроках.',
            },
        ],
    },
    invoiceManagement: {
        featureHeader: 'Управление Счетами',
        featureDescription:
            'Отслеживание и анализ различных аспектов работы в реальном времени, таких как посещаемость, выполненные задачи или прогресс проектов.',
        featureCaption:
            'Создавайте, отслеживайте статус счетов в реальном времени и обрабатывайте их с помощью автоматизированных оповещений и уведомлений.',
        keyPoints: [
            {
                header: 'Автоматизированные уведомления и напоминания',
                paragraph:
                    'Настройте системы для отслеживания статуса счетов и отправки автоматизированных напоминаний и оповещений о сроках и просроченных платежах.',
            },
            {
                header: 'Шаблоны счетов',
                paragraph:
                    'Стандартизированные шаблоны счетов, включающие все необходимые детали, такие как информация о клиенте, детали заказа, налоги, скидки и условия оплаты.',
            },
            {
                header: 'Цифровая подпись и подтверждение доставки',
                paragraph:
                    'Собирайте цифровые подписи и подтверждение доставки на основе OTP в процессе оформления счета.',
            },
            {
                header: 'Синхронизация счетов и обновления в реальном времени',
                paragraph:
                    'Автоматическая синхронизация с центральной системой (облачное хранилище) и обновления статуса счетов в реальном времени.',
            },
        ],
    },
    distributorOrder: {
        featureHeader: 'Заказы Дистрибьютора',
        featureDescription:
            'Отслеживание и анализ различных аспектов работы в реальном времени, таких как посещаемость, выполненные задачи или прогресс проектов.',
        featureCaption:
            'Будьте в курсе задач сотрудников, протоколов встреч, проверяйте визиты и принимайте решения на основе данных.',
        keyPoints: [
            {
                header: 'Информация о Распределении',
                paragraph:
                    'Создавайте и обрабатывайте заказы с учетом всех необходимых деталей для каждого распределения и назначайте задачи в зависимости от доступности.',
            },
            {
                header: 'Интеграция с Инвентаризацией в Реальном Времени',
                paragraph:
                    'Интегрируйтесь с системой управления запасами для обеспечения точного распределения stock и обновления уровней запасов в реальном времени.',
            },
            {
                header: 'Автоматизация Уведомлений и Оповещений',
                paragraph:
                    'Настройте систему для отправки автоматизированных уведомлений на ключевых этапах процесса заказа.',
            },
            {
                header: 'Панель Инструментов для Сотрудников',
                paragraph:
                    'Доступ к панели инструментов, где сотрудники могут проверять заказы, отслеживать прогресс и загружать необходимые документы.',
            },
        ],
    },
    inventoryManagement: {
        featureHeader: 'Управление Инвентарем',
        featureDescription:
            'Отслеживание и анализ различных аспектов работы в реальном времени, таких как посещаемость, выполненные задачи или прогресс проектов.',
        featureCaption:
            'Правильный баланс запасов для обновлений в реальном времени и отслеживания инвентаря для точных данных.',
        keyPoints: [
            {
                header: 'Интеграция с Управлением Заказами',
                paragraph:
                    'Обеспечьте бесперебойную интеграцию между системами управления запасами и заказами для синхронизации уровней запасов с входящими и исходящими заказами.',
            },
            {
                header: 'Управление Многочисленными Локациями',
                paragraph:
                    'Отслеживайте инвентарь в нескольких складах, магазинах или локациях и обеспечивайте централизованное управление, оптимизацию распределения запасов и улучшение видимости.',
            },
        ],
    },
    orderCollectionManagement: {
        featureHeader: 'Управление Заказами/Сбором',
        featureDescription:
            'Упростите операции с заказами/сбором с помощью масштабируемого и автоматизированного решения.',
        featureCaption: 'Упростите операции с заказами/сбором с помощью масштабируемого и автоматизированного решения.',
        keyPoints: [
            {
                header: 'Единая Система Управления Заказами',
                paragraph:
                    'Централизованная система, доступная как для сотрудников на месте, так и для удаленных сотрудников, для управления заказами, отслеживания прогресса и обновления статусов в реальном времени.',
            },
            {
                header: 'Контроль Доступа на Основе Ролей',
                paragraph:
                    'Разные уровни доступа и разрешений в зависимости от ролей (например, менеджеры, сотрудники на месте, удаленные сотрудники), чтобы обеспечить безопасность данных и соответствующий доступ.',
            },
            {
                header: 'Синхронизация в Реальном Времени',
                paragraph:
                    'Обеспечьте синхронизацию всех данных о заказах в реальном времени на всех устройствах и локациях, чтобы все имели доступ к самой актуальной информации.',
            },
            {
                header: 'Коммуникация и Координация',
                paragraph:
                    'Облегчите коммуникацию между сотрудниками на месте и удаленными сотрудниками с помощью встроенных сообщений и уведомлений.',
            },
        ],
    },
};

export default features;
