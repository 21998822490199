const features = {
    attendanceMonitoring: {
        featureSubcaption: 'દરેક ક્ષણમાં કાર્યક્ષમતા - કર્મચારી વ્યવસ્થાપન સિસ્ટમ્સ માટે નવીન ઉકેલો',
        featureHeader: 'હાજરી મોનીટરીંગ',
        featureDescription:
            'જીઓફેન્સ વિસ્તાર અથવા શાખામાં વ્યક્તિઓની હાજરી અથવા ગેરહાજરી વિશે રિયલ-ટાઇમ ઘડિયાળની અંદર/બહારની માહિતી',
        featureParagraph:
            'હાજરી ચકાસવા માટે હવે સમયપત્રકો, વધુ કાગળકામ અને વિવિધ દસ્તાવેજોની જરૂર નથી. WebWork Time Tracker નો ઉપયોગ કરીને હાજરી મોનીટરીંગ પ્રક્રિયા સ્વચાલિત કરો અને તમારા કર્મચારીઓના કામના કલાકોનો શરૂઆત અને અંત એક સારી રીતે સંરચિત ફોર્મેટમાં જુઓ',
        featureCaption:
            'તમારા મેદાનના કર્મચારીઓ અને ઓન-પ્રેમિસ કર્મચારીઓ માટે તમારી એકથી વધુ શાખાની હાજરીનું સંચાલન કરો',
        keyPoints: [
            {
                header: 'રિયલ ટાઇમ હાજરી',
                paragraph: 'જીઓફેન્સ વિસ્તાર અથવા શાખામાં વ્યક્તિઓની હાજરી અથવા ગેરહાજરી વિશે રિયલ-ટાઇમ માહિતી',
            },
            {
                header: 'કુલ કામના કલાકોની ગણતરી',
                paragraph: 'રોજિંદા, સાપ્તાહિક અને માસિક આધાર પર કામના કલાકોની મોનીટરીંગ કરો',
            },
            {
                header: 'દૂરસ્થ પ્રાપ્ય હાજરી રિપોર્ટ્સ',
                paragraph: 'મલ્ટિપલ બ્રાંચીસની હાજરી રિપોર્ટ્સને દૂરસ્થ રીતે ઍક્સેસ કરો',
            },
            {
                header: 'કેન્દ્રિત હાજરી સંચાલન',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, પ્રોસેસ અને સિસ્ટમ્સના સંકલનને એક જ કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં એકત્રિત કરો',
            },
        ],
        softwareDetails: [
            {
                title: 'કર્મચારી હાજરી ટ્રેકિંગ પર ઝલક મેળવો',
                description: [
                    'કર્મચારીઓની પંક્તિશીલતામાં પારદર્શકતા મેળવો',
                    'સમય બચાવો અને કોઈ પણ વધારાના ક્રિયાઓ વિના કર્મચારી હાજરીનું આયોજન કરો.',
                    'સહજ રીતે મોનીટર કરો કે તમારી કર્મચારીયોએ સમયસર કાર્યસ્થળ પર હાજરી આપી છે કે કેમ અને તેઓએ તેમના કામના કલાકો પૂર્ણ કર્યા છે કે નહીં.',
                    'તમારા કાર્યસ્થળના સંચાલનને વધુ સુવ્યવસ્થિત કરો અને વધુ જવાબદાર કામનું વાતાવરણ ઊભું કરો.',
                ],
                sDKeys: [
                    'જીઓફેન્સ પરિમિતિ સેટ કરીને ઑન-પ્રેમિસ સ્ટાફના આપમેળે લોગ ઇન અને લોગ આઉટ કરો.',
                    'કર્મચારીઓ કે જેઓ લોગ ઇન અથવા લોગ આઉટ કરવા ભૂલે છે કે નિષ્ફળ રહે છે તે માટે નોટિફિકેશન અલર્ટ્સ મેળવો.',
                    'તમારા મેદાનના સ્ટાફના પ્રવૃત્તિઓને રિયલ ટાઇમમાં મોનીટર અને ટ્રેક કરો, જે તેઓ હાજરી લોગ ઇન કરે તે પછી.',
                ],
            },
            {
                title: 'વિગતવાર હાજરી મોનીટરીંગ રિપોર્ટ્સ મેળવો',
                description: [
                    'હાજરી ડેટાનું બુદ્ધિશાળી વિશ્લેષણ',
                    'અગાઉ જવું, મોડું આવવું, અને ઓછું ટ્રેકિંગ જેવા ફિલ્ટર્સ પસંદ કરો, જે તમારે ડ્રોપ-ડાઉન સૂચિમાંથી પસંદ કરી શકો છો.',
                    'આ તમને તમારે જરૂરિયાત મુજબ માહિતી ઝડપથી જનરેટ કરવામાં મદદ કરશે.',
                    'તમે દૈનિક હાજરીને ટ્રેક કરી શકો છો અથવા કેલેન્ડરમાંથી અંતરાલ પસંદ કરી શકો છો.',
                ],
                sDKeys: [
                    'તમારા સંસ્થા માટે હાજરીના સારાંશ મેળવો.',
                    'તમે તમારી ટીમ, વિભાગો અથવા શાખાઓમાં વાયરલ ટ્રેન્ડ્સ જોવા માટે રિપોર્ટ્સ જનરેટ અને ઉપયોગ કરી શકો છો.',
                    'નિયમિત અંતરાલ પર તમારા મેલ બોક્સમાં રિપોર્ટ્સ મેળવો.(લોગ ઇન સમય, લોગ આઉટ સમય, કુલ કામના કલાકો, કુલ સત્રો)',
                    'ઉચ્ચ ગુણવત્તાવાળી નિર્ણય લેવાનું ડેટા મેળવો.',
                ],
            },
            {
                title: 'અન્ય સમય અને ઉત્પાદકતાના ફીચર્સ અન્વેષણ કરો',
                description: [
                    'કર્મચારી હાજરીને રિયલ-ટાઇમમાં મોનીટર અને ટ્રેક કરવાનો સુરક્ષિત અને પારદર્શક માર્ગ, 100% ટચલેસ સિસ્ટમ સાથે.',
                    'આसान સેટઅપ',
                    'સ્વચાલિત ચેક ઇન અને ચેક આઉટ્સ વિગતવાર રિપોર્ટ્સ',
                    'ટ્રેકિંગ સમય સાથે સ્ક્રીનશોટ્સ',
                    'પ્રવૃત્તિ સ્તર રેકોર્ડિંગ',
                    'એપ્લિકેશન્સ અને વેબસાઇટ ઉપયોગ મોનીટરીંગ',
                ],
                sDKeys: [
                    'કર્મચારી હાજરી સમય સંચાલન કરવામાં અને ઉત્પાદનક્ષમતા સુધારવામાં મદદરૂપ છે.',
                    'કાગળકામ દૂર કરો અને કર્મચારી હાજરીના ચોક્કસ રેકોર્ડ્સ મેળવો.',
                ],
            },
        ],
    },
    leaveManagement: {
        featureHeader: 'લીવ મેનેજમેન્ટ',
        feaatureSubcaption:
            'કર્મચારી ગેરહાજરીને સુમેળમાં રાખો અને ઉત્પાદન ક્ષમતા અને કાર્યચાલુતામાં સતત સ્થિરતા જાળવો.',
        featureDescription:
            'અસરકારક રીતે ગેરહાજરી અને હાજરીનું સંચાલન અને ટ્રેક કરો, ખાતરી કરો કે યોગ્ય દસ્તાવેજીકરણ થાય છે, કંપનીની નીતિઓનું પાલન થાય છે અને કાર્યપ્રવાહની સતત ચાલતું રહે છે.',
        featureParagraph:
            'હાજરી ચકાસવા માટે હવે સમયપત્રકો, વધુ કાગળકામ અને વિવિધ દસ્તાવેજોની જરૂર નથી. WebWork Time Tracker નો ઉપયોગ કરીને હાજરી મોનીટરીંગ પ્રક્રિયા સ્વચાલિત કરો અને તમારા કર્મચારીઓના કામના કલાકોનો શરૂઆત અને અંત એક સારી રીતે સંરચિત ફોર્મેટમાં જુઓ.',
        featureCaption: 'તમારા કર્મચારીઓના આગલા રજાઓ વિશે અગ્રતાથી જાણકારી મેળવો અને તમારા દિવસની યોજના બનાવો.',
        keyPoints: [
            {
                header: 'રજાઓની તાત્કાલિક ગણતરી અને બેલેન્સ',
                paragraph:
                    'અનામત રજાઓનું સંચાલન કરો, ખાતરી કરો કે કર્મચારીઓ તેમના ફાળવેલા રજાઓના પ્રમાણને પાર ન કરે, અને નેગેટિવ રજાઓના બેલેન્સને અટકાવો.',
            },
            {
                header: 'લવચીક રજા નીતિઓ, મંજૂરી વર્કફ્લોઝ',
                paragraph:
                    'સંગઠન માટે લવચીક રજા નીતિઓ કન્ફિગર કરો અને રજા વિનંતી માટે પેરાલેલ અથવા સિક્વેન્શિયલ મંજૂરી પદ્ધતિ સેટ કરો.',
            },
            {
                header: 'કામકાજી કાયદા અને કંપનીની નીતિઓનું પાલન',
                paragraph:
                    'કામકાજી કાયદા અને રજાના અધિકારોની નીતિઓ વિશે જાણકારી રાખો. તમારી રજા વ્યવસ્થાપન નીતિઓ કંપનીની નીતિઓ સાથે સુસંગત છે તે ખાતરી કરો.',
            },
            {
                header: 'સ્વસેવા વિકલ્પો',
                paragraph:
                    'કર્મચારીઓને સ્વસેવા વિકલ્પો પ્રદાન કરો, જેથી તેઓ તેમના રજાના બેલેન્સ ચેક કરી શકે, વિનંતીઓ સબમિટ કરી શકે અને તેમની વિનંતીઓની સ્થિતિ જોઈ શકે.',
            },
        ],
        softwareDetails: [
            {
                title: 'તમારા ટીમ માટે બહુપલ નીતિઓ અને લવચીક રજા નીતિઓ',
                description: [
                    'લવચીક રજા નીતિઓ તમારી ચોક્કસ જરૂરિયાતો માટે કન્ફિગર કરી શકાય છે.',
                    'ભિન્ન કર્મચારી જૂથો માટે બહુપલ રજા યોજનાઓ અને નીતિઓ.',
                    'અનલિમિટેડ રજાઓના પ્રકારો અને રજા વ્યવહારોનું સમર્થન.',
                ],
                sDKeys: [
                    'આ નીતિઓમાં વાર્ષિક રજા, બીમારી રજા, માતાપિતા રજા, શોક રજાઓ વગેરે જેવા રજાના પ્રકારો શામેલ છે.',
                    'નીતિઓ માટે પાત્રતા માપદંડ સેટ કરો.',
                    'અનામત દર, ઉપયોગ નિયમો, જરૂરી દસ્તાવેજીકરણ.',
                ],
            },
            {
                title: 'રજા અરજી અને મંજૂરી વર્કફ્લો માટે સ્વસેવા',
                description: [
                    'સ્વચાલન કર્મચારીઓના પ્રયાસોને ઘટાડે છે. કર્મચારીઓ વેબ પોર્ટલ અથવા મોબાઇલ એપ્લિકેશન દ્વારા રજા માટે અરજી કરે છે.',
                    'રજાના બેલેન્સ અને વ્યવહારો માટે રજા ટ્રેકર.',
                    'ટીમ રજા ડેટા અને નમૂનાઓ માટે મેનેજર વ્યૂ વધુ સારી સમજણ માટે.',
                    'એસ્કલેશન સાથેના વર્કફ્લોઝ પ્રતિક્રિયાશીલ સિસ્ટમ અને શ્રેષ્ઠ કર્મચારી અનુભવ તરફ દોરી જાય છે.',
                    'ઇમેલ નોટિફિકેશન્સ અને ચેતવણીઓ.',
                ],
                sDKeys: [
                    'કર્મચારીઓ વેબ પોર્ટલ અથવા મોબાઇલ એપ્લિકેશન દ્વારા રજા માટે અરજી કરે છે.',
                    'રજાના બેલેન્સ અને વ્યવહારો માટે રજા ટ્રેકર.',
                    'ટીમ રજા ડેટા અને નમૂનાઓ માટે મેનેજર વ્યૂ વધુ સારી સમજણ માટે.',
                    'એસ્કલેશન સાથેના વર્કફ્લોઝ પ્રતિક્રિયાશીલ સિસ્ટમ અને શ્રેષ્ઠ કર્મચારી અનુભવ તરફ દોરી જાય છે.',
                    'ઇમેલ નોટિફિકેશન્સ અને ચેતવણીઓ.',
                ],
            },
            {
                title: 'કંપનીની નીતિઓ અને કામકાજી કાયદાનો પાલન કરો',
                description: [
                    'અસરકારક કર્મચારી રજા વ્યવસ્થાપન ન केवल કામકાજી કાયદાનો પાલન કરે છે, પરંતુ કાર્યસ્થળની પોઝિટિવ સંસ્કૃતિ તરફ પણ યોગદાન આપે છે. અસરકારક રજા નીતિઓ અમલમાં મૂકી અને ટેકનોલોજીનો ઉપયોગ કરીને, સંસ્થાઓ પ્રક્રિયાને સરળ બનાવી શકે છે અને તેમના કર્મચારીઓને સમર્થન આપી શકે છે.',
                ],
                sDKeys: [
                    'બજેટ માટે રજા જવાબદારીઓને અસરકારક રીતે સંચાલિત કરો અને નાણાકીય જોખમો ઘટાડો.',
                    'માસિક, ત્રૈમાસિક અથવા વાર્ષિક આધાર પર પ્રદર્શનની ટ્રેકિંગ માટે વ્યક્તિગત રિપોર્ટ્સ માટે આંકડાકીય ડેટા જનરેટ કરો.',
                    'બળ સંચાલન, સંસાધન ફાળવણી અને કર્મચારી જોડાણની વ્યૂહરચનાઓ વિશે જાણકારી રાખો.',
                    'સરકારના નિયમો અનુસાર વિગતવાર રેકોર્ડ્સ અને રિપોર્ટ્સ.',
                    'વિભિન્ન કર્મચારી જૂથો માટે કસ્ટમાઇઝ કરેલ વિસ્તૃત રજાઓની સૂચિ.',
                ],
            },
        ],
    },
    shiftManagement: {
        featureHeader: 'શિફ્ટ મેનેજમેન્ટ',
        feaatureSubcaption:
            'વેચાણ પ્રવૃત્તિઓ સાથે કામદારોનું સમયપત્રક બનાવો, ગ્રાહકની માંગને પહોંચી વળવા માટે કાર્યક્ષમ સ્ટાફિંગ સ્તરને સુનિશ્ચિત કરો અને ઉત્પાદન ક્ષમતાનો મહત્તમ ઉપયોગ કરો.',
        featureDescription:
            'ઉત્પાદકતા વધારવા, કાર્યકારી જરૂરિયાતો પૂરી કરવા અને વિવિધ સમયગાળાઓમાં શ્રમિકોની પૂર્તિ સુનિશ્ચિત કરવા માટે કાર્ય કલાકોનું આયોજન અને સમયપત્રક બનાવો.',
        featureParagraph:
            'હાજરી ચકાસવા માટે હવે સમયપત્રકો, વધુ કાગળકામ અને વિવિધ દસ્તાવેજોની જરૂર નથી. WebWork Time Tracker નો ઉપયોગ કરીને હાજરી મોનીટરીંગ પ્રક્રિયા સ્વચાલિત કરો અને તમારા કર્મચારીઓના કામના કલાકોનો શરૂઆત અને અંત એક સારી રીતે સંરચિત ફોર્મેટમાં જુઓ.',
        featureCaption: 'કુલ કામના કલાકો અને બહુવિધ સત્રોની સમાગમ પર આધારિત તમારા કર્મચારીઓના શિફ્ટને ટ્રેક કરો.',
        keyPoints: [
            {
                header: 'કર્મચારી કાર્ય સમયપત્રકોનું સંચાલન',
                paragraph:
                    'સ્પષ્ટ અને સારી રીતે વ્યવસ્થિત શિફ્ટ શેડ્યૂલ વિકસાવો. તમારા વ્યવસાયના સ્વરૂપ પર આધારિત વિવિધ પ્રકારની શિફ્ટો ગોઠવો.',
            },
            {
                header: 'શિફ્ટ્સ નક્કી કરવી, ભૂમિકા ફાળવવી, પુનરાવર્તિત અથવા કસ્ટમ શેડ્યૂલ્સ સેટ કરો',
                paragraph:
                    'વિવિધ શિફ્ટોમાં જરૂરિયાત અનુસાર કર્મચારીઓની સંખ્યા નક્કી કરવા માટે કાર્યબોજ અને કાર્યોનું વિશ્લેષણ કરો.',
            },
            {
                header: 'સંવાદ સાધનોમાં નિર્મિત',
                paragraph:
                    'કર્મચારીઓ માટે તેમના શેડ્યૂલ્સમાં પ્રવેશ મેળવવા માટે સેન્ટ્રલાઇઝ્ડ પ્લેટફોર્મ, અને કોઈપણ અપડેટ અથવા ફેરફારો માટે વિશ્વસનીય સંવાદ ચેનલની સ્થાપના કરો.',
            },
            {
                header: 'કઈ કેન્દ્રિયકૃત શિફ્ટ મેનેજમેન્ટ સાથે બહુવિધ સ્થળોના શેડ્યૂલ્સ',
                paragraph:
                    'કર્મચારીઓના શેડ્યૂલ્સનું કેન્દ્રિયકૃત દેખરેખ અને નિયંત્રણ, મેનેજરોને શિફ્ટોને અસરકારક રીતે ફાળવવા, હાજરીને ટ્રેક કરવા, અને એક જ પ્લેટફોર્મ પરથી બહુવિધ સ્થાન પર શ્રમિક સંસાધનોનું સંચાલન કરવા માટે સક્ષમ બનાવે છે.',
            },
        ],
        softwareDetails: [
            {
                title: 'સ્થાન અને શિફ્ટના આધારે શેડ્યૂલિંગ પ્રક્રિયાને સ્વચાલિત કરો',
                description: [
                    'વિવિધ શિફ્ટોમાં જરૂરી કર્મચારીઓની સંખ્યા નક્કી કરવા માટે કાર્યબોજ અને કાર્યોનું વિશ્લેષણ કરો.',
                    'પીક વ્યાપાર કલાકો, મોસમી ફેરફારો અને દરેક શિફ્ટ માટે કોઈપણ વિશિષ્ટ જરૂરિયાતો પર ધ્યાન આપો. તેમાં દિવસની શિફ્ટ, રાત્રિ શિફ્ટ, રોટેટિંગ શિફ્ટ, સ્પ્લિટ શિફ્ટ અથવા લવચીક સમયપત્રક શામેલ હોઈ શકે છે.',
                ],
                sDKeys: [
                    'કર્મચારીઓ વચ્ચે શિફ્ટસ બદલો.',
                    'કર્મચારીઓ ઉપલબ્ધ વિકલ્પ સાથે અન્ય કર્મચારીઓ સાથે તેમની શિફ્ટસ બદલી શકે છે.',
                    'કર્મચારીઓ પોતાનું શ્રેષ્ઠ શિફ્ટ અને સમય-ઓફ પસંદ કરી શકે છે.',
                ],
            },
            {
                title: 'ડાયનામિક શિફ્ટ શેડ્યૂલિંગ માટે શિફ્ટ બોર્ડ',
                description: [
                    'વિરામો અને આરામ સમયગાળાઓ માટેના કામકાજી કાયદાનો પાલન સુનિશ્ચિત કરો.',
                    'કર્મચારીઓના થાકને રોકવા અને ઉત્પાદન ક્ષમતાને જાળવવા માટે શિફ્ટ શેડ્યૂલમાં વિરામો બનાવો.',
                    'ખાતરી કરો કે તમારી શિફ્ટ મેનેજમેન્ટ નીતિઓ કાનૂની જરૂરિયાતો સાથે સુસંગત છે.',
                ],
                sDKeys: [
                    'વપરાશકર્તા-મૈત્રીપૂર્ણ શેડ્યૂલિંગ સાધનોનો ઉપયોગ કરીને તમારી ટીમ માટે કાર્યક્ષમ કાર્ય સમયપત્રકો સરળતાથી બનાવો.',
                    'તમારા વ્યવસાયની કામગીરીની જરૂરિયાતો અને કર્મચારીની ઉપલબ્ધતાને અનુરૂપ શિફ્ટ્સને કસ્ટમાઇઝ કરો.',
                    'કર્મચારીની પસંદગીઓ અને કાર્યબોજને ધ્યાનમાં રાખીને શિફ્ટસનો ન્યાયી વિતરણ સુનિશ્ચિત કરો.',
                ],
            },
            {
                title: 'અજીબ કલાકો પર કામ કરવા માટે શિફ્ટ ભથ્થાઓ સાથે કર્મચારીઓને પ્રોત્સાહિત કરો',
                description: [
                    'કર્મચારીઓને તેમના કામ કરેલા શિફ્ટ્સ પર આધારિત ચોક્કસ વેતન અને કપાતોને સુનિશ્ચિત કરવા માટે શિફ્ટ મેનેજમેન્ટ સિસ્ટમને પેરોલ પ્રક્રિયાઓ સાથે સંકલિત કરો.',
                    'ઓવરટાઇમ માટે પ્રક્રિયાઓને વ્યાખ્યાયિત કરો અને ખાતરી કરો કે ઓવરટાઇમ નીતિઓ કામકાજી કાયદાઓ સાથે સુસંગત છે.',
                ],
                sDKeys: [
                    'એક ક્લિકમાં કર્મચારીઓની શિફ્ટ વિનંતીને મંજૂરી આપો.',
                    'કર્મચારીઓ તેમની સગવડ અનુસાર સપ્તાહ/માસની શરૂઆતમાં તેમની પોતાની શિફ્ટ પસંદ કરી શકે છે અને તેમના પોર્ટલ પરથી વિનંતીઓ મોકલી શકે છે.',
                    'કંપનીની નીતિ અથવા કલાકોના વેતન દર પર આધારિત શિફ્ટ ભથ્થા ચુકવીને નફાકારક ઉકેલ.',
                ],
            },
        ],
    },
    geoFenceBasedManagement: {
        featureHeader: 'જિઓ ફેન્સ આધારિત હાજરી',
        feaatureSubcaption: 'સચોટતા અને જવાબદારી વધારવી, નિર્ધારિત સીમાઓ સાથે કર્મચારીઓની હાજરીને ટ્રેક કરો',
        featureDescription:
            'નિર્ધારિત ભૌગોલિક સીમાઓમાં કર્મચારીઓની હાજરીને મોનિટર કરવા અને ચકાસવા માટે સ્થાન આધારિત ટેક્નોલોજી',
        featureCaption:
            'જિઓ ફેન્સ આધારિત હાજરી, તમને સચોટ હાજરી ટ્રેકિંગમાં મદદ કરે છે, કારણ કે કર્મચારીઓની હાજરી માત્ર ત્યારે જ દર્શાવવામાં આવે છે જ્યારે તેઓ નિર્ધારિત વિસ્તારમાં હોય',
        keyPoints: [
            {
                header: 'નિર્ધારિત જિઓ-ફેન્સ્ડ વિસ્તારની અંદર કર્મચારીઓને સચોટ રીતે શોધો',
                paragraph: 'કર્મચારીઓ તેમની નિયુક્ત કાર્યસ્થળો પર હાજર છે તે સુનિશ્ચિત કરવા માટે સચોટ હાજરી રેકોર્ડ્સ',
            },
            {
                header: 'કર્મચારીના સ્થાનનું વાસ્તવિક-સમયનું મોનિટરીંગ',
                paragraph: 'જિઓ-ફેન્સની અંદર કર્મચારીઓના સ્થાનોને મોનિટર કરવા માટે વાસ્તવિક-સમય ટ્રેકિંગ લાગુ કરો',
            },
            {
                header: 'કલાકી નોંધ કરવા માટે વિશિષ્ટ ભૌગોલિક સીમાઓ નિર્ધારિત કરો',
                paragraph:
                    'કાર્યસ્થળ અથવા નિર્ધારિત હાજરી સ્થાનોની આસપાસ જિઓ-ફેન્સ તરીકે જાણીતી નિર્ધારિત ભૌગોલિક સીમાઓ સ્થાપિત કરો',
            },
            {
                header: 'જીઓફેન્સ એલર્ટ્સ',
                paragraph:
                    'કર્મચારી જ્યારે જિઓ-ફેન્સ સીમાઓમાં પ્રવેશ કરે છે અથવા બહાર જાય છે ત્યારે મેનેજર્સ અથવા એચઆર સ્ટાફને જાણ કરવા માટે એલર્ટ અથવા સૂચનાઓ સેટ કરો',
            },
        ],
        softwareDetails: [
            {
                title: 'હાજરી માર્કિંગ પર સ્થાન નિયંત્રણ',
                description: [
                    'GPS કો-ઓર્ડિનેટ્સ અથવા સ્થાન આધારિત ટેક્નોલોજીનો ઉપયોગ કરીને વિશિષ્ટ ભૌગોલિક સીમાઓ સ્થાપિત કરવી. આ સીમાઓ તે વિસ્તારનું પ્રતિનિધિત્વ કરે છે જ્યાં કર્મચારીઓને કામ માટે હાજર રહેવાની અપેક્ષા છે.',
                ],
                sDKeys: [
                    'ઓફિસ નજીકની હાજરી સુનિશ્ચિત કરવા માટે વિશિષ્ટ IP સરનામાથી હાજરીને મંજૂરી આપવાનો વૈકલ્પિક વપરાશકર્તા પ્રતિબંધ ફીચર.',
                    'પુષ સૂચનાઓ ફીચરનો ઉપયોગ કરીને વિશિષ્ટ અથવા તમામ વપરાશકર્તાઓ સાથે સંવાદની સરળતા.',
                    'દૈનિક કાર્યો અને શેડ્યૂલ્સ જોવા માટે હાજરી કર્મચારીઓ સાથે કાર્યો લિંક કરો.',
                ],
            },
            {
                title: 'હાજરી પ્રતિબંધો અને બાયોમેટ્રિક ઓથેન્ટિકેશન સેટ કરો',
                description: [
                    'જીઓ-ફેન્સ્ડ વિસ્તારોની અંદર કર્મચારીઓના સ્થાનોને મોનિટર કરવા માટે વાસ્તવિક-સમયનું સ્થાન ટ્રેકિંગ લાગુ કરો. આ માહિતી હાજરીની પુષ્ટિ કરવા માટે અને નિર્ધારિત કામના સ્થળો દરમિયાન કર્મચારીઓ હાજર છે તેની ખાતરી કરવા માટે ઉપયોગ થાય છે.',
                ],
                sDKeys: [
                    'કર્મચારીઓ તેમના પ્રથમ કાર્યસ્થળ પર પહોંચે ત્યારે જ તેમની હાજરી નોંધાવી શકે છે.',
                    'કર્મચારીઓ દૂરથી હાજરી નોંધાવી શકે છે; ફોલ્ટી હાજરી કે દરરોજ ઓફિસ જવા માટે હજી હાજરી આપવા જેવી મુશ્કેલી નથી.',
                    'મેનેજર્સ વાસ્તવિક સમયના સ્થાનને ટ્રેક કરી શકે છે અને ફોન એપ્લિકેશન્સ અથવા વેબ દ્વારા દૂરથી અપડેટ પ્રાપ્ત કરી શકે છે.',
                ],
            },
            {
                title: 'સુરક્ષા અને અધિકૃતિ વધારવું',
                description: [
                    'PIN કોડ્સ, બાયોમેટ્રિક ઓથેન્ટિકેશન અને ચહેરા ઓળખનો ઉપયોગ કરીને છેતરપીંડીપૂર્ણ હાજરી દાખલીઓને રોકવા માટે સુરક્ષિત ઓથેન્ટિકેશન ઉપાયો લાગુ કરો.',
                    'જીઓ-ફેન્સ વિસ્તારમાં પ્રવેશ કરતા જ કર્મચારીઓને ચેક ઇન કરવાની યાદ અપાવવા માટે મોબાઇલ સૂચનાઓ અથવા એલર્ટ સેટ કરો. આ સુનિશ્ચિત કરે છે કે કર્મચારીઓ હાજરી ટ્રેકિંગ સિસ્ટમ વિશે સચેત છે.',
                ],
            },
        ],
    },
    multipleClockInOptions: {
        featureHeader: 'મલ્ટિપલ ક્લોક ઇન વિકલ્પો',
        feaatureSubcaption:
            'લવચીકતા અને સગવડતા પ્રદાન કરવી, મલ્ટિપલ ક્લોક ઇન વિકલ્પો કર્મચારીઓને તેમનો સમય કાર્યક્ષમ રીતે સંભાળવામાં સક્ષમ બનાવે છે.',
        featureDescription:
            'કર્મચારીઓને બાયોમેટ્રિક સ્કેનર, મોબાઇલ એપ્લિકેશન્સ, સમય ઘડિયાળ અને વેબ-આધારિત પ્લેટફોર્મનો ઉપયોગ કરીને તેમના કાર્ય કલાકો નોંધાવવા માટે લવચીકતા પ્રદાન કરો.',
        featureCaption:
            'રિમોટથી કામ કરી રહેલા કર્મચારીઓ માટે અનુકૂળતા સક્ષમ કરો અને વિવિધ સેશન સાથે મલ્ટિપલ ક્લોક ઇન કરી શકો છો',
        keyPoints: [
            {
                header: 'મલ્ટિપલ સ્થળો ધરાવતી સંસ્થાઓ માટે યોગ્ય',
                paragraph: 'વિભિન્ન શાખાઓ માટે ઑન-ફીલ્ડ અને ઑફ-ફીલ્ડ હાજરી મેનેજમેન્ટ માટે લવચીક ક્લોક ઇન વિકલ્પો.',
            },
            {
                header: 'કર્મચારી સ્વ સેવા પોર્ટલ',
                paragraph:
                    'કર્મચારીઓને હાજરી રેકોર્ડ્સના સ્વ સેવા પોર્ટલનો ઍક્સેસ પ્રદાન કરો અને જીઓ-ફેન્સની અંદર તેમના ચેક-ઇન્સ અને ચેક-આઉટ્સને મેનેજ કરો.',
            },
            {
                header: 'કલાકી નોંધ માટે મલ્ટિપલ વિકલ્પો',
                paragraph:
                    'લવચીક ક્લોક-ઇન વિકલ્પોમાં બાયોમેટ્રિક સ્કાન્સ, મોબાઇલ એપ્લિકેશન્સ, RFID કાર્ડ્સ અને ચહેરા ઓળખનો સમાવેશ થાય છે, જે વિવિધ કાર્ય પરિસ્થિતિઓમાં સચોટ સમય ટ્રેકિંગ સુનિશ્ચિત કરે છે.',
            },
            {
                header: 'સચોટ જિઓ-આધારિત ક્લોક ઇન',
                paragraph:
                    'ઑફિસ પરિસરના શ્રેણીની અંદર રહેલા કર્મચારીઓ માટે ઑટો ક્લોક ઇન હાજરી માર્કિંગ સાથે એલર્ટ સૂચના.',
            },
        ],
        softwareDetails: [
            {
                title: 'સાઇટ પર અને સાઇટથી દૂર ક્લોક ઇન્સ',
                description: [
                    'વિભિન્ન કાર્ય પરિસ્થિતિઓને ધ્યાનમાં રાખીને, અમારી કર્મચારી હાજરી મેનેજમેન્ટ સિસ્ટમ મલ્ટિપલ ક્લોક-ઇન વિકલ્પો પ્રદાન કરે છે.',
                    'કર્મચારીઓ સાઇટ પર હોય, ફીલ્ડમાં હોય કે રિમોટથી કામ કરી રહ્યાં હોય, તેઓ બાયોમેટ્રિક સ્કાન્સ, ચહેરા ઓળખ અને જિઓ ફેન્સ આધારિત ઑટો ક્લોક ઇન દ્વારા તેમનો સમય સરળતાથી લોગ કરી શકે છે.',
                ],
            },
            {
                title: 'કર્મચારીને તેમના રેકોર્ડ્સ ચકાસવા માટે સ્વ સેવા પોર્ટલ',
                description: [
                    'કર્મચારી સ્વ સેવા પોર્ટલ મલ્ટિપલ ચેક-ઇન્સ અને ચેક-આઉટ્સ પર નિયંત્રણ પ્રદાન કરે છે.',
                    'વપરાશકર્તા-મૈત્રીપૂર્ણ સુવિધાઓ સાથે, કર્મચારીઓ સરળતાથી તેમના કાર્ય કલાકોને મેનેજ કરી શકે છે, ભલે તેઓ શિફ્ટો, સ્થાન કે કાર્યો વચ્ચે પરિવર્તન કરી રહ્યા હોય.',
                    'આ કર્મચારીઓને લવચીકતા સાથે સક્ષમ બનાવે છે, જ્યારે સચોટ સમય રેકોર્ડ્સ જાળવી રાખે છે, કાર્યક્ષમતા વધારવા અને વહીવટી પ્રક્રિયાઓને સરળ બનાવે છે.',
                ],
            },
            {
                title: 'મલ્ટિપલ શાખાઓ માટે મલ્ટિપલ ક્લોક ઇન્સ',
                description: [
                    'તમારી કંપનીની ગતિશીલ જરૂરિયાતોને ધ્યાનમાં રાખો જે મલ્ટિપલ સ્થાનોમાં વ્યાપક છે અને સચોટ હાજરી ટ્રેકિંગ અને ઑપરેશનલ કાર્યક્ષમતા સુનિશ્ચિત કરો.',
                ],
            },
        ],
    },
    attendanceReport: {
        featureHeader: 'હાજરી રિપોર્ટ્સ',
        feaatureSubcaption: 'ટીમની પ્રતિબદ્ધતા અને સમયપાલનનું માર્ગદર્શક પ્રતિબિંબ, એક જ રિપોર્ટમાં કૅપ્ચર કરાયેલું.',
        featureDescription:
            'નિર્દિષ્ટ સમય મર્યાદામાં કર્મચારીઓની હાજરી ડેટા, કાર્ય કલાકો, ગેરહાજરી, મોડા આગમન અને વહેલી રવાના થતા આવરણોનો સારાંશ આપો.',
        featureCaption:
            'વિભિન્ન શાખાઓ માટે સ્વચાલિત રિપોર્ટ જનરેટ કરો અને તેમને દૈનિક, સાપ્તાહિક, માસિક ધોરણે 40+ થી વધુ ટેમ્પ્લેટ્સ સાથે મેળવો',
        keyPoints: [
            {
                header: 'હાજરી વિગતો આપમેળે કરો: લૉગ-ઇન સમય, મોડો સમય, કુલ કાર્ય કલાકો, કુલ સેશન્સ',
                paragraph:
                    'હાજરીનો એકંદર સારાંશ આપો, જેમાં કુલ કાર્ય દિવસોની સંખ્યા, કામ કરેલ કુલ કલાકો અને કોઈપણ ઓવરટાઈમ કલાકોનો સમાવેશ થાય છે.',
            },
            {
                header: 'મલ્ટિપલ શાખાઓ રિપોર્ટ્સ',
                paragraph: 'વિશ્વસનીય રીતે મલ્ટિપલ શાખાઓની હાજરી રિપોર્ટ્સ કસ્ટમાઇઝેબલ કેટેગરીઝ સાથે દુરથી ઍક્સેસ કરો.',
            },
            {
                header: 'ઓવરટાઇમ અને રજા બેલેન્સ',
                paragraph:
                    'દરેક કર્મચારી માટે રજા બેલેન્સ દર્શાવો, જેમાં વેકેશન ડેઝ, બીમાર રજા અને અન્ય કોઈપણ પ્રકારની રજા જમા કરેલ અને ઉપયોગ કરેલ છે.',
            },
            {
                header: 'કસ્ટમાઇઝ થયેલ હાજરી રિપોર્ટ',
                paragraph:
                    'વપરાશકર્તાઓને વિભાગ, ટીમ અથવા વ્યક્તિગત કર્મચારી જેવી ખાસ માપદંડો પર આધારિત રિપોર્ટ્સ કસ્ટમાઇઝ કરવાની મંજૂરી આપો.',
            },
        ],
        softwareDetails: [
            {
                title: 'તમારી સંસ્થા રચનાના આધારે રિપોર્ટ્સ જનરેટ કરો.',
                description: [
                    'નિયમિત રીતે જનરેટ થયેલ અને વિશ્લેષિત હાજરી રિપોર્ટ્સ કર્મચારી મેનેજમેન્ટ સુધારવા, પ્રવૃત્તિઓની ઓળખ કરવા અને સંસ્થાના નીતિ અને નિયમોનું પાલન સુનિશ્ચિત કરવા માટે મૂલ્યવાન આંતરદૃષ્ટિ પ્રદાન કરી શકે છે.',
                    'રિપોર્ટની વિગતો સંસ્થા નિષ્ઠા અને જરૂરિયાતો પર આધાર રાખે છે.',
                ],
                sDKeys: [
                    'વિભિન્ન વપરાશકર્તા દ્વારા એકત્રિત માહિતી આપમેળે વિશ્લેષણ માટે સાથ આપે છે, જે મેન્યુઅલી રિપોર્ટ જનરેટ કરવા માટેના સમય અને સંસાધનોની બચત કરે છે.',
                    'વપરાશકર્તાઓ તેમના જરૂરી વિશ્લેષણને માત્ર થોડા ક્લિક્સમાં પસંદ કરી શકે છે અને તે કોઈ પણ સમયે સબ્સ્ક્રાઇબ કરી શકે છે.',
                    'નમૂના વિશ્લેષણ રિપોર્ટ ડાઉનલોડ કરી શકાય છે અને તે ઇચ્છિત રિપોર્ટનું વાસ્તવિક પ્રતીનિધિત્વ તરીકે જોઈ શકાય છે.',
                ],
            },
            {
                title: 'સહેલાઇથી કસ્ટમાઇઝેબલ રિપોર્ટ્સ મેળવો.',
                description: [
                    'કોઈપણ અપવાદો અથવા હાજરીમાં અનિયમિતતાઓ, જેમ કે અનધિકૃત ગેરહાજરી, વધુ મોડું આગમન અથવા અનુમોદન વિના ઓવરટાઇમ હાઇલાઇટ કરો.',
                    'હાજરીના પ્રવાહો અને પેટર્નને દ્રશ્યરૂપે રજૂ કરવા માટે ચાર્ટ્સ અથવા ગ્રાફ્સનો ઉપયોગ કરો. આને કારણે આઉટલાયર્સ અને પ્રવાહોને જલ્દી ઓળખવી સરળ બને છે.',
                ],
                sDKeys: [
                    'વપરાશકર્તા વિવિધ રીતે જનરેટ થયેલ ડેટા ઉકેલો માટે લાભ મેળવે છે, જેમ કે પગાર પ્રક્રિયા, પ્રદર્શન મૂલ્યાંકન, રિએમ્બર્સમેન્ટ વગેરે.',
                    'રિપોર્ટ્સ અન્ય વપરાશકર્તાઓ સાથે અધિકારીય પ્રવાહના આધારે શેર કરી શકાય છે.',
                    'કંપની મેન્યુઅલ હસ્તક્ષેપ વિના, તેમની ટીમની કાર્યક્ષમતા અને કામગીરી વિશે સરળ રીતે અવગત થઈ શકે છે.',
                ],
            },
            {
                title: 'તમારો મેન્યુઅલ રિપોર્ટ માટેનો સમય બચાવો.',
                description: [
                    'કર્મચારી નામો, ઓળખ નંબરો અને વિભાગની સંલગ્નતા જેવા મૂળભૂત વિગતો સામેલ છે, જે હાજરી ડેટાને સંદર્ભ પૂરી પાડે છે.',
                    'રિપોર્ટ દ્વારા આવરી લેવાયેલા સમય અને તારીખની શ્રેણી સ્પષ્ટપણે બતાવી છે. આ વપરાશકર્તાઓને ડેટાની સમયસર સુવિધા સમજવામાં મદદ કરે છે.',
                    'હાજરીનો એકંદર સારાંશ પૂરો પાડવો, જેમાં કુલ કાર્ય દિવસોની સંખ્યા, કામ કરેલા કુલ કલાકો અને કોઈપણ ઓવરટાઇમ કલાકોનો સમાવેશ થાય છે.',
                ],
            },
        ],
    },
    workforceTracking: {
        featureHeader: 'કર્મચારી ટ્રેકિંગ',
        featureDescription: 'કર્મચારીઓની પ્રવૃત્તિઓ, ઉત્પાદકતા અને હાજરીનું સ્નિગ્ધ રૂપે મોનીટરીંગ અને મેનેજમેન્ટ.',
        featureParagraph:
            'હવે વધુ ટાઇમકાર્ડ્સ, અત્યધિક દસ્તાવેજો અને કર્મચારીઓની હાજરીની તપાસ કરવા માટે જુદા જુદા દસ્તાવેજોની જરૂર નથી. WebWork Time Tracker સાથે હાજરી મોનીટરીંગ પ્રક્રિયાને આપમેળે કરો અને કર્મચારીઓની કાર્યકાળની શરૂઆત અને અંતને સારી રીતે રચાયેલ ફોર્મેટમાં જુઓ.',
        featureCaption:
            'સંસ્થાના કર્મચારીઓની હાજરી, કાર્ય કલાકો, ઉત્પાદકતા અને પ્રદર્શન સહિતની વિવિધ પાસાઓનું મોનીટરીંગ અને મેનેજ કરો.',
        keyPoints: [
            {
                header: 'ટાઇમલાઇન આધારિત હાજરી અને ગતિશીલતાનું ટ્રેકિંગ',
                paragraph:
                    'ક્લોક ઇન અને ક્લોક આઉટ જેવા ઇવેન્ટ્સ અને કર્મચારીઓની કામગીરી, પ્રદર્શન મેટ્રિક્સ અથવા ઇવેન્ટ્સમાં ઊંડાણપૂર્વકની માહિતીનું મોનીટરીંગ કરો.',
            },
            {
                header: 'ડેટા મેનેજમેન્ટ માટે કસ્ટમ ફોર્મ્સ',
                paragraph:
                    'મિટિંગ્સ અને ટાસ્ક્સ માટે મિનિટ્સ સ્ટોર કરવા માટે ડેટા ટ્રેક કરીને ટ્રેન્ડ્સ અને ડેટા પર આધારિત નિર્ણયો માટે સ્ટોર કરો.',
            },
            {
                header: 'ગ્રાહક અને લીડ મેનેજમેન્ટ',
                paragraph:
                    'કર્મચારીઓ દ્વારા પૂર્ણ કરેલા કામના આધારે ઉત્પાદકતાનું ટ્રેક કરો અને મુખ્ય પ્રદર્શન સૂચકોને માપો.',
            },
            {
                header: 'દાખલ કરેલ સચોટ દાવાઓ માટે કુલ અંતરની ગણતરી',
                paragraph:
                    'મેદાન પર કામ માટે કર્મચારીઓએ નમાવેલી અંતરનું સચોટ રીતે ટ્રેક કરો અને દૈનિક, સાપ્તાહિક અને માસિક મેટ્રિક્સના આધારે તૃણમૂલ્ય નકામી મૂડીભંડાર કરેલા સ્થળોને વળતર આપો.',
            },
        ],
        softwareDetails: [
            {
                title: 'કામના કલાકોનું મોનીટરીંગ કરવા માટે સમય ટ્રેકિંગ સિસ્ટમ લાગુ કરો.',
                description: [
                    'કર્મચારીઓ કામ અને પ્રોજેક્ટ પર પસાર કરેલ સમયનું મોનીટરીંગ કરવા માટે સમય ટ્રેકિંગ ઉકેલો લાગુ કરો.',
                    'આ સંસાધનોના ઉપયોગને સમજવામાં અને સમય વ્યવસ્થાપન સુધારવામાં મદદ કરે છે.',
                    'પ્રોજેક્ટ મેનેજમેન્ટ સિસ્ટમ્સનો ઉપયોગ કરીને પ્રોજેક્ટની પ્રગતિને ટ્રેક કરો, ટાસ્ક્સ નક્કી કરો, અને સમય મર્યાદાઓનું મોનીટરીંગ કરો.',
                    'આ પ્રોજેક્ટના કાર્યપ્રવાહોને ઓપ્ટિમાઇઝ કરવામાં અને સમયસર પૂર્ણતા સુનિશ્ચિત કરવામાં મદદ કરે છે.',
                ],
            },
            {
                title: 'મહત્વના પ્રદર્શન સૂચકોનું મોનીટરીંગ કરો.',
                description: [
                    'તમારા સંસ્થાના ધ્યેયો માટે સંબંધિત મુખ્ય પ્રદર્શન સૂચકોને વ્યાખ્યાયિત કરો અને ટ્રેક કરો.',
                    'આમાં વેચાણ લક્ષ્યો, ગ્રાહક સંતોષ મેટ્રિક્સ અથવા અન્ય પ્રદર્શન સૂચકોનો સમાવેશ થઈ શકે છે.',
                ],
                sDKeys: [
                    'વેચાણ પ્રદર્શન: માસિક/ક્વાર્ટરલી વેચાણ આવક, બંધ થયેલ ડીલ્સની સંખ્યા, વેચાણ કન્વર્ઝન દર, સરેરાશ ડીલ કદ, વેચાણ પાઇપલાઇન મૂલ્ય.',
                    'ગ્રાહક સેવા: ગ્રાહક સંતોષ સ્કોર (CSAT), નેટ પ્રમોટર સ્કોર (NPS), પ્રથમ પ્રતિસાદ સમય, સરેરાશ સમાધાન સમય, ગ્રાહક જાળવણી દર, ટિકિટ વોલ્યુમ.',
                    'ઓપરેશન્સ/પ્રોડક્શન: ઉત્પાદન આઉટપુટ/થ્રુપુટ, ગુણવત્તા મેટ્રિક્સ (દોષ દર, ભૂલ દર), સમયસર ડિલિવરી પ્રદર્શન, ઈન્વેન્ટરી ટર્નઓવર રેશિયો, ઉપકરણ ડાઉનટાઇમ, કર્મચારી ઉપયોગ દર.',
                    'જનરલ: કર્મચારી ઉત્પાદકતા (પ્રતિ કલાક આઉટપુટ), હાજરી અને સમયપાલન, લક્ષ્ય પ્રાપ્તિ દર, કર્મચારી પ્રતિસાદ અને પ્રદર્શન સમીક્ષાઓ.',
                ],
            },
            {
                title: 'તમારા કર્મચારી વલણોની રિપોર્ટ્સ જનરેટ કરો.',
                description: [
                    'કર્મચારી વલણો, પ્રદર્શન અને સુધારાના ક્ષેત્રો અંગેની આંતરદૃષ્ટિઓ મેળવવા માટે વિશ્લેષણ અને રિપોર્ટિંગ ફીચર્સને એકીકૃત કરો.',
                    'કસ્ટમાઇઝ થયેલ રિપોર્ટ્સ નિર્ણય લેવામાં સહાયરૂપ છે.',
                ],
                sDKeys: [
                    'સમગ્ર કર્મચારીઓ અથવા ઉદ્યોગ બેન્ચમાર્ક સાથે પ્રદર્શન મેટ્રિક્સનું તુલનાત્મક વિશ્લેષણ અને સમયાંતરે કોઈપણ વલણો અથવા પરિવર્તનોની ઓળખ કરો.',
                    'ફિલ્ડ ટીમો, પ્રદેશો અથવા સમયગાળા દરમિયાન પ્રદર્શન મેટ્રિક્સની તુલના કરો.',
                    'ફિલ્ડ ફોર્સ પ્રવૃત્તિઓના વિસ્તારનકર્તા નકશાનું વિઝ્યુલાઇઝેશન કરવા માટે ભૌગોલિક સ્થાનકન દર્શાવો.',
                    'ભૌગોલિક પ્રદેશ અથવા પ્રદેશ દ્વારા વેચાણ અથવા સેવા પ્રદર્શનનું વિશ્લેષણ કરો. વિસ્તરણ અથવા ઓપ્ટિમાઇઝેશન માટે ઉચ્ચ ક્ષમતાવાળા વિસ્તારોની ઓળખ કરો.',
                ],
            },
        ],
    },
    fieldSalesAnalytics: {
        featureHeader: 'ફિલ્ડ સેલ્સ વિશ્લેષણ',
        featureDescription:
            'ફિલ્ડ પર સેલ્સ ટીમના મુખ્ય મેટ્રિક્સ જેવા કે વેચાણ આવક, ગ્રાહક સાથેની ક્રિયાપ્રતિક્રિયાઓ અને ભૌગોલિક વલણોને ટ્રેક કરો અને વિશ્લેષણ કરો.',
        featureParagraph:
            'હવે વધુ ટાઇમકાર્ડ્સ, અત્યધિક દસ્તાવેજો અને કર્મચારીઓની હાજરી ચકાસવા માટે જુદા જુદા દસ્તાવેજોની જરૂર નથી. WebWork Time Tracker સાથે હાજરી મોનીટરીંગ પ્રક્રિયાને આપમેળે કરો અને કર્મચારીઓની કાર્યકાળની શરૂઆત અને અંતને સારી રીતે રચાયેલ ફોર્મેટમાં જુઓ.',
        featureCaption:
            'ફિલ્ડ સેલ્સ પ્રવૃત્તિઓ સાથે સંબંધિત ડેટાના સંગ્રહ, વિશ્લેષણ અને મૂલ્યાંકન કરો જેથી આંતરદૃષ્ટિ મેળવવામાં અને જાણકારી પર આધારિત નિર્ણય લેવાય.',
        keyPoints: [
            {
                header: 'વેચાણ અને લીડ્સ માટે મુખ્ય પ્રદર્શન સૂચક (KPI)',
                paragraph:
                    'મુખ્ય પ્રદર્શન સૂચક ડેટા પર આધારિત નિર્ણય લેવામાં મદદ કરી શકે છે, જે પ્રોડક્ટિવ નિર્ણયો અથવા નવા સ્ટ્રેટેજી બનાવવા માટે મદદરૂપ થાય છે.',
            },
            {
                header: 'કસ્ટમ રિપોર્ટ્સ',
                paragraph:
                    'સેલ્સ, લીડ અને ગ્રાહક પ્રાપ્તિ પર દૈનિક, સાપ્તાહિક અથવા માસિક આધારિત રિપોર્ટ્સ એક્સેસ કરી શકાય છે. આ વ્યક્તિની પ્રોડક્ટિવિટી અને કાર્યક્ષમતા માટે મદદરૂપ છે.',
            },
            {
                header: 'સેલ્સ વિઝિટને માન્ય કરવું અને જીઓફેન્સથી સમન્વયિત કરવું',
                paragraph:
                    'સચોટતા માટે જીઓફેન્સ આધારિત ચેક-ઇન સાથે વિઝિટને માન્ય બનાવો. સ્થાનના કોઓર્ડિનેટ્સની સાથે ચેક-ઇન કરતી વખતે કેમેરાની તસવીર જોડાય શકે છે.',
            },
            {
                header: 'બીટ રૂટની અસરકારકતા માપો',
                paragraph:
                    'બીટ રૂટનો ઉપયોગ કર્મચારીઓની ક્લાયન્ટ વિઝિટ્સ માટે વિઝિટ્સ અથવા બીટ પ્લાન્સની યોજના બનાવવા માટે થઈ શકે છે. નકશામાં તમારા બીટ પ્લાનને સીધા એન્ટિગ્રેટ કરો જેથી નૅવિગેશનને ઑપ્ટિમાઇઝ કરી શકાય.',
            },
        ],
        softwareDetails: [
            {
                title: 'વધુ સારું વેચાણ પ્રદર્શન માટે રોજિંદા ફિલ્ડ અંતર્દૃષ્ટિ.',
                description: [
                    'ડેટા વિશ્લેષણ પર આધારિત સતત ઇ-રિપોર્ટ જનરેશન.',
                    'આપના ઉપયોગ માટે વિશ્વસનીય અને વપરાશકર્તા માટે અનુકૂળ રિપોર્ટ ફીચર.',
                    'વેચાણ મેનેજર તરીકે, તમે વિવિધ રિપોર્ટ્સને સ્પષ્ટ રીતે જોઈ શકો છો, જે તમારા મેઇલ ઍડ્રેસ પર મોકલવામાં આવશે અથવા ડૅશબોર્ડ ઈન્સાઇટમાં જોઈ શકો છો.',
                    'મહત્વપૂર્ણ રિપોર્ટ્સમાં માસિક રિપોર્ટ્સ, બ્રાન્ચ સ્થાન પ્રમાણેના રિપોર્ટ્સ અને કર્મચારી વિશિષ્ટ રિપોર્ટ્સનો સમાવેશ થાય છે.',
                ],
            },
            {
                title: 'કામના મેદાનમાં હાજરીની સમીક્ષા.',
                description: [
                    'કર્મચારી ફિલ્ડ હાજરી અને ફિલ્ડ વિઝિટ્સની સમીક્ષા કરો.',
                    'ફિલ્ડ કામદારની હાજરી અને મિટિંગ્સ પર વિશાળ દૃષ્ટિકોણ મેળવો.',
                    'ફિલ્ડ હાજરી રિપોર્ટમાં, તમને હાજરી માર્કિંગ સમય, સ્થાન, મોડી હાજરી સમય, કાર્યકાળ વગેરે અંગેનો ડેટા મળશે.',
                    'તે જ રીતે, અમે ફિલ્ડ વિઝિટ્સના ચેક-ઇન/આઉટ રિપોર્ટ્સ પણ પ્રદાન કરીએ છીએ, જેમાં ફિલ્ડ વિઝિટ્સ સંબંધિત ડેટા, જેવી કે લૉગિન/આઉટ સમય, ચેક-ઇન/આઉટ સમય, મિટિંગ્સના સ્થાન, મુસાફરી કરેલી અંતર અને મિટિંગ્સના પરિણામોના નોંધપોથી સમાવેશ થાય છે.',
                ],
            },
            {
                title: 'ડેટા વિશ્લેષણ પર આધારિત રિપોર્ટ જનરેટ.',
                description: [
                    'માસિક ઇ-રિપોર્ટ્સની સબસ્ક્રાઇબ કરો અને ડાઉનલોડ કરો.',
                    'તમે જ્યારે ઇચ્છો ત્યારે માસિક ઇ-રિપોર્ટ્સ ડાઉનલોડ કરી શકો છો.',
                    'pdf અને excel ફાઇલ ફોર્મેટમાં ઉપલબ્ધ છે. એક સંપૂર્ણ રિપોર્ટનો સમૂહ તમને તમારી પાસે ઉપલબ્ધ હોય છે, જે એક કર્મચારીના સંસ્થાની બહારના પ્રવૃત્તિઓ પર વિગતવાર માહિતી પૂરી પાડે છે, જે મેન્યુઅલ રીતે કરવી મુશ્કેલ હોય છે.',
                ],
            },
        ],
    },
    locationBasedAttendance: {
        featureHeader: 'સ્થાન આધારિત હાજરી',
        featureDescription:
            'જીઓફેન્સ આધારિત હાજરી સુનિશ્ચિત કરે છે કે કર્મચારીઓ ફક્ત પ્રિડેફાઇન કરેલા ભૂગોળ સીમાઓની અંદર હોતી વખતે જ ક્લોક ઇન અથવા આઉટ કરી શકે છે.',
        featureCaption:
            'સ્થાન આધારિત હાજરી ચોકસાઈની ખાતરી આપે છે, જે કાર્યકર્તાઓની ઉપસ્થિતિ તેમની શારીરિક સ્થિતિની આધારિત રીતે મોનીટર અને રેકોર્ડ કરે છે.',
        keyPoints: [
            {
                header: 'જીઓફેન્સિંગ',
                paragraph:
                    'આફિસ પ્રાંગણના ખાસ સ્થળોની આસપાસ જીઓફેન્સ મર્યાદાઓ બનાવવી. કર્મચારી નિર્ધારિત વિસ્તરમાં પ્રવેશ કરવાથી અથવા છોડવાથી હાજરી આપમેળે રેકોર્ડ થાય છે.',
            },
            {
                header: 'પ્રમાણિકરણ અને સિક્યુરિટી',
                paragraph:
                    'PINs, બાયોમેટ્રિક, અથવા ફેશિયલ હાજરીનો ઉપયોગ કરીને હાજરી ડેટાની ચોકસાઈ અને સુરક્ષા સુનિશ્ચિત કરવા માટે પ્રમાણિકરણ પદ્ધતિઓ.',
            },
            {
                header: 'રિયલ-ટાઇમ અપડેટ્સ',
                paragraph:
                    'હાજરીના રેકોર્ડ તાત્કાલિક અપડેટ થાય તે સુનિશ્ચિત કરે છે, સમયસર વ્યવસ્થાપન નિર્ણયો માટે મદદરૂપ છે.',
            },
            {
                header: 'રિમોટ વર્ક અંગેના વિચાર',
                paragraph: 'રિમોટ કર્મચારીઓ માટે હાજરી ટ્રેકિંગ માટે વિકલ્પક પદ્ધતિઓ પર વિચાર કરો.',
            },
        ],
        softwareDetails: [
            {
                title: 'કર્મચારીઓનું સમય અને સ્થાન.',
                description: [
                    'જાણો તમારી કર્મચારીઓ ક્યારે અને ક્યાં કામ કરે છે.',
                    'તમારા કર્મચારીઓને તેમના મોબાઇલ ફોન પર મંજૂરી આપવાનો સૂચન કરો જેથી તે સમય અને સ્થાનને ટ્રેક કરી શકે.',
                    'એકવાર મંજૂરી આપવામાં આવે, ટ્રેકર ટૅપ સાથે કાર્યારંભ કરી શકે છે.',
                ],
            },
            {
                title: 'લાઇવ રિપોર્ટિંગ',
                description: [
                    'સ્થાન આધારિત કર્મચારી હાજરી સિસ્ટમ અમલમાં મૂકવી ચોકસાઈ સુધારી શકે છે અને હાજરી મેનેજમેન્ટને સરળ બનાવી શકે છે. પરંતુ, આ સિસ્ટમોના અમલ અને ઉપયોગ વિશે કર્મચારીઓની ગોપનીયતા બાબતો સાથે લાભોનો સંતુલન બનાવવું જરૂરી છે.',
                ],
            },
            {
                title: 'જીઓફેન્સ આધારિત ચેતવણી',
                description: [
                    'અસામાન્ય હાજરી પેટર્ન અથવા વિસંગતિઓ માટે આપમેળે ચેતવણીઓ સુયોજિત કરો. આટલું જ નહીં, સત્તાધિકૃત ચેક-ઇન્સ અથવા ચેક-આઉટ્સની ઓળખ કરવામાં મદદરૂપ છે.',
                    'હાજરી પેટર્ન, વલણો, અને અપવાદોની અંદર દૃષ્ટિ મેળવવા માટે રિપોર્ટિંગ અને વિશ્લેષણ ફીચર્સને અમલમાં મૂકો.',
                ],
                sDKeys: [
                    'મુલ્યવાન સાધનો જેમ કે વાહનો અથવા સાધનો નિર્ધારિત વિસ્તારોમાં પ્રવેશ કરે છે અથવા છોડે છે ત્યારે ચેતવણીઓ પ્રાપ્ત કરો, ચોરી અથવા સત્તાધિકૃત ઉપયોગ અટકાવવામાં મદદ કરે છે.',
                    'ફિલ્ડ કામદારની સુરક્ષાને સુનિશ્ચિત કરવા માટે ખતરનાક વિસ્તારો અથવા દૂરસ્થ કાર્યસ્થાનોની આસપાસ જીઓફેન્સ સેટ કરો, જ્યારે કર્મચારીઓ પ્રતિબંધિત ઝોનમાં પ્રવેશ કરે ત્યારે ચેતવણીઓ શરૂ થાય છે.',
                    'જ્યારે ડિવાઇસ જીઓફેન્સ ક્ષેત્રમાં પ્રવેશ કરે છે અથવા બહાર આવે છે ત્યારે સિસ્ટમ પૂર્વનિર્ધારિત ચેતવણીઓ અથવા સૂચનાઓ શરૂ કરે છે.',
                    'સિસ્ટમ વાસ્તવિક સમયમાં ટ્રેક કરેલા ઉપકરણોની સ્થિતિ સતત મોનિટર કરે છે અને જો તે કોઈ પણ વ્યાખ્યાયિત જીઓફેન્સ સાથે ચકાસે છે.',
                ],
            },
        ],
    },
    liveReporting: {
        featureHeader: 'લાઇવ રિપોર્ટિંગ',
        featureDescription:
            'હાજરી, પૂરા કરેલ કાર્ય અથવા પ્રોજેક્ટ પ્રગતિ જેવી વિવિધ પાસાઓનું વાસ્તવિક-સમય ટ્રેકિંગ અને વિશ્લેષણ.',
        featureCaption:
            'કર્મચારીઓના કાર્ય, મિટિંગ મિનિટ, મુલાકાતોની માન્યતા અને ડેટા આધારિત નિર્ણય લેવાનું માહિતીમાં રહેવું.',
        keyPoints: [
            {
                header: 'ઈવન્ટ આધારિત સૂચનાઓ',
                paragraph:
                    'એડમિન અથવા મેનેજર તે સૂચનાઓ માટે સબ્સ્ક્રાઇબ કરી શકે છે કે તેઓ કયા સૂચનાઓ પ્રાપ્ત કરવા માંગે છે. આને તેમને મળતી સૂચનાઓ પર ધ્યાન કેન્દ્રિત કરવામાં મદદ કરે છે.',
            },
            {
                header: 'હાજરી અને રજા વિશે જાણો',
                paragraph: 'આપણા આગળ રહો અને દિવસ માટે યોજના બનાવવું વધુ ઉત્પાદનશીલ અને અસરકારક બની શકે છે.',
            },
            {
                header: 'સમર્થનો ટ્રેક રાખો',
                paragraph: 'રિએમ્બર્સમેન્ટથી લઈ રજા સુધી તમામ મંજૂરતોને ટ્રેક કરો અને પછીથી જોવાં માટે.',
            },
            {
                header: 'ફોર્મ અને MOM સાથે અપડેટ રહેવું',
                paragraph:
                    'કસ્ટમ ફોર્મ્સ મિટિંગ માટે વિશિષ્ટ માહિતી મેળવવામાં મદદરૂપ હોઈ શકે છે જે રિમોટ રીતે ઍક્સેસ કરી શકાય છે.',
            },
        ],
        softwareDetails: [
            {
                title: 'ક્ષેત્ર બળને વાસ્તવિક-સમયમાં લોકેટ કરો.',
                description: [
                    'તમારા ક્ષેત્રના સ્ટાફને વાસ્તવિક-સમયમાં લોકેટ કરો.',
                    'તમારા ક્ષેત્ર કાર્યકરોની વાસ્તવિક-સમયમાં તાત્કાલિક સ્થાનની વિગતો મેળવો.',
                    'ટ્રિઆન્ટ તમને તમારા ક્ષેત્રના કર્મચારીઓને તેમના મોબાઇલ GPS નો ઉપયોગ કરીને ઓનલાઈન અને ઓફલાઇન બંનેમાં લોકેટ અને ટ્રેક કરવાની સુવિધા આપે છે. તમારા ક્ષેત્રના સ્ટાફની દરેક ગતિ પર અપડેટ રહેવું અને પારદર્શક કાર્ય પરિસ્થિતિ જાળવો.',
                ],
            },
            {
                title: 'તમારા કર્મચારી ક્ષેત્ર માહિતી સાથે અપડેટ રહો.',
                description: [
                    'તમારા કર્મચારીઓની ક્ષેત્ર માહિતી સાથે અપડેટ રહો.',
                    'ફિલ્ડ સ્ટાફની આફિસ માહિતી તમારા ડેશબોર્ડ સાથે આપમેળે સિંક્ડ થાય છે.',
                    'તમારા કર્મચારીઓની વિગતો જેમ કે બેટરી શતક, GPS, ઇન્ટરનેટ ઓન/ઓફ સમયની દેખાવતા, ideal સમય જે તેઓ ફિલ્ડ પર વિતાવે છે તે તાત્કાલિક વાસ્તવિક-સમયમાં તમને સૂચિત કરવામાં આવશે.',
                ],
            },
            {
                title: 'કર્મચારીઓની રિએમ્બર્સમેન્ટ અને ભથ્થા ડેટાને સ્વચાલિત કરો.',
                description: [
                    'સચોટ ડેટા માટે AI પાવર્ડ લોકેશન ઇતિહાસ.',
                    'તમારા કર્મચારીઓના સ્થાન ડેટા કૃત્રિમ બુદ્ધિ દ્વારા પાવર્ડ રહેશે જેથી સચોટ માર્ગ અને ટ્રેકિંગ માહિતી મળે. લાઇવ ટ્રેકિંગ ડેટા અને અન્ય લોકેશન ઇતિહાસને નિયમિત અંતરાલે તમારા મેઇલબોક્સ પર આપમેળે મોકલવામાં આવશે જેથી ક્ષેત્ર પર અને ક્ષેત્ર બહાર શું થઈ રહ્યું છે તે જાણી શકાય.',
                ],
            },
        ],
    },
    aIBasedOptimization: {
        featureHeader: 'AI આધારિત ઓપ્ટિમાઇઝેશન',
        featureDescription:
            'ફિલ્ડ પ્રતિનિધિઓ અથવા વેચાણના કર્મચારીઓ માટે માર્ગોને AI બીટ રુટના આધારે ઓપ્ટિમાઇઝ અને સ્વચાલિત કરો.',
        featureCaption:
            'આજના માટેના માર્ગોને ઓપ્ટિમાઇઝ કરો અને અમારી AI આધારિત આલ્ગોરિધમ સાથે તમારી અગાઉની સિઝનની સાથે અહેવાલોની તુલના કરો.',
        keyPoints: [
            {
                header: 'વાસ્તવિક-સમય ડેટા ઇન્ટિગ્રેશન',
                paragraph:
                    'વાસ્તવિક-સમયના ડેટા સ્રોતો, જેમ કે જીવંત ટ્રાફિક અપડેટ્સ, હવામાનની શરતો અને રોડ બંધ થયા છે, જેને વર્તમાન પરિસ્થિતિઓને આધારે માર્ગોને ગતિશીલ રીતે સંશોધિત કરવા માટે ઉપયોગ કરો, જે દિવસની યોજના માટે સહાયક છે.',
            },
            {
                header: 'માર્ગ યોજના માટેનો યૂઝર ઇન્ટરફેસ',
                paragraph:
                    'ઓપ્ટિમાઇઝ કરેલા માર્ગોને જુઓ અને કોઈ જરૂરી ફેરફાર કરો. આ મોબાઇલ એપ્લિકેશન અથવા વેબ આધારિત પ્લેટફોર્મનો ભાગ હોઈ શકે છે.',
            },
            {
                header: 'ઉત્પાદકતા મેટ્રિક્સ',
                paragraph: 'ભિન્ન બીટ્સ અથવા માર્ગોની વિશિષ્ટ વિશેષતાઓને ધ્યાનમાં રાખવા માટે AI સિસ્ટમ.',
            },
            {
                header: 'મેપિંગ અને ભૂસ્તરિય ડેટા',
                paragraph:
                    'સ道路 નેટવર્ક, ટ્રાફિકની શરતો, અને અન્ય સ્થાન સંબંધિત માહિતી સમજવા માટે મેપિંગ સેવાઓ અને ભૂસ્તરિય ડેટાનો ઉપયોગ કરો.',
            },
        ],
        softwareDetails: [
            {
                title: 'વાસ્તવિક-સમય અપડેટ સાથે માર્ગ ઓપ્ટિમાઇઝેશન',
                description: [
                    'વ્યક્તિઓ અથવા વાહનો માટે સૌથી કાર્યક્ષમ માર્ગોને ઓપ્ટિમાઇઝ અને યોજના બનાવવી, જેમ કે ડિલિવરી ટ્રક્સ, વેચાણના પ્રતિનિધિઓ, અથવા ક્ષેત્ર સેવા ટેકનિકસ, જેમણે તેમના નિયમિત પ્રવૃત્તિઓના ભાગ રૂપે ચોક્કસ બીટ્સ અથવા માર્ગોને અનુસરે છે.',
                    'આ એપ્લિકેશન કાર્યક્ષમતા સુધારવામાં, મુસાફરીનો સમય ઘટાડવામાં અને ઓપરેશનલ ખર્ચ ઓછું કરવામાં મદદ કરી શકે છે.',
                ],
                sDKeys: [
                    'GPS ઉપકરણો, ટ્રાફિક અહેવાલો, હવામાન અપડેટ્સ, અને વાહન સેન્સર સહિત વિવિધ સ્ત્રોતો પાસેથી ડેટા એકત્રિત કરવું.',
                    'ફાસ્ટર પ્રતિસાદ સમય અને સમયસર આગમન ગ્રાહક સંતોષ વધારવામાં મદદ કરે છે, જે સેવા-ઓરિએન્ટેડ બિઝનેસ માટે મહત્વપૂર્ણ છે.',
                ],
            },
            {
                title: 'પ્રાઇવસી સમસ્યાઓ અને પાલનનો સાવચેત વિચાર આવશ્યક',
                description: [
                    'સુનિશ્ચિત કરો કે AI આધારિત સિસ્ટમ સ્થાનિક નિયમો, ખાસ કરીને ગોપનીયતા, ડેટા સુરક્ષા, અને પરિવહન કાયદા સંબંધિત નિયમો સાથે પાલન કરે છે.',
                    'નવા ડેટા અને પ્રતિસાદના આધારે AI અલ્ગોરિધમોને નિયમિત રીતે અપડેટ અને સુધારો, બીટ માર્ગોની સતત ઓપ્ટિમાઇઝેશન માટે લક્ષ્ય.',
                ],
            },
            {
                title: 'માર્ગ યોજના કાર્યક્ષમતામાં અન્ય સિસ્ટમો સાથે સંકલન',
                description: [
                    'એતિહાસિક ડેટા વિશ્લેષણ કરવા અને ભવિષ્યના પેટર્નનું અનુમાન કરવા માટે મશીન લર્નિંગ અલ્ગોરિધમનો ઉપયોગ કરો.',
                    'આ ટ્રાફિકની શરતો, ડિલિવરી સમય, અને અન્ય પરિબળોને અનુમાન કરવામાં મદદ કરી શકે છે જે માર્ગ યોજના પર અસર કરે છે.',
                ],
            },
        ],
    },
    leadManagement: {
        featureHeader: 'લીડ મેનેજમેન્ટ',
        featureDescription:
            'કર્મચારી હાજરીના ડેટા, કાર્ય કલાકો, ગેરહાજરી, મોડા આવનારા, અને વહેલા છૂટા થવાની વિગતો વિશિષ્ટ સમયસીમાની અંદર સંક્ષિપ્ત કરી શકાય છે.',
        featureCaption:
            'વિભિન્ન શાખાઓ માટે ઓટોમેટેડ અહેવાલો જનરેટ કરો અને 40+ થી વધુ ટેમ્પ્લેટ્સ સાથે દૈનિક, સাপ্তાહિક અથવા માસિક પ્રાપ્ત કરો.',
        keyPoints: [
            {
                header: 'હાજરીની વિગતો ઓટોમેટ કરો: લોગ-ઇન સમય, મોડા સમય, કુલ કાર્ય કલાકો, કુલ સત્રો',
                paragraph:
                    'હાજરીનો સમાન સારાંશ પ્રદાન કરો, જેમાં કુલ કાર્યદિવસો, કુલ કલાકો કામ કર્યા અને કોઈ પણ ઓવરટાઇમ કલાકો શામેલ છે.',
            },
            {
                header: 'બહુવિધ શાખાઓના અહેવાલો',
                paragraph: 'કસ્ટમાઇઝેબલ શ્રેણીઓ સાથે ઘણા શાખાઓના હાજરી અહેવાલો દુરસંચારથી ઍક્સેસ કરો.',
            },
            {
                header: 'ઓવરટાઇમ અને છૂટ્ટા બેલેન્સ',
                paragraph:
                    'પ્રત્યેક કર્મચારી માટે છૂટ્ટા બેલેન્સ બતાવો, જેમાં વેકેશન દિવસો, બીમારીની છૂટ्टी, અને અન્ય પ્રકારની છૂટ્ટી શામેલ છે.',
            },
            {
                header: 'કસ્ટમાઇઝ્ડ હાજરી અહેવાલ',
                paragraph:
                    'પ્રયોગકર્તાઓને કસ્ટમાઇઝેબલ અહેવાલો પસંદ કરવાની મંજૂરી આપે છે, જેમ કે વિભાગ, ટીમ, અથવા વ્યક્તિગત કર્મચારી આધારિત.',
            },
        ],
        softwareDetails: [
            {
                title: 'તમારા સંસ્થાની રચના આધારિત અહેવાલો જનરેટ કરો.',
                description: [
                    'નિયમિત રીતે જનરેટ અને વિશ્લેષણ કરવામાં આવતા હાજરી અહેવાલો કાર્યશક્તિ વ્યવસ્થાપન સુધારવા, વણજાતીય લક્ષણોને ઓળખવા, અને સંસ્થાના નીતિઓ સાથે પાલન સુનિશ્ચિત કરવા માટે મૂલ્યવાન દૃષ્ટિકોણ પ્રદાન કરી શકે છે.',
                    'અહેવાલની વિશિષ્ટતાઓ સંસ્થાની જરૂરિયાતો અને રચના પર આધારિત રહેશે.',
                ],
            },
            {
                title: 'કસ્ટમાઇઝેબલ અહેવાલો સરળતાથી મેળવો.',
                description: [
                    'અધિકૃત ગેરહાજરીઓ, અતિશય મોડા, અથવા મંજૂરી વિના ઓવરટાઇમના ઉદાહરણોને ઓળખો અને હાઇલાઇટ કરો.',
                    'હાજરીની વલણો અને પેટર્નને визуલી રિપ્રેઝન્ટ કરવા માટે ચાર્ટ અથવા ગ્રાફનો ઉપયોગ કરો. આથી આઉટલાયર્સ અને વલણોને સરળતાથી ઓળખી શકાય છે.',
                ],
            },
            {
                title: 'મેન્યુઅલ અહેવાલો પર તમારું સમય ઓછું કરો.',
                description: [
                    'કર્મચારીના નામો, ઓળખ નંબરો, અને વિભાગની જોડણીઓ જેવી મૂળભૂત વિગતો સમાવિષ્ટ છે, જે હાજરીના ડેટાને સંદર્ભ આપે છે.',
                    'અહેવાલ દ્વારા આવરી લેવામાં આવેલા તારીખ અને સમયની શ્રેણી સ્પષ્ટ રીતે નિર્દેશિત કરવામાં આવે છે. આથી ઉપયોગકર્તાઓ ડેટાના સમયમર્યાદાની સમજણ પ્રાપ્ત કરે છે.',
                    'હાજરીનો સમગ્ર સારાંશ પ્રદાન કરવો, જેમાં કુલ કાર્યદિવસો, કુલ કલાકો કામ કર્યા, અને કોઈ પણ ઓવરટાઇમ કલાકો શામેલ છે.',
                ],
            },
        ],
    },
    taskManagement: {
        featureHeader: 'ટાસ્ક મેનેજમેન્ટ',
        featureDescription: 'કર્મચારીઓની પ્રવૃતિઓ, ઉત્પાદકતા, અને હાજરીનું સિસ્તમેટિક મોનિટરિંગ અને મેનેજમેન્ટ.',
        featureParagraph:
            'હાજરી તપાસવા માટે હવે વધુ સમયકાર્ડ્સ, વધારાની પેપરવર્ક, અને વિવિધ દસ્તાવેજોની જરૂર નથી. WebWork Time Tracker સાથે હાજરી મોનિટરિંગ પ્રક્રિયાને ઓટોમેટ કરો અને તમારા કર્મચારીઓના કાર્યકાળની શરૂઆત અને અંતને એક સારી રીતે સંયોજિત ફોર્મેટમાં જુઓ.',
        featureCaption:
            'કર્મચારીની હાજરી, કાર્ય કલાકો, ઉત્પાદકતા, અને કાર્યક્ષમતા સહિત સંસ્થાની કાર્યબળના વિવિધ પાસાંને મોનિટર અને મેનેજ કરો.',
        keyPoints: [
            {
                header: 'વાસ્તવિક સમયમાં મોનિટર કરો',
                paragraph:
                    'કાર્ય બોર્ડ જોવા, કાર્યની સ્થિતિ તપાસી, અને ખર્ચેલા સમયને ટ્રેક કરીને વાસ્તવિક સમયમાં પ્રગતિનું મોનિટર કરો.',
            },
            {
                header: 'તમારી તમામ વર્કફ્લોઝને એક જ જગ્યાએ મોનિટર કરો',
                paragraph:
                    'ટાસ્ક મેનેજમેન્ટ સહયોગી ફીચર્સ ટીમના સભ્યો સાથે વાસ્તવિક સમયમાં સંચાર માટે. ટાસ્ક પર કોમેન્ટ કરો, પ્રશ્નો પૂછો, અને અપડેટ્સ આપો જેથી દરેકને માહિતી મળી રહે.',
            },
            {
                header: 'પ્રદર્શકતા મોનિટર કરો',
                paragraph:
                    'ઉત્પાદકતાને ટ્રેક કરવા માટે ગ્રાહક અને લીડ મેનેજમેન્ટ. કર્મચારીઓની ઉત્પાદકતા અને મુખ્ય કાર્યક્ષમતા સૂચકાંકોના આધાર પર માપવામાં આવી શકે છે.',
            },
            {
                header: 'લક્ષ્યોને સ્ટાન્ડર્ડ બનાવો',
                paragraph:
                    'લક્ષ્યોને સંગ્રહિત કરવા માટે હિયારાર્કીકલ રચના: પ્રોજેક્ટ લક્ષ્યો, વિભાગીય લક્ષ્યો, વ્યક્તિગત કાર્યક્ષમતા લક્ષ્યો, વ્યૂહાત્મક પહેલો, વગેરે.',
            },
        ],
        softwareDetails: [
            {
                title: 'કામના કલાકોને મોનિટર કરવા માટે સમય મોનિટરિંગ સિસ્ટમને લાગુ કરો.',
                description: [
                    'કર્મચારીઓએ કાર્ય અને પ્રોજેક્ટ્સ પર વ્યય કરેલા સમયને મોનિટર કરવા માટે સમય મોનિટરિંગ સોલ્યુશન્સ લાગુ કરવું.',
                    'આને સાધનોનો ઉપયોગ કરીને સંસાધનોની ઉપયોગિતા સમજવામાં અને સમય મેનેજમેન્ટ સુધારવામાં મદદ કરી શકે છે.',
                    'પ્રોજેક્ટ મેનેજમેન્ટ સિસ્ટમનો ઉપયોગ કરીને પ્રોજેક્ટની પ્રગતિ ટ્રેક કરો, કાર્ય સોંપો, અને મર્યાદાઓને મોનિટર કરો.',
                    'આ પ્રોજેક્ટ વર્કફ્લોઝને આప్టિમાઇઝ કરવામાં અને સમયસર પૂર્ણતાને સુનિશ્ચિત કરવામાં મદદ કરે છે.',
                ],
            },
            {
                title: 'સંબંધિત મુખ્ય કાર્યક્ષમતા સૂચકાંકોને મોનિટર કરો.',
                description: [
                    'તમારા સંસ્થાની લક્ષ્યોને અનુરૂપ મુખ્ય કાર્યક્ષમતા સૂચકાંકોની વ્યાખ્યા અને મોનિટરિંગ.',
                    'આમાં વેચાણ લક્ષ્યો, ગ્રાહક સંતોષ સૂચકાંકો, અથવા અન્ય કાર્યક્ષમતા સૂચકાંકો શામેલ હોઈ શકે છે.',
                ],
            },
            {
                title: 'તમારા કાર્યબળના વલણોના અહેવાલો જનરેટ કરો.',
                description: [
                    'કાર્યબળના વલણો, પ્રદર્શકતા, અને સુધારાના ક્ષેત્રો માટે દૃષ્ટિ પ્રાપ્ત કરવા માટે વિશ્લેષણ અને અહેવાલીંગ ફીચર્સને એકત્રિત કરવી.',
                    'કસ્ટમાઇઝેબલ અહેવાલો નિર્ણાયક પ્રક્રિયામાં મદદ કરી શકે છે.',
                ],
            },
        ],
    },
    salesPipelineMonitoring: {
        featureHeader: 'સેલ્સ પાઈપલાઇન મોનિટરિંગ',
        featureDescription:
            'ફીલ્ડ પર સેલ્સ ટીમના મુખ્ય મેટ્રિક્સને ટ્રેક અને વિશ્લેષણ કરો, જેમ કે વેચાણ આવક, ગ્રાહક મીટિંગ્સ, અને ભૂગોળને આધારિત પ્રવૃત્તિઓ.',
        featureParagraph:
            'હાજરી તપાસવા માટે હવે વધુ સમયકાર્ડ્સ, વધારાની પેપરવર્ક, અને વિવિધ દસ્તાવેજોની જરૂર નથી. WebWork Time Tracker સાથે હાજરી મોનિટરિંગ પ્રક્રિયાને ઓટોમેટ કરો અને તમારા કર્મચારીઓના કાર્યકાળની શરૂઆત અને અંતને એક સારી રીતે સંયોજિત ફોર્મેટમાં જુઓ.',
        featureCaption:
            'ફીલ્ડ સેલ્સ પ્રવૃત્તિઓ સાથે સંકળાયેલા ડેટાની એકત્રિત, વિશ્લેષણ, અને વ્યાખ્યા દ્વારા洞જ્ઞાન પ્રાપ્ત કરો અને જાણકારીયુક્ત નિર્ણયો લો.',
        keyPoints: [
            {
                header: 'ઓટોમેટેડ વર્કફ્લોઝ',
                paragraph:
                    'લીડ કૅપ્ચર, લાયકાત, પોષણ, પાઈપલાઇન મેનેજમેન્ટ, પ્રવૃત્તિ ટ્રેકિંગ, અને અહેવાલ જેવા સેલ્સ પ્રક્રિયાના વિવિધ તબકકાઓને સરળ બનાવવા અને ઓટોમેટ કરવા માટે સિસ્ટમનો ઉપયોગ કરો.',
            },
            {
                header: 'સીએમઆર સાથે એકીકરણ',
                paragraph:
                    'લીડ મેનેજમેન્ટ, સંપર્ક ટ્રેકિંગ, ડીલ મોનિટરિંગ, અને બે પ્લેટફોર્મ વચ્ચે ડેટા સમંકલનને સરળ બનાવો.',
            },
            {
                header: 'સહયોગ અને સંચાર સુલભ બનાવવો',
                paragraph:
                    'કેન્દ્રિત સંચાર ચેનલ્સ, સેલ્સ મીટિંગ્સની યોજના બનાવવી, સહયોગી ડીલ સમીક્ષાઓ, પ્રક્રિયાઓને દસ્તાવેજ બનાવવી, ક્રોસ-ફંક્શનલ સહયોગ, ખુલ્લું સંચાર પ્રોત્સાહિત કરવું.',
            },
            {
                header: 'ભવિષ્યની સેલ્સ કાર્યક્ષમતાની洞જ્ઞાન',
                paragraph: 'ભવિષ્યના પરિણામો અને વલણો અનુમાનિત કરવા માટે ડેટા વિશ્લેષણ અને આગાહી મોડેલિંગનો ઉપયોગ કરો.',
            },
        ],
        softwareDetails: [
            {
                title: 'સારો સેલ્સ પ્રદર્શન માટે દરરોજ ફીલ્ડ洞જ્ઞાન',
                description: [
                    'ડેટા વિશ્લેષણ આધારિત સમકાલિન ઇ-અહેવાલ જનરેશન. તમારા સરળ ઍક્સેસ અને ઉપયોગ માટે વિશ્વસનીય અને યુઝર-ફ્રેન્ડલી ગો-ટુ અહેવાલ ફીચર.',
                    'એક સેલ્સ મેનેજર તરીકે, તમે વિવિધ અહેવાલો પર સ્પષ્ટ નજર રાખશો જે તમારા મેલ સરનામા પર મોકલવામાં આવશે અથવા તમે તમારા ડેશબોર્ડ洞જ્ઞાનમાં પણ જોઈ શકો છો.',
                    'કેટલાક નોંધપાત્ર અહેવાલો માસિક અહેવાલો, શાખા સ્થાન-વિશિષ્ટ અહેવાલો, કર્મચારી-વિશિષ્ટ અહેવાલો વગેરે છે.',
                ],
            },
            {
                title: 'ફીલ્ડ_ATTENDANCE પર સર્વગ્રાહી દૃષ્ટિ',
                description: [
                    'કર્મચારીની ફીલ્ડ હાજરી અને ફીલ્ડ મુલાકાતો પર સર્વગ્રાહી દૃષ્ટિ.',
                    'ફીલ્ડ વર્કફોર્સની હાજરી અને મીટિંગ્સ પર વ્યાપક દૃષ્ટિ મેળવો.',
                    'ફીલ્ડ હાજરી અહેવાલમાં, તમને ફીલ્ડ હાજરી માર્કિંગ સમય, સ્થાન, મોડું હાજરી સમય, કાર્યકાળ, વગેરે વિશે ડેટા મળશે.',
                    'તે જ રીતે, ફીલ્ડ મુલાકાત ચેક-ઇન/આઉટ અહેવાલોમાં મીટિંગનો સમય, ચેક-ઇન/આઉટ સમય, મીટિંગોનું સ્થાન, જવામાં આવેલા અંતર, મીટિંગ પરિણામ નોંધો, વગેરે જેવા ફીલ્ડ મુલાકાતો સાથે સંકળાયેલા ડેટા શામેલ છે.',
                ],
            },
            {
                title: 'ડેટા વિશ્લેષણ આધારિત અહેવાલ જનરેશન',
                description: [
                    'માસિક ઇ-અહેવાલ્સને સબ્સ્ક્રાઈબ કરો અને ડાઉનલોડ કરો.',
                    'તમે તમારી ઇચ્છા પ્રમાણે માસિક ઇ-અહેવાલ્સને સબ્સ્ક્રાઈબ કરી અને ડાઉનલોડ કરી શકો છો.',
                    'PDF અને Excel ફાઈલ ફોર્મેટમાં ઉપલબ્ધ. એક સંપૂર્ણ સ્ટેક અહેવાલો જે તમને સંસ્થાની બહારના કર્મચારીની ક્રિયાઓ વિશે વિગતવાર માહિતી આપે છે, જે મેન્યુઅલી એક કઠિન કાર્ય હોય છે.',
                ],
            },
        ],
    },
    workflowAutomation: {
        featureHeader: 'વર્કફ્લો ઓટોમેશન',
        featureDescription:
            'ભૂગોળ પર આધારિત હાજરી સુનિશ્ચિત કરવા માટે, જેથી કર્મચારીઓ માત્ર તે જગ્યામાં હાજરી લાવવામાં આવી શકે જ્યાં તેઓ પૂર્વ નિર્ધારિત ભૂગોળની સીમાઓમાં હોય.',
        featureCaption:
            'સ્થાન આધારિત હાજરી ચોકસાઈ સાથે કર્મચારીઓની હાજરીને મોનિટર અને રેકોર્ડ કરે છે તેમના શારીરિક સ્થાનના આધારે.',
        keyPoints: [
            {
                header: 'ઓટોમેટેડ ટાસ્ક અસાઇનમેન્ટ',
                paragraph:
                    'વર્કફ્લો ઓટોમેશન ટાસ્કોને સૌથી યોગ્ય વ્યક્તિઓને આપમેળે અસાઇન કરવા માટે, જેમાં સ્કિલ સેટ્સ, ઉપલબ્ધતા, વર્કલોડ, અને પ્રાથમિકતા સ્તરો જેવા因素ોનો વિચાર કરવામાં આવે છે.',
            },
            {
                header: 'લીડ રાઉટિંગ, લાયકાત અને સ્કોરિંગ',
                paragraph:
                    'આસપાસના સેલ્સ પ્રતિનિધિઓનેincoming leadsને આપમેળે અસાઇન કરવા માટે, તેમની ગુણવત્તાને મૂલવવા માટે, અને અનુસરણની ક્રિયાઓને પ્રાથમિકતા આપવાનો લીડ રાઉટિંગ, લાયકાત અને સ્કોરિંગ.',
            },
            {
                header: 'બાહ્ય સિસ્ટમો સાથે એકીકરણ',
                paragraph:
                    'સહયોગને સુલભ બનાવવું, ડેટાની ચોકસાઈ વધારવી, અને વિવિધ બિઝનેસ કાર્યોમાં સુગમ વર્કફ્લો સુનિશ્ચિત કરે છે.',
            },
            {
                header: 'પ્રદર્શન મોનિટરિંગ અને અહેવાલ',
                paragraph:
                    'પ્રક્રિયાની પૂર્ણતાના સમય, ભૂલ દર, સ્રોતોના ઉપયોગ, અને કુલ કાર્યક્ષમતા જેવા મેટ્રિક્સને મોનિટર કરો.',
            },
        ],
        softwareDetails: [
            {
                title: 'શક્તિશાળી કાર્યકરોનો સમય અને સ્થાન.',
                description: [
                    'જાણો ક્યારે અને ક્યાં તમારા કર્મચારીઓ કામ કરે છે.',
                    'તમારા કર્મચારીઓને તેમના મોબાઈલ ફોન પર પરવાનગી આપવાની સૂચના આપો જેથી તે સમય અને સ્થાનને ટ્રેક કરી શકે.',
                    'પરવાનગી આપવામાં આવતા પછી, ટ્રેકર ટૅપ સાથે કાર્ય કરવા લાગશે.',
                ],
            },
            {
                title: 'લાઈવ રિપોર્ટિંગ ટ્વો',
                description: [
                    'સ્થાન આધારિત કર્મચારી હાજરી સિસ્ટમ અમલમાં લાવવાથી ચોકસાઈ સુધારી શકાય છે અને હાજરી મેનેજમેન્ટને સરળ બનાવી શકાય છે. તેમ છતાં, કર્મચારીની ખાનગી રીતે સંતુલન બાનવવું મહત્વપૂર્ણ છે અને અમલ અને ઉપયોગ અંગે ખુલ્લું સંચાર કરવું જરૂરી છે.',
                ],
            },
            {
                title: 'જિઓ ફેન્સ આધારિત એલર્ટ',
                description: [
                    'અસામાન્ય હાજરી પેટર્ન અથવા વિસંગતિઓ માટે ઓટોમેટેડ એલર્ટ સેટ કરો. આથી, અનધિકૃત ચેક-ઇન્સ અથવા ચેક-આઉટ્સ જેવી શક્ય સમસ્યાઓ ઓળખવામાં મદદ કરે છે.',
                    'હાજરી પેટર્ન, વલણો, અને અપવાદો વિશે洞જ્ઞાન મેળવવા માટે રિપોર્ટિંગ અને વિશ્લેષણ ફીચર્સને અમલમાં લાવો.',
                ],
            },
        ],
    },
    aIBasedAssistance: {
        featureHeader: 'એઆઈ આધારિત સહાય',
        featureDescription:
            'કર્મચારીઓના કાર્યના વિવિધ પાસાઓનું રિયલ-ટાઇમ ટ્રેકિંગ અને વિશ્લેષણ, જેમ કે હાજરી, પૂર્ણ થયેલા ટાસ્ક અથવા પ્રોજેક્ટ પ્રગતિ.',
        featureCaption:
            'કર્મચારીઓના ટાસ્ક, મીટીંગ્સના મિનિટ્સ, મુલાકાતોને માન્ય બનાવવું, અને ડેટાના આધાર પર નિર્ણય લો.',
        keyPoints: [
            {
                header: 'ઇવેન્ટ આધારિત સૂચનાઓ',
                paragraph:
                    'એડમિન અથવા મેનેજર તે સૂચનાઓ માટે સબ્સ્ક્રાઇબ અથવા વિકલ્પ પસંદ કરી શકે છે જે તેઓ પ્રાપ્ત કરવા માંગે છે. આ રીતે, તે સૂચનાઓ પર ધ્યાન કેન્દ્રિત કરવામાં મદદ મળશે જે તેઓ ખરેખર મેળવવા માંગે છે.',
            },
            {
                header: 'હાજરી અને રજા વિશે સૂચિત રહો',
                paragraph:
                    'હાજરી અને રજા વિશે સૂચિત રહો. આગળ એક પગલાં આગળ રહો અને દિવસ માટેની યોજના વધારે ઉત્પાદનશીલ અને અસરકારક બનાવવો માટે કેપની ગણના જાણો.',
            },
            {
                header: 'સર્વ અનુમતિઓ માટે ટ્રેક રાખો',
                paragraph: 'સર્વ અનુમતિઓને ટ્રેક કરો. પ્રતિસાધનથી રજા સુધીની તમામ અનુમતિઓને ટ્રેક કરો અને તે પછી જોશો.',
            },
            {
                header: 'ફોર્મ્સ અને MOM સાથે અપડેટ રહો',
                paragraph:
                    'ફોર્મ્સ અને મીનીટ્સ ઓફ મીટિંગ દ્વારા રીમોટ ટ્રેકિંગ. કસ્ટમ ફોર્મ્સ વિશિષ્ટ માહિતી મેળવા માટે મદદ કરી શકે છે જે મીટિંગ માટે ઉપયોગી છે જે રીમોટલી ઍક્સેસ કરી શકાય છે.',
            },
        ],
        softwareDetails: [
            {
                title: 'ફીલ્ડ ફોર્સને રિયલ ટાઇમમાં ટ્રેક કરો.',
                description: [
                    'તમારા ફીલ્ડ સ્ટાફને રિયલ ટાઇમમાં ટ્રેક કરો',
                    'જેમ જેમ તેઓ મુસાફરી કરે છે તેમ તેમ તમારા ફીલ્ડ વર્કફોર્સની તાત્કાલિક સ્થાન વિગતો મેળવો.',
                    'ટ્રેન્ટ તમને તમારા ફીલ્ડ કર્મચારીઓને તેમના મોબાઇલ GPS નો ઉપયોગ કરીને ઓનલાઇન અને ઓફલાઇન બંને પર ટ્રેક કરવા સક્ષમ બનાવે છે. તમારા ફીલ્ડ સ્ટાફની દરેક ખસેડ પર અપડેટ રહેવું અને પારદર્શક કાર્યના વાતાવરણને જાળવવું.',
                ],
            },
            {
                title: 'તમારા કર્મચારી ફીલ્ડ માહિતી સાથે અપડેટ રહો',
                description: [
                    'તમારા કર્મચારીઓની ફીલ્ડ માહિતી સાથે અપડેટ રહો',
                    'ફીલ્ડ સ્ટાફની ફીલ્ડ માહિતી આપોઆપ તમારા ડેશબોર્ડ સાથે સિંક થઈ જશે.',
                    'તમારા કર્મચારીઓની વિગતો જેમ કે બેટરી ટકાવારી, GPS, ઇન્ટરનેટ ચાલુ/બંધ સમય વિઝિબિલિટી, અને તે ફીલ્ડ પર પસાર કરેલ સમય તમે તાત્કાલિક રીતે અપડેટ થશે.',
                ],
            },
            {
                title: 'કર્મચારીના વળતરો અને ભથ્થા માહિતી આપમેળે બનાવો.',
                description: [
                    'વિશિષ્ટ ડેટા માટે એઆઈ આધારિત લોકેશન હિસ્ટ્રી',
                    'તમારા કર્મચારીઓના લોકેશન ડેટા એઆઈ દ્વારા શક્તિશાળી રહેશે જેથી યોગ્ય માર્ગ અને ટ્રેકિંગ માહિતી મળે. જીવંત ટ્રેકિંગ ડેટા અને અન્ય લોકેશન હિસ્ટોરીઝ નિયમિત અંતરાલ પર તમારા મેઇલબૉક્સમાં આપમેળે મોકલવામાં આવશે જે કામ પર અને ઓફિસમાંથી શું થઈ રહ્યું છે તેની વધુ બાજુથી જોઈ શકાય છે.',
                ],
            },
        ],
    },
    digitizationOfOrder: {
        featureHeader: 'ઓર્ડરનો ડિજિટાઇઝેશન',
        featureDescription:
            'કર્મચારીઓના કાર્યના વિવિધ પાસાઓનું રિયલ-ટાઇમ ટ્રેકિંગ અને વિશ્લેષણ, જેમ કે હાજરી, પૂર્ણ થયેલા ટાસ્ક અથવા પ્રોજેક્ટ પ્રગતિ.',
        featureCaption: 'ઓર્ડર મેનેજમેન્ટને પૂર્ણ રીતે સરળ બનાવો અને મેન્યુઅલ ભૂલો ઘટાડો',
        keyPoints: [
            {
                header: 'રિપોર્ટિંગ અને એનાલિટિક્સ',
                paragraph:
                    'ઓર્ડર પ્રોસેસિંગની કામગીરી અને કર્મચારીની ઉત્પાદનક્ષમતા મોનિટર કરવા માટે વિગતવાર રિપોર્ટ્સ અને એનાલિટિક્સ જનરેટ કરો.',
            },
            {
                header: 'ઓટોમેટેડ ઓર્ડર એન્ટ્રી',
                paragraph:
                    'કર્મચારીઓ અથવા ગ્રાહકો દ્વારા દાખલ કરવામાં આવેલા ઓર્ડર વિગતોને આપમેળે કેચ અને માન્ય બનાવવું.',
            },
            {
                header: 'રિયલ-ટાઇમ ઓર્ડર ટ્રેકિંગ અને સ્થિતિ અપડેટ્સ',
                paragraph:
                    'ઓર્ડરની સ્થિતિ અને શિપમેન્ટ ટ્રેકિંગ વિશે રિયલ-ટાઇમ અપડેટ્સ કર્મચારીઓ અને ગ્રાહકોને પ્રદાન કરો.',
            },
            {
                header: 'ઓટોમેટેડ વર્કફ્લો અને ટાસ્ક અસાઇનમેન્ટ',
                paragraph: 'ઓર્ડર માન્યતા પ્રક્રિયાઓને ઓટોમેટ કરો અને યોગ્ય કર્મચારીઓને ટાસ્ક અસાઇન કરો.',
            },
        ],
    },
    targetManagement: {
        featureHeader: 'લક્ષ્ય મેનેજમેન્ટ',
        featureDescription:
            'કર્મચારીઓના કાર્યના વિવિધ પાસાઓનું રિયલ-ટાઇમ ટ્રેકિંગ અને વિશ્લેષણ, જેમ કે હાજરી, પૂર્ણ થયેલા ટાસ્ક અથવા પ્રોજેક્ટ પ્રગતિ.',
        featureCaption:
            'કર્મચારીઓના ટાસ્ક, મીટિંગની મિનિટો, મુલાકાતો માન્ય કરવી અને ડેટા આધારિત નિર્ણયો લેવા માટે જાણકારી પ્રાપ્ત કરો.',
        keyPoints: [
            {
                header: 'ફીલ્ડ ટ્રેકિંગ સાથે સંકલન',
                paragraph:
                    'દૈનિક, અઠવાડિક અને માસિક લક્ષ્યો પ્રાપ્ત કરો અને જીવંત ફીલ્ડ ટ્રેકિંગ સાથે કર્મચારી સિદ્ધિઓનો રેકોર્ડ રાખો.',
            },
            {
                header: 'રિયલ-ટાઇમ અપડેટ્સ અને સિંક્સ',
                paragraph:
                    'રિયલ-ટાઇમ પ્રગતિ (હાંલાકી લક્ષ્યોની સંખ્યા વિરૂદ્ધ બાકી), ચેક-ઇન/ચેક-આઉટ સમય, દરેક કાર્ય પર ખપાવવામાં આવેલ કલાકોની સંખ્યા (ટકાવારી), અને સ્થાન ટ્રેકિંગ.',
            },
            {
                header: 'કાર્યક્ષમતા અને એનાલિટિક્સ',
                paragraph:
                    'કાર્યક્ષમતા મેટ્રિક્સ (પરિપૂર્ણતા દર, સમય લાગ્યો, ગ્રાહક સંતોષ સ્કોર), લક્ષ્યો વિરૂદ્ધ તુલના (અસલ કાર્યક્ષમતા vs. લક્ષ્ય), ઐતિહાસિક ડેટા (ભૂતકાળની કાર્યક્ષમતા प्रवृत्तિઓ).',
            },
            {
                header: 'ઓટોમેટેડ નોટિફિકેશન્સ અને એલર્ટ્સ',
                paragraph:
                    'લક્ષ્ય સિદ્ધિ એલર્ટ્સ, કાર્યક્ષમતા યાદીઓ અને સમયમર્યાદા ચેતાવણીઓ વિશે કર્મચારીઓને જાણકારી આપવા માટે ઓટોમેટેડ નોટિફિકેશન્સ મોકલો.',
            },
        ],
    },
    invoiceManagement: {
        featureHeader: 'ચિઠ્ઠી મેનેજમેન્ટ',
        featureDescription:
            'કર્મચારીઓના કાર્યના વિવિધ પાસાઓનું રિયલ-ટાઇમ ટ્રેકિંગ અને વિશ્લેષણ, જેમ કે હાજરી, પૂર્ણ થયેલા ટાસ્ક અથવા પ્રોજેક્ટ પ્રગતિ.',
        featureCaption: 'સ્વચાલિત એલર્ટ્સ અને સૂચનાઓ સાથે ચિઠ્ઠીઓ બનાવવા, ટ્રેક કરવા અને પ્રોસેસિંગ કરવું.',
        keyPoints: [
            {
                header: 'સ્વચાલિત નોટિફિકેશન્સ અને યાદીઓ',
                paragraph:
                    'ચિઠ્ઠી સ્થિતિને ટ્રેક કરવા માટે સિસ્ટમ્સ સેટ કરો અને બાકી અને વિલંબિત ચુકવણી માટે સ્વચાલિત યાદીઓ અને એલર્ટ્સ મોકલો.',
            },
            {
                header: 'ચિઠ્ઠી_templates',
                paragraph:
                    'વિશ્વસનીય ચિઠ્ઠી ટેમ્પલેટ્સ જે તમામ જરૂરી વિગતો સાથે હોય છે જેમ કે ગ્રાહક માહિતી, ઓર્ડર વિગતો, કર, ડિસ્કાઉન્ટ અને ચુકવણીની શરતો.',
            },
            {
                header: 'ડિજિટલ સહી અને ડિલિવરીનો પુરાવો',
                paragraph: 'ચિઠ્ઠી પ્રક્રિયાનો ભાગ તરીકે ડિજિટલ સહી અને OTP આધારિત ડિલિવરીનો પુરાવો એકત્રિત કરો.',
            },
            {
                header: 'ઇન્વૉઇસ સાથે સિન્ક અને રિયલ ટાઇમ અપડેટ',
                paragraph:
                    'કेंद્રીય સિસ્ટમ (ક્લાઉડ આધારિત સંગ્રહ) સાથે ઓટોમેટિક સિન્ક અને ઇન્વૉઇસ સ્થિતિ પર રિયલ-ટાઇમ અપડેટ્સ.',
            },
        ],
    },
    distributorOrder: {
        featureHeader: 'વિતરણકર્તા ઓર્ડર',
        featureDescription:
            'કર્મચારીઓના કાર્યના વિવિધ પાસાઓનું રિયલ-ટાઇમ ટ્રેકિંગ અને વિશ્લેષણ, જેમ કે હાજરી, પૂર્ણ થયેલા ટાસ્ક અથવા પ્રોજેક્ટ પ્રગતિ.',
        featureCaption:
            'કર્મચારીઓના કાર્ય, મીનીટ્સ ઓફ મીટીંગ, વિઝિટ ચકાસવો, અને ડેટાના આધારે નિર્ણય લેવા માટે માહિતી પ્રાપ્ત રાખો.',
        keyPoints: [
            {
                header: 'વિતરણ માહિતી',
                paragraph:
                    'પ્રતિ વિતરણ માટે તમામ સંબંધિત વિગતો સાથે ઓર્ડરો બનાવો અને પ્રોસેસ કરો અને ઉપલબ્ધતા આધાર પર ટાસ્કને નિયત કરો.',
            },
            {
                header: 'રિયલ ટાઇમ ઇન્વેન્ટરી ઇન્ટિગ્રેશન',
                paragraph:
                    'ખાતરી કરવા માટે ઇન્વેન્ટરી મેનેજમેન્ટ સાથે ઇન્ટિગ્રેટ કરો કે સ્ટોક ની ચોકસાઈથી ફાળવણી થાય અને ઇન્વેન્ટરી સ્તરોને રિયલ-ટાઇમમાં અપડેટ કરો.',
            },
            {
                header: 'સ્વચાલિત નોટિફિકેશન્સ અને એલર્ટ્સ',
                paragraph:
                    'ઓર્ડર પ્રક્રિયાના મુખ્ય તબક્કાઓ પર સ્વચાલિત નોટિફિકેશન્સ મોકલવા માટે સિસ્ટમને રૂપરેખાંકિત કરો.',
            },
            {
                header: 'કર્મચારી ડેશબોર્ડ',
                paragraph:
                    'કર્મચારીઓ માટે ડેશબોર્ડનો પ્રવેશ પ્રાપ્ત કરો જ્યાં તેઓ ઓર્ડરો ચકાસી શકે છે, પ્રગતિને ટ્રેક કરી શકે છે, અને જરૂરી દસ્તાવેજોને ડાઉનલોડ કરી શકે છે.',
            },
        ],
    },
    inventoryManagement: {
        featureHeader: 'ઇન્વેન્ટરી મેનેજમેન્ટ',
        featureDescription:
            'કર્મચારીઓના કાર્યના વિવિધ પાસાઓનું રિયલ-ટાઇમ ટ્રેકિંગ અને વિશ્લેષણ, જેમ કે હાજરી, પૂર્ણ થયેલા ટાસ્ક અથવા પ્રોજેક્ટ પ્રગતિ.',
        featureCaption:
            'સચોટ ડેટા માટે સ્ટોકના યોગ્ય સંતુલનને રિયલ-ટાઇમ અપડેટ્સ અને ઇન્વેન્ટરી ટ્રેકિંગ સાથે સંતુલિત કરો.',
        keyPoints: [
            {
                header: 'ઓર્ડર મેનેજમેન્ટ સાથે ઇન્ટિગ્રેશન',
                paragraph:
                    'ઇન્વેન્ટરી અને ઓર્ડર મેનેજમેન્ટ સિસ્ટમ વચ્ચે સુલભ ઇન્ટિગ્રેશન સુનિશ્ચિત કરો, આવી રીતે સ્ટોક સ્તરોને આવતા અને જતાં ઓર્ડરો સાથે સુમેળમાં રાખવા માટે.',
            },
            {
                header: 'મલ્ટી-લોકેશન મેનેજમેન્ટ',
                paragraph:
                    'મલ્ટિપલ વેરહાઉસ, સ્ટોર્સ અથવા લોકેશન્સમાં ઇન્વેન્ટરીને ટ્રેક કરે છે અને કેન્દ્રિય નિયંત્રણ, સ્ટોક વિતરણને ઑપ્ટિમાઇઝ કરે છે અને દ્રષ્ટિ સુધારે છે.',
            },
        ],
    },
    orderCollectionManagement: {
        featureHeader: 'ઓર્ડર/કલેક્શન મેનેજમેન્ટ',
        featureDescription: 'ઓર્ડર/કલેક્શન ઓપરેશન્સને સ્કેલેબલ અને સ્વચાલિત સોલ્યુશન સાથે સરળ બનાવવો.',
        featureCaption: 'ઓર્ડર/કલેક્શન ઓપરેશન્સને સ્કેલેબલ અને સ્વચાલિત સોલ્યુશન સાથે સરળ બનાવવો.',
        keyPoints: [
            {
                header: 'એકત્રિત ઓર્ડર મેનેજમેન્ટ સિસ્ટમ',
                paragraph:
                    'એક કેન્દ્રિય સિસ્ટમ જેની ઍક્સેસ ઑન-સાઇટ અને ઓફ-સાઇટ કર્મચારીઓ બંને દ્વારા કરવામાં આવી શકે છે, ઓર્ડરોને મેનેજ કરવા, પ્રગતિને ટ્રેક કરવા અને સ્ટેટસને રિયલ-ટાઇમમાં અપડેટ કરવાની.',
            },
            {
                header: 'રોલ આધારિત ઍક્સેસ નિયંત્રણ',
                paragraph:
                    'ભિન્ન સ્તરોની ઍક્સેસ અને પરવાનગીઓની આધારિત રોલ (ઉદા., મેનેજર્સ, ઑન-સાઇટ કર્મચારીઓ, ઓફ-સાઇટ કર્મચારીઓ) જેથી ડેટા સુરક્ષા અને યોગ્ય ઍક્સેસ સુનિશ્ચિત થાય.',
            },
            {
                header: 'રિયલ-ટાઇમ સમન્વય',
                paragraph:
                    'બધા ઓર્ડર ડેટાને તમામ ડિવાઇસો અને લોકેશનોમાં રિયલ-ટાઇમમાં સમન્વયિત કરવું, જેથી દરેકને તાજા માહિતી સુધી ઍક્સેસ મળે.',
            },
            {
                header: 'સંચાર અને સમન્વય',
                paragraph: 'ઑન-સાઇટ અને ઓફ-સાઇટ કર્મચારીઓ વચ્ચે સંચાર સુલભ બનાવવો એની એપ-મેસેજિંગ અને સૂચનાઓ દ્વારા.',
            },
        ],
    },
};

export default features;
