const pricing = {
    attendance: {
        name: 'الحضور',
        price: '40',
        subLine: 'الأفضل لمراقبة ساعات العمل',
        features: [
            'تسجيل الدخول بالبصمة',
            'تسجيل الدخول بناءً على الموقع الجغرافي',
            'خيار نصف اليوم',
            'تسجيل الخروج التلقائي',
            'عداد التأخير',
            'متوسط وقت تسجيل الدخول',
            'إجمالي ساعات العمل',
            'الاشتراك التلقائي في تقرير واحد',
            'الحضور، المتوسط، تسجيل الدخول/الخروج',
            'التقارير',
            'شامل جميع الميزات الأساسية',
            'البصمة',
            'الذكاء الاصطناعي للتعرف على الوجه',
            'كشك',
            'خيار الفروع المتعددة',
            'نموذج لتسجيل الدخول والخروج',
            'شامل لجميع الميزات الأساسية والمتميزة',
        ],
    },
    task: {
        name: 'المهام',
        price: '100',
        subLine: 'الأفضل لإدارة المهام',
        features: [
            'المهام الميدانية',
            'المهام الخدمية',
            'إدارة المهام بناءً على السياج الجغرافي',
            'تسجيل الدخول والخروج',
            'وقت المغادرة',
            'إخطار مسبق',
        ],
    },
    tracking: {
        name: 'التتبع',
        price: '300',
        subLine: 'الأفضل للتتبع في الوقت الفعلي',
        features: [
            'المهام الميدانية',
            'المهام الخدمية',
            'إدارة المهام بناءً على السياج الجغرافي',
            'تسجيل الدخول والخروج',
            'وقت المغادرة',
            'التتبع المباشر',
        ],
    },
    customer: {
        name: 'العملاء',
        price: '80',
        subLine: 'إدارة علاقات العملاء',
        features: [
            'التواصل عبر واتساب، البريد الإلكتروني، والرسائل',
            'تعيين العلامات',
            'التذكيرات بناءً على الفترات الزمنية',
            'تعيين زيارات بناءً على السياج الجغرافي',
            'سجل الزيارات',
            'مشاركة بطاقة الأعمال',
            'الإعلانات',
            'شامل جميع الميزات الأساسية',
            'الذكاء الاصطناعي للمسار الذكي',
            'ماسح بطاقة الأعمال',
            'قوالب جاهزة للتواصل',
            'إدارة التذكيرات',
        ],
    },
    reimbursement: {
        name: 'التعويضات',
        price: '30',
        subLine: 'أتمتة المدفوعات بدقة وفي الوقت المناسب',
        features: ['التعويض بناءً على مسافة المركبة', 'تحليلات', 'بناءً على الفرع', 'بناءً على الشهري', 'الفئات'],
    },
    leave: {
        name: 'الإجازات',
        price: '30',
        subLine: 'للمديرين للموافقة عليها بسهولة',
        features: [
            'ترحيل الإجازات',
            'إدارة العطلات',
            'التحقق من الإجازات المستحقة',
            'خيار نصف اليوم والعمل من المنزل',
            'الموافقة على الإجازات',
            'تخصيص السياسة لكل فرد',
        ],
    },
    payroll: {
        name: 'الرواتب',
        price: '40',
        subLine: 'حسابات الرواتب بدقة',
        features: [
            'سياسة الرواتب',
            'حساب الرواتب المتقدمة',
            'ملخص الدفع',
            'حساب بنقرة واحدة',
            'حساب الراتب بالساعة والشهرية',
            'حساب العمل الإضافي',
        ],
    },
    faceID: {
        name: 'التعرف على الوجه',
        price: '25',
        subLine: 'الأفضل لتعزيز الأمان',
        features: ['إضافة التعرف على الوجه في الحضور كإضافة'],
    },
    forms: {
        name: 'النماذج',
        price: '30',
        subLine: 'إنشاء وإدارة النماذج الرقمية',
        features: [
            'النماذج الديناميكية',
            'إنشاء النماذج المخصصة',
            'تحليلات بناءً على النماذج',
            'نماذج للأحداث',
            'الدفع من خلال النماذج',
            'إنشاء نماذج مخصصة',
        ],
    },
};
export default pricing;
