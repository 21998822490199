const homePage = {
    heroHeader: 'விற்பனை மேம்பாட்டையும் செயல்திறனை பெருக்கவும்',
    heroHeaderTypist: ['பணியாளர் மேலாண்மை', 'களம் கண்காணிப்பு', 'CRM'],
    heroHeaderSubText:
        'நேரடி நிலைக்கோடு வருகை, நேரடி கண்காணிப்பு, பணி மற்றும் ஆணை மேலாண்மை, தரவுப் பார்வைகள் மற்றும் API சேவைகள்',
    moreProductivityLessExpenses: 'அதிக உற்பத்தி, குறைந்த செலவுகள்',
    keyFeatures: [
        {
            title: 'லென்ஸ்',
            description:
                'AI உதவியுடன் தொடுவதற்குரிய முகப்பு வருகை. உங்கள் மொபைல் கேமராவை பயன்படுத்தி வருகையை மட்கவும்.',
        },
        {
            title: 'வெப் ஆப்',
            description:
                'பயனர் நட்பான டாஷ் போர்டு, HR மற்றும் மேலாளர் குழுக்கள் விரிவான அறிக்கைகளை எளிதாக அணுகலாம், ஊழியர் பதிவுகளை மேலாண்மை செய்யலாம், செயல்திறனைப் பின்பற்றலாம் மற்றும் ஊதியத்தைச் சரிபார்க்கலாம்.',
        },
        {
            title: 'மரபு அடிப்படையிலான கட்டுப்பாடு',
            description:
                'அதிரடி, HR மேலாளர்கள், மேற்பார்வையாளர்கள் மற்றும் ஊழியர்களை உள்ளடக்கிய பல்வேறு அமைப்புத் தரப்புகளை சேர்க்க மரபு அடிப்படையிலான கட்டுப்பாடு அமைப்பு.',
        },
        {
            title: 'மார்க்கப்பார்வை (Route Optimization)',
            description:
                'செயல்திறனை மேம்படுத்தவும், செயல்முறைச் செலவுகளை குறைக்கவும், மாறுபட்ட வழி கணக்கீடுகள், பன்மடங்கு நிறுத்த optimization, செலவீன கணிப்புகள் மற்றும் நேரடி புதுப்பிப்புகளைப் பயன்படுத்தவும்.',
        },
    ],
    features: 'அம்சங்கள்',
    ourFeatures:
        'எங்கள் அம்சங்கள் எளிமையான, சக்திவாய்ந்த மற்றும் எந்தவொரு தொழில்துறைக்கும் எளிதாக பொருந்தக்கூடியவை, இது நிறுவனத்தின் உற்பத்தி திறனை அதிகரிக்க உதவும்.',
    ourFeaturesObject: [
        {
            title: 'வருகை கண்காணிப்பு',
            description: 'நிலையின் அடிப்படையில் வருகை மட்குதல் & வேலை நேரங்களைப் பின்பற்றுதல்',
        },
        {
            title: 'செலவு மேலாண்மை',
            description: 'செலவுகள் முறையான முறையில் கணக்கிடப்பட்டு பதிவு செய்யப்படும்',
        },
        {
            title: 'பணியாளர் கண்காணிப்பு',
            description: 'ஊழியர்களின் செயல்பாடுகளை, செயல்திறனை கண்காணித்து பதிவு செய்தல்',
        },
        {
            title: 'ஊதிய மேலாண்மை',
            description: 'ஊதிய செயல்முறைகளை துல்லியமான பணவீனம் மற்றும் சரியான புறக்கணிப்புடன் மேம்படுத்துதல்',
        },
        {
            title: 'ஆணை மேலாண்மை',
            description: 'பலவிதமான தனிப்பயனாக்கக்கூடிய உத்திகள் மூலம் ஆணைகள்/சமீபங்களில் மேலாண்மை செய்யும் செயல்முறை',
        },
        {
            title: 'மார்க்கப்பார்வை',
            description: 'நேரம், எரிசக்தி மற்றும் வளங்களைச் சேமிக்க மிகவும் எளிய பாதையை கண்டறிதல்.',
        },
    ],
    whatWeDoQuestion: 'TraceForce என்ன செய்யும்?',
    whatWeDoCards: [
        {
            title: 'ஆதிகாரிகள் செயல்பாடுகளை நேரடியாகப் பின்வரிக்கவும்',
            description: 'நாட்பட்ட அல்லது மைதான அடிப்படையில் குழுவின் செயல்பாட்டின் விரிவான பகுப்பாய்வைப் பெறுங்கள்.',
        },
        {
            title: 'GeoFence அடிப்படையிலான வருகை மற்றும் சோதனை அறிக்கைகள்',
            description:
                'மாதிரி செயல் திறனுடன் கூடிய சரியான வருகை மற்றும் விடுப்பு மேலாண்மை மூலம் குழுவின் செயல்திறனை அதிகரிக்கவும்.',
        },
        {
            title: 'ஆணைகள்/சமீபங்களில் துல்லியமாக மேலாண்மை செய்யுங்கள்',
            description:
                'பயன்பாட்டுக் குறியீடுகளுடன் மற்றும் நேரமான ஆணைகளை மிகுந்த உற்பத்தி, வாடிக்கையாளர் திருப்தியுடன் குறைக்கவும்.',
        },
        {
            title: 'அம்சவியல் பணி மேலாண்மை',
            description:
                'திட்டமிடல், பணி ஒதுக்கீடு மற்றும் உங்கள் குழுவின் உறுப்பினர்களுடன் நேரடியாக ஒத்துழைப்பு எளிதாக்கவும்.',
        },
        {
            title: 'தானாகவே தயாரிக்கப்பட்ட அறிக்கைகளை அடிப்படையிலான பார்வைகள்',
            description: 'ஊழியர் ஈடுபாட்டைப் பார்வையிடவும் மற்றும் குழு பகுப்பாய்வை முடக்கமின்றி செய்யவும்',
        },
        {
            title: 'அனுமதியின் அடிப்படையில் செலவுகளை மேலாண்மை செய்யுங்கள்',
            description: 'பங்குதாரர்கள் அடிப்படையில் இணையதள ஊதியங்களை பரிசீலிக்கவும்.',
        },
    ],
    whatWeDoList: [
        'வருகை பதிவு',
        'Geo Location Tracker',
        'மேக சேமிப்பு',
        'எச்சரிக்கைகள் மற்றும் அறிவிப்புகள்',
        'திட்ட மேலாண்மை',
        'விற்பனை மூலையூட்டு மேலாண்மை',
    ],
    featureTitle: 'அம்சங்கள்',
    featureSubTitle:
        'எங்கள் அம்சங்கள் எளிமையான, சக்திவாய்ந்த மற்றும் எந்தவொரு தொழில்துறைக்கும் எளிதாக பொருந்தக்கூடியவை, இது நிறுவனத்தின் உற்பத்தி திறனை அதிகரிக்க உதவும்.',
    featureCards: [
        {
            title: 'வருகை கண்காணிப்பு',
            description: 'நிலையின் அடிப்படையில் வருகை மட்குதல் & வேலை நேரங்களைப் பின்பற்றுதல்',
        },
        {
            title: 'செலவு மேலாண்மை',
            description: 'செலவுகள் முறையான முறையில் கணக்கிடப்பட்டு பதிவு செய்யப்படும்',
        },
        {
            title: 'பணியாளர் கண்காணிப்பு',
            description: 'ஊழியர்களின் செயல்பாடுகளை, செயல்திறனை கண்காணித்து பதிவு செய்தல்',
        },
        {
            title: 'களவியல் விற்பனை பகுப்பாய்வு',
            description: 'விற்பனை தரவுகளை சேகரித்து, விற்பனை செயல்திறனை மதிப்பீடு செய்தல்',
        },
        {
            title: 'ஆணை மேலாண்மை',
            description: 'பலவிதமான தனிப்பயனாக்கக்கூடிய உத்திகள் மூலம் ஆணைகள்/சமீபங்களில் மேலாண்மை செய்யும் செயல்முறை',
        },
        {
            title: 'நேரடி அறிவிப்பு',
            description:
                'உங்கள் கள ஊழியர்கள் எங்கு உள்ளனர் மற்றும் அவர்கள் எந்தப் பணியில் ஈடுபட்டுள்ளனர் என்பதைக் அறிக',
        },
    ],
    integrationTitle: 'தொழில்கள்',
    integrationSubTitle: 'ஒவ்வொரு தொழிலுக்கும் வேலைப் பணி மேலாண்மையையும் உற்பத்தித்திறனை இணைக்கும்',
    orderManagementSubtitle: 'ஆணை மேலாண்மையைச் சந்திக்க தேவையான ஒரேதாக இல்லாமல்',
    orderManagementTitle:
        'உங்கள் விற்பனை குழுவிற்கான நிச்சயமான ஆணை மற்றும் சேகரிப்பு மேலாண்மையுடன் தானாகவே, கண்காணிப்பு மற்றும் விநியோகத்தைச் செய்யுங்கள்.',
    orderManagementAccordian: [
        {
            title: 'மின்னணு ஆணை பதிவு',
            points: [
                'ஆணையின் நிலையை இடைவிடாது கண்காணிக்கவும்.',
                'ஆணைகளை பதிவு செய்யவும், கையெழுத்தற்ற பிழைகளை குறைக்கவும்.',
            ],
        },
        {
            title: 'தானாகவே மின்னணு சான்றிதழ்',
            points: [
                'ஆணை விவரங்களின் அடிப்படையில் மின்னணு சான்றிதழ்களை தானாகவே உருவாக்குங்கள்.',
                'மேலான செயல்முறை மற்றும் செலவீனத்திற்கு மின்னணு சான்றிதழ்களை அனுப்புங்கள்.',
                'சான்றிதழ் மூலம் ஆன்மீகத்திற்கான OTP அடிப்படையிலான உறுதிப்படுத்தல்',
            ],
        },
        {
            title: 'விற்பனை இலக்குகள்',
            points: [
                'இலக்குகளைப் பின்பற்ற விரிவான அறிக்கைகள் மற்றும் பகுப்பாய்வுடன்.',
                'பணியாளர் வேலையின் அடிப்படையில் குழுக்களுக்கு விற்பனை இலக்குகளை அமைக்கவும் மற்றும் கண்காணிக்கவும்.',
            ],
        },
        {
            title: 'நேரடி கையிருப்பு கண்காணிப்பு',
            points: [
                'ஒவ்வொரு முறையும் துல்லியமான பங்கு அளவுகளைப் கையாளவும், ஆணை உருவாக்கியதும் நிறைவுபடுத்தவும்.',
                'குறைவான கையிருப்பு நிலைகளைப் அடிப்படையில் தானாகவே வாங்கும் ஆணைகளை உருவாக்கவும்.',
            ],
        },
        {
            title: 'ஆணையின் விநியோகம்',
            points: [
                'கட்டுப்பாட்டிற்கும் வெளிப்பாட்டிற்கும் உறுதிப்படுத்தல் மூலம் கூடைகளை கையாளவும்.',
                'விற்பனை குழுவிற்கு முன்பணியாளர் மற்றும் இடத்திற்கு அடிப்படையிலான விநியோகத்தை மேம்படுத்தவும்.',
                'விரைவான விநியோகத்திற்கும் செலவீனத் தீர்வுக்குமான AI அடிப்படையிலான பாதை அடிப்படையிலான தீர்வு.',
            ],
        },
    ],
    whyChooseTraceforce: 'TraceForceஐ ஏன் தேர்வுசெய்வது?',
    whyChooseTraceforceSubtitle:
        'நிர்வாகப் பணிகளை தானாகவே செய்யும், செயல்முறைகளை எளிமையாக்கும் மற்றும் ஊழியர்களின் ஈடுபாட்டைப் அதிகரிக்கும் வகையில் மனித வள மேலாண்மையை புரட்சி செய்யும்.',
    whyChooseTraceForceList: [
        'உற்பத்தி மேம்பாடு',
        'நேர திறன்',
        'சரியான கருத்துகள்',
        'நேரடி கண்காணிப்பு',
        'முழுமையான பதில்கள்',
        'AI அடிப்படையிலான மேம்பாடு',
        'மரபு அடிப்படையிலான தனிப்பயன்',
        'பல கிளை ஆதரவு',
        '24x7 ஆதரவு சேவை',
    ],
    calculatorTitle: 'கணக்கீடுபொறி',
    calculatorSubtitle:
        'எங்கள் அம்சங்கள் எளிமையான, சக்திவாய்ந்த மற்றும் எந்தவொரு தொழில்துறைக்கும் எளிதாக பொருந்தக்கூடியவை, இது நிறுவனத்தின் உற்பத்தி திறனை அதிகரிக்க உதவும்.',
    workingHours: 'வேலை நேரங்கள்',
    uncountedHours: 'எண்ணப்படாத நேரங்கள்',
    dailySavings: 'நேரடி சேமிப்பு',
    monthlySavings: 'மாதாந்திர சேமிப்பு',
    productivityGrowth: 'உற்பத்தி வளர்ச்சி',
    numberOfEmployees: 'ஊழியர்கள் எண்ணிக்கை',
    faqs: 'அடிக்கடி கேட்கப்படும் கேள்விகள்',
    frequentlyAskedQuestions: 'அடிக்கடி கேட்கப்படும் கேள்விகள்',
    faqLine: 'எங்கள் வாடிக்கையாளர்களுக்கான சில அடிப்படை வகையான கேள்விகள் இங்கே உள்ளன',
    faqAccordian: [
        {
            question: 'எனது நிறுவனத்தை எப்படிச் சுயமாகக் கையொப்பமிடுவது?',
            ans: 'TraceForceஐ எங்கள் மொபைல் ஆப் அல்லது வெப் ஆப்பில் 3 எளிய படிகளில் உள்நுழையலாம். உங்கள் பெயர், மின்னஞ்சல் மற்றும் GST எண்ணை சேர்க்கவும், நீங்கள் தயார்.',
        },
        {
            question: 'நான் எவ்வாறு உள்நுழைவதற்கான முறைமைகளை உறுதிப்படுத்தலாம்?',
            ans: 'TraceForce உங்களுக்கு பல Clock In விருப்பங்களை வழங்குகிறது. உதாரணமாக, எங்களிடம் இயல்புநிலை மொபைல் உள்நுழைவு விருப்பம், செல்ஃபி அடிப்படையிலான விருப்பம் மற்றும் மிகுந்த பாதுகாப்பாக இருக்கும் AI அடிப்படையிலான முகமூடி அடிப்படையிலான அடையாளமிடுதல்.',
        },
        {
            question: 'வருகையை எவ்வாறு மட்குவது?',
            ans: 'பயனர் allocated GeoFenceல் உள்ள நேரத்தில் மட்டுமே வருகையை மட்கலாம். Proxy பாதுகாக்க, நாங்கள் பல்வேறு உறுதிப்படுத்தல் முறைமைகளை வைத்துள்ளோம்.',
        },
        {
            question: 'நான் இடம் மாற்றம் அல்லது நேரத்தை மாற்ற முடியுமா?',
            ans: 'TraceForce எந்தவொரு ஸ்பாம் அல்லது தேவையற்ற செயல்பாடுகளை அடையாளம் காணும் மற்றும் செயலியை முடக்கிவிடும் மற்றும் பயனருக்கு அறிவிப்பையும் அனுப்பும்.',
        },
        {
            question: 'TraceForce தொலைபார்வை ஊழியர்களைக் கையாள முடியுமா?',
            ans: 'TraceForce ஒப்பந்த ஊழியர்களை மணிநேர அடிப்படையில் ஆதரிக்கின்றது மற்றும் அதற்கேற்ப ஊதியங்களை கணக்கிடுகின்றது.',
        },
        {
            question: 'நான் என் பணியாளர்களை மொபைல் மூலம் கண்காணிக்க முடியுமா?',
            ans: 'TraceForce உங்கள் பணியாளர்களைப் மொபைல் மற்றும் வெப் மூலம் கண்காணித்து மேலாண்மை செய்ய உதவுகிறது, இது உங்கள் நாள்தோறும் விரிவான தகவல்களை அளிக்கிறது.',
        },
        {
            question: 'HRMS தவிர TraceForce என்ன சிறப்பு சேவைகளை வழங்குகிறது?',
            ans: 'TraceForce மேலாளர்களுக்கு அல்லது வணிக உரிமையாளர்களுக்கு தேவையான அறிவிப்புகளை மட்டுமே வழங்கும் மிகவும் விரிவான சேவைகளை வழங்குகிறது.',
        },
    ],
};
export default homePage;
