const navbar = {
    industries: 'Industries',
    pharmaceuticals: 'Pharmaceutiques',
    fieldSales: 'Ventes sur le Terrain',
    fmcg: 'FMCG',
    service: 'Service',
    fieldOperations: 'Opérations sur le Terrain',
    logisticsAndTransportation: 'Logistique et Transport',
    education: 'Éducation',
    products: 'Produits',
    pricing: 'Tarification',
    attendanceManagement: "Gestion de l'Assiduité",
    fieldForceManagement: 'Gestion de la Force de Terrain',
    company: 'Entreprise',
    ourStory: 'Notre Histoire',
    joinUs: 'Rejoignez-nous',
    contactUs: 'Contactez-nous',
    integration: 'Intégration',
    becomeAPartner: 'Devenir Partenaire',
    blog: 'Blog',
};

export default navbar;
