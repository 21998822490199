const pricing = {
    attendance: {
        name: 'Asistencia',
        price: '40',
        subLine: 'Ideal para monitorear horas de trabajo',
        features: [
            'Entrada basada en biometría',
            'Entrada basada en geolocalización',
            'Opción de medio día',
            'Cierre automático de sesión',
            'Contador de tardanza',
            'Promedio de hora de entrada',
            'Total de horas trabajadas',
            'Suscripción automática a 1 informe',
            'Asistencia, promedio, entrada/salida',
            'Informes',
            'Incluye todas las funciones básicas',
            'Biometría',
            'Reconocimiento facial AI',
            'Kiosco',
            'Opción de múltiples sucursales',
            'Formulario para entrada y salida',
            'Incluye todas las funciones básicas y premium',
        ],
    },
    task: {
        name: 'Tarea',
        price: '100',
        subLine: 'Ideal para gestionar tareas',
        features: [
            'Tarea de campo',
            'Tarea de servicio',
            'Gestión de tareas basada en geocerca',
            'Entrada y salida',
            'Hora de salida',
            'Notificación previa',
        ],
    },
    tracking: {
        name: 'Rastreo',
        price: '300',
        subLine: 'Ideal para rastreo en tiempo real',
        features: [
            'Tarea de campo',
            'Tarea de servicio',
            'Gestión de tareas basada en geocerca',
            'Entrada y salida',
            'Hora de salida',
            'Rastreo en vivo',
        ],
    },
    customer: {
        name: 'Cliente',
        price: '80',
        subLine: 'Gestiona relaciones con clientes',
        features: [
            'Comunicación por Whatsapp, Email y Mensaje',
            'Asignar etiquetas',
            'Recordatorios basados en intervalos',
            'Visitas basadas en geocerca',
            'Historial de visitas',
            'Compartir tarjeta de presentación',
            'Anuncio',
            'Incluye todas las funciones básicas',
            'Ruta de recorrido basada en AI',
            'Escáner de tarjetas de presentación',
            'Plantillas predefinidas para comunicación',
            'Gestionar recordatorios',
        ],
    },
    reimbursement: {
        name: 'Reembolsos',
        price: '30',
        subLine: 'Automatiza pagos precisos y puntuales',
        features: [
            'Reembolsos basados en distancia de vehículo',
            'Análisis',
            'Basado en sucursal',
            'Basado en mensual',
            'Categoría',
        ],
    },
    leave: {
        name: 'Licencias',
        price: '30',
        subLine: 'Facilita la aprobación para los gerentes',
        features: [
            'Acumulación de licencias',
            'Gestionar días festivos',
            'Consultar licencias acumuladas',
            'Medio día y trabajo desde casa',
            'Aprobar licencias',
            'Personalizar política para cada individuo',
        ],
    },
    payroll: {
        name: 'Nómina',
        price: '40',
        subLine: 'Cálculos salariales precisos',
        features: [
            'Política de nómina',
            'Cálculo de salario adelantado',
            'Resumen de pagos',
            'Cálculo con un clic',
            'Cálculo de salario por hora y mensual',
            'Cálculo de horas extras',
        ],
    },
    faceID: {
        name: 'Reconocimiento Facial',
        price: '25',
        subLine: 'Ideal para mejorar la seguridad',
        features: ['Agregar reconocimiento facial en asistencia como complemento'],
    },
    forms: {
        name: 'Formularios',
        price: '30',
        subLine: 'Crea y gestiona formularios digitales',
        features: [
            'Formularios dinámicos',
            'Creación de formularios personalizados',
            'Análisis basado en formularios',
            'Formularios para eventos',
            'Pago a través de formularios',
            'Crear formularios personalizados',
        ],
    },
};
export default pricing;
