const joinUsPage = {
    joinUsString: 'Únete a Nosotros',
    joinUsHeroPara:
        '¡Ven y sé parte de nuestro equipo en TraceForce! Explora oportunidades emocionantes donde el trabajo en equipo y la creatividad son el camino a seguir. Descubre cómo puedes contribuir a nuestra misión de empoderar a las empresas a través de soluciones de software intuitivas. Crece en tu carrera en un entorno de apoyo que valora tus talentos e ideas únicas.',
    ourRecruitmentProcess: 'Nuestro Proceso de Reclutamiento',
    ourBenifitsString: 'Nuestros Beneficios',
    ourRecruitmentProcessObjects: [
        {
            title: 'Entrevista Técnica',
            description:
                'Esta entrevista está diseñada para evaluar tus habilidades técnicas, habilidades para resolver problemas y cómo aplicas tu conocimiento a escenarios del mundo real. Queremos obtener una visión de tu competencia técnica, habilidades de codificación y tu enfoque para enfrentar desafíos. Esta ronda se realiza a través de una llamada por Meet/Skype.',
        },
        {
            title: 'Prueba de Práctica (Aptitud)',
            description:
                'Evaluamos tu lógica de codificación y optimización de código asignando una tarea que se enviará por correo después de tu primera ronda basada en tu entrevista. La tarea tendrá un plazo determinado dentro del cual el candidato debe completarla.',
        },
        {
            title: 'Entrevista con la Gestión',
            description:
                'Una charla rápida con nuestra gestión para comprender tu visión profesional y tu ajuste cultural en nuestra organización. Buscamos una persona con sólido conocimiento en el tema y fuertes ética de trabajo.',
        },
        {
            title: 'Selección Final',
            description:
                'La finalización de la oferta se realizará con el equipo de Recursos Humanos. Hacemos nuestra selección final con una consolidación en profundidad del rendimiento de los candidatos en todas las rondas de entrevistas. Basado en la decisión mutua, se confirmarán las fechas de incorporación, y te proporcionaremos las mejores herramientas desde el primer día.',
        },
    ],
    ourBenifitsObjects: [
        {
            name: '¡Trabaja desde Cualquier Lugar!',
            description:
                '¡Adivina que, es de vuelta al escritorio! Porque tu oficina en casa es un poco demasiado amigable con los perros y tu ‘sala de reuniones’ está actualmente ocupada por una siesta de gato. No ofrecemos Trabajo desde cualquier lugar porque nos gusta verte todos los días.',
        },
        {
            name: 'Fines de Semana Alternos',
            description:
                'Cuando tus fines de semana son tan alternos como tus cambios de humor. Una semana, estás planeando una escapada divertida o poniéndote al día con el sueño, y la siguiente, estás lidiando con plazos y respondiendo correos electrónicos. Todo es parte de la aventura en equilibrar trabajo y diversión.',
        },
        {
            name: 'Seguro de Salud',
            description:
                'Para todos esos momentos de ‘puede que necesite un médico para esto’. Con una cobertura integral, no tendrás que preocuparte por los ‘y si no’; solo concédele atención a tu recuperación y a volver a tu mejor versión. Porque cuando no te sientes bien, mereces tener la mejor atención al alcance de tu mano.',
        },
        {
            name: 'Bono por Referencia',
            description:
                '¿Tienes amigos? ¡Obtén bonos! Es un ganar-ganar. Imagina esto: tienes un círculo de amigos que son increíbles en lo que hacen y están buscando nuevas oportunidades. Al referirlos a nuestra empresa, no solo los estás ayudando a encontrar un trabajo fantástico, sino que también estás llenando tus propios bolsillos con un dulce bono por referencia.',
        },
        {
            name: 'Kit del Empleado',
            description:
                'Kit del Empleado - Todo lo que necesitas para conquistar el día laboral, excepto un clon de ti mismo. Desde suministros de oficina esenciales hasta obsequios motivacionales, este kit lo tiene todo. ¿Necesitas un bolígrafo? Verificado. ¿Cuaderno para todas tus ideas brillantes? Verificado.',
        },
        {
            name: 'Horas de Trabajo Flexibles',
            description:
                'Ya no tendrás que apresurarte a casa para ver tu programa favorito; ahora, tu oficina puede ser tu sofá, y tu tiempo de descanso puede ser el horario estelar. Con horas flexibles, puedes pasar de llamadas de conferencia a suspensos, de hojas de cálculo a maratones de Netflix sin esfuerzo.',
        },
        {
            name: 'Licencia Sabbática',
            description: 'Licencia Sabbática',
        },
        {
            name: 'Licencia por Paternidad/Maternidad',
            description: 'Licencia por Paternidad/Maternidad',
        },
        {
            name: 'Equilibrio entre Trabajo y Vida Personal',
            description: 'Equilibrio entre Trabajo y Vida Personal',
        },
        {
            name: 'Celebraciones de Oficina',
            description: 'Celebraciones de Oficina',
        },
    ],
    jobsAtTraceForce: 'Trabajos en TraceForce',
};
export default joinUsPage;
