const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'আপনার সাথে বৃদ্ধি পায় এমন নমনীয় পরিকল্পনা',
    subFlexiblePlansThatGrowsWithYou:
        'আপনার ব্যবসায়িক প্রয়োজনীয়তা অনুযায়ী মূল্য পরিকল্পনা নির্বাচন করুন। ট্রেসফোর্স সেরা মূল্য সহ মূল্য প্যাকেজ সরবরাহ করে।',
    pricingPlans: 'মূল্য পরিকল্পনা',
    pricingThatWorksForEveryOne: 'সবাইর জন্য কার্যকরী মূল্য',
    monthly: 'মাসিক',
    annualy: 'বার্ষিক',
    international: 'আন্তর্জাতিক',
    domestic: 'দেশীয়',
    month: 'মাস',
    benifits: 'সুবিধা',
    allPlanIncludeTheseBenifits: 'সব পরিকল্পনায় এই সুবিধাগুলি অন্তর্ভুক্ত রয়েছে',
    someBenfitsOfMonthlyYearlySubscription: 'মাসিক/বার্ষিক সাবস্ক্রিপশনের কিছু সুবিধা',
    benifitsArr: [
        {
            title: 'প্রযুক্তিগত সহায়তা',
            description: 'আমাদের পেশাদার প্রযুক্তিগত সহায়তা দল আপনাকে প্রতিটি পদক্ষেপে সহায়তা করবে।',
        },
        {
            title: 'প্রযুক্তি',
            description: 'প্ল্যাটফর্মটি শুরু করতে বিশেষ প্রশিক্ষণ প্রদান।',
        },
        {
            title: 'বৃদ্ধির বিশ্লেষণ',
            description: 'মাসিক বৃদ্ধি সম্পর্কে তথ্য প্রদান করার জন্য একটি নিবেদিত দল।',
        },
        {
            title: 'পুরস্কার',
            description: 'প্রতি মাসে সেরা পারফর্মিং অ্যাকাউন্টের জন্য একটি বিশেষ পুরস্কার।',
        },
    ],
    fAQs: 'সাধারণত জিজ্ঞাসিত প্রশ্নাবলী',
    frequentlyAskedQuestions: 'সাধারণত জিজ্ঞাসিত প্রশ্নাবলী',
    someBaTypeOfQuestions: 'আমাদের গ্রাহকদের থেকে কিছু সাধারণ ধরনের প্রশ্ন',
    fAQArr: [
        {
            question: 'আমি কিভাবে আমার কোম্পানির জন্য সাইন আপ করতে পারি?',
            ans: 'হ্যাঁ, মার্কেটপ্লেস লাইসেন্স আপনাকে এই থিমটি যে কোনও চূড়ান্ত পণ্যে ব্যবহার করার অনুমতি দেয়। লাইসেন্স সম্পর্কে আরও তথ্যের জন্য মার্কেটপ্লেসে লাইসেন্সের শর্তাবলী পরীক্ষা করুন।',
        },
        {
            question: 'আমি কিভাবে আমার কর্মশক্তি-যানবাহন যোগ করতে পারি?',
            ans: 'না, এটি একটি HTML টেমপ্লেট। এটি সরাসরি WordPress-এর সাথে কাজ করবে না, যদিও আপনি এটিকে একটি WordPress-সামঞ্জস্যপূর্ণ থিমে রূপান্তরিত করতে পারেন।',
        },
        {
            question: 'আমার কর্মশক্তি কিভাবে ট্রেসফোর্সে লগ ইন করবে?',
            ans: 'আপনার সমস্যা বা প্রতিক্রিয়া আমাদের নিবেদিত সহায়তা ইমেইলে পাঠান (support@coderthemes.com)। আমরা আপনাকে সহায়তা করার জন্য যে কোনও সময় উপলব্ধ।',
        },
        {
            question: 'আমি কি আমার কর্মশক্তি মোবাইলের মাধ্যমে ট্র্যাক করতে পারি?',
            ans: 'হ্যাঁ, আমরা নিয়মিতভাবে প্রম্পটগুলি আপডেট করব। সমস্ত ভবিষ্যত আপডেট বিনামূল্যে উপলব্ধ হবে।',
        },
        {
            question: 'কিভাবে অ্যাটেনডেন্স চিহ্নিত করব?',
            ans: 'হ্যাঁ, মার্কেটপ্লেস লাইসেন্স আপনাকে এই থিমটি যে কোনও চূড়ান্ত পণ্যে ব্যবহার করার অনুমতি দেয়। লাইসেন্স সম্পর্কে আরও তথ্যের জন্য মার্কেটপ্লেসে লাইসেন্সের শর্তাবলী পরীক্ষা করুন।',
        },
        {
            question: 'ডেভেলপার অপশন কিভাবে অক্ষম করবেন?',
            ans: 'না, এটি একটি HTML টেমপ্লেট। এটি সরাসরি WordPress-এর সাথে কাজ করবে না, যদিও আপনি এটিকে একটি WordPress-সামঞ্জস্যপূর্ণ থিমে রূপান্তরিত করতে পারেন।',
        },
        {
            question: 'ট্রেসফোর্স কী কী বৈশিষ্ট্য সরবরাহ করে?',
            ans: 'আপনার সমস্যা বা প্রতিক্রিয়া আমাদের নিবেদিত সহায়তা ইমেইলে পাঠান (support@coderthemes.com)। আমরা আপনাকে সহায়তা করার জন্য যে কোনও সময় উপলব্ধ।',
        },
        {
            question: 'আমি কি ট্রেসফোর্স সফটওয়্যার ইনস্টল করতে হবে?',
            ans: 'হ্যাঁ, আমরা নিয়মিতভাবে প্রম্পটগুলি আপডেট করব। সমস্ত ভবিষ্যত আপডেট বিনামূল্যে উপলব্ধ হবে।',
        },
        {
            question: 'ট্রেসফোর্স সফটওয়্যারের বাস্তবায়ন প্রক্রিয়া কী?',
            ans: 'হ্যাঁ, মার্কেটপ্লেস লাইসেন্স আপনাকে এই থিমটি যে কোনও চূড়ান্ত পণ্যে ব্যবহার করার অনুমতি দেয়। লাইসেন্স সম্পর্কে আরও তথ্যের জন্য মার্কেটপ্লেসে লাইসেন্সের শর্তাবলী পরীক্ষা করুন।',
        },
        {
            question: 'ট্রেসফোর্স সফটওয়্যারের সাথে কী কী প্রশিক্ষণ এবং সহায়তা বিকল্প রয়েছে?',
            ans: 'না, এটি একটি HTML টেমপ্লেট। এটি সরাসরি WordPress-এর সাথে কাজ করবে না, যদিও আপনি এটিকে একটি WordPress-সামঞ্জস্যপূর্ণ থিমে রূপান্তরিত করতে পারেন।',
        },
        {
            question: 'ট্রেসফোর্স সফটওয়্যার কি আমাদের নির্দিষ্ট HR প্রক্রিয়ার অনুযায়ী কাস্টমাইজ করা যেতে পারে?',
            ans: 'আপনার সমস্যা বা প্রতিক্রিয়া আমাদের নিবেদিত সহায়তা ইমেইলে পাঠান (support@coderthemes.com)। আমরা আপনাকে সহায়তা করার জন্য যে কোনও সময় উপলব্ধ।',
        },
        {
            question: 'HRMS সফটওয়্যার কীভাবে সম্মতি এবং প্রতিবেদন সহায়তা করতে পারে?',
            ans: 'হ্যাঁ, আমরা নিয়মিতভাবে প্রম্পটগুলি আপডেট করব। সমস্ত ভবিষ্যত আপডেট বিনামূল্যে উপলব্ধ হবে।',
        },
        {
            question: 'HRMS সফটওয়্যার কি দূরবর্তী কাজ এবং মোবাইল অ্যাক্সেস সমর্থন করে?',
            ans: 'হ্যাঁ, আমরা নিয়মিতভাবে প্রম্পটগুলি আপডেট করব। সমস্ত ভবিষ্যত আপডেট বিনামূল্যে উপলব্ধ হবে।',
        },
    ],
};
export default pricingPage;
