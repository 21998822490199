const navbar = {
    industries: 'துறைகள்',
    pharmaceuticals: 'மருந்தியல்',
    fieldSales: 'துறை விற்பனை',
    fmcg: 'எஃப்.எம்.சி.ஜி',
    service: 'சேவை',
    fieldOperations: 'துறை செயல்பாடுகள்',
    logisticsAndTransportation: 'செயற்பாட்டுத் திட்டம் மற்றும் போக்குவரத்து',
    education: 'கல்வி',
    products: 'தயாரிப்புகள்',
    pricing: 'விலைகள்',
    attendanceManagement: 'சமீபத்தி மேலாண்மை',
    fieldForceManagement: 'துறை படை மேலாண்மை',
    company: 'நிறுவனம்',
    ourStory: 'எங்கள் கதை',
    joinUs: 'எங்களுடன் சேருங்கள்',
    contactUs: 'எங்களை அணுகுங்கள்',
    integration: 'ஒத்திசைவு',
    becomeAPartner: 'ஒரு பங்குதாரராக மாறுங்கள்',
    blog: 'பிளாக்',
};
export default navbar;
