const industries = {
    pharmaceutical: {
        industryHeading:
            'மாதிரியான பாதுகாப்பு முறைமைகளை கண்காணிக்கவும், நேரத்தைப் பின்பற்றவும், வருகை மற்றும் கவனிப்புக்கான அணுகலைக் கட்டுப்படுத்தவும், திட்ட மேலாண்மையை, வெளிப்புற நடவடிக்கைகளை மற்றும் செயல்திறனை மதிப்பீடு செய்யவும்.',
        featureHeader: 'மருத்துவம்',
        featureParagraph:
            'மருத்துவ/அரசாங்க நிறுவனங்களுக்கு நேரத்தைப் பின்பற்றுதல், மொபைல் அணுகல், வெளியிட நடவடிக்கைகள், மற்றும் விற்பனைத் திறனை மேம்படுத்துவதற்கான படிப்புகளுடன் கூடிய வருகை மேலாண்மை மற்றும் கள பின்பற்றுதல் மென்பொருள்.',
        keyPoints: [
            {
                header: 'ஊழியர்களின் பதிவுகள், ஊதியங்கள் மற்றும் அடிப்படைத் தகவல்களை HR முறைமைகளுடன் ஒருங்கிணைக்கவும்.',
                paragraph: 'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் வேலை நேரங்களை கண்காணிக்கவும்.',
            },
            {
                header: 'ஊழியர் அட்டவணைகளை விரைவாக்க கமாண்ட் மேலாண்மை முறைமைகளைப் பயன்படுத்தவும்.',
                paragraph: 'பல கிளைகள் அல்லது பாகங்களில் வருகை அறிக்கைகளை தொலைவிலிருந்து அணுகவும்.',
            },
            {
                header: 'நிரந்தர செயல்பாடுகள் அத்தியாவசியமான உற்பத்தி மற்றும் தயாரிப்பு பகுதிகளின் அட்டவணைகளை கண்காணிக்கவும்.',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
            {
                header: 'மருத்துவத் துறையில் தொடர்புடைய விதிமுறைகள் மற்றும் தரநிலைகளுடன் அனைத்துத் தகவல்களை ஒத்துழைக்கவும்.',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
        ],
    },
    education: {
        industryHeading: 'மாணவர்களுக்கான ஒருங்கிணைந்த CRM, குறைவான வேலை துவக்கம் மற்றும் நற்பெயர்படுத்தலுக்கான வசதி',
        featureHeader: 'கல்வி',
        featureParagraph:
            'மாணவர் ஆட்சேர்ப்பு மற்றும் Admissions \n மாணவர் ஈடுபாடு மற்றும் தக்கவைக்கும் \n பல இடங்களில் Admissions ஐ மேலாண்மை செய்யுங்கள் \n தனிப்பயன் மாணவர் ஆதரவு',
        keyPoints: [
            {
                header: 'முதல் தொடர்பில் இருந்து விண்ணப்ப செயல்முறையின் அனைத்து நிலைகளையும் கண்காணிக்கவும்.',
                paragraph: 'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் வேலை நேரங்களை கண்காணிக்கவும்.',
            },
            {
                header: 'சிறந்த விண்ணப்பங்களை கண்டறியவும், முறையற்ற மாணவர்களை மேலாண்மை செய்யவும், வருகைகளை பதிவு செய்யவும்.',
                paragraph: 'பல கிளைகள் அல்லது பாகங்களில் வருகை அறிக்கைகளை தொலைவிலிருந்து அணுகவும்.',
            },
            {
                header: 'மாணவர்களின் பதிவு செயல்முறையின் பல்வேறு நிலைகளை காட்சிப்படுத்தவும், மேலாண்மை செய்யவும்.',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
            {
                header: 'விண்ணப்ப செயல்முறையை தனிப்பயன் ஆன்லைன் படிவங்களுடன் எளிமைப்படுத்தவும்.',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
        ],
    },
    fieldAndSales: {
        industryHeading:
            'விற்பனை குழுவின் செயல்திறனை மேம்படுத்தவும், நேரடி நிலை கண்காணிப்பு, திறமையான பாதை திட்டமிடல் மற்றும் வாடிக்கையாளர் ஈடுபாட்டை உறுதிப்படுத்தவும்.',
        featureHeader: 'கள விற்பனை',
        featureParagraph:
            'வெற்றி அடையவும் உங்கள் மைதான விற்பனையை மேம்படுத்தவும், திட்டமிடல், மாற்றுதல் மற்றும் நிறைவேற்றல் மூலம். TraceForce கள விற்பனை கண்காணிப்பு மற்றும் CRM மேலாண்மையை எளிதாக்குகிறது.',
        keyPoints: [
            {
                header: 'நேரடி வருகை',
                paragraph:
                    'Geofence பகுதி அல்லது கிளையின் உள்ளே உள்ள நபர்களின் இருப்பு அல்லது இல்லாத குறித்த நேர தகவல்களைப் பெறுங்கள்.',
            },
            {
                header: 'மொத்த வேலை நேர கணக்கீடு',
                paragraph: 'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் வேலை நேரங்களை கண்காணிக்கவும்.',
            },
            {
                header: 'தொலைவிலிருந்து வருகை அறிக்கைகளை அணுகவும்',
                paragraph: 'பல கிளைகள் அல்லது பாகங்களில் வருகை அறிக்கைகளை தொலைவிலிருந்து அணுகவும்.',
            },
            {
                header: 'மையமாக்கப்பட்ட வருகை மேலாண்மை',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
        ],
    },
    fmcg: {
        featureHeader: 'FMCG',
        industryHeading:
            'செயல்திறன் அளவுகோல்கள், செலவுகளின் மேலாண்மை மற்றும் ஒப்பந்த கண்காணிப்பு, விற்பனை குழுவின் மேலாண்மை, வாடிக்கையாளர் ஈடுபாட்டை மேம்படுத்தவும், தொடர்பு மற்றும் ஒத்துழைப்பு அம்சங்களைச் சேர்க்கவும்.',
        featureParagraph:
            'நேரடி வருகை கண்காணிப்பு சந்தை தேவையின் அடிப்படையில் பணியாளர் அளவுகளை விரைவாக சரிசெய்ய உதவுகிறது, FMCG செயல்பாடுகளின் திறனை அதிகரிக்கிறது.',
        keyPoints: [
            {
                header: 'துல்லியமான வருகை கண்காணிப்பு',
                paragraph:
                    'Geofence பகுதி அல்லது கிளையின் உள்ளே உள்ள நபர்களின் இருப்பு அல்லது இல்லாத குறித்த நேர தகவல்களைப் பெறுங்கள்.',
            },
            {
                header: 'துல்லியமான ஊதிய செயல்முறை, தவறுகளை குறைத்து நிதி வேறுபாடுகளை குறைத்தல்.',
                paragraph: 'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் வேலை நேரங்களை கண்காணிக்கவும்.',
            },
            {
                header: 'வேலையளவுகளுக்கு சட்டங்களைப் பின்பற்றுதல்',
                paragraph: 'பல கிளைகள் அல்லது பாகங்களில் வருகை அறிக்கைகளை தொலைவிலிருந்து அணுகவும்.',
            },
            {
                header: 'திறமையான விடுப்பு மேலாண்மை, பணியாளர் குறைபாடுகளைத் தடுக்கவும்',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
            {
                header: 'தொடர்ந்த உற்பத்தி மற்றும் விநியோகத்தை உறுதிப்படுத்தல்',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
            {
                header: 'துல்லியமான மாற்றம் செயல்முறை',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
            {
                header: 'ஆணைகளை கண்காணித்து நிறைவேற்றல்',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
        ],
    },
    services: {
        industryHeading:
            'திட்ட மற்றும் பணியாளர் மேலாண்மையை சீராக்கவும், நேரம் மற்றும் வருகை பதிவுகளை துல்லியமாக பராமரிக்கவும், ஊதிய செயல்முறை மற்றும் ஒப்பந்தங்களை உதவவும்.',
        featureHeader: 'சேவைகள்',
        featureParagraph:
            'களத்தில் சோதிக்கப்பட்ட அம்சங்கள் உற்பத்தி திறனை அதிகரிக்க, சிக்கல்களை குறைக்க மற்றும் பணிகளை தானாகவே செய்ய உதவுகிறது.',
        keyPoints: [
            {
                header: 'நேரடி கள ஊழியர் GPS கண்காணிப்பு',
                paragraph:
                    'Geofence பகுதி அல்லது கிளையின் உள்ளே உள்ள நபர்களின் இருப்பு அல்லது இல்லாத குறித்த நேர தகவல்களைப் பெறுங்கள்.',
            },
            {
                header: 'தனிப்பயன் படிவங்கள் மற்றும் Geo-tagged புகைப்படங்கள்',
                paragraph: 'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் வேலை நேரங்களை கண்காணிக்கவும்.',
            },
            {
                header: 'எளிமையான டாஷ்போர்ட் & பதிவிறக்கம் செய்யக்கூடிய அறிக்கைகள்',
                paragraph: 'பல கிளைகள் அல்லது பாகங்களில் வருகை அறிக்கைகளை தொலைவிலிருந்து அணுகவும்.',
            },
            {
                header: 'கலெட்சன்களை மேலாண்மை மற்றும் கண்காணிக்கவும்',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
        ],
    },
    fieldOperations: {
        industryHeading:
            'தரவுக்கருத்து அடிப்படையிலான முடிவுகளை எடுக்கும் மற்றும் கள குழுக்கள் மற்றும் தலைமையகம் மத்தியிலான தகவல் பரிமாற்றத்தை மேம்படுத்தவும்.',
        featureHeader: 'கள நடவடிக்கைகள்',
        featureParagraph:
            'இயல்பற்ற செயல்பாடுகள் மற்றும் சேவையை எளிதாக்கவும், TraceForce முழுமையான தீர்வுடன். வேலை மேலாண்மை, வருகையை கண்காணிக்கவும், AI அடிப்படையிலான பாதை மேம்பாட்டை அதிகரிக்கவும் மற்றும் விற்பனை வருமானத்தை அதிகரிக்கவும்.',
        keyPoints: [
            {
                header: 'திட்டமிடல் மற்றும் பணிகளை கண்காணிக்கவும்',
                paragraph:
                    'Geofence பகுதி அல்லது கிளையின் உள்ளே உள்ள நபர்களின் இருப்பு அல்லது இல்லாத குறித்த நேர தகவல்களைப் பெறுங்கள்.',
            },
            {
                header: 'அனைத்து கள ஊழியர்களின் நேரடி இடத்தைப் கண்காணிக்கவும்',
                paragraph: 'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் வேலை நேரங்களை கண்காணிக்கவும்.',
            },
            {
                header: 'செலவுப் படிவங்களை சான்றளிக்கவும் மற்றும் செயலாக்கவும்',
                paragraph: 'பல கிளைகள் அல்லது பாகங்களில் வருகை அறிக்கைகளை தொலைவிலிருந்து அணுகவும்.',
            },
            {
                header: 'SLA குறிக்கோள்களை அமைக்கவும் மற்றும் தாமதங்களை கண்காணிக்கவும்',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
            {
                header: 'எளிமையான டாஷ்போர்ட் & பதிவிறக்கம் செய்யக்கூடிய அறிக்கைகள்',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
            {
                header: 'OTP உறுதிப்படுத்தப்பட்ட பணிகள் நிறைவேற்றல்',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
        ],
    },
    logisticsAndTransportation: {
        industryHeading:
            'தரவுக்கருத்து அடிப்படையிலான முடிவுகளை எடுக்கும் மற்றும் கள குழுக்கள் மற்றும் தலைமையகம் மத்தியிலான தகவல் பரிமாற்றத்தை மேம்படுத்தவும்.',
        featureHeader: 'மொழிபெயர்ப்பு மற்றும் போக்குவரத்து',
        featureParagraph:
            'இயல்பற்ற மற்றும் சரியான வகையில் போக்குவரத்து மற்றும் விநியோகத்தை ஒழுங்குபடுத்தவும். பெரிய பணியாளர் படைவலத்தைப் மேலாண்மை செய்யவும் பல்துறை மற்றும் முன்னணி அம்சங்களுடன்.',
        keyPoints: [
            {
                header: 'விரைவில் வழங்குநரின் இடத்தைப் பெறுங்கள்.',
                paragraph:
                    'Geofence பகுதி அல்லது கிளையின் உள்ளே உள்ள நபர்களின் இருப்பு அல்லது இல்லாத குறித்த நேர தகவல்களைப் பெறுங்கள்.',
            },
            {
                header: 'செயல்திறனை மேம்படுத்தவும் செயல்பாடுகளை மற்றும் வேலைநடத்தை கண்காணிக்கவும்.',
                paragraph: 'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் வேலை நேரங்களை கண்காணிக்கவும்.',
            },
            {
                header: 'பணியாளர்களின் பணிகளை எளிமைப்படுத்தவும்.',
                paragraph: 'பல கிளைகள் அல்லது பாகங்களில் வருகை அறிக்கைகளை தொலைவிலிருந்து அணுகவும்.',
            },
            {
                header: 'வர்த்தக பயண பாதைகளை மேலும் நன்றாகப் பரிசீலிக்க, வரலாற்றுப் புள்ளிவிபரங்கள் மற்றும் நேரடி போக்குவரத்து தகவல்களைப் பயன்படுத்தவும்.',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
            {
                header: 'தானாகவே செலவீனத்தை மற்றும் ஊதியங்களை செயலாக்கவும்.',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
            {
                header: 'தானாகவே கள வேலைகளை மேற்கொண்டு வாடிக்கையாளர் சேவையை மேம்படுத்தவும்.',
                paragraph: 'வருகை தொடர்புடைய தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒருங்கிணைக்கவும்.',
            },
        ],
    },
};
export default industries;
