const joinUsPage = {
    joinUsString: 'আমাদের সাথে যোগদান করুন',
    joinUsHeroPara:
        'TraceForce-এ আমাদের দলের অংশ হতে আসুন! টিমওয়ার্ক এবং সৃজনশীলতার মাধ্যমে উত্তেজনাপূর্ণ সুযোগগুলি অন্বেষণ করুন। জানুন কীভাবে আপনি আমাদের মিশনকে সহায়তা করতে পারেন যা ব্যবসাগুলিকে বুদ্ধিমান সফটওয়্যার সমাধানের মাধ্যমে ক্ষমতায়িত করে। একটি সহায়ক পরিবেশে আপনার ক্যারিয়ার বৃদ্ধি করুন যা আপনার অনন্য প্রতিভা এবং আইডিয়াকে মূল্য দেয়।',
    ourRecruitmentProcess: 'আমাদের নিয়োগ প্রক্রিয়া',
    ourBenifitsString: 'আমাদের সুবিধা',
    ourRecruitmentProcessObjects: [
        {
            title: 'প্রযুক্তিগত সাক্ষাৎকার',
            description:
                'এই সাক্ষাৎকারটি আপনার প্রযুক্তিগত দক্ষতা, সমস্যা সমাধানের সক্ষমতা এবং বাস্তব পরিস্থিতিতে আপনার জ্ঞান প্রয়োগের কৌশল মূল্যায়ন করতে ডিজাইন করা হয়েছে। আমরা আপনার প্রযুক্তিগত দক্ষতা, কোডিং ক্ষমতা এবং চ্যালেঞ্জ মোকাবিলার পদ্ধতি জানার চেষ্টা করি। এই রাউন্ডটি মিট/স্কাইপ কলের মাধ্যমে সম্পন্ন হয়।',
        },
        {
            title: 'প্র্যাকটিস টেস্ট (এপটিটিউড)',
            description:
                'আমরা কোডিং লজিক এবং কোড অপ্টিমাইজেশন মূল্যায়ন করি একটি টাস্ক নির্ধারণের মাধ্যমে যা আপনার প্রথম রাউন্ড সাক্ষাৎকারের ভিত্তিতে পাঠানো হবে। অ্যাসাইনমেন্টটিকে একটি নির্দিষ্ট সময়সীমা দেওয়া হবে যার মধ্যে প্রার্থীকে এটি সম্পন্ন করতে হবে।',
        },
        {
            title: 'ম্যানেজমেন্ট সাক্ষাৎকার',
            description:
                'আমাদের ব্যবস্থাপনার সাথে একটি দ্রুত কথোপকথন আপনার ক্যারিয়ার ভিশন এবং আমাদের সংগঠনে সাংস্কৃতিক ফিট বুঝতে। আমরা একটি দৃঢ় বিষয়ভিত্তিক জ্ঞান এবং শক্তিশালী কর্ম নৈতিকতা সহ ব্যক্তির সন্ধান করি।',
        },
        {
            title: 'চূড়ান্ত নির্বাচন',
            description:
                'অফার চূড়ান্তকরণ HR দলের সাথে সম্পন্ন হবে। আমরা সমস্ত সাক্ষাৎকারের রাউন্ডের পারফরম্যান্সের গভীর সংহতির ভিত্তিতে আমাদের চূড়ান্ত নির্বাচন করি। পারস্পরিক সিদ্ধান্তের ভিত্তিতে যোগদানের তারিখ নিশ্চিত করা হবে, এবং আমরা আপনাকে প্রথম দিনেই সেরা টুলস প্রদান করব।',
        },
    ],
    ourBenifitsObjects: [
        {
            name: 'যেকোনো জায়গা থেকে কাজ করুন!',
            description:
                "ভাবুন, আপনার বাড়ির অফিস কুকুরের জন্য খুবই বন্ধুপ্রতীম এবং আপনার 'মিটিং রুম' বর্তমানে একটি বিড়াল নাপের দ্বারা দখল করা হয়েছে। আমরা যেহেতু আপনাকে প্রতিদিন দেখতে চাই, তাই 'যেকোনো জায়গা থেকে কাজ করা' অফার করি না।",
        },
        {
            name: 'বিকল্প সপ্তাহান্ত',
            description:
                'যখন আপনার সপ্তাহান্তগুলি আপনার মেজাজের পরিবর্তনের মতো পরিবর্তিত হয়। এক সপ্তাহ, আপনি মজার একটি যাত্রা পরিকল্পনা করছেন বা ঘুমাচ্ছেন, এবং পরের সপ্তাহে, আপনি ডেডলাইন মোকাবিলা করছেন এবং ইমেইল উত্তর দিচ্ছেন। এটি কাজ এবং খেলার মধ্যে ভারসাম্য বজায় রাখার একটি অংশ।',
        },
        {
            name: 'স্বাস্থ্য বীমা',
            description:
                "সব সেই ‘আমি এটার জন্য ডাক্তার দরকার হতে পারে’ মুহূর্তগুলির জন্য। সম্পূর্ণ কভারেজের সাথে, আপনাকে 'কি হলে' নিয়ে চিন্তা করতে হবে না—কেবলমাত্র ভাল হওয়া এবং আপনার সেরা আত্মে ফিরে আসার দিকে মনোযোগ দিন। কারণ আপনি যদি আপনার সেরা বোধ না করেন, তবে আপনি সেরা যত্ন পাওয়ার যোগ্য।",
        },
        {
            name: 'রেফারেল বোনাস',
            description:
                'বন্ধুরা আছে? বোনাস পেতে পারেন! এটি একটি লাভ-লাভ। কল্পনা করুন: আপনার একটি বন্ধুদের চক্র আছে যারা তাদের কাজে চমৎকার এবং নতুন সুযোগের সন্ধান করছে। তাদের আমাদের কোম্পানিতে রেফার করার মাধ্যমে, আপনি কেবল তাদের একটি দুর্দান্ত চাকরি খুঁজে পেতে সাহায্য করছেন না, বরং একটি মিষ্টি রেফারেল বোনাসও পাচ্ছেন।',
        },
        {
            name: 'এমপ্লয়ি কিট',
            description:
                'এমপ্লয়ি কিট - কাজের দিনের জন্য আপনাকে conquer করার জন্য প্রয়োজনীয় সমস্ত কিছু, তবে আপনার একটি কপি ছাড়া। মৌলিক অফিস সরঞ্জাম থেকে উত্সাহজনক জিনিসপত্র পর্যন্ত, এই কিটে সবই রয়েছে। একটি কলম প্রয়োজন? চেক। আপনার সকল দারুণ আইডিয়ার জন্য নোটবুক? চেক।',
        },
        {
            name: 'ফ্লেক্সিবল ওয়ার্কিং আওয়ার্স',
            description:
                'আপনার প্রিয় শো দেখার জন্য বাড়ি ফিরে তাড়াহুড়ো করার দরকার নেই; এখন, আপনার অফিস হতে পারে আপনার সোফা, এবং আপনার বিরতি সময় প্রাইম টাইম হতে পারে। ফ্লেক্সিবল আওয়ার্সের সাথে, আপনি কনফারেন্স কল থেকে ক্লিফহ্যাঙ্গার, স্প্রেডশিট থেকে নেটফ্লিক্স শো দেখতে সহজভাবে পরিবর্তন করতে পারেন।',
        },
        {
            name: 'ছুটির ছুটি',
            description: 'ছুটির ছুটি',
        },
        {
            name: 'প্যাটার্নিটি/ মাতৃত্বকালীন ছুটি',
            description: 'প্যাটার্নিটি/ মাতৃত্বকালীন ছুটি',
        },
        {
            name: 'কর্মজীবন-ব্যালান্স',
            description: 'কর্মজীবন-ব্যালান্স',
        },
        {
            name: 'অফিস উদযাপন',
            description: 'অফিস উদযাপন',
        },
    ],
    jobsAtTraceForce: 'TraceForce-এ চাকরি',
};
export default joinUsPage;
