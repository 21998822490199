const footer = {
    requestACallBack: 'একটি কলব্যাক অনুরোধ করুন',
    freeAccountMessage: 'এখনই আপনার ফ্রি ১৪ দিনের অ্যাকাউন্ট তৈরি করুন',
    footerMessage:
        'অ্যাডমিনিস্ট্রেটিভ কাজ অটোমেট করার, প্রক্রিয়াগুলি সরল করার এবং কর্মচারীদের অংশগ্রহণ বাড়ানোর মাধ্যমে কর্মচারী ব্যবস্থাপনা এবং বিক্রয় অপারেশনকে বিপ্লবিত করা। আমাদের বৈশিষ্ট্যগুলি সহজ, শক্তিশালী এবং যেকোনো শিল্পের জন্য সহজেই উপযুক্ত, যা সংগঠনের উৎপাদনশীলতা বৃদ্ধি করে।',
    address:
        '৯২২, এ উইং, নিয়াতি এমপ্রেস, বেকার এবং গেজেস ইন্ডিয়া প্রাইভেট লিমিটেডের পাশে, ভিমান নগর রোড, ভিমান নগর, ৪১১০১৪, পুনে।',
    rightsReserved: 'ট্রেসফোর্স সর্বস্বত্ব সংরক্ষিত',
};
export default footer;
