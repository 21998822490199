const features = {
    attendanceMonitoring: {
        featureSubcaption:
            "L'efficacité dans chaque instant - Solutions innovantes pour les systèmes de gestion des employés",
        featureHeader: "Suivi de l'Assiduité",
        featureDescription:
            "Informations en temps réel sur l'heure d'arrivée et de départ concernant la présence ou l'absence des individus dans la zone de géorepérage ou sur site",
        featureParagraph:
            "Fini les cartes de pointage, la paperasse excessive et les documents divers pour vérifier l'assiduité des employés. Automatisez le processus de suivi des présences avec WebWork Time Tracker et visualisez les heures de début et de fin des journées de vos employés dans un format bien structuré.",
        featureCaption:
            "Gérez l'assiduité de vos employés sur plusieurs sites, qu'ils soient sur le terrain ou sur place",
        keyPoints: [
            {
                header: 'Assiduité en Temps Réel',
                paragraph:
                    "Informations en temps réel sur la présence ou l'absence des individus dans la zone de géorepérage ou sur site",
            },
            {
                header: 'Calcul des Heures de Travail Totales',
                paragraph: 'Suivi des heures de travail sur une base quotidienne, hebdomadaire et mensuelle',
            },
            {
                header: "Rapports d'Assiduité Accès à Distance",
                paragraph: 'Accédez aux rapports de présence de plusieurs sites à distance',
            },
            {
                header: 'Gestion Centralisée des Présences',
                paragraph:
                    "Consolidation des données, processus et systèmes liés à l'assiduité dans une seule plateforme centralisée",
            },
        ],
        softwareDetails: [
            {
                title: 'Obtenez des informations sur le suivi des présences des employés',
                description: [
                    'Obtenez une transparence sur la ponctualité des employés',
                    "Gagnez du temps et organisez l'assiduité des employés sans aucune action supplémentaire.",
                    "Surveillez facilement si vos employés sont arrivés à l'heure et ont terminé leurs heures de travail.",
                    'Gardez vos opérations sur le lieu de travail mieux organisées et assurez un environnement de travail hautement responsable.',
                ],
                sDKeys: [
                    'Enregistrez automatiquement les entrées et sorties du personnel sur place en configurant un périmètre géorepéré.',
                    'Recevez des alertes pour les employés qui oublient ou ne parviennent pas à enregistrer leurs entrées ou sorties.',
                    "Localisez et suivez les mouvements de votre personnel de terrain en temps réel dès qu'ils enregistrent leur présence.",
                ],
            },
            {
                title: 'Obtenez des rapports détaillés de suivi des présences',
                description: [
                    "Analyse intelligente des données d'assiduité",
                    "Filtrez les départs anticipés, les arrivées tardives et le manque de suivi à partir d'une liste déroulante.",
                    'Cela vous aidera à générer instantanément les informations de votre choix sans avoir à les rechercher manuellement.',
                    'Vous pouvez suivre la présence quotidiennement ou choisir un intervalle dans le calendrier.',
                ],
                sDKeys: [
                    "Obtenez des résumés d'assiduité pour votre organisation.",
                    'Générez et utilisez des rapports pour visualiser les tendances au sein de votre équipe, de vos départements ou de vos agences.',
                    "Recevez des rapports à intervalles réguliers dans votre boîte mail (heure d'entrée, heure de sortie, heures de travail totales, sessions totales).",
                    'Données perspicaces pour une meilleure prise de décision.',
                ],
            },
            {
                title: "Explorez d'autres fonctionnalités de temps et de productivité",
                description: [
                    "Un moyen sûr et transparent de surveiller et suivre l'assiduité des employés en temps réel avec un système 100% sans contact.",
                    'Installation facile',
                    'Enregistrements automatisés des présences et rapports détaillés',
                    "Captures d'écran avec suivi du temps",
                    "Enregistrement du niveau d'activité",
                    "Suivi de l'utilisation des applications et des sites Web",
                ],
                sDKeys: [
                    "L'assiduité des employés a prouvé son efficacité pour la gestion du temps et l'amélioration de la productivité.",
                    "Éliminez la paperasse et recueillez des données précises sur l'assiduité des employés.",
                ],
            },
        ],
    },

    leaveManagement: {
        featureHeader: 'Gestion des Congés',
        feaatureSubcaption:
            'Coordonnez les absences du personnel tout en maintenant la productivité et la continuité des opérations.',
        featureDescription:
            'Gérez et suivez efficacement les absences et les présences, en garantissant une documentation appropriée, le respect des politiques de l’entreprise et le maintien d’un flux de travail fluide.',
        featureParagraph:
            "Fini les cartes de pointage, la paperasse excessive et les documents divers pour vérifier l'assiduité des employés. Automatisez le processus de suivi de l'assiduité avec WebWork Time Tracker et visualisez les heures de début et de fin des journées de vos employés dans un format bien structuré.",
        featureCaption: 'Restez informé des congés à venir de vos employés et planifiez votre journée en conséquence.',
        keyPoints: [
            {
                header: 'Calcul Automatique des Congés Accumulés et des Soldes',
                paragraph:
                    "Gérez l'accumulation des congés, assurez-vous que les employés ne dépassent pas les montants alloués et évitez les soldes de congés négatifs.",
            },
            {
                header: 'Politiques de Congés Flexibles, Flux de Travail d’Approbation',
                paragraph:
                    "Configurez des politiques de congés flexibles pour l'organisation en définissant le calendrier des congés. Activez une hiérarchie parallèle ou séquentielle pour l'approbation ou le refus des demandes de congés.",
            },
            {
                header: 'Respect des Lois du Travail et des Politiques de l’Entreprise',
                paragraph:
                    "Restez informé des lois du travail et des réglementations relatives aux droits aux congés. Assurez-vous que vos politiques de gestion des congés sont conformes aux politiques de l'entreprise.",
            },
            {
                header: 'Options de Libre-Service',
                paragraph:
                    'Offrez aux employés des options de libre-service, leur permettant de vérifier leurs soldes de congés, de soumettre des demandes et de consulter l’état de leurs demandes.',
            },
        ],
        softwareDetails: [
            {
                title: 'Politiques de Congés Multiples et Flexibles pour votre Équipe',
                description: [
                    'Politiques de congés flexibles pour répondre exactement à vos besoins avec des paramètres configurables.',
                    'Différents régimes et politiques de congés adaptés à divers groupes d’employés.',
                    'Types de congés illimités et transactions de congés supportés.',
                ],
                sDKeys: [
                    'Ces politiques incluent des types de congés tels que les congés annuels, les congés maladie, les congés parentaux, les congés de deuil, etc.',
                    "Définir les critères d'éligibilité pour les politiques.",
                    'Taux d’accumulation, règles d’utilisation, documentation requise.',
                ],
            },
            {
                title: 'Libre-Service pour la Demande de Congé et Flux de Travail d’Approbation',
                description: [
                    "L'automatisation réduit les efforts des employés. Les employés demandent un congé via le portail Web ou l'application mobile.",
                    'Suivi des soldes de congés et des transactions.',
                    'Vue des données de congés de l’équipe par le manager et analyse des schémas de congés pour de meilleures informations.',
                    'Les workflows avec escalades permettent un système réactif et une excellente expérience pour les employés.',
                    'Notifications et alertes par email.',
                ],
                sDKeys: [
                    "Les employés demandent un congé via le portail Web ou l'application mobile.",
                    'Suivi des soldes de congés et des transactions.',
                    'Vue des données de congés de l’équipe par le manager et analyse des schémas de congés pour de meilleures informations.',
                    'Les workflows avec escalades permettent un système réactif et une excellente expérience pour les employés.',
                    'Notifications et alertes par email.',
                ],
            },
            {
                title: 'Conformité avec les Politiques de l’Entreprise et la Législation du Travail',
                description: [
                    'Une gestion efficace des congés des employés assure non seulement la conformité avec les lois du travail, mais contribue également à une culture de travail positive. En mettant en œuvre des politiques de congés efficaces et en utilisant la technologie, les organisations peuvent rationaliser le processus et soutenir le besoin de leurs employés de prendre des congés.',
                ],
                sDKeys: [
                    'Gérez les responsabilités liées aux congés pour le budget efficacement et minimisez les risques financiers.',
                    'Générez des données statistiques pour des rapports individuels afin de suivre les performances mensuelles, trimestrielles ou annuelles.',
                    "Restez informé des stratégies de planification des effectifs, d'allocation des ressources et d'engagement des employés.",
                    'Dossiers détaillés et rapports conformes aux règles gouvernementales.',
                    'Liste complète et personnalisée des jours fériés pour une main-d’œuvre diversifiée.',
                ],
            },
        ],
    },
    shiftManagement: {
        featureHeader: 'Gestion des Plannings',
        feaatureSubcaption:
            'Planifiez le personnel avec les activités de vente, assurez un niveau de dotation optimal pour répondre aux demandes des clients et maximiser la productivité.',
        featureDescription:
            'Organisez et planifiez les heures de travail de manière à optimiser la productivité, répondre aux besoins opérationnels et garantir une couverture sur différentes périodes.',
        featureParagraph:
            "Fini les cartes de pointage, la paperasse excessive et les documents divers pour vérifier l'assiduité des employés. Automatisez le processus de suivi des présences avec WebWork Time Tracker et visualisez les heures de début et de fin des journées de vos employés dans un format bien structuré.",
        featureCaption:
            'Suivez les plannings de vos employés en fonction des heures de travail totales et des sessions multiples agrégées.',
        keyPoints: [
            {
                header: 'Gérer les Horaires de Travail des Employés',
                paragraph:
                    'Développez un planning clair et bien organisé. Définissez différents types de shifts en fonction de la nature de votre entreprise.',
            },
            {
                header: 'Définir les Shifts, Assigner des Rôles, Configurer des Horaires Récurrents ou Personnalisés',
                paragraph:
                    'Analysez la charge de travail et les tâches pour déterminer le nombre d’employés nécessaires durant les différentes périodes de travail.',
            },
            {
                header: 'Outils de Communication Intégrés',
                paragraph:
                    'Plateforme centralisée pour que les employés accèdent à leurs plannings, et établissent un canal de communication fiable pour toute mise à jour ou modification.',
            },
            {
                header: 'Gestion Centralisée des Shifts avec Plannings pour Plusieurs Sites',
                paragraph:
                    "Supervision et contrôle centralisés des plannings des employés, permettant aux managers d'allouer efficacement les shifts, de suivre les présences et de gérer les ressources humaines à travers plusieurs sites à partir d'une seule plateforme.",
            },
        ],
        softwareDetails: [
            {
                title: 'Automatisez le Processus de Planification en Fonction des Lieux et des Shifts',
                description: [
                    'Analysez la charge de travail et les tâches pour déterminer le nombre d’employés nécessaires durant les différentes périodes de travail.',
                    'Tenez compte des heures de pointe, des variations saisonnières et des exigences spécifiques pour chaque shift. Cela peut inclure les shifts de jour, de nuit, les shifts rotatifs, les shifts fractionnés ou les horaires flexibles.',
                ],
                sDKeys: [
                    'Échange de shifts entre employés.',
                    'Les employés peuvent échanger ou changer leurs shifts avec d’autres collègues grâce à la fonctionnalité disponible.',
                    'Les employés peuvent choisir leurs shifts et leurs congés qui leur conviennent le mieux.',
                ],
            },
            {
                title: 'Tableau des Shifts pour une Planification Dynamique',
                description: [
                    'Assurez-vous de respecter les lois du travail concernant les pauses et les périodes de repos.',
                    'Incluez des pauses dans le planning des shifts pour éviter la fatigue des employés et maintenir la productivité.',
                    'Assurez-vous que vos politiques de gestion des shifts sont conformes aux exigences légales.',
                ],
                sDKeys: [
                    'Construisez sans effort des plannings de travail efficaces pour votre équipe à l’aide d’outils de planification conviviaux.',
                    'Adaptez les shifts aux besoins opérationnels de votre entreprise et à la disponibilité des employés.',
                    'Assurez une répartition équitable des shifts tout en tenant compte des préférences des employés et de la charge de travail.',
                ],
            },
            {
                title: 'Encouragez les Employés avec des Indemnités de Shift pour Travailler à des Heures Inhabituelles',
                description: [
                    'Intégrez les systèmes de gestion des shifts aux processus de paie pour garantir une compensation et des déductions précises en fonction des shifts effectués.',
                    'Définissez les procédures pour les heures supplémentaires et assurez-vous que les politiques de surtemps respectent les réglementations du travail.',
                ],
                sDKeys: [
                    'Approuvez les demandes de shift des employés en un seul clic.',
                    'Les employés peuvent choisir leurs propres shifts au début de la semaine/mois et envoyer des demandes depuis leur portail selon leur convenance.',
                    "Solution rentable en versant des indemnités de shift en fonction du taux horaire ou de la politique de l'entreprise.",
                ],
            },
        ],
    },
    geoFenceBasedManagement: {
        featureHeader: 'Présence Basée sur Geo Fence',
        feaatureSubcaption:
            'Maximisez la précision et la responsabilité en suivant la présence des employés dans des zones définies.',
        featureDescription:
            'Technologie basée sur la localisation pour surveiller et vérifier la présence des employés à l’intérieur des frontières géographiques prédéfinies.',
        featureCaption:
            'La présence basée sur Geo Fence améliore la précision de votre suivi de présence, les employés étant marqués présents uniquement lorsqu’ils se trouvent dans la zone désignée.',
        keyPoints: [
            {
                header: 'Localisez Précisément les Employés dans la Zone Geo-Fencée Désignée',
                paragraph:
                    'Des enregistrements de présence précis pour garantir que les employés se trouvent à leurs lieux de travail désignés.',
            },
            {
                header: 'Surveillance en Temps Réel des Localisations des Employés',
                paragraph:
                    'Mettez en place un suivi en temps réel pour surveiller les localisations des employés dans la zone geo-fencée.',
            },
            {
                header: 'Définir des Limites Géographiques Spécifiques pour l’Enregistrement des Entrées et Sorties',
                paragraph:
                    'Établissez des limites géographiques spécifiques, connues sous le nom de geo-fences, autour du lieu de travail ou des emplacements de présence désignés.',
            },
            {
                header: 'Alertes Geo-Fence',
                paragraph:
                    'Configurez des alertes ou notifications pour informer les managers ou le personnel RH lorsque les employés entrent ou sortent des limites définies du geo-fence.',
            },
        ],
        softwareDetails: [
            {
                title: 'Restriction de Localisation pour la Marque de Présence',
                description: [
                    'Établir des limites géographiques spécifiques en utilisant des coordonnées GPS ou des technologies basées sur la localisation. Ces limites représentent les zones où les employés doivent être présents pour travailler.',
                ],
                sDKeys: [
                    'Fonctionnalité facultative de restriction utilisateur pour permettre la présence uniquement depuis une adresse IP spécifique afin d’assurer une présence dans les environs du bureau.',
                    'Facilitez la communication avec des utilisateurs spécifiques ou tous les utilisateurs grâce à la fonctionnalité de notifications push.',
                    'Associez les tâches aux présences des employés pour voir les tâches quotidiennes et les plannings.',
                ],
            },
            {
                title: 'Configurer des Restrictions de Présence et une Authentification Biométrique',
                description: [
                    'Mettre en place un suivi de localisation en temps réel pour surveiller les localisations des employés dans les zones geo-fencées. Ces informations sont utilisées pour confirmer la présence et garantir que les employés se trouvent aux lieux de travail désignés durant les heures prévues.',
                ],
                sDKeys: [
                    'Les employés ne peuvent marquer leur présence qu’une fois arrivés à leur premier lieu de tâche.',
                    'Les employés peuvent marquer leur présence à distance; plus besoin de venir au bureau juste pour pointer leur présence.',
                    'Les managers peuvent suivre la localisation en temps réel et recevoir des mises à jour à distance via les applications mobiles ou le web.',
                ],
            },
            {
                title: 'Renforcez la Sécurité et l’Autorisation',
                description: [
                    'Mettez en œuvre des mesures d’authentification sécurisées pour empêcher les entrées frauduleuses de présence en utilisant des codes PIN, une authentification biométrique et la reconnaissance faciale.',
                    'Configurez des notifications mobiles ou des alertes pour les employés, leur rappelant de s’enregistrer lorsqu’ils entrent dans la zone geo-fencée. Cela garantit que les employés sont conscients du système de suivi de présence.',
                ],
            },
        ],
    },
    multipleClockInOptions: {
        featureHeader: 'Multiples Options de Pointage',
        feaatureSubcaption:
            'Offrant flexibilité et commodité, les multiples options de pointage permettent aux employés de gérer leur temps efficacement.',
        featureDescription:
            'Offrez aux employés la flexibilité d’enregistrer leurs heures de travail en utilisant des scanners biométriques, des applications mobiles, des horloges de pointage et des plateformes web.',
        featureCaption:
            'Facilitez l’utilisation pour les employés travaillant à distance et leur permettant de pointer plusieurs fois avec différentes sessions.',
        keyPoints: [
            {
                header: 'Adapté aux Organisations ayant Plusieurs Sites',
                paragraph:
                    'Options de pointage flexibles pour la gestion de la présence sur le terrain et hors terrain des différentes branches.',
            },
            {
                header: 'Portail Libre-Service pour les Employés',
                paragraph:
                    'Offrez aux employés l’accès à un portail libre-service pour consulter leurs enregistrements de présence et gérer leurs pointages à l’intérieur du geo-fence.',
            },
            {
                header: 'Multiples Options de Pointage',
                paragraph:
                    'Les options de pointage flexibles incluent les scans biométriques, les applications mobiles, les cartes RFID, et la reconnaissance faciale pour un suivi du temps fluide dans divers environnements de travail.',
            },
            {
                header: 'Pointage Précis Basé sur la Localisation',
                paragraph: 'Pointage automatique des employés dans la zone de l’entreprise avec notification d’alerte.',
            },
        ],
        softwareDetails: [
            {
                title: 'Pointage sur Site et Hors Site',
                description: [
                    'Adapté à des environnements de travail variés, notre système de gestion de la présence propose plusieurs options de pointage.',
                    'Que les employés soient sur site, sur le terrain, ou travaillent à distance, ils peuvent enregistrer leur temps facilement via des scans biométriques, la reconnaissance faciale, et le pointage automatique basé sur geo-fence.',
                ],
            },
            {
                title: 'Portail Libre-Service pour que les Employés Vérifient leurs Enregistrements',
                description: [
                    'Le portail libre-service des employés offre un contrôle sur les multiples pointages.',
                    'Avec des fonctionnalités conviviales, les employés peuvent facilement gérer leurs heures de travail qu’ils soient en transition entre différents quarts, lieux ou tâches.',
                    'Cela donne aux employés la flexibilité tout en maintenant des enregistrements de temps précis, améliorant ainsi l’efficacité et rationalisant les processus administratifs.',
                ],
            },
            {
                title: 'Multiples Pointages pour Plusieurs Branches',
                description: [
                    'Répondez aux besoins dynamiques de votre entreprise avec plusieurs sites et assurez un suivi précis de la présence ainsi qu’une efficacité opérationnelle.',
                ],
            },
        ],
    },
    attendanceReport: {
        featureHeader: 'Rapports de Présence',
        feaatureSubcaption:
            'Un reflet perspicace de l’engagement et de la ponctualité de l’équipe, capturé dans un seul rapport.',
        featureDescription:
            'Résumez les données de présence des employés, les heures de travail, les absences, les retards et les départs anticipés sur une période donnée.',
        featureCaption:
            'Générez des rapports automatisés pour différentes branches et recevez-les quotidiennement, hebdomadairement, mensuellement avec plus de 40 modèles.',
        keyPoints: [
            {
                header: 'Automatisez les Détails de la Présence : Heure de Connexion, Retards, Heures de Travail Totales, Total des Sessions',
                paragraph:
                    'Fournissez un résumé global de la présence, incluant le nombre total de jours travaillés, le nombre total d’heures travaillées, et toute heure supplémentaire.',
            },
            {
                header: 'Rapports de Plusieurs Branches',
                paragraph:
                    'Accédez à distance aux rapports de présence de plusieurs branches avec des catégories personnalisables.',
            },
            {
                header: 'Heures Supplémentaires et Soldes de Congés',
                paragraph:
                    'Affichez les soldes de congés pour chaque employé, y compris les jours de vacances, les congés maladie, et tout autre type de congé accumulé et utilisé.',
            },
            {
                header: 'Rapport de Présence Personnalisé',
                paragraph:
                    'Permet aux utilisateurs de personnaliser les rapports en fonction de critères spécifiques, tels que le département, l’équipe ou l’employé individuel.',
            },
        ],
        softwareDetails: [
            {
                title: 'Générez des rapports en fonction de la structure de votre organisation.',
                description: [
                    'Les rapports de présence générés et analysés régulièrement peuvent fournir des informations précieuses pour améliorer la gestion de la main-d’œuvre, identifier les tendances, et assurer la conformité aux politiques organisationnelles.',
                    'Les spécificités du rapport dépendront des besoins et de la structure de l’organisation.',
                ],
                sDKeys: [
                    'Les informations collectées auprès de divers utilisateurs sont rassemblées pour fournir des analyses automatisées, réduisant ainsi le temps et les ressources consacrés à la génération de rapports manuellement.',
                    'Les utilisateurs peuvent sélectionner les analyses requises en quelques clics et peuvent s’y abonner à tout moment.',
                    'Un échantillon de rapport d’analyse peut être téléchargé et visualisé comme une représentation réelle du rapport souhaité.',
                ],
            },
            {
                title: 'Obtenez facilement des rapports personnalisables.',
                description: [
                    'Identifiez et mettez en évidence toute exception ou irrégularité dans la présence, telle que des absences non autorisées, des retards excessifs ou des heures supplémentaires non approuvées.',
                    'Utilisez des graphiques ou des diagrammes pour représenter visuellement les tendances et les schémas de présence. Cela permet d’identifier plus facilement les anomalies et les tendances en un coup d’œil.',
                ],
                sDKeys: [
                    'Les utilisateurs peuvent bénéficier des informations générées de diverses manières, telles que le traitement des paies, les évaluations de performance, les remboursements, etc.',
                    'Les rapports peuvent être partagés avec d’autres utilisateurs en fonction du flux hiérarchique d’autorité.',
                    'L’entreprise peut être collectivement informée de l’état du travail et de la performance de son équipe de la manière la plus simple, sans aucune intervention manuelle.',
                ],
            },
            {
                title: 'Réduisez le temps passé à générer des rapports manuels.',
                description: [
                    'Inclut des détails fondamentaux tels que les noms des employés, les numéros d’identification, et les affiliations départementales pour fournir un contexte aux données de présence.',
                    'Spécifiez clairement la période couverte par le rapport. Cela aide les utilisateurs à comprendre la portée temporelle des données.',
                    'Fournissez un résumé global de la présence, incluant le nombre total de jours travaillés, le nombre total d’heures travaillées, et toute heure supplémentaire.',
                ],
            },
        ],
    },
    workforceTracking: {
        featureHeader: 'Suivi de la Main-d’Œuvre',
        featureDescription:
            'Surveillance systématique et gestion des activités, de la productivité et de la présence des employés.',
        featureParagraph:
            'Fini les cartes de pointage, la paperasse excessive et les différents documents pour vérifier la présence des employés. Automatisez le processus de suivi des présences avec WebWork Time Tracker et consultez les heures de début et de fin de travail de vos employés dans un format bien structuré.',
        featureCaption:
            "Surveillez et gérez divers aspects de la main-d'œuvre d'une organisation, y compris la présence des employés, les heures de travail, la productivité et les performances.",
        keyPoints: [
            {
                header: 'Suivi de la Présence et des Déplacements Basé sur une Ligne de Temps',
                paragraph:
                    "Surveillez des événements comme les horaires d'arrivée et de départ, ainsi que les informations des employés sur leurs opérations, métriques de performance ou événements.",
            },
            {
                header: 'Formulaires Personnalisés pour la Gestion des Données',
                paragraph:
                    'Pour les comptes rendus de réunion, stockez les données des réunions et des tâches pour suivre les tendances et faciliter la prise de décisions basées sur les données.',
            },
            {
                header: 'Gestion des Clients et des Prospects',
                paragraph:
                    'Suivez la productivité et mesurez les indicateurs clés de performance en fonction du volume de travail accompli par les employés.',
            },
            {
                header: 'Calculs Totaux des Distances pour les Demandes de Remboursement Exactes',
                paragraph:
                    'Suivez la distance exacte parcourue par les employés pour les travaux sur le terrain en fonction de données quotidiennes, hebdomadaires et mensuelles, et remboursez les frais en fonction de la distance et des lieux parcourus.',
            },
        ],
        softwareDetails: [
            {
                title: 'Mettez en place des systèmes de suivi du temps pour surveiller les heures de travail.',
                description: [
                    'Mise en place de solutions de suivi du temps pour surveiller le temps que les employés consacrent aux tâches et aux projets.',
                    'Cela permet de mieux comprendre l’utilisation des ressources et d’améliorer la gestion du temps.',
                    'Utilisez des systèmes de gestion de projets pour suivre l’avancement des projets, attribuer des tâches et surveiller les échéances.',
                    'Cela aide à optimiser les flux de travail des projets et à garantir leur achèvement dans les délais.',
                ],
            },
            {
                title: 'Surveillez les indicateurs clés de performance pertinents.',
                description: [
                    'Définition et suivi des indicateurs clés de performance en lien avec les objectifs de votre organisation.',
                    'Cela peut inclure les objectifs de vente, les indicateurs de satisfaction client ou d’autres indicateurs de performance.',
                ],
                sDKeys: [
                    'Performances commerciales : revenus de ventes mensuels/trimestriels, nombre de contrats conclus, taux de conversion des ventes, taille moyenne des contrats, valeur du pipeline de ventes.',
                    'Service client : score de satisfaction client (CSAT), score net de promotion (NPS), temps de première réponse, temps moyen de résolution, taux de rétention client, volume des tickets.',
                    'Opérations/Production : production/throughput, métriques de qualité (taux de défaut, taux d’erreur), performance des livraisons à temps, ratio de rotation des stocks, temps d’arrêt des équipements, taux d’utilisation des employés.',
                    'Général : productivité des employés (rendement par heure), présence et ponctualité, taux d’accomplissement des objectifs, retours d’évaluation et performances des employés.',
                ],
            },
            {
                title: 'Générez des rapports sur les tendances de votre main-d’œuvre.',
                description: [
                    'Intégration d’outils d’analytique et de génération de rapports pour obtenir des informations sur les tendances de la main-d’œuvre, les performances et les domaines à améliorer.',
                    'Des rapports personnalisés peuvent faciliter la prise de décision.',
                ],
                sDKeys: [
                    'Comparaison de la composition démographique avec la main-d’œuvre globale ou les références de l’industrie, et identification de toute tendance ou changement démographique au fil du temps.',
                    'Comparaison des indicateurs de performance entre les différentes équipes de terrain, régions ou périodes.',
                    'Cartographie géospatiale des activités de la force de terrain pour visualiser la distribution et les zones de couverture.',
                    'Analyse des performances de vente ou de service par région géographique ou territoire. Identification des zones à fort potentiel pour l’expansion ou l’optimisation.',
                ],
            },
        ],
    },
    fieldSalesAnalytics: {
        featureHeader: 'Analyse des Ventes sur le Terrain',
        featureDescription:
            'Suivez et analysez les indicateurs clés de l’équipe commerciale sur le terrain tels que les revenus de ventes, les interactions avec les clients et les tendances géographiques.',
        featureParagraph:
            'Fini les cartes de pointage, la paperasse excessive et les différents documents pour vérifier la présence des employés. Automatisez le processus de suivi des présences avec WebWork Time Tracker et consultez les heures de début et de fin de travail de vos employés dans un format bien structuré.',
        featureCaption:
            'Analysez la collecte, l’analyse et l’interprétation des données liées aux activités de ventes sur le terrain pour obtenir des informations et prendre des décisions éclairées.',
        keyPoints: [
            {
                header: 'Indicateurs Clés de Performance pour les Ventes et les Prospects',
                paragraph:
                    'Les indicateurs clés de performance peuvent aider à prendre des décisions basées sur les données, permettant ainsi de prendre des décisions productives pour la prochaine fois ou de réviser la stratégie.',
            },
            {
                header: 'Rapports Personnalisés',
                paragraph:
                    "Les rapports peuvent être consultés quotidiennement, hebdomadairement ou mensuellement sur les ventes, les prospects et l'acquisition de clients. Cela pourrait aider à améliorer la productivité et l'efficacité d'une personne.",
            },
            {
                header: 'Validation des Visites de Vente avec Géorepérage',
                paragraph: `Validez la visite grâce à des check-ins basés sur le géorepérage pour une plus grande précision. Les coordonnées de l'emplacement peuvent être associées à une photo prise par la caméra lors du partage.`,
            },
            {
                header: 'Mesurer l’Efficacité du Circuit de Visite',
                paragraph:
                    'Le circuit de visite peut être utilisé pour planifier des visites ou des plans de tournée pour les employés lors de visites chez des clients. Intégrez votre plan de tournée directement dans les cartes pour optimiser la navigation.',
            },
        ],
        softwareDetails: [
            {
                title: 'Aperçus quotidiens sur le terrain pour de meilleures performances commerciales.',
                description: [
                    'Génération de rapports électroniques orientés vers l’analyse des données, fonction fiable et conviviale pour un accès et une utilisation faciles.',
                    'En tant que responsable des ventes, vous aurez une vue claire sur divers rapports qui seront envoyés à votre adresse e-mail ou que vous pourrez également consulter dans votre tableau de bord.',
                    'Parmi les rapports notables, on trouve les rapports mensuels, les rapports par emplacement de succursale, les rapports spécifiques aux employés, etc.',
                ],
            },
            {
                title: 'Vue d’ensemble des présences sur le terrain.',
                description: [
                    'Vue d’ensemble des présences et des visites sur le terrain des employés.',
                    'Obtenez une vue globale sur la présence de la main-d’œuvre sur le terrain et les réunions.',
                    'Dans le rapport de présence sur le terrain, vous obtiendrez des données sur les heures d’arrivée, les emplacements, les retards, les heures de travail, etc.',
                    'De la même manière, nous vous fournissons également des rapports de check-in/out des visites sur le terrain. Ces rapports incluent des données telles que les heures de login/out, les heures de check-in/out, les emplacements des réunions, la distance parcourue, les notes sur les résultats des réunions, etc.',
                ],
            },
            {
                title: 'Rapports orientés vers l’analyse des données.',
                description: [
                    'Abonnez-vous et téléchargez des rapports électroniques mensuels.',
                    'Vous pouvez vous abonner et télécharger les rapports électroniques mensuels quand vous le souhaitez.',
                    'Disponibles en format pdf et excel. Une pile complète de rapports prêts à vous fournir des informations détaillées sur les actions externes des employés hors de l’organisation, ce qui peut être une tâche difficile à gérer manuellement.',
                ],
            },
        ],
    },
    locationBasedAttendance: {
        featureHeader: 'Présence Basée sur la Localisation',
        featureDescription:
            "Système de pointage basé sur le géorepérage pour s'assurer que les employés ne peuvent pointer leur présence que lorsqu'ils se trouvent dans les limites géographiques prédéfinies.",
        featureCaption:
            'La présence basée sur la localisation assure une précision pour surveiller et enregistrer la présence des employés en fonction de leur position géographique.',
        keyPoints: [
            {
                header: 'Géorepérage',
                paragraph:
                    "Mettez en place des frontières virtuelles géorepérées autour des locaux spécifiques de l'entreprise. Cela aide à automatiser la présence en fonction de l'entrée ou de la sortie d'un employé d'une zone désignée.",
            },
            {
                header: 'Authentification et Sécurité',
                paragraph:
                    "Méthodes d'authentification pour garantir l'exactitude et la sécurité des données de présence grâce à des codes PIN, la biométrie ou la reconnaissance faciale.",
            },
            {
                header: 'Mises à Jour en Temps Réel',
                paragraph:
                    'Assurez-vous que les données de présence sont rapidement mises à jour, permettant ainsi une prise de décision managériale opportune.',
            },
            {
                header: 'Considérations pour le Travail à Distance',
                paragraph:
                    'Envisagez des méthodes alternatives de suivi de la présence pour les employés en télétravail.',
            },
        ],
        softwareDetails: [
            {
                title: 'Temps et localisation des employés.',
                description: [
                    'Sachez quand et où vos employés travaillent.',
                    'Demandez à vos employés d’accorder la permission sur leur téléphone portable afin de suivre le temps et la localisation.',
                    "Une fois la permission accordée, le traqueur peut commencer à fonctionner d'un simple appui.",
                ],
            },
            {
                title: 'Rapport en Direct',
                description: [
                    "La mise en œuvre d'un système de pointage basé sur la localisation peut améliorer l'exactitude et simplifier la gestion des présences. Toutefois, il est essentiel d'équilibrer les avantages avec les considérations de confidentialité des employés et de communiquer de manière transparente sur l'utilisation de ces systèmes.",
                ],
            },
            {
                title: 'Alertes Basées sur le Géorepérage',
                description: [
                    'Configurez des alertes automatiques pour les schémas de présence inhabituels ou les écarts. Cela aide à identifier les problèmes potentiels, tels que les pointages non autorisés.',
                    "Mettez en place des fonctionnalités de reporting et d'analyse pour obtenir des informations sur les tendances et les exceptions en matière de présence.",
                ],
                sDKeys: [
                    'Recevez des alertes lorsque des actifs de valeur, tels que des véhicules ou du matériel, entrent ou sortent des zones désignées, aidant ainsi à prévenir le vol ou l’utilisation non autorisée.',
                    'Assurez la sécurité des travailleurs de terrain en mettant en place des géorepérages autour des zones dangereuses ou des sites de travail éloignés, déclenchant des alertes si des employés entrent dans des zones restreintes.',
                    'Lorsque l’appareil entre ou sort d’une zone géorepérée, le système déclenche des alertes ou notifications prédéfinies.',
                    'Le système surveille en continu la localisation des appareils suivis en temps réel et vérifie s’ils croisent des géorepérages définis.',
                ],
            },
        ],
    },
    liveReporting: {
        featureHeader: 'Reporting en Direct',
        featureDescription:
            'Suivi et analyse en temps réel de divers aspects de leur travail, tels que la présence, les tâches accomplies ou l’avancement des projets.',
        featureCaption:
            'Restez informé des tâches des employés, des comptes rendus de réunion, validez les visites et prenez des décisions basées sur les données.',
        keyPoints: [
            {
                header: 'Notification Basée sur les Événements',
                paragraph:
                    'Les administrateurs ou les managers peuvent s’abonner ou choisir les notifications qu’ils souhaitent recevoir. Cela aide à se concentrer sur les notifications qu’ils souhaitent réellement recevoir.',
            },
            {
                header: 'Restez Informé de la Présence et des Congés',
                paragraph:
                    'Restez un pas en avant et connaissant le nombre de personnes présentes avant la planification de la journée, ce qui pourrait rendre la journée plus productive et efficace.',
            },
            {
                header: 'Suivez Toutes les Approbations',
                paragraph:
                    'Suivez toutes les approbations, des remboursements aux congés, et consultez-les ultérieurement.',
            },
            {
                header: 'Restez Informé des Formulaires et des Comptes Rendus de Réunion',
                paragraph:
                    'Les formulaires personnalisés peuvent aider à obtenir des données spécifiques pour une réunion, accessibles à distance.',
            },
        ],
        softwareDetails: [
            {
                title: 'Localisez la force de terrain en temps réel.',
                description: [
                    'Localisez votre personnel de terrain en temps réel.',
                    'Obtenez les détails de localisation instantanés de votre force de travail sur le terrain alors qu’ils se déplacent en temps réel.',
                    'Treant vous permet de localiser et de suivre vos employés de terrain en utilisant le GPS mobile, en ligne et hors ligne. Restez informé de chaque mouvement de votre personnel sur le terrain et maintenez un environnement de travail transparent.',
                ],
            },
            {
                title: 'Restez à jour avec les informations de terrain de vos employés.',
                description: [
                    'Restez à jour avec les informations de terrain de vos employés.',
                    'Les informations sur le terrain des membres du personnel seront automatiquement synchronisées avec votre tableau de bord.',
                    'Les détails de vos employés tels que le pourcentage de batterie, le GPS, la visibilité de l’Internet activé/désactivé, le temps idéal qu’ils passent sur le terrain seront instantanément notifiés en temps réel.',
                ],
            },
            {
                title: 'Automatisez les données de remboursement et d’indemnité des employés.',
                description: [
                    'Données historiques de localisation alimentées par l’IA pour des informations précises.',
                    'Les données de localisation de vos employés seront alimentées par l’intelligence artificielle pour fournir des informations précises sur les itinéraires et le suivi. Les données de suivi en direct et les autres historiques de localisation seront automatiquement envoyées à votre boîte mail à intervalles réguliers pour une analyse approfondie de ce qui se passe sur le terrain et en dehors.',
                ],
            },
        ],
    },
    aIBasedOptimization: {
        featureHeader: 'Optimisation Basée sur l’IA',
        featureDescription:
            'Optimisez et automatisez la planification des itinéraires pour les représentants de terrain ou le personnel de vente en fonction des itinéraires optimisés par IA.',
        featureCaption:
            'Optimisez les itinéraires pour la journée et comparez les rapports avec ceux de la saison précédente grâce à notre algorithme basé sur l’IA.',
        keyPoints: [
            {
                header: 'Intégration des Données en Temps Réel',
                paragraph:
                    'Sources de données en temps réel, telles que les mises à jour du trafic, les conditions météorologiques et les fermetures de routes, pour ajuster dynamiquement les itinéraires en fonction des circonstances actuelles, facilitant ainsi une planification quotidienne efficace.',
            },
            {
                header: 'Interface Utilisateur pour la Planification des Itinéraires',
                paragraph:
                    'Visualisez les itinéraires optimisés et effectuez les ajustements nécessaires. Cela peut faire partie d’une application mobile ou d’une plateforme web.',
            },
            {
                header: 'Métriques de Productivité',
                paragraph:
                    'Système IA prenant en compte les caractéristiques uniques des différents itinéraires ou zones.',
            },
            {
                header: 'Cartographie et Données Géospatiales',
                paragraph:
                    'Utilisez les services de cartographie et les données géospatiales pour comprendre le réseau routier, les conditions de circulation et d’autres informations liées à l’emplacement.',
            },
        ],
        softwareDetails: [
            {
                title: 'Optimisation des Itinéraires avec Mises à Jour en Temps Réel',
                description: [
                    'Optimisez et planifiez les itinéraires les plus efficaces pour les individus ou les véhicules, tels que les camions de livraison, les représentants commerciaux ou les techniciens de terrain, qui suivent des itinéraires ou des zones spécifiques dans le cadre de leurs activités régulières.',
                    'Cette application peut aider à améliorer l’efficacité, réduire le temps de déplacement et minimiser les coûts opérationnels.',
                ],
                sDKeys: [
                    'Collecte de données à partir de diverses sources, y compris les dispositifs GPS, les rapports de trafic, les mises à jour météorologiques et les capteurs de véhicules.',
                    'Des temps de réponse plus rapides et des arrivées à l’heure améliorent la satisfaction des clients, ce qui est crucial pour les entreprises orientées service.',
                ],
            },
            {
                title: 'Nécessite une Attention Particulière aux Problèmes de Confidentialité et de Conformité',
                description: [
                    'Assurez-vous que le système basé sur l’IA est conforme aux réglementations locales, notamment celles relatives à la confidentialité, à la sécurité des données et aux lois sur le transport.',
                    'Mettez régulièrement à jour et améliorez les algorithmes IA en fonction des nouvelles données et des retours, visant une optimisation continue des itinéraires.',
                ],
            },
            {
                title: 'Intégration avec d’Autres Systèmes pour une Efficacité Maximale de la Planification des Itinéraires',
                description: [
                    'Utilisez les algorithmes d’apprentissage automatique pour analyser les données historiques et prédire les tendances futures.',
                    'Cela peut aider à anticiper les conditions de circulation, les temps de livraison et d’autres facteurs influençant la planification des itinéraires.',
                ],
            },
        ],
    },
    leadManagement: {
        featureHeader: 'Gestion des Leads',
        featureDescription:
            'Résumé des données de présence des employés, des heures de travail, des absences, des arrivées tardives et des départs anticipés dans une période donnée.',
        featureCaption:
            'Générez des rapports automatisés pour différents établissements et recevez-les quotidiennement, hebdomadairement ou mensuellement avec plus de 40 modèles.',
        keyPoints: [
            {
                header: 'Automatiser les Détails de Présence : Heure de Connexion, Temps de Retard, Total des Heures de Travail, Total des Sessions',
                paragraph:
                    'Fournir un résumé global de la présence, y compris le nombre total de jours travaillés, le nombre total d’heures travaillées et les heures supplémentaires.',
            },
            {
                header: 'Rapports pour Plusieurs Établissements',
                paragraph:
                    'Accédez à distance aux rapports de présence de plusieurs établissements avec des catégories personnalisables.',
            },
            {
                header: 'Solde des Heures Supplémentaires et Congés',
                paragraph:
                    'Affichez les soldes de congés pour chaque employé, y compris les jours de congés, les congés maladie et tout autre type de congé accumulé et utilisé.',
            },
            {
                header: 'Rapport de Présence Personnalisé',
                paragraph:
                    'Permettez aux utilisateurs de personnaliser les rapports en fonction de critères spécifiques, tels que le département, l’équipe ou l’employé individuel.',
            },
        ],
        softwareDetails: [
            {
                title: 'Générez des rapports basés sur la structure de votre organisation.',
                description: [
                    'Les rapports de présence générés et analysés régulièrement peuvent fournir des informations précieuses pour améliorer la gestion des ressources humaines, identifier les tendances et garantir la conformité avec les politiques organisationnelles.',
                    'Les spécificités du rapport dépendront des besoins et de la structure de l’organisation.',
                ],
            },
            {
                title: 'Obtenez facilement des rapports personnalisables.',
                description: [
                    'Identifiez et mettez en évidence toute exception ou irrégularité dans la présence, telle que les absences non autorisées, les retards excessifs ou les heures supplémentaires non approuvées.',
                    'Utilisez des graphiques ou des diagrammes pour représenter visuellement les tendances et les modèles de présence. Cela peut faciliter l’identification des anomalies et des tendances en un coup d’œil.',
                ],
            },
            {
                title: 'Réduisez le temps passé sur les rapports manuels.',
                description: [
                    'Inclut des détails fondamentaux tels que les noms des employés, les numéros d’identification et les affiliations départementales pour fournir un contexte aux données de présence.',
                    'Spécifiez clairement la période couverte par le rapport. Cela aide les utilisateurs à comprendre la portée temporelle des données.',
                    'Fournissez un résumé global de la présence, y compris le nombre total de jours travaillés, le nombre total d’heures travaillées et les heures supplémentaires.',
                ],
            },
        ],
    },
    taskManagement: {
        featureHeader: 'Gestion des Tâches',
        featureDescription:
            'Suivi systématique et gestion des activités des employés, de leur productivité et de leur présence.',
        featureParagraph:
            'Fini les cartes de pointage, la paperasse excessive et les documents divers pour vérifier la présence des employés. Automatisez le processus de suivi de la présence avec WebWork Time Tracker et visualisez le début et la fin des heures de travail de vos employés dans un format bien structuré.',
        featureCaption:
            "Surveillez et gérez divers aspects de la main-d'œuvre de l'organisation, y compris la présence des employés, les heures de travail, la productivité et la performance.",
        keyPoints: [
            {
                header: 'Surveillez en temps réel',
                paragraph:
                    'Surveillez les progrès en temps réel en consultant les tableaux de tâches, en vérifiant l’état des tâches et en suivant le temps passé.',
            },
            {
                header: 'Surveillez tous vos flux de travail en un seul endroit pour toute l’organisation',
                paragraph:
                    'Fonctionnalités de collaboration pour la gestion des tâches permettant de communiquer avec les membres de l’équipe en temps réel. Commentez les tâches, posez des questions et fournissez des mises à jour pour tenir tout le monde informé.',
            },
            {
                header: 'Surveillez la performance',
                paragraph:
                    'Gestion des clients et des leads pour suivre la productivité. La productivité des employés et les indicateurs clés de performance peuvent être mesurés en fonction de la quantité de travail accomplie par les employés.',
            },
            {
                header: 'Standardisez la définition des objectifs',
                paragraph:
                    'Structure hiérarchique pour organiser les objectifs : objectifs de projet, objectifs départementaux, objectifs de performance individuels, initiatives stratégiques, etc.',
            },
        ],
        softwareDetails: [
            {
                title: 'Implémentez des systèmes de suivi du temps pour surveiller les heures de travail.',
                description: [
                    'Implémentez des solutions de suivi du temps pour surveiller le temps que les employés passent sur les tâches et les projets.',
                    'Cela peut aider à comprendre l’utilisation des ressources et à améliorer la gestion du temps.',
                    'Utilisez des systèmes de gestion de projet pour suivre l’avancement des projets, attribuer des tâches et surveiller les délais.',
                    'Cela aide à optimiser les flux de travail des projets et à garantir leur achèvement dans les délais.',
                ],
            },
            {
                title: 'Surveillez les indicateurs clés de performance pertinents.',
                description: [
                    'Définir et suivre les indicateurs clés de performance pertinents pour les objectifs de votre organisation.',
                    'Cela peut inclure des objectifs de vente, des mesures de satisfaction client ou d’autres indicateurs de performance.',
                ],
            },
            {
                title: `Générez des rapports sur les tendances de votre main-d'œuvre.`,
                description: [
                    `Intégrez des fonctionnalités d’analyse et de reporting pour obtenir des informations sur les tendances de la main-d'œuvre, la performance et les domaines à améliorer.`,
                    'Les rapports personnalisés peuvent aider à la prise de décision.',
                ],
            },
        ],
    },
    salesPipelineMonitoring: {
        featureHeader: 'Suivi du Pipeline de Vente',
        featureDescription:
            'Suivi et analyse des indicateurs clés de l’équipe de vente sur le terrain, tels que les revenus de vente, les interactions avec les clients et les tendances géographiques.',
        featureParagraph:
            'Fini les cartes de pointage, la paperasse excessive et les documents divers pour vérifier la présence des employés. Automatisez le processus de suivi de la présence avec WebWork Time Tracker et visualisez le début et la fin des heures de travail de vos employés dans un format bien structuré.',
        featureCaption:
            'Analysez la collecte, l’analyse et l’interprétation des données liées aux activités de vente sur le terrain pour obtenir des informations et prendre des décisions éclairées.',
        keyPoints: [
            {
                header: 'Flux de Travail Automatisés',
                paragraph:
                    'Exploitez le système pour rationaliser et optimiser les différentes étapes du processus de vente telles que la capture des prospects, la qualification, la gestion du pipeline, le suivi des activités et le reporting.',
            },
            {
                header: 'Intégration avec CRM',
                paragraph:
                    'Rationalisez la gestion des prospects, le suivi des contacts, la surveillance des transactions et la synchronisation des données entre les deux plateformes.',
            },
            {
                header: 'Facilitez la Collaboration et la Communication',
                paragraph:
                    'Canaux de communication centralisés, planification des réunions de vente, examens collaboratifs des transactions, documentation des processus, collaboration interfonctionnelle, promotion de la communication ouverte.',
            },
            {
                header: 'Aperçus sur la Performance Future des Ventes',
                paragraph:
                    'Exploitez l’analyse des données et la modélisation prédictive pour prévoir les résultats et tendances potentiels.',
            },
        ],
        softwareDetails: [
            {
                title: 'Aperçus Quotidiens du Terrain pour une Meilleure Performance de Vente',
                description: [
                    'Génération de rapports e-analytique orientée sur les données. Fonctionnalité de rapport fiable et conviviale pour un accès facile et une utilisation.',
                    'En tant que responsable des ventes, vous aurez une vue claire sur divers rapports qui seront envoyés à votre adresse e-mail ou que vous pourrez également consulter dans votre tableau de bord.',
                    'Certains des rapports notables sont les rapports mensuels, les rapports par emplacement de succursale, les rapports spécifiques aux employés, etc.',
                ],
            },
            {
                title: 'Vue d’Ensemble sur la Présence au Travail',
                description: [
                    'Vue d’ensemble de la présence des employés sur le terrain et des visites.',
                    'Ayez une vue complète sur la présence de la main-d’œuvre sur le terrain et les réunions.',
                    'Dans le rapport de présence sur le terrain, vous obtiendrez des données sur le temps de marquage de la présence, le lieu, le temps de retard, les heures de travail, etc.',
                    'De même, les rapports de check-in/check-out des visites sur le terrain incluent des données relatives aux visites sur le terrain telles que le temps de connexion/déconnexion, le temps de check-in/check-out, le lieu des réunions, la distance parcourue, les notes des résultats des réunions, etc.',
                ],
            },
            {
                title: 'Génération de Rapports Orientés sur l’Analyse des Données',
                description: [
                    'Abonnez-vous et téléchargez les rapports e-mensuels.',
                    'Vous pouvez vous abonner et télécharger les rapports e-mensuels quand vous le souhaitez.',
                    'Disponibles au format PDF et Excel. Une pile complète de rapports pour vous fournir des informations détaillées sur les actions externes des employés en dehors de l’organisation, ce qui tend à être une tâche difficile manuellement.',
                ],
            },
        ],
    },
    workflowAutomation: {
        featureHeader: 'Automatisation des Flux de Travail',
        featureDescription:
            'Suivi de la présence basé sur la géolocalisation pour garantir que les employés ne peuvent se pointer qu’en entrant ou en sortant des limites géographiques prédéfinies.',
        featureCaption:
            'La présence basée sur la géolocalisation assure une précision pour surveiller et enregistrer la présence des employés en fonction de leur emplacement physique.',
        keyPoints: [
            {
                header: 'Attribution Automatisée des Tâches',
                paragraph:
                    'Automatisation des flux de travail pour attribuer automatiquement les tâches aux individus les plus appropriés, en tenant compte des compétences, de la disponibilité, de la charge de travail et des niveaux de priorité.',
            },
            {
                header: 'Routage, Qualification et Scoring des Leads',
                paragraph:
                    'Routage, qualification, scoring des leads pour attribuer les leads entrants aux représentants commerciaux appropriés, évaluer leur qualité et prioriser les actions de suivi.',
            },
            {
                header: 'Intégration avec des Systèmes Externes',
                paragraph:
                    'Facilite une collaboration plus fluide, améliore la précision des données et assure un flux de travail cohérent à travers diverses fonctions commerciales.',
            },
            {
                header: 'Suivi de la Performance et Reporting',
                paragraph:
                    'Suivi des indicateurs tels que les temps de complétion des processus, les taux d’erreur, l’utilisation des ressources et la productivité globale.',
            },
        ],
        softwareDetails: [
            {
                title: 'Temps et Emplacement de la Main-d’œuvre',
                description: [
                    'Sachez Quand et Où Vos Employés Travaillent',
                    'Demandez à vos employés de donner la permission sur leurs téléphones mobiles pour que le suivi du temps et de l’emplacement puisse être activé.',
                    'Une fois la permission accordée, le suivi peut commencer à fonctionner en un clic.',
                ],
            },
            {
                title: 'Reporting en Temps Réel',
                description: [
                    "La mise en œuvre d'un système de présence basé sur la géolocalisation peut améliorer la précision et rationaliser la gestion de la présence. Cependant, il est crucial d'équilibrer les avantages avec les considérations relatives à la confidentialité des employés et de communiquer de manière transparente sur la mise en œuvre et l'utilisation de ces systèmes.",
                ],
            },
            {
                title: 'Alerte Basée sur la Géolocalisation',
                description: [
                    'Configurez des alertes automatisées pour des modèles de présence inhabituels ou des écarts. Cela aide à identifier les problèmes potentiels, tels que les pointsage non autorisés.',
                    'Mettez en œuvre des fonctionnalités de reporting et d’analyse pour obtenir des informations sur les modèles de présence, les tendances et les exceptions.',
                ],
            },
        ],
    },
    aIBasedAssistance: {
        featureHeader: 'Assistance Basée sur l’IA',
        featureDescription:
            'Suivi et analyse en temps réel de divers aspects de leur travail, tels que la présence, les tâches complètes ou l’avancement des projets.',
        featureCaption:
            'Restez informé des tâches des employés, des Minutes de Réunion, validez les visites et prenez des décisions basées sur les données.',
        keyPoints: [
            {
                header: 'Notification Basée sur les Événements',
                paragraph:
                    'L’administrateur ou le responsable peut s’abonner ou choisir les notifications qu’il souhaite recevoir. Cela aide à se concentrer sur les notifications vraiment importantes.',
            },
            {
                header: 'Restez Informé sur la Présence et les Congés',
                paragraph:
                    'Restez informé sur la présence et les congés. Soyez un pas en avant et connaissez l’effectif à l’avance pour que la planification de la journée soit plus productive et efficace.',
            },
            {
                header: 'Suivi de Toutes les Approvals',
                paragraph:
                    'Suivi de toutes les approbations. Suivez toutes les approbations, des remboursements aux congés, et consultez-les plus tard.',
            },
            {
                header: 'Restez à Jour avec les Formulaires et les Minutes de Réunion',
                paragraph:
                    'Suivi à distance via les formulaires et les Minutes de Réunion. Les formulaires personnalisés peuvent aider à recueillir des données spécifiques pour une réunion, accessibles à distance.',
            },
        ],
        softwareDetails: [
            {
                title: 'Localisez le personnel de terrain en temps réel',
                description: [
                    'Localisez votre personnel de terrain en temps réel',
                    'Obtenez des détails de localisation instantanés de votre personnel de terrain au fur et à mesure qu’il se déplace.',
                    'Treant vous permet de localiser et de suivre vos employés de terrain en utilisant leur GPS mobile en ligne et hors ligne. Restez informé des déplacements de votre personnel de terrain et maintenez un environnement de travail transparent.',
                ],
            },
            {
                title: 'Restez à jour avec les informations de votre personnel de terrain',
                description: [
                    'Restez à jour avec les informations de votre personnel de terrain',
                    'Les informations sur le terrain seront automatiquement synchronisées avec votre tableau de bord.',
                    'Les détails de vos employés, comme le pourcentage de batterie, la visibilité du GPS, le temps de connexion/déconnexion Internet, et le temps idéal qu’ils passent sur le terrain vous seront notifiés instantanément en temps réel.',
                ],
            },
            {
                title: 'Automatisez les données de remboursement et d’indemnité des employés',
                description: [
                    'Historique de localisation alimenté par l’IA pour des données précises',
                    'Les données de localisation de vos employés seront alimentées par l’intelligence artificielle pour fournir des informations précises sur les itinéraires et le suivi. Les données de suivi en direct et autres historiques de localisation seront automatisées vers votre boîte mail à intervalles réguliers pour une analyse approfondie de ce qui se passe sur le terrain et en dehors.',
                ],
            },
        ],
    },
    digitizationOfOrder: {
        featureHeader: 'Numérisation des Commandes',
        featureDescription:
            'Suivi et analyse en temps réel de divers aspects de leur travail, tels que la présence, les tâches complètes ou l’avancement des projets.',
        featureCaption: 'Optimisez la gestion des commandes de bout en bout et réduisez les erreurs manuelles',
        keyPoints: [
            {
                header: 'Rapports et Analytique',
                paragraph:
                    'Générez des rapports détaillés et des analyses pour surveiller la performance du traitement des commandes et la productivité des employés.',
            },
            {
                header: 'Saisie Automatisée des Commandes',
                paragraph:
                    'Capturez et validez automatiquement les détails des commandes saisies par les employés ou les clients.',
            },
            {
                header: 'Suivi des Commandes en Temps Réel et Mises à Jour du Statut',
                paragraph:
                    'Fournissez des mises à jour en temps réel sur le statut des commandes et le suivi des expéditions aux employés et aux clients.',
            },
            {
                header: 'Automatisation des Flux de Travail et Attribution des Tâches',
                paragraph:
                    'Automatisez les processus d’approbation des commandes et assignez des tâches aux employés appropriés.',
            },
        ],
    },
    targetManagement: {
        featureHeader: 'Gestion des Objectifs',
        featureDescription:
            'Suivi et analyse en temps réel de divers aspects de leur travail, tels que la présence, les tâches accomplies ou l’avancement des projets.',
        featureCaption:
            'Restez informé des tâches des employés, des minutes de réunion, validez les visites et prenez des décisions basées sur les données.',
        keyPoints: [
            {
                header: 'Intégration avec le Suivi Terrain',
                paragraph:
                    'Atteignez les objectifs quotidiens, hebdomadaires et mensuels et conservez les enregistrements des réalisations des employés avec un suivi terrain en temps réel.',
            },
            {
                header: 'Mises à Jour et Synchronisation en Temps Réel',
                paragraph:
                    'Suivi en temps réel des progrès (nombre d’objectifs atteints vs. restants), heures d’enregistrement/de sortie, nombre d’heures (durée) passée sur chaque tâche, et suivi de la localisation.',
            },
            {
                header: 'Performance et Analytique',
                paragraph:
                    'Métriques de performance (taux d’achèvement, temps pris, scores de satisfaction client), comparaison avec les objectifs (performance réelle vs. objectif), données historiques (tendances de performance passées).',
            },
            {
                header: 'Notifications et Alertes Automatisées',
                paragraph:
                    'Envoyez des notifications automatisées pour tenir les employés informés des alertes de réalisation des objectifs, des rappels de performance et des avertissements de délais.',
            },
        ],
    },
    invoiceManagement: {
        featureHeader: 'Gestion des Factures',
        featureDescription:
            'Suivi et analyse en temps réel de divers aspects de leur travail, tels que la présence, les tâches accomplies ou l’avancement des projets.',
        featureCaption:
            'Créez, suivez le statut des factures en temps réel et le traitement avec des alertes et notifications automatisées.',
        keyPoints: [
            {
                header: 'Notifications et Rappels Automatisés',
                paragraph:
                    'Mettez en place des systèmes pour suivre le statut des factures et envoyer des rappels et alertes automatisés pour les paiements dus et en retard.',
            },
            {
                header: 'Modèles de Factures',
                paragraph:
                    'Modèles de factures standardisés incluant tous les détails nécessaires tels que les informations sur le client, les détails de la commande, les taxes, les remises et les conditions de paiement.',
            },
            {
                header: 'Signature Numérique et Preuve de Livraison',
                paragraph:
                    'Collectez des signatures numériques et des preuves de livraison basées sur un OTP dans le cadre du processus de facturation.',
            },
            {
                header: 'Synchronisation des Factures et Mise à Jour en Temps Réel',
                paragraph:
                    'Synchronisation automatique avec le système central (stockage en nuage) et mises à jour en temps réel sur le statut des factures.',
            },
        ],
    },
    distributorOrder: {
        featureHeader: 'Commande des Distributeurs',
        featureDescription:
            'Suivi et analyse en temps réel de divers aspects de leur travail, tels que la présence, les tâches accomplies ou l’avancement des projets.',
        featureCaption:
            'Restez informé des tâches des employés, des minutes de réunion, validez les visites et prenez des décisions basées sur les données.',
        keyPoints: [
            {
                header: 'Informations sur la Distribution',
                paragraph:
                    'Créez et traitez les commandes avec tous les détails pertinents pour chaque distribution et assignez des tâches en fonction de la disponibilité.',
            },
            {
                header: 'Intégration en Temps Réel avec l’Inventaire',
                paragraph:
                    'Intégrez avec la gestion des stocks pour assurer une allocation précise des stocks et mettre à jour les niveaux de stocks en temps réel.',
            },
            {
                header: 'Notifications et Alertes Automatisées',
                paragraph:
                    'Configurez le système pour envoyer des notifications automatisées aux étapes clés du processus de commande.',
            },
            {
                header: 'Tableau de Bord des Employés',
                paragraph:
                    'Accédez à un tableau de bord où les employés peuvent vérifier les commandes, suivre l’avancement et télécharger les documents nécessaires.',
            },
        ],
    },
    inventoryManagement: {
        featureHeader: 'Gestion des Stocks',
        featureDescription:
            'Suivi et analyse en temps réel de divers aspects de leur travail, tels que la présence, les tâches accomplies ou l’avancement des projets.',
        featureCaption:
            'Le bon équilibre des stocks avec des mises à jour en temps réel et le suivi des inventaires pour des données précises.',
        keyPoints: [
            {
                header: 'Intégration avec la Gestion des Commandes',
                paragraph:
                    'Assurez une intégration fluide entre les systèmes de gestion des stocks et de gestion des commandes pour synchroniser les niveaux de stocks avec les commandes entrantes et sortantes.',
            },
            {
                header: 'Gestion Multi-Localisation',
                paragraph:
                    'Suivez les stocks à travers plusieurs entrepôts, magasins ou emplacements et accédez à un contrôle centralisé, optimisez la répartition des stocks et améliorez la visibilité.',
            },
        ],
    },
    orderCollectionManagement: {
        featureHeader: 'Gestion des Commandes/Collectes',
        featureDescription:
            'Simplifiez les opérations de commandes/collectes avec une solution évolutive et automatisée.',
        featureCaption: 'Simplifiez les opérations de commandes/collectes avec une solution évolutive et automatisée.',
        keyPoints: [
            {
                header: 'Système de Gestion des Commandes Unifié',
                paragraph:
                    'Un système centralisé accessible par les employés sur site et à distance pour gérer les commandes, suivre les progrès et mettre à jour les statuts en temps réel.',
            },
            {
                header: 'Contrôle d’Accès Basé sur les Rôles',
                paragraph:
                    'Différents niveaux d’accès et autorisations basés sur les rôles (par exemple, managers, employés sur site, employés à distance) pour assurer la sécurité des données et un accès approprié.',
            },
            {
                header: 'Synchronisation en Temps Réel',
                paragraph:
                    'Assurez que toutes les données de commande sont synchronisées en temps réel sur tous les appareils et emplacements, afin que tout le monde ait accès aux dernières informations.',
            },
            {
                header: 'Communication et Coordination',
                paragraph:
                    'Facilitez la communication entre les employés sur site et à distance grâce à la messagerie et aux notifications dans l’application.',
            },
        ],
    },
};

export default features;
