const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'Гибкие планы, которые растут вместе с вами',
    subFlexiblePlansThatGrowsWithYou:
        'Выберите план ценообразования, который соответствует потребностям вашего бизнеса. Traceforce предлагает ценовые пакеты по оптимальным ценам, но с превосходной ценностью.',
    pricingPlans: 'Ценовые планы',
    pricingThatWorksForEveryone: 'Ценообразование, которое подходит всем',
    monthly: 'Ежемесячно',
    annualy: 'Ежегодно',
    international: 'Международный',
    domestic: 'Внутренний',
    month: 'Месяц',
    benifits: 'Преимущества',
    allPlanIncludeTheseBenifits: 'Все планы включают эти преимущества',
    someBenfitsOfMonthlyYearlySubscription: 'Некоторые преимущества ежемесячной/ежегодной подписки',
    benifitsArr: [
        {
            title: 'Техническая поддержка',
            description: 'Наша профессиональная техническая поддержка поможет вам на каждом этапе.',
        },
        {
            title: 'Технологии',
            description: 'Специальное обучение для начала работы с платформой от профессионалов.',
        },
        {
            title: 'Анализ роста',
            description: 'Посвященная команда предоставит вам инсайты о вашем ежемесячном росте.',
        },
        {
            title: 'Награда',
            description: 'Особая награда для лучшего аккаунта каждый месяц.',
        },
    ],
    fAQs: 'Часто задаваемые вопросы',
    frequentlyAskedQuestions: 'Часто задаваемые вопросы',
    someBaTypeOfQuestions: 'Вот некоторые типичные вопросы от наших клиентов',
    fAQArr: [
        {
            question: 'Как я могу зарегистрироваться для моей фирмы?',
            ans: 'Да, лицензия на маркетплейсе позволяет использовать эту тему в любом конечном продукте. Для получения дополнительной информации о лицензии, пожалуйста, проверьте условия лицензии на маркетплейсе.',
        },
        {
            question: 'Где я могу добавить транспортное средство моего персонала?',
            ans: 'Нет, это HTML-шаблон. Он не будет работать напрямую с WordPress, хотя вы можете преобразовать его в тему, совместимую с WordPress.',
        },
        {
            question: 'Как мой персонал может войти в Traceforce?',
            ans: 'Отправьте ваши проблемы или отзывы на наш специальный адрес электронной почты поддержки (support@coderthemes.com). Мы доступны в любое время, чтобы помочь вам.',
        },
        {
            question: 'Могу ли я отслеживать мой персонал через мобильное устройство?',
            ans: 'Да, мы будем регулярно обновлять подсказки. Все будущие обновления будут предоставлены бесплатно.',
        },
        {
            question: 'Как отметить посещаемость?',
            ans: 'Да, лицензия на маркетплейсе позволяет использовать эту тему в любом конечном продукте. Для получения дополнительной информации о лицензии, пожалуйста, проверьте условия лицензии на маркетплейсе.',
        },
        {
            question: 'Как отключить опции для разработчиков?',
            ans: 'Нет, это HTML-шаблон. Он не будет работать напрямую с WordPress, хотя вы можете преобразовать его в тему, совместимую с WordPress.',
        },
        {
            question: 'Какие функции предлагает Traceforce?',
            ans: 'Отправьте ваши проблемы или отзывы на наш специальный адрес электронной почты поддержки (support@coderthemes.com). Мы доступны в любое время, чтобы помочь вам.',
        },
        {
            question: 'Нужно ли устанавливать программное обеспечение Traceforce?',
            ans: 'Да, мы будем регулярно обновлять подсказки. Все будущие обновления будут предоставлены бесплатно.',
        },
        {
            question: 'Каков процесс внедрения программного обеспечения Traceforce?',
            ans: 'Да, лицензия на маркетплейсе позволяет использовать эту тему в любом конечном продукте. Для получения дополнительной информации о лицензии, пожалуйста, проверьте условия лицензии на маркетплейсе.',
        },
        {
            question: 'Какие варианты обучения и поддержки доступны с программным обеспечением Traceforce?',
            ans: 'Нет, это HTML-шаблон. Он не будет работать напрямую с WordPress, хотя вы можете преобразовать его в тему, совместимую с WordPress.',
        },
        {
            question:
                'Может ли программное обеспечение Traceforce быть настроено в соответствии с нашими конкретными HR-процессами?',
            ans: 'Отправьте ваши проблемы или отзывы на наш специальный адрес электронной почты поддержки (support@coderthemes.com). Мы доступны в любое время, чтобы помочь вам.',
        },
        {
            question: 'Как программное обеспечение HRMS может помочь с соблюдением требований и отчетностью?',
            ans: 'Да, мы будем регулярно обновлять подсказки. Все будущие обновления будут предоставлены бесплатно.',
        },
        {
            question:
                'Поддерживает ли программное обеспечение HRMS удаленную работу и доступ через мобильные устройства?',
            ans: 'Да, мы будем регулярно обновлять подсказки. Все будущие обновления будут предоставлены бесплатно.',
        },
    ],
};
export default pricingPage;
