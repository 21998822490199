const navbar = {
    industries: 'Industri',
    pharmaceuticals: 'Farmasi',
    fieldSales: 'Penjualan Lapangan',
    fmcg: 'FMCG',
    service: 'Layanan',
    fieldOperations: 'Operasi Lapangan',
    logisticsAndTransportation: 'Logistik dan Transportasi',
    education: 'Pendidikan',
    products: 'Produk',
    pricing: 'Harga',
    attendanceManagement: 'Manajemen Kehadiran',
    fieldForceManagement: 'Manajemen Tenaga Kerja Lapangan',
    company: 'Perusahaan',
    ourStory: 'Kisah Kami',
    joinUs: 'Bergabunglah dengan Kami',
    contactUs: 'Hubungi Kami',
    integration: 'Integrasi',
    becomeAPartner: 'Jadi Mitra',
    blog: 'Blog',
};
export default navbar;
