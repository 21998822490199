const pricing = {
    attendance: {
        name: 'Kehadiran',
        price: '40',
        subLine: 'Terbaik untuk Memantau Jam Kerja',
        features: [
            'Clock-in berbasis Biometrik',
            'Clock-in berbasis Geo',
            'Opsi Setengah Hari',
            'Logout Otomatis',
            'Penghitung Keterlambatan',
            'Rata-rata Waktu Clock In',
            'Total Jam Kerja',
            'Langganan Otomatis ke 1 Laporan',
            'Kehadiran, Rata-rata, Check-in/ Check-out',
            'Laporan',
            'Termasuk Semua Fitur Dasar',
            'Biometrik',
            'Face AI',
            'Kiosk',
            'Opsi Beberapa Cabang',
            'Formulir untuk Clock In dan Clock Out',
            'Termasuk Semua Fitur Dasar dan Premium',
        ],
    },
    task: {
        name: 'Tugas',
        price: '100',
        subLine: 'Terbaik untuk Mengelola Tugas',
        features: [
            'Tugas Lapangan',
            'Tugas Layanan',
            'Manajemen Tugas berbasis Geofence',
            'Check In Check Out',
            'Waktu untuk Pergi',
            'Notifikasi Sebelumnya',
        ],
    },
    tracking: {
        name: 'Pelacakan',
        price: '300',
        subLine: 'Terbaik untuk Pelacakan Real-Time',
        features: [
            'Tugas Lapangan',
            'Tugas Layanan',
            'Manajemen Tugas berbasis Geofence',
            'Check In Check Out',
            'Waktu untuk Pergi',
            'Pelacakan Langsung',
        ],
    },
    customer: {
        name: 'Pelanggan',
        price: '80',
        subLine: 'Kelola Hubungan Pelanggan',
        features: [
            'Komunikasi menggunakan Whatsapp, Email, dan Pesan',
            'Tentukan Tag',
            'Pengingat Berdasarkan Interval',
            'Tentukan Kunjungan berbasis Geo Fence',
            'Riwayat Kunjungan',
            'Bagikan Kartu Nama',
            'Pengumuman',
            'Termasuk Semua Fitur Dasar',
            'Rute Beat berbasis AI',
            'Pemindai Kartu Nama',
            'Template Pra-buat untuk Komunikasi',
            'Kelola Pengingat',
            'Komunikasi menggunakan Whatsapp, Email, dan Pesan',
            'Tentukan Tag',
            'Pengingat Berdasarkan Interval',
            'Tentukan Kunjungan berbasis Geo Fence',
            'Riwayat Kunjungan',
            'Bagikan Kartu Nama',
            'Pengumuman',
        ],
    },
    reimbursement: {
        name: 'Reimbursement',
        price: '30',
        subLine: 'Otomatisasi Pembayaran Akurat & Tepat Waktu',
        features: [
            'Reimbursement berdasarkan Jarak Kendaraan',
            'Analitik (Subpoint)',
            'Berdasarkan Cabang',
            'Berdasarkan Bulanan',
            'Kategori',
        ],
    },
    leave: {
        name: 'Cuti',
        price: '30',
        subLine: 'Manajer untuk Menyetujuinya dengan Mudah',
        features: [
            'Cuti yang Dibawa ke Depan',
            'Kelola Hari Libur',
            'Periksa Cuti yang Terakumulasi',
            'Setengah Hari dan Kerja dari Rumah',
            'Setujui Cuti',
            'Kustomisasi Kebijakan untuk Setiap Individu',
        ],
    },
    payroll: {
        name: 'Penggajian',
        price: '40',
        subLine: 'Perhitungan Gaji yang Akurat',
        features: [
            'Kebijakan Penggajian',
            'Perhitungan Gaji Awal',
            'Ringkasan Pembayaran',
            'Perhitungan 1-Klik',
            'Perhitungan Gaji Per Jam & Bulanan',
            'Perhitungan Lembur',
        ],
    },
    faceID: {
        name: 'Face ID',
        price: '25',
        subLine: 'Terbaik untuk Meningkatkan Keamanan',
        features: ['Tambahkan Face ID dalam kehadiran sebagai (Add on)'],
    },
    forms: {
        name: 'Formulir',
        price: '30',
        subLine: 'Buat dan Kelola Formulir Digital',
        features: [
            'Formulir Dinamis',
            'Pembuatan Formulir Kustom',
            'Analitik Berdasarkan Formulir',
            'Formulir untuk Acara',
            'Pembayaran melalui Formulir',
            'Buat Formulir yang Dipersonalisasi',
        ],
    },
};
export default pricing;
