const features = {
    attendanceMonitoring: {
        featureSubcaption: 'Efisiensi dalam setiap detik - Solusi inovatif untuk sistem manajemen karyawan',
        featureHeader: 'Pemantauan Kehadiran',
        featureDescription:
            'Informasi real-time tentang masuk/keluar dari area Geofence atau Cabang mengenai kehadiran atau ketidakhadiran individu',
        featureParagraph:
            'Tidak perlu lagi kartu waktu, tumpukan kertas, dan berbagai dokumen untuk memeriksa kehadiran karyawan. Otomatisasi proses pemantauan kehadiran dengan WebWork Time Tracker dan lihat awal dan akhir jam kerja karyawan Anda dalam format yang terstruktur dengan baik.',
        featureCaption: 'Kelola kehadiran cabang multiple untuk karyawan lapangan dan karyawan di tempat',
        keyPoints: [
            {
                header: 'Kehadiran Real-Time',
                paragraph:
                    'Informasi real-time tentang kehadiran atau ketidakhadiran individu di dalam area Geofence atau Cabang',
            },
            {
                header: 'Perhitungan Jam Kerja Total',
                paragraph: 'Pantau jam kerja berdasarkan Harian, Mingguan, dan Bulanan',
            },
            {
                header: 'Laporan Kehadiran Akses Jarak Jauh',
                paragraph: 'Akses laporan kehadiran dari Beberapa Cabang secara jarak jauh',
            },
            {
                header: 'Manajemen Kehadiran Terpusat',
                paragraph:
                    'Konsolidasi data, proses, dan sistem terkait kehadiran menjadi satu platform atau sistem yang terpusat',
            },
        ],
        softwareDetails: [
            {
                title: 'Dapatkan wawasan tentang pelacakan kehadiran karyawan',
                description: [
                    'Dapatkan Transparansi Punctualitas Karyawan',
                    'Hemat waktu dan atur kehadiran karyawan tanpa tindakan tambahan.',
                    'Pantau dengan mudah apakah karyawan Anda telah tiba di tempat kerja tepat waktu dan menyelesaikan jam kerja mereka.',
                    'Pertahankan operasi tempat kerja Anda lebih teratur dan memiliki lingkungan kerja yang sangat bertanggung jawab.',
                ],
                sDKeys: [
                    'Otomatis log in dan log out untuk staf di tempat dengan mengatur perimeter geofence.',
                    'Terima notifikasi untuk karyawan yang lupa atau gagal log in atau log out.',
                    'Temukan dan lacak pergerakan staf lapangan Anda secara real-time segera setelah mereka log in kehadiran mereka.',
                ],
            },
            {
                title: 'Dapatkan laporan pemantauan kehadiran yang terperinci',
                description: [
                    'Analisis Cerdas Data Kehadiran',
                    'Filter meninggalkan lebih awal, datang terlambat, dan pelacakan kurang yang dapat Anda pilih dari daftar drop-down.',
                    'Ini akan membantu Anda menghasilkan informasi yang Anda inginkan tanpa harus mencarikannya secara manual.',
                    'Anda dapat melacak kehadiran secara harian atau memilih interval dari kalender.',
                ],
                sDKeys: [
                    'Dapatkan ringkasan kehadiran untuk organisasi Anda.',
                    'Hasilkan dan manfaatkan laporan untuk melihat tren viral dalam tim, departemen, atau cabang Anda.',
                    'Terima laporan pada interval reguler di kotak surat Anda. (Waktu log in, waktu log out, total jam kerja, total sesi)',
                    'Data yang berguna untuk pengambilan keputusan yang lebih baik.',
                ],
            },
            {
                title: 'Jelajahi fitur waktu dan produktivitas lainnya.',
                description: [
                    'Cara yang aman dan transparan untuk memantau dan melacak kehadiran karyawan secara real-time dengan sistem tanpa sentuhan 100%.',
                    'Pengaturan Mudah',
                    'Laporan otomatis check in dan check out yang terperinci',
                    'Screenshot dengan waktu pelacakan',
                    'Perekaman Tingkat Aktivitas',
                    'Pemantauan Penggunaan Aplikasi dan Situs Web',
                ],
                sDKeys: [
                    'Kehadiran karyawan telah terbukti membantu mengelola waktu dan meningkatkan produktivitas.',
                    'Hapus kertas dan kumpulkan catatan kehadiran karyawan yang akurat.',
                ],
            },
        ],
    },
    leaveManagement: {
        featureHeader: 'Manajemen Cuti',
        featureSubcaption:
            'Koordinasikan absensi staf sambil mempertahankan tingkat produktivitas dan kontinuitas operasional.',
        featureDescription:
            'Menangani dan melacak absensi dan kehadiran secara efisien, memastikan dokumentasi yang tepat, kepatuhan terhadap kebijakan perusahaan, dan menjaga kelancaran alur kerja.',
        featureParagraph:
            'Tidak perlu lagi kartu waktu, tumpukan kertas, dan berbagai dokumen untuk memeriksa kehadiran karyawan. Otomatisasi proses pemantauan kehadiran dengan WebWork Time Tracker dan lihat awal dan akhir jam kerja karyawan Anda dalam format yang terstruktur dengan baik.',
        featureCaption:
            'Tetap terinformasi tentang cuti karyawan Anda yang akan datang sebelumnya dan rencanakan hari Anda dengan baik',
        keyPoints: [
            {
                header: 'Perhitungan Otomatis Cuti dan Saldo',
                paragraph:
                    'Kelola akumulasi cuti, memastikan karyawan tidak melebihi jumlah yang diberikan, dan mencegah saldo cuti negatif.',
            },
            {
                header: 'Kebijakan Cuti Fleksibel, Alur Kerja Persetujuan',
                paragraph:
                    'Konfigurasi kebijakan cuti fleksibel organisasi dengan mengatur kalender cuti. Aktifkan pengaturan hierarki paralel atau berurutan untuk persetujuan atau penolakan permintaan cuti.',
            },
            {
                header: 'Kepatuhan terhadap Undang-Undang Ketenagakerjaan dan Kebijakan Perusahaan',
                paragraph:
                    'Tetap terinformasi tentang undang-undang ketenagakerjaan dan peraturan terkait hak cuti. Pastikan bahwa kebijakan manajemen cuti Anda mematuhi kebijakan perusahaan.',
            },
            {
                header: 'Opsi Layanan Mandiri',
                paragraph:
                    'Berikan opsi layanan mandiri kepada karyawan, memungkinkan mereka memeriksa saldo cuti mereka, mengajukan permintaan, dan melihat status permintaan mereka.',
            },
        ],
        softwareDetails: [
            {
                title: 'Kebijakan cuti yang fleksibel dan banyak untuk tim Anda',
                description: [
                    'Kebijakan cuti fleksibel untuk memenuhi kebutuhan Anda dengan parameter yang dapat dikonfigurasi',
                    'Beberapa skema dan kebijakan cuti yang disesuaikan untuk berbagai kelompok karyawan',
                    'Jenis cuti dan transaksi cuti tanpa batas yang didukung',
                ],
                sDKeys: [
                    'Kebijakan ini mencakup jenis cuti seperti cuti tahunan, cuti sakit, cuti orang tua, cuti berduka, dll.',
                    'Atur kriteria kelayakan untuk kebijakan',
                    'Tingkat akumulasi, aturan penggunaan, dokumentasi yang diperlukan',
                ],
            },
            {
                title: 'Layanan mandiri untuk aplikasi cuti dan alur kerja persetujuan',
                description: [
                    'Otomatisasi mengurangi upaya karyawan. Karyawan mengajukan cuti melalui portal web atau aplikasi mobile',
                    'Pelacak cuti untuk saldo dan transaksi cuti',
                    'Tampilan manajer dari data cuti tim & pola cuti untuk wawasan yang lebih baik',
                    'Alur kerja dengan eskalasi menghasilkan sistem yang responsif dan pengalaman karyawan yang baik',
                    'Notifikasi dan pemberitahuan email',
                ],
                sDKeys: [
                    'Karyawan mengajukan cuti melalui portal web atau aplikasi mobile',
                    'Pelacak cuti untuk saldo dan transaksi cuti',
                    'Tampilan manajer dari data cuti tim & pola cuti untuk wawasan yang lebih baik',
                    'Alur kerja dengan eskalasi menghasilkan sistem yang responsif dan pengalaman karyawan yang baik',
                    'Notifikasi dan pemberitahuan email',
                ],
            },
            {
                title: 'Mematuhi kebijakan perusahaan dan undang-undang ketenagakerjaan',
                description: [
                    'Manajemen cuti karyawan yang efektif tidak hanya memastikan kepatuhan terhadap undang-undang ketenagakerjaan tetapi juga berkontribusi pada budaya kerja yang positif. Dengan menerapkan kebijakan cuti yang efisien dan memanfaatkan teknologi, organisasi dapat memperlancar proses dan mendukung kebutuhan waktu istirahat karyawan mereka.',
                ],
                sDKeys: [
                    'Kelola kewajiban cuti untuk anggaran secara efektif dan minimalkan risiko keuangan',
                    'Hasilkan data statistik untuk laporan individu untuk melacak kinerja berdasarkan bulanan, kuartalan, atau tahunan',
                    'Tetap terinformasi tentang perencanaan tenaga kerja, alokasi sumber daya, dan strategi keterlibatan karyawan',
                    'Catatan & laporan terperinci sesuai dengan aturan pemerintah',
                    'Daftar hari libur yang disesuaikan untuk tenaga kerja yang beragam',
                ],
            },
        ],
    },
    shiftManagement: {
        featureHeader: 'Manajemen Shift',
        featureSubcaption:
            'Jadwalkan tenaga kerja dengan aktivitas penjualan, pastikan tingkat staf yang optimal untuk memenuhi permintaan pelanggan dan memaksimalkan produktivitas.',
        featureDescription:
            'Organisasi dan jadwalkan jam kerja yang mengoptimalkan produktivitas, memenuhi kebutuhan operasional, memastikan cakupan di berbagai periode waktu.',
        featureParagraph:
            'Tidak perlu lagi kartu waktu, tumpukan kertas, dan berbagai dokumen untuk memeriksa kehadiran karyawan. Otomatisasi proses pemantauan kehadiran dengan WebWork Time Tracker dan lihat awal dan akhir jam kerja karyawan Anda dalam format yang terstruktur dengan baik.',
        featureCaption: 'Lacak shift karyawan Anda berdasarkan total jam kerja dan agregasi sesi multiple',
        keyPoints: [
            {
                header: 'Kelola Jadwal Kerja Karyawan',
                paragraph:
                    'Kembangkan jadwal shift yang jelas dan terorganisir dengan baik. Tentukan berbagai jenis shift berdasarkan sifat bisnis Anda.',
            },
            {
                header: 'Menentukan Shift, Menetapkan Peran, Mengatur Jadwal Berkala atau Kustom',
                paragraph:
                    'Analisis beban kerja dan tugas untuk menentukan jumlah karyawan yang diperlukan selama shift yang berbeda.',
            },
            {
                header: 'Alat Komunikasi Terintegrasi',
                paragraph:
                    'Platform terpusat untuk karyawan mengakses jadwal mereka, dan membangun saluran komunikasi yang dapat diandalkan untuk pembaruan atau perubahan.',
            },
            {
                header: 'Manajemen Shift Terpusat dengan Jadwal Lokasi Ganda',
                paragraph:
                    'Pengawasan dan kontrol terpusat terhadap jadwal karyawan, memungkinkan manajer untuk secara efisien mengalokasikan shift, melacak kehadiran, dan mengelola sumber daya tenaga kerja di berbagai lokasi dari satu platform.',
            },
        ],
        softwareDetails: [
            {
                title: 'Otomatisasi proses penjadwalan berdasarkan lokasi dan shift',
                description: [
                    'Analisis beban kerja dan tugas untuk menentukan jumlah karyawan yang diperlukan selama shift yang berbeda.',
                    'Pertimbangkan jam sibuk bisnis, variasi musiman, dan kebutuhan spesifik untuk setiap shift. Ini bisa mencakup shift pagi, shift malam, shift rotasi, shift terpisah, atau jadwal fleksibel.',
                ],
                sDKeys: [
                    'Pertukaran shift antar karyawan',
                    'Karyawan dapat menukar atau mengganti shift mereka dengan karyawan lain menggunakan fitur/opsi yang tersedia',
                    'Karyawan dapat memilih shift dan waktu libur mereka yang paling sesuai untuk mereka',
                ],
            },
            {
                title: 'Papan shift untuk penjadwalan shift dinamis',
                description: [
                    'Pastikan kepatuhan terhadap undang-undang ketenagakerjaan mengenai istirahat dan periode istirahat',
                    'Bangun istirahat ke dalam jadwal shift untuk mencegah kelelahan karyawan dan menjaga produktivitas',
                    'Pastikan bahwa kebijakan manajemen shift Anda sesuai dengan persyaratan hukum',
                ],
                sDKeys: [
                    'Membangun jadwal kerja yang efisien untuk tim Anda menggunakan alat penjadwalan yang ramah pengguna',
                    'Sesuaikan shift untuk memenuhi tuntutan operasional bisnis Anda dan ketersediaan karyawan',
                    'Pastikan distribusi shift yang adil dengan mempertimbangkan preferensi karyawan dan beban kerja',
                ],
            },
            {
                title: 'Dorong karyawan dengan tunjangan shift untuk bekerja di jam-jam aneh',
                description: [
                    'Integrasikan sistem manajemen shift dengan proses penggajian untuk memastikan kompensasi dan potongan yang akurat berdasarkan shift yang bekerja',
                    'Menentukan prosedur untuk lembur dan memastikan bahwa kebijakan lembur mematuhi peraturan ketenagakerjaan',
                ],
                sDKeys: [
                    'Setujui permintaan shift karyawan dengan satu klik',
                    'Karyawan dapat memilih shift mereka sendiri pada awal minggu/bulan dan mengirimkan permintaan dari portal mereka sesuai kenyamanan mereka',
                    'Solusi menguntungkan dengan membayar tunjangan shift berdasarkan tarif gaji per jam atau kebijakan perusahaan',
                ],
            },
        ],
    },
    geoFenceBasedManagement: {
        featureHeader: 'Kehadiran Berdasarkan Geo Fence',
        featureSubcaption:
            'Maksimalkan akurasi dan akuntabilitas, lacak kehadiran karyawan dengan batas yang ditentukan',
        featureDescription:
            'Teknologi berbasis lokasi untuk memantau dan memverifikasi kehadiran karyawan dalam batas geografis yang telah ditentukan',
        featureCaption:
            'Kehadiran berbasis Geo Fence memanfaatkan presisi pelacakan kehadiran Anda karena karyawan hanya dianggap hadir saat mereka berada di area yang ditentukan',
        keyPoints: [
            {
                header: 'Lokasi Karyawan dengan Akurat dalam Area Geo-Fenced yang Ditentukan',
                paragraph:
                    'Catatan kehadiran yang akurat untuk memastikan bahwa karyawan hadir di lokasi kerja mereka yang ditentukan',
            },
            {
                header: 'Pemantauan Lokasi Karyawan Secara Real-Time',
                paragraph: 'Terapkan pelacakan real-time untuk memantau lokasi karyawan di dalam geo-fence',
            },
            {
                header: 'Tentukan Batas Geografis Spesifik untuk Clocking In atau Out',
                paragraph:
                    'Tetapkan batas geografis spesifik, yang dikenal sebagai geo-fences, di sekitar tempat kerja atau lokasi kehadiran yang ditentukan',
            },
            {
                header: 'Peringatan Geo-Fence',
                paragraph:
                    'Siapkan peringatan atau notifikasi untuk memberitahu manajer atau personel HR ketika karyawan memasuki atau meninggalkan batas geo-fence yang ditentukan',
            },
        ],
        softwareDetails: [
            {
                title: 'Pembatasan lokasi pada penandaan kehadiran',
                description: [
                    'Menetapkan batas geografis spesifik menggunakan koordinat GPS atau teknologi berbasis lokasi. Batas ini mewakili area di mana karyawan diharapkan hadir untuk bekerja.',
                ],
                sDKeys: [
                    'Fitur pembatasan pengguna opsional untuk memungkinkan kehadiran dari alamat IP tertentu untuk memastikan kehadiran dari sekitar kantor.',
                    'Kemudahan komunikasi dengan pengguna tertentu atau semua pengguna menggunakan Fitur Notifikasi Push.',
                    'Tautkan tugas dengan karyawan kehadiran untuk melihat tugas dan jadwal harian.',
                ],
            },
            {
                title: 'Tetapkan batasan kehadiran dan otentikasi biometrik',
                description: [
                    'Melaksanakan pelacakan lokasi secara real-time untuk memantau lokasi karyawan dalam area geo-fenced. Informasi ini digunakan untuk mengonfirmasi kehadiran dan memastikan bahwa karyawan berada dalam lokasi kerja yang ditentukan selama jam kerja yang dijadwalkan.',
                ],
                sDKeys: [
                    'Karyawan hanya dapat menandai kehadiran mereka setelah mencapai lokasi tugas pertama mereka.',
                    'Karyawan dapat menandai kehadiran mereka dari jarak jauh; tidak perlu lagi kehadiran yang salah atau bepergian ke kantor setiap hari hanya untuk menandai kehadiran mereka.',
                    'Manajer dapat melacak lokasi secara real-time dan menerima pembaruan dari jarak jauh melalui aplikasi ponsel atau web.',
                ],
            },
            {
                title: 'Tingkatkan keamanan dan otorisasi',
                description: [
                    'Terapkan langkah-langkah otentikasi yang aman untuk mencegah entri kehadiran yang curang dengan menggunakan kode PIN, otentikasi biometrik, dan pengenalan wajah.',
                    'Siapkan notifikasi atau peringatan mobile untuk karyawan, mengingatkan mereka untuk check-in saat mereka memasuki area geo-fenced. Ini memastikan bahwa karyawan sadar akan sistem pelacakan kehadiran.',
                ],
            },
        ],
    },
    multipleClockInOptions: {
        featureHeader: 'Opsi Clock In Multiple',
        featureSubcaption:
            'Memberikan fleksibilitas dan kenyamanan, opsi clock in multiple memberdayakan karyawan untuk mengelola waktu mereka secara efisien.',
        featureDescription:
            'Memberikan fleksibilitas kepada karyawan untuk mencatat jam kerja mereka menggunakan pemindai biometrik, aplikasi seluler, jam waktu, dan platform berbasis web.',
        featureCaption:
            'Memudahkan penggunaan untuk karyawan yang bekerja dari jarak jauh dan dapat melakukan clock in beberapa kali dengan sesi yang berbeda',
        keyPoints: [
            {
                header: 'Cocok untuk Organisasi dengan Beberapa Lokasi',
                paragraph:
                    'Opsi clock in yang fleksibel untuk manajemen kehadiran di lapangan dan di luar lapangan dari berbagai cabang.',
            },
            {
                header: 'Portal Layanan Mandiri Karyawan',
                paragraph:
                    'Memberikan akses kepada karyawan ke portal layanan mandiri catatan kehadiran mereka dan mengelola check-in dan check-out mereka dalam geo-fence.',
            },
            {
                header: 'Berbagai Opsi Clock In',
                paragraph:
                    'Opsi clock-in yang fleksibel mencakup pemindaian biometrik, aplikasi seluler, kartu RFID, dan pengenalan wajah, memastikan pelacakan waktu yang mulus di berbagai lingkungan kerja.',
            },
            {
                header: 'Clock In Berbasis Geo yang Akurat',
                paragraph:
                    'Penandaan kehadiran auto clock in untuk karyawan dalam jangkauan lokasi kantor dengan notifikasi peringatan.',
            },
        ],
        softwareDetails: [
            {
                title: 'Clock In di Lokasi dan di Luar Lokasi',
                description: [
                    'Menyesuaikan dengan berbagai lingkungan kerja, sistem manajemen kehadiran karyawan kami menawarkan berbagai opsi clock-in.',
                    'Apakah karyawan berada di lokasi, di lapangan, atau bekerja dari jarak jauh, mereka dapat dengan mudah mencatat waktu mereka melalui pemindaian biometrik, pengenalan wajah, dan clock in berbasis geo fence.',
                ],
            },
            {
                title: 'Portal Layanan Mandiri Karyawan untuk Memeriksa Catatan Mereka',
                description: [
                    'Portal layanan mandiri karyawan memberikan kontrol atas beberapa check-in dan check-out.',
                    'Dengan fitur yang ramah pengguna, karyawan dapat dengan mudah mengelola jam kerja mereka apakah mereka beralih antara shift, lokasi, atau tugas.',
                    'Ini memberdayakan staf dengan fleksibilitas sambil menjaga catatan waktu yang akurat, meningkatkan efisiensi, dan menyederhanakan proses administratif.',
                ],
            },
            {
                title: 'Clock In Multiple untuk Cabang Multiple',
                description: [
                    'Mengakomodasi kebutuhan dinamis perusahaan Anda yang mencakup beberapa lokasi dan memastikan pelacakan kehadiran yang akurat serta efisiensi operasional.',
                ],
            },
        ],
    },
    attendanceReport: {
        featureHeader: 'Laporan Kehadiran',
        featureSubcaption: 'Refleksi mendalam tentang komitmen dan ketepatan waktu tim, tertangkap dalam satu laporan.',
        featureDescription:
            'Merangkum data kehadiran karyawan, jam kerja, ketidakhadiran, keterlambatan, dan keberangkatan awal dalam jangka waktu tertentu.',
        featureCaption:
            'Hasilkan laporan otomatis untuk berbagai cabang dan terima laporan tersebut setiap hari, mingguan, bulanan dengan lebih dari 40+ template.',
        keyPoints: [
            {
                header: 'Automasi Detail Kehadiran: Waktu Masuk, Waktu Keterlambatan, Total Jam Kerja, Total Sesi',
                paragraph:
                    'Memberikan ringkasan keseluruhan tentang kehadiran, termasuk jumlah hari kerja, total jam kerja, dan jam lembur.',
            },
            {
                header: 'Laporan Beberapa Cabang',
                paragraph:
                    'Akses laporan kehadiran beberapa cabang secara jarak jauh dengan kategori yang dapat disesuaikan.',
            },
            {
                header: 'Saldo Lembur dan Cuti',
                paragraph:
                    'Tampilkan saldo cuti untuk setiap karyawan, termasuk hari libur, cuti sakit, dan jenis cuti lainnya yang telah terakumulasi dan digunakan.',
            },
            {
                header: 'Laporan Kehadiran Kustom',
                paragraph:
                    'Izinkan pengguna untuk menyesuaikan laporan berdasarkan kriteria tertentu, seperti departemen, tim, atau karyawan individu.',
            },
        ],
        softwareDetails: [
            {
                title: 'Hasilkan laporan berdasarkan struktur organisasi Anda.',
                description: [
                    'Laporan kehadiran yang dihasilkan dan dianalisis secara reguler dapat memberikan wawasan berharga untuk meningkatkan manajemen tenaga kerja, mengidentifikasi tren, dan memastikan kepatuhan terhadap kebijakan organisasi.',
                    'Detail laporan akan bergantung pada kebutuhan dan struktur organisasi.',
                ],
                sDKeys: [
                    'Informasi yang dikumpulkan dari berbagai ujung pengguna digabungkan untuk memberikan analitik otomatis yang mengurangi waktu dan sumber daya yang dihabiskan untuk menghasilkan laporan secara manual.',
                    'Pengguna dapat memilih analitik yang diperlukan dalam beberapa klik dan dapat berlangganan kapan saja.',
                    'Laporan analitik contoh dapat diunduh dan dilihat sebagai representasi sebenarnya dari laporan yang diinginkan.',
                ],
            },
            {
                title: 'Dapatkan laporan yang dapat disesuaikan dengan mudah.',
                description: [
                    'Identifikasi dan soroti pengecualian atau ketidakberesan dalam kehadiran, seperti ketidakhadiran yang tidak sah, keterlambatan yang berlebihan, atau kasus lembur yang tidak disetujui.',
                    'Gunakan grafik atau diagram untuk secara visual mewakili tren dan pola kehadiran. Ini dapat memudahkan identifikasi outlier dan tren dengan cepat.',
                ],
                sDKeys: [
                    'Pengguna dapat memanfaatkan wawasan data yang dihasilkan dalam berbagai cara seperti pemrosesan gaji, penilaian kinerja, dan evaluasi, penggantian biaya, dll.',
                    'Laporan dapat dibagikan dengan pengguna lain berdasarkan alur otoritas hierarkis.',
                    'Perusahaan dapat secara kolektif mengetahui status kerja dan kinerja tim mereka dengan cara yang paling mudah tanpa intervensi manual.',
                ],
            },
            {
                title: 'Kurangi waktu yang dihabiskan untuk laporan manual.',
                description: [
                    'Termasuk detail fundamental seperti nama karyawan, nomor identifikasi, dan afiliasi departemen untuk memberikan konteks pada data kehadiran.',
                    'Menentukan dengan jelas rentang tanggal dan waktu yang dicakup oleh laporan. Ini membantu pengguna memahami ruang lingkup temporal data.',
                    'Memberikan ringkasan keseluruhan tentang kehadiran, termasuk jumlah hari kerja, total jam kerja, dan jam lembur.',
                ],
            },
        ],
    },
    workforceTracking: {
        featureHeader: 'Pelacakan Tenaga Kerja',
        featureDescription:
            'Pemantauan dan pengelolaan sistematis terhadap aktivitas, produktivitas, dan kehadiran karyawan.',
        featureParagraph:
            'Tidak perlu lagi kartu waktu, tumpukan dokumen, dan berbagai dokumen untuk memeriksa kehadiran karyawan. Otomatiskan proses pemantauan kehadiran dengan WebWork Time Tracker dan lihat awal serta akhir jam kerja karyawan Anda dalam format yang terstruktur dengan baik.',
        featureCaption:
            'Pantau dan kelola berbagai aspek tenaga kerja organisasi, termasuk kehadiran karyawan, jam kerja, produktivitas, dan kinerja.',
        keyPoints: [
            {
                header: 'Pelacakan Berdasarkan Garis Waktu untuk Kehadiran dan Pergerakan',
                paragraph:
                    'Pantau peristiwa seperti masuk dan keluar jam kerja serta wawasan karyawan tentang operasi, metrik kinerja, atau peristiwa.',
            },
            {
                header: 'Formulir Kustom untuk Manajemen Data',
                paragraph:
                    'Untuk notulen rapat, simpan data untuk rapat dan tugas untuk melacak tren dan pengambilan keputusan berbasis data.',
            },
            {
                header: 'Manajemen Pelanggan dan Prospek',
                paragraph:
                    'Pantau produktivitas dan ukur indikator kinerja kunci berdasarkan jumlah pekerjaan yang diselesaikan oleh karyawan.',
            },
            {
                header: 'Perhitungan Jarak Total untuk Klaim Penggantian Biaya yang Akurat',
                paragraph:
                    'Lacak jarak tempuh karyawan yang akurat untuk pekerjaan lapangan berdasarkan metrik harian, mingguan, dan bulanan, serta ganti biaya sesuai dengan jarak dan lokasi yang ditempuh.',
            },
        ],
        softwareDetails: [
            {
                title: 'Terapkan sistem pelacakan waktu untuk memantau jam kerja.',
                description: [
                    'Menerapkan solusi pelacakan waktu untuk memantau waktu yang dihabiskan karyawan pada tugas dan proyek.',
                    'Ini dapat membantu dalam memahami pemanfaatan sumber daya dan meningkatkan manajemen waktu.',
                    'Manfaatkan sistem manajemen proyek untuk melacak kemajuan proyek, menetapkan tugas, dan memantau tenggat waktu.',
                    'Ini membantu dalam mengoptimalkan alur kerja proyek dan memastikan penyelesaian tepat waktu.',
                ],
            },
            {
                title: 'Pantau indikator kinerja kunci yang relevan.',
                description: [
                    'Menetapkan dan melacak indikator kinerja kunci yang relevan dengan tujuan organisasi Anda.',
                    'Ini mungkin termasuk target penjualan, metrik kepuasan pelanggan, atau indikator kinerja lainnya.',
                ],
                sDKeys: [
                    'Kinerja Penjualan: Pendapatan Penjualan Bulanan/Kuartalan, jumlah Kesepakatan Ditutup, Tingkat Konversi Penjualan, ukuran Kesepakatan Rata-rata, Nilai Pipeline Penjualan.',
                    'Layanan Pelanggan: Skor kepuasan pelanggan (CSAT), Skor promotor bersih (NPS), waktu respons pertama, waktu penyelesaian rata-rata, Tingkat retensi pelanggan, Volume tiket.',
                    'Operasi/Produksi: Output/throughput produksi, metrik kualitas (tingkat cacat, tingkat kesalahan), kinerja Pengiriman Tepat Waktu, rasio perputaran inventaris, waktu henti peralatan, tingkat pemanfaatan karyawan.',
                    'Umum: Produktivitas karyawan (output per jam), Kehadiran dan ketepatan waktu, Tingkat pencapaian tujuan, Umpan balik karyawan dan penilaian kinerja.',
                ],
            },
            {
                title: 'Hasilkan laporan tren tenaga kerja Anda.',
                description: [
                    'Mengintegrasikan fitur analitik dan pelaporan untuk mendapatkan wawasan tentang tren tenaga kerja, kinerja, dan area untuk perbaikan.',
                    'Laporan yang disesuaikan dapat membantu pengambilan keputusan.',
                ],
                sDKeys: [
                    'Perbandingan komposisi demografis dengan tenaga kerja secara keseluruhan atau tolok ukur industri dan identifikasi tren atau pergeseran demografis dari waktu ke waktu.',
                    'Perbandingan metrik kinerja di berbagai tim lapangan, wilayah, atau periode waktu.',
                    'Pemetaan geospasial dari aktivitas tenaga kerja lapangan untuk memvisualisasikan distribusi dan area cakupan.',
                    'Analisis kinerja penjualan atau layanan berdasarkan wilayah geografis atau teritori. Identifikasi area berpotensi tinggi untuk ekspansi atau optimasi.',
                ],
            },
        ],
    },
    fieldSalesAnalytics: {
        featureHeader: 'Analitik Penjualan Lapangan',
        featureDescription:
            'Lacak dan analisis metrik kunci dari tim penjualan di lapangan seperti pendapatan penjualan, interaksi dengan pelanggan, dan tren geografis.',
        featureParagraph:
            'Tidak perlu lagi kartu waktu, tumpukan dokumen, dan berbagai dokumen untuk memeriksa kehadiran karyawan. Otomatiskan proses pemantauan kehadiran dengan WebWork Time Tracker dan lihat awal serta akhir jam kerja karyawan Anda dalam format yang terstruktur dengan baik.',
        featureCaption:
            'Analisis pengumpulan, analisis, dan interpretasi data terkait aktivitas penjualan lapangan untuk mendapatkan wawasan dan membuat keputusan yang terinformasi.',
        keyPoints: [
            {
                header: 'Indikator Kinerja Kunci untuk Penjualan dan Prospek',
                paragraph:
                    'Indikator Kinerja Kunci dapat membantu dalam membuat keputusan berbasis data yang dapat membantu dalam membuat keputusan produktif di masa depan atau merombak strategi.',
            },
            {
                header: 'Laporan Kustom',
                paragraph:
                    'Laporan dapat diakses secara harian, mingguan, atau bulanan mengenai Penjualan, Prospek, dan Akuisisi Pelanggan. Ini dapat membantu dalam produktivitas dan efisiensi seseorang.',
            },
            {
                header: 'Validasi Kunjungan Penjualan dan Penyesuaian dengan Geofence',
                paragraph:
                    'Validasi kunjungan dengan check-in berbasis Geofence untuk akurasi. Koordinat lokasi dapat dilampirkan pada foto kamera saat berbagi.',
            },
            {
                header: 'Ukur Efektivitas Rute Beat',
                paragraph:
                    'Rute Beat dapat digunakan untuk merencanakan kunjungan atau rencana beat untuk karyawan ke kunjungan klien. Integrasikan rencana beat Anda di Maps secara langsung untuk mengoptimalkan navigasi.',
            },
        ],
        softwareDetails: [
            {
                title: 'Wawasan lapangan sehari-hari untuk kinerja penjualan yang lebih baik.',
                description: [
                    'Generasi laporan e-report yang berbasis data analitik, fitur laporan yang andal dan mudah digunakan untuk akses dan penggunaan Anda.',
                    'Anda sebagai manajer penjualan akan memiliki pandangan yang jelas tentang berbagai laporan yang akan dikirim ke alamat email Anda atau Anda juga dapat melihatnya di dasbor Anda.',
                    'Beberapa laporan yang signifikan termasuk laporan bulanan, laporan berdasarkan lokasi cabang, laporan khusus karyawan, dll.',
                ],
            },
            {
                title: 'Pandangan keseluruhan tentang kehadiran lapangan kerja.',
                description: [
                    'Pandangan keseluruhan tentang kehadiran lapangan karyawan dan kunjungan lapangan.',
                    'Memiliki pandangan menyeluruh tentang kehadiran tenaga kerja lapangan dan pertemuan.',
                    'Dalam laporan kehadiran lapangan, Anda akan mendapatkan data tentang waktu penandaan kehadiran lapangan, lokasi, waktu kehadiran terlambat, jam kerja, dll.',
                    'Dengan cara yang sama, kami juga menyediakan laporan check-in/out kunjungan lapangan. Ini termasuk data terkait kunjungan lapangan seperti waktu login/out, waktu check-in/out, lokasi pertemuan, jarak yang ditempuh, catatan hasil pertemuan, dll.',
                ],
            },
            {
                title: 'Laporan yang dihasilkan berbasis data analitik',
                description: [
                    'Berlangganan dan unduh e-report bulanan.',
                    'Anda dapat berlangganan dan mengunduh e-report bulanan kapan saja Anda mau.',
                    'Tersedia dalam format file pdf dan excel. Sekumpulan laporan lengkap untuk Anda yang memberikan informasi terperinci tentang tindakan eksternal seorang karyawan di luar organisasi yang secara manual cenderung menjadi tugas yang sulit.',
                ],
            },
        ],
    },
    locationBasedAttendance: {
        featureHeader: 'Kehadiran Berdasarkan Lokasi',
        featureDescription:
            'Kehadiran berbasis geofence untuk memastikan bahwa karyawan hanya dapat melakukan clock in atau clock out ketika mereka berada dalam batas geografis yang telah ditentukan.',
        featureCaption:
            'Kehadiran berdasarkan lokasi memastikan ketepatan untuk memantau dan mencatat kehadiran karyawan berdasarkan lokasi fisik mereka.',
        keyPoints: [
            {
                header: 'Geofencing',
                paragraph:
                    'Implementasikan batasan virtual geofence di sekitar lokasi spesifik dari tempat kantor. Membantu dalam otomatisasi kehadiran berdasarkan karyawan yang memasuki atau meninggalkan area yang ditentukan.',
            },
            {
                header: 'Autentikasi dan Keamanan',
                paragraph:
                    'Metode autentikasi untuk memastikan akurasi dan keamanan data kehadiran menggunakan PIN, biometrik, atau kehadiran wajah.',
            },
            {
                header: 'Pembaruan Real-Time',
                paragraph:
                    'Pastikan catatan kehadiran diperbarui secara tepat waktu, memungkinkan keputusan manajerial yang tepat waktu.',
            },
            {
                header: 'Pertimbangan Kerja Jarak Jauh',
                paragraph:
                    'Pertimbangkan metode alternatif untuk pelacakan kehadiran bagi karyawan yang bekerja jarak jauh.',
            },
        ],
        softwareDetails: [
            {
                title: 'Waktu dan lokasi tenaga kerja.',
                description: [
                    'Ketahui Kapan dan Dimana Karyawan Anda Bekerja',
                    'Instruksikan karyawan Anda untuk memberikan izin di ponsel mereka sehingga dapat melacak waktu dan lokasi.',
                    'Setelah izin diberikan, pelacak dapat mulai berfungsi dengan sekali ketuk.',
                ],
            },
            {
                title: 'Pelaporan Langsung',
                description: [
                    'Implementasi sistem kehadiran karyawan berbasis lokasi dapat meningkatkan akurasi dan menyederhanakan manajemen kehadiran. Namun, penting untuk menyeimbangkan manfaat dengan pertimbangan privasi karyawan dan berkomunikasi secara transparan tentang implementasi dan penggunaan sistem semacam itu.',
                ],
            },
            {
                title: 'Peringatan Berdasarkan Geofence',
                description: [
                    'Atur peringatan otomatis untuk pola kehadiran yang tidak biasa atau ketidaksesuaian. Ini membantu dalam mengidentifikasi masalah potensial, seperti check-in atau check-out yang tidak sah.',
                    'Implementasikan fitur pelaporan dan analitik untuk mendapatkan wawasan tentang pola kehadiran, tren, dan pengecualian.',
                ],
                sDKeys: [
                    'Terima peringatan saat aset berharga seperti kendaraan atau peralatan memasuki atau meninggalkan area yang ditentukan, membantu mencegah pencurian atau penggunaan yang tidak sah.',
                    'Pastikan keselamatan pekerja lapangan dengan mengatur geofences di sekitar area berbahaya atau lokasi kerja yang terpencil, memicu peringatan jika karyawan memasuki zona terbatas.',
                    'Saat perangkat memasuki atau keluar dari area yang tergeofence, sistem memicu peringatan atau pemberitahuan yang telah ditentukan sebelumnya.',
                    'Sistem terus memantau lokasi perangkat yang dilacak secara real-time dan memeriksa apakah mereka bersinggungan dengan geofences yang didefinisikan.',
                ],
            },
        ],
    },
    liveReporting: {
        featureHeader: 'Pelaporan Langsung',
        featureDescription:
            'Pelacakan dan analisis waktu nyata dari berbagai aspek pekerjaan mereka, seperti kehadiran, tugas yang diselesaikan, atau kemajuan proyek.',
        featureCaption:
            'Tetap terinformasi tentang tugas karyawan, menit rapat, validasi kunjungan, dan buat keputusan berdasarkan data.',
        keyPoints: [
            {
                header: 'Notifikasi Berdasarkan Acara',
                paragraph:
                    'Admin atau Manajer dapat berlangganan atau memilih notifikasi yang ingin diterima. Ini membantu fokus pada notifikasi yang benar-benar diinginkan.',
            },
            {
                header: 'Tetap Terinformasi tentang Kehadiran dan Cuti',
                paragraph:
                    'Selalu tahu jumlah karyawan sebelum hari dimulai sehingga perencanaan hari bisa lebih produktif dan efisien.',
            },
            {
                header: 'Lacak Semua Persetujuan',
                paragraph: 'Lacak semua persetujuan mulai dari penggantian biaya hingga cuti dan lihat mereka nanti.',
            },
            {
                header: 'Tetap Terupdate dengan Formulir dan MOM',
                paragraph:
                    'Formulir kustom dapat membantu dalam mendapatkan data spesifik untuk rapat yang dapat diakses dari jarak jauh.',
            },
        ],
        softwareDetails: [
            {
                title: 'Temukan tenaga lapangan secara real-time.',
                description: [
                    'Temukan staf lapangan Anda secara real-time.',
                    'Dapatkan detail lokasi instan dari tenaga lapangan Anda saat mereka bepergian secara real-time.',
                    'Treant memungkinkan Anda untuk menemukan dan melacak karyawan lapangan Anda menggunakan GPS ponsel mereka baik secara online maupun offline. Tetap terupdate pada setiap gerakan staf lapangan Anda dan pertahankan lingkungan kerja yang transparan.',
                ],
            },
            {
                title: 'Tetap terupdate dengan informasi lapangan karyawan Anda.',
                description: [
                    'Tetap terupdate dengan informasi lapangan karyawan Anda.',
                    'Informasi lapangan staf Anda akan otomatis disinkronkan dengan dasbor Anda.',
                    'Detail karyawan Anda seperti persentase baterai, GPS, waktu internet menyala/mati, waktu ideal yang mereka habiskan di lapangan akan diberitahukan kepada Anda secara instan dan waktu nyata.',
                ],
            },
            {
                title: 'Automasi data penggantian biaya dan tunjangan karyawan.',
                description: [
                    'Sejarah lokasi yang didukung AI untuk data akurat.',
                    'Data lokasi karyawan Anda akan didukung oleh Kecerdasan Buatan untuk memberikan informasi rute dan pelacakan yang akurat. Data pelacakan langsung dan sejarah lokasi lainnya akan otomatis dikirimkan ke kotak surat Anda secara berkala untuk pemahaman yang lebih mendalam tentang apa yang terjadi di dan di luar lapangan.',
                ],
            },
        ],
    },
    aIBasedOptimization: {
        featureHeader: 'Optimisasi Berbasis AI',
        featureDescription:
            'Optimalkan dan otomatisasikan perencanaan rute untuk perwakilan lapangan atau tenaga penjualan berdasarkan rute beat AI.',
        featureCaption:
            'Optimalkan rute untuk hari ini dan bandingkan laporan dengan musim sebelumnya dengan algoritma AI kami.',
        keyPoints: [
            {
                header: 'Integrasi Data Waktu Nyata',
                paragraph:
                    'Sumber data waktu nyata, seperti pembaruan lalu lintas langsung, kondisi cuaca, dan penutupan jalan, untuk menyesuaikan rute secara dinamis berdasarkan keadaan saat ini membantu dalam perencanaan harian yang efisien.',
            },
            {
                header: 'Antarmuka Pengguna untuk Perencanaan Rute',
                paragraph:
                    'Lihat rute yang dioptimalkan, dan lakukan penyesuaian yang diperlukan. Ini bisa menjadi bagian dari aplikasi seluler atau platform berbasis web.',
            },
            {
                header: 'Metode Produktivitas',
                paragraph: 'Sistem AI untuk memperhitungkan karakteristik unik dari berbagai beat atau rute.',
            },
            {
                header: 'Pemetaan dan Data Geospasial',
                paragraph:
                    'Manfaatkan layanan pemetaan dan data geospasial untuk memahami jaringan jalan, kondisi lalu lintas, dan informasi terkait lokasi lainnya.',
            },
        ],
        softwareDetails: [
            {
                title: 'Optimisasi Rute dengan Pembaruan Waktu Nyata',
                description: [
                    'Optimalkan dan rencanakan rute yang paling efisien untuk individu atau kendaraan, seperti truk pengiriman, perwakilan penjualan, atau teknisi layanan lapangan, yang mengikuti beat atau rute tertentu sebagai bagian dari aktivitas reguler mereka.',
                    'Aplikasi ini dapat membantu dalam meningkatkan efisiensi, mengurangi waktu perjalanan, dan meminimalkan biaya operasional.',
                ],
                sDKeys: [
                    'Mengumpulkan data dari berbagai sumber, termasuk perangkat GPS, laporan lalu lintas, pembaruan cuaca, dan sensor kendaraan.',
                    'Waktu respons yang lebih cepat dan kedatangan tepat waktu meningkatkan kepuasan pelanggan, yang sangat penting bagi bisnis yang berorientasi pada layanan.',
                ],
            },
            {
                title: 'Memerlukan Pertimbangan Hati-Hati tentang Masalah Privasi dan Kepatuhan',
                description: [
                    'Pastikan bahwa sistem berbasis AI mematuhi peraturan lokal, terutama yang terkait dengan privasi, keamanan data, dan undang-undang transportasi.',
                    'Secara teratur memperbarui dan meningkatkan algoritma AI berdasarkan data baru dan umpan balik, dengan tujuan untuk optimisasi rute beat yang berkelanjutan.',
                ],
            },
            {
                title: 'Integrasi dengan Sistem Lain untuk Efisiensi Perencanaan Rute',
                description: [
                    'Gunakan algoritma pembelajaran mesin untuk menganalisis data historis dan memprediksi pola di masa depan.',
                    'Ini dapat membantu dalam mengantisipasi kondisi lalu lintas, waktu pengiriman, dan faktor lain yang mempengaruhi perencanaan rute.',
                ],
            },
        ],
    },
    leadManagement: {
        featureHeader: 'Manajemen Lead',
        featureDescription:
            'Ringkasan data kehadiran karyawan, jam kerja, ketidakhadiran, keterlambatan, dan keberangkatan awal dalam jangka waktu tertentu.',
        featureCaption:
            'Hasilkan laporan otomatis untuk berbagai cabang dan terima setiap hari, mingguan, atau bulanan dengan lebih dari 40+ template.',
        keyPoints: [
            {
                header: 'Automasi Detail Kehadiran: Waktu Masuk, Waktu Terlambat, Total Jam Kerja, Total Sesi',
                paragraph:
                    'Memberikan ringkasan keseluruhan kehadiran, termasuk jumlah hari kerja total, total jam kerja, dan jam lembur jika ada.',
            },
            {
                header: 'Laporan Cabang Multiple',
                paragraph:
                    'Akses laporan kehadiran cabang multiple secara jarak jauh dengan kategori yang dapat disesuaikan.',
            },
            {
                header: 'Saldo Lembur dan Cuti',
                paragraph:
                    'Tampilkan saldo cuti untuk setiap karyawan, termasuk hari libur, cuti sakit, dan jenis cuti lainnya yang diperoleh dan digunakan.',
            },
            {
                header: 'Laporan Kehadiran yang Disesuaikan',
                paragraph:
                    'Izinkan pengguna untuk menyesuaikan laporan berdasarkan kriteria tertentu, seperti departemen, tim, atau karyawan individu.',
            },
        ],
        softwareDetails: [
            {
                title: 'Hasilkan laporan berdasarkan struktur organisasi Anda.',
                description: [
                    'Laporan kehadiran yang dihasilkan dan dianalisis secara teratur dapat memberikan wawasan berharga untuk meningkatkan manajemen tenaga kerja, mengidentifikasi tren, dan memastikan kepatuhan terhadap kebijakan organisasi.',
                    'Spesifikasi laporan akan bergantung pada kebutuhan dan struktur organisasi.',
                ],
            },
            {
                title: 'Mendapatkan laporan yang dapat disesuaikan dengan mudah.',
                description: [
                    'Identifikasi dan soroti pengecualian atau ketidakteraturan dalam kehadiran, seperti ketidakhadiran yang tidak sah, keterlambatan yang berlebihan, atau kasus lembur yang tidak disetujui.',
                    'Gunakan grafik atau diagram untuk mewakili tren dan pola kehadiran secara visual. Ini dapat mempermudah identifikasi penyimpangan dan tren sekilas.',
                ],
            },
            {
                title: 'Kurangi waktu yang dihabiskan untuk laporan manual.',
                description: [
                    'Mencakup detail dasar seperti nama karyawan, nomor identifikasi, dan afiliasi departemen untuk memberikan konteks pada data kehadiran.',
                    'Menetapkan secara jelas rentang tanggal dan waktu yang tercakup dalam laporan. Ini membantu pengguna memahami cakupan temporal data.',
                    'Memberikan ringkasan keseluruhan kehadiran, termasuk jumlah hari kerja total, total jam kerja, dan jam lembur jika ada.',
                ],
            },
        ],
    },
    taskManagement: {
        featureHeader: 'Manajemen Tugas',
        featureDescription:
            'Pemantauan dan manajemen sistematis dari aktivitas, produktivitas, dan kehadiran karyawan.',
        featureParagraph:
            'Tidak perlu lagi kartu waktu, pekerjaan berlebihan, dan dokumen berbeda untuk memeriksa kehadiran karyawan. Otomatiskan proses pemantauan kehadiran dengan WebWork Time Tracker dan lihat awal dan akhir jam kerja karyawan Anda dalam format yang terstruktur dengan baik.',
        featureCaption:
            'Pantau dan kelola berbagai aspek tenaga kerja organisasi, termasuk kehadiran karyawan, jam kerja, produktivitas, dan kinerja.',
        keyPoints: [
            {
                header: 'Pantau secara real-time',
                paragraph:
                    'Pantau kemajuan secara real-time dengan melihat papan tugas, memeriksa status tugas, dan melacak waktu yang dihabiskan.',
            },
            {
                header: 'Pantau semua alur kerja Anda di satu tempat untuk seluruh organisasi',
                paragraph:
                    'Fitur kolaborasi manajemen tugas untuk berkomunikasi dengan anggota tim secara real-time. Berkomentar pada tugas, bertanya, dan memberikan pembaruan untuk menjaga semua orang tetap terinformasi.',
            },
            {
                header: 'Pantau kinerja',
                paragraph:
                    'Manajemen pelanggan dan lead untuk melacak produktivitas. Produktivitas karyawan dan indikator kinerja utama dapat diukur berdasarkan jumlah pekerjaan yang diselesaikan oleh karyawan.',
            },
            {
                header: 'Standarisasi penetapan tujuan',
                paragraph:
                    'Struktur hierarkis untuk mengorganisir tujuan: tujuan proyek, tujuan departemen, tujuan kinerja individu, inisiatif strategis, dll.',
            },
        ],
        softwareDetails: [
            {
                title: 'Implementasikan sistem pelacakan waktu untuk memantau jam kerja.',
                description: [
                    'Implementasi solusi pelacakan waktu untuk memantau waktu yang dihabiskan karyawan pada tugas dan proyek.',
                    'Ini dapat membantu dalam memahami pemanfaatan sumber daya dan meningkatkan manajemen waktu.',
                    'Manfaatkan sistem manajemen proyek untuk melacak kemajuan proyek, menetapkan tugas, dan memantau tenggat waktu.',
                    'Ini membantu dalam mengoptimalkan alur kerja proyek dan memastikan penyelesaian tepat waktu.',
                ],
            },
            {
                title: 'Pantau indikator kinerja utama yang relevan.',
                description: [
                    'Menetapkan dan melacak indikator kinerja utama yang relevan dengan tujuan organisasi Anda.',
                    'Ini mungkin termasuk target penjualan, metrik kepuasan pelanggan, atau indikator kinerja lainnya.',
                ],
            },
            {
                title: 'Hasilkan laporan tren tenaga kerja Anda.',
                description: [
                    'Integrasi fitur analitik dan pelaporan untuk mendapatkan wawasan tentang tren tenaga kerja, kinerja, dan area untuk perbaikan.',
                    'Laporan yang disesuaikan dapat membantu pengambilan keputusan.',
                ],
            },
        ],
    },
    salesPipelineMonitoring: {
        featureHeader: 'Pemantauan Pipeline Penjualan',
        featureDescription:
            'Lacak dan analisis metrik kunci dari tim penjualan di lapangan seperti pendapatan penjualan, interaksi pelanggan, dan tren geografis.',
        featureParagraph:
            'Tidak perlu lagi kartu waktu, pekerjaan berlebihan, dan dokumen berbeda untuk memeriksa kehadiran karyawan. Otomatiskan proses pemantauan kehadiran dengan WebWork Time Tracker dan lihat awal dan akhir jam kerja karyawan Anda dalam format yang terstruktur dengan baik.',
        featureCaption:
            'Analisis pengumpulan, analisis, dan interpretasi data terkait aktivitas penjualan di lapangan untuk mendapatkan wawasan dan membuat keputusan yang terinformasi.',
        keyPoints: [
            {
                header: 'Alur Kerja Otomatis',
                paragraph:
                    'Manfaatkan sistem untuk menyederhanakan dan mengoptimalkan berbagai tahap proses penjualan seperti penangkapan lead, kualifikasi, pemeliharaan, manajemen pipeline, pelacakan aktivitas, dan pelaporan.',
            },
            {
                header: 'Integrasi dengan CRM',
                paragraph:
                    'Sederhanakan manajemen lead, pelacakan kontak, pemantauan kesepakatan, dan sinkronisasi data antara kedua platform.',
            },
            {
                header: 'Fasilitasi Kolaborasi dan Komunikasi',
                paragraph:
                    'Saluran komunikasi terpusat, penjadwalan rapat penjualan, tinjauan kesepakatan kolaboratif, mendokumentasikan proses, kolaborasi lintas fungsi, mempromosikan komunikasi terbuka.',
            },
            {
                header: 'Wawasan tentang Kinerja Penjualan di Masa Depan',
                paragraph:
                    'Manfaatkan analisis data dan pemodelan prediktif untuk meramalkan hasil dan tren potensial.',
            },
        ],
        softwareDetails: [
            {
                title: 'Wawasan Lapangan Sehari-hari untuk Kinerja Penjualan yang Lebih Baik',
                description: [
                    'Pembuatan laporan e-report berbasis analitik data. Fitur laporan yang dapat diandalkan dan ramah pengguna untuk akses dan penggunaan Anda yang mudah.',
                    'Sebagai manajer penjualan, Anda akan memiliki tampilan jelas berbagai laporan yang akan dikirim ke alamat email Anda atau Anda juga dapat melihatnya di dasbor Anda.',
                    'Beberapa laporan penting adalah laporan bulanan, laporan berdasarkan lokasi cabang, laporan khusus karyawan, dll.',
                ],
            },
            {
                title: 'Tampilan Keseluruhan pada Kehadiran Kerja Lapangan',
                description: [
                    'Tampilan keseluruhan pada kehadiran karyawan lapangan dan kunjungan lapangan.',
                    'Dapatkan pandangan komprehensif tentang kehadiran tenaga kerja lapangan dan pertemuan.',
                    'Dalam laporan kehadiran lapangan, Anda akan mendapatkan data tentang penandaan kehadiran lapangan, waktu lokasi, waktu kehadiran terlambat, jam kerja, dll.',
                    'Demikian pula, laporan check-in/out kunjungan lapangan mencakup data terkait kunjungan lapangan seperti waktu login/out, waktu check-in/out, lokasi pertemuan, jarak tempuh, catatan hasil pertemuan, dll.',
                ],
            },
            {
                title: 'Pembuatan Laporan Berbasis Analitik Data',
                description: [
                    'Berlangganan dan unduh e-report bulanan.',
                    'Anda dapat berlangganan dan mengunduh e-report bulanan kapan saja Anda mau.',
                    'Tersedia dalam format file PDF dan Excel. Kumpulan laporan lengkap yang disusun untuk Anda agar memiliki informasi rinci tentang tindakan eksternal karyawan di luar organisasi, yang biasanya sulit dilakukan secara manual.',
                ],
            },
        ],
    },
    workflowAutomation: {
        featureHeader: 'Otomatisasi Alur Kerja',
        featureDescription:
            'Kehadiran berbasis geofence untuk memastikan bahwa karyawan hanya dapat masuk atau keluar saat mereka berada dalam batas geografis yang telah ditentukan.',
        featureCaption:
            'Kehadiran berbasis lokasi menjamin ketepatan untuk memantau dan mencatat kehadiran karyawan berdasarkan lokasi fisik mereka.',
        keyPoints: [
            {
                header: 'Penugasan Tugas Otomatis',
                paragraph:
                    'Otomatisasi alur kerja untuk secara otomatis menetapkan tugas kepada individu yang paling tepat, dengan mempertimbangkan faktor seperti keterampilan, ketersediaan, beban kerja, dan tingkat prioritas.',
            },
            {
                header: 'Routing, Kualifikasi, dan Skoring Lead',
                paragraph:
                    'Routing lead, kualifikasi, dan skoring untuk menetapkan lead yang masuk kepada perwakilan penjualan yang sesuai, menilai kualitasnya, dan memprioritaskan tindakan tindak lanjut.',
            },
            {
                header: 'Integrasi dengan Sistem Eksternal',
                paragraph:
                    'Memfasilitasi kolaborasi yang lebih lancar, meningkatkan akurasi data, dan memastikan alur kerja yang kohesif di berbagai fungsi bisnis.',
            },
            {
                header: 'Pelacakan dan Pelaporan Kinerja',
                paragraph:
                    'Melacak metrik seperti waktu penyelesaian proses, tingkat kesalahan, pemanfaatan sumber daya, dan produktivitas keseluruhan.',
            },
        ],
        softwareDetails: [
            {
                title: 'Waktu dan Lokasi Tenaga Kerja.',
                description: [
                    'Ketahui Kapan dan Di Mana Karyawan Anda Bekerja',
                    'Instruksikan karyawan Anda untuk memberikan izin di ponsel mereka agar sistem dapat melacak waktu dan lokasi.',
                    'Setelah izin diberikan, pelacak dapat mulai berfungsi dengan sekali ketuk.',
                ],
            },
            {
                title: 'Pelaporan Langsung Dua',
                description: [
                    'Implementasi sistem kehadiran karyawan berbasis lokasi dapat meningkatkan akurasi dan menyederhanakan manajemen kehadiran. Namun, penting untuk menyeimbangkan manfaat dengan pertimbangan privasi karyawan dan berkomunikasi secara transparan tentang implementasi dan penggunaan sistem tersebut.',
                ],
            },
            {
                title: 'Peringatan Berdasarkan Geo-Fence',
                description: [
                    'Atur peringatan otomatis untuk pola kehadiran yang tidak biasa atau ketidaksesuaian. Ini membantu dalam mengidentifikasi masalah potensial, seperti check-in atau check-out yang tidak sah.',
                    'Implementasikan fitur pelaporan dan analitik untuk mendapatkan wawasan tentang pola kehadiran, tren, dan pengecualian.',
                ],
            },
        ],
    },
    aIBasedAssistance: {
        featureHeader: 'Bantuan Berbasis AI',
        featureDescription:
            'Pelacakan dan analisis waktu nyata dari berbagai aspek pekerjaan mereka, seperti kehadiran, tugas yang diselesaikan, atau kemajuan proyek.',
        featureCaption:
            'Tetap terinformasi tentang tugas karyawan, Notulen Rapat, validasi kunjungan, dan buat keputusan berdasarkan data.',
        keyPoints: [
            {
                header: 'Pemberitahuan Berdasarkan Acara',
                paragraph:
                    'Admin atau Manajer dapat berlangganan atau memilih pemberitahuan yang ingin mereka terima. Ini membantu fokus pada pemberitahuan yang benar-benar ingin diterima.',
            },
            {
                header: 'Tetap Terinformasi tentang Kehadiran dan Cuti',
                paragraph:
                    'Tetap Terinformasi tentang Kehadiran dan Cuti. Tetap satu langkah di depan dan ketahui jumlah karyawan sebelumnya sehingga perencanaan hari dapat lebih produktif dan efisien.',
            },
            {
                header: 'Pantau Semua Persetujuan',
                paragraph:
                    'Pantau Semua Persetujuan. Lacak semua persetujuan dari penggantian biaya hingga cuti dan lihat nanti.',
            },
            {
                header: 'Tetap Terupdate dengan Formulir dan MOM',
                paragraph:
                    'Pelacakan Jarak Jauh melalui Formulir dan Notulen Rapat. Formulir kustom dapat membantu dalam mengambil data spesifik untuk rapat yang dapat diakses dari jarak jauh.',
            },
        ],
        softwareDetails: [
            {
                title: 'Temukan tenaga lapangan Anda secara real-time.',
                description: [
                    'Temukan staf lapangan Anda secara real-time.',
                    'Dapatkan detail lokasi instan dari tenaga kerja lapangan Anda saat mereka bepergian secara real-time.',
                    'Treant memungkinkan Anda untuk menemukan dan melacak karyawan lapangan Anda menggunakan GPS ponsel mereka baik secara online maupun offline. Tetap terupdate tentang setiap gerakan staf lapangan Anda dan pertahankan lingkungan kerja yang transparan.',
                ],
            },
            {
                title: 'Tetap terupdate dengan informasi lapangan karyawan Anda.',
                description: [
                    'Tetap terupdate dengan informasi lapangan karyawan Anda.',
                    'Informasi lapangan staf akan secara otomatis disinkronkan dengan dasbor Anda.',
                    'Detail karyawan Anda seperti persentase baterai, GPS, visibilitas internet on/off, waktu ideal yang mereka habiskan di lapangan akan diberitahukan kepada Anda secara instan dan real-time.',
                ],
            },
            {
                title: 'Otomatisasikan data penggantian biaya dan tunjangan karyawan.',
                description: [
                    'Sejarah lokasi yang didorong AI untuk data yang akurat.',
                    'Data lokasi karyawan Anda akan didorong oleh Kecerdasan Buatan untuk memberikan informasi rute dan pelacakan yang akurat. Data pelacakan langsung dan sejarah lokasi lainnya akan otomatis dikirim ke kotak surat Anda secara berkala untuk pendalaman lebih dalam tentang apa yang terjadi di lapangan maupun di luar lapangan.',
                ],
            },
        ],
    },
    digitizationOfOrder: {
        featureHeader: 'Digitalisasi Pesanan',
        featureDescription:
            'Pelacakan dan analisis waktu nyata dari berbagai aspek pekerjaan mereka, seperti kehadiran, tugas yang diselesaikan, atau kemajuan proyek.',
        featureCaption: 'Sederhanakan manajemen pesanan dari awal hingga akhir dan kurangi kesalahan manual.',
        keyPoints: [
            {
                header: 'Pelaporan dan Analitik',
                paragraph:
                    'Hasilkan laporan dan analitik terperinci untuk memantau kinerja pemrosesan pesanan dan produktivitas karyawan.',
            },
            {
                header: 'Entri Pesanan Otomatis',
                paragraph:
                    'Tangkap dan validasi detail pesanan secara otomatis yang dimasukkan oleh karyawan atau pelanggan.',
            },
            {
                header: 'Pelacakan dan Pembaruan Status Pesanan Real-Time',
                paragraph:
                    'Berikan pembaruan waktu nyata tentang status pesanan dan pelacakan pengiriman kepada karyawan dan pelanggan.',
            },
            {
                header: 'Otomatisasi Alur Kerja dan Penugasan Tugas',
                paragraph: 'Otomatiskan proses persetujuan pesanan dan tugaskan tugas kepada karyawan yang sesuai.',
            },
        ],
    },
    targetManagement: {
        featureHeader: 'Manajemen Target',
        featureDescription:
            'Pelacakan dan analisis waktu nyata dari berbagai aspek pekerjaan mereka, seperti kehadiran, tugas yang diselesaikan, atau kemajuan proyek.',
        featureCaption:
            'Tetap terinformasi tentang tugas karyawan, notulen rapat, validasi kunjungan, dan ambil keputusan berdasarkan data.',
        keyPoints: [
            {
                header: 'Integrasi dengan Pelacakan Lapangan',
                paragraph:
                    'Capai target harian, mingguan, dan bulanan serta simpan catatan pencapaian karyawan dengan pelacakan lapangan secara langsung.',
            },
            {
                header: 'Pembaruan dan Sinkronisasi Waktu Nyata',
                paragraph:
                    'Kemajuan waktu nyata (jumlah target yang dicapai vs. sisa), waktu check-in/check-out, jumlah jam (durasi) yang dihabiskan pada setiap tugas, dan pelacakan lokasi.',
            },
            {
                header: 'Kinerja dan Analitik',
                paragraph:
                    'Metrik kinerja (tingkat penyelesaian, waktu yang dihabiskan, skor kepuasan pelanggan), perbandingan terhadap target (kinerja aktual vs. target), data historis (tren kinerja masa lalu).',
            },
            {
                header: 'Pemberitahuan dan Peringatan Otomatis',
                paragraph:
                    'Kirim pemberitahuan otomatis untuk menjaga karyawan terinformasi tentang pemberitahuan pencapaian target, pengingat kinerja, dan peringatan tenggat waktu.',
            },
        ],
    },
    invoiceManagement: {
        featureHeader: 'Manajemen Faktur',
        featureDescription:
            'Pelacakan dan analisis waktu nyata dari berbagai aspek pekerjaan mereka, seperti kehadiran, tugas yang diselesaikan, atau kemajuan proyek.',
        featureCaption:
            'Buat, lacak status faktur secara waktu nyata, dan proses dengan peringatan dan pemberitahuan otomatis.',
        keyPoints: [
            {
                header: 'Pemberitahuan dan Pengingat Otomatis',
                paragraph:
                    'Atur sistem untuk melacak status faktur dan mengirim pengingat serta peringatan otomatis untuk pembayaran yang jatuh tempo dan yang telah melewati batas waktu.',
            },
            {
                header: 'Templat Faktur',
                paragraph:
                    'Templat faktur yang distandarisasi yang mencakup semua detail yang diperlukan seperti informasi pelanggan, detail pesanan, pajak, diskon, dan syarat pembayaran.',
            },
            {
                header: 'Tanda Tangan Digital dan Bukti Pengiriman',
                paragraph:
                    'Kumpulkan tanda tangan digital dan bukti pengiriman berbasis OTP sebagai bagian dari proses faktur.',
            },
            {
                header: 'Sinkronisasi Faktur dan Pembaruan Waktu Nyata',
                paragraph:
                    'Sinkronisasi otomatis dengan sistem pusat (penyimpanan berbasis cloud) dan pembaruan waktu nyata pada status faktur.',
            },
        ],
    },
    distributorOrder: {
        featureHeader: 'Pesanan Distributor',
        featureDescription:
            'Pelacakan dan analisis waktu nyata dari berbagai aspek pekerjaan mereka, seperti kehadiran, tugas yang diselesaikan, atau kemajuan proyek.',
        featureCaption:
            'Tetap terinformasi tentang tugas karyawan, Notulen rapat, validasi kunjungan, dan ambil keputusan berdasarkan data.',
        keyPoints: [
            {
                header: 'Informasi Distribusi',
                paragraph:
                    'Buat dan proses pesanan dengan semua detail relevan untuk setiap distribusi dan tetapkan tugas berdasarkan ketersediaan.',
            },
            {
                header: 'Integrasi Inventaris Waktu Nyata',
                paragraph:
                    'Integrasikan dengan manajemen inventaris untuk memastikan alokasi stok yang akurat dan perbarui tingkat inventaris secara waktu nyata.',
            },
            {
                header: 'Otomatisasi Pemberitahuan dan Peringatan',
                paragraph:
                    'Konfigurasikan sistem untuk mengirim pemberitahuan otomatis pada tahap-tahap penting dari proses pesanan.',
            },
            {
                header: 'Dasbor Karyawan',
                paragraph:
                    'Akses dasbor di mana karyawan dapat memeriksa pesanan, melacak kemajuan, dan mengunduh dokumen yang diperlukan.',
            },
        ],
    },
    inventoryManagement: {
        featureHeader: 'Manajemen Inventaris',
        featureDescription:
            'Pelacakan dan analisis waktu nyata dari berbagai aspek pekerjaan mereka, seperti kehadiran, tugas yang diselesaikan, atau kemajuan proyek.',
        featureCaption:
            'Keseimbangan yang tepat dari stok untuk pembaruan waktu nyata dan pelacakan inventaris untuk data yang akurat.',
        keyPoints: [
            {
                header: 'Integrasi dengan Manajemen Pesanan',
                paragraph:
                    'Pastikan integrasi yang mulus antara sistem inventaris dan manajemen pesanan untuk menyinkronkan tingkat stok dengan pesanan yang masuk dan keluar.',
            },
            {
                header: 'Manajemen Multi-Lokasi',
                paragraph:
                    'Lacak inventaris di berbagai gudang, toko, atau lokasi dan akses kontrol terpusat, optimalkan distribusi stok, dan tingkatkan visibilitas.',
            },
        ],
    },
    orderCollectionManagement: {
        featureHeader: 'Manajemen Pesanan/Koleksi',
        featureDescription: 'Permudah operasi pesanan/koleksi dengan solusi yang dapat diskalakan dan otomatis.',
        featureCaption: 'Permudah operasi pesanan/koleksi dengan solusi yang dapat diskalakan dan otomatis.',
        keyPoints: [
            {
                header: 'Sistem Manajemen Pesanan Terpadu',
                paragraph:
                    'Sistem terpusat yang dapat diakses oleh karyawan di lokasi dan luar lokasi untuk mengelola pesanan, melacak kemajuan, dan memperbarui status secara waktu nyata.',
            },
            {
                header: 'Kontrol Akses Berdasarkan Peran',
                paragraph:
                    'Tingkat akses dan izin yang berbeda berdasarkan peran (misalnya, manajer, karyawan di lokasi, karyawan luar lokasi) untuk memastikan keamanan data dan akses yang sesuai.',
            },
            {
                header: 'Sinkronisasi Waktu Nyata',
                paragraph:
                    'Pastikan bahwa semua data pesanan disinkronkan secara waktu nyata di semua perangkat dan lokasi, sehingga setiap orang memiliki akses ke informasi terbaru.',
            },
            {
                header: 'Komunikasi dan Koordinasi',
                paragraph:
                    'Fasilitasi komunikasi antara karyawan di lokasi dan luar lokasi melalui pesan dan notifikasi di dalam aplikasi.',
            },
        ],
    },
};

export default features;
