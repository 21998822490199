const footer = {
    requestACallBack: 'Solicitar una Llamada',
    freeAccountMessage: 'Cree su cuenta gratuita de 14 días ahora',
    footerMessage:
        'Revolucionando la gestión de empleados y la operación de ventas mediante la automatización de tareas administrativas, la optimización de procesos y el aumento de la participación de los empleados. Nuestras funciones son simples, potentes y se adaptan fácilmente a cualquier industria para aumentar la productividad de la organización.',
    address:
        '922, A Wing, Nyati Empress,\nJunto a Baker and Guages India Pvt Ltd,\nOff Viman Nagar Road, Viman Nagar, 411014, Pune.',
    rightsReserved: 'TraceForce Todos los Derechos Reservados',
};
export default footer;
