const features = {
    attendanceMonitoring: {
        featureSubcaption: 'हर टिक में दक्षता - कर्मचारी प्रबंधन प्रणाली के लिए नवीन समाधान',
        featureHeader: 'उपस्थिति निगरानी',
        featureDescription:
            'जियोफेंस क्षेत्र या शाखा के भीतर व्यक्तियों की उपस्थिति या अनुपस्थिति के बारे में वास्तविक समय की घड़ी की जानकारी',
        featureParagraph:
            'अब समयकार्ड, अत्यधिक कागजी कार्रवाई और कर्मचारियों की उपस्थिति की जांच के लिए विभिन्न दस्तावेज़ नहीं होंगे। WebWork टाइम ट्रैकर के साथ उपस्थिति निगरानी प्रक्रिया को स्वचालित करें और आपके कर्मचारियों के कामकाजी घंटों की शुरुआत और अंत को एक सुव्यवस्थित प्रारूप में देखें',
        featureCaption:
            'अपने क्षेत्रीय कर्मचारियों और ऑन-प्रिमाइज़ कर्मचारियों के लिए कई शाखाओं की उपस्थिति प्रबंधित करें',
        keyPoints: [
            {
                header: 'वास्तविक समय की उपस्थिति',
                paragraph:
                    'जियोफेंस क्षेत्र या शाखा के भीतर व्यक्तियों की उपस्थिति या अनुपस्थिति के बारे में वास्तविक समय की जानकारी',
            },
            {
                header: 'कुल कामकाजी घंटों की गणना',
                paragraph: 'दैनिक, साप्ताहिक और मासिक आधार पर कामकाजी घंटों की निगरानी करें',
            },
            {
                header: 'दूरस्थ पहुंच उपस्थिति रिपोर्ट',
                paragraph: 'कई शाखाओं की उपस्थिति रिपोर्ट दूरस्थ रूप से प्राप्त करें',
            },
            {
                header: 'केंद्रीकृत उपस्थिति प्रबंधन',
                paragraph:
                    'उपस्थिति-संबंधित डेटा, प्रक्रियाओं और प्रणालियों का एकल, केंद्रीकृत प्लेटफॉर्म या सिस्टम में समेकन',
            },
        ],
        softwareDetails: [
            {
                title: 'कर्मचारी उपस्थिति ट्रैकिंग पर अंतर्दृष्टि प्राप्त करें',
                description: [
                    'कर्मचारियों की समय पर उपस्थिति की पारदर्शिता प्राप्त करें',
                    'समय बचाएं और किसी अतिरिक्त कार्रवाई के बिना कर्मचारी उपस्थिति को व्यवस्थित करें।',
                    'आसान से निगरानी करें कि क्या आपके कर्मचारी कार्यस्थल पर समय पर पहुंचे और अपने कामकाजी घंटे पूरे किए या नहीं।',
                    'अपने कार्यस्थल की गतिविधियों को अधिक व्यवस्थित रखें और एक जिम्मेदार कार्य वातावरण बनाए रखें।',
                ],
                sDKeys: [
                    'जियोफेंस परिधि सेट करके ऑन-प्रिमाइज़ स्टाफ को स्वचालित रूप से लॉग इन और लॉग आउट करें।',
                    'उन कर्मचारियों के लिए अधिसूचना अलर्ट प्राप्त करें जिन्होंने लॉग इन या लॉग आउट करना भूल गए हैं।',
                    'जैसे ही वे अपनी उपस्थिति लॉग करते हैं, अपने क्षेत्रीय स्टाफ की गतिविधियों को वास्तविक समय में ट्रैक करें।',
                ],
            },
            {
                title: 'विस्तृत उपस्थिति निगरानी रिपोर्ट प्राप्त करें',
                description: [
                    'उपस्थिति डेटा का बुद्धिमान विश्लेषण',
                    'प्रारंभिक छोड़ने, देर से आने और कम ट्रैकिंग को फ़िल्टर करें जिन्हें आप ड्रॉप-डाउन सूची से चुन सकते हैं।',
                    'यह आपकी पसंदीदा जानकारी तुरंत उत्पन्न करने में मदद करेगा बिना उसे मैन्युअल रूप से खोजे।',
                    'आप दैनिक आधार पर उपस्थिति ट्रैक कर सकते हैं या कैलेंडर से एक अंतराल चुन सकते हैं।',
                ],
                sDKeys: [
                    'अपनी संगठन के लिए उपस्थिति सारांश प्राप्त करें।',
                    'टीम, विभाग या शाखाओं के भीतर प्रवृत्तियों को देखने के लिए रिपोर्ट उत्पन्न करें और उनका उपयोग करें।',
                    'नियमित अंतराल पर अपने मेलबॉक्स में रिपोर्ट प्राप्त करें (लॉग इन समय, लॉग आउट समय, कुल कामकाजी घंटे, कुल सत्र)',
                    'बेहतर निर्णय लेने के लिए सूचनात्मक डेटा।',
                ],
            },
            {
                title: 'अन्य समय और उत्पादकता सुविधाओं की खोज करें।',
                description: [
                    'एक सुरक्षित और पारदर्शी तरीका जो वास्तविक समय में 100% बिना संपर्क प्रणाली के कर्मचारियों की उपस्थिति को ट्रैक करता है।',
                    'आसान सेटअप',
                    'स्वचालित चेक इन और चेक आउट विस्तृत रिपोर्ट',
                    'ट्रैकिंग समय के साथ स्क्रीनशॉट',
                    'गतिविधि स्तर रिकॉर्डिंग',
                    'ऐप्स और वेबसाइट उपयोग निगरानी',
                ],
                sDKeys: [
                    'कर्मचारी उपस्थिति को प्रबंधित करने और उत्पादकता बढ़ाने में सहायता करता है।',
                    'कागजी कार्रवाई को समाप्त करें और सटीक कर्मचारी उपस्थिति रिकॉर्ड एकत्र करें।',
                ],
            },
        ],
    },
    leaveManagement: {
        featureHeader: 'अवकाश प्रबंधन',
        feaatureSubcaption:
            'कर्मचारियों की अनुपस्थिति का समन्वय करें जबकि उत्पादकता स्तर और संचालन निरंतरता बनाए रखें।',
        featureDescription:
            'अनुपस्थितियों और उपस्थियों को प्रभावी ढंग से प्रबंधित और ट्रैक करें, सुनिश्चित करें कि उचित दस्तावेजीकरण, कंपनी नीतियों का पालन हो, और निर्बाध कार्यप्रवाह निरंतरता बनाए रखें।',
        featureParagraph:
            'अब समयकार्ड, अत्यधिक कागजी कार्रवाई और कर्मचारियों की उपस्थिति की जांच के लिए विभिन्न दस्तावेज़ नहीं होंगे। WebWork टाइम ट्रैकर के साथ उपस्थिति निगरानी प्रक्रिया को स्वचालित करें और आपके कर्मचारियों के कामकाजी घंटों की शुरुआत और अंत को एक सुव्यवस्थित प्रारूप में देखें।',
        featureCaption:
            'अपने कर्मचारियों के आगामी अवकाश के बारे में पूर्व में सूचित रहें और अपने दिन की योजना अनुसार बनाएं',
        keyPoints: [
            {
                header: 'अवकाश अर्जन और शेष की स्वचालित गणना',
                paragraph:
                    'अवकाश अर्जनों का प्रबंधन करें, सुनिश्चित करें कि कर्मचारी निर्धारित मात्रा से अधिक न जाएं, और नकारात्मक अवकाश शेष को रोकें।',
            },
            {
                header: 'लचीले अवकाश नीतियां, अनुमोदन वर्कफ़्लोज़',
                paragraph:
                    'अवकाश कैलेंडर सेट करके संगठन की लचीली अवकाश नीतियां कॉन्फ़िगर करें। अवकाश अनुरोधों की स्वीकृति या अस्वीकृति के लिए समानांतर या अनुक्रमिक पदानुक्रम सेटअप सक्षम करें।',
            },
            {
                header: 'श्रम कानूनों और कंपनी नीतियों का पालन',
                paragraph:
                    'अवकाश अधिकारों से संबंधित श्रम कानूनों और विनियमों के बारे में सूचित रहें। सुनिश्चित करें कि आपकी अवकाश प्रबंधन नीतियां कंपनी की नीतियों के साथ अनुपालन में हैं।',
            },
            {
                header: 'स्व-सेवा विकल्प',
                paragraph:
                    'कर्मचारियों को स्व-सेवा विकल्प प्रदान करें, जिससे वे अपने अवकाश शेष की जांच कर सकें, अनुरोध जमा कर सकें, और अपने अनुरोध की स्थिति देख सकें।',
            },
        ],
        softwareDetails: [
            {
                title: 'आपकी टीम के लिए कई और लचीले अवकाश नीतियां',
                description: [
                    'आपकी सटीक आवश्यकताओं को पूरा करने के लिए लचीली अवकाश नीतियां',
                    'विभिन्न कर्मचारी समूहों के लिए कई अवकाश योजनाएं और नीतियां',
                    'असीमित अवकाश प्रकार और अवकाश लेनदेन समर्थित',
                ],
                sDKeys: [
                    'इन नीतियों में वार्षिक अवकाश, बीमार अवकाश, पेरेंटल अवकाश, शोक अवकाश आदि जैसे अवकाश प्रकार शामिल हैं।',
                    'नीतियों के लिए पात्रता मानदंड सेट करें',
                    'अर्जन दरें, उपयोग नियम, आवश्यक दस्तावेज़',
                ],
            },
            {
                title: 'अवकाश आवेदन और अनुमोदन वर्कफ़्लो के लिए स्व-सेवा',
                description: [
                    'स्वचालन कर्मचारी प्रयासों को कम करता है। कर्मचारी वेब पोर्टल या मोबाइल ऐप के माध्यम से अवकाश के लिए आवेदन करते हैं',
                    'अवकाश शेष और लेनदेन के लिए अवकाश ट्रैकर',
                    'टीम अवकाश डेटा और अवकाश पैटर्न के लिए प्रबंधक दृश्य बेहतर अंतर्दृष्टि के लिए',
                    'वर्कफ़्लोज़ के साथ उत्थान एक प्रतिक्रियाशील प्रणाली और एक शानदार कर्मचारी अनुभव की ओर ले जाता है',
                    'ईमेल सूचनाएं और अलर्ट',
                ],
                sDKeys: [
                    'कर्मचारी वेब पोर्टल या मोबाइल ऐप के माध्यम से अवकाश के लिए आवेदन करते हैं',
                    'अवकाश शेष और लेनदेन के लिए अवकाश ट्रैकर',
                    'टीम अवकाश डेटा और अवकाश पैटर्न के लिए प्रबंधक दृश्य बेहतर अंतर्दृष्टि के लिए',
                    'वर्कफ़्लोज़ के साथ उत्थान एक प्रतिक्रियाशील प्रणाली और एक शानदार कर्मचारी अनुभव की ओर ले जाता है',
                    'ईमेल सूचनाएं और अलर्ट',
                ],
            },
            {
                title: 'कंपनी नीतियों और श्रम कानून का पालन करें',
                description: [
                    'प्रभावी कर्मचारी अवकाश प्रबंधन न केवल श्रम कानूनों के साथ अनुपालन सुनिश्चित करता है बल्कि एक सकारात्मक कार्यस्थल संस्कृति में भी योगदान करता है। प्रभावी अवकाश नीतियों को लागू करके और प्रौद्योगिकी का उपयोग करके, संगठन प्रक्रिया को सुव्यवस्थित कर सकते हैं और अपने कर्मचारियों की समय की आवश्यकता को समर्थन दे सकते हैं।',
                ],
                sDKeys: [
                    'बजट के लिए अवकाश दायित्वों का प्रभावी ढंग से प्रबंधन करें और वित्तीय जोखिमों को कम करें',
                    'मासिक, त्रैमासिक या वार्षिक आधार पर प्रदर्शन ट्रैक करने के लिए सांख्यिकीय डेटा उत्पन्न करें',
                    'वर्कफोर्स योजना, संसाधन आवंटन और कर्मचारी सगाई रणनीतियों के बारे में सूचित रहें',
                    'सरकारी नियमों के अनुसार विस्तृत रिकॉर्ड और रिपोर्ट',
                    'विविध कार्यबल के लिए कस्टमाइज्ड समग्र छुट्टियों की सूची',
                ],
            },
        ],
    },
    shiftManagement: {
        featureHeader: 'शिफ्ट प्रबंधन',
        feaatureSubcaption:
            'विक्रय गतिविधियों के साथ कार्यबल की योजना बनाएं, ग्राहक की मांगों को पूरा करने के लिए आदर्श स्टाफिंग स्तर सुनिश्चित करें और उत्पादकता को अधिकतम करें',
        featureDescription:
            'काम के घंटों को व्यवस्थित और अनुसूचित करें जो उत्पादकता को अनुकूलित करता है, संचालन की आवश्यकताओं को पूरा करता है, विभिन्न समय अवधियों में कवरेज सुनिश्चित करता है',
        featureParagraph:
            'अब समयकार्ड, अत्यधिक कागजी कार्रवाई और कर्मचारियों की उपस्थिति की जांच के लिए विभिन्न दस्तावेज़ नहीं होंगे। WebWork टाइम ट्रैकर के साथ उपस्थिति निगरानी प्रक्रिया को स्वचालित करें और आपके कर्मचारियों के कामकाजी घंटों की शुरुआत और अंत को एक सुव्यवस्थित प्रारूप में देखें।',
        featureCaption: 'कर्मचारियों की शिफ्ट को कुल कामकाजी घंटों और विभिन्न सत्रों के आधार पर ट्रैक करें',
        keyPoints: [
            {
                header: 'कर्मचारियों के काम के कार्यक्रमों का प्रबंधन करें',
                paragraph:
                    'स्पष्ट और सुव्यवस्थित शिफ्ट शेड्यूल तैयार करें। आपके व्यवसाय की प्रकृति के आधार पर विभिन्न प्रकार की शिफ्ट्स परिभाषित करें।',
            },
            {
                header: 'शिफ्ट्स को परिभाषित करें, भूमिकाएँ असाइन करें, पुनरावर्ती या कस्टम शेड्यूल सेट करें',
                paragraph:
                    'भारी कामकाज और कार्यों का विश्लेषण करें ताकि विभिन्न शिफ्ट्स के दौरान कितने कर्मचारियों की आवश्यकता है, इसका निर्धारण किया जा सके।',
            },
            {
                header: 'बिल्ट-इन संचार उपकरण',
                paragraph:
                    'कर्मचारियों को उनके शेड्यूल तक पहुंच प्रदान करने के लिए केंद्रीकृत प्लेटफ़ॉर्म और किसी भी अपडेट या परिवर्तनों के लिए एक विश्वसनीय संचार चैनल स्थापित करें।',
            },
            {
                header: 'केंद्रित शिफ्ट प्रबंधन के साथ कई स्थान शेड्यूल',
                paragraph:
                    'कर्मचारियों के शेड्यूल का केंद्रीकृत पर्यवेक्षण और नियंत्रण, जिससे प्रबंधक कुशलतापूर्वक शिफ्ट आवंटित कर सकें, उपस्थिति ट्रैक कर सकें और एक ही प्लेटफ़ॉर्म से कई स्थानों में कार्यबल संसाधनों का प्रबंधन कर सकें।',
            },
        ],
        softwareDetails: [
            {
                title: 'स्थान और शिफ्ट के आधार पर शेड्यूलिंग प्रक्रिया स्वचालित करें',
                description: [
                    'शिफ्ट्स के दौरान कर्मचारियों की आवश्यकता का निर्धारण करने के लिए कामकाज और कार्यों का विश्लेषण करें',
                    'शिखर व्यापार घंटे, मौसमी उतार-चढ़ाव और प्रत्येक शिफ्ट के लिए किसी भी विशिष्ट आवश्यकताओं पर विचार करें। इसमें दिन की शिफ्ट्स, रात की शिफ्ट्स, घुमावदार शिफ्ट्स, विभाजित शिफ्ट्स या लचीले शेड्यूल शामिल हो सकते हैं।',
                ],
                sDKeys: [
                    'कर्मचारियों के बीच शिफ्ट स्वैपिंग',
                    'कर्मचारी उपलब्ध सुविधा/विकल्प के साथ अपनी शिफ्ट्स को अन्य कर्मचारियों के साथ आदान-प्रदान कर सकते हैं',
                    'कर्मचारी अपनी शिफ्ट और समय-छुट्टियों को चुन सकते हैं जो उनके लिए सबसे अच्छा हो',
                ],
            },
            {
                title: 'डायनेमिक शिफ्ट शेड्यूलिंग के लिए शिफ्ट बोर्ड',
                description: [
                    'ब्रेक और विश्राम अवधि के संबंध में श्रम कानूनों के अनुपालन को सुनिश्चित करें',
                    'शिफ्ट शेड्यूल में ब्रेक्स को शामिल करें ताकि कर्मचारी की थकावट को रोका जा सके और उत्पादकता बनाए रखी जा सके',
                    'सुनिश्चित करें कि आपकी शिफ्ट प्रबंधन नीतियां कानूनी आवश्यकताओं के साथ मेल खाती हैं',
                ],
                sDKeys: [
                    'उपयोगकर्ता-अनुकूल शेड्यूलिंग टूल्स का उपयोग करके अपनी टीम के लिए कुशल काम के शेड्यूल आसानी से बनाएं',
                    'अपने व्यवसाय की संचालनात्मक मांगों और कर्मचारी उपलब्धता के अनुसार शिफ्ट्स को अनुकूलित करें',
                    'कर्मचारी प्राथमिकताओं और काम के बोझ पर विचार करते हुए शिफ्ट्स का निष्पक्ष वितरण सुनिश्चित करें',
                ],
            },
            {
                title: 'अजीब घंटों पर काम करने के लिए कर्मचारियों को शिफ्ट भत्ते प्रदान करें',
                description: [
                    'शिफ्ट प्रबंधन सिस्टम को वेतन प्रक्रियाओं के साथ एकीकृत करें ताकि शिफ्ट्स के आधार पर सही मुआवजा और कटौतियों को सुनिश्चित किया जा सके',
                    'ओवरटाइम के लिए प्रक्रियाओं को परिभाषित करें और सुनिश्चित करें कि ओवरटाइम नीतियां श्रम नियमों के अनुपालन में हैं',
                ],
                sDKeys: [
                    'एक क्लिक में कर्मचारियों के शिफ्ट अनुरोध को मंजूरी दें',
                    'कर्मचारी सप्ताह/महीने की शुरुआत में अपनी शिफ्ट चुन सकते हैं और अपनी सुविधा के अनुसार अपने पोर्टल से अनुरोध भेज सकते हैं',
                    'घंटे की वेतन दर या कंपनी की नीति के आधार पर शिफ्ट भत्तों का भुगतान करके लाभकारी समाधान',
                ],
            },
        ],
    },
    geoFenceBasedManagement: {
        featureHeader: 'जियो फेंस आधारित उपस्थिति',
        feaatureSubcaption:
            'सटीकता और जवाबदेही को अधिकतम करें, निर्दिष्ट सीमाओं के साथ कर्मचारियों की उपस्थिति ट्रैक करें',
        featureDescription:
            'पूर्वनिर्धारित भौगोलिक सीमाओं के भीतर कर्मचारी की उपस्थिति की निगरानी और पुष्टि के लिए स्थान-आधारित तकनीक',
        featureCaption:
            'जियो फेंस आधारित उपस्थिति आपके उपस्थिति ट्रैकिंग की सटीकता का लाभ उठाती है क्योंकि कर्मचारी केवल तब ही उपस्थित माने जाते हैं जब वे निर्दिष्ट क्षेत्र में होते हैं',
        keyPoints: [
            {
                header: 'निर्दिष्ट जियो-फेंस्ड क्षेत्र के भीतर कर्मचारियों को सटीक रूप से स्थानित करें',
                paragraph:
                    'सटीक उपस्थिति रिकॉर्ड यह सुनिश्चित करने के लिए कि कर्मचारी अपने निर्दिष्ट कार्य स्थानों पर मौजूद हैं',
            },
            {
                header: 'कर्मचारियों के स्थानों की वास्तविक समय में निगरानी',
                paragraph: 'जियो-फेंस के भीतर कर्मचारियों के स्थानों की निगरानी के लिए वास्तविक समय ट्रैकिंग लागू करें',
            },
            {
                header: 'क्लॉकिंग इन या आउट के लिए विशिष्ट भौगोलिक सीमाएँ परिभाषित करें',
                paragraph:
                    'कार्यस्थल या निर्दिष्ट उपस्थिति स्थानों के चारों ओर जियो-फेंस के रूप में ज्ञात विशिष्ट भौगोलिक सीमाएँ स्थापित करें',
            },
            {
                header: 'जियोफेंस अलर्ट्स',
                paragraph:
                    'जब कर्मचारी परिभाषित जियो-फेंस की सीमाओं में प्रवेश या बाहर निकलते हैं, तो प्रबंधकों या एचआर कर्मियों को सूचित करने के लिए अलर्ट्स या सूचनाएं सेट करें',
            },
        ],
        softwareDetails: [
            {
                title: 'उपस्थिति मार्किंग पर स्थान प्रतिबंध',
                description: [
                    'GPS समन्वय या स्थान-आधारित तकनीकों का उपयोग करके विशिष्ट भौगोलिक सीमाएँ स्थापित करना। ये सीमाएँ उन क्षेत्रों का प्रतिनिधित्व करती हैं जहाँ कर्मचारियों को काम के लिए उपस्थित होना अपेक्षित है।',
                ],
                sDKeys: [
                    'विशिष्ट IP पते से उपस्थिति की अनुमति देने के लिए वैकल्पिक उपयोगकर्ता प्रतिबंधित सुविधा, जिससे कार्यालय की नजदीकियों से उपस्थिति सुनिश्चित की जा सके।',
                    'पुश सूचनाओं की सुविधा का उपयोग करके विशिष्ट या सभी उपयोगकर्ताओं के साथ संचार की आसानी।',
                    'उपस्थिति कर्मचारियों के साथ कार्यों को लिंक करें ताकि दैनिक कार्यों और शेड्यूल को देखा जा सके।',
                ],
            },
            {
                title: 'उपस्थिति प्रतिबंध और जैविक प्रमाणीकरण सेट करें',
                description: [
                    'जियो-फेंस वाले क्षेत्रों में कर्मचारियों के स्थानों की निगरानी के लिए वास्तविक समय स्थान ट्रैकिंग लागू करना। इस जानकारी का उपयोग उपस्थिति की पुष्टि करने और यह सुनिश्चित करने के लिए किया जाता है कि कर्मचारी निर्धारित कार्य स्थानों पर निर्धारित घंटों के दौरान मौजूद हैं।',
                ],
                sDKeys: [
                    'कर्मचारी केवल तब ही उपस्थिति मार्क कर सकते हैं जब वे अपने पहले कार्य स्थान पर पहुंच जाएं।',
                    'कर्मचारी दूरस्थ रूप से उपस्थिति मार्क कर सकते हैं; अब अधिक दोषपूर्ण उपस्थिति या रोजाना कार्यालय की यात्रा की आवश्यकता नहीं है।',
                    'प्रबंधक वास्तविक समय में स्थान ट्रैक कर सकते हैं और फोन अनुप्रयोगों या वेब के माध्यम से दूरस्थ रूप से अपडेट प्राप्त कर सकते हैं।',
                ],
            },
            {
                title: 'सुरक्षा और प्राधिकरण बढ़ाएं',
                description: [
                    'धोखाधड़ीपूर्ण उपस्थिति प्रविष्टियों को रोकने के लिए PIN कोड, जैविक प्रमाणीकरण और चेहरे की पहचान का उपयोग करके सुरक्षित प्रमाणीकरण उपाय लागू करें।',
                    'कर्मचारियों को जियो-फेंस क्षेत्र में प्रवेश करने पर चेक-इन करने की याद दिलाने के लिए मोबाइल सूचनाओं या अलर्ट्स की व्यवस्था करें। यह सुनिश्चित करता है कि कर्मचारी उपस्थिति ट्रैकिंग सिस्टम के बारे में जागरूक हैं।',
                ],
            },
        ],
    },
    multipleClockInOptions: {
        featureHeader: 'एकाधिक क्लॉक इन विकल्प',
        feaatureSubcaption:
            'लचीलापन और सुविधा प्रदान करते हुए, एकाधिक क्लॉक इन विकल्प कर्मचारियों को उनके समय को कुशलता से प्रबंधित करने में सक्षम बनाते हैं।',
        featureDescription:
            'कर्मचारियों को बायोमेट्रिक स्कैनर, मोबाइल ऐप्स, टाइम क्लॉक्स और वेब-आधारित प्लेटफॉर्म का उपयोग करके उनके कार्य घंटों को रिकॉर्ड करने की सुविधा प्रदान करें।',
        featureCaption:
            'रिमोटली काम करने वाले कर्मचारियों के लिए उपयोग की आसानी सक्षम करें और विभिन्न सत्रों के साथ एकाधिक बार क्लॉक इन करें',
        keyPoints: [
            {
                header: 'कई स्थानों वाले संगठनों के लिए उपयुक्त',
                paragraph: 'फील्ड और ऑफ-फील्ड उपस्थिति प्रबंधन के लिए लचीले क्लॉक इन विकल्प विभिन्न शाखाओं के लिए।',
            },
            {
                header: 'कर्मचारी स्व-सेवा पोर्टल',
                paragraph:
                    'कर्मचारियों को एक स्व-सेवा पोर्टल की पहुंच प्रदान करें जो उपस्थिति रिकॉर्ड और जियो-फेंस के भीतर चेक-इन्स और चेक-आउट्स को प्रबंधित करता है।',
            },
            {
                header: 'क्लॉकिंग इन के लिए कई विकल्प',
                paragraph:
                    'लचीले क्लॉक-इन विकल्प में बायोमेट्रिक स्कैन, मोबाइल ऐप्स, RFID कार्ड और चेहरे की पहचान शामिल हैं जो विभिन्न कार्य वातावरण में सहज समय ट्रैकिंग सुनिश्चित करते हैं।',
            },
            {
                header: 'सटीक जियो-आधारित क्लॉक इन',
                paragraph:
                    'ऑफिस परिसर की सीमा के भीतर कर्मचारियों के लिए ऑटो क्लॉक इन उपस्थिति मार्किंग के साथ अलर्ट सूचना।',
            },
        ],
        softwareDetails: [
            {
                title: 'ऑन-साइट और ऑफ-साइट क्लॉक इन',
                description: [
                    'विभिन्न कार्य वातावरण को ध्यान में रखते हुए, हमारा कर्मचारी उपस्थिति प्रबंधन प्रणाली कई क्लॉक-इन विकल्प प्रदान करता है।',
                    'चाहे कर्मचारी ऑन-साइट, फील्ड में या दूरस्थ रूप से काम कर रहे हों, वे बायोमेट्रिक स्कैन, चेहरे की पहचान और जियो-फेंस आधारित ऑटो क्लॉक इन के माध्यम से आसानी से अपना समय लॉग कर सकते हैं।',
                ],
            },
            {
                title: 'कर्मचारी स्व-सेवा पोर्टल उनके रिकॉर्ड जांचने के लिए',
                description: [
                    'कर्मचारी स्व-सेवा पोर्टल एकाधिक चेक-इन्स और चेक-आउट्स पर नियंत्रण प्रदान करता है।',
                    'उपयोगकर्ता-मित्रवत विशेषताओं के साथ, कर्मचारी आसानी से अपने कार्य घंटों का प्रबंधन कर सकते हैं चाहे वे शिफ्ट, स्थान, या कार्यों के बीच संक्रमण कर रहे हों।',
                    'यह कर्मचारियों को लचीलापन प्रदान करता है जबकि सटीक समय रिकॉर्ड बनाए रखता है, दक्षता बढ़ाता है, और प्रशासनिक प्रक्रियाओं को सुव्यवस्थित करता है।',
                ],
            },
            {
                title: 'कई शाखाओं के लिए कई क्लॉक-इन',
                description: [
                    'कई स्थानों में फैली आपकी कंपनी की गतिशील आवश्यकताओं को पूरा करें और सटीक उपस्थिति ट्रैकिंग और संचालनात्मक दक्षता सुनिश्चित करें।',
                ],
            },
        ],
    },
    attendanceReport: {
        featureHeader: 'उपस्थिति रिपोर्ट',
        feaatureSubcaption: 'टीम की प्रतिबद्धताओं और समय की पाबंदी की एक स्पष्ट झलक, एक ही रिपोर्ट में कैप्चर की गई।',
        featureDescription:
            'कर्मचारी उपस्थिति डेटा, कार्य घंटों, अनुपस्थितियों, देर से आने और जल्दी जाने को एक विशिष्ट समय सीमा के भीतर संक्षेप में प्रस्तुत करें।',
        featureCaption:
            'विभिन्न शाखाओं के लिए स्वचालित रिपोर्ट जनरेट करें और उन्हें दैनिक, साप्ताहिक, मासिक आधार पर प्राप्त करें, 40+ टेम्पलेट्स के साथ।',
        keyPoints: [
            {
                header: 'स्वचालित उपस्थिति विवरण: लॉग-इन समय, देर से आने का समय, कुल कार्य घंटे, कुल सत्र',
                paragraph:
                    'उपस्थिति का एक समग्र सारांश प्रदान करें, जिसमें कार्य दिवसों की कुल संख्या, कुल कार्य घंटे, और कोई भी ओवरटाइम घंटे शामिल हों।',
            },
            {
                header: 'कई शाखाओं की रिपोर्ट',
                paragraph: 'अनुकूलन योग्य श्रेणियों के साथ कई शाखाओं की उपस्थिति रिपोर्ट को दूरस्थ रूप से एक्सेस करें।',
            },
            {
                header: 'ओवरटाइम और छुट्टी की बैलेंस',
                paragraph:
                    'प्रत्येक कर्मचारी के लिए छुट्टी की बैलेंस को प्रदर्शित करें, जिसमें छुट्टियों के दिन, बीमार छुट्टी, और किसी भी अन्य प्रकार की छुट्टियाँ शामिल हैं।',
            },
            {
                header: 'अनुकूलित उपस्थिति रिपोर्ट',
                paragraph:
                    'उपयोगकर्ताओं को विशेष मानदंडों के आधार पर रिपोर्ट को अनुकूलित करने की अनुमति दें, जैसे कि विभाग, टीम, या व्यक्तिगत कर्मचारी।',
            },
        ],
        softwareDetails: [
            {
                title: 'अपनी संगठनात्मक संरचना के आधार पर रिपोर्ट उत्पन्न करें।',
                description: [
                    'नियमित रूप से उत्पन्न और विश्लेषण की गई उपस्थिति रिपोर्टें कार्यबल प्रबंधन में सुधार, प्रवृत्तियों की पहचान और संगठनात्मक नीतियों के अनुपालन को सुनिश्चित करने के लिए मूल्यवान अंतर्दृष्टि प्रदान कर सकती हैं।',
                    'रिपोर्ट की विशेषताएँ संगठन की जरूरतों और संरचना पर निर्भर करेंगी।',
                ],
                sDKeys: [
                    'विभिन्न उपयोगकर्ता-अंत से एकत्रित जानकारी को स्वचालित विश्लेषण देने के लिए संकलित किया जाता है, जिससे रिपोर्ट बनाने में समय और संसाधनों की बचत होती है।',
                    'उपयोगकर्ता कुछ क्लिक में अपनी आवश्यक विश्लेषण का चयन कर सकते हैं और किसी भी समय इसके लिए सदस्यता ले सकते हैं।',
                    'नमूना विश्लेषण रिपोर्ट डाउनलोड की जा सकती है और वांछित रिपोर्ट का वास्तविक प्रतिनिधित्व के रूप में देखा जा सकता है।',
                ],
            },
            {
                title: 'आसानी से अनुकूलन योग्य रिपोर्ट प्राप्त करें।',
                description: [
                    'अनधिकृत अनुपस्थिति, अत्यधिक देर, या अप्रूव्ड ओवरटाइम के उदाहरणों जैसी उपस्थिति में किसी भी अपवाद या असामान्यता को पहचानें और हाइलाइट करें।',
                    'उपस्थिति की प्रवृत्तियों और पैटर्न को दृश्य रूप में प्रस्तुत करने के लिए चार्ट या ग्राफ का उपयोग करें। यह आउटलेयर और प्रवृत्तियों को एक नजर में पहचानने में मदद कर सकता है।',
                ],
                sDKeys: [
                    'उपयोगकर्ता विभिन्न तरीकों से डेटा अंतर्दृष्टि का लाभ उठा सकते हैं जैसे कि पेरोल प्रोसेसिंग, प्रदर्शन मूल्यांकन, और रिइम्बर्समेंट आदि।',
                    'रिपोर्ट को अन्य उपयोगकर्ताओं के साथ साझा किया जा सकता है जो प्राधिकरण की श्रेणियों के आधार पर हैं।',
                    'कंपनी टीम की कार्य स्थिति और प्रदर्शन से सामूहिक रूप से अवगत हो सकती है बिना किसी मैनुअल हस्तक्षेप के।',
                ],
            },
            {
                title: 'मैनुअल रिपोर्ट पर बिताए गए समय को कम करें।',
                description: [
                    'कर्मचारी नाम, पहचान नंबर, और विभागीय संबद्धताओं जैसी मौलिक जानकारी शामिल करें ताकि उपस्थिति डेटा को संदर्भित किया जा सके।',
                    'रिपोर्ट द्वारा कवर की गई तारीख और समय की सीमा को स्पष्ट रूप से निर्दिष्ट करें। इससे उपयोगकर्ताओं को डेटा की समयसीमा समझने में मदद मिलती है।',
                    'उपस्थिति का एक समग्र सारांश प्रदान करें, जिसमें कार्य दिवसों की कुल संख्या, कुल कार्य घंटे, और किसी भी ओवरटाइम घंटे शामिल हों।',
                ],
            },
        ],
    },
    workforceTracking: {
        featureHeader: 'वर्कफोर्स ट्रैकिंग',
        featureDescription: 'कर्मचारियों की गतिविधियों, उत्पादकता, और उपस्थिति की प्रणालीबद्ध निगरानी और प्रबंधन।',
        featureParagraph:
            'अब समय कार्ड, अत्यधिक कागजी कार्य और विभिन्न दस्तावेजों की आवश्यकता नहीं है। वेबवर्क टाइम ट्रैकर के साथ उपस्थिति निगरानी प्रक्रिया को स्वचालित करें और अपने कर्मचारियों के कामकाजी घंटों की शुरुआत और अंत को एक सुव्यवस्थित प्रारूप में देखें।',
        featureCaption:
            'एक संगठन के वर्कफोर्स के विभिन्न पहलुओं की निगरानी और प्रबंधन करें, जिसमें कर्मचारी उपस्थिति, कार्य घंटे, उत्पादकता, और प्रदर्शन शामिल हैं।',
        keyPoints: [
            {
                header: 'समय-रेखा आधारित उपस्थिति और आंदोलन ट्रैकिंग',
                paragraph:
                    'क्लॉक इन और क्लॉक आउट जैसे घटनाओं की निगरानी करें और साथ ही कर्मचारियों की उनके संचालन, प्रदर्शन मेट्रिक्स, या घटनाओं पर अंतर्दृष्टि प्राप्त करें।',
            },
            {
                header: 'डेटा प्रबंधन के लिए कस्टम फॉर्म',
                paragraph:
                    'बैठकों की मिनट्स, कार्यों और डेटा को ट्रैक करने के लिए डेटा स्टोर करें ताकि प्रवृत्तियों और डेटा-आधारित निर्णय-निर्माण को ट्रैक किया जा सके।',
            },
            {
                header: 'ग्राहक और लीड प्रबंधन',
                paragraph:
                    'कर्मचारियों द्वारा किए गए काम की मात्रा के आधार पर उत्पादकता को ट्रैक करें और प्रमुख प्रदर्शन संकेतकों को मापें।',
            },
            {
                header: 'सटीक रिइम्बर्समेंट क्लेम्स के लिए कुल दूरी की गणनाएँ',
                paragraph:
                    'फील्डवर्क के लिए कर्मचारियों की सटीक यात्रा की दूरी को ट्रैक करें, दैनिक, साप्ताहिक, और मासिक मेट्रिक्स के आधार पर, और यात्रा की गई दूरी और स्थानों के अनुसार खर्च की भरपाई करें।',
            },
        ],
        softwareDetails: [
            {
                title: 'कार्य घंटे ट्रैकिंग सिस्टम को लागू करें।',
                description: [
                    'कर्मचारियों द्वारा कार्यों और परियोजनाओं पर बिताए गए समय की निगरानी के लिए समय ट्रैकिंग समाधानों को लागू करें।',
                    'यह संसाधन उपयोग को समझने और समय प्रबंधन में सुधार में मदद कर सकता है।',
                    'परियोजना प्रबंधन प्रणालियों का उपयोग करें ताकि परियोजना की प्रगति को ट्रैक किया जा सके, कार्य सौंपे जा सकें, और समयसीमा की निगरानी की जा सके।',
                    'यह परियोजना वर्कफ़्लो को ऑप्टिमाइज़ करने और समय पर पूरा करने में मदद करता है।',
                ],
            },
            {
                title: 'प्रासंगिक प्रमुख प्रदर्शन संकेतकों की निगरानी करें।',
                description: [
                    'आपके संगठन के लक्ष्यों से संबंधित प्रमुख प्रदर्शन संकेतकों को परिभाषित और ट्रैक करें।',
                    'यह बिक्री लक्ष्यों, ग्राहक संतोषजनक मेट्रिक्स, या अन्य प्रदर्शन संकेतकों को शामिल कर सकता है।',
                ],
                sDKeys: [
                    'बिक्री प्रदर्शन: मासिक/त्रैमासिक बिक्री राजस्व, बंद किए गए सौदों की संख्या, बिक्री रूपांतरण दर, औसत सौदा आकार, बिक्री पाइपलाइन मूल्य।',
                    'ग्राहक सेवा: ग्राहक संतोषजनक स्कोर (CSAT), नेट प्रमोटर स्कोर (NPS), पहली प्रतिक्रिया समय, औसत समाधान समय, ग्राहक प्रतिधारण दर, टिकट वॉल्यूम।',
                    'ऑपरेशंस/उत्पादन: उत्पादन आउटपुट/थ्रूपुट, गुणवत्ता मीट्रिक्स (विफलता दर, त्रुटि दर), समय पर डिलीवरी प्रदर्शन, इन्वेंट्री टर्नओवर अनुपात, उपकरण डाउनटाइम, कर्मचारी उपयोग दर।',
                    'सामान्य: कर्मचारी उत्पादकता (प्रति घंटे आउटपुट), उपस्थिति और समय पर पहुंच, लक्ष्य प्राप्ति दर, कर्मचारी फीडबैक और प्रदर्शन समीक्षा।',
                ],
            },
            {
                title: 'वर्कफोर्स ट्रेंड्स की रिपोर्ट जनरेट करें।',
                description: [
                    'वर्कफोर्स ट्रेंड्स, प्रदर्शन, और सुधार के क्षेत्रों पर अंतर्दृष्टि प्राप्त करने के लिए एनालिटिक्स और रिपोर्टिंग सुविधाओं को एकीकृत करें।',
                    'अनुकूलित रिपोर्ट निर्णय-निर्माण में मदद कर सकती हैं।',
                ],
                sDKeys: [
                    'जनसांख्यिकीय संरचना की तुलना कुल वर्कफोर्स या उद्योग मानक से करें और समय के साथ किसी भी जनसांख्यिकीय प्रवृत्तियों या बदलावों की पहचान करें।',
                    'विभिन्न फील्ड टीमों, क्षेत्रों, या समय अवधियों के बीच प्रदर्शन मेट्रिक्स की तुलना करें।',
                    'फील्ड फोर्स गतिविधियों का जियोग्राफिकल मैपिंग करें ताकि वितरण और कवरेज क्षेत्रों को दृश्य रूप में दिखाया जा सके।',
                    'भौगोलिक क्षेत्र या क्षेत्रीय बिक्री या सेवा प्रदर्शन का विश्लेषण करें। विस्तार या ऑप्टिमाइजेशन के लिए उच्च संभावनाशील क्षेत्रों की पहचान करें।',
                ],
            },
        ],
    },
    fieldSalesAnalytics: {
        featureHeader: 'फील्ड सेल्स एनालिटिक्स',
        featureDescription:
            'फील्ड पर बिक्री टीम के प्रमुख मैट्रिक्स जैसे बिक्री राजस्व, ग्राहक इंटरैक्शन, और भौगोलिक प्रवृत्तियों को ट्रैक और विश्लेषण करें।',
        featureParagraph:
            'अब समय कार्ड, अत्यधिक कागजी कार्य और विभिन्न दस्तावेजों की आवश्यकता नहीं है। वेबवर्क टाइम ट्रैकर के साथ उपस्थिति निगरानी प्रक्रिया को स्वचालित करें और अपने कर्मचारियों के कामकाजी घंटों की शुरुआत और अंत को एक सुव्यवस्थित प्रारूप में देखें।',
        featureCaption:
            'फील्ड बिक्री गतिविधियों से संबंधित डेटा का संग्रहण, विश्लेषण, और व्याख्या करके अंतर्दृष्टि प्राप्त करें और सूचित निर्णय लें।',
        keyPoints: [
            {
                header: 'बिक्री और लीड के लिए प्रमुख प्रदर्शन संकेतक',
                paragraph:
                    'प्रमुख प्रदर्शन संकेतक डेटा-संचालित निर्णय लेने में मदद कर सकते हैं जो अगली बार उत्पादक निर्णय लेने या रणनीति को बदलने में सहायक हो सकते हैं।',
            },
            {
                header: 'कस्टम रिपोर्ट',
                paragraph:
                    'रिपोर्ट्स को दैनिक, साप्ताहिक, या मासिक आधार पर बिक्री, लीड, और ग्राहक अधिग्रहण पर एक्सेस किया जा सकता है। यह व्यक्ति की उत्पादकता और दक्षता में मदद कर सकता है।',
            },
            {
                header: 'सत्यापन बिक्री यात्रा और जियोफेंस के साथ स्ट्रीमलाइन',
                paragraph:
                    'सटीकता के लिए जियोफेंस-आधारित चेक-इन के साथ यात्रा को मान्य करें। स्थान के समन्वय को साझा करते समय एक कैमरा चित्र से जोड़ा जा सकता है।',
            },
            {
                header: 'बीट रूट की प्रभावशीलता मापें',
                paragraph:
                    'बीट रूट का उपयोग कर्मचारियों के ग्राहक यात्राओं के लिए यात्रा या बीट योजनाओं की योजना बनाने के लिए किया जा सकता है। नेविगेशन को ऑप्टिमाइज़ करने के लिए अपने बीट प्लान को मैप्स में सीधे एकीकृत करें।',
            },
        ],
        softwareDetails: [
            {
                title: 'बेहतर बिक्री प्रदर्शन के लिए प्रतिदिन फील्ड अंतर्दृष्टि।',
                description: [
                    'डेटा एनालिटिक्स उन्मुख समकालिक ई-रिपोर्ट जनरेशन, आपके आसान एक्सेस और उपयोग के लिए विश्वसनीय और उपयोगकर्ता-मित्रवत रिपोर्ट फीचर।',
                    'एक बिक्री प्रबंधक के रूप में, आपके पास विभिन्न रिपोर्टों पर स्पष्ट नज़र होगी जो आपके मेल पते पर भेजी जाएगी या आप इसे अपने डैशबोर्ड इनसाइट में भी देख सकते हैं।',
                    'कुछ उल्लेखनीय रिपोर्टों में मासिक रिपोर्ट, शाखा स्थान-वार रिपोर्ट, कर्मचारी-विशिष्ट रिपोर्ट आदि शामिल हैं।',
                ],
            },
            {
                title: 'वर्कफील्ड उपस्थिति पर कुल नजर।',
                description: [
                    'कर्मचारी फील्ड उपस्थिति और फील्ड यात्राओं पर कुल नजर।',
                    'फील्ड वर्कफोर्स उपस्थिति और बैठकों पर पूरी दृष्टि।',
                    'फील्ड उपस्थिति रिपोर्ट में, आपको फील्ड उपस्थिति मार्किंग समय, स्थान, देर से उपस्थिति समय, कार्य घंटे, आदि के बारे में डेटा मिलेगा।',
                    'इसी तरह, हम आपको फील्ड यात्राओं की चेक-इन/चेक-आउट रिपोर्ट भी प्रदान करते हैं। इसमें फील्ड यात्राओं से संबंधित डेटा शामिल है जैसे लॉगिन/आउट समय, चेक-इन/चेक-आउट समय, बैठकों का स्थान, यात्रा की गई दूरी, बैठक परिणाम नोट्स, आदि।',
                ],
            },
            {
                title: 'डेटा एनालिटिक्स उन्मुख रिपोर्ट जनरेट की गई',
                description: [
                    'मासिक ई-रिपोर्ट्स की सदस्यता लें और डाउनलोड करें।',
                    'आप कभी भी मासिक ई-रिपोर्ट्स की सदस्यता ले सकते हैं और डाउनलोड कर सकते हैं।',
                    'पीडीएफ और एक्सेल फ़ाइल स्वरूप में उपलब्ध। एक पूरा ढेर रिपोर्ट्स का आपके लिए एकत्रित किया गया है जिसमें संगठन के बाहर एक कर्मचारी की बाहरी क्रियाओं के बारे में विस्तृत जानकारी है, जो मैनुअल रूप से एक कठिन कार्य हो सकता है।',
                ],
            },
        ],
    },
    locationBasedAttendance: {
        featureHeader: 'स्थान आधारित उपस्थिति',
        featureDescription:
            'भौगोलिक सीमा आधारित उपस्थिति यह सुनिश्चित करती है कि कर्मचारी केवल तब ही clock in या clock out कर सकें जब वे पूर्व निर्धारित भौगोलिक सीमाओं के भीतर हों।',
        featureCaption:
            'स्थान आधारित उपस्थिति भौतिक स्थान के आधार पर कर्मचारी उपस्थिति की निगरानी और रिकॉर्डिंग की सटीकता सुनिश्चित करती है।',
        keyPoints: [
            {
                header: 'जियोफेंसिंग',
                paragraph:
                    'ऑफिस परिसरों के विशिष्ट स्थानों के चारों ओर जियोफेंस्ड वर्चुअल सीमाएं लागू करें। एक कर्मचारी द्वारा निर्दिष्ट क्षेत्र में प्रवेश या बाहर जाने के आधार पर उपस्थिति स्वचालित करने में मदद करती है।',
            },
            {
                header: 'प्रमाणीकरण और सुरक्षा',
                paragraph:
                    'उपस्थिति डेटा की सटीकता और सुरक्षा सुनिश्चित करने के लिए पिन, बायोमेट्रिक, या चेहरे की पहचान जैसे प्रमाणीकरण विधियों का उपयोग करें।',
            },
            {
                header: 'वास्तविक समय अपडेट',
                paragraph:
                    'सुनिश्चित करें कि उपस्थिति रिकॉर्ड तुरंत अपडेट होते हैं, जिससे समय पर प्रबंधन निर्णय लेने की सुविधा मिलती है।',
            },
            {
                header: 'दूरस्थ कार्य पर विचार',
                paragraph: 'दूरस्थ कर्मचारियों के लिए उपस्थिति ट्रैकिंग के वैकल्पिक तरीकों पर विचार करें।',
            },
        ],
        softwareDetails: [
            {
                title: 'कर्मचारी का समय और स्थान',
                description: [
                    'जानें कि आपके कर्मचारी कब और कहां काम करते हैं।',
                    'अपने कर्मचारियों को उनके मोबाइल फोन पर अनुमति देने के लिए निर्देश दें ताकि यह समय और स्थान ट्रैक कर सके।',
                    'एक बार अनुमति मिलने के बाद, ट्रैकर एक टैप के साथ कार्य करना शुरू कर सकता है।',
                ],
            },
            {
                title: 'लाइव रिपोर्टिंग टू',
                description: [
                    'स्थान आधारित कर्मचारी उपस्थिति प्रणाली को लागू करने से सटीकता में सुधार हो सकता है और उपस्थिति प्रबंधन सुव्यवस्थित हो सकता है। हालांकि, यह महत्वपूर्ण है कि लाभों को कर्मचारी की गोपनीयता के विचारों के साथ संतुलित किया जाए और ऐसे सिस्टम के कार्यान्वयन और उपयोग के बारे में पारदर्शिता से संवाद किया जाए।',
                ],
            },
            {
                title: 'जियोफेंस आधारित अलर्ट',
                description: [
                    'असामान्य उपस्थिति पैटर्न या विसंगतियों के लिए स्वचालित अलर्ट सेट करें। यह संभावित समस्याओं की पहचान करने में मदद करता है, जैसे कि अनधिकृत चेक-इन या चेक-आउट।',
                    'उपस्थिति पैटर्न, प्रवृत्तियों और अपवादों की जानकारी प्राप्त करने के लिए रिपोर्टिंग और विश्लेषण सुविधाओं को लागू करें।',
                ],
                sDKeys: [
                    'जब मूल्यवान संपत्तियां जैसे वाहन या उपकरण निर्दिष्ट क्षेत्रों में प्रवेश या छोड़ते हैं तो अलर्ट प्राप्त करें, जो चोरी या अनधिकृत उपयोग को रोकने में मदद करता है।',
                    'खतरनाक क्षेत्रों या दूरदराज के कार्य स्थलों के चारों ओर जियोफेंस सेट करें, जिससे यदि कर्मचारी प्रतिबंधित क्षेत्रों में प्रवेश करते हैं तो अलर्ट सक्रिय हो जाए।',
                    'जब कोई उपकरण जियोफेंस किए गए क्षेत्र में प्रवेश करता है या बाहर निकलता है, तो प्रणाली पूर्व निर्धारित अलर्ट या सूचनाएं ट्रिगर करती है।',
                    'प्रणाली वास्तविक समय में ट्रैक किए गए उपकरणों की स्थिति की निरंतर निगरानी करती है और जांचती है कि वे किसी भी परिभाषित जियोफेंस से मेल खाते हैं या नहीं।',
                ],
            },
        ],
    },
    liveReporting: {
        featureHeader: 'लाइव रिपोर्टिंग',
        featureDescription:
            'विभिन्न कार्य पहलुओं का वास्तविक समय में ट्रैकिंग और विश्लेषण, जैसे उपस्थिति, पूर्ण किए गए कार्य या प्रोजेक्ट प्रगति।',
        featureCaption:
            'कर्मचारियों के कार्यों, बैठकों की मिनटें, दौरे की पुष्टि के बारे में सूचित रहें और डेटा के आधार पर निर्णय लें।',
        keyPoints: [
            {
                header: 'इवेंट आधारित अधिसूचना',
                paragraph:
                    'प्रशासक या प्रबंधक उन अधिसूचनाओं की सदस्यता ले सकते हैं या ऑप्ट कर सकते हैं जिन्हें वे प्राप्त करना चाहते हैं। इससे वे उन अधिसूचनाओं पर ध्यान केंद्रित कर सकते हैं जिन्हें वे वास्तव में प्राप्त करना चाहते हैं।',
            },
            {
                header: 'उपस्थिति और छुट्टी के बारे में सूचित रहें',
                paragraph:
                    'एक कदम आगे रहें और दिन की योजना बनाने से पहले हेडकाउंट जानें ताकि दिन अधिक उत्पादक और कुशल हो सके।',
            },
            {
                header: 'सभी अनुमोदनों का ट्रैक रखें',
                paragraph:
                    'सभी अनुमोदनों का ट्रैक करें, जैसे कि रिइम्बर्समेंट से लेकर छुट्टी तक, और बाद में उन्हें देखें।',
            },
            {
                header: 'फार्म और MOM के साथ अपडेटेड रहें',
                paragraph:
                    'कस्टम फॉर्म बैठक के लिए विशिष्ट डेटा प्राप्त करने में मदद कर सकते हैं जिसे दूरस्थ रूप से एक्सेस किया जा सकता है।',
            },
        ],
        softwareDetails: [
            {
                title: 'फील्ड फोर्स को वास्तविक समय में ट्रैक करें।',
                description: [
                    'अपने फील्ड स्टाफ को वास्तविक समय में ट्रैक करें।',
                    'जैसे ही आपके फील्ड वर्कफोर्स यात्रा करते हैं, तात्कालिक स्थान विवरण प्राप्त करें।',
                    'Treant आपको उनके मोबाइल GPS का उपयोग करके ऑनलाइन और ऑफलाइन दोनों तरीके से अपने फील्ड कर्मचारियों को ट्रैक करने की अनुमति देता है। अपने फील्ड स्टाफ की हर हरकत पर अपडेटेड रहें और एक पारदर्शी कार्य वातावरण बनाए रखें।',
                ],
            },
            {
                title: 'अपने कर्मचारियों की फील्ड जानकारी के साथ अपडेटेड रहें।',
                description: [
                    'अपने कर्मचारियों की फील्ड जानकारी के साथ अपडेटेड रहें।',
                    'फील्ड स्टाफ की ऑन-फील्ड जानकारी स्वचालित रूप से आपके डैशबोर्ड के साथ समन्वयित हो जाएगी।',
                    'कर्मचारियों की जानकारी जैसे बैटरी प्रतिशत, GPS, इंटरनेट ऑन/ऑफ समय की दृश्यता, और वे फील्ड पर कितना समय बिताते हैं, आपको वास्तविक समय में तुरंत सूचित किया जाएगा।',
                ],
            },
            {
                title: 'कर्मचारियों के रिइम्बर्समेंट और भत्ता डेटा को स्वचालित करें।',
                description: [
                    'सटीक डेटा के लिए AI संचालित स्थान इतिहास।',
                    'कर्मचारियों का स्थान डेटा आर्टिफिशियल इंटेलिजेंस द्वारा संचालित होगा ताकि सटीक मार्ग और ट्रैकिंग जानकारी प्रदान की जा सके। लाइव ट्रैकिंग डेटा और अन्य स्थान इतिहास नियमित अंतराल पर आपके मेलबॉक्स में स्वचालित रूप से भेजे जाएंगे ताकि आप फील्ड पर और बाहर क्या हो रहा है, पर गहरी जानकारी प्राप्त कर सकें।',
                ],
            },
        ],
    },
    aIBasedOptimization: {
        featureHeader: 'AI आधारित ऑप्टिमाइजेशन',
        featureDescription:
            'फील्ड प्रतिनिधियों या बिक्री कर्मचारियों के लिए मार्गों की योजना को AI बीट रूट के आधार पर ऑप्टिमाइज और स्वचालित करें।',
        featureCaption:
            'AI आधारित एल्गोरिदम के साथ दिन के लिए मार्गों का ऑप्टिमाइज करें और अपनी पिछली सीज़न की रिपोर्ट्स की तुलना करें।',
        keyPoints: [
            {
                header: 'रियल-टाइम डेटा इंटिग्रेशन',
                paragraph:
                    'रियल-टाइम डेटा स्रोतों, जैसे लाइव ट्रैफिक अपडेट्स, मौसम की स्थितियां, और सड़क बंद होने की जानकारी का उपयोग करके मार्गों को वर्तमान परिस्थितियों के आधार पर गतिशील रूप से समायोजित करें, जो दैनिक योजना को कुशल बनाता है।',
            },
            {
                header: 'मार्ग योजना के लिए यूजर इंटरफेस',
                paragraph:
                    'ऑप्टिमाइज्ड मार्गों को देखें और आवश्यक समायोजन करें। यह एक मोबाइल ऐप या वेब-आधारित प्लेटफॉर्म का हिस्सा हो सकता है।',
            },
            {
                header: 'उत्पादकता मेट्रिक्स',
                paragraph: 'AI सिस्टम विभिन्न बीट्स या मार्गों की विशिष्ट विशेषताओं को ध्यान में रखता है।',
            },
            {
                header: 'मैपिंग और भू-स्थानिक डेटा',
                paragraph:
                    'सड़क नेटवर्क, ट्रैफिक स्थितियों और अन्य स्थान से संबंधित जानकारी को समझने के लिए मैपिंग सेवाओं और भू-स्थानिक डेटा का उपयोग करें।',
            },
        ],
        softwareDetails: [
            {
                title: 'रियल-टाइम अपडेट्स के साथ मार्ग ऑप्टिमाइजेशन',
                description: [
                    'व्यक्तियों या वाहनों, जैसे डिलीवरी ट्रक्स, बिक्री प्रतिनिधियों, या फील्ड सेवा तकनीशियनों के लिए सबसे कुशल मार्गों की योजना बनाएं और ऑप्टिमाइज करें, जो अपनी नियमित गतिविधियों के हिस्से के रूप में विशिष्ट बीट्स या मार्गों का पालन करते हैं।',
                    'यह एप्लिकेशन दक्षता को सुधारने, यात्रा के समय को कम करने और ऑपरेशनल लागत को न्यूनतम करने में मदद कर सकती है।',
                ],
                sDKeys: [
                    'GPS डिवाइसेस, ट्रैफिक रिपोर्ट्स, मौसम अपडेट्स और वाहन सेंसर सहित विभिन्न स्रोतों से डेटा एकत्र करना।',
                    'त्वरित प्रतिक्रिया समय और समय पर आगमन ग्राहक संतोष को बढ़ाता है, जो सेवा-उन्मुख व्यवसायों के लिए महत्वपूर्ण है।',
                ],
            },
            {
                title: 'गोपनीयता मुद्दों और अनुपालन पर सावधानीपूर्वक विचार की आवश्यकता है',
                description: [
                    'सुनिश्चित करें कि AI आधारित सिस्टम स्थानीय नियमों के साथ अनुपालन करता है, विशेष रूप से गोपनीयता, डेटा सुरक्षा, और परिवहन कानूनों से संबंधित।',
                    'नए डेटा और फीडबैक के आधार पर AI एल्गोरिदम को नियमित रूप से अपडेट और सुधारें, बीट रूट्स के निरंतर ऑप्टिमाइजेशन के लिए प्रयास करें।',
                ],
            },
            {
                title: 'मार्ग योजना की दक्षता के लिए अन्य सिस्टम के साथ एकीकरण',
                description: [
                    'ऐतिहासिक डेटा का विश्लेषण करने और भविष्य के पैटर्न की भविष्यवाणी करने के लिए मशीन लर्निंग एल्गोरिदम का उपयोग करें।',
                    'यह ट्रैफिक स्थितियों, डिलीवरी के समय, और अन्य कारकों की भविष्यवाणी करने में मदद कर सकता है जो मार्ग योजना को प्रभावित करते हैं।',
                ],
            },
        ],
    },
    leadManagement: {
        featureHeader: 'लीड प्रबंधन',
        featureDescription:
            'विशिष्ट समयसीमा के भीतर कर्मचारियों की उपस्थिति डेटा, कामकाजी घंटे, अनुपस्थितियां, देर से आगमन और जल्दी प्रस्थान का सारांश बनाएं।',
        featureCaption:
            'विभिन्न शाखाओं के लिए स्वचालित रिपोर्ट्स तैयार करें और उन्हें दैनिक, साप्ताहिक या मासिक आधार पर प्राप्त करें, 40+ से अधिक टेम्पलेट्स के साथ।',
        keyPoints: [
            {
                header: 'उपस्थिति विवरण को स्वचालित करें: लॉग-इन समय, देर से समय, कुल कामकाजी घंटे, कुल सत्र',
                paragraph:
                    'उपस्थिति का एक सामान्य सारांश प्रदान करें, जिसमें कुल कामकाजी दिन, कुल घंटे काम किए गए, और किसी भी ओवरटाइम घंटे को शामिल किया गया है।',
            },
            {
                header: 'मल्टीपल ब्रांच रिपोर्ट्स',
                paragraph: 'कस्टमाइज़ेबल श्रेणियों के साथ कई शाखाओं की उपस्थिति रिपोर्ट को दूरस्थ रूप से एक्सेस करें।',
            },
            {
                header: 'ओवरटाइम और छुट्टी बैलेंस',
                paragraph:
                    'प्रत्येक कर्मचारी के लिए छुट्टी बैलेंस दिखाएं, जिसमें छुट्टियों के दिन, बीमार छुट्टी, और किसी अन्य प्रकार की छुट्टी शामिल है, जो जमा की गई और उपयोग की गई है।',
            },
            {
                header: 'कस्टमाइज़्ड उपस्थिति रिपोर्ट',
                paragraph:
                    'विशिष्ट मानदंडों, जैसे विभाग, टीम, या व्यक्तिगत कर्मचारी के आधार पर रिपोर्ट को कस्टमाइज़ करने की अनुमति दें।',
            },
        ],
        softwareDetails: [
            {
                title: 'अपनी संगठनात्मक संरचना के आधार पर रिपोर्ट उत्पन्न करें।',
                description: [
                    'नियमित रूप से उत्पन्न और विश्लेषित उपस्थिति रिपोर्ट कार्यबल प्रबंधन में सुधार, प्रवृत्तियों की पहचान, और संगठनात्मक नीतियों के अनुपालन को सुनिश्चित करने के लिए मूल्यवान अंतर्दृष्टि प्रदान कर सकती है।',
                    'रिपोर्ट की विशिष्टताएँ संगठन की जरूरतों और संरचना पर निर्भर करेंगी।',
                ],
            },
            {
                title: 'आसानी से कस्टमाइज़ेबल रिपोर्ट प्राप्त करें।',
                description: [
                    'अनधिकृत अनुपस्थितियों, अत्यधिक देर से आने, या अप्रूव्ड ओवरटाइम के मामलों जैसी उपस्थिति में किसी भी अपवाद या असामान्यता की पहचान और हाइलाइट करें।',
                    'उपस्थिति प्रवृत्तियों और पैटर्नों को दृश्यमान रूप में दिखाने के लिए चार्ट या ग्राफ़ का उपयोग करें। यह आउटलेयर और प्रवृत्तियों की पहचान को सरल बना सकता है।',
                ],
            },
            {
                title: 'मैनुअल रिपोर्ट्स पर बिताए गए समय को कम करें।',
                description: [
                    'कर्मचारी के नाम, पहचान संख्या, और विभागीय संबंधों जैसी मौलिक जानकारी शामिल करें ताकि उपस्थिति डेटा को संदर्भ प्रदान किया जा सके।',
                    'रिपोर्ट द्वारा कवर की गई तारीख और समय सीमा को स्पष्ट रूप से निर्दिष्ट करें। यह उपयोगकर्ताओं को डेटा के कालिक दायरे को समझने में मदद करता है।',
                    'उपस्थिति का एक सामान्य सारांश प्रदान करें, जिसमें कुल कामकाजी दिन, कुल घंटे काम किए गए, और किसी भी ओवरटाइम घंटे को शामिल किया गया है।',
                ],
            },
        ],
    },
    taskManagement: {
        featureHeader: 'कार्य प्रबंधन',
        featureDescription: 'कर्मचारियों की गतिविधियों, उत्पादकता, और उपस्थिति का व्यवस्थित निगरानी और प्रबंधन।',
        featureParagraph:
            'अब टाइमकार्ड, अत्यधिक कागजी काम, और कर्मचारियों की उपस्थिति जांचने के लिए विभिन्न दस्तावेज़ों की आवश्यकता नहीं है। WebWork Time Tracker के साथ उपस्थिति निगरानी प्रक्रिया को स्वचालित करें और आपके कर्मचारियों के कामकाजी घंटों की शुरुआत और अंत को सुव्यवस्थित प्रारूप में देखें।',
        featureCaption:
            'संस्थान के कार्यबल के विभिन्न पहलुओं की निगरानी और प्रबंधन करें, जिसमें कर्मचारी उपस्थिति, कार्य घंटे, उत्पादकता, और प्रदर्शन शामिल हैं।',
        keyPoints: [
            {
                header: 'वास्तविक समय में निगरानी करें',
                paragraph:
                    'कार्य बोर्ड देखने, कार्य स्थिति की जांच करने, और समय बिताने को ट्रैक करके वास्तविक समय में प्रगति की निगरानी करें।',
            },
            {
                header: 'संगठन के सभी कार्य प्रवाह को एक ही स्थान पर निगरानी करें',
                paragraph:
                    'कार्य प्रबंधन सहयोग विशेषताएँ टीम के सदस्यों के साथ वास्तविक समय में संवाद करने के लिए। कार्यों पर टिप्पणी करें, प्रश्न पूछें, और सभी को सूचित रखने के लिए अपडेट प्रदान करें।',
            },
            {
                header: 'प्रदर्शन की निगरानी करें',
                paragraph:
                    'उत्पादकता को ट्रैक करने के लिए ग्राहक और लीड प्रबंधन। कर्मचारियों की उत्पादकता और प्रमुख प्रदर्शन संकेतकों को कर्मचारियों द्वारा पूरा किए गए काम की मात्रा के आधार पर मापा जा सकता है।',
            },
            {
                header: 'लक्ष्य-निर्धारण को मानकीकृत करें',
                paragraph:
                    'लक्ष्यों को व्यवस्थित करने के लिए पदानुक्रमित संरचना: परियोजना लक्ष्यों, विभागीय उद्देश्यों, व्यक्तिगत प्रदर्शन लक्ष्यों, रणनीतिक पहलों, आदि।',
            },
        ],
        softwareDetails: [
            {
                title: 'कामकाजी घंटों की निगरानी के लिए समय ट्रैकिंग सिस्टम लागू करें।',
                description: [
                    'कर्मचारियों द्वारा कार्यों और परियोजनाओं पर बिताए गए समय की निगरानी के लिए समय ट्रैकिंग समाधानों को लागू करें।',
                    'यह संसाधन उपयोग को समझने और समय प्रबंधन में सुधार करने में मदद कर सकता है।',
                    'परियोजना प्रबंधन सिस्टम का उपयोग परियोजना की प्रगति को ट्रैक करने, कार्य सौंपने, और समयसीमा की निगरानी करने के लिए करें।',
                    'यह परियोजना कार्यप्रवाह को ऑप्टिमाइज़ करने और समय पर पूर्णता सुनिश्चित करने में मदद करता है।',
                ],
            },
            {
                title: 'संबंधित प्रमुख प्रदर्शन संकेतकों की निगरानी करें।',
                description: [
                    'आपकी संगठनात्मक लक्ष्यों के लिए प्रासंगिक प्रमुख प्रदर्शन संकेतकों को परिभाषित और ट्रैक करें।',
                    'यह बिक्री लक्ष्यों, ग्राहक संतोषजनक मैट्रिक्स, या अन्य प्रदर्शन संकेतकों को शामिल कर सकता है।',
                ],
            },
            {
                title: 'अपने कार्यबल प्रवृत्तियों की रिपोर्ट उत्पन्न करें।',
                description: [
                    'कार्यबल प्रवृत्तियों, प्रदर्शन, और सुधार के क्षेत्रों पर अंतर्दृष्टि प्राप्त करने के लिए एनालिटिक्स और रिपोर्टिंग विशेषताओं को एकीकृत करें।',
                    'कस्टमाइज़ की गई रिपोर्टें निर्णय-निर्माण में सहायता कर सकती हैं।',
                ],
            },
        ],
    },
    salesPipelineMonitoring: {
        featureHeader: 'सेल्स पाइपलाइन निगरानी',
        featureDescription:
            'फील्ड में बिक्री टीम की प्रमुख मेट्रिक्स को ट्रैक और विश्लेषण करें जैसे बिक्री राजस्व, ग्राहक इंटरैक्शन, और भौगोलिक रुझान।',
        featureParagraph:
            'अब टाइमकार्ड, अत्यधिक कागजी काम, और कर्मचारियों की उपस्थिति जांचने के लिए विभिन्न दस्तावेज़ों की आवश्यकता नहीं है। WebWork Time Tracker के साथ उपस्थिति निगरानी प्रक्रिया को स्वचालित करें और आपके कर्मचारियों के कामकाजी घंटों की शुरुआत और अंत को सुव्यवस्थित प्रारूप में देखें।',
        featureCaption:
            'फील्ड बिक्री गतिविधियों से संबंधित डेटा का संग्रह, विश्लेषण, और व्याख्या करें ताकि आप अंतर्दृष्टि प्राप्त कर सकें और सूचित निर्णय ले सकें।',
        keyPoints: [
            {
                header: 'स्वचालित वर्कफ़्लो',
                paragraph:
                    'सिस्टम का उपयोग करके बिक्री प्रक्रिया के विभिन्न चरणों को स्ट्रीमलाइन और ऑप्टिमाइज़ करें जैसे लीड कैप्चर, योग्यता, पोषण, पाइपलाइन प्रबंधन, गतिविधि ट्रैकिंग, और रिपोर्टिंग।',
            },
            {
                header: 'CRM के साथ एकीकरण',
                paragraph:
                    'लीड प्रबंधन, संपर्क ट्रैकिंग, डील मॉनिटरिंग, और दोनों प्लेटफ़ॉर्मों के बीच डेटा सिंक्रनाइज़ेशन को स्ट्रीमलाइन करें।',
            },
            {
                header: 'सहयोग और संचार को बढ़ावा दें',
                paragraph:
                    'केंद्रीकृत संचार चैनल, बिक्री बैठकों की योजना बनाना, सहयोगी डील समीक्षा, प्रक्रियाओं का दस्तावेज़ीकरण, क्रॉस-फंक्शनल सहयोग, और खुला संचार बढ़ावा देना।',
            },
            {
                header: 'भविष्य की बिक्री प्रदर्शन पर अंतर्दृष्टि',
                paragraph:
                    'डेटा विश्लेषण और भविष्यवाणी मॉडलिंग का उपयोग करके संभावित परिणामों और रुझानों की भविष्यवाणी करें।',
            },
        ],
        softwareDetails: [
            {
                title: 'बेहतर बिक्री प्रदर्शन के लिए रोजाना फील्ड अंतर्दृष्टि',
                description: [
                    'डेटा एनालिटिक-ओरिएंटेड समानांतर ई-रिपोर्ट जनरेशन। आपकी आसान पहुंच और उपयोग के लिए एक विश्वसनीय और उपयोगकर्ता-अनुकूल रिपोर्ट फीचर।',
                    'एक बिक्री प्रबंधक के रूप में, आपके पास विभिन्न रिपोर्टों की स्पष्ट झलक होगी जो आपके मेल पते पर भेजी जाएगी या आप इसे अपने डैशबोर्ड इनसाइट में भी देख सकते हैं।',
                    'कुछ उल्लेखनीय रिपोर्टों में मासिक रिपोर्ट, शाखा स्थान-वार रिपोर्ट, कर्मचारी-विशिष्ट रिपोर्ट शामिल हैं।',
                ],
            },
            {
                title: 'वर्कफील्ड उपस्थिति पर समग्र दृष्टि',
                description: [
                    'कर्मचारी फील्ड उपस्थिति और फील्ड विज़िट पर समग्र दृष्टि।',
                    'फील्ड कार्यबल की उपस्थिति और बैठकों पर व्यापक दृश्य प्राप्त करें।',
                    'फील्ड उपस्थिति रिपोर्ट में आपको फील्ड उपस्थिति मार्किंग समय, स्थान, देर से उपस्थिति समय, कामकाजी घंटे आदि की जानकारी मिलेगी।',
                    'इसी तरह, फील्ड विज़िट चेक-इन/आउट रिपोर्ट्स में फील्ड विज़िट से संबंधित डेटा शामिल होता है जैसे लॉगिन/आउट समय, चेक-इन/आउट समय, बैठकों का स्थान, यात्रा की गई दूरी, बैठक परिणाम नोट्स आदि।',
                ],
            },
            {
                title: 'डेटा एनालिटिक-ओरिएंटेड रिपोर्ट जनरेशन',
                description: [
                    'मासिक ई-रिपोर्ट्स की सदस्यता लें और डाउनलोड करें।',
                    'आप कभी भी मासिक ई-रिपोर्ट्स की सदस्यता ले सकते हैं और डाउनलोड कर सकते हैं।',
                    'पीडीएफ और एक्सेल फ़ाइल प्रारूप में उपलब्ध। रिपोर्टों का एक पूरा ढेर आपके लिए एक कर्मचारी की बाहरी गतिविधियों के बारे में विस्तृत जानकारी प्रदान करने के लिए, जो मैन्युअल रूप से एक कठिन कार्य होता है।',
                ],
            },
        ],
    },
    workflowAutomation: {
        featureHeader: 'वर्कफ़्लो ऑटोमेशन',
        featureDescription:
            'जियो फेंस आधारित उपस्थिति यह सुनिश्चित करने के लिए कि कर्मचारी केवल पूर्वनिर्धारित भौगोलिक सीमाओं के भीतर होने पर ही घड़ी में या बाहर जा सकते हैं।',
        featureCaption:
            'स्थान आधारित उपस्थिति सटीकता सुनिश्चित करती है ताकि कर्मचारी की उपस्थिति उनके भौगोलिक स्थान के आधार पर निगरानी और रिकॉर्ड की जा सके।',
        keyPoints: [
            {
                header: 'स्वचालित कार्य असाइनमेंट',
                paragraph:
                    'वर्कफ़्लो ऑटोमेशन स्वचालित रूप से कार्यों को सबसे उपयुक्त व्यक्तियों को सौंपने के लिए, कौशल सेट, उपलब्धता, वर्कलोड, और प्राथमिकता स्तर जैसे कारकों पर विचार करता है।',
            },
            {
                header: 'लीड राउटिंग, योग्यता और स्कोरिंग',
                paragraph:
                    'आने वाली लीड्स को उपयुक्त बिक्री प्रतिनिधियों को सौंपने, उनकी गुणवत्ता का आकलन करने, और फॉलो-अप कार्यों को प्राथमिकता देने के लिए लीड राउटिंग, योग्यता और स्कोरिंग।',
            },
            {
                header: 'बाहरी सिस्टम्स के साथ एकीकरण',
                paragraph:
                    'सहज सहयोग को सुविधाजनक बनाना, डेटा की सटीकता को बढ़ाना, और विभिन्न व्यापार कार्यों के बीच एकजुट वर्कफ़्लो सुनिश्चित करना।',
            },
            {
                header: 'प्रदर्शन ट्रैकिंग और रिपोर्टिंग',
                paragraph:
                    'प्रक्रिया पूर्णता समय, त्रुटि दरों, संसाधन उपयोग, और समग्र उत्पादकता जैसे मेट्रिक्स को ट्रैक करें।',
            },
        ],
        softwareDetails: [
            {
                title: 'वर्कफोर्स का समय और स्थान।',
                description: [
                    'जानें जब और कहाँ आपके कर्मचारी काम करते हैं',
                    'अपने कर्मचारियों को अपने मोबाइल फोन पर अनुमति देने के लिए निर्देशित करें ताकि यह समय और स्थान को ट्रैक कर सके।',
                    'एक बार अनुमति मिलने के बाद, ट्रैकर टैप के साथ काम करना शुरू कर सकता है।',
                ],
            },
            {
                title: 'लाइव रिपोर्टिंग टू',
                description: [
                    'स्थान आधारित कर्मचारी उपस्थिति प्रणाली को लागू करने से सटीकता बढ़ाई जा सकती है और उपस्थिति प्रबंधन को सुव्यवस्थित किया जा सकता है। हालांकि, इसके लाभों को कर्मचारियों की गोपनीयता विचारों के साथ संतुलित करना महत्वपूर्ण है और ऐसे सिस्टम के कार्यान्वयन और उपयोग के बारे में पारदर्शिता से संचार करना आवश्यक है।',
                ],
            },
            {
                title: 'जियो फेंस आधारित अलर्ट',
                description: [
                    'असामान्य उपस्थिति पैटर्न या विसंगतियों के लिए स्वचालित अलर्ट सेट करें। इससे संभावित समस्याओं की पहचान करने में मदद मिलती है, जैसे कि अनधिकृत चेक-इन या चेक-आउट।',
                    'उपस्थिति पैटर्न, रुझान, और अपवादों पर अंतर्दृष्टि प्राप्त करने के लिए रिपोर्टिंग और विश्लेषणात्मक विशेषताएँ लागू करें।',
                ],
            },
        ],
    },
    aIBasedAssistance: {
        featureHeader: 'AI आधारित सहायता',
        featureDescription:
            'कर्मचारियों के काम के विभिन्न पहलुओं, जैसे कि उपस्थिति, पूर्ण कार्य या परियोजना प्रगति, की वास्तविक समय में निगरानी और विश्लेषण।',
        featureCaption:
            'कर्मचारियों के कार्य, मीटिंग्स की मिनट्स, विजिट्स की वैधता के बारे में सूचित रहें और डेटा के आधार पर निर्णय लें।',
        keyPoints: [
            {
                header: 'इवेंट आधारित सूचना',
                paragraph:
                    'एडमिन या मैनेजर उन सूचनाओं की सदस्यता ले सकते हैं या उन्हें प्राप्त करने के लिए ऑप्ट कर सकते हैं जिनकी उन्हें आवश्यकता है। यह केवल उन सूचनाओं पर ध्यान केंद्रित करने में मदद करेगा जिन्हें वे वास्तव में प्राप्त करना चाहते हैं।',
            },
            {
                header: 'उपस्थिति और छुट्टी के बारे में सूचित रहें',
                paragraph:
                    'उपस्थिति और छुट्टी के बारे में सूचित रहें। एक कदम आगे रहें और दिन की योजना को अधिक उत्पादक और प्रभावी बनाने के लिए हेडकाउंट के बारे में पहले से जानें।',
            },
            {
                header: 'सभी अनुमोदनों का ट्रैक रखें',
                paragraph:
                    'सभी अनुमोदनों का ट्रैक रखें। रियंबर्समेंट से लेकर छुट्टी तक सभी अनुमोदनों को ट्रैक करें और बाद में उन्हें देखें।',
            },
            {
                header: 'फॉर्म और MOM के साथ अपडेट रहें',
                paragraph:
                    'फॉर्म और मीटिंग्स की मिनट्स के माध्यम से रिमोट ट्रैकिंग। कस्टम फॉर्म एक मीटिंग के लिए विशिष्ट डेटा प्राप्त करने में मदद कर सकते हैं जिन्हें दूरस्थ रूप से एक्सेस किया जा सकता है।',
            },
        ],
        softwareDetails: [
            {
                title: 'फील्ड स्टाफ को वास्तविक समय में लोकेट करें।',
                description: [
                    'अपने फील्ड स्टाफ को वास्तविक समय में लोकेट करें',
                    'जैसे ही आपका फील्ड वर्कफोर्स यात्रा करता है, आपको तत्काल स्थान विवरण प्राप्त होगा।',
                    'ट्रींट आपको आपके फील्ड कर्मचारियों को उनके मोबाइल GPS का उपयोग करके ऑनलाइन और ऑफलाइन दोनों तरीके से लोकेट और ट्रैक करने में सक्षम बनाता है। अपने फील्ड स्टाफ की हर चाल पर अपडेटेड रहें और एक पारदर्शी कार्य वातावरण बनाए रखें।',
                ],
            },
            {
                title: 'अपने कर्मचारियों की फील्ड जानकारी के साथ अपडेट रहें',
                description: [
                    'अपने कर्मचारियों की फील्ड जानकारी के साथ अपडेट रहें',
                    'फील्ड स्टाफ की ऑन-फील्ड जानकारी स्वचालित रूप से आपके डैशबोर्ड के साथ सिंक हो जाएगी।',
                    'आपके कर्मचारियों की जानकारी जैसे बैटरी प्रतिशत, GPS, इंटरनेट ऑन/ऑफ समय की दृश्यता, और फील्ड पर बिताए गए आदर्श समय को तुरंत वास्तविक समय में सूचित किया जाएगा।',
                ],
            },
            {
                title: 'कर्मचारियों के रियंबर्समेंट और भत्ता डेटा को ऑटोमेट करें।',
                description: [
                    'सटीक डेटा के लिए AI द्वारा संचालित स्थान इतिहास',
                    'आपके कर्मचारियों का स्थान डेटा आर्टिफिशियल इंटेलिजेंस द्वारा संचालित होगा ताकि सटीक रूट और ट्रैकिंग जानकारी प्रदान की जा सके। लाइव ट्रैकिंग डेटा और अन्य स्थान इतिहास को नियमित अंतराल पर आपके मेलबॉक्स में स्वचालित रूप से भेजा जाएगा ताकि ऑन और ऑफ फील्ड हो रही गतिविधियों का गहराई से विश्लेषण किया जा सके।',
                ],
            },
        ],
    },
    digitizationOfOrder: {
        featureHeader: 'आदेश की डिजिटाइजेशन',
        featureDescription:
            'कर्मचारियों के काम के विभिन्न पहलुओं, जैसे कि उपस्थिति, पूर्ण कार्य या परियोजना प्रगति, की वास्तविक समय में निगरानी और विश्लेषण।',
        featureCaption: 'आदेश प्रबंधन को संपूर्ण रूप से सुव्यवस्थित करें और मैनुअल त्रुटियों को कम करें।',
        keyPoints: [
            {
                header: 'रिपोर्टिंग और विश्लेषण',
                paragraph:
                    'आदेश प्रसंस्करण प्रदर्शन और कर्मचारी उत्पादकता की निगरानी के लिए विस्तृत रिपोर्ट और विश्लेषण तैयार करें।',
            },
            {
                header: 'स्वचालित आदेश प्रविष्टि',
                paragraph:
                    'कर्मचारियों या ग्राहकों द्वारा दर्ज किए गए आदेश विवरण को स्वचालित रूप से कैप्चर और मान्य करें।',
            },
            {
                header: 'वास्तविक समय का आदेश ट्रैकिंग और स्थिति अपडेट',
                paragraph:
                    'कर्मचारियों और ग्राहकों को आदेश की स्थिति और शिपमेंट ट्रैकिंग पर वास्तविक समय के अपडेट प्रदान करें।',
            },
            {
                header: 'स्वचालित वर्कफ़्लो और कार्य असाइनमेंट',
                paragraph: 'आदेश अनुमोदन प्रक्रियाओं को स्वचालित करें और उचित कर्मचारियों को कार्य सौंपें।',
            },
        ],
    },
    targetManagement: {
        featureHeader: 'लक्ष्य प्रबंधन',
        featureDescription:
            'कर्मचारियों के काम के विभिन्न पहलुओं, जैसे कि उपस्थिति, पूर्ण कार्य या परियोजना प्रगति, की वास्तविक समय में निगरानी और विश्लेषण।',
        featureCaption:
            'कर्मचारियों के कार्यों, मीटिंग्स की मिनट्स, विज़िट्स की पुष्टि, और डेटा के आधार पर निर्णय लेने के बारे में सूचित रहें।',
        keyPoints: [
            {
                header: 'फील्ड ट्रैकिंग के साथ इंटीग्रेट करें',
                paragraph:
                    'दैनिक, साप्ताहिक, और मासिक लक्ष्यों को प्राप्त करें और लाइव फील्ड ट्रैकिंग के साथ कर्मचारी की उपलब्धियों के रिकॉर्ड रखें।',
            },
            {
                header: 'वास्तविक समय में अपडेट और सिंक',
                paragraph:
                    'लक्ष्यों की प्रगति (प्राप्त लक्ष्य बनाम शेष), चेक-इन/चेक-आउट समय, प्रत्येक कार्य पर बिताए गए घंटे (अवधि), और स्थान ट्रैकिंग।',
            },
            {
                header: 'प्रदर्शन और विश्लेषण',
                paragraph:
                    'प्रदर्शन मैट्रिक्स (पूर्णता दर, लिया गया समय, ग्राहक संतोषजनक अंक), लक्ष्यों की तुलना (वास्तविक प्रदर्शन बनाम लक्ष्य), ऐतिहासिक डेटा (पिछले प्रदर्शन के रुझान)।',
            },
            {
                header: 'स्वचालित सूचनाएं और अलर्ट',
                paragraph:
                    'स्वचालित सूचनाएं भेजें ताकि कर्मचारियों को लक्ष्य प्राप्ति अलर्ट, प्रदर्शन अनुस्मारक, और डेडलाइन चेतावनियों के बारे में सूचित किया जा सके।',
            },
        ],
    },
    invoiceManagement: {
        featureHeader: 'इनवॉइस प्रबंधन',
        featureDescription:
            'काम के विभिन्न पहलुओं, जैसे कि उपस्थिति, पूर्ण कार्य या परियोजना प्रगति, की वास्तविक समय में निगरानी और विश्लेषण।',
        featureCaption: 'स्वचालित अलर्ट और सूचनाओं के साथ इनवॉइस की स्थिति और प्रोसेसिंग का ट्रैक बनाएं।',
        keyPoints: [
            {
                header: 'स्वचालित सूचनाएं और अनुस्मारक',
                paragraph:
                    'इनवॉइस की स्थिति को ट्रैक करने और देय और विलंबित भुगतानों के लिए स्वचालित अनुस्मारक और अलर्ट भेजने के लिए सिस्टम सेट करें।',
            },
            {
                header: 'इनवॉइस टेम्पलेट्स',
                paragraph:
                    'मानकीकृत इनवॉइस टेम्पलेट्स जो ग्राहक की जानकारी, आदेश विवरण, कर, छूट, और भुगतान शर्तें शामिल करती हैं।',
            },
            {
                header: 'डिजिटल साइन और डिलीवरी का प्रमाण',
                paragraph: 'इनवॉइस प्रक्रिया के भाग के रूप में डिजिटल साइन और OTP आधारित डिलीवरी प्रमाण एकत्र करें।',
            },
            {
                header: 'इनवॉइस का सिंक और वास्तविक समय में अपडेट',
                paragraph:
                    'केंद्रीय सिस्टम (क्लाउड-आधारित स्टोरेज) के साथ स्वचालित सिंक और इनवॉइस की स्थिति पर वास्तविक समय में अपडेट।',
            },
        ],
    },
    distributorOrder: {
        featureHeader: 'वितरक आदेश',
        featureDescription:
            'काम के विभिन्न पहलुओं, जैसे कि उपस्थिति, पूर्ण कार्य या परियोजना प्रगति, की वास्तविक समय में निगरानी और विश्लेषण।',
        featureCaption: 'कर्मचारियों के कार्य, बैठक की मिनटें, यात्राओं की पुष्टि करें और डेटा के आधार पर निर्णय लें।',
        keyPoints: [
            {
                header: 'वितरण जानकारी',
                paragraph:
                    'प्रत्येक वितरण के लिए सभी प्रासंगिक विवरणों के साथ आदेश बनाएं और प्रोसेस करें और उपलब्धता के आधार पर कार्य सौंपें।',
            },
            {
                header: 'वास्तविक समय का इन्वेंट्री एकीकरण',
                paragraph:
                    'सटीक स्टॉक आवंटन सुनिश्चित करने के लिए इन्वेंट्री प्रबंधन के साथ एकीकृत करें और वास्तविक समय में इन्वेंट्री स्तरों को अपडेट करें।',
            },
            {
                header: 'स्वचालित सूचनाएं और अलर्ट',
                paragraph: 'आदेश प्रक्रिया के प्रमुख चरणों पर स्वचालित सूचनाएं भेजने के लिए सिस्टम को कॉन्फ़िगर करें।',
            },
            {
                header: 'कर्मचारी डैशबोर्ड',
                paragraph:
                    'डैशबोर्ड तक पहुँच प्राप्त करें जहाँ कर्मचारी आदेश देख सकते हैं, प्रगति को ट्रैक कर सकते हैं, और आवश्यक दस्तावेज़ डाउनलोड कर सकते हैं।',
            },
        ],
    },
    inventoryManagement: {
        featureHeader: 'इन्वेंट्री प्रबंधन',
        featureDescription:
            'काम के विभिन्न पहलुओं, जैसे कि उपस्थिति, पूर्ण कार्य या परियोजना प्रगति, की वास्तविक समय में निगरानी और विश्लेषण।',
        featureCaption: 'सही स्टॉक बैलेंस के साथ वास्तविक समय की अपडेट और इन्वेंट्री की निगरानी के लिए सटीक डेटा।',
        keyPoints: [
            {
                header: 'आदेश प्रबंधन के साथ एकीकरण',
                paragraph:
                    'स्टॉक स्तरों को आने वाले और जाने वाले आदेशों के साथ समन्वयित करने के लिए इन्वेंट्री और आदेश प्रबंधन प्रणालियों के बीच निर्बाध एकीकरण सुनिश्चित करें।',
            },
            {
                header: 'मल्टी-लोकेशन प्रबंधन',
                paragraph:
                    'कई गोदामों, स्टोर्स या स्थानों के बीच इन्वेंट्री को ट्रैक करें और केंद्रीकृत नियंत्रण तक पहुंच प्राप्त करें, स्टॉक वितरण को अनुकूलित करें और दृश्यता में सुधार करें।',
            },
        ],
    },
    orderCollectionManagement: {
        featureHeader: 'ऑर्डर/संग्रह प्रबंधन',
        featureDescription: 'स्केलेबल और स्वचालित समाधान के साथ ऑर्डर/संग्रह संचालन को सरल बनाएं।',
        featureCaption: 'स्केलेबल और स्वचालित समाधान के साथ ऑर्डर/संग्रह संचालन को सरल बनाएं।',
        keyPoints: [
            {
                header: 'एकीकृत ऑर्डर प्रबंधन प्रणाली',
                paragraph:
                    'एक केंद्रीकृत प्रणाली जो ऑन-साइट और ऑफ-साइट दोनों कर्मचारियों द्वारा ऑर्डर प्रबंधन, प्रगति ट्रैकिंग, और वास्तविक समय में स्थिति अपडेट करने के लिए सुलभ है।',
            },
            {
                header: 'भूमिका आधारित एक्सेस नियंत्रण',
                paragraph:
                    'भूमिकाओं (जैसे, प्रबंधक, ऑन-साइट कर्मचारी, ऑफ-साइट कर्मचारी) के आधार पर विभिन्न स्तरों की पहुँच और अनुमतियाँ डेटा सुरक्षा और उचित पहुँच सुनिश्चित करने के लिए।',
            },
            {
                header: 'वास्तविक समय की समन्वय',
                paragraph:
                    'सुनिश्चित करें कि सभी ऑर्डर डेटा सभी उपकरणों और स्थानों में वास्तविक समय में समन्वयित हो, ताकि सभी के पास नवीनतम जानकारी तक पहुँच हो।',
            },
            {
                header: 'संचार और समन्वय',
                paragraph:
                    'ऑन-साइट और ऑफ-साइट कर्मचारियों के बीच इन-ऐप संदेश और सूचनाओं के माध्यम से संचार को सुगम बनाएं।',
            },
        ],
    },
};

export default features;
