const pricing = {
    attendance: {
        name: 'Посещаемость',
        price: '40',
        subLine: 'Лучшее для мониторинга рабочего времени',
        features: [
            'Биометрический вход',
            'Гео-основанный вход',
            'Опция полдня',
            'Автоматический выход',
            'Счетчик опозданий',
            'Среднее время входа',
            'Общее рабочее время',
            'Автоматическая подписка на 1 отчет',
            'Посещаемость, Среднее, Вход/Выход',
            'Отчеты',
            'Включает все основные функции',
            'Биометрия',
            'Лицо AI',
            'Киоск',
            'Опция для нескольких филиалов',
            'Форма для входа и выхода',
            'Включает все основные и премиум функции',
        ],
    },
    task: {
        name: 'Задача',
        price: '100',
        subLine: 'Лучшее для управления задачами',
        features: [
            'Полевые задачи',
            'Сервисные задачи',
            'Управление задачами на основе геозон',
            'Вход/Выход',
            'Время ухода',
            'Предварительное уведомление',
        ],
    },
    tracking: {
        name: 'Отслеживание',
        price: '300',
        subLine: 'Лучшее для отслеживания в реальном времени',
        features: [
            'Полевые задачи',
            'Сервисные задачи',
            'Управление задачами на основе геозон',
            'Вход/Выход',
            'Время ухода',
            'Живое отслеживание',
        ],
    },
    customer: {
        name: 'Клиент',
        price: '80',
        subLine: 'Управление взаимоотношениями с клиентами',
        features: [
            'Коммуникация через Whatsapp, Email и сообщения',
            'Назначение тегов',
            'Напоминания по интервалу',
            'Назначение посещения на основе геозон',
            'История посещений',
            'Поделиться визитной карточкой',
            'Анонс',
            'Включает все основные функции',
            'Маршрут на основе AI',
            'Сканер визиток',
            'Шаблоны для коммуникации',
            'Управление напоминаниями',
            'Коммуникация через Whatsapp, Email и сообщения',
            'Назначение тегов',
            'Напоминания по интервалу',
            'Назначение посещения на основе геозон',
            'История посещений',
            'Поделиться визитной карточкой',
            'Анонс',
        ],
    },
    reimbursement: {
        name: 'Возмещение',
        price: '30',
        subLine: 'Автоматизация точных и своевременных выплат',
        features: [
            'Возмещение на основе расстояния',
            'Аналитика (подпункт)',
            'На основе филиала',
            'На основе месяца',
            'Категория',
        ],
    },
    leave: {
        name: 'Отпуск',
        price: '30',
        subLine: 'Менеджеры могут одобрить без усилий',
        features: [
            'Перенос оставшихся дней',
            'Управление праздниками',
            'Просмотр накопленных дней',
            'Полдня и работа из дома',
            'Одобрение отпуска',
            'Настройка политики для каждого сотрудника',
        ],
    },
    payroll: {
        name: 'Зарплата',
        price: '40',
        subLine: 'Точные расчеты зарплаты',
        features: [
            'Политика зарплаты',
            'Расчет аванса',
            'Общий обзор платежей',
            'Расчет в 1 клик',
            'Расчет почасовой и месячной зарплаты',
            'Расчет сверхурочных',
        ],
    },
    faceID: {
        name: 'Face ID',
        price: '25',
        subLine: 'Лучшее для повышения безопасности',
        features: ['Добавление Face ID в посещаемость как дополнение'],
    },
    forms: {
        name: 'Формы',
        price: '30',
        subLine: 'Создание и управление цифровыми формами',
        features: [
            'Динамические формы',
            'Создание пользовательских форм',
            'Аналитика по формам',
            'Формы для мероприятий',
            'Оплата через формы',
            'Создание персонализированных форм',
        ],
    },
};
export default pricing;
