const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'Rencana Fleksibel yang Berkembang Bersama Anda',
    subFlexiblePlansThatGrowsWithYou:
        'Pilih rencana harga yang memenuhi kebutuhan bisnis Anda. Traceforce menawarkan paket harga dengan harga optimal namun nilai superior.',
    pricingPlans: 'Rencana Harga',
    pricingThatWorksForEveryone: 'Harga yang Bekerja untuk Semua Orang',
    monthly: 'Bulanan',
    annualy: 'Tahunan',
    international: 'Internasional',
    domestic: 'Domestik',
    month: 'Bulan',
    benifits: 'Manfaat',
    allPlanIncludeTheseBenifits: 'Semua rencana mencakup manfaat ini',
    someBenfitsOfMonthlyYearlySubscription: 'Beberapa Manfaat dari langganan bulanan/tahunan',
    benifitsArr: [
        {
            title: 'Dukungan Teknis',
            description: 'Tim dukungan teknis profesional kami akan membantu Anda di setiap langkah.',
        },
        {
            title: 'Teknologi',
            description: 'Pelatihan khusus untuk memulai dengan platform dari para profesional.',
        },
        {
            title: 'Analisis Pertumbuhan',
            description: 'Tim khusus untuk memberikan wawasan tentang pertumbuhan bulanan Anda.',
        },
        {
            title: 'Penghargaan',
            description: 'Penghargaan khusus untuk akun dengan performa terbaik setiap bulan.',
        },
    ],
    fAQs: 'Pertanyaan yang Sering Diajukan',
    frequentlyAskedQuestions: 'Pertanyaan yang Sering Diajukan',
    someBaTypeOfQuestions: 'Berikut beberapa jenis pertanyaan umum dari pelanggan kami',
    fAQArr: [
        {
            question: 'Bagaimana cara saya mendaftar untuk perusahaan saya?',
            ans: 'Ya, lisensi pasar memungkinkan Anda menggunakan tema ini dalam produk akhir apa pun. Untuk informasi lebih lanjut tentang lisensi, silakan periksa syarat lisensi di pasar.',
        },
        {
            question: 'Di mana saya dapat menambahkan kendaraan tenaga kerja saya?',
            ans: 'Tidak, ini adalah template HTML. Template ini tidak akan bekerja langsung dengan WordPress, meskipun Anda dapat mengonversinya menjadi tema yang kompatibel dengan WordPress.',
        },
        {
            question: 'Bagaimana tenaga kerja saya masuk ke Traceforce?',
            ans: 'Kirimkan masalah atau umpan balik Anda ke email dukungan khusus kami (support@coderthemes.com). Kami tersedia kapan saja untuk membantu Anda.',
        },
        {
            question: 'Bisakah saya melacak tenaga kerja saya melalui ponsel?',
            ans: 'Ya, kami akan secara teratur memperbarui petunjuk. Semua pembaruan mendatang akan tersedia secara gratis.',
        },
        {
            question: 'Bagaimana cara menandai kehadiran?',
            ans: 'Ya, lisensi pasar memungkinkan Anda menggunakan tema ini dalam produk akhir apa pun. Untuk informasi lebih lanjut tentang lisensi, silakan periksa syarat lisensi di pasar.',
        },
        {
            question: 'Bagaimana cara menonaktifkan opsi pengembang?',
            ans: 'Tidak, ini adalah template HTML. Template ini tidak akan bekerja langsung dengan WordPress, meskipun Anda dapat mengonversinya menjadi tema yang kompatibel dengan WordPress.',
        },
        {
            question: 'Fitur apa yang ditawarkan Traceforce?',
            ans: 'Kirimkan masalah atau umpan balik Anda ke email dukungan khusus kami (support@coderthemes.com). Kami tersedia kapan saja untuk membantu Anda.',
        },
        {
            question: 'Apakah saya perlu menginstal perangkat lunak Traceforce?',
            ans: 'Ya, kami akan secara teratur memperbarui petunjuk. Semua pembaruan mendatang akan tersedia secara gratis.',
        },
        {
            question: 'Apa proses implementasi perangkat lunak Traceforce?',
            ans: 'Ya, lisensi pasar memungkinkan Anda menggunakan tema ini dalam produk akhir apa pun. Untuk informasi lebih lanjut tentang lisensi, silakan periksa syarat lisensi di pasar.',
        },
        {
            question: 'Apa opsi pelatihan dan dukungan yang tersedia dengan perangkat lunak Traceforce?',
            ans: 'Tidak, ini adalah template HTML. Template ini tidak akan bekerja langsung dengan WordPress, meskipun Anda dapat mengonversinya menjadi tema yang kompatibel dengan WordPress.',
        },
        {
            question: 'Dapatkah perangkat lunak Traceforce dikustomisasi sesuai dengan proses HR spesifik kami?',
            ans: 'Kirimkan masalah atau umpan balik Anda ke email dukungan khusus kami (support@coderthemes.com). Kami tersedia kapan saja untuk membantu Anda.',
        },
        {
            question: 'Bagaimana perangkat lunak HRMS dapat membantu dengan kepatuhan dan pelaporan?',
            ans: 'Ya, kami akan secara teratur memperbarui petunjuk. Semua pembaruan mendatang akan tersedia secara gratis.',
        },
        {
            question: 'Apakah perangkat lunak HRMS mendukung kerja jarak jauh dan akses seluler?',
            ans: 'Ya, kami akan secara teratur memperbarui petunjuk. Semua pembaruan mendatang akan tersedia secara gratis.',
        },
    ],
};
export default pricingPage;
