const products = {
    attendanceManagement: {
        productName: 'إدارة الحضور',
        titleDescription: 'إعداد سهل، تسجيل دخول وخروج تلقائي، تقارير',
        productCards: [
            {
                title: 'توازن العمل والحياة',
                data: 'اعتنِ بتوازن العمل والحياة لموظفيك من خلال تذكيرات TraceForce حول العمل والحياة الشخصية.',
            },
            {
                title: 'أنواع الأعضاء',
                data: 'بسّط إدارة مساحة العمل باستخدام أدوار الأعضاء مثل المدير التنفيذي، مدير المشروع، مدير الفريق، وعرض المشاريع.',
            },
            {
                title: 'يساعد في تتبع',
                data: 'تجعل جداول الزمن الذكية من TraceForce إدارة المهام أبسط من خلال السماح لك بعرض ساعات النشاط، ساعات الركود، وتحليل ساعات الإنتاجية بتنسيق مفصل.',
            },
        ],
        whyChooseSubCaption:
            'انتقل إلى بيئة عمل فعالة للغاية واختبر زيادة الإنتاجية والتعاون. مركّز البيانات في البطاقات.',
        headerCaption: 'لماذا تختار إدارة الحضور من TraceForce',
        headerT1: 'ميزات تتبع الحضور التي تقدم أكثر من ذلك بكثير',
        subCaptionT1:
            'اختبر تتبع الموظفين، مراقبة العمل عن بُعد، مراقبة الإنتاجية، إدارة الحضور، جداول الزمن، وأكثر — كل ذلك مع طبقة من الوظائف المتقدمة.',
        features: {
            attendanceMonitoring: {
                featureHeader: 'مراقبة الحضور',
                featureDescription:
                    'معلومات تسجيل الدخول/الخروج في الوقت الحقيقي حول حضور أو غياب الأفراد ضمن منطقة الجغرافية أو الفرع.',
                featureParagraph:
                    'لا مزيد من بطاقات الوقت، والأعمال الورقية الزائدة، والمستندات المختلفة للتحقق من حضور الموظفين. قم بأتمتة عملية مراقبة الحضور باستخدام WebWork Time Tracker وشاهد بداية ونهاية ساعات العمل لموظفيك في تنسيق منظم.',
                fIIPoints: [
                    'معلومات في الوقت الحقيقي حول حضور أو غياب الأفراد ضمن منطقة الجغرافية أو الفرع',
                    'مراقبة ساعات العمل بناءً على يومي، أسبوعي وشهري',
                    'الوصول إلى تقارير الحضور لعدة فروع عن بُعد',
                    'توحيد البيانات والعمليات والأنظمة المتعلقة بالحضور في منصة أو نظام مركزي واحد',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'الحضور في الوقت الحقيقي',
                        paragraph: 'معلومات في الوقت الحقيقي حول حضور أو غياب الأفراد ضمن منطقة الجغرافية أو الفرع',
                    },
                    totalWorkingHoursCalculation: {
                        header: 'حساب ساعات العمل الإجمالية',
                        paragraph: 'مراقبة ساعات العمل بناءً على يومي، أسبوعي وشهري',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'الوصول عن بُعد لتقارير الحضور',
                        paragraph: 'الوصول إلى تقارير الحضور لعدة فروع عن بُعد',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'مراقبة الحضور واحد',
                        description: [
                            'احصل على شفافية بشأن انضباط الموظفين',
                            'وفر الوقت ونظم حضور الموظفين دون أي إجراءات إضافية.',
                            'راقب بسهولة ما إذا كان موظفوك قد وصلوا إلى مكان العمل في الوقت المحدد وأكملوا ساعات عملهم أم لا.',
                            'حافظ على تنظيم عمليات مكان العمل وامتلك بيئة عمل مسؤولة للغاية.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'مراقبة الحضور اثنان',
                        description: [
                            'تحليل ذكي لبيانات الحضور',
                            'تصفية حالات الخروج المبكر، والتأخير، وقلة التتبع التي يمكنك اختيارها من قائمة المنسدلة.',
                            'سيساعدك هذا على توليد معلوماتك المفضلة على الفور دون الحاجة إلى البحث عنها يدويًا.',
                            'يمكنك تتبع الحضور على أساس يومي أو يمكنك اختيار فترة زمنية من التقويم.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'مراقبة الحضور ثلاث',
                        description: [
                            'طريقة آمنة وشفافة لمراقبة وتتبع حضور الموظفين في الوقت الحقيقي باستخدام نظام بدون تلامس بنسبة 100%.',
                            'إعداد سهل',
                            'تسجيل الدخول والخروج التلقائي، تقارير مفصلة',
                            'لقطات شاشة مع وقت التتبع',
                            'تسجيل مستوى النشاط',
                            'مراقبة استخدام التطبيقات والمواقع الإلكترونية',
                        ],
                    },
                },
            },
            shiftManagement: {
                featureHeader: 'إدارة التحولات',
                featureDescription:
                    'تنظيم وتخطيط ساعات العمل التي تعزز الإنتاجية، تلبية الاحتياجات التشغيلية، وضمان التغطية عبر فترات زمنية مختلفة.',
                fIIPoints: [
                    'تطوير جدول تحولات واضح ومنظم. تحديد أنواع مختلفة من التحولات بناءً على طبيعة عملك.',
                    'تحليل حجم العمل والمهام لتحديد عدد الموظفين المطلوبين خلال التحولات المختلفة',
                    'منصة مركزية للموظفين للوصول إلى جداولهم، وإنشاء قناة اتصال موثوقة لأي تحديثات أو تغييرات.',
                    'إشراف ومراقبة مركزية لجداول الموظفين، مما يسمح للمديرين بتوزيع التحولات بكفاءة، تتبع الحضور، وإدارة الموارد البشرية عبر مواقع متعددة من منصة واحدة.',
                ],
            },
            geoFenceBasedManagement: {
                featureHeader: 'إدارة الحضور بناءً على الجغرافيا',
                featureDescription:
                    'تكنولوجيا قائمة على الموقع لمراقبة والتحقق من وجود الموظفين ضمن حدود جغرافية محددة.',
                fIIPoints: [
                    'سجلات حضور دقيقة لضمان أن الموظفين موجودون في مواقع عملهم المحددة.',
                    'تنفيذ تتبع في الوقت الحقيقي لمراقبة مواقع الموظفين ضمن السياج الجغرافي.',
                    'تحديد حدود جغرافية معينة، تعرف باسم السياجات الجغرافية، حول مكان العمل أو المواقع المحددة للحضور.',
                    'إعداد تنبيهات أو إشعارات لإبلاغ المديرين أو موظفي الموارد البشرية عند دخول الموظفين أو مغادرتهم حدود السياج الجغرافي المحددة.',
                ],
            },
            multipleClockInOptions: {
                featureHeader: 'خيارات تسجيل الدخول المتعددة',
                featureDescription:
                    'توفير المرونة للموظفين لتسجيل ساعات عملهم باستخدام أجهزة المسح البيومترية، التطبيقات المحمولة، ساعات الوقت، والمنصات المستندة إلى الويب.',
                fIIPoints: [
                    'مناسب للمنظمات ذات المواقع المتعددة خيارات تسجيل الدخول المرنة لإدارة الحضور الميداني وغير الميداني للفروع المختلفة',
                    'توفير الوصول للموظفين إلى بوابة الخدمة الذاتية لسجلات الحضور وإدارة تسجيل الدخول والخروج داخل السياج الجغرافي.',
                    'تشمل خيارات تسجيل الدخول المرنة المسح البيومتري، التطبيقات المحمولة، بطاقات RFID والتعرف على الوجه لضمان تتبع الوقت بسلاسة عبر بيئات العمل المختلفة.',
                    'تسجيل الحضور التلقائي للموظفين ضمن نطاق مباني المكتب مع إشعار تنبيه.',
                ],
            },
            attendanceReport: {
                featureHeader: 'تقارير الحضور',
                featureDescription:
                    'تلخيص بيانات حضور الموظفين، ساعات العمل، الغيابات، التأخيرات، والمغادرات المبكرة ضمن إطار زمني محدد.',
                fIIPoints: [
                    'توفير ملخص شامل للحضور، بما في ذلك إجمالي عدد أيام العمل، إجمالي الساعات العمل، وأي ساعات إضافية.',
                    'الوصول عن بُعد إلى تقارير الحضور لعدة فروع مع فئات قابلة للتخصيص.',
                    'عرض أرصدة الإجازات لكل موظف، بما في ذلك أيام الإجازة، الإجازة المرضية، وأي أنواع أخرى من الإجازات المتراكمة والمستخدمة.',
                    'السماح للمستخدمين بتخصيص التقارير بناءً على معايير محددة، مثل القسم، الفريق، أو الموظف الفردي.',
                ],
            },
        },
    },
    fieldTrackingManagement: {
        productName: 'إدارة القوى الميدانية',
        titleDescription: 'عمليات المبيعات والخدمات الميدانية مع التقارير المباشرة وخطة الزيارات',
        productCards: [
            {
                title: 'تقليل التكاليف العامة',
                data: 'استمتع بتقليل التكاليف التشغيلية من خلال أتمتة جداول الموظفين. تتبع وقت الموظفين، المهام والنفقات لتحديد المجالات التي يمكنك توفير المال فيها.',
            },
            {
                title: 'قيمة عظيمة للعملاء',
                data: 'حدد ضمان جدول زمني وخدمة فعالة. تتبع تفاعلات العملاء والتعليقات لتحديد الثغرات والمجالات التي تحتاج إلى تحسين.',
            },
            {
                title: 'تحليلات الأداء',
                data: 'قم بإنشاء تقارير شاملة على لوحات القيادة الموحدة، مما يوفر رؤى قيمة حول أداء القوة العاملة الخاصة بك، الجدول الزمني للوظائف، رضا العملاء والمجالات التي تحتاج إلى تحسين.',
            },
        ],
        whyChooseSubCaption:
            'أقصى كفاءة مع برنامج إدارة القوى الميدانية\nالتعليق الفرعي - تتبع موظفيك في الميدان في الوقت الحقيقي، راقب تقدم المهام، واحصل على رؤى حول موقعهم ونشاطهم وأدائهم. حسن المساءلة واستخدم اتخاذ القرار المعتمد على البيانات.',
        headerCaption: 'لماذا تختار إدارة القوى الميدانية من TraceForce',
        headerT1: 'أبرز ميزات أنظمة إدارة القوى الميدانية',
        subCaptionT1:
            'استفد من تتبع موقع الموظفين في الوقت الحقيقي مع تحسين قائم على الذكاء الاصطناعي لتبسيط العمليات، التقارير المباشرة للحصول على رؤى فورية، واستخدام تحليلات المبيعات الميدانية لتحسين الأداء. ضمان تخصيص الموارد بكفاءة، تحسين الإنتاجية، واتخاذ قرارات مبنية على البيانات.',
        features: {
            workforceTracking: {
                featureHeader: 'تتبع القوى العاملة',
                featureDescription: 'مراقبة منهجية وإدارة أنشطة الموظفين وإنتاجيتهم وحضورهم.',
                featureParagraph:
                    'وداعاً لبطاقات الوقت، الأوراق الزائدة والمستندات المختلفة للتحقق من حضور الموظفين. قم بأتمتة عملية مراقبة الحضور باستخدام WebWork Time Tracker واطلع على بداية ونهاية ساعات العمل للموظفين لديك بشكل منظم.',
                fIIPoints: [
                    'مراقبة الأحداث مثل تسجيل الدخول والخروج وأيضاً نظرة على أداء الموظفين وقياسات الأداء أو الأحداث.',
                    'تخزين البيانات للاجتماعات والمهام لتتبع الاتجاهات واتخاذ القرارات المعتمدة على البيانات لمحاضر الاجتماع.',
                    'إدارة العملاء والآفاق لتتبع إنتاجية الموظفين ويمكن قياس مؤشرات الأداء الرئيسية بناءً على كمية العمل المنجز من قبل الموظفين.',
                    'تتبع المسافة الدقيقة التي قطعها الموظفون للعمل الميداني بناءً على الأساس اليومي والأسبوعي والشهري وتعويض النفقات وفقاً للمسافة والأماكن التي تمت زيارتها.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'تتبع الحضور بناءً على الجدول الزمني',
                        paragraph:
                            'مراقبة الحضور والحركة مثل تسجيل الدخول والخروج وأيضاً نظرة على أداء الموظفين وقياسات الأداء أو الأحداث.',
                    },
                    customFormsforDataManagement: {
                        header: 'نماذج مخصصة لإدارة البيانات',
                        paragraph: 'مراقبة ساعات العمل بناءً على الأساس اليومي والأسبوعي والشهري.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'الوصول عن بعد لتقارير الحضور',
                        paragraph: 'الوصول إلى تقارير الحضور لفروع متعددة عن بُعد.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'مراقبة الحضور - الخيار الأول',
                        description: [
                            'احصل على شفافية حول دقة حضور الموظفين.',
                            'وفر الوقت ونظم حضور الموظفين دون أي إجراءات إضافية.',
                            'راقب بسهولة ما إذا كان موظفوك قد وصلوا إلى مكان العمل في الوقت المحدد وأكملوا ساعات عملهم أم لا.',
                            'اجعل عمليات مكان العمل أكثر تنظيماً ولديك بيئة عمل مسؤولة للغاية.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'مراقبة الحضور - الخيار الثاني',
                        description: [
                            'تحليل ذكي لبيانات الحضور.',
                            'تصفية حالات المغادرة المبكرة، الوصول المتأخر، وقلة التتبع التي يمكنك اختيارها من القائمة المنسدلة.',
                            'سيساعدك هذا على توليد المعلومات المفضلة لديك على الفور دون الحاجة إلى البحث عنها يدوياً.',
                            'يمكنك تتبع الحضور على أساس يومي أو يمكنك اختيار فترة من التقويم.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        imagePath: '',
                        title: 'مراقبة الحضور - الخيار الثالث',
                        description: [
                            'طريقة آمنة وشفافة لمراقبة وتتبع حضور الموظفين في الوقت الفعلي باستخدام نظام خالٍ من اللمس بنسبة 100%.',
                            'إعداد سهل.',
                            'تسجيل الدخول والخروج التلقائي وتقارير مفصلة.',
                            'لقطات شاشة مع تتبع الوقت.',
                            'تسجيل مستوى النشاط.',
                            'مراقبة استخدام التطبيقات والمواقع الإلكترونية.',
                        ],
                    },
                },
            },
            fieldSalesAnalytics: {
                featureHeader: 'تحليلات المبيعات الميدانية',
                featureDescription:
                    'تتبع وتحليل المقاييس الرئيسية لفريق المبيعات في الميدان مثل إيرادات المبيعات، تفاعلات العملاء، والاتجاهات الجغرافية.',
                featureParagraph:
                    'وداعاً لبطاقات الوقت، الأوراق الزائدة والمستندات المختلفة للتحقق من حضور الموظفين. قم بأتمتة عملية مراقبة الحضور باستخدام WebWork Time Tracker واطلع على بداية ونهاية ساعات العمل للموظفين لديك بشكل منظم.',
                fIIPoints: [
                    'يمكن أن تساعد مؤشرات الأداء الرئيسية في اتخاذ قرارات معتمدة على البيانات مما يمكن أن يساعد في اتخاذ قرارات منتجة في المرات القادمة أو إعادة النظر في الاستراتيجية.',
                    'يمكن الوصول إلى التقارير على أساس يومي أو أسبوعي أو شهري حول المبيعات، العملاء المحتملين، واكتساب العملاء وهذا يمكن أن يساعد في إنتاجية الشخص وكفاءته.',
                    'التحقق من الزيارة باستخدام تسجيل الدخول القائم على الجغرافيا لضمان الدقة. يمكن إرفاق إحداثيات الموقع بصورة الكاميرا أثناء المشاركة.',
                    'يمكن استخدام خطة الزيارات للتخطيط للزيارة أو خطة الزيارات للموظفين لزيارة العملاء. دمج خطة الزيارات الخاصة بك في الخرائط مباشرة لتحسين التنقل.',
                ],
            },
            locationBasedAttendance: {
                featureHeader: 'الحضور القائم على الموقع',
                featureDescription:
                    'حضور قائم على الجغرافيا لضمان أن الموظفين يمكنهم تسجيل الدخول أو الخروج فقط عندما يكونون داخل الحدود الجغرافية المحددة.',
                fIIPoints: [
                    'تنفيذ حدود افتراضية جغرافية حول مواقع محددة في مقر المكتب. يساعد في أتمتة الحضور بناءً على دخول الموظف أو مغادرته لمكان محدد.',
                    'طرق التوثيق لضمان دقة وأمان بيانات الحضور باستخدام الرموز الشخصية أو البيومترية أو الحضور بالوجه.',
                    'ضمان تحديث سجلات الحضور على الفور، مما يسمح باتخاذ قرارات إدارية في الوقت المناسب.',
                    'تحديد طرق بديلة لتتبع الحضور للموظفين عن بُعد.',
                ],
            },
            liveReporting: {
                featureHeader: 'التقارير المباشرة',
                featureDescription:
                    'تتبع وتحليل الوقت الفعلي لجوانب مختلفة من عملهم، مثل الحضور، المهام المكتملة، أو تقدم المشاريع.',
                fIIPoints: [
                    'يمكن للمسؤول أو المدير الاشتراك أو اختيار الإشعارات التي يرغبون في تلقيها. سيساعد ذلك في التركيز على الإشعارات التي يريدون تلقيها.',
                    'ابق على اطلاع دائم بشأن الحضور والإجازات، وكن على اطلاع مسبق حول عدد الأفراد لتخطيط اليوم بشكل أكثر إنتاجية وكفاءة.',
                    'تتبع جميع الموافقات من التعويضات إلى الإجازات وعرضها لاحقاً.',
                    'تتبع عن بُعد من خلال النماذج ومحاضر الاجتماعات. يمكن أن تساعد النماذج المخصصة في جلب بيانات محددة للاجتماع والتي يمكن الوصول إليها عن بُعد.',
                ],
            },
            aIBasedOptimization: {
                featureHeader: 'تحسين قائم على الذكاء الاصطناعي',
                featureDescription:
                    'قم بتحسين وتلقائي تخطيط المسارات لممثلي الميدان أو موظفي المبيعات بناءً على مسار الذكاء الاصطناعي.',
                fIIPoints: [
                    'استخدم مصادر البيانات في الوقت الفعلي، مثل تحديثات حركة المرور الحية، والظروف الجوية، وإغلاق الطرق، لتعديل المسارات ديناميكيًا بناءً على الظروف الحالية، مما يساعد في التخطيط اليومي بشكل فعال.',
                    'عرض المسارات المحسّنة، وإجراء أي تعديلات ضرورية. يمكن أن يكون ذلك جزءًا من تطبيق موبايل أو منصة على الويب.',
                    'نظام الذكاء الاصطناعي يأخذ في اعتباره الخصائص الفريدة للمسارات أو التوصيلات المختلفة.',
                    'استخدم خدمات الخرائط والبيانات الجغرافية لفهم شبكة الطرق، وظروف حركة المرور، ومعلومات الموقع الأخرى.',
                ],
            },
            taskManagement: {
                featureHeader: 'إدارة المهام',
                featureDescription: 'تنظيم وتحديد أولويات وتنفيذ الأنشطة بفعالية لتحقيق أهداف أو أهداف معينة.',
                featureParagraph:
                    'لا حاجة إلى بطاقات الوقت، أو الأوراق الزائدة، أو مستندات مختلفة للتحقق من حضور الموظفين. قم بأتمتة عملية متابعة الحضور باستخدام WebWork Time Tracker وراقب بدء وانتهاء ساعات العمل لموظفيك بتنسيق منظم.',
                fIIPoints: [
                    'راقب التقدم في الوقت الفعلي من خلال عرض لوحات المهام، وفحص حالة المهام، وتتبع الوقت المنقضي.',
                    'ميزات التعاون في إدارة المهام للتواصل مع أعضاء الفريق في الوقت الفعلي. اترك تعليقات على المهام، اطرح الأسئلة، وقدم التحديثات لإبقاء الجميع على اطلاع.',
                    'إدارة العملاء والقيادات لتتبع الإنتاجية. يمكن قياس مؤشرات الأداء الرئيسية بناءً على كمية العمل المنجز من قبل الموظفين.',
                    'هيكل هرمي لتنظيم الأهداف، وأهداف المشاريع، وأهداف الأقسام، وأهداف الأداء الفردية، والمبادرات الاستراتيجية، وما إلى ذلك.',
                ],
            },
        },
    },
    payrollAndLeaveManagement: {
        productName: 'إدارة الرواتب والإجازات',
        titleDescription: 'تحسين معالجة الرواتب وإدارة الإجازات',
        headerCaption: 'لماذا تختار TraceForce لإدارة العملاء',
        productCards: [
            {
                title: 'سهولة الإدارة',
                data: 'بسط الإدارة عن طريق مركزية معلومات العملاء، وأتمتة المهام الروتينية، وتبسيط التواصل.',
            },
            {
                title: 'قابلية التخصيص',
                data: 'قابلية التخصيص',
            },
            {
                title: 'التكاملات',
                data: 'تمكين التكاملات لمشاركة البيانات، وأتمتة المهام، وتحسين التعاون عبر الفرق، مما يؤدي إلى تنفيذ CRM أكثر كفاءة وفعالية.',
            },
        ],
        whyChooseSubCaption: 'تتبع ورعاية العملاء المحتملين بكفاءة، لضمان عدم فقدان أي فرصة وتحسين معدلات التحويل.',
        headerT1: 'فوائد نظام إدارة العملاء من TraceForce',
        subCaptionT1:
            'أتمتة التقاط العملاء المحتملين وتوزيعهم\nتكامل البيانات في الوقت الفعلي والتقارير\nأتمتة أدوات المبيعات والتسويق',
        features: {
            leadManagement: {
                featureHeader: 'معالجة الرواتب',
                featureDescription: 'متابعات في الوقت المناسب، تفاعلات مخصصة، ومعدلات تحويل قصوى.',
                featureParagraph:
                    'لا حاجة إلى بطاقات الوقت، أو الأوراق الزائدة، أو مستندات مختلفة للتحقق من حضور الموظفين. قم بأتمتة عملية متابعة الحضور باستخدام WebWork Time Tracker وراقب بدء وانتهاء ساعات العمل لموظفيك بتنسيق منظم.',
                fIIPoints: [
                    'بسط عملية إدارة العملاء المحتملين بناءً على الوقت وموقع قوة المبيعات، مما يجعل من السهل تحقيق الأهداف في فترة زمنية قصيرة.',
                    'استخدم تاريخ جهات الاتصال لإنشاء أقسام بناءً على زيارات المواقع، وتفاعلات البريد الإلكتروني، والمزيد. قسم قوة المبيعات بناءً على هذه البيانات لإدارة العملاء المحتملين.',
                    'استخدم درجات العملاء المحتملين لتعيين العملاء المحتملين إلى فريق المبيعات الخاص بك، أو إعداد سير العمل لإعلام المندوبين عندما يكون عملاؤهم المحتملين جاهزين للتحويل.',
                    'الوصول بسهولة إلى تاريخ كل عميل محتمل، بما في ذلك معلومات الشركة وخط زمني لكل نقطة تواصل بين الاتصال وعملك.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'تتبع الحضور بناءً على الجدول الزمني',
                        paragraph:
                            'تتبع الحضور والحركة مثل تسجيل الدخول والخروج، وأيضًا رؤى الموظفين حول عملياتهم، ومؤشرات الأداء، أو الأحداث.',
                    },
                    customFormsforDataManagement: {
                        header: 'نماذج مخصصة لإدارة البيانات',
                        paragraph: 'راقب ساعات العمل بناءً على اليومية، والأسبوعية، والشهرية.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'الوصول عن بُعد إلى تقارير الحضور',
                        paragraph: 'الوصول إلى تقارير الحضور لعدة فروع عن بُعد.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'مراقبة الحضور واحدة',
                        description: [
                            'احصل على شفافية في دقة وصول الموظفين',
                            'وفر الوقت ونظم حضور الموظفين بدون أي إجراءات إضافية.',
                            'راقب بسهولة ما إذا كان موظفوك قد وصلوا إلى مكان العمل في الوقت المحدد وأكملوا ساعات عملهم أم لا.',
                            'اجعل عمليات مكان العمل أكثر تنظيمًا واحصل على بيئة عمل مسؤولة للغاية.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'مراقبة الحضور اثنان',
                        description: [
                            'تحليل ذكي لبيانات الحضور',
                            'تصفية المغادرين مبكرًا، والمتأخرين، والتتبع الأقل الذي يمكنك تحديده من القائمة المنسدلة.',
                            'سيساعدك ذلك في الحصول على المعلومات التي تفضلها على الفور دون الحاجة إلى البحث يدويًا.',
                            'يمكنك تتبع الحضور يوميًا أو يمكنك اختيار فترة من التقويم.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'مراقبة الحضور ثلاث',
                        description: [
                            'طريقة آمنة وشفافة لمراقبة وتتبع حضور الموظفين في الوقت الفعلي بنظام خالٍ من اللمس بنسبة 100%.',
                            'إعداد سهل',
                            'تسجيلات الدخول والخروج المؤتمتة وتقارير مفصلة',
                            'لقطات شاشة مع تتبع الوقت',
                            'تسجيل مستوى النشاط',
                            'مراقبة استخدام التطبيقات والمواقع الإلكترونية',
                        ],
                    },
                },
            },
            leaveManagement: {
                featureHeader: 'إدارة الإجازات',
                featureDescription:
                    'إدارة وتتبع الحضور بفعالية، وضمان التوثيق الصحيح، والامتثال لسياسات الشركة، والحفاظ على استمرارية سير العمل بسلاسة.',
                featureParagraph:
                    'لا حاجة إلى بطاقات الوقت، أو الأوراق الزائدة، أو مستندات مختلفة للتحقق من حضور الموظفين. قم بأتمتة عملية متابعة الحضور باستخدام WebWork Time Tracker وراقب بدء وانتهاء ساعات العمل لموظفيك بتنسيق منظم.',
                fIIPoints: [
                    'إدارة تراكمات الإجازات، وضمان عدم تجاوز الموظفين للمبالغ المخصصة، ومنع الأرصدة السالبة للإجازات.',
                    'تكوين سياسات الإجازات المرنة للمنظمة عن طريق إعداد تقويم الإجازات. تمكين الإعدادات المتوازية أو التسلسلية لموافقة أو رفض طلبات الإجازات.',
                    'ابق على اطلاع بقوانين العمل واللوائح المتعلقة بحقوق الإجازات. تأكد من أن سياسات إدارة الإجازات الخاصة بك تتوافق مع سياسات الشركة.',
                    'توفير خيارات الخدمة الذاتية للموظفين، مما يتيح لهم التحقق من أرصدة الإجازات الخاصة بهم، وتقديم الطلبات، وعرض حالة طلباتهم.',
                ],
            },
        },
    },
    orderManagement: {
        productName: 'إدارة الطلبات',
        titleDescription: 'تبسيط عمليات الطلبات/الجمع بحل قابل للتوسع وآلي.',
        headerCaption: 'لماذا تختار TraceForce لإدارة العملاء',
        productCards: [
            {
                title: 'سهولة الإدارة',
                data: 'بسط الإدارة عن طريق مركزية معلومات العملاء، وأتمتة المهام الروتينية، وتبسيط التواصل.',
            },
            {
                title: 'قابلية التخصيص',
                data: 'قابلية التخصيص',
            },
            {
                title: 'التكاملات',
                data: 'تمكين التكاملات لمشاركة البيانات، وأتمتة المهام، وتحسين التعاون عبر الفرق، مما يؤدي إلى تنفيذ CRM أكثر كفاءة وفعالية.',
            },
        ],
        whyChooseSubCaption: 'تتبع ورعاية العملاء المحتملين بكفاءة، لضمان عدم فقدان أي فرصة وتحسين معدلات التحويل.',
        headerT1: 'فوائد نظام إدارة العملاء من TraceForce',
        subCaptionT1:
            'أتمتة التقاط العملاء المحتملين وتوزيعهم\nتكامل البيانات في الوقت الفعلي والتقارير\nأتمتة أدوات المبيعات والتسويق',
        features: {
            digitizationOfOrder: {
                featureHeader: 'رقمنة الطلب',
                featureDescription: 'تبسيط إدارة الطلبات من البداية إلى النهاية وتقليل الأخطاء اليدوية',
                featureParagraph:
                    'لا حاجة إلى بطاقات الوقت، أو الأوراق الزائدة، أو مستندات مختلفة للتحقق من حضور الموظفين. قم بأتمتة عملية متابعة الحضور باستخدام WebWork Time Tracker وراقب بدء وانتهاء ساعات العمل لموظفيك بتنسيق منظم.',
                fIIPoints: [
                    'إنشاء تقارير وتحليلات مفصلة لمراقبة أداء معالجة الطلبات وإنتاجية الموظفين.',
                    'التقاط تفاصيل الطلب والتحقق منها تلقائيًا التي أدخلها الموظفون أو العملاء.',
                    'توفير تحديثات في الوقت الفعلي حول حالة الطلب وتتبع الشحن للموظفين والعملاء.',
                    'أتمتة عمليات الموافقة على الطلبات وتعيين المهام للموظفين المناسبين.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'تتبع الحضور بناءً على الجدول الزمني',
                        paragraph:
                            'تتبع الحضور والحركة مثل تسجيل الدخول والخروج، وأيضًا رؤى الموظفين حول عملياتهم، ومؤشرات الأداء، أو الأحداث.',
                    },
                    customFormsforDataManagement: {
                        header: 'نماذج مخصصة لإدارة البيانات',
                        paragraph: 'راقب ساعات العمل بناءً على اليومية، والأسبوعية، والشهرية.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'الوصول عن بُعد إلى تقارير الحضور',
                        paragraph: 'الوصول إلى تقارير الحضور لعدة فروع عن بُعد.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'مراقبة الحضور واحدة',
                        description: [
                            'احصل على شفافية في دقة وصول الموظفين',
                            'وفر الوقت ونظم حضور الموظفين بدون أي إجراءات إضافية.',
                            'راقب بسهولة ما إذا كان موظفوك قد وصلوا إلى مكان العمل في الوقت المحدد وأكملوا ساعات عملهم أم لا.',
                            'اجعل عمليات مكان العمل أكثر تنظيمًا واحصل على بيئة عمل مسؤولة للغاية.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'مراقبة الحضور اثنان',
                        description: [
                            'تحليل ذكي لبيانات الحضور',
                            'تصفية المغادرين مبكرًا، والمتأخرين، والتتبع الأقل الذي يمكنك تحديده من القائمة المنسدلة.',
                            'سيساعدك ذلك في الحصول على المعلومات التي تفضلها على الفور دون الحاجة إلى البحث يدويًا.',
                            'يمكنك تتبع الحضور يوميًا أو يمكنك اختيار فترة من التقويم.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'مراقبة الحضور ثلاث',
                        description: [
                            'طريقة آمنة وشفافة لمراقبة وتتبع حضور الموظفين في الوقت الفعلي بنظام خالٍ من اللمس بنسبة 100%.',
                            'إعداد سهل',
                            'تسجيلات الدخول والخروج المؤتمتة وتقارير مفصلة',
                            'لقطات شاشة مع تتبع الوقت',
                            'تسجيل مستوى النشاط',
                            'مراقبة استخدام التطبيقات والمواقع الإلكترونية',
                        ],
                    },
                },
            },
            targetManagement: {
                featureHeader: 'إدارة الأهداف',
                featureDescription: 'تحديد أهداف واضحة وقابلة للتحقيق لكل موظف بناءً على دورهم وقدراتهم.',
                featureParagraph:
                    'لا حاجة إلى بطاقات الوقت، أو الأوراق الزائدة، أو مستندات مختلفة للتحقق من حضور الموظفين. قم بأتمتة عملية متابعة الحضور باستخدام WebWork Time Tracker وراقب بدء وانتهاء ساعات العمل لموظفيك بتنسيق منظم.',
                fIIPoints: [
                    'تحقيق الأهداف اليومية والأسبوعية والشهرية والحفاظ على سجلات إنجازات الموظفين مع تتبع الحقول الحية.',
                    'التقدم في الوقت الفعلي (عدد الأهداف المحققة مقابل المتبقية)، أوقات تسجيل الدخول/الخروج، عدد الساعات (المدة) المستغرقة في كل مهمة، وتتبع الموقع.',
                    'مقاييس الأداء (معدل الإنجاز، الوقت المستغرق، درجات رضا العملاء)، المقارنة ضد الأهداف (الأداء الفعلي مقابل الهدف)، البيانات التاريخية (اتجاهات الأداء السابقة).',
                    'إرسال إشعارات تلقائية لإبقاء الموظفين على اطلاع بشأن تنبيهات تحقيق الأهداف، تذكيرات الأداء، وتحذيرات المواعيد النهائية.',
                ],
            },
            invoiceManagement: {
                featureHeader: 'إدارة الفواتير',
                featureDescription: 'إنشاء وتتبع حالة الفواتير في الوقت الفعلي مع التنبيهات والإشعارات المؤتمتة.',
                fIIPoints: [
                    'إعداد أنظمة لتتبع حالة الفواتير وإرسال التذكيرات والإشعارات المؤتمتة بشأن المدفوعات المستحقة والمتأخرة.',
                    'قوالب فواتير موحدة تشمل جميع التفاصيل الضرورية مثل معلومات العميل، تفاصيل الطلب، الضرائب، الخصومات، وشروط الدفع.',
                    'جمع التوقيعات الرقمية وإثبات التسليم بناءً على OTP كجزء من عملية الفاتورة.',
                    'مزامنة تلقائية مع النظام المركزي (التخزين السحابي) وتحديثات في الوقت الفعلي حول حالة الفاتورة.',
                ],
            },
            distributorOrder: {
                featureHeader: 'طلب التوزيع',
                featureDescription:
                    'إدارة المنتجات/الجمع وتعامل مع أولوية المهام مع تحديثات في الوقت الفعلي على المخزون.',
                fIIPoints: [
                    'إنشاء ومعالجة الطلبات مع جميع التفاصيل ذات الصلة لكل توزيع وتعيين المهام بناءً على التوفر.',
                    'التكامل مع إدارة المخزون لضمان تخصيص دقيق للمخزون وتحديث مستويات المخزون في الوقت الفعلي.',
                    'تكوين النظام لإرسال إشعارات مؤتمتة في مراحل رئيسية من عملية الطلب.',
                    'الوصول إلى لوحة المعلومات حيث يمكن للموظفين التحقق من الطلبات، تتبع التقدم، وتنزيل المستندات اللازمة.',
                ],
            },
            inventoryManagement: {
                featureHeader: 'إدارة المخزون',
                featureDescription:
                    'التوازن الصحيح للمخزون مع التحديثات في الوقت الفعلي وتتبع المخزون للحصول على بيانات دقيقة.',
                fIIPoints: [
                    'ضمان تكامل سلس بين نظم إدارة المخزون وإدارة الطلبات لمزامنة مستويات المخزون مع الطلبات الواردة والصادرة.',
                    'تتبع المخزون عبر مستودعات متعددة، أو متاجر، أو مواقع والوصول إلى التحكم المركزي، وتحسين توزيع المخزون وتحسين الرؤية.',
                ],
            },
            orderCollectionManagement: {
                featureHeader: 'إدارة الطلبات/الجمع',
                featureDescription: 'تبسيط عمليات الطلبات/الجمع بحل قابل للتوسع وآلي.',
                fIIPoints: [
                    'نظام مركزي يمكن الوصول إليه من قبل الموظفين في الموقع وخارج الموقع لإدارة الطلبات، تتبع التقدم، وتحديث الحالات في الوقت الفعلي.',
                    'مستويات مختلفة من الوصول والتصاريح بناءً على الأدوار (مثل المديرين، الموظفين في الموقع، الموظفين خارج الموقع) لضمان أمان البيانات والوصول المناسب.',
                    'ضمان مزامنة جميع بيانات الطلبات في الوقت الفعلي عبر جميع الأجهزة والمواقع، بحيث يكون لدى الجميع الوصول إلى أحدث المعلومات.',
                    'تسهيل التواصل بين الموظفين في الموقع وخارج الموقع من خلال الرسائل والإشعارات داخل التطبيق.',
                ],
            },
        },
    },
};
export default products;
