const industries = {
    pharmaceutical: {
        industryHeading:
            'Pantau protokol keamanan, lacak waktu, kehadiran, dan kendalikan akses ke area sensitif dengan memfasilitasi manajemen proyek yang efektif, kegiatan di luar situs, dan evaluasi kinerja.',
        featureHeader: 'Farmasi',
        featureParagraph:
            'Perangkat lunak manajemen kehadiran dan pelacakan lapangan yang fokus pada farmasi menyederhanakan manajemen tenaga kerja untuk perusahaan farmasi/kesehatan dengan fitur pelacakan waktu, aksesibilitas seluler untuk karyawan lapangan, pelaporan kepatuhan operasi lapangan, dan integrasi dengan sistem yang berbeda untuk generasi penjualan dan produktivitas.',
        keyPoints: [
            {
                header: 'Integrasikan sistem pelacakan karyawan dengan sistem HR terkait catatan karyawan, penggajian, dan pelaporan kepatuhan.',
                paragraph: 'Pantau jam kerja berdasarkan Harian, Mingguan, dan Bulanan',
            },
            {
                header: 'Gunakan sistem manajemen shift untuk mengoptimalkan penjadwalan tenaga kerja',
                paragraph: 'Akses Laporan Kehadiran dari Beberapa Cabang secara jarak jauh',
            },
            {
                header: 'Pantau jadwal area manufaktur dan produksi di mana operasi kontinu sangat penting.',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
            {
                header: 'Pastikan bahwa semua sistem pelacakan karyawan mematuhi peraturan dan standar yang relevan dalam industri farmasi.',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
        ],
    },
    education: {
        industryHeading: 'CRM Penerimaan yang Komprehensif untuk Kolaborasi Siswa yang Mulus dan Manajemen Prospek',
        featureHeader: 'Pendidikan',
        featureParagraph:
            'Rekrutmen dan Penerimaan Siswa \n Keterlibatan dan Retensi Siswa \n Kelola penerimaan di beberapa lokasi \n Dukungan Siswa yang Dipersonalisasi',
        keyPoints: [
            {
                header: 'Lacak dan kelola calon siswa dari titik kontak pertama melalui proses aplikasi.',
                paragraph: 'Pantau jam kerja berdasarkan Harian, Mingguan, dan Bulanan',
            },
            {
                header: 'Identifikasi dan kelola calon siswa yang menunjukkan minat untuk mendaftar. Lacak komunikasi dan kemajuan untuk mengubah pertanyaan menjadi siswa terdaftar.',
                paragraph: 'Akses Laporan Kehadiran dari Beberapa Cabang secara jarak jauh',
            },
            {
                header: 'Visualisasikan dan kelola berbagai tahap proses pendaftaran siswa. Pindahkan pelamar melalui tahap seperti Pertanyaan, Aplikasi, Wawancara, Penerimaan, dan Pendaftaran.',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
            {
                header: 'Permudah proses aplikasi dengan formulir online yang dapat disesuaikan. Kumpulkan informasi yang diperlukan dari calon siswa dengan efisien.',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
        ],
    },
    fieldAndSales: {
        industryHeading:
            'Optimalkan kinerja tim penjualan yang bergerak dengan memungkinkan pelacakan lokasi waktu nyata, memastikan perencanaan rute yang efisien, dan keterlibatan pelanggan yang tepat waktu.',
        featureHeader: 'Penjualan Lapangan',
        featureParagraph:
            'Percepat pertumbuhan dan tingkatkan kekuatan lapangan Anda dengan alat yang tepat untuk Merencanakan, Mengubah & Menjalankan. TraceForce menyederhanakan pelacakan penjualan lapangan dan manajemen prospek serta CRM',
        keyPoints: [
            {
                header: 'Kehadiran Waktu Nyata',
                paragraph:
                    'Informasi waktu nyata tentang kehadiran atau ketidakhadiran individu dalam area Geofence atau Cabang',
            },
            {
                header: 'Perhitungan Total Jam Kerja',
                paragraph: 'Pantau jam kerja berdasarkan Harian, Mingguan, dan Bulanan',
            },
            {
                header: 'Laporan Kehadiran Akses Jarak Jauh',
                paragraph: 'Akses Laporan Kehadiran dari Beberapa Cabang secara jarak jauh',
            },
            {
                header: 'Manajemen kehadiran terpusat',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
        ],
    },
    fmcg: {
        featureHeader: 'FMCG',
        industryHeading:
            'Metrik kinerja, manajemen pengeluaran, dan pelacakan kepatuhan yang efektif untuk manajemen kekuatan penjualan, meningkatkan keterlibatan pelanggan dengan fitur komunikasi dan kolaborasi',
        featureParagraph:
            'Pelacakan kehadiran waktu nyata memungkinkan penyesuaian cepat terhadap tingkat staf berdasarkan permintaan pasar, meningkatkan kelincahan operasi FMCG.',
        keyPoints: [
            {
                header: 'Pelacakan kehadiran yang akurat membantu',
                paragraph:
                    'Informasi waktu nyata tentang kehadiran atau ketidakhadiran individu dalam area Geofence atau Cabang',
            },
            {
                header: 'Pemrosesan penggajian yang tepat, mengurangi kesalahan dan meminimalkan ketidaksesuaian finansial.',
                paragraph: 'Pantau jam kerja berdasarkan Harian, Mingguan, dan Bulanan',
            },
            {
                header: 'Memastikan kepatuhan terhadap undang-undang dan peraturan ketenagakerjaan,',
                paragraph: 'Akses Laporan Kehadiran dari Beberapa Cabang secara jarak jauh',
            },
            {
                header: 'Memfasilitasi manajemen cuti yang efektif, mencegah masalah kekurangan staf',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
            {
                header: 'Memastikan produksi dan distribusi yang berkelanjutan.',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
            {
                header: 'Memastikan proses penggantian yang akurat.',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
            {
                header: 'Melacak pesanan saat bepergian dan penyelesaian.',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
        ],
    },
    services: {
        industryHeading:
            'Sederhanakan manajemen proyek dan tugas, optimalkan alokasi sumber daya dengan memastikan catatan waktu dan kehadiran yang akurat, membantu dalam pemrosesan penggajian dan kepatuhan.',
        featureHeader: 'Layanan',
        featureParagraph:
            'Fitur yang sudah teruji di lapangan meningkatkan produktivitas, meminimalkan kompleksitas, dan mengotomatiskan tugas, menghemat waktu dan sumber daya untuk bisnis Anda.',
        keyPoints: [
            {
                header: 'Pelacakan GPS staf lapangan waktu nyata',
                paragraph:
                    'Informasi waktu nyata tentang kehadiran atau ketidakhadiran individu dalam area Geofence atau Cabang',
            },
            {
                header: 'Formulir Kustom & Foto Geo-tagged',
                paragraph: 'Pantau jam kerja berdasarkan Harian, Mingguan, dan Bulanan',
            },
            {
                header: 'Dasbor intuitif & laporan yang dapat diunduh',
                paragraph: 'Akses Laporan Kehadiran dari Beberapa Cabang secara jarak jauh',
            },
            {
                header: 'Kelola & pantau koleksi',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
        ],
    },
    fieldOperations: {
        industryHeading:
            'Fasilitasi keputusan berbasis data dan tingkatkan komunikasi antara tim lapangan dan kantor pusat.',
        featureHeader: 'Operasi Lapangan',
        featureParagraph:
            'Ucapkan selamat tinggal pada manajemen operasi lapangan dan layanan yang tidak efisien dengan solusi serba ada TraceForce. Optimalkan manajemen tugas, lacak kehadiran saat bepergian, optimalkan rute berbasis AI untuk meningkatkan produktivitas dan pendapatan penjualan.',
        keyPoints: [
            {
                header: 'Jadwalkan & pantau tugas',
                paragraph:
                    'Informasi waktu nyata tentang kehadiran atau ketidakhadiran individu dalam area Geofence atau Cabang',
            },
            {
                header: 'Lacak lokasi waktu nyata dari semua karyawan lapangan',
                paragraph: 'Pantau jam kerja berdasarkan Harian, Mingguan, dan Bulanan',
            },
            {
                header: 'Validasi dan proses klaim pengeluaran',
                paragraph: 'Akses Laporan Kehadiran dari Beberapa Cabang secara jarak jauh',
            },
            {
                header: 'Tetapkan Target SLA & Pantau Keterlambatan',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
            {
                header: 'Dasbor intuitif & laporan yang dapat diunduh',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
            {
                header: 'Penyelesaian Tugas Terverifikasi OTP',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
        ],
    },
    logisticsAndTransportation: {
        industryHeading:
            'Fasilitasi keputusan berbasis data dan tingkatkan komunikasi antara tim lapangan dan kantor pusat.',
        featureHeader: 'Logistik dan Transportasi',
        featureParagraph:
            'Sederhanakan dan atur logistik serta pengiriman dengan cara yang lebih sederhana. Kelola operasi logistik dari tenaga kerja besar dengan fitur canggih dan kuat.',
        keyPoints: [
            {
                header: 'Dapatkan pelacakan lokasi orang yang melakukan pengiriman secara waktu nyata.',
                paragraph:
                    'Informasi waktu nyata tentang kehadiran atau ketidakhadiran individu dalam area Geofence atau Cabang',
            },
            {
                header: 'Tingkatkan efisiensi operasional dengan memantau aktivitas dan alur kerja.',
                paragraph: 'Pantau jam kerja berdasarkan Harian, Mingguan, dan Bulanan',
            },
            {
                header: 'Permudah manajemen tugas tenaga kerja.',
                paragraph: 'Akses Laporan Kehadiran dari Beberapa Cabang secara jarak jauh',
            },
            {
                header: 'Analisis data historis dan informasi lalu lintas waktu nyata untuk mengoptimalkan rute pengiriman.',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
            {
                header: 'Pemrosesan penggantian dan penggajian otomatis.',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
            {
                header: 'Layanan pelanggan yang lebih baik dengan pekerjaan lapangan otomatis.',
                paragraph:
                    'Konsolidasi data terkait kehadiran, proses, dan sistem ke dalam satu platform atau sistem terpusat',
            },
        ],
    },
};
export default industries;
