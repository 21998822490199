const joinUsPage = {
    joinUsString: 'انضم إلينا',
    joinUsHeroPara:
        'كن جزءًا من فريقنا في TraceForce! استكشف فرصًا مثيرة حيث التعاون والإبداع يقودان الطريق. اكتشف كيف يمكنك المساهمة في مهمتنا لتمكين الشركات من خلال حلول برمجية بديهية. نمِّ مسيرتك المهنية في بيئة داعمة تقدر مواهبك وأفكارك الفريدة.',
    ourRecruitmentProcess: 'عملية التوظيف لدينا',
    ourBenifitsString: 'مزايا فريقنا',
    ourRecruitmentProcessObjects: [
        {
            title: 'المقابلة التقنية',
            description:
                'هذه المقابلة مصممة لتقييم مهاراتك التقنية وقدراتك في حل المشكلات وكيفية تطبيق معرفتك على السيناريوهات الواقعية. نرغب في معرفة مستوى كفاءتك التقنية وقدراتك في البرمجة وطريقة تعاملك مع التحديات. تُجرى هذه الجولة عبر مكالمة Meet أو Skype.',
        },
        {
            title: 'اختبار الممارسة (القدرات)',
            description:
                'نقوم بتقييم منطق البرمجة وتحسين الكود الخاص بك من خلال مهمة تُرسل إليك بعد الجولة الأولى بناءً على مقابلتك. يجب على المرشح إكمال المهمة ضمن الجدول الزمني المحدد.',
        },
        {
            title: 'مقابلة الإدارة',
            description:
                'محادثة سريعة مع فريق الإدارة لفهم رؤيتك المهنية ومدى توافقك الثقافي مع منظمتنا. نبحث عن شخص يمتلك معرفة قوية في مجال عمله وأخلاقيات عمل قوية.',
        },
        {
            title: 'الاختيار النهائي',
            description:
                'يتم الانتهاء من العرض النهائي مع فريق الموارد البشرية. نحن نقوم باختيارنا النهائي بناءً على أداء المرشحين في جميع جولات المقابلة.',
        },
    ],
    ourBenifitsObjects: [
        {
            name: 'العمل من أي مكان!',
            description:
                "قد يكون مكتبك المنزلي مريحًا جدًا، لكننا نفضل أن نراك كل يوم، لذا لا نقدم خيار 'العمل من أي مكان'.",
        },
        {
            name: 'نهاية أسبوع متناوبة',
            description:
                'عندما تكون عطلات نهاية الأسبوع الخاصة بك متناوبة مثل تقلبات مزاجك. في أحد الأسابيع تخطط لقضاء عطلة ممتعة، وفي الأسبوع التالي تتعامل مع المواعيد النهائية والرسائل الإلكترونية.',
        },
        {
            name: 'التأمين الصحي',
            description:
                "لجميع تلك اللحظات التي قد تحتاج فيها إلى طبيب. مع التغطية الشاملة، لا داعي للقلق بشأن 'ماذا لو'.",
        },
        {
            name: 'مكافأة الإحالة',
            description:
                'هل لديك أصدقاء؟ احصل على مكافآت! تساعد أصدقاءك في العثور على وظيفة رائعة وتحصل على مكافأة الإحالة.',
        },
        {
            name: 'مجموعة الموظفين',
            description: 'كل ما تحتاجه للتفوق في يوم العمل. من المستلزمات المكتبية الأساسية إلى الأدوات التحفيزية.',
        },
        {
            name: 'ساعات عمل مرنة',
            description: 'مع ساعات العمل المرنة، يمكنك تحقيق التوازن بين المكالمات والاجتماعات ومشاهدة برامجك المفضلة.',
        },
        {
            name: 'إجازة سباتية',
            description: 'إجازة سباتية',
        },
        {
            name: 'إجازة الأبوة / الأمومة',
            description: 'إجازة الأبوة / الأمومة',
        },
        {
            name: 'التوازن بين العمل والحياة',
            description: 'التوازن بين العمل والحياة',
        },
        {
            name: 'احتفالات المكتب',
            description: 'احتفالات المكتب',
        },
    ],
    jobsAtTraceForce: 'وظائف في تريس فورس',
};
export default joinUsPage;
