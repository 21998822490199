const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'Flexible plans that grow with you',
    subFlexiblePlansThatGrowsWithYou: `Choose the pricing plan that meets your business needs.Traceforce offers price packages at optimal prices yet superior value.`,
    pricingPlans: 'Pricing Plans',
    pricingThatWorksForEveryOne: 'Pricing That Works For Everyone',
    monthly: 'Monthly',
    annualy: 'Annualy',
    international: 'International',
    domestic: 'Domestic',
    month: 'Month',
    benifits: 'Benifits',
    allPlanIncludeTheseBenifits: 'All plans include these benifits',
    someBenfitsOfMonthlyYearlySubscription: 'Some Benifits of the monthly/yearly subscription',
    benifitsArr: [
        {
            title: 'Technical Support',
            description: 'Our professional technical support team will assist you every step of the way.',
        },
        {
            title: 'Technology',
            description: 'Special training to get started with the platform from professionals.',
        },
        {
            title: 'Growth Analysis',
            description: 'A dedicated team to provide insights into your monthly growth.',
        },
        {
            title: 'Reward',
            description: 'A special reward for the best-performing account each month.',
        },
    ],
    fAQs: 'Frequently Asked Questions',
    frequentlyAskedQuestions: 'Frequently Asked Questions',
    someBaTypeOfQuestions: 'Here are some common types of questions from our customers',
    fAQArr: [
        {
            question: 'How can I sign up for my firm?',
            ans: 'Yes, the marketplace license allows you to use this theme in any final product. For more information about the license, please check the license terms on the marketplace.',
        },
        {
            question: 'Where can I add my workforce-vehicle?',
            ans: 'No, this is an HTML template. It will not work directly with WordPress, although you can convert it into a WordPress-compatible theme.',
        },
        {
            question: 'How does my workforce log in to Traceforce?',
            ans: 'Send your issues or feedback to our dedicated support email (support@coderthemes.com). We are available at any time to assist you.',
        },
        {
            question: 'Can I track my workforce through mobile?',
            ans: 'Yes, we will regularly update the prompts. All future updates will be available for free.',
        },
        {
            question: 'How to mark attendance?',
            ans: 'Yes, the marketplace license allows you to use this theme in any final product. For more information about the license, please check the license terms on the marketplace.',
        },
        {
            question: 'How to disable developer options?',
            ans: 'No, this is an HTML template. It will not work directly with WordPress, although you can convert it into a WordPress-compatible theme.',
        },
        {
            question: 'What features does Traceforce offer?',
            ans: 'Send your issues or feedback to our dedicated support email (support@coderthemes.com). We are available at any time to assist you.',
        },
        {
            question: 'Do I need to install Traceforce software?',
            ans: 'Yes, we will regularly update the prompts. All future updates will be available for free.',
        },
        {
            question: 'What is the implementation process of Traceforce software?',
            ans: 'Yes, the marketplace license allows you to use this theme in any final product. For more information about the license, please check the license terms on the marketplace.',
        },
        {
            question: 'What training and support options are available with Traceforce software?',
            ans: 'No, this is an HTML template. It will not work directly with WordPress, although you can convert it into a WordPress-compatible theme.',
        },
        {
            question: 'Can Traceforce software be customized according to our specific HR processes?',
            ans: 'Send your issues or feedback to our dedicated support email (support@coderthemes.com). We are available at any time to assist you.',
        },
        {
            question: 'How can HRMS software help with compliance and reporting?',
            ans: 'Yes, we will regularly update the prompts. All future updates will be available for free.',
        },
        {
            question: 'Does HRMS software support remote work and mobile access?',
            ans: 'Yes, we will regularly update the prompts. All future updates will be available for free.',
        },
    ],
};

export default pricingPage;
