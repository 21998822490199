const features = {
    attendanceMonitoring: {
        featureSubcaption: 'ஒவ்வொரு தட்டிலும் திறனாய்வு - ஊழியர் மேலாண்மை முறைமைகளுக்கான புதுமையான தீர்வுகள்',
        featureHeader: 'குழப்பத் கண்காணிப்பு',
        featureDescription:
            'கியோஃபென்ஸ் பரப்பளவுக்குள் அல்லது கிளையுடன் தொடர்புடைய நபர்களின் இருப்பு அல்லது இல்லாதது பற்றிய நேரடிக் குறிப்புகள்',
        featureParagraph:
            'கால்கூட்டுகள், அதிகமான ஆவணங்கள் மற்றும் ஊழியர்களின் நேரத்தைச் சரிபார்க்கப் பல்வேறு ஆவணங்கள் இனி தேவையில்லை. WebWork Time Tracker-ஐப் பயன்படுத்தி, உங்கள் ஊழியர்களின் வேலை நேரங்களை நன்கு அமைக்கப்பட்ட வடிவத்தில் காணலாம்.',
        featureCaption:
            'உங்கள் பகுதி ஊழியர்களின் மற்றும் நிலைத்துள்ள ஊழியர்களின் பல கிளைகளுக்கான வருகையை நிர்வகிக்கவும்',
        keyPoints: [
            {
                header: 'நேரடி வருகை',
                paragraph:
                    'கியோஃபென்ஸ் பரப்பளவுக்குள் அல்லது கிளையுடன் தொடர்புடைய நபர்களின் இருப்பு அல்லது இல்லாதது பற்றிய நேரடி தகவல்',
            },
            {
                header: 'மொத்த வேலை நேரக் கணக்கீடு',
                paragraph: 'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் வேலை நேரத்தை கண்காணிக்கவும்',
            },
            {
                header: 'தொலைவிலிருந்து அணுகல் வருகை அறிக்கைகள்',
                paragraph: 'பல கிளைகளின் வருகை அறிக்கைகளை தொலைவிலிருந்து அணுகவும்',
            },
            {
                header: 'மையமாக்கப்பட்ட வருகை மேலாண்மை',
                paragraph:
                    'வருகை தொடர்புடைய தரவுகளை, செயல்முறைகளை, மற்றும் முறைமைகளை ஒரே மையமாக்கப்பட்ட தளத்தில் அல்லது முறைமையில் ஒன்றாக சேர்க்கவும்',
            },
        ],
        softwareDetails: [
            {
                title: 'ஊழியர் வருகை கண்காணிப்பு பற்றிய தகவல்களைப் பெறவும்',
                description: [
                    'ஊழியர்களின் நேர்மை விளக்கத்தைப் பெறவும்',
                    'அதிகாரி நடவடிக்கையின்றி ஊழியர் வருகையைச் சேமிக்கவும் மற்றும் ஒழுங்குபடுத்தவும்.',
                    'உங்கள் ஊழியர்கள் நேரத்தில் வேலைநிறைவாக வந்து விட்டனர் என்பதை எளிதாக கண்காணிக்கவும்.',
                    'உங்கள் வேலைப்பகுதியில் மேலாண்மையை மேலும் முறையாகவும், மிகவும் பொறுப்பான வேலை சூழலை உருவாக்கவும்.',
                ],
                sDKeys: [
                    'பொதுமை ஊழியர்களுக்கான ஜியோஃபென்ஸ் எல்லையை அமைத்துக்கொள்ளவும்.',
                    'வருகை மற்றும் வருகை மறந்து விட்ட ஊழியர்களுக்கான அறிவுறுத்தல் எச்சரிக்கைகளைப் பெறவும்.',
                    'உங்கள் மையப்புலத்தில் உள்நாட்டுக் கணக்குகளைத் தொகுக்கவும்.',
                ],
            },
            {
                title: 'விபரமான வருகை கண்காணிப்பு அறிக்கைகளைப் பெறவும்',
                description: [
                    'வருகை தரவுகளின் நுண்ணறிவு பகுப்பாய்வு',
                    'முந்தைய வருகை விவரங்களை எளிதாக உருவாக்கவும், தேடுதல் தேவை இல்லை.',
                    'தினசரி அல்லது மாதாந்திர நேரவெளிகளைப் தேர்வுசெய்து வருகையைக் கண்காணிக்கவும்.',
                ],
                sDKeys: [
                    'உங்கள் நிறுவனத்திற்கான வருகை சுருக்கங்களைப் பெறவும்.',
                    'உங்கள் குழு, துறைகள் அல்லது கிளைகளின் தங்கல்களைப் பார்வையிட அறிக்கைகளை உருவாக்கவும்.',
                    'அறிக்கைகள் மின்னஞ்சலில் பெறவும் (நேரங்கள், வருகை, மொத்த வேலை நேரம், மொத்த அமர்வுகள்)',
                    'சிறந்த தீர்மானங்களுக்கான நுண்ணறிவு தரவுகள்.',
                ],
            },
            {
                title: 'மற்ற நேரம் மற்றும் உற்பத்தித் தன்மைகளைப் ஆராயவும்',
                description: [
                    '100% தொடுவாய்ப்பு முறைமையுடன் நேரடியாக வருகையை கண்காணிக்கவும்.',
                    'எளிதான அமைப்பு',
                    'தானாகவே பதிவுசெய்யும் வருகை மற்றும் வருகை விவரங்கள்',
                    'நேரத்திற்கு பிடித்த திரைச்சீட்டுகள்',
                    'செயல்திறன் அளவீடுகள்',
                    'ஆப்ஸ் மற்றும் வலைத்தளப் பயன்பாட்டைப் கண்காணிக்கவும்',
                ],
                sDKeys: [
                    'ஊழியரின் நேரத்தை நிர்வகிக்கவும் மற்றும் உற்பத்தியை மேம்படுத்தவும்.',
                    'ஆவணங்களை நீக்கி, நம்பகமான ஊழியர் வருகை பதிவுகளைச் சேகரிக்கவும்.',
                ],
            },
        ],
    },
    leaveManagement: {
        featureHeader: 'விடுப்பு மேலாண்மை',
        feaatureSubcaption:
            'ஊழியர்களின் விடுப்புகளை ஒருங்கிணைத்து, உற்பத்தி அளவுகள் மற்றும் செயல்பாட்டு தொடர்ந்து பராமரிக்கவும்.',
        featureDescription:
            'விடுப்புகள் மற்றும் வருகைகளை திறம்பட கையாளவும், சரியான ஆவணங்களை உறுதி செய்யவும், நிறுவன விதிகளைப் பின்பற்றவும், மற்றும் சீரான வேலைப்போக்கை தொடர்ந்திருப்பதை உறுதிப்படுத்தவும்.',
        featureParagraph:
            'கால்கூட்டுகள், அதிகமான ஆவணங்கள் மற்றும் ஊழியர்களின் நேரத்தைச் சரிபார்க்கப் பல்வேறு ஆவணங்கள் இனி தேவையில்லை. WebWork Time Tracker-ஐப் பயன்படுத்தி, உங்கள் ஊழியர்களின் வேலை நேரங்களை நன்கு அமைக்கப்பட்ட வடிவத்தில் காணலாம்.',
        featureCaption:
            'உங்கள் ஊழியர்களின் வருங்கால விடுப்புகள் பற்றிய தகவல்களை முன்னதாகவே பெறுங்கள் மற்றும் உங்கள் நாளை accordingly திட்டமிடுங்கள்',
        keyPoints: [
            {
                header: 'விடுப்பு சேர்க்கைகள் மற்றும் சமநிலைகளை தானாகக் கணக்கிடல்',
                paragraph:
                    'அவையம்சங்களை நிர்வகிக்கவும், ஊழியர்கள் ஒதுக்கப்பட்ட அளவுகளை மீறாதபடி உறுதிப்படுத்தவும், மற்றும் எதிர்மறை விடுப்பு சமநிலைகளைத் தவிர்க்கவும்.',
            },
            {
                header: 'விளைவான விடுப்பு கொள்கைகள், அங்கீகார வேலைப்போற்கள்',
                paragraph:
                    'அமைப்பின் குறுக்குவட்டப் கொள்கைகளை அமைக்கவும். விடுப்பு கோரிக்கைகளுக்கான அங்கீகாரம் அல்லது நிராகரிப்பு மெட்ரிக்களைத் தொடுக்கவும்.',
            },
            {
                header: 'மேலாண்மை சட்டங்களுக்கும் நிறுவன கொள்கைகளுக்கும் ஏற்பாடு',
                paragraph:
                    'விடுப்பு உரிமைகள் தொடர்பான வேலை சட்டங்கள் மற்றும் விதிமுறைகளைப் பற்றிய தகவல்களைப் பெறுங்கள். உங்கள் விடுப்பு மேலாண்மை கொள்கைகள் நிறுவன கொள்கைகளுக்கு ஏற்படுத்தப்பட்டுள்ளதா என்பதை உறுதிப்படுத்தவும்.',
            },
            {
                header: 'சுய-சேவை விருப்பங்கள்',
                paragraph:
                    'ஊழியர்களுக்கு தங்களை நேரிடையாக செக்கிட, விண்ணப்பங்களை சமர்ப்பிக்க மற்றும் தங்கள் விண்ணப்பங்களின் நிலையைப் பார்க்கவோ அதிகாரம் கொடுக்கவும்.',
            },
        ],
        softwareDetails: [
            {
                title: 'உங்கள் குழுவிற்கான பலவகையான மற்றும் முறையான விடுப்பு கொள்கைகள்',
                description: [
                    'உங்கள் தேவைகளைப் பூர்த்தி செய்யக் கூடிய அளவையற்ற விடுப்பு கொள்கைகள்',
                    'விவரமான ஊழியர் குழுக்களுக்கு ஏற்ப பலவகையான விடுப்பு திட்டங்கள் மற்றும் கொள்கைகள்',
                    'அளவையற்ற விடுப்பு வகைகள் மற்றும் விடுப்பு பரிமாற்றங்களை ஆதரிக்கிறது',
                ],
                sDKeys: [
                    'இந்த கொள்கைகள் ஆண்டு விடுப்பு, நோய்நிலை விடுப்பு, பெற்றோர்கள் விடுப்பு, துயரமுற்றவர் விடுப்பு போன்ற விடுப்பு வகைகளை உள்ளடக்குகிறது.',
                    'கொள்கைகளுக்கான தகுதி அடிப்படைகளை அமைக்கவும்',
                    'சேர்க்கை விகிதங்கள், பயன்பாட்டு விதிகள், தேவைப்படும் ஆவணங்கள்',
                ],
            },
            {
                title: 'விடுப்பு விண்ணப்பம் மற்றும் அங்கீகார வேலைப்போற்க்கான சுய சேவை',
                description: [
                    'ஆட்டோமேஷன் ஊழியர்களின் முயற்சிகளைக் குறைக்கிறது. ஊழியர்கள் இணையதளப் போர்டல் அல்லது மொபைல் ஆப் மூலம் விடுப்புக்காக விண்ணப்பிக்கிறார்கள்.',
                    'விடுப்பு சமநிலைகள் மற்றும் பரிமாற்றங்களை கண்காணிக்க விடுப்பு tracker',
                    'மேலாளரின் குழு விடுப்பு தரவுகள் மற்றும் விடுப்பு மாதிரிகளை மேம்படுத்துவதற்கான காட்சி',
                    'பரிசோதனைகள் உடன் வேலைப்போற்கள் ஒரு பின்விளைவான முறைமையை மற்றும் ஒரு சிறந்த ஊழியர் அனுபவத்தை உருவாக்குகின்றன',
                    'மின்னஞ்சல் அறிவுறுத்தல்கள் மற்றும் எச்சரிக்கைகள்',
                ],
                sDKeys: [
                    'ஊழியர்கள் இணையதளப் போர்டல் அல்லது மொபைல் ஆப் மூலம் விடுப்புக்காக விண்ணப்பிக்கிறார்கள்',
                    'விடுப்பு சமநிலைகள் மற்றும் பரிமாற்றங்களை கண்காணிக்க விடுப்பு tracker',
                    'மேலாளரின் குழு விடுப்பு தரவுகள் மற்றும் விடுப்பு மாதிரிகளை மேம்படுத்துவதற்கான காட்சி',
                    'பரிசோதனைகள் உடன் வேலைப்போற்கள் ஒரு பின்விளைவான முறைமையை மற்றும் ஒரு சிறந்த ஊழியர் அனுபவத்தை உருவாக்குகின்றன',
                    'மின்னஞ்சல் அறிவுறுத்தல்கள் மற்றும் எச்சரிக்கைகள்',
                ],
            },
            {
                title: 'நிறுவன கொள்கைகள் மற்றும் வேலை சட்டங்களுக்கு இணங்குங்கள்',
                description: [
                    'தன்னிறுவன விடுப்பு மேலாண்மை வேலை சட்டங்களுக்கு இணக்கமாக இருப்பதையும், நல்ல வேலை சூழல் கலாச்சாரத்திற்கு ஒத்துழைபடும் முறையை ஏற்படுத்துகிறது. தொழில்நுட்பத்தைப் பயன்படுத்தி, நுட்பமான விடுப்பு கொள்கைகளை நடைமுறைப்படுத்துவதன் மூலம், அமைப்புகள் செயல்முறையை எளிதாக்கி, ஊழியர்களின் விடுப்பு தேவைகளை ஆதரிக்க முடியும்.',
                ],
                sDKeys: [
                    'பட்ஜெட்டைச் சரியாக நிர்வகிக்கவும் மற்றும் நிதி ஆபத்துகளைக் குறைக்கவும் விடுப்பு தகுதி மேலாண்மையைச் செயல்படுத்தவும்',
                    'மாதாந்திர, காலாண்டு அல்லது ஆண்டு அடிப்படையில் செயல்திறனைப் கண்காணிக்க தனிப்பட்ட அறிக்கைகளுக்கான புள்ளிவிபரங்களை உருவாக்கவும்',
                    'ஊழியர் திட்டம், வளங்கள் ஒதுக்கீடு மற்றும் ஊழியர் ஈடுபாடு யோசனைகளைப் பற்றிய தகவல்களைப் பெறுங்கள்',
                    'அரசாங்க விதிமுறைகளுக்கு ஏற்ப விரிவான பதிவுகள் மற்றும் அறிக்கைகள்',
                    'பல்வேறு ஊழியர்களுக்கான தனிப்பயனாக்கப்பட்ட விடுப்புப் பட்டியல்',
                ],
            },
        ],
    },
    shiftManagement: {
        featureHeader: 'மேலாண்மை நேரங்கள்',
        feaatureSubcaption:
            'விற்பனைச் செயல்பாடுகளுடன் கூடிய வேலைக்காரர்களின் அட்டவணையை திட்டமிடுங்கள், வாடிக்கையாளர் தேவைகளை பூர்த்தி செய்ய அதிகபட்ச உற்பத்தியை உறுதிப்படுத்துங்கள்.',
        featureDescription:
            'உற்பத்தியை மேம்படுத்தும், செயல்பாட்டு தேவைகளை பூர்த்தி செய்யும், மற்றும் பல்வேறு நேர பகுதிகளில் திறந்தவழக்கத்தை உறுதிப்படுத்தும் வேலை நேரங்களை ஒழுங்குபடுத்தவும், திட்டமிடவும்.',
        featureParagraph:
            'கால்கூட்டுகள், அதிகமான ஆவணங்கள் மற்றும் ஊழியர்களின் நேரத்தைச் சரிபார்க்கப் பல்வேறு ஆவணங்கள் இனி தேவையில்லை. WebWork Time Tracker-ஐப் பயன்படுத்தி, உங்கள் ஊழியர்களின் வேலை நேரங்களை நன்கு அமைக்கப்பட்ட வடிவத்தில் காணலாம்.',
        featureCaption:
            'மொத்த வேலை நேரங்கள் மற்றும் பல்வேறு அம்சங்களின் அடிப்படையில் உங்கள் ஊழியர்களின் நேரத்தை கண்காணியுங்கள்',
        keyPoints: [
            {
                header: 'ஊழியர்களின் வேலை அட்டவணைகளை நிர்வகிக்கவும்',
                paragraph:
                    'தெளிவாகவும் நன்கு அமைக்கப்பட்ட shift அட்டவணையை உருவாக்கவும். உங்கள் தொழிலின் இயல்புக்கு ஏற்ப பலவிதமான நேரங்களை வரையறுக்கவும்.',
            },
            {
                header: 'நேரங்களை வரையறுக்கவும், வேலையை ஒதுக்கவும், அசலான அல்லது தனிப்பயனாக்கப்பட்ட அட்டவணைகளை அமைக்கவும்',
                paragraph:
                    'பன்முகத்தன்மையைப் பரிசீலித்து, பல்வேறு நேரங்களில் எவ்வளவு ஊழியர்கள் தேவை என்பதை நிர்ணயிக்கவும்.',
            },
            {
                header: 'அடங்கிய தொடர்பு கருவிகள்',
                paragraph:
                    'ஊழியர்கள் தங்கள் அட்டவணைகளை அணுகுவதற்கான மைய இடைமுகம் மற்றும் ஏதேனும் புதுப்பிப்புகள் அல்லது மாற்றங்களுக்கு நம்பகமான தொடர்பு சேனலை உருவாக்கவும்.',
            },
            {
                header: 'பல இடங்கள் மற்றும் இடங்கள் அட்டவணைகளுடன் மையமாக்கப்பட்ட நேர மேலாண்மை',
                paragraph:
                    'ஊழியர்களின் அட்டவணைகளின் மைய மேலாண்மை மற்றும் கட்டுப்பாடு, மேலாளர்களுக்கு மைய இடைமுகத்தில் இருந்து பல இடங்களில் நேரங்களை திறம்பட ஒதுக்க, வருகையை கண்காணிக்க மற்றும் வேலைக்காரர்கள் வளங்களை நிர்வகிக்க அனுமதிக்கின்றது.',
            },
        ],
        softwareDetails: [
            {
                title: 'இடம் மற்றும் நேரத்தை அடிப்படையாகக் கொண்ட திட்டமிடல் செயல்முறையை ஆட்டோமேட் செய்யவும்',
                description: [
                    'பன்முகத்தன்மையைப் பரிசீலித்து, பல்வேறு நேரங்களில் எவ்வளவு ஊழியர்கள் தேவை என்பதை நிர்ணயிக்கவும்.',
                    'மேலாண்மை நேரங்கள், பருவ வேறுபாடுகள் மற்றும் ஒவ்வொரு நேரத்திற்கும் சிறப்பு தேவைகளைப் பரிசீலிக்கவும். இது நாள் நேரங்கள், இரவு நேரங்கள், திருப்பமோடு நேரங்கள், பிளவுகளோடு நேரங்கள் அல்லது பொருளாதார அட்டவணைகளை உள்ளடக்கலாம்.',
                ],
                sDKeys: [
                    'ஊழியர்கள் இடையே நேர மாற்றங்களை ஒத்துக்கொள்',
                    'ஊழியர்கள் தங்கள் நேரங்களை மற்றும் நேரத்திலிருந்து மாற்றத்தை மாற்றலாம்',
                    'ஊழியர்கள் தங்களுக்கு சிறந்த நேரங்களை மற்றும் விடுப்புகளைத் தேர்வு செய்யலாம்',
                ],
            },
            {
                title: 'உருவாக விற்கும் அட்டவணை வடிவமைப்பு',
                description: [
                    'வேலை நேரங்களுக்கான breaks மற்றும் rest காலங்களுக்கு வேலைசெய்திய சட்டங்களுடன் இணக்கமாக இருப்பதை உறுதிப்படுத்துங்கள்.',
                    'ஊழியர்களின் சோர்வைத் தடுக்கும் மற்றும் உற்பத்தியைப் பராமரிக்க breaks-ஐ நேர அட்டவணையில் சேர்க்கவும்.',
                    'உங்கள் நேர மேலாண்மை கொள்கைகள் சட்ட தேவைகளுடன் இணங்குவதை உறுதிப்படுத்துங்கள்.',
                ],
                sDKeys: [
                    'பயனர் நட்பான திட்டமிடல் கருவிகளைப் பயன்படுத்தி உங்கள் குழுவிற்கான திறமையான வேலை அட்டவணைகளை எளிதாக உருவாக்குங்கள்.',
                    'உங்கள் தொழிலின் செயல்திறனை மற்றும் ஊழியர்களின் கிடைக்கும் நேரத்தைச் பொருந்தும் வகையில் நேரங்களை தனிப்பயனாக்கவும்.',
                    'ஊழியர்களின் விருப்பங்கள் மற்றும் வேலைப்பளு கவனத்தில் கொள்ளும் போது நேரங்களை சமமாகப் பகிர்ந்திடுங்கள்.',
                ],
            },
            {
                title: 'விரும்பத்தக்க நேரங்களைத் வேலை செய்ய ஊழியர்களைக் ஊக்குவிக்கவும்',
                description: [
                    'நேர மேலாண்மை அமைப்புகளை சம்பள செயல்முறைகளுடன் ஒருங்கிணைத்து, நேரங்களின் அடிப்படையில் சரியான வெகுமதி மற்றும் கழிவுகளை உறுதிப்படுத்துங்கள்.',
                    'ஓவர் நேரத்திற்கு முறைகளை வரையறுக்கவும் மற்றும் ஓவர் நேர கொள்கைகள் வேலை சட்டங்களுடன் இணங்குமாறு உறுதிப்படுத்துங்கள்.',
                ],
                sDKeys: [
                    'ஊழியர்களின் நேர கோரிக்கைகளை ஒரு கிளிக்கில் அங்கீகரிக்கவும்.',
                    'ஊழியர்கள் வாரம்/மாதம் தொடக்கத்தில் தங்கள் நேரங்களைத் தேர்வுசெய்யலாம் மற்றும் தங்கள் போர்டல் மூலம் அனுமதிகளைச் சமர்ப்பிக்கலாம்.',
                    'மணிக்கு சம்பள விகிதம் அல்லது நிறுவன கொள்கையின் அடிப்படையில் நேர வெகுமதிகளை வழங்கும் பயனுள்ள தீர்வு.',
                ],
            },
        ],
    },
    geoFenceBasedManagement: {
        featureHeader: 'ஜியோ ஃபென்ஸ் அடிப்படையிலான மானியத்தை கண்காணிப்பு',
        feaatureSubcaption:
            'துல்லியத்தை மற்றும் பொறுப்பை அதிகரிக்க, ஊழியர்களின் இருப்பை நியமிக்கப்பட்ட எல்லைகளை கொண்டு கண்காணிக்கவும்',
        featureDescription:
            'முன்னதாக வரையறுக்கப்பட்ட புவியியல் எல்லைகளுக்குள் ஊழியர்களின் இருப்பை கண்காணிக்க மற்றும் சான்றளிக்க இடம் அடிப்படையிலான தொழில்நுட்பத்தைப் பயன்படுத்துங்கள்',
        featureCaption:
            'ஜியோ ஃபென்ஸ் அடிப்படையிலான மானியம், ஊழியர்கள் நியமிக்கப்பட்ட இடத்தில் இருக்கும்போது மட்டும் முன்னிலை பெறுவதை உங்களுக்கு உறுதிப்படுத்துகிறது',
        keyPoints: [
            {
                header: 'நியமிக்கப்பட்ட ஜியோ-பென்ஸ் உள்ள பரப்பில் ஊழியர்களை துல்லியமாகக் கண்டறியவும்',
                paragraph: 'நியமிக்கப்பட்ட வேலை இடங்களில் ஊழியர்கள் உள்ளாரா என்பதை உறுதி செய்ய சரியான மானிய பதிவுகள்',
            },
            {
                header: 'ஊழியர்களின் இடங்களை நேரடி கண்காணிப்பு',
                paragraph: 'ஜியோ-பென்ஸ் உள்ள ஊழியர்களின் இடங்களை கண்காணிக்க நேரடி கண்காணிப்பை செயல்படுத்தவும்',
            },
            {
                header: 'க்ளாகிங் இன்ச் அல்லது அவுட்டிற்கான குறிப்பிட்ட புவியியல் எல்லைகளை வரையறுக்கவும்',
                paragraph:
                    'வேலை இடம் அல்லது நியமிக்கப்பட்ட மானிய இடங்களைச் சுற்றிய புவியியல் எல்லைகளை, ஜியோ-பென்ஸ் என்று அழைக்கப்படுகிறது, அமைக்கவும்',
            },
            {
                header: 'ஜியோ-பென்ஸ் எச்சரிக்கைகள்',
                paragraph:
                    'ஊழியர்கள் வரையறுக்கப்பட்ட ஜியோ-பென்ஸ் எல்லைகளை நுழைக்கும்போது அல்லது விலகும்போது மேலாளர்கள் அல்லது HR நபர்களுக்கு தகவல்களை அளிக்க எச்சரிக்கைகள் அல்லது அறிவிப்புகளை அமைக்கவும்',
            },
        ],
        softwareDetails: [
            {
                title: 'அடிப்படையிலான இடப் பிணைப்பை அமைக்கவும்',
                description: [
                    'ஜியோ-பென்ஸ் அட்டவணைகளை அமைக்கும் போது GPS இணைப்புகளை அல்லது இடம் அடிப்படையிலான தொழில்நுட்பங்களைப் பயன்படுத்தி குறிப்பிட்ட புவியியல் எல்லைகளை நிறுவவும். இவை ஊழியர்கள் வேலை செய்ய வேண்டும் என்று எதிர்பார்க்கப்படும் பகுதிகளைச் குறிக்கின்றன.',
                ],
                sDKeys: [
                    'அறிக்கையிடப்பட்ட இடத்தின் அருகில் மானியத்தை உறுதிப்படுத்த கையாளப்படும் ஐபி முகவரியில் மானியத்தை அனுமதிக்க விருப்பம்.',
                    'Push Notifications வசதியைப் பயன்படுத்தி குறிப்பிட்ட அல்லது அனைத்து பயனர்களுடன் தொடர்பு கொள்ளுதல் எளிதாகும்.',
                    'தினசரி வேலை மற்றும் அட்டவணைகளைப் பார்க்க ஊழியர்களுடன் பணிகளை இணைக்கவும்.',
                ],
            },
            {
                title: 'அடிப்படையிலான மானிய மற்றும் உயிரணு அங்கீகாரம் அமைக்கவும்',
                description: [
                    'ஜியோ-பென்ஸ் உள்ள இடங்களில் ஊழியர்களின் இடங்களை கண்காணிக்க நேரடி இட கண்காணிப்பை செயல்படுத்துதல். இந்த தகவல் மானியத்தை உறுதிப்படுத்த மற்றும் ஊழியர்கள் நியமிக்கப்பட்ட வேலை இடங்களில் தங்களுக்கான நேரங்களில் இருப்பதை உறுதிப்படுத்த பயன்படுத்தப்படுகிறது.',
                ],
                sDKeys: [
                    'ஊழியர்கள் தங்களது முதல் பணியிடம் வந்த பிறகே தங்கள் மானியத்தை மட்டுமே பதிவு செய்யலாம்.',
                    'ஊழியர்கள் தொலைவிலிருந்து தங்கள் மானியத்தை பதிவு செய்யலாம்; மானியத்தைப் பதிவு செய்யக் காரியமான மானியம் அல்லது ஒவ்வொரு நாளும் அலுவலகத்திற்கு பயணம் செய்ய தேவையில்லை.',
                    'மேலாளர்கள் நேரடி இடத்தைப் கண்காணித்து, தொலைபேசி செயலிகள் அல்லது வலைதளத்தின் மூலம் புதுப்பிப்புகளைப் பெறலாம்.',
                ],
            },
            {
                title: 'பாதுகாப்பு மற்றும் அங்கீகாரத்தை மேம்படுத்தவும்',
                description: [
                    'மோசடி மானிய உள்வாங்கல்களைத் தடுக்கும் வகையில் PIN குறியீடுகள், உயிரணு அங்கீகாரம் மற்றும் முகவியல் அங்கீகாரங்களைப் பயன்படுத்தி பாதுகாப்பான அங்கீகாரத்தை செயல்படுத்துங்கள்.',
                    'ஜியோ-பென்ஸ் பகுதிக்கு நுழைக்கும்போது ஊழியர்களைச் சரிபார்க்க அவர்களுக்கு மொபைல் அறிவிப்புகள் அல்லது எச்சரிக்கைகளை அமைக்கவும். இது ஊழியர்கள் மானிய கண்காணிப்பு முறையைப் பற்றி அறிந்திருப்பதை உறுதிப்படுத்துகிறது.',
                ],
            },
        ],
    },
    multipleClockInOptions: {
        featureHeader: 'பல நேர அட்டவணை விருப்பங்கள்',
        feaatureSubcaption:
            'நிறுவனங்களுக்கு வசதியான மற்றும் சுலபமான முறையை வழங்குகிறது, பல நேர அட்டவணை விருப்பங்கள் ஊழியர்களுக்கு நேரத்தை திறமையாக நிர்வகிக்க ஆற்றல்களை வழங்குகிறது.',
        featureDescription:
            'ஊழியர்களுக்கு உயிரணு ஸ்கேனர்கள், மொபைல் செயலிகள், நேரக் காப்பாளர்கள் மற்றும் வலை அடிப்படையிலான மாடல்களைப் பயன்படுத்தி தங்கள் வேலை நேரங்களைப் பதிவு செய்ய வசதியை வழங்குங்கள்.',
        featureCaption: 'தற்காலிகமான அல்லது பல்வேறு நேரத்தில் வேலை செய்யும் ஊழியர்களுக்கு உபயோகத்தை எளிதாக்குங்கள்.',
        keyPoints: [
            {
                header: 'பல இடங்களுடன் நிறுவனங்களுக்கு ஏற்ப',
                paragraph: 'பல்வேறு கிளைகளின் வேலை மற்றும் வெளிநாட்டு மானியத்தை நிர்வகிக்க எலக்டிக் நகல் விருப்பங்கள்.',
            },
            {
                header: 'ஊழியர்களுக்கான சுய-சேவையின் போர்டல்',
                paragraph:
                    'ஜியோ-பென்ஸ் உள்ளே அவர்களின் மானிய பதிவுகளுக்குப் புகுபதிகை செய்ய மற்றும் உள்ளே மற்றும் வெளியே வரவிரும்பும் போது மானியங்களை நிர்வகிக்க ஊழியர்களுக்கு அணுகலை வழங்குங்கள்.',
            },
            {
                header: 'மல்டிபிள் நேர அட்டவணை விருப்பங்கள்',
                paragraph:
                    'உயிரணு ஸ்கேன்கள், மொபைல் செயலிகள், RFID கார்டுகள் மற்றும் முகப்பரிசோதனை உள்ளிட்ட பல்வேறு நேர அட்டவணை விருப்பங்களை வழங்குகிறது, இது பல்வேறு வேலை சூழல்களில் இடைநீக்கம் இல்லாமல் நேரத்தை கண்காணிக்க உதவுகிறது.',
            },
            {
                header: 'துல்லியமான புவியியல் அடிப்படையிலான நேர அட்டவணை',
                paragraph:
                    'அலுவலகத்தின் வரம்புக்குள் உள்ள ஊழியர்களுக்கான தானாகவே நேரம் குறியீட்டு மானியம் மற்றும் எச்சரிக்கையை வழங்குகிறது.',
            },
        ],
        softwareDetails: [
            {
                title: 'இடத்தில் மற்றும் இடத்திற்கு மாறுபட்ட நேர அட்டவணைகள்',
                description: [
                    'பல்வேறு வேலை சூழல்களுக்கு ஏற்ப, எங்கள் ஊழியர் மானியம் நிர்வகிப்பு முறை பல்வேறு நேர அட்டவணை விருப்பங்களை வழங்குகிறது.',
                    'ஊழியர்கள் இடத்தில், வெளியில், அல்லது தொலைவிலிருந்தும் உள்ளார்கள் என்றாலும், அவர்கள் எளிதாக தங்கள் நேரத்தை உயிரணு ஸ்கேன்கள், முகப்பரிசோதனை மற்றும் ஜியோ-பென்ஸ் அடிப்படையிலான தானாகவே நேர அட்டவணை மூலம் பதிவு செய்ய முடியும்.',
                ],
            },
            {
                title: 'ஊழியர்களுக்கான சுய சேவை போர்டல்',
                description: [
                    'ஊழியர்களுக்கான சுய சேவையின் போர்டல் பல்வேறு மானியங்களை பதிவு செய்ய மற்றும் வெளியே செல்ல முழு கட்டுப்பாட்டை வழங்குகிறது.',
                    'பயனர் நட்பான அம்சங்களுடன், ஊழியர்கள் மாறும் நேரம், இடங்கள், அல்லது பணிகள் இடையேயான மாறுதல்களை எளிதாக நிர்வகிக்க முடியும்.',
                    'இது ஊழியர்களுக்கு தக்க நேர பதிவுகளைப் பாதுகாப்பதுடன், திறமையை அதிகரிக்கவும், நிர்வாக செயல்முறைகளை எளிதாக்கவும் உதவுகிறது.',
                ],
            },
            {
                title: 'பல கிளைகளுக்கான பல்வேறு நேர அட்டவணைகள்',
                description: [
                    'பல இடங்களில் பரவலாக உள்ள உங்கள் நிறுவனத்தின் மாற்றக்கூடிய தேவைகளைப் பொருந்தும் மற்றும் துல்லியமான மானிய கண்காணிப்பு மற்றும் செயல்திறனை உறுதிப்படுத்துகிறது.',
                ],
            },
        ],
    },
    attendanceReport: {
        featureHeader: 'பங்கேற்பு அறிக்கைகள்',
        feaatureSubcaption:
            'ஒரே அறிக்கையில் அணியினரின் முயற்சிகள் மற்றும் நேர்மையைப் புலப்படுத்தும் தகவலான ஒரு சீரான காட்சி.',
        featureDescription:
            'உரிய காலப்பகுதியில் ஊழியர்களின் பங்கேற்பு தரவுகள், வேலை நேரங்கள், அகல்நிலை, பின்னணி வருகைகள், மற்றும் முன்னணி பிரேக்குகளை சுருக்கமாக வழங்குங்கள்.',
        featureCaption:
            'வித்யாசமான கிளைகளுக்கான தானாக உருவாக்கப்படும் அறிக்கைகளை தினசரி, வாராந்திர, மாதாந்திர அடிப்படையில் 40+ மாதிரிகள் மூலம் பெறுங்கள்.',
        keyPoints: [
            {
                header: 'தானாகவே பங்கேற்பு விவரங்கள்: பதிவு நேரம், தாமத நேரம், மொத்த வேலை நேரம், மொத்த அமர்வுகள்',
                paragraph:
                    'வேலை நாட்கள், மொத்த வேலை நேரம், மற்றும் எந்தவொரு கூடுதல் நேரத்தையும் உள்ளடக்கிய பங்கேற்பின் சுருக்கமான கணக்கீட்டை வழங்குங்கள்.',
            },
            {
                header: 'பல கிளைகளுக்கான அறிக்கைகள்',
                paragraph:
                    'அனுப்பதற்குரிய வகைகள் மற்றும் அளவைகள் கொண்ட பல கிளை பங்கேற்பு அறிக்கைகளை தொலைவில் அணுகுங்கள்.',
            },
            {
                header: 'அதிக நேரம் மற்றும் விடுமுறை இருப்புகள்',
                paragraph:
                    'ஒவ்வொரு ஊழியருக்கும் விடுமுறை இருப்புகளைப் காட்சிபடுத்துங்கள், விடுமுறை நாட்கள், நோய் விடுமுறை, மற்றும் பயன்படுத்தப்பட்ட பிற விடுமுறை வகைகள் உட்பட.',
            },
            {
                header: 'அறிக்கையை தனிப்பயனாக்குங்கள்',
                paragraph:
                    'துறை, குழு, அல்லது தனிப்பட்ட ஊழியர் போன்ற குறிப்பிட்ட அளவைகளின் அடிப்படையில் அறிக்கைகளை தனிப்பயனாக்க அனுமதிக்கவும்.',
            },
        ],
        softwareDetails: [
            {
                title: 'உங்கள் நிறுவன அமைப்பின் அடிப்படையில் அறிக்கைகளை உருவாக்குங்கள்.',
                description: [
                    'ப regelmäßமாக உருவாக்கப்படும் மற்றும் பகுப்பாய்வு செய்யப்பட்ட பங்கேற்பு அறிக்கைகள் வேலைபோக நிர்வாகத்தை மேம்படுத்த, வழக்கங்களை அடையாளம் காட்டு, மற்றும் நிறுவன கொள்கைகளுடன் ஒத்துக்கொள்ளும் வகையில் மதிப்பீடுகளை வழங்கலாம்.',
                    'அறிக்கையின் விவரங்கள் நிறுவனத்தின் தேவைகள் மற்றும் அமைப்பின் அடிப்படையில் மாறுபடும்.',
                ],
                sDKeys: [
                    'விளக்கமான விவரங்கள் மற்றும் மானியங்கள் தானாகவே உருவாக்கப்படுவதன் மூலம், அறிக்கைகளை கைவண்ணமாக உருவாக்குவதற்கு செலவிடும் நேரம் மற்றும் வளங்களை குறைத்துவிடலாம்.',
                    'பயனர்கள் சில கிளிக் கள் மூலம் தேவையான பகுப்பாய்வுகளைத் தேர்ந்தெடுக்கலாம் மற்றும் எப்போது வேண்டுமானாலும் சந்தாதாரராக ஆகலாம்.',
                    'எடுத்துக்காட்டு பகுப்பாய்வு அறிக்கையைப் பதிவிறக்கம் செய்து, விரும்பிய அறிக்கையின் செயற்கை பிரதிபலிப்பாகக் காணலாம்.',
                ],
            },
            {
                title: 'எளிதாக தனிப்பயனாக்கப்படும் அறிக்கைகளைப் பெறுங்கள்.',
                description: [
                    'அனுமதியற்ற முறைவிலக்குகளை, அதிக தாமதங்களை, அல்லது அனுமதியற்ற கூடுதல் நேரங்களின் போது எத்தனை மாற்றங்களைச் சிறப்பித்து வெளிப்படுத்தவும்.',
                    'பங்கேற்பு வழக்கங்கள் மற்றும் முறைகளை மெய்நிகர்படுத்தும் காட்சிகள் அல்லது கட்டடங்களைப் பயன்படுத்துங்கள். இது குறுக்குகள் மற்றும் வழக்கங்களை எளிதாக அடையாளம் காண உதவும்.',
                ],
                sDKeys: [
                    'பணபுழக்கம், செயல்திறனைப் மதிப்பீடு செய்வது மற்றும் மறு உண்டியல்களில் பயன்படுத்துவதற்கான தரவுத் தகவல்களைப் பெறலாம்.',
                    'அறிக்கைகளை அதிகார வரிசையின் அடிப்படையில் பிற பயனர்களுடன் பகிரலாம்.',
                    'கம்பனி தங்களுடைய குழுவின் வேலை நிலை மற்றும் செயல்திறனை எளிதாகப் பெறலாம், இது எந்தவொரு கையால் செய்தல் தேவையில்லாமல்.',
                ],
            },
            {
                title: 'கைமுறையாக அறிக்கைகளை உருவாக்கும் நேரத்தை குறைக்கவும்.',
                description: [
                    'ஊழியர்களின் பெயர்கள், அடையாள எண்கள் மற்றும் துறை தொடர்புகளை உள்ளடக்கிய அடிப்படை விவரங்களை அடையாளம் காணுங்கள்.',
                    'அறிக்கையில் உள்ள தேதி மற்றும் நேர வரம்புகளை தெளிவாகக் குறிப்பிட்டு, தரவின் காலப்பகுதியைப் புரிந்துகொள்ள உதவுங்கள்.',
                    'வேலை நாட்கள், மொத்த வேலை நேரம், மற்றும் கூடுதல் நேரங்களைப் உள்ளடக்கிய பங்கேற்பின் முழுமையான சுருக்கத்தை வழங்குங்கள்.',
                ],
            },
        ],
    },
    workforceTracking: {
        featureHeader: 'பணியாளர் கண்காணிப்பு',
        featureDescription:
            'ஊழியர்களின் செயற்பாடுகள், செயல்திறன் மற்றும் பங்கேற்பை முறையாக கண்காணித்து மேலாண்மை செய்யவும்.',
        featureParagraph:
            'ஆய்வுக்கோப்புகள், அதிகமான ஆவணங்கள் மற்றும் பல்வேறு ஆவணங்களைப் பார்க்கும் தேவையில்லை. WebWork Time Tracker உடன் பங்கேற்பு கண்காணிப்பு செயல்முறையை தானாகவே செய்யுங்கள் மற்றும் உங்கள் ஊழியர்களின் வேலை நேரங்களை நன்றாக வடிவமைக்கப்பட்ட வடிவத்தில் பாருங்கள்.',
        featureCaption:
            'ஒரு அமைப்பின் பணி பொறுப்புகளைப் பல்வேறு பரிமாணங்களில், ஊழியர்களின் பங்கேற்பு, வேலை நேரங்கள், செயல்திறன் மற்றும் செயல்திறனை கண்காணிக்கவும் மற்றும் மேலாண்மை செய்யவும்.',
        keyPoints: [
            {
                header: 'நேர அடிப்படையிலான பங்கேற்பு மற்றும் நகர்வு கண்காணிப்பு',
                paragraph:
                    'பதிவு செய்யும் மற்றும் வெளியேறும் நிகழ்வுகளை கண்காணிக்கவும் மற்றும் ஊழியர்களின் செயல்பாடுகள், செயல்திறன் அளவீடுகள் அல்லது நிகழ்வுகள் பற்றிய கண்ணோட்டங்களைப் பெறவும்.',
            },
            {
                header: 'தரவு மேலாண்மைக்கான தனிப்பயன் படிவங்கள்',
                paragraph:
                    'செய்திகளின் நிமிடங்கள், கூட்டங்களில் மற்றும் பணிகளில் தரவுகளை சேமிக்கவும், வழக்கங்களைப் track செய்யவும் மற்றும் தரவுக்கு அடிப்படையிலான முடிவுகள் எடுக்கும்.',
            },
            {
                header: 'வாடிக்கையாளர் மற்றும் தலைவர்கள் மேலாண்மை',
                paragraph:
                    'ஊழியர்களால் முடிக்கப்பட்ட வேலை அளவுக்கேற்ப செயல்திறனை கண்காணிக்கவும் முக்கிய செயல்திறன் மானியங்களை அளவிடவும்.',
            },
            {
                header: 'நிச்சயமான செலுத்தலுக்கான மொத்த தூரக் கணக்கீடுகள்',
                paragraph:
                    'உலகளாவிய, வாராந்திர மற்றும் மாதாந்திர அளவுகளை அடிப்படையாகக் கொண்டு புலப் பணிகளுக்கான ஊழியர்களின் துல்லியமான பயணத்தை கண்காணிக்கவும், பயணப்பட்டிடங்கள் மற்றும் இடங்களைப் பொறுத்து செலுத்தல்களை வழங்கவும்.',
            },
        ],
        softwareDetails: [
            {
                title: 'வேலை நேரங்களை கண்காணிக்க நேரக் கணக்கீட்டு அமைப்புகளை செயல்படுத்தவும்.',
                description: [
                    'ஊழியர்கள் பணிகள் மற்றும் திட்டங்களில் செலவிடும் நேரத்தை கண்காணிக்க நேரக் கணக்கீட்டு தீர்வுகளை செயல்படுத்துதல்.',
                    'இந்தது வளங்களின் பயன்பாட்டைப் புரிந்துகொள்ளவும், நேர மேலாண்மையை மேம்படுத்தவும் உதவும்.',
                    'திட்ட மேலாண்மை அமைப்புகளைப் பயன்படுத்தி திட்ட முன்னேற்றத்தை கண்காணிக்கவும், பணிகளை ஒதுக்கவும், மற்றும் நேரங்களில் காப்புக் கொள்ளவும்.',
                    'இந்தது திட்டப் பணிகளை மேம்படுத்தவும், நேரத்திற்கு முடிக்கவும் உதவும்.',
                ],
            },
            {
                title: 'தரவரிசைகளின் முக்கிய செயல்திறன் அளவீடுகளை கண்காணிக்கவும்.',
                description: [
                    'உங்கள் நிறுவனத்தின் இலக்குகளைப் பொருந்தும் முக்கிய செயல்திறன் அளவீடுகளை வரையறுக்கவும் மற்றும் கண்காணிக்கவும்.',
                    'இதில் விற்பனை இலக்குகள், வாடிக்கையாளர் திருப்தி அளவீடுகள், அல்லது பிற செயல்திறன் அளவீடுகள் உட்பட இருக்கலாம்.',
                ],
                sDKeys: [
                    'விற்பனை செயல்திறன்: மாதாந்திர/தொகுதி விற்பனை வருவாய், முடிவடைந்த ஒப்பந்தங்களின் எண்ணிக்கை, விற்பனை மாற்று வீதம், சராசரி ஒப்பந்த அளவு, விற்பனை குழாய்கள் மதிப்பு.',
                    'வாடிக்கையாளர் சேவை: வாடிக்கையாளர் திருப்தி மதிப்பு (CSAT), நிகர ஊக்கப் பகுப்பு மதிப்பு (NPS), முதல் பதில் நேரம், சராசரி தீர்வுக்கான நேரம், வாடிக்கையாளர் நிரந்தர விகிதம், டிக்கெட் அளவு.',
                    'செயல்பாடுகள்/தயாரிப்பு: உற்பத்தி வெளியீடு/திருப்புவிப்பு, தரநிலை அளவீடுகள் (பிழை வீதம், பிழை வீதம்), நேரத்தில் விநியோக செயல்திறன், பொருளாதார வரம்பு விகிதம், உபகரணங்கள் தாமதம், ஊழியர் பயன்பாட்டு விகிதம்.',
                    'பொது: ஊழியரின் செயல்திறன் (ஒரு மணிநேரத்திற்கு வெளியீடு), பங்கேற்பு மற்றும் நேர்மையlilik, இலக்கு அடைவுத்திறன் விகிதம், ஊழியரின் கருத்துகள் மற்றும் செயல்திறன் மதிப்பீடுகள்.',
                ],
            },
            {
                title: 'உங்கள் பணி தரவுகள் மற்றும் செயல்திறன் முறைவுகளை உருவாக்கவும்.',
                description: [
                    'பணி தரவுகள், செயல்திறன், மற்றும் மேம்பாட்டு பகுதிகளைப் பற்றி உள்ளூர்த் ஆய்வுகளுடன் இணைக்கவும்.',
                    'தனிப்பயனாக்கப்பட்ட அறிக்கைகள் முடிவுகளை எடுக்க உதவும்.',
                ],
                sDKeys: [
                    'மக்கள் தொகை அமைப்பைப் பரிமாணங்களுடன் ஒப்பிடும் மற்றும் காலப்பகுதிக்குள் எந்தவொரு மக்கள் தொகை மாற்றங்களை அடையாளம் காணுதல்.',
                    'வித்யாசமான பணி அளவீடுகளைப் புலப்படுத்துதல்.',
                    'புலப் படை நடவடிக்கைகளின் நிலவரங்களை காட்சியாகக் காண்கிற ஜியோபேஷியல் வரைபடங்கள்.',
                    'பிறந்த சேவை செயல்திறனின் பகுப்பாய்வு, வளர்ச்சி அல்லது மேம்பாட்டு பகுதிகளைத் திக்குணமாக்குதல்.',
                ],
            },
        ],
    },
    fieldSalesAnalytics: {
        featureHeader: 'மேல் விற்பனை பகுப்பாய்வு',
        featureDescription:
            'விற்பனை குழுவின் முக்கிய அளவீடுகளைப் கண்காணிக்கவும், பகுப்பாய்வு செய்யவும், விற்பனை வருவாய், வாடிக்கையாளர் தொடர்புகள், மற்றும் புவியியல் உலைவர்களைச் சேர்க்கவும்.',
        featureParagraph:
            'ஆய்வுக்கோப்புகள், அதிகமான ஆவணங்கள் மற்றும் பல்வேறு ஆவணங்களைப் பார்க்கும் தேவையில்லை. WebWork Time Tracker உடன் பங்கேற்பு கண்காணிப்பு செயல்முறையை தானாகவே செய்யுங்கள் மற்றும் உங்கள் ஊழியர்களின் வேலை நேரங்களை நன்றாக வடிவமைக்கப்பட்ட வடிவத்தில் பாருங்கள்.',
        featureCaption:
            'மேல் விற்பனை செயல்பாடுகளுடன் தொடர்புடைய தரவுகளைப் சேகரிக்க, பகுப்பாய்வு செய்ய, மற்றும் பொருளாகக் கூறுவதற்கான பார்வைகளைப் பெறுவதற்கு பயன்படும்.',
        keyPoints: [
            {
                header: 'விற்பனை மற்றும் தலைவுகளைப் 위한 முக்கிய செயல்திறன் அளவீடுகள்',
                paragraph:
                    'முக்கிய செயல்திறன் அளவீடுகள் தரவுக்கு அடிப்படையாகக் கொண்ட முடிவுகளை எடுக்க உதவலாம், இது அடுத்த முறையில் செயல்திறனை மேம்படுத்த அல்லது உத்தியை புதுப்பிக்க உதவலாம்.',
            },
            {
                header: 'தனிப்பயன் அறிக்கைகள்',
                paragraph:
                    'விற்பனை, தலைவுகள், மற்றும் வாடிக்கையாளர் உற்பத்தி பற்றிய அறிக்கைகள் தினசரி, வாராந்திர, அல்லது மாதாந்திர அடிப்படையில் அணுகப்படலாம். இது நபரின் செயல்திறன் மற்றும் திறனைத் தூண்டுவதாக இருக்கலாம்.',
            },
            {
                header: 'விற்பனை பக்கம் சரிபார்த்தல் மற்றும் Geofence உடன் சீரமைக்கவும்',
                paragraph:
                    'சரியானதைக் கண்காணிக்க Geofence அடிப்படையிலான செக்-இன்கள் மூலம் ப Besuch ட்டைச் சரிபார்க்கவும். இடத்தின் ஒருங்கிணைப்புகளைப் புகைப்படத்துடன் இணைக்கவும்.',
            },
            {
                header: 'பீட் ரவுட் செயல்திறனை அளவிடுங்கள்',
                paragraph:
                    'பீட் ரவுட் ஐ வாடிக்கையாளர் சந்திப்புகளுக்கு பணிகளுக்கு அல்லது பீட் திட்டங்களுக்கு திட்டமிட பயன்படுத்தலாம். உங்கள் பீட் திட்டத்தை நேரடியாக வரைபடங்களில் ஒருங்கிணைத்து வழிநடத்தலைத் மேம்படுத்தவும்.',
            },
        ],
        softwareDetails: [
            {
                title: 'மொழுவிட ஒவ்வொரு நாளும் புல் ஃபீல்ட் பார்வை.',
                description: [
                    'தரவுத் தரவுகள் மூலம் நேரடி e-அறிக்கைகளின் உருவாக்கம் நம்பகமான மற்றும் பயனர் நட்பு செல்வாக்கு அறிக்கைகளை எளிதில் அணுகவும் பயன்படுத்தவும்.',
                    'நீங்கள் ஒரு விற்பனை மேலாளர் என்கிறீர்கள், பல்வேறு அறிக்கைகளைப் பெறுவீர்கள், அவை உங்கள் மின்னஞ்சல் முகவரிக்கு அனுப்பப்படும் அல்லது உங்கள் டாஷ்போர்டில் பார்வையிடலாம்.',
                    'சில முக்கியமான அறிக்கைகள் மாதாந்திர அறிக்கைகள், கிளை இடம் அடிப்படையிலான அறிக்கைகள், ஊழியர் நெறியியல் அறிக்கைகள், மற்றும் பிற.',
                ],
            },
            {
                title: 'முடிந்த பணி புல் பங்கேற்பில் ஒருங்கிணைக்கவும்.',
                description: [
                    'ஊழியர் புல் பங்கேற்பு மற்றும் புல் சந்திப்புகளில் ஒருங்கிணைக்கவும்.',
                    'புல் பங்கேற்பு அறிக்கையில், புல் பங்கேற்பு குறியீட்டு நேரம், இடம், தாமதமான பங்கேற்பு நேரம், வேலை நேரங்கள் போன்ற தகவல்களைப் பெறுவீர்கள்.',
                    'அதேபோல், புல் சந்திப்பு செக்-இன்கள்/அவுட் அறிக்கைகள் நிச்சயமாக வழங்கப்படும். இது புல் சந்திப்புகளுடன் தொடர்புடைய தரவுகளைப் பகிர்கிறது, உதாரணமாக லாகின்/அவுட் நேரம், செக்-இன்கள்/அவுட் நேரம், சந்திப்பு இடம், பயணம் செய்த தூரம், சந்திப்பு முடிவுகள் குறிப்பு, மற்றும் பிற.',
                ],
            },
            {
                title: 'தரவுத் தரவுகள் மூலம் உருவாக்கப்படும் அறிக்கைகள்.',
                description: [
                    'மாதாந்திர e-அறிக்கைகளைச் சந்திக்கவும் பதிவிறக்கவும்.',
                    'நீங்கள் எப்போது வேண்டுமானாலும் மாதாந்திர e-அறிக்கைகளை சந்திக்கவும் பதிவிறக்கலாம்.',
                    'PDF மற்றும் எக்செல் கோப்பு வடிவங்களில் கிடைக்கும். இது உங்கள் அமைப்புக்கு வெளிப்புற செயல்பாட்டைப் பற்றிய முழுமையான தகவல்களை வழங்கும், இது கைமுறை அவசியம் ஒரு கடினமான பணியாகும்.',
                ],
            },
        ],
    },
    locationBasedAttendance: {
        featureHeader: 'இட அடிப்படையிலான பங்கேற்பு',
        featureDescription:
            'முன்னதாகவே நிர்ணயிக்கப்பட்ட புவியியல் எல்லைகளுக்குள் மட்டுமே ஊழியர்கள் clock in அல்லது out செய்யக்கூடிய வகையில் Geo fence அடிப்படையிலான பங்கேற்பு.',
        featureCaption:
            'இட அடிப்படையிலான பங்கேற்பு ஊழியர்களின் உடல் இடத்தை அடிப்படையாகக் கொண்டு அவர்களின் பங்கேற்பை கண்காணிக்க மற்றும் பதிவுசெய்ய உண்மைமானதை உறுதி செய்கிறது.',
        keyPoints: [
            {
                header: 'Geofencing',
                paragraph:
                    'சிறப்பு அலுவலக இடங்களில் சுற்றியுள்ள geofenced கற்பனை எல்லைகளை நுழைவிக்கவும். ஒரு ஊழியர் வகைப்படுத்தப்பட்ட இடத்தைச் சேர்க்கும்போது அல்லது விலகும்போது பங்கேற்பை தானாகவே செய்ய உதவுகிறது.',
            },
            {
                header: 'அங்கீகாரம் மற்றும் பாதுகாப்பு',
                paragraph:
                    'PINகள், உயிரணுக்கோவை அல்லது முகமூடி பங்கேற்பு மூலம் பங்கேற்பு தரவின் சரியான மற்றும் பாதுகாப்பு முறைகளை உறுதிப்படுத்தும்.',
            },
            {
                header: 'வேர்ல்-இன் நேரப் புதுப்பிப்புகள்',
                paragraph:
                    'பங்கேற்பு பதிவுகள் நேர்மறையாக புதுப்பிக்கப்படுவதை உறுதி செய்யவும், முன்னணி மேலாண்மை முடிவுகளுக்கு அனுமதி அளிக்கவும்.',
            },
            {
                header: 'தொலைந்த வேலைப் பரிசீலனைகள்',
                paragraph: 'தொலைதூர ஊழியர்களுக்கான பங்கேற்பு கண்காணிப்புக்கான மாற்று முறைகளைப் பரிசீலிக்கவும்.',
            },
        ],
        softwareDetails: [
            {
                title: 'வேலை செய்யும் நேரம் மற்றும் இடம்.',
                description: [
                    'நீங்கள் உங்கள் ஊழியர்கள் எப்போது மற்றும் எங்கு வேலை செய்கிறார்கள் என்பதைக் தெரியப் பெறுங்கள்.',
                    'உங்கள் ஊழியர்களை அவர்களின் மொபைல் போன்களில் அனுமதியை வழங்குவதற்குத் தெரியச் செய்யுங்கள், இது நேரம் மற்றும் இடத்தை கண்காணிக்கலாம்.',
                    'அனுமதி வழங்கப்பட்ட பிறகு, டிராக்கர் ஒரு டாப் மூலம் செயல்படத் தொடங்கும்.',
                ],
            },
            {
                title: 'Live Reporting Two',
                description: [
                    'இட அடிப்படையிலான ஊழியர் பங்கேற்பு முறைமையை செயல்படுத்துவதால் சரியானதை மேம்படுத்தலாம் மற்றும் பங்கேற்பு மேலாண்மையை எளிதாக்கலாம். இருப்பினும், ஊழியர் தனியுரிமை விவரங்களை சமநிலையாக்க வேண்டும் மற்றும் இத்தகைய முறைமைகள் செயல்படுத்தப்படுவது மற்றும் பயன்படுத்தப்படுவது பற்றிய தகவல்களை தெளிவாகப் பேச வேண்டும்.',
                ],
            },
            {
                title: 'Geo fence அடிப்படையிலான எச்சரிக்கை',
                description: [
                    'அசாதாரண பங்கேற்பு முறைமைகளை அல்லது வேறுபாடுகளைத் தானாகவே எச்சரிக்கைகள் அமைக்கவும். இது அனுமதியற்ற செக்-இன்கள் அல்லது செக்-அவுட்களைப் போன்ற சாத்தியமான பிரச்சினைகளை அடையாளம் காண உதவுகிறது.',
                    'பங்கேற்பு முறைமைகளை, போக்கு, மற்றும் மாறுபாடுகளைப் பெறுவதற்கான அறிக்கைகள் மற்றும் பகுப்பாய்வுகளை செயல்படுத்தவும்.',
                ],
                sDKeys: [
                    'மதிப்புமிக்க சொத்துகள், வாகனங்கள் அல்லது உபகரணங்கள் வகைப்படுத்தப்பட்ட பகுதிகளை நுழையும்போது அல்லது விலகும்போது எச்சரிக்கைகள் பெறுங்கள், இது திருட்டு அல்லது அனுமதியற்ற பயன்பாட்டைக் தவிர்க்க உதவுகிறது.',
                    'பேசல் பணியாளர்களின் பாதுகாப்பை உறுதிப்படுத்தும் வகையில் ஆபத்தான இடங்கள் அல்லது தொலைபார்வை வேலைக்கான இடங்களைச் சுற்றியுள்ள geofences அமைக்கவும், ஊழியர்கள் கட்டுப்படுத்தப்பட்ட பகுதிகளுக்குள் நுழைந்தால் எச்சரிக்கைகள் அளிக்கவும்.',
                    'ஒரு சாதனம் geofenced பகுதிக்கு நுழையும் அல்லது விலகும் போது, முறைமை முன்னமைக்கப்பட்ட எச்சரிக்கைகள் அல்லது தகவல்களைத் தூண்டுகிறது.',
                    'முறைமை நேர்மறையாக கண்காணிக்கும் சாதனங்களின் இடங்களை மெய்நிகர் கண்காணிப்பு முறைகள் மூலம் மற்றும் அவை எந்த வகையான geofences உடன் தொடர்புடையவை என்பதைச் சரிபார்க்கிறது.',
                ],
            },
        ],
    },
    liveReporting: {
        featureHeader: 'Live Reporting',
        featureDescription:
            'செயல்திறனைக் கண்காணிக்க மற்றும் அலசுவதற்கான நேரடி அடிப்படையில் பங்கேற்பு, பணி முடிவுகள் அல்லது திட்ட முன்னேற்றம் போன்ற பல அம்சங்களைப் பற்றிய விவரங்கள்.',
        featureCaption:
            'ஊழியர்களின் பணி, கூட்டங்களின் நிமிடங்கள், பார்வை உறுதிப்படுத்தல் மற்றும் தரவின் அடிப்படையில் முடிவுகளை எடுக்க எப்போதும் அறிந்திருங்கள்.',
        keyPoints: [
            {
                header: 'நிகழ்வு அடிப்படையிலான அறிவிப்புகள்',
                paragraph:
                    'அட்மின் அல்லது மேலாளர் எந்த அறிவிப்புகளைப் பெற விரும்புகிறார்கள் என்பதற்காக சந்தா எடுக்க அல்லது தேர்வு செய்யலாம். இது அவர்கள் உண்மையில் பெற விரும்பும் அறிவிப்புகளை மையமாகக் கொண்டு கவனத்தை சுமந்து உதவுகிறது.',
            },
            {
                header: 'பங்கேற்பு மற்றும் விடுப்புகள் பற்றிய தகவல்களைப் பெறுங்கள்',
                paragraph:
                    'ஒரு படி முன்னேறுங்கள் மற்றும் நாளைத் திட்டமிடுவதற்கு முன்னர் தலைக்கணக்கைப் பெறுங்கள், இது மிகவும் உற்பத்தி மற்றும் திறமையானதாக இருக்கும்.',
            },
            {
                header: 'அனைத்து ஒப்புதிகளைப் track செய்யுங்கள்',
                paragraph:
                    'அனுப்பிய செலவீனங்கள் முதல் விடுப்புகள் வரை அனைத்து ஒப்புதிகளைப் track செய்யவும் மற்றும் பிறகு அவற்றைப் பார்வையிடவும்.',
            },
            {
                header: 'படிவங்கள் மற்றும் கூட்டங்களுடன் புதுப்பிக்கவும்',
                paragraph:
                    'அன்வயம் படிவங்கள் குறிப்பிட்ட தரவுகளைப் பெற உதவலாம், இது தொலைவிலிருந்து அணுகக்கூடியதாக இருக்கும்.',
            },
        ],
        softwareDetails: [
            {
                title: 'பீல்ட் ஸ்டாஃபை நேரடியாக தேடுங்கள்.',
                description: [
                    'உங்கள் பீல்ட் ஸ்டாஃபைப் நேரடியாக தேடுங்கள்.',
                    'உங்கள் பீல்ட் பணியாளர்களின் பயணத்தின் நேரடி இடத்தின் விவரங்களைப் பெறுங்கள்.',
                    'Treant உங்கள் பீல்ட் ஊழியர்களை அவர்களின் மொபைல் GPS ஐப் பயன்படுத்தி ஆன்லைன் மற்றும் ஆஃப்லைனில் கண்டறியவும், track செய்யவும் உதவுகிறது. உங்கள் பீல்ட் ஸ்டாஃபின் ஒவ்வொரு இயக்கத்தையும் புதுப்பிக்கவும் மற்றும் வெளிப்படையான வேலைச் சூழலைக் காப்பாற்றவும்.',
                ],
            },
            {
                title: 'உங்கள் ஊழியர்களின் பீல்ட் தகவலுடன் புதுப்பிக்கவும்.',
                description: [
                    'உங்கள் ஊழியர்களின் பீல்ட் தகவலுடன் புதுப்பிக்கவும்.',
                    'பீல்ட் ஸ்டாஃப் பீல்டில் தகவல்கள் உங்கள் டாஷ்போர்டுடன் தானாகவே ஒத்திசைக்கப்படும்.',
                    'உங்கள் ஊழியர்களின் விவரங்கள் போன்றவை, பேட்டரி சதவீதம், GPS, இணையத்துடன்/இல்லாமல் நேரத்தின் வெளிப்பாடு, பீல்டில் அவர்கள் செலவிடும் நியாயமான நேரம், இவை நேரடியாக உங்களுக்கு அறிவிக்கப்படும்.',
                ],
            },
            {
                title: 'ஊழியர்களின் செலவீனங்கள் மற்றும் இலவசத் தகவல்களை தானாகவே செய்யுங்கள்.',
                description: [
                    'சரியான தரவிற்கான AI சக்தியுள்ள இட வரலாறு.',
                    'உங்கள் ஊழியர்களின் இட தகவல்கள் Artificial Intelligence மூலம் சக்தியளிக்கப்படும், இதனால் சரியான பாதை மற்றும் track செய்த தகவல்களை வழங்குவீர்கள். நேரடி track செய்த தரவுகள் மற்றும் பிற இட வரலாறுகள் உங்கள் மெயில்பாக்ஸில் சீரான இடைவெளியில் தானாகவே அனுப்பப்படும், மைதானத்தில் மற்றும் வெளியில் நடக்கும் விவரங்களை ஆழமாகப் புரிந்துகொள்ள.',
                ],
            },
        ],
    },
    aIBasedOptimization: {
        featureHeader: 'AI அடிப்படையிலான Optimization',
        featureDescription:
            'மேலாளர்கள் அல்லது விற்பனை ஊழியர்களுக்கான பாதைகளை AI அடிப்படையிலான beat route அடிப்படையில் சிறந்த முறையில் திட்டமிடவும் தானாகவே இயக்கவும்.',
        featureCaption:
            'தினசரி பாதைகளை முறையிடுங்கள் மற்றும் எங்கள் AI அடிப்படையிலான அல்கரிதமுடன் உங்கள் முந்தைய பருவத்துடன் ஒப்பிடவும்.',
        keyPoints: [
            {
                header: 'நேரடி தரவுகளின் ஒருங்கிணைப்பு',
                paragraph:
                    'நேரடி டிராபிக் அப்டேட்டுகள், வானிலை நிலைமைகள், மற்றும் சாலை முடக்கங்கள் போன்ற நேரடி தரவுகள் மூலம் தற்போதைய சூழ்நிலைகளின் அடிப்படையில் பாதைகளை சரிசெய்யவும், தினசரி திட்டமிடல் திறனை மேம்படுத்தவும்.',
            },
            {
                header: 'பாதை திட்டமிடலுக்கான பயனர் இடைமுகம்',
                paragraph:
                    'சரியான பாதைகளைப் பார்வையிடுங்கள் மற்றும் தேவையான மாற்றங்களைச் செய்யவும். இது ஒரு மொபைல் பயன்பாட்டின் அல்லது இணைய அடிப்படையிலான தளத்தின் ஒரு பகுதியாக இருக்க முடியும்.',
            },
            {
                header: 'உற்பத்தி மெட்ரிக்ஸ்',
                paragraph:
                    'வித்தியாசமான பாதைகள் அல்லது routes இன் தனித்துவமான பண்புகளைப் கணக்கில் எடுக்கும் AI அமைப்பு.',
            },
            {
                header: 'மாப்பிங் மற்றும் பூமியியல் தரவுகள்',
                paragraph:
                    'சாலை நெட்வர்க், டிராபிக் நிலைமைகள் மற்றும் பிற இடம் தொடர்பான தகவல்களைப் புரிந்துகொள்ள மாப்பிங் சேவைகள் மற்றும் பூமியியல் தரவுகளைப் பயன்படுத்தவும்.',
            },
        ],
        softwareDetails: [
            {
                title: 'நேரடி அப்டேட்டுகளுடன் பாதை சரிசெய்தல்',
                description: [
                    'தனிப்பட்டவர்களுக்கு அல்லது வாகனங்களுக்கு மிகச் சிறந்த பாதைகளை திட்டமிடவும், அதில் டெலிவரி டிரக், விற்பனை பிரதிநிதிகள், அல்லது பீல்ட் சேவை நிபுணர்கள் போன்றவர்கள் உள்ளனர்.',
                    'இந்த பயன்பாடு திறனை மேம்படுத்த, பயண நேரத்தை குறைக்க மற்றும் செயல்பாட்டு செலவுகளை குறைக்க உதவலாம்.',
                ],
                sDKeys: [
                    'GPS சாதனங்கள், டிராபிக் ரிப்போர்டுகள், வானிலை அப்டேட்டுகள் மற்றும் வாகன சென்சார்களிடமிருந்து தரவுகளை சேகரிக்கவும்.',
                    'விரைவான பதிலளிப்பு நேரங்கள் மற்றும் நேரத்திலான வருகைகள் வாடிக்கையாளர் திருப்தியை மேம்படுத்துகிறது, இது சேவையாளர் அடிப்படையிலான வணிகங்களுக்கு முக்கியம்.',
                ],
            },
            {
                title: 'தனியுரிமை பிரச்சினைகள் மற்றும் கீழ் ஒழுங்கமைப்புகளை மிகச் சிந்தனையுடன் கவனிக்க வேண்டும்',
                description: [
                    'AI அடிப்படையிலான அமைப்பு உள்ளூர் விதிமுறைகளைப் பின்பற்றுவதை உறுதி செய்யவும், குறிப்பாக தனியுரிமை, தரவுத் பாதுகாப்பு மற்றும் போக்குவரத்து சட்டங்கள் தொடர்பானவை.',
                    'புதிய தரவுகள் மற்றும் கருத்துகள் அடிப்படையில் AI அல்கரிதங்களை முறைப்பாடுகளுடன் புதுப்பிக்கவும், பயண பாதைகளை முறைப்பாடுகளை தொடர்ச்சியான மேம்படுத்துதலுக்கு.',
                ],
            },
            {
                title: 'பாதை திட்டமிடல் திறனுக்கான மற்ற அமைப்புகளுடன் ஒருங்கிணைப்பு',
                description: [
                    'சரியான டிராபிக் நிலைமைகள், டெலிவரி நேரங்கள் மற்றும் பாதை திட்டமிடலை பாதிக்கும் பிற அம்சங்களை எதிர்கொள்வதற்காக, பூர்வகால தரவுகளைப் பகுப்பாய்வு செய்ய மற்றும் எதிர்கால மாதிரிகளை முன்னறிவிக்க மையம் கற்றல் அல்கரிதங்களைப் பயன்படுத்தவும்.',
                ],
            },
        ],
    },
    leadManagement: {
        featureHeader: 'விற்பனை முன்னணி மேலாண்மை',
        featureDescription:
            'பரிசோதனை செய்யப்பட்ட ஊழியர் வருகை தரவுகளை, வேலைக்கான நேரங்களை, अनुपस्थितிகள், தாமதமான வருகைகள், மற்றும் முன்னணி செலவுகளை குறிப்பிட்ட நேர அட்டவணை உட்பட சுருக்கமாகக் கூறுங்கள்.',
        featureCaption:
            'மூலம் கிளைகள் மற்றும் மாதாந்திரமாக 40+ மாதிரிகளுடன் ஆட்டோமேட்டிகமாக முன் உள்ளீட்டுகள் உருவாக்கவும்.',
        keyPoints: [
            {
                header: 'வருகை விவரங்களை தானாகவே உருவாக்குங்கள்: நுழைவுச் சமயம், தாமதமான சமயம், மொத்த வேலை நேரம், மொத்த அமர்வுகள்',
                paragraph:
                    'ஆரம்பமான வருகை மேலோட்டத்தை வழங்குங்கள், இதில் வேலைக்கான நாட்களின் மொத்த எண்ணிக்கையும், வேலை செய்யப்பட்ட மொத்த நேரமும், மற்றும் எதுவும் ஓவர்டைம் நேரங்களும் அடங்கும்.',
            },
            {
                header: 'பல கிளைகள் அறிக்கைகள்',
                paragraph: 'அமைப்புகளுக்கான தனிப்பயனாக்கிய வகைகளுடன் பல கிளைகள் வருகை அறிக்கைகளை தொலைவாகப் பெறுங்கள்.',
            },
            {
                header: 'ஓவர்டைம் மற்றும் விடுமுறை நிலைகள்',
                paragraph:
                    'ஒவ்வொரு ஊழியரின் விடுமுறை நிலைகளைக் காட்சிப்படுத்துங்கள், vacation days, sick leave, மற்றும் பிற வகையான விடுமுறைகள் சேர்க்கப்பட்ட மற்றும் பயன்படுத்தப்பட்டவையாக.',
            },
            {
                header: 'தனிப்பயனாக்கப்பட்ட வருகை அறிக்கை',
                paragraph:
                    'பிரிவு, குழு அல்லது தனிப்பட்ட ஊழியர் போன்ற குறிப்பிட்ட அளவுகோல்கள் அடிப்படையில் அறிக்கைகளை தனிப்பயனாக்கவும்.',
            },
        ],
        softwareDetails: [
            {
                title: 'உங்கள் அமைப்பின் அமைப்புக்கேற்ப அறிக்கைகளை உருவாக்குங்கள்.',
                description: [
                    'முதற்கொண்டு உருவாக்கப்படும் மற்றும் பகுப்பாய்வு செய்யப்பட்ட வருகை அறிக்கைகள், தொழிலாளர் மேலாண்மையை மேம்படுத்த, பரிமாணங்களை கண்டறிய மற்றும் அமைப்பின் கொள்கைகளுக்கு ஏற்ப தகவல்களை உறுதிசெய்ய மதிப்பீட்டு தகவல்களை வழங்கலாம்.',
                    'அறிக்கையின் விவரங்கள் அமைப்பின் தேவைகள் மற்றும் அமைப்பின் அடிப்படையில் மாறுபடும்.',
                ],
            },
            {
                title: 'எளிதாக தனிப்பயனாக்கப்பட்ட அறிக்கைகளைப் பெறுங்கள்.',
                description: [
                    'அங்கீகாரமில்லாத अनुपस्थितிகள், அதிகமான தாமதங்கள் அல்லது அனுமதியில்லாத ஓவர்டைம் போன்ற வருகையில் எந்தவொரு விலக்குகளையோ அல்லது விதிவிலக்குகளை அறிக்கையிடுங்கள்.',
                    'வருகை மாறுபாடுகளை மற்றும் மாதிரிகளை எளிதாகக் கண்டறிய அட்டவணைகள் அல்லது வரைபடங்களைப் பயன்படுத்துங்கள்.',
                ],
            },
            {
                title: 'கைமுறையாக்கப்பட்ட அறிக்கைகளைச் சிக்கனம் குறைக்கவும்.',
                description: [
                    'வருகை தரவுகளுக்கு தொடர்பான விவரங்களை வழங்க எளிதாக அடையாளங்களுடன் கூடிய ஊழியர் பெயர்கள், அடையாள எண்கள், மற்றும் பிரிவு தொடர்புகளைச் சேர்க்கவும்.',
                    'அறிக்கையில் அடிப்படையிலான தேதிகள் மற்றும் நேர வரம்புகளை தெளிவாகக் குறிப்பிடுங்கள். இதனால் பயனர் தரவின் காலபரப்பை புரிந்து கொள்ள உதவும்.',
                    'மொத்த வேலை நாட்களின் எண்ணிக்கையும், வேலை செய்யப்பட்ட மொத்த நேரமும் மற்றும் ஓவர்டைம் நேரங்களையும் உள்ளடக்குவதாக ஒருங்கிணைக்கப்பட்ட வருகை மேலோட்டத்தை வழங்குங்கள்.',
                ],
            },
        ],
    },
    taskManagement: {
        featureHeader: 'பணி மேலாண்மை',
        featureDescription:
            'ஊழியர்களின் செயல்பாடுகள், உற்பத்தித்திறன் மற்றும் வருகைகளை முறைபடுத்தி கண்காணிப்பு மற்றும் மேலாண்மை.',
        featureParagraph:
            'ஊழியர்களின் வருகையைச் சரிபார்க்க நேர அட்டைகள், அதிகமான ஆவணங்கள் மற்றும் வெவ்வேறு ஆவணங்களை கையாள வேண்டாம். WebWork Time Tracker உடன் வருகை கண்காணிப்பு செயல்முறையை தானாகவே செய்யவும் மற்றும் உங்கள் ஊழியர்களின் வேலை நேரங்களை ஒரு நன்கு வடிவமைக்கப்பட்ட வடிவத்தில் காணவும்.',
        featureCaption:
            'ஒரு அமைப்பின் படைப்புகளை, வேலை நேரங்களை, உற்பத்தித்திறனை மற்றும் செயல்திறனை பின்வரும் மற்றும் மேலாண்மை செய்யவும்.',
        keyPoints: [
            {
                header: 'வெளிச்சமாக கண்காணிக்கவும்',
                paragraph:
                    'பணி வார்ட்களைப் பார்க்கவும், பணி நிலையைச் சரிபார்க்கவும், மற்றும் செலவழிக்கப்படும் நேரத்தைப் track செய்யவும்.',
            },
            {
                header: 'அனைத்து நிறுவனத்திற்குமான உங்கள் பணி நிலைகளைக் ஒரே இடத்தில் கண்காணிக்கவும்',
                paragraph:
                    'அணிவகுப்பு பணி மேலாண்மை அம்சங்கள் மூலம் குழு உறுப்பினர்களுடன் நேரடியாக தொடர்பு கொள்ளவும். பணி மீது கருத்து தெரிவிக்கவும், கேள்விகள் கேட்கவும், மற்றும் அனைவரையும் தகவலளிக்கக் கூடிய அப்டேட்களை வழங்கவும்.',
            },
            {
                header: 'செயல்திறனை கண்காணிக்கவும்',
                paragraph:
                    'உற்பத்தித்திறனை கண்காணிக்க வாடிக்கையாளர் மற்றும் முன்னணி மேலாண்மை. ஊழியர்களின் உற்பத்தித்திறனை மற்றும் முக்கிய செயல்திறனைச் சதவீதம் ஊழியர்கள் செய்யும் பணி அடிப்படையில் அளவிடலாம்.',
            },
            {
                header: 'இலக்குகளை சீர்தரமாக அமைக்கவும்',
                paragraph:
                    'இலக்குகளை ஒழுங்குபடுத்த பயனுள்ள உபயோகத்தின் அடிப்படையில்: திட்ட இலக்குகள், பிரிவினரின் நோக்கங்கள், தனிப்பட்ட செயல்திறன் இலக்குகள், தந்திர வகைகளைப் போன்றவை.',
            },
        ],
        softwareDetails: [
            {
                title: 'வேலை நேரங்களை கண்காணிக்க கால அளவீட்டு முறைமைகளை செயல்படுத்தவும்.',
                description: [
                    'ஊழியர்கள் பணி மற்றும் திட்டங்களில் செலவழிக்கும் நேரத்தை கண்காணிக்க கால அளவீட்டு தீர்வுகளை செயல்படுத்துதல்.',
                    'இது வளங்கள் பயன்பாட்டைப் புரிந்து கொள்ள மற்றும் நேர மேலாண்மையை மேம்படுத்த உதவும்.',
                    'திட்ட மேலாண்மை முறைமைகளைப் பயன்படுத்தி திட்ட முன்னேற்றத்தை கண்காணிக்கவும், பணிகளை ஒதுக்கவும், மற்றும் கடைசி தேதிகளைச் சரிபார்க்கவும்.',
                    'இது திட்ட வேலைப்பாடுகளைப் பூர்த்தி செய்ய மற்றும் தற்காலிகமாக நிறைவு செய்ய உதவுகிறது.',
                ],
            },
            {
                title: 'தகுந்த முக்கிய செயல்திறன் அளவுகளை கண்காணிக்கவும்.',
                description: [
                    'உங்கள் அமைப்பின் இலக்குகளுக்கு பொருந்தும் முக்கிய செயல்திறன் அளவுகளை வரையறுக்கவும் மற்றும் கண்காணிக்கவும்.',
                    'இதில் விற்பனை இலக்குகள், வாடிக்கையாளர் திருப்தி அளவுகோல்கள் அல்லது பிற செயல்திறன் அளவுகோல்கள் அடங்கலாம்.',
                ],
            },
            {
                title: 'உங்கள் தொழிலாளர்களின் ஊழியரின் போக்கு அறிக்கைகளை உருவாக்கவும்.',
                description: [
                    'உயர்தர பகுப்பாய்வு மற்றும் அறிக்கைகளின் அம்சங்களை ஒருங்கிணைக்கவும், உற்பத்தித் திறன்கள், செயல்திறன், மற்றும் மேம்படுத்தல் தேவைப்பட்ட பகுதிகளைப் பெறுங்கள்.',
                    'தனிப்பயனாக்கப்பட்ட அறிக்கைகள் முடிவெடுப்பில் உதவலாம்.',
                ],
            },
        ],
    },
    salesPipelineMonitoring: {
        featureHeader: 'விற்பனை பைப்லைன் கண்காணிப்பு',
        featureDescription:
            'விற்பனை குழுவின் முக்கிய அளவுகோல்களைப் கண்காணிக்கவும், ஆராய்கவும், விற்பனை வருமானம், வாடிக்கையாளர் தொடர்புகள், மற்றும் பிரதேச வளைவுகளைப் பரிசீலிக்கவும்.',
        featureParagraph:
            'நேர அட்டைகள், அதிகமான ஆவணங்கள் மற்றும் ஊழியர்களின் வருகையைச் சரிபார்க்க வெவ்வேறு ஆவணங்களை கையாள வேண்டாம். WebWork Time Tracker உடன் வருகை கண்காணிப்பு செயல்முறையை தானாகவே செய்யவும் மற்றும் உங்கள் ஊழியர்களின் வேலை நேரங்களை ஒரு நன்கு வடிவமைக்கப்பட்ட வடிவத்தில் காணவும்.',
        featureCaption:
            'மெய்ப்பொருளான தரவுகளின் சேகரிப்பு, ஆய்வு மற்றும் விளக்கத்தைப் பரிசீலிக்கவும் மற்றும் தகவல்தரமான முடிவுகளை எடுக்கவும்.',
        keyPoints: [
            {
                header: 'தானாகச் செய்யப்படும் வேலைப்பாடுகள்',
                paragraph:
                    'விற்பனை செயல்முறையின் பல நிலைகளை நன்கு ஒருங்கிணைக்கும் மற்றும் நரம்பியல் நெருக்கடி செய்யவும் உதவுங்கள், குறிப்பாக முன்னணி பிடிப்பு, தகுதிகரிப்பு, வளர்ப்பு, பைப்லைன் மேலாண்மை, நடவடிக்கை கண்காணிப்பு, மற்றும் அறிக்கையிடல்.',
            },
            {
                header: 'CRM உடன் ஒருங்கிணைப்பு',
                paragraph:
                    'முன்னணி மேலாண்மை, தொடர்பு கண்காணிப்பு, ஒப்பந்தக் கண்காணிப்பு, மற்றும் இரண்டு மன்றங்களுக்கிடையே தரவுகளின் ஒத்திசைவு.',
            },
            {
                header: 'குழுவாய்ப்பாட்டு மற்றும் தொடர்பை எளிதாக்கவும்',
                paragraph:
                    'மையமான தொடர்பு சேனல்கள், விற்பனை கூட்டங்களை திட்டமிடுதல், ஒத்துழைப்பு ஒப்பந்த ஆய்வுகள், செயல்முறைகளை ஆவணமாக்குதல், குறுக்குவிளைவான ஒத்துழைப்பு, திறந்த தொடர்பு ஊக்குவிப்பு.',
            },
            {
                header: 'எதிர்கால விற்பனை செயல்திறனில் தகவல்கள்',
                paragraph:
                    'தரவுப் பகுப்பாய்வு மற்றும் முன்னோக்கி மாதிரிகளைப் பயன்படுத்தி, சாத்தியமான முடிவுகள் மற்றும் வளைவுகளை முன்னறிந்து கொள்ளுங்கள்.',
            },
        ],
        softwareDetails: [
            {
                title: 'மேலாளர் விற்பனை செயல்திறனை மேம்படுத்த அன்றாட புல அறிக்கைகள்',
                description: [
                    'தரவுப் பகுப்பாய்விற்கு தகுந்த அன்றாட மின்னணு அறிக்கைகளை உருவாக்குதல். நம்பகமான மற்றும் பயனர் நட்பு அறிக்கைகள் உங்களுக்கான எளிய அணுகுமுறை மற்றும் பயன்பாட்டிற்கு.',
                    'ஒரு விற்பனை மேலாளராக, உங்கள் மின்னஞ்சல் முகவரிக்கு அனுப்பப்படும் பல்வேறு அறிக்கைகளை நீங்கள் தெளிவாகப் பார்க்க முடியும் அல்லது உங்கள் கட்டுப்பாட்டு புலத்தில் அதைப் பார்க்கவும்.',
                    'அம்சமாக, மாதாந்திர அறிக்கைகள், கிளை இடமெடுக்கும் அறிக்கைகள், ஊழியரின் குறிப்பிட்ட அறிக்கைகள் போன்றவை.',
                ],
            },
            {
                title: 'பணி நிலைகளில் ஒரு மொத்தக் காட்சி',
                description: [
                    'ஊழியர்களின் பணி நிலைகள் மற்றும் பணி பயணங்களை முழுமையாகப் பார்வையிடுங்கள்.',
                    'உங்கள் பணி ஊழியர்களின் வருகை மற்றும் கூட்டங்களை பற்றிய முழுமையான காட்சி.',
                    'பணி வருகை அறிக்கையில், வருகை குறியீட்டு நேரம், இடம், தாமதமான வருகை நேரம், வேலை நேரம் போன்ற தரவுகளைப் பெறுவீர்கள்.',
                    'இதற்குரிய, பணி பயணச் சரிபார்ப்பு/வெளியே செல்லும் அறிக்கைகள், பணி பயணங்கள் பற்றிய தகவல்களைப் பராமரிக்கிறது, எண்ஆண்டுகள், செக்-இன்/அவுட் நேரம், கூட்டங்களின் இடம், பயணிக்கப்பட்ட தூரம், கூட்டத் தகவல்கள் போன்றவை.',
                ],
            },
            {
                title: 'தரவுப் பகுப்பாய்வு அடிப்படையிலான அறிக்கைகள் உருவாக்குதல்',
                description: [
                    'மாதாந்திர மின்னணு அறிக்கைகளைப் சந்தா செய்யவும் மற்றும் பதிவிறக்கம் செய்யவும்.',
                    'நீங்கள் விரும்பும் நேரத்தில் மாதாந்திர மின்னணு அறிக்கைகளைப் சந்தா செய்யலாம் மற்றும் பதிவிறக்கம் செய்யலாம்.',
                    'PDF மற்றும் Excel கோப்பு வடிவங்களில் கிடைக்கிறது. ஒரு முழுமையான அறிக்கைகள் தொகுப்பு, ஊழியரின் வெளிப்புற செயல்பாடுகளைப் பற்றிய விவரங்களுடன் உங்கள் மின்னஞ்சலுக்கு விபரமாகக் கிடைக்கிறது, இது கைமுறையாகச் செய்யும்போது கடினமாக இருக்கும்.',
                ],
            },
        ],
    },
    workflowAutomation: {
        featureHeader: 'வேலைப்பாட்டை தானாகச் செய்யுதல்',
        featureDescription:
            'ஆகிய ஆவணங்களைச் சமாளிக்கும்படி ஊழியர்கள் முன்னதாக நிர்ணயிக்கப்பட்ட புவியியல் எல்லைகளை உள்ளே அல்லது வெளியே வரவேண்டிய அவசரமான தன்மை மற்றும் போக்குவரத்து காரணங்களைப் பார்வையிடுகிறது.',
        featureCaption:
            'இடதுச்சிறந்த வருகை நேரத்தை ஒவ்வொரு ஊழியரின் புவியியல் இடத்தை அடிப்படையாகக் கொண்டு கண்காணிக்கவும் பதிவுசெய்யவும்.',
        keyPoints: [
            {
                header: 'தானாகச் செய்யப்படும் பணியாளர்கள் ஒதுக்கீடு',
                paragraph:
                    'திறமைகள், கிடைக்கும் நேரம், வேலைப் பகை, மற்றும் முன்னுரிமை நிலைகள் போன்ற காரணிகளை கருத்தில் கொண்டு, பணிகளை மிகச் சிறந்த முறையில் ஒதுக்க ஆட்டோமேஷன்.',
            },
            {
                header: 'முன்னணி பறிப்பு, தகுதிகரிப்பு மற்றும் மதிப்பீடு',
                paragraph:
                    'முன்னணி பறிப்பு, தகுதிகரிப்பு, மதிப்பீடுகளுக்கு நன்கு ஒதுக்கப்பட்ட விற்பனை பிரதிநிதிகளை ஒதுக்குகிறது, அவர்களின் தரத்தைப் மதிப்பீடு செய்கிறது, மற்றும் தொடர்ந்த செயல்முறைகளை முன்னுரிமை அளிக்கிறது.',
            },
            {
                header: 'வெளிப்புற அமைப்புகளுடன் ஒருங்கிணைப்பு',
                paragraph:
                    'மென்மையான ஒத்துழைப்பு, தரவுப் சரிவரத்தை மேம்படுத்துதல், மற்றும் பல்வேறு வணிக செயல்பாடுகள் முழுவதும் ஒருங்கிணைந்த வேலைப்பாட்டை உறுதிப்படுத்துகிறது.',
            },
            {
                header: 'செயல்திறனைக் கண்காணித்தல் மற்றும் அறிக்கையிடல்',
                paragraph:
                    'செயல்முறை நிறைவேற்ற நேரங்கள், பிழை வீதங்கள், வளங்களைப் பயன்படுத்துதல், மற்றும் மொத்த செயல்திறனைப் கண்காணிக்கவும்.',
            },
        ],
        softwareDetails: [
            {
                title: 'உழைப்பாளர்களின் நேரம் மற்றும் இடம்.',
                description: [
                    'உங்கள் ஊழியர்கள் எப்போது மற்றும் எங்கு வேலை செய்கிறார்கள் என்பதைக் அறியுங்கள்.',
                    'உங்கள் ஊழியர்களிடம் அவர்களது மொபைல் போன்களில் அனுமதி கேட்டு, நேரம் மற்றும் இடத்தைப் பின்தொடரலாம்.',
                    'அனுமதி வழங்கப்பட்ட பிறகு, டிராக்கர் ஒரு தட்டத்தைப் பயன்படுத்தி செயல்பட ஆரம்பிக்கும்.',
                ],
            },
            {
                title: 'செயல்நேரப் புகாரளிப்பு இரண்டு',
                description: [
                    'ஒரு இட அடிப்படையிலான ஊழியரின் வருகை முறைமையை நடைமுறைக்கு கொண்டு வருவது துல்லியத்தை மேம்படுத்த முடியும் மற்றும் வருகை மேலாண்மையை சீராக்க உதவுகிறது. ஆனால், ஊழியர்களின் தனிப்பட்ட தன்மையைப் பாதுகாக்க இந்த அமைப்புகளைப் பயன்படுத்துவதில் வெளிப்படையாக கருத்துக்களைத் தருவது முக்கியமாகும்.',
                ],
            },
            {
                title: 'ஜியோ பந்தி அடிப்படையிலான எச்சரிக்கை',
                description: [
                    'விராத வருகை மாதிரிகள் அல்லது முரண்பாடுகளுக்கான தானாகச் செய்யப்படும் எச்சரிக்கைகளை அமைக்கவும். இது, அனுமதியற்ற செக்-இன் அல்லது செக்-ஆவுட் போன்ற சாத்தியமான பிரச்சினைகளைப் கண்டறிய உதவுகிறது.',
                    'வருகை மாதிரிகள், நெட்வொர்க், மற்றும் பிழைகளைப் புரிந்துகொள்ள அறிக்கையிடல் மற்றும் பகுப்பாய்வு அம்சங்களை செயல்படுத்தவும்.',
                ],
            },
        ],
    },
    aIBasedAssistance: {
        featureHeader: 'ஏ.ஐ அடிப்படையிலான உதவி',
        featureDescription:
            'வருகை, பணி முடிந்தது அல்லது திட்ட முன்னேற்றம் போன்ற பணி விதிகளின் திடக்கட்டாய மற்றும் பகுப்பாய்வு.',
        featureCaption:
            'ஊழியர்களின் பணி, கூட்டத்தின் நிமிடங்கள், சான்றிதழ்களைச் சரிபார்க்கவும், தரவின் அடிப்படையில் முடிவுகள் எடுக்கவும்.',
        keyPoints: [
            {
                header: 'இடையூறு அடிப்படையிலான அறிவிப்புகள்',
                paragraph:
                    'அதிரடி அல்லது மேலாளர் அறிவிப்புகளைப் பெற தாங்கள் விரும்பும் வகையில் உறுப்பினராக அல்லது தெரிவுசெய்ய முடியும். இது உண்மையில் பெற விரும்பும் அறிவிப்புகளை மையமாகக் கொண்டு உதவுகிறது.',
            },
            {
                header: 'வருகை மற்றும் விடுப்புகள் பற்றிய தகவல்களைப் பெறுங்கள்',
                paragraph:
                    'வருகை மற்றும் விடுப்புகள் பற்றிய தகவல்களைப் பெறுங்கள். நாளைத் திட்டமிடுவதற்கு முன்னதாக மக்கள் எண்ணிக்கையைப் அறிந்து, நாள் சிறந்த முறையில் மையமாகச் செய்யவும்.',
            },
            {
                header: 'அனைத்து அனுமதிகளையும் கண்காணிக்கவும்',
                paragraph:
                    'அனைத்து அனுமதிகளை கண்காணிக்கவும். பணிப்பட்டியலின் அனைத்து அனுமதிகளையும் கண்காணிக்கவும் மற்றும் பின்னர் பார்வையிடவும்.',
            },
            {
                header: 'படிவங்கள் மற்றும் MOM உடன் மேம்படுத்தவும்',
                paragraph:
                    'படிவங்கள் மற்றும் கூட்டத்தின் நிமிடங்கள் மூலம் தூர நிலைமையை கண்காணிக்கவும். சிறப்பு படிவங்கள் கூட்டத்திற்கான குறிப்பிட்ட தரவுகளைப் பெற உதவலாம், இது தூரத்தில் அணுகக்கூடியதாக இருக்கலாம்.',
            },
        ],
        softwareDetails: [
            {
                title: 'உங்கள் நிலைமையை நேரடியாகக் கண்டறியவும்.',
                description: [
                    'உங்கள் புலத்துறை ஊழியர்களைப் நேரடியாகக் கண்டறியவும்.',
                    'உங்கள் புலத்துறை ஊழியர்களின் உடனடி நிலைமைகளைப் பெறுங்கள், அவர்கள் பயணிக்கும் போது நேரடியாக.',
                    'Treant உங்கள் புலத்துறை ஊழியர்களைப் ஆன்லைன் மற்றும் ஆஃப்லைன் முறையில் தங்கள் மொபைல் GPS-ஐப் பயன்படுத்தி கண்டறியவும் கண்காணிக்கவும் சாத்தியமாக்குகிறது. உங்கள் புலத்துறை ஊழியர்களின் ஒவ்வொரு அசைவையும் இளமையாக்கவும் ஒரு வெளிப்படையான வேலை சூழலைப் பராமரிக்கவும்.',
                ],
            },
            {
                title: 'உங்கள் ஊழியர்களின் புலத்துறை தகவலுடன் மேம்படுத்தவும்',
                description: [
                    'உங்கள் ஊழியர்களின் புலத்துறை தகவலுடன் மேம்படுத்தவும்.',
                    'புலத்துறை ஊழியர்களின் புலத்துறை தகவல்கள் தானாகவே உங்கள் காட்சிப்பேட்டைத் தாராளமாகப் பரவுகிறது.',
                    'உங்கள் ஊழியர்களின் விவரங்கள், பேட்டரி சதவீதம், GPS, இன்டர்நெட் on/off நேரத் திறன், புலத்தில் செலவழிக்கும் நேரத்தை உடனடியாக உங்கள் காட்சிப்பேட்டியில் அறிவிக்கப்படும்.',
                ],
            },
            {
                title: 'ஊழியர்களின் மீதான அனுமதிகள் மற்றும் சலுகை தரவுகளை தானாகச் செய்யவும்.',
                description: [
                    'துல்லியமான தரவுக்கு ஏ.ஐ. அடிப்படையிலான நிலை வரலாறு',
                    'உங்கள் ஊழியர்களின் நிலை தகவல் ஆர்டிபிஷியல் இன்டலிஜென்ஸ் மூலம் துல்லியமான பாதை மற்றும் கண்காணிப்பு தகவல்களை வழங்கப்படும். நேரடி கண்காணிப்பு தரவுகள் மற்றும் பிற நிலை வரலாறுகள் உங்கள் அஞ்சலுக்கு தானாகவே அனுப்பப்படும், மற்றும் புலத்தில் மற்றும் அதன் அடுத்த நிலையில் என்ன நடக்கிறது என்பதற்கான ஆழமான தகவல்களைப் பெறவும்.',
                ],
            },
        ],
    },
    digitizationOfOrder: {
        featureHeader: 'கட்டளையின் டிஜிடலீசேஷன்',
        featureDescription:
            'வருகை, பணி முடிந்தது அல்லது திட்ட முன்னேற்றம் போன்ற பணி விதிகளின் திடக்கட்டாய மற்றும் பகுப்பாய்வு.',
        featureCaption: 'கட்டளை மேலாண்மையை முடுக்கமாக்குங்கள் மற்றும் கைமுறை பிழைகளை குறைக்கவும்',
        keyPoints: [
            {
                header: 'அறிக்கைகள் மற்றும் பகுப்பாய்வுகள்',
                paragraph:
                    'கட்டளை செயலாக்க செயல்திறனை மற்றும் ஊழியர்களின் உற்பத்தித்திறனை கண்காணிக்க விரிவான அறிக்கைகள் மற்றும் பகுப்பாய்வுகளை உருவாக்குங்கள்.',
            },
            {
                header: 'தானாகவே கட்டளை பதிவு',
                paragraph:
                    'ஊழியர்கள் அல்லது வாடிக்கையாளர்களால் நுழைக்கப்படும் கட்டளை விவரங்களை தானாகவே பிடித்து சரிபார்க்கவும்.',
            },
            {
                header: 'நேரடி கட்டளை கண்காணிப்பு மற்றும் நிலை புதுப்பிப்புகள்',
                paragraph:
                    'கட்டளை நிலை மற்றும் கப்பல் கண்காணிப்பில் நேரடி புதுப்பிப்புகளை ஊழியர்கள் மற்றும் வாடிக்கையாளர்களுக்கு வழங்கவும்.',
            },
            {
                header: 'தானாகவே வேலைப்பாடுகள் மற்றும் பணி ஒதுக்கீடு',
                paragraph:
                    'கட்டளை ஒப்புதலின் செயல்முறைகளை தானாகவே செய்யவும் மற்றும் சரியான ஊழியர்களுக்கு பணி ஒதுக்கீடு செய்யவும்.',
            },
        ],
    },
    targetManagement: {
        featureHeader: 'அம்ச மேலாண்மை',
        featureDescription:
            'வருகை, பணி முடிந்தது அல்லது திட்ட முன்னேற்றம் போன்ற பணி விதிகளின் திடக்கட்டாய மற்றும் பகுப்பாய்வு.',
        featureCaption:
            'ஊழியர்களின் பணிகளை, கூட்டத்தின் நிமிடங்களை, சந்திப்புகளை சரிபார்க்கவும் மற்றும் தரவின் அடிப்படையில் முடிவுகளை எடுக்கவும்.',
        keyPoints: [
            {
                header: 'மைதான கண்காணிப்புடன் ஒருங்கிணைக்கவும்',
                paragraph:
                    'நீங்கள் தினசரி, வாராந்திர மற்றும் மாதாந்திர இலக்குகளை அடையுங்கள் மற்றும் நேரடி மைதான கண்காணிப்புடன் ஊழியர்களின் சாதனைகளை பதிவு செய்யுங்கள்.',
            },
            {
                header: 'நேரடி புதுப்பிப்புகள் மற்றும் ஒத்திசைவு',
                paragraph:
                    'நேரடி முன்னேற்றம் (அடையப்பட்ட இலக்கங்களின் எண்ணிக்கை vs. மீதமுள்ளவை), பங்கேற்பு/வெளியேறும் நேரங்கள், ஒவ்வொரு பணியிலும் செலவிடப்பட்ட நேரம் (தொடர்ச்சி), மற்றும் இடம் கண்காணிப்பு.',
            },
            {
                header: 'சாதனைகள் மற்றும் பகுப்பாய்வுகள்',
                paragraph:
                    'சாதனையியல் அளவீடுகள் (நிறைவு வீதம், எடுத்த நேரம், வாடிக்கையாளர் திருப்தி மதிப்பெண்கள்), இலக்கங்களுடன் ஒப்பீடு (சரியான செயல்திறனை vs. இலக்கு), வரலாற்று தரவுகள் (முந்தைய செயல்திறன் வீதங்கள்).',
            },
            {
                header: 'தானாகவே அறிவிப்புகள் மற்றும் எச்சரிக்கைகள்',
                paragraph:
                    'இலக்கு அடைப்பு எச்சரிக்கைகள், செயல்திறன் நினைவுறுத்தல்கள் மற்றும் அவசர நிலை எச்சரிக்கைகளை ஊழியர்களுக்கு தானாகவே அறிவிப்புகளை அனுப்புங்கள்.',
            },
        ],
    },
    invoiceManagement: {
        featureHeader: 'அஞ்சல் மேலாண்மை',
        featureDescription:
            'வருகை, பணி முடிந்தது அல்லது திட்ட முன்னேற்றம் போன்ற பணி விதிகளின் திடக்கட்டாய மற்றும் பகுப்பாய்வு.',
        featureCaption:
            'தானாகவே எச்சரிக்கைகள் மற்றும் அறிவிப்புகளுடன் உருவாக்கவும், நேரடி அஞ்சல் நிலையை கண்காணிக்கவும் மற்றும் செயலாக்கவும்.',
        keyPoints: [
            {
                header: 'தானாகவே அறிவிப்புகள் மற்றும் நினைவூட்டல்கள்',
                paragraph:
                    'அஞ்சல் நிலையை கண்காணிக்க மற்றும் செலுத்த வேண்டிய மற்றும் தாமதமான கட்டணங்களுக்கு தானாகவே நினைவூட்டல்கள் மற்றும் எச்சரிக்கைகள் அனுப்புவதற்கான அமைப்புகளை அமைக்கவும்.',
            },
            {
                header: 'அஞ்சல் மாதிரிகள்',
                paragraph:
                    'வாடிக்கையாளர் தகவல், ஆணை விவரங்கள், வரி, தள்ளுபடி மற்றும் கட்டண நிபந்தனைகளை உள்ளடக்கிய அனைத்து தேவையான விவரங்களையும் கொண்ட சீரமைக்கப்பட்ட அஞ்சல் மாதிரிகள்.',
            },
            {
                header: 'டிஜிட்டல் கையொப்பம் மற்றும் விநியோகத்தின் ஆதாரம்',
                paragraph:
                    'அஞ்சல் செயல்முறைத்தின் ஒரு பகுதியாக டிஜிட்டல் கையொப்பங்களையும் OTP அடிப்படையிலான விநியோக ஆதாரத்தையும் சேகரிக்கவும்.',
            },
            {
                header: 'அஞ்சல்களை ஒத்திசைவுக்கு மற்றும் நேரடி புதுப்பிப்பு',
                paragraph:
                    'மைய அமைப்புடன் தானாகவே ஒத்திசைவுடன் (மின்னணு அடிப்படையிலான சேமிப்பு) மற்றும் அஞ்சல் நிலையின் நேரடி புதுப்பிப்புகள்.',
            },
        ],
    },
    distributorOrder: {
        featureHeader: 'வகுத்தல் ஆணை',
        featureDescription:
            'வருகை, பணி முடிந்தது அல்லது திட்ட முன்னேற்றம் போன்ற பணி விதிகளின் திடக்கட்டாய மற்றும் பகுப்பாய்வு.',
        featureCaption:
            'அழுத்துகளின் அளவுகளையும், கூட்டங்களின் நிமிடங்களை, சந்திப்புகளைச் சரிபார்க்கவும், தரவின் அடிப்படையில் முடிவுகளை எடுக்கவும்.',
        keyPoints: [
            {
                header: 'வகுத்தல் தகவல்',
                paragraph:
                    'ஒவ்வொரு வகுத்தலுக்கும் தேவையான அனைத்து விவரங்களுடன் ஆணைகளை உருவாக்கவும், செயலாக்கவும் மற்றும் கிடைக்கக்கூடியதின் அடிப்படையில் பணி ஒதுக்கவும்.',
            },
            {
                header: 'நேரடி சில்லறை ஒருங்கிணைப்பு',
                paragraph:
                    'அறிக்கையாளர் மேலாண்மையுடன் ஒருங்கிணைக்கவும், சரியான சரக்கு ஒதுக்கீட்டை உறுதிப்படுத்தவும் மற்றும் நேரடி சந்தையில் அளவுகளை புதுப்பிக்கவும்.',
            },
            {
                header: 'தானாகவே அறிவிப்புகள் மற்றும் எச்சரிக்கைகள்',
                paragraph:
                    'ஆணை செயல்முறையின் முக்கிய நிலைகளில் தானாகவே அறிவிப்புகளை அனுப்புவதற்கான அமைப்பைப் конфிகர் செய்யவும்.',
            },
            {
                header: 'ஊழியர் அட்டவணை',
                paragraph:
                    'ஊழியர்கள் ஆணைகளைச் சரிபார்க்க, முன்னேற்றத்தைத் TRACK செய்ய, தேவையான ஆவணங்களை பதிவிறக்கம் செய்யக்கூடிய அட்டவணையை அணுகவும்.',
            },
        ],
    },
    inventoryManagement: {
        featureHeader: 'சரக்கு மேலாண்மை',
        featureDescription:
            'வருகை, பணி முடிந்தது அல்லது திட்ட முன்னேற்றம் போன்ற பணி விதிகளின் திடக்கட்டாய மற்றும் பகுப்பாய்வு.',
        featureCaption:
            'சரியான சரக்கின் சமநிலை, நேரடி புதுப்பிப்புகள் மற்றும் துல்லியமான தரவுக்கான சரக்கு கண்காணிப்பு.',
        keyPoints: [
            {
                header: 'ஆணை மேலாண்மையுடன் ஒருங்கிணைப்பு',
                paragraph:
                    'நுழையக்கூடிய மற்றும் வெளியேற்றப்படும் ஆணைகளுடன் பங்கு அளவுகளை ஒத்திசைத்தல் உத்திரவாதம் செய்யவும், சரக்கு மற்றும் ஆணை மேலாண்மை அமைப்புகள் இடையே நிலையான ஒருங்கிணைப்பை உறுதிப்படுத்தவும்.',
            },
            {
                header: 'பல இடங்கள் மேலாண்மை',
                paragraph:
                    'பல கையிருப்பு, கடைகள் அல்லது இடங்கள் அடிப்படையில் சரக்குகளை கண்காணிக்கவும், மையமாக்கப்பட்ட கட்டுப்பாட்டை அணுகவும், சரக்கு விநியோகத்தை ஊக்குவிக்கவும் மற்றும் தெளிவை மேம்படுத்தவும்.',
            },
        ],
    },
    orderCollectionManagement: {
        featureHeader: 'ஆர்டர்/சேகரிப்பு மேலாண்மை',
        featureDescription: 'ஆர்டர்கள்/சேகரிப்பு செயல்பாடுகளை எளிதாக்குவதற்கான அளவுருவான மற்றும் தன்னடக்கிய தீர்வுடன்.',
        featureCaption: 'ஆர்டர்கள்/சேகரிப்பு செயல்பாடுகளை எளிதாக்குவதற்கான அளவுருவான மற்றும் தன்னடக்கிய தீர்வுடன்.',
        keyPoints: [
            {
                header: 'ஒன்றிணைந்த ஆர்டர் மேலாண்மை அமைப்பு',
                paragraph:
                    'ஆர்டர்கள் நிர்வகிக்க, முன்னேற்றத்தை கண்காணிக்க, மற்றும் நிலைகளை நேரடியாக புதுப்பிக்க தளவாய்ப்பு வழங்கும் மைய அமைப்பு.',
            },
            {
                header: 'வல்லுநர் அடிப்படையிலான அணுகல் கட்டுப்பாடு',
                paragraph:
                    'பங்கு அடிப்படையிலான அணுகல் மற்றும் உரிமைகள் (எ.கா., மேலாளர்கள், தளத்தில் பணியாற்றுபவர்கள், தளத்திற்கு வெளியே பணியாற்றுபவர்கள்) தரவின் பாதுகாப்பு மற்றும் சரியான அணுகலை உறுதிசெய்ய.',
            },
            {
                header: 'நேரடி ஒத்திசைவு',
                paragraph:
                    'எல்லா சாதனங்களிலும் மற்றும் இடங்களில் ஆர்டர் தரவுகளை நேரடியாக ஒத்திசைவாக்கி, அனைவருக்கும் சமீபத்திய தகவல்களை அணுகுமாறு உறுதிப்படுத்தவும்.',
            },
            {
                header: 'அதிரடியான மற்றும் ஒருங்கிணைப்பான தகவல் தொடர்பு',
                paragraph:
                    'ஆன்லைனில் மற்றும் ஆஃப்லைனில் பணியாற்றுபவர்களுக்கு மின்னஞ்சல் மற்றும் அறிவிப்புகள் மூலம் தகவல் தொடர்பை எளிதாக்கவும்.',
            },
        ],
    },
};

export default features;
