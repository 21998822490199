const footer = {
    requestACallBack: 'ஒரு திரும்ப அழைப்பைப் கோருங்கள்',
    freeAccountMessage: 'தற்காலிகமாக உங்கள் இலவச 14-நாட்கள் கணக்கை தற்போது உருவாக்கவும்',
    footerMessage:
        'நிர்வாகப் பணிகளை தானாகவே செய்யவும், செயல்முறைகளை எளிமையாக்கவும், மற்றும் ஊழியர்களின் ஈடுபாட்டை அதிகரிக்கவும், ஊழியர் மேலாண்மை மற்றும் விற்பனை செயல்பாட்டை புரட்சி செய்யும். எங்கள் அம்சங்கள் எளிமையான, சக்திவாய்ந்த மற்றும் எந்தவொரு தொழில்துறைக்கும் எளிதாக பொருந்தக்கூடியவை, இது நிறுவனத்தின் உற்பத்தி திறனை அதிகரிக்க உதவும்.',
    address:
        '922, A விங், நயாட்டி எம்பிரஸ், பேக்கர் மற்றும் கேஜ் இந்தியா பிவிடி லிமிடட் அருகில், விமான் நகர் சாலை, விமான் நகர், 411014, பூனே.',
    rightsReserved: 'டிரேஸ் ஃபோர்ஸ் அனைத்து உரிமைகளும் காப்புக்கொண்டது',
};
export default footer;
