const pricing = {
    attendance: {
        name: 'Assiduité',
        price: '40',
        subLine: 'Idéal pour surveiller les heures de travail',
        features: [
            'Identification biométrique',
            'Identification basée sur la géolocalisation',
            'Option de demi-journée',
            'Déconnexion automatique',
            'Compte des retards',
            'Heure moyenne de connexion',
            'Heures de travail totales',
            'Abonnement automatique à 1 rapport',
            'Assiduité, Moyenne, Connexion/Déconnexion',
            'Rapports',
            'Inclut toutes les fonctionnalités de base',
            'Biométrie',
            'IA de reconnaissance faciale',
            'Kiosque',
            'Option pour plusieurs succursales',
            'Formulaire pour Connexion et Déconnexion',
            'Inclut toutes les fonctionnalités de base et Premium',
        ],
    },
    task: {
        name: 'Tâche',
        price: '100',
        subLine: 'Idéal pour la gestion des tâches',
        features: [
            'Tâche sur le terrain',
            'Tâche de service',
            'Gestion des tâches basée sur la géolocalisation',
            'Connexion/Déconnexion',
            'Heure de départ',
            'Notification préalable',
        ],
    },
    tracking: {
        name: 'Suivi',
        price: '300',
        subLine: 'Idéal pour le suivi en temps réel',
        features: [
            'Tâche sur le terrain',
            'Tâche de service',
            'Gestion des tâches basée sur la géolocalisation',
            'Connexion/Déconnexion',
            'Heure de départ',
            'Suivi en direct',
        ],
    },
    customer: {
        name: 'Client',
        price: '80',
        subLine: 'Gérez les relations avec les clients',
        features: [
            'Communication via Whatsapp, Email et Message',
            'Assignation de tags',
            'Rappels basés sur un intervalle',
            'Visite basée sur la géolocalisation',
            'Historique des visites',
            'Partager la carte de visite',
            'Annonces',
            'Inclut toutes les fonctionnalités de base',
            "Itinéraire basé sur l'IA",
            'Scanneur de cartes de visite',
            'Modèles préfaits pour la communication',
            'Gérer les rappels',
            'Communication via Whatsapp, Email et Message',
            'Assignation de tags',
            'Rappels basés sur un intervalle',
            'Visite basée sur la géolocalisation',
            'Historique des visites',
            'Partager la carte de visite',
            'Annonces',
        ],
    },
    reimbursement: {
        name: 'Remboursement',
        price: '30',
        subLine: 'Automatisez les paiements précis et ponctuels',
        features: [
            'Remboursement basé sur la distance parcourue',
            'Analyse (sous-point)',
            'Basé sur la succursale',
            'Basé sur le mois',
            'Catégorie',
        ],
    },
    leave: {
        name: 'Congé',
        price: '30',
        subLine: 'Pour que les gestionnaires approuvent facilement',
        features: [
            'Report des congés',
            'Gérer les jours fériés',
            'Vérifier les congés accumulés',
            'Demi-journée et travail à domicile',
            'Approuver les congés',
            'Personnaliser la politique pour chaque individu',
        ],
    },
    payroll: {
        name: 'Paie',
        price: '40',
        subLine: 'Calculs de salaire précis',
        features: [
            'Politique de paie',
            'Calcul des avances sur salaire',
            'Résumé des paiements',
            'Calcul en 1 clic',
            'Calcul des salaires horaires et mensuels',
            'Calcul des heures supplémentaires',
        ],
    },
    faceID: {
        name: 'Identification Faciale',
        price: '25',
        subLine: 'Idéal pour renforcer la sécurité',
        features: ["Ajouter l'identification faciale dans l'assiduité en tant qu'option supplémentaire"],
    },
    forms: {
        name: 'Formulaires',
        price: '30',
        subLine: 'Créer et gérer des formulaires numériques',
        features: [
            'Formulaires dynamiques',
            'Création de formulaires personnalisés',
            'Analyse basée sur les formulaires',
            'Formulaires pour événements',
            'Paiement via formulaires',
            'Créer des formulaires personnalisés',
        ],
    },
};

export default pricing;
