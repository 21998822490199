const aIAssistancePage = {
    beatRoute: 'Beat Route',
    assistance: 'Assistance',
    reports: 'Reports',
    chatbot: 'Chatbot',
    reduceOnWheel: 'Reduce On-wheel Travel Costs with Route Optimization Software',
    optimizeYourMarket:
        'Optimize your market coverage and utilize resources efficiently for your sales and distribution operations with the help of our Route planning software that is designed specifically for the FMCG & Consumer Goods industry.',
    optimizeYourSales: 'Optimize your Sales & Distribution Operations with Intelligent Route Planning Software',
    optimizeYourSalesCards: [
        {
            title: 'Customer Profiling & Deduplication',
            description:
                'Gather accurate customer data and GPS location through our front-end app with route planning software.',
        },
        {
            title: 'Territory Viability Assessment',
            description:
                'Gather accurate customer data and GPS location through our front-end app with route planning software.',
        },
        {
            title: 'Sales Route Planning',
            description:
                'Gather accurate customer data and GPS location through our front-end app with route planning software.',
        },
        {
            title: 'Dynamic Route Planning',
            description:
                'Gather accurate customer data and GPS location through our front-end app with route planning software.',
        },
        {
            title: 'Resource Optimization',
            description:
                'Gather accurate customer data and GPS location through our front-end app with route planning software.',
        },
    ],
    beatRoutePlanning: 'Beat Route Planning',
    beatRoutePlanningCards: [
        {
            title: 'Geotag Clients',
            description: 'Expenses are tracked and recorded in an accurate manner',
        },
        {
            title: 'Plan And Upload Beat Plans',
            description: 'Expenses are tracked and recorded in an accurate manner',
        },
        {
            title: 'Monitor Results',
            description: 'Expenses are tracked and recorded in an accurate manner',
        },
    ],
    minimizeTheCost:
        'Minimize the cost of market coverage operations & maximize the daily visits with Optimized Routes',
    aIFeatures: [
        {
            title: 'Customer prioritization',
            paragraph:
                'Intelligent beat planning starts with accurate data about each customer in terms of their business potential, precise GPS location, outlet type and more. Save time and effort of your sales reps when you exactly know which outlets to target for your products. Access the viabilities of new territories with cost estimation. You can use either your own existing data, or gather new data with BeatRoute before running route optimization for delivery operations.',
        },
        {
            title: 'Route analytics',
            paragraph:
                'Intelligent beat planning starts with accurate data about each customer in terms of their business potential, precise GPS location, outlet type and more. Save time and effort of your sales reps when you exactly know which outlets to target for your products. Access the viabilities of new territories with cost estimation. You can use either your own existing data, or gather new data with BeatRoute before running route optimization for delivery operations.',
        },
        {
            title: 'Risk management',
            paragraph:
                'Intelligent beat planning starts with accurate data about each customer in terms of their business potential, precise GPS location, outlet type and more. Save time and effort of your sales reps when you exactly know which outlets to target for your products. Access the viabilities of new territories with cost estimation. You can use either your own existing data, or gather new data with BeatRoute before running route optimization for delivery operations.',
        },
        {
            title: 'Real time adaption',
            paragraph:
                'Intelligent beat planning starts with accurate data about each customer in terms of their business potential, precise GPS location, outlet type and more. Save time and effort of your sales reps when you exactly know which outlets to target for your products. Access the viabilities of new territories with cost estimation. You can use either your own existing data, or gather new data with BeatRoute before running route optimization for delivery operations.',
        },
        {
            title: 'Optimize routing',
            paragraph:
                'Intelligent beat planning starts with accurate data about each customer in terms of their business potential, precise GPS location, outlet type and more. Save time and effort of your sales reps when you exactly know which outlets to target for your products. Access the viabilities of new territories with cost estimation. You can use either your own existing data, or gather new data with BeatRoute before running route optimization for delivery operations.',
        },
    ],
};
export default aIAssistancePage;
