const navbar = {
    industries: 'الصناعات',
    pharmaceuticals: 'الأدوية',
    fieldSales: 'المبيعات الميدانية',
    fmcg: 'السلع الاستهلاكية سريعة الحركة',
    service: 'الخدمات',
    fieldOperations: 'العمليات الميدانية',
    logisticsAndTransportation: 'اللوجستيات والنقل',
    education: 'التعليم',
    products: 'المنتجات',
    pricing: 'التسعير',
    attendanceManagement: 'إدارة الحضور',
    fieldForceManagement: 'إدارة القوة الميدانية',
    company: 'الشركة',
    ourStory: 'قصتنا',
    joinUs: 'انضم إلينا',
    contactUs: 'اتصل بنا',
    integration: 'التكامل',
    becomeAPartner: 'كن شريكًا',
    blog: 'مدونة',
};
export default navbar;
