const products = {
    attendanceManagement: {
        productName: 'உறுப்பினருக்கான மேலாண்மை',
        titleDescription: 'எளிதான அமைப்பு, தானாகவே உள்நுழைவு மற்றும் வெளிநுழைவு, அறிக்கைகள்',
        productCards: [
            {
                title: 'வேலை-வாழ்க்கை சமநிலை',
                data: 'உங்கள் ஊழியர்களின் வேலை-வாழ்க்கை சமநிலையை TraceForce எச்சரிக்கைகள் மூலம் பராமரிக்கவும்.',
            },
            {
                title: 'உறுப்பினர் வகைகள்',
                data: 'திறனாய்வு மேலாளர், திட்ட மேலாளர், குழு மேலாளர் மற்றும் திட்ட பார்வையாளர்களை உள்ளடக்கிய உறுப்பினர் பங்குகள் மூலம் வேலை நிலைத்தன்மையை எளிதாக்கவும்.',
            },
            {
                title: 'படிகாணிப்பில் உதவுகிறது',
                data: 'TraceForce-இன் சمار்ட் நேர அட்டவணைகள், செயல்பாட்டை எளிதாக்கி, செயல்பாட்டு நேரம்,Idle நேரம் மற்றும் பயனுள்ள நேரம் ஆகியவற்றைப் விவரமான அட்டவணை முறையில் காண உதவுகிறது.',
            },
        ],
        whyChooseSubCaption:
            'ஒரு மிகச் சிறந்த செயல்திறன் இடம் மற்றும் அதிகரிக்கப்பட்ட ஒத்துழைப்பைப் அனுபவிக்க மாறுங்கள். அட்டவணைகளை அட்டவணைகளில் மையப்படுத்தவும்.',
        headerCaption: 'TraceForce Attendance Management ஐ ஏன் தேர்வு செய்வது',
        headerT1: 'மிகவும் அதிகமாக செய்யும் பங்கேற்பு கண்காணிப்பு அம்சங்கள்',
        subCaptionT1:
            'ஊழியர் கண்காணிப்பு, தொலைதூர வேலை கண்காணிப்பு, செயல்திறன் கண்காணிப்பு, பங்கேற்பு மேலாண்மை, நேர அட்டவணைகள் மற்றும் மேலும் — அனைத்து இன்றைய தொழில்நுட்பங்களுடன்.',
        features: {
            attendanceMonitoring: {
                featureHeader: 'பங்கேற்பு கண்காணிப்பு',
                featureDescription:
                    'Geofence மண்டலத்தில் அல்லது கிளையிலுள்ள தனிகளின் இருப்பு அல்லது இல்லாத நிலை பற்றிய நேரடி உள்நுழைவு/வெளிநுழைவு தகவல்.',
                featureParagraph:
                    'நேரகால அட்டவணைகள், அதிகமான ஆவணங்கள் மற்றும் பல்வேறு ஆவணங்களை சோதிக்க வேண்டிய தேவை இல்லை. WebWork Time Tracker மூலம் பங்கேற்பு கண்காணிப்பு செயல்முறையை தானாகவே செயல் படுத்தவும், உங்கள் ஊழியர்களின் வேலை நேரத்தை ஒழுங்கமைக்கப்பட்ட வடிவத்தில் காணவும்.',
                fIIPoints: [
                    'Geofence மண்டலத்தில் அல்லது கிளையிலுள்ள தனிகளின் இருப்பு அல்லது இல்லாத நிலை பற்றிய நேரடி தகவல்',
                    'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் வேலை நேரத்தை கண்காணிக்கவும்',
                    'பல கிளைகளின் பங்கேற்பு அறிக்கைகளை தொலைவிலிருந்து அணுகவும்',
                    'பங்கேற்பு சம்பந்தமான தரவுகள், செயல்முறைகள் மற்றும் முறைமைகளை ஒரே மையப்படுத்தப்பட்ட மேடையில் ஒருங்கிணைத்தல்',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'நேரடி பங்கேற்பு',
                        paragraph:
                            'Geofence மண்டலத்தில் அல்லது கிளையிலுள்ள தனிகளின் இருப்பு அல்லது இல்லாத நிலை பற்றிய நேரடி தகவல்',
                    },
                    totalWorkingHoursCalculation: {
                        header: 'மொத்த வேலை நேரம் கணக்கீடு',
                        paragraph: 'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் வேலை நேரத்தை கண்காணிக்கவும்',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'தொலைவிலிருந்து அணுகல் பங்கேற்பு அறிக்கைகள்',
                        paragraph: 'பல கிளைகளின் பங்கேற்பு அறிக்கைகளை தொலைவிலிருந்து அணுகவும்',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'பங்கேற்பு கண்காணிப்பு ஒன்று',
                        description: [
                            'ஊழியர்களின் நேரத்திறனைத் தெளிவுபடுத்தவும்',
                            'இணைபோக்காக ஊழியர்களின் பங்கேற்பை அமைதியாகக் கண்காணிக்கவும்.',
                            'உங்கள் ஊழியர்கள் வேலை இடத்திற்கு நேரத்தில் வந்தது மற்றும் தங்கள் வேலை நேரத்தை முடித்ததாகக் கண்டறிய எளிதாக செயல் படுத்தவும்.',
                            'உங்கள் வேலை இடத்தை மேலும் அமைதியாகக் கொண்டிருக்கவும் மற்றும் மிகவும் பொறுப்புள்ள வேலை சூழலைப் பெற்றுக் கொள்ளவும்.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'பங்கேற்பு கண்காணிப்பு இரண்டு',
                        description: [
                            'பங்கேற்பு தரவுகளின் அறிவுத்திறன் பகுப்பாய்வு',
                            'முன்னணியிலிருந்து செல்லும், தாமதமாக வரும் மற்றும் குறைவாகக் கணக்கிடும் ஆகியவற்றைப் நீங்கள் தேர்வு செய்யக்கூடிய வடிவமைப்புகள்.',
                            'நீங்கள் விரும்பிய தகவல்களை உடனடியாக உருவாக்க உதவுகின்றது.',
                            'நீங்கள் தினசரி அடிப்படையில் பங்கேற்பு கண்காணிக்கலாம் அல்லது நாட்காட்டியில் இருந்து ஒரு இடைவேளை தேர்வு செய்யலாம்.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'பங்கேற்பு கண்காணிப்பு மூன்று',
                        description: [
                            '100% தொடக்கமில்லாத முறையில் நேரடி பங்கேற்பை கண்காணிக்கும் மற்றும் கண்காணிக்கும் பாதுகாப்பான மற்றும் தெளிவான முறை.',
                            'எளிதான அமைப்பு',
                            'தானாகவே உள்நுழைவு மற்றும் வெளியே செல்லுதல் விவரமான அறிக்கைகள்',
                            'படங்கள் மற்றும் நேரக்காணிப்பு',
                            'செயல்பாட்டு நிலை பதிவேடுகள்',
                            'அப் மற்றும் இணையதளப் பயன்பாட்டு கண்காணிப்பு',
                        ],
                    },
                },
            },
            shiftManagement: {
                featureHeader: 'அதிர்வாண்மை மேலாண்மை',
                featureDescription:
                    'தயவுசெய்து வேலை நேரங்களை திட்டமிடவும், துல்லியமான உற்பத்தி மேம்பாடு, இயக்க நிலைகளை பூர்த்தி செய்யவும், வேறுபட்ட நேர இடைவெளிகளுக்குப் பொறுப்பு.',
                fIIPoints: [
                    'தெளிவான மற்றும் நன்கு ஒழுங்கமைக்கப்பட்ட அதிர்வாண்மையை உருவாக்கவும். உங்கள் வணிகத்தின் இயல்பின் அடிப்படையில் வெவ்வேறு வகை அதிர்வாண்மைகளை வரையறுக்கவும்.',
                    'வேர்லோடு மற்றும் பணிகளைப் பகுப்பாய்வு செய்யவும், ஒவ்வொரு அதிர்வாண்மையிலும் தேவையான ஊழியர்களின் எண்ணிக்கையைத் தீர்மானிக்கவும்.',
                    'உங்கள் ஊழியர்கள் தங்கள் அட்டவணைகளை அணுகுவதற்கான மையப்படுத்தப்பட்ட மேடை மற்றும் புதுப்பிப்புகள் அல்லது மாற்றங்கள் குறித்து நம்பகமான தகவல் மூலம் ஒரு நம்பகமான தொடர்பு சேனலை உருவாக்கவும்.',
                    'ஒரே மேடையில் ஊழியர்களின் அட்டவணைகளை மையப்படுத்தி, மேலாளர்களுக்குக் கொடுக்கும், பங்கேற்புகளை கண்காணிக்கவும், பல இடங்களில் வேலைநிறுத்தங்களை மேலாண்மை செய்யவும்.',
                ],
            },
            geoFenceBasedManagement: {
                featureHeader: 'Geo Fence அடிப்படையிலான பங்கேற்பு',
                featureDescription:
                    'அமைக்கப்பட்ட புவியியல் எல்லைகளுக்குள் ஊழியர்களின் இருப்பை கண்காணிக்கவும், உறுதிசெய்யவும் இடத்தில் அடிப்படையிலான தொழில்நுட்பம்.',
                fIIPoints: [
                    'ஊழியர்கள் தங்கள் குறிப்பிட்ட வேலை இடங்களில் இருப்பதை உறுதிசெய்ய சரியான பங்கேற்பு பதிவுகள்.',
                    'Geo-fence உள்ள ஊழியர்களின் இடங்களை கண்காணிக்க நேரடி கண்காணிப்பு செயல்படுத்தவும்.',
                    'வேலை இடம் அல்லது குறிப்பிட்ட பங்கேற்பு இடங்களை சுற்றியுள்ள புவியியல் எல்லைகளை அமைக்கவும்.',
                    'ஆசிரியர்கள் அல்லது HR பணியாளர்களுக்கு புவியியல் எல்லைகளை நுழையும் அல்லது விலகும் ஊழியர்களைப் அறிக்கையிட எச்சரிக்கைகள் அல்லது அறிவிப்புகளை அமைக்கவும்.',
                ],
            },
            multipleClockInOptions: {
                featureHeader: 'பலவகை உள்நுழைவு விருப்பங்கள்',
                featureDescription:
                    'உயிரணுக்கான ஸ்கேனர், மொபைல் பயன்பாடுகள், நேர மணிகள் மற்றும் இணைய அடிப்படையிலான மேடைகளைப் பயன்படுத்தி ஊழியர்கள் தங்களது வேலை நேரங்களை பதிவு செய்யும் சுதந்திரத்தை வழங்கவும்.',
                fIIPoints: [
                    'பல இடங்களில் அமைக்கப்பட்ட நிறுவனங்களுக்கு சுபீட்சமான clock-in விருப்பங்கள்.',
                    'ஊழியர்களுக்கு பங்கேற்பு பதிவுகள் மற்றும் அவர்களின் உள்நுழைவு மற்றும் வெளியே செல்லுதல்களை மேலாண்மை செய்ய உள்நுழைவு புரவி வழங்கவும்.',
                    'மொபைல் பயன்பாடுகள், RFID அட்டைகள் மற்றும் முகத்தை அடையாளம் காண்பதில் உள்ளபடி clock-in விருப்பங்களை வழங்கவும், இதன் மூலம் பல வேலை சூழல்களில் நேரத்தை சரியாகக் கண்காணிக்கலாம்.',
                    'அலர்ட் அறிவிப்புடன் அலுவலக வட்டத்தில் உள்ள ஊழியர்களுக்கான தானாகவே clock-in பங்கேற்பை குறிக்கவும்.',
                ],
            },
            attendanceReport: {
                featureHeader: 'பங்கேற்பு அறிக்கைகள்',
                featureDescription:
                    'ஊழியர்களின் பங்கேற்பு தரவுகளை, வேலை நேரம், இல்லாதநிலை, தாமதமாக வரும், மற்றும் முதலில் விட்டுவிடல் போன்றவற்றைப் குறிப்பிட்ட காலதாமரத்தில் சுருக்கமாகக் கூறவும்.',
                fIIPoints: [
                    'பங்கேற்பு மொத்தத்தில் ஒரு சுருக்கத்தை வழங்கவும், மொத்த வேலை நாட்கள், மொத்த வேலை நேரம் மற்றும் எந்த கூடுதல் நேரத்தையும் உள்ளடக்கவும்.',
                    'அடிக்கடி மாற்றக்கூடிய வகைகளுடன் பல கிளைகளின் பங்கேற்பு அறிக்கைகளை தொலைவிலிருந்து அணுகவும்.',
                    'ஒவ்வொரு ஊழியரின் விடுப்பு மீதமுள்ள நிலைகளை, விடுப்பு நாட்கள், பசி விடுப்பு, மற்றும் பிற விடுப்பு வகைகளை காட்டவும்.',
                    'துறையின்படி, குழுவின்படி அல்லது தனிப்பட்ட ஊழியருக்கான குறிப்பிட்ட அளவுருவின் அடிப்படையில் அறிக்கைகளை தனிப்பயனாக்க அனுமதிக்கவும்.',
                ],
            },
        },
    },
    fieldTrackingManagement: {
        productName: 'புலநிலை சக்தி மேலாண்மை',
        titleDescription: 'புலநிலை விற்பனை மற்றும் சேவைகள் செயல்பாடு நேரடி அறிவிப்புகள் மற்றும் பீட் வழியை உடையது',
        productCards: [
            {
                title: 'குறைவான அதிக செலவுகள்',
                data: 'ஊழியர்களின் அட்டவணைகளை தானாகவே அமைப்பதன் மூலம் செயல்பாட்டு செலவுகளை குறைத்துக் கொள்ளுங்கள். ஊழியர்களின் நேரம், பணிகள் மற்றும் செலவுகளை கண்காணிக்கவும், பணம் சேமிக்கக்கூடிய பகுதிகளை அடையாளம் காணவும்.',
            },
            {
                title: 'நல்ல வாடிக்கையாளர் மதிப்பு',
                data: 'அட்டவணை உத்தி மற்றும் செயல்திறனை நிரூபிக்கவும். வாடிக்கையாளர் தொடர்புகள் மற்றும் கருத்துக்களை கண்காணிக்கவும், இடைவெளிகள் மற்றும் மேம்பாட்டு பகுதிகளை எளிதாகக் கண்டறியவும்.',
            },
            {
                title: 'செயல்திறன் பகுப்பாய்வுகள்',
                data: 'உங்கள் ஊழியர்களின் செயல்திறன், வேலை காலக்கோடு, வாடிக்கையாளர் திருப்தி மற்றும் மேம்பாட்டு பகுதிகளைப் பற்றிய முக்கியமான தகவல்களை வழங்கும் ஒருங்கிணைக்கப்பட்ட டாஷ்போர்ட்களில் எளிதாக அறிக்கைகள் உருவாக்கவும்.',
            },
        ],
        whyChooseSubCaption:
            'உயர்ந்த திறன் புலநிலை சக்தி மேலாண்மை மென்பொருளுடன்\nபின்வரும் துணை தலைப்பு - உங்கள் புலநிலை ஊழியர்களை நேரடி முறையில் கண்காணிக்கவும், பணியின் முன்னேற்றத்தை மானிட்டரிங்கும், அவர்களின் இடம், செயல்பாடு மற்றும் செயல்திறனைப் பற்றிய பார்வைகளைப் பெறவும். பொறுப்பின்மைச் சரிசெய்யவும் மற்றும் தரவுகளின் அடிப்படையிலான முடிவு எடுக்கவும்.',
        headerCaption: 'TraceForce Field Force Management ஐ ஏன் தேர்வு செய்வது',
        headerT1: 'புலநிலை சக்தி மேலாண்மை முறைமைகளின் முக்கிய அம்சங்கள்',
        subCaptionT1:
            'நேரடி ஊழியர் இடம் கண்காணிப்பைப் AI அடிப்படையிலான சீரமைப்புடன் இணைத்துப் பயன்படுத்தி செயல்பாடுகளை எளிதாக்குங்கள், உடனடி பார்வைகளுக்கான நேரடி அறிவிப்புகளை வழங்குங்கள், மற்றும் செயல்திறனை மேம்படுத்த புலநிலை விற்பனை பகுப்பாய்வுகளைப் பயன்படுத்துங்கள். திறம்பட வளங்களை ஒதுக்கவும், செயல்திறனை மேம்படுத்தவும், மற்றும் தரவுகளின் அடிப்படையிலான முடிவு எடுக்கவும்.',

        features: {
            workforceTracking: {
                featureHeader: 'ஊழியர் கண்காணிப்பு',
                featureDescription:
                    'ஊழியர்களின் செயல்பாடுகள், செயல்திறன் மற்றும் பங்கேற்புகளை சீர்மையாக கண்காணித்து நிர்வகிக்கவும்.',
                featureParagraph:
                    'நேர அட்டவணைகள், அதிகமான ஆவணங்கள் மற்றும் பல்வேறு ஆவணங்களைப் பார்க்கவேண்டிய தேவை இல்லை. WebWork Time Tracker உடன் பங்கேற்பு கண்காணிப்பு செயல்முறையை தானாகவே செயல் படுத்தி, உங்கள் ஊழியர்களின் வேலை நேரத்தை ஒழுங்கமைக்கப்பட்ட வடிவத்தில் காணுங்கள்.',
                fIIPoints: [
                    'Clock in மற்றும் Clock Out போன்ற நிகழ்வுகளை கண்காணிக்கவும், ஊழியர்களின் செயல்பாடுகள், செயல்திறன் அளவீடுகள், அல்லது நிகழ்வுகளுக்கு இடையூறு தரவும்.',
                    'நிகழ்வுகளுக்கான தரவுகளை சேமிக்கவும், ஒரு கூட்டத்தின் நிமிடங்களைப் பெற தேவையான பயணத்தைப் குறிக்கவும்.',
                    'ஊழியர்களின் செயல்திறனை, மற்றும் முக்கிய செயல்திறன் அளவீடுகளைக் கணக்கிட மசுதியாக்கவும்.',
                    'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் புலநிலை வேலைக்கு நிகரான விபரங்கள் கண்காணிக்கவும் மற்றும் செலவுகளை மீளக்கூடிய அளவிலானது.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'நேரடி பங்கேற்பு கண்காணிப்பு',
                        paragraph:
                            'Clock in மற்றும் Clock Out போன்ற நிகழ்வுகளை கண்காணிக்கவும், ஊழியர்களின் செயல்பாடுகள் மற்றும் செயல்திறன் அளவீடுகளுக்கு இடையூறு தரவும்.',
                    },
                    customFormsforDataManagement: {
                        header: 'தனிப்பயனாக்கப்பட்ட வடிவங்கள் தரவுப் பராமரிப்பு',
                        paragraph: 'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் வேலை நேரத்தை கண்காணிக்கவும்.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'தொலைவிலிருந்து அணுகல் பங்கேற்பு அறிக்கைகள்',
                        paragraph: 'பல கிளைகளின் பங்கேற்பு அறிக்கைகளை தொலைவிலிருந்து அணுகவும்.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'பங்கேற்பு கண்காணிப்பு ஒன்று',
                        description: [
                            'ஊழியர்களின் நேரத்திறனைத் தெளிவுபடுத்தவும்',
                            'எவ்விதக் கூடுதல் நடவடிக்கையுடன் ஊழியர்களின் பங்கேற்பைச் சேமிக்கவும்.',
                            'உங்கள் ஊழியர்கள் நேரத்தில் வேலை இடத்திற்கு வந்ததையும், வேலை நேரத்தை முடித்ததையும் எளிதாகக் கண்காணிக்கவும்.',
                            'உங்கள் வேலை இடத்தை மேலும் அமைதியாகக் கொண்டிருக்கவும் மற்றும் மிகவும் பொறுப்புள்ள வேலை சூழலைப் பெற்றுக் கொள்ளவும்.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'பங்கேற்பு கண்காணிப்பு இரண்டு',
                        description: [
                            'பங்கேற்பு தரவுகளின் அறிவுத்திறன் பகுப்பாய்வு',
                            'முன்னணியிலிருந்து செல்லும், தாமதமாக வரும் மற்றும் குறைவாகக் கணக்கிடும் ஆகியவற்றைப் நீங்கள் தேர்வு செய்யக்கூடிய வடிவமைப்புகள்.',
                            'உங்கள் விருப்பமான தகவல்களை உடனடியாக உருவாக்க உதவுகின்றது.',
                            'நீங்கள் தினசரி அடிப்படையில் பங்கேற்பை கண்காணிக்கலாம் அல்லது நாட்காட்டியில் இருந்து ஒரு இடைவேளை தேர்வு செய்யலாம்.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        imagePath: '',
                        title: 'பங்கேற்பு கண்காணிப்பு மூன்று',
                        description: [
                            '100% தொடக்கமில்லாத முறையில் நேரடி பங்கேற்பை கண்காணிக்கும் மற்றும் கண்காணிக்கும் பாதுகாப்பான மற்றும் தெளிவான முறை.',
                            'எளிதான அமைப்பு',
                            'தானாகவே உள்நுழைவு மற்றும் வெளியே செல்லுதல் விவரமான அறிக்கைகள்',
                            'படங்கள் மற்றும் நேரக்காணிப்பு',
                            'செயல்பாட்டு நிலை பதிவேடுகள்',
                            'அப் மற்றும் இணையதளப் பயன்பாட்டு கண்காணிப்பு',
                        ],
                    },
                },
            },
            fieldSalesAnalytics: {
                featureHeader: 'புலநிலை விற்பனை பகுப்பாய்வுகள்',
                featureDescription:
                    'விற்பனை வரவு, வாடிக்கையாளர் தொடர்புகள் மற்றும் புவியியல் பழக்கவழக்கங்களைப் போன்ற முக்கிய அளவீடுகளைப் புலநிலையிலுள்ள விற்பனை அணி கண்காணிக்கவும் மற்றும் பகுப்பாய்வு செய்யவும்.',
                featureParagraph:
                    'நேர அட்டவணைகள், அதிகமான ஆவணங்கள் மற்றும் பல்வேறு ஆவணங்களைப் பார்க்கவேண்டிய தேவை இல்லை. WebWork Time Tracker உடன் பங்கேற்பு கண்காணிப்பு செயல்முறையை தானாகவே செயல் படுத்தி, உங்கள் ஊழியர்களின் வேலை நேரத்தை ஒழுங்கமைக்கப்பட்ட வடிவத்தில் காணுங்கள்.',
                fIIPoints: [
                    'தரவுகளின் அடிப்படையில் முடிவு எடுக்க உதவுவதற்கான முக்கிய செயல்திறன் அளவீடுகள். இது அடுத்த முறையாக அல்லது உத்தியோகபூர்வமாக உங்கள் திட்டத்தை மறுசீரமைக்க உதவும்.',
                    'விற்பனை, தற்காலிகம் மற்றும் வாடிக்கையாளர் மேம்பாட்டைப் பற்றிய அறிக்கைகள் தினசரி, வாராந்திர அல்லது மாதாந்திர அடிப்படையில் அணுகலாம். இது நபரின் செயல்திறனை மற்றும் திறமையை உதவுகிறது.',
                    'அக்கட்சிகளின் நேர்மை சரிபார்ப்புகளை உறுதிப்படுத்தும் வகையில் Geofence அடிப்படையிலான சரிபார்ப்புகளை சோதிக்கவும். படத்தைப் பகிர்வது போது இடத்தின் கோர்டினேட்களை இணைக்கவும்.',
                    'ஊழியர்கள் முந்தைய திட்டங்களை திட்டமிடுவதற்காக Beat Route ஐப் பயன்படுத்தவும். உங்கள் முறைமையை நேரடியாக வரைபடங்களில் ஒருங்கிணைக்கவும்.',
                ],
            },
            locationBasedAttendance: {
                featureHeader: 'இட அடிப்படையிலான பங்கேற்பு',
                featureDescription:
                    'ஊழியர்கள் முன்னதாகவே வரையறுக்கப்பட்ட புவியியல் எல்லைகளை உள்ளே அல்லது வெளியே சென்று clock in அல்லது out செய்யலாம்.',
                fIIPoints: [
                    'தனித்துவமான இடங்களின் சுற்றுப்புற எல்லைகளை இடமாற்றத்திற்கான புலநிலையை செயல்படுத்தவும். நிபந்தனைக்கான எல்லையை நுழையும் அல்லது விடுபடும் ஊழியர்களின் அடிப்படையில் பங்கேற்பை தானாகவே செயல் படுத்தவும்.',
                    'PIN கள், உயிரணுக்கோட்டுகள் அல்லது முகம் அடையாளம் காண்பதற்கான எதையாவது பயன்படுத்துவதன் மூலம் பங்கேற்பு தரவின் சரியான மற்றும் பாதுகாப்பான தன்மை உறுதி செய்யவும்.',
                    'பங்கேற்பு பதிவுகள் தற்காலிகமாகப் புதுப்பிக்கப்படும், மேலாண்மைக் கடமைகளுக்கு தகுந்த நேரத்தில் முடிவுகள் எடுக்கவும்.',
                    'தொலைவிலுள்ள ஊழியர்களுக்கான பங்கேற்பு கண்காணிப்பிற்கான மாற்று முறைகளைப் பரிசீலிக்கவும்.',
                ],
            },
            liveReporting: {
                featureHeader: 'நேரடி அறிவிப்புகள்',
                featureDescription:
                    'பங்கேற்பு, முடிக்கப்பட்ட பணிகள் அல்லது திட்ட முன்னேற்றம் போன்ற பணியிடத்தின் பல அம்சங்களை நேரடியாக கண்காணிக்கவும் மற்றும் பகுப்பாய்வு செய்யவும்.',
                fIIPoints: [
                    'ஆட்மின் அல்லது மேலாளர் அவர்கள் பெற விரும்பும் அறிவிப்புகளை சந்திக்க அல்லது தேர்வு செய்யலாம். இது அவர்கள் உண்மையில் தேவையான அறிவிப்புகளில் கவனம் செலுத்த உதவும்.',
                    'பங்கேற்பு மற்றும் விடுப்புகளுக்கான அறிவிப்புகளைப் பெறுங்கள். இது ஒரு நாளைக்கு முன்னதாகவும், அதிகமாகவும் இருப்பதை அறிந்து, அந்த நாளை மேலும் உற்பத்தியாக்கவும் உதவும்.',
                    'அனைத்து அங்கீகாரங்களைப் கண்காணிக்கவும், மீளாய்வுக்கான முறைமைகள் மற்றும் விடுப்புகள் உள்ளிட்டவை.',
                    'ஊடகங்கள் மற்றும் கூட்டங்களுக்கான தனிப்பயனாக்கப்பட்ட வடிவங்களைப் பயன்படுத்தி தொலைவிலிருந்து கண்காணிக்கவும், இந்த தரவுகளைப் அணுகவும்.',
                ],
            },
            aIBasedOptimization: {
                featureHeader: 'AI அடிப்படையிலான சீரமைப்பு',
                featureDescription:
                    'புலநிலையிலுள்ள பிரதிநிதிகள் அல்லது விற்பனைப் பணியாளர்களுக்கான பாதைகளை AI Beat Route அடிப்படையிலான திட்டமிடல் மற்றும் தானாகவே சரிசெய்யவும்.',
                fIIPoints: [
                    'உதவியிருக்கும் நேரடி தரவுகள், நேரடி போக்குவரத்து அப்டேட், வானிலை நிலைகள், மற்றும் சாலை மூடல்களைப் போன்றவை, தற்போதைய சூழ்நிலைகளின் அடிப்படையில் பாதைகளை அடிக்கடி மாற்றவும்.',
                    'சீரமைக்கப்பட்ட பாதைகளைப் பார்க்கவும், தேவையான திருத்தங்களைச் செய்யவும். இது மொபைல் செயலியில் அல்லது இணைய அடிப்படையிலான தளத்தில் இடம்பெறலாம்.',
                    'AI முறைமையைப் பயன்படுத்தி மாறுபட்ட பாதைகள் அல்லது கமிடிகளை கவனிக்கவும்.',
                    'வழிமொழிகள் மற்றும் புவியியல் தரவுகளைப் பயன்படுத்தி சாலை நெட்வொர்க், போக்குவரத்து நிலைகள் மற்றும் மற்ற இடம் சார்ந்த தகவல்களைப் புரிந்து கொள்ளுங்கள்.',
                ],
            },
            taskManagement: {
                featureHeader: 'பணி மேலாண்மை',
                featureDescription:
                    'சரியான இலக்குகளை அல்லது நோக்கங்களை அடைய பணிகளை திட்டமிடுதல், முன்னுரிமை அளித்தல் மற்றும் திறம்பட செயல்படுத்தல்.',
                featureParagraph:
                    'நேர அட்டவணைகள், அதிகமான ஆவணங்கள் மற்றும் பல்வேறு ஆவணங்களைப் பார்க்கவேண்டிய தேவை இல்லை. WebWork Time Tracker உடன் பங்கேற்பு கண்காணிப்பு செயல்முறையை தானாகவே செயல் படுத்தி, உங்கள் ஊழியர்களின் வேலை நேரத்தை ஒழுங்கமைக்கப்பட்ட வடிவத்தில் காணுங்கள்.',
                fIIPoints: [
                    'பணி பாகைகள், பணி நிலைகளைச் சரிபார்க்கவும் மற்றும் செலவுக்கேற்ப நேரத்தை கண்காணிக்கவும் நேரடியாக முன்னேற்றத்தை கண்காணிக்கவும்.',
                    'பணி மேலாண்மை ஒத்துழைப்பு அம்சங்களைப் பயன்படுத்தி, நேரடியாக குழு உறுப்பினர்களுடன் தொடர்புகொள்ளவும். பணிகள் மீது கருத்து கூறவும், கேள்விகள் கேட்கவும் மற்றும் அனைவரையும் தகவலால் உணர்த்தவும்.',
                    'புள்ளிவிபரங்களையும், மற்றும் முக்கிய செயல்திறன் அளவீடுகளையும் கண்காணிக்கவும்.',
                    'திட்டங்களை, துறைகளின் நோக்கங்களை, தனிப்பட்ட செயல்திறன் இலக்குகளை, ஆவணங்களை வகுப்புகள் மற்றும் முக்கியமான நிலை ஆகியவற்றை ஒழுங்குபடுத்தும் மேம்பட்ட அமைப்பு.',
                ],
            },
        },
    },
    payrollAndLeaveManagement: {
        productName: 'ஊதிய மற்றும் விடுப்பு மேலாண்மை',
        titleDescription: 'ஊதிய செயலாக்கம் மற்றும் விடுப்பு நிர்வாகத்தை மேம்படுத்துங்கள்',
        headerCaption: 'ஏன் TraceForce Lead Management CRM ஐ தேர்வு செய்வது',
        productCards: [
            {
                title: 'மேலாண்மையின் எளிது',
                data: 'வாடிக்கையாளர் தகவல்களை மையமாகக் கொண்டு, நிட்சமுள்ள பணிகளை தானாகவே செய்யும் மற்றும் தொடர்புகொள்வுகளை எளிதாக்கி மேலாண்மையை எளிதாக்குங்கள்.',
            },
            {
                title: 'தனிப்பயனாக்குதல்',
                data: 'தனிப்பயனாக்குதல்',
            },
            {
                title: 'இணைப்புகள்',
                data: 'தரவுகளைப் பகிர, பணிகளை தானாகவே செய்ய மற்றும் அணிக்குள் ஒத்துழைப்பைப் மேம்படுத்துவதற்கான இணைப்புகளைச் செயல்படுத்துங்கள், இது மேலும் திறம்பட மற்றும் விளைவுடைய CRM செயலாக்கத்தை உருவாக்கும்.',
            },
        ],
        whyChooseSubCaption:
            'வ Lead களை திறம்பட கண்காணிக்கவும் மற்றும் பராமரிக்கவும், எந்த வாய்ப்பும் இழக்காமல், மாற்று விகிதங்களை மேம்படுத்தவும்.',
        headerT1: 'TraceForce Lead Management அமைப்பின் நன்மைகள்',
        subCaptionT1:
            'Lead பிடிக்கும் தானாகவே செயலாக்கம் மற்றும் பகிர்வு\nநேரடி தரவுகள் இணைப்பு மற்றும் அறிக்கைகள்\nவிற்பனை மற்றும் சந்தைப்படுத்தல் கருவிகள் தானாகவே',

        features: {
            leadManagement: {
                featureHeader: 'ஊதிய செயலாக்கம்',
                featureDescription:
                    'நேரத்தை கடைபிடிக்கவும், தனிப்பயனாக்கப்பட்ட தொடர்புகளை உருவாக்கவும், மற்றும் மாற்று விகிதங்களை அதிகரிக்கவும்.',
                featureParagraph:
                    'நேர அட்டவணைகள், அதிகமான ஆவணங்கள் மற்றும் பல்வேறு ஆவணங்களைப் பார்க்கவேண்டிய தேவை இல்லை. WebWork Time Tracker உடன் பங்கேற்பு கண்காணிப்பு செயல்முறையை தானாகவே செயல் படுத்தி, உங்கள் ஊழியர்களின் வேலை நேரத்தை ஒழுங்கமைக்கப்பட்ட வடிவத்தில் காணுங்கள்.',
                fIIPoints: [
                    'விற்பனை அணி நேரம் மற்றும் இடத்தை அடிப்படையாக்கொண்டு உங்கள் Lead மேலாண்மை செயல்முறையை ஒழுங்குபடுத்துங்கள், குறைவான காலத்தில் குறைவாக இலக்குகளை அடைய எளிதாக்குங்கள்.',
                    'தளவாரச் சந்தை வரலாறுகளைப் பயன்படுத்தி, வலைத்தள பார்வைகள், மின்னஞ்சல் தொடர்புகள் மற்றும் மேலும் அடிப்படையிலான வகைப்படுத்தங்களை உருவாக்குங்கள். இந்த தரவின் அடிப்படையில் விற்பனை அணியை வகைப்படுத்துங்கள்.',
                    'Lead மதிப்பெண்களைப் பயன்படுத்தி, உங்கள் விற்பனை அணிக்கான முன்னணி திறனுள்ளவர்கள் என பிரித்து, அல்லது மாற்றுவதற்கான நிலைகளை அமைக்கவும்.',
                    'ஒவ்வொரு Lead இன் வரலாறையும் எளிதாக அணுகுங்கள், அதில் நிறுவனத் தகவல்களும், உங்கள் வணிகத்துடனான ஒவ்வொரு தொடர்பின் காலவரிசையும் உள்ளன.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'நேர அடிப்படையிலான பங்கேற்பு கண்காணிப்பு',
                        paragraph:
                            'Clock in மற்றும் Clock Out போன்ற நிகழ்வுகளை கண்காணிக்கவும், ஊழியர்களின் செயல்பாடுகள் மற்றும் செயல்திறன் அளவீடுகளுக்கு இடையூறு தரவும்.',
                    },
                    customFormsforDataManagement: {
                        header: 'தனிப்பயனாக்கப்பட்ட வடிவங்கள் தரவுப் பராமரிப்பு',
                        paragraph: 'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் வேலை நேரத்தை கண்காணிக்கவும்.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'தொலைவிலிருந்து அணுகல் பங்கேற்பு அறிக்கைகள்',
                        paragraph: 'பல கிளைகளின் பங்கேற்பு அறிக்கைகளை தொலைவிலிருந்து அணுகவும்.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'பங்கேற்பு கண்காணிப்பு ஒன்று',
                        description: [
                            'ஊழியர்களின் நேரத்தைப் பற்றிய தெளிவுபடுத்துங்கள்',
                            'எவ்விதக் கூடுதல் நடவடிக்கையுடன் ஊழியர்களின் பங்கேற்பைச் சேமிக்கவும்.',
                            'உங்கள் ஊழியர்கள் நேரத்தில் வேலை இடத்திற்கு வந்ததையும், வேலை நேரத்தை முடித்ததையும் எளிதாகக் கண்காணிக்கவும்.',
                            'உங்கள் வேலை இடத்தை மேலும் அமைதியாகக் கொண்டிருக்கவும் மற்றும் மிகவும் பொறுப்புள்ள வேலை சூழலைப் பெற்றுக் கொள்ளவும்.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'பங்கேற்பு கண்காணிப்பு இரண்டு',
                        description: [
                            'பங்கேற்பு தரவுகளின் அறிவுத்திறன் பகுப்பாய்வு',
                            'முன்னணியிலிருந்து செல்லும், தாமதமாக வரும் மற்றும் குறைவாகக் கணக்கிடும் ஆகியவற்றைப் நீங்கள் தேர்வு செய்யக்கூடிய வடிவமைப்புகள்.',
                            'உங்கள் விருப்பமான தகவல்களை உடனடியாக உருவாக்க உதவுகின்றது.',
                            'நீங்கள் தினசரி அடிப்படையில் பங்கேற்பை கண்காணிக்கலாம் அல்லது நாட்காட்டியில் இருந்து ஒரு இடைவேளை தேர்வு செய்யலாம்.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'பங்கேற்பு கண்காணிப்பு மூன்று',
                        description: [
                            '100% தொடக்கமில்லாத முறையில் நேரடி பங்கேற்பை கண்காணிக்கும் மற்றும் கண்காணிக்கும் பாதுகாப்பான மற்றும் தெளிவான முறை.',
                            'எளிதான அமைப்பு',
                            'தானாகவே உள்நுழைவு மற்றும் வெளியே செல்லுதல் விவரமான அறிக்கைகள்',
                            'படங்கள் மற்றும் நேரக்காணிப்பு',
                            'செயல்பாட்டு நிலை பதிவேடுகள்',
                            'அப் மற்றும் இணையதளப் பயன்பாட்டு கண்காணிப்பு',
                        ],
                    },
                },
            },
            leaveManagement: {
                featureHeader: 'விடுப்பு மேலாண்மை',
                featureDescription:
                    'தலைக்கணக்குகளை திறம்பட கையாளவும், சரியான ஆவணங்களை உறுதி செய்யவும், நிறுவனக் கொள்கைகளைப் பின்பற்றவும் மற்றும் மெதுவாகத் தெளிவான வேலைச்சூழலைக் வைத்துக்கொள்ளவும்.',
                featureParagraph:
                    'நேர அட்டவணைகள், அதிகமான ஆவணங்கள் மற்றும் பல்வேறு ஆவணங்களைப் பார்க்கவேண்டிய தேவை இல்லை. WebWork Time Tracker உடன் பங்கேற்பு கண்காணிப்பு செயல்முறையை தானாகவே செயல் படுத்தி, உங்கள் ஊழியர்களின் வேலை நேரத்தை ஒழுங்கமைக்கப்பட்ட வடிவத்தில் காணுங்கள்.',
                fIIPoints: [
                    'விடுப்பு சுருக்கங்களை நிர்வகிக்கவும், ஊழியர்கள் ஒதுக்கப்பட்ட அளவுகளை மீறாமல், மற்றும் எதிர்மறை விடுப்பு நிலைகளைத் தடுக்கவும்.',
                    'விடுப்பு காலெண்டரை அமைப்பதன் மூலம் அமைப்பின் மாறுபாட்டை உள்ளமைக்கவும். விடுப்பு கோரிக்கைகள் அங்கீகாரம் அல்லது நிராகரிக்கைக்கு முன்னணி அல்லது தொடர்ச்சி இரயிலாக்கத்தை செயல்படுத்தவும்.',
                    'விடுப்பு உரிமைகளைப் பற்றிய தொழிலாளர் சட்டங்கள் மற்றும் ஒழுங்குமுறைகளைப் பற்றிய தகவல்களைப் பெறுங்கள். உங்கள் விடுப்பு மேலாண்மை கொள்கைகள் நிறுவனக் கொள்கைகளைப் பின்பற்றுகின்றனவா என்பதை உறுதி செய்யுங்கள்.',
                    'ஊழியர்களுக்கு சுய சேவை விருப்பங்களை வழங்குங்கள், இது அவர்களுக்கு அவர்களின் விடுப்பு சுருக்கங்களை சரிபார்க்க, கோரிக்கைகளைச் சமர்ப்பிக்க, மற்றும் அவர்களின் கோரிக்கைகள் நிலையைப் பார்க்க உதவும்.',
                ],
            },
        },
    },
    orderManagement: {
        productName: 'ஆர்டர் மேலாண்மை',
        titleDescription:
            'ஆர்டர்கள்/சேகரிப்பு செயல்பாடுகளை எளிதாக்கும் அளவீட்டு மற்றும் தானாகவே செய்யக்கூடிய தீர்வுடன்.',
        headerCaption: 'ஏன் TraceForce Lead Management CRM ஐ தேர்வு செய்வது',
        productCards: [
            {
                title: 'மேலாண்மையின் எளிது',
                data: 'வாடிக்கையாளர் தகவல்களை மையமாகக் கொண்டு, நிட்சமுள்ள பணிகளை தானாகவே செய்யும் மற்றும் தொடர்புகொள்வுகளை எளிதாக்கி மேலாண்மையை எளிதாக்குங்கள்.',
            },
            {
                title: 'தனிப்பயனாக்குதல்',
                data: 'தனிப்பயனாக்குதல்',
            },
            {
                title: 'இணைப்புகள்',
                data: 'தரவைப் பகிர, பணிகளை தானாகவே செய்ய மற்றும் அணிக்குள் ஒத்துழைப்பைப் மேம்படுத்துவதற்கான இணைப்புகளைச் செயல்படுத்துங்கள், இது மேலும் திறம்பட மற்றும் விளைவுடைய CRM செயலாக்கத்தை உருவாக்கும்.',
            },
        ],
        whyChooseSubCaption:
            'வ Lead களை திறம்பட கண்காணிக்கவும் மற்றும் பராமரிக்கவும், எந்த வாய்ப்பும் இழக்காமல், மாற்று விகிதங்களை மேம்படுத்தவும்.',
        headerT1: 'TraceForce Lead Management அமைப்பின் நன்மைகள்',
        subCaptionT1:
            'Lead பிடிக்கும் தானாகவே செயலாக்கம் மற்றும் பகிர்வு\nநேரடி தரவுகள் இணைப்பு மற்றும் அறிக்கைகள்\nவிற்பனை மற்றும் சந்தைப்படுத்தல் கருவிகள் தானாகவே',

        features: {
            digitizationOfOrder: {
                featureHeader: 'ஆர்டர் டிஜிட்டைசேஷன்',
                featureDescription: 'ஆர்டர் மேலாண்மையை முடுக்கமாகச் செய்யவும், கையேடு தவறுகளை குறைக்கவும்.',
                featureParagraph:
                    'நேர அட்டவணைகள், அதிகமான ஆவணங்கள் மற்றும் பல்வேறு ஆவணங்களைப் பார்க்கவேண்டிய தேவை இல்லை. WebWork Time Tracker உடன் பங்கேற்பு கண்காணிப்பு செயல்முறையை தானாகவே செயல் படுத்தி, உங்கள் ஊழியர்களின் வேலை நேரத்தை ஒழுங்கமைக்கப்பட்ட வடிவத்தில் காணுங்கள்.',
                fIIPoints: [
                    'ஆர்டர் செயலாக்க செயல்திறனை மற்றும் ஊழியரின் உற்பத்தி திறனை கண்காணிக்க விரிவான அறிக்கைகள் மற்றும் பகுப்பாய்வுகளை உருவாக்குங்கள்.',
                    'ஊழியர்கள் அல்லது வாடிக்கையாளர்களால் உள்ளிடப்பட்ட ஆர்டர் விவரங்களை தானாகவே பிடிக்கவும் மற்றும் சரிபார்க்கவும்.',
                    'ஆர்டர் நிலை மற்றும் பண்டத்தின் கண்காணிப்புக்கு நேரடி புதுப்பிப்புகளை ஊழியர்கள் மற்றும் வாடிக்கையாளர்களுக்கு வழங்குங்கள்.',
                    'ஆர்டர் ஒப்புதிச் செயல்முறைகளை தானாகவே செயல்படுத்துங்கள் மற்றும் சரியான ஊழியர்களுக்கு பணிகளை ஒதுக்குங்கள்.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'நேர அடிப்படையிலான பங்கேற்பு கண்காணிப்பு',
                        paragraph:
                            'Clock in மற்றும் Clock Out போன்ற நிகழ்வுகளை கண்காணிக்கவும், ஊழியர்களின் செயல்பாடுகள் மற்றும் செயல்திறன் அளவீடுகளுக்கு இடையூறு தரவும்.',
                    },
                    customFormsforDataManagement: {
                        header: 'தனிப்பயனாக்கப்பட்ட வடிவங்கள் தரவுப் பராமரிப்பு',
                        paragraph: 'தினசரி, வாராந்திர மற்றும் மாதாந்திர அடிப்படையில் வேலை நேரத்தை கண்காணிக்கவும்.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'தொலைவிலிருந்து அணுகல் பங்கேற்பு அறிக்கைகள்',
                        paragraph: 'பல கிளைகளின் பங்கேற்பு அறிக்கைகளை தொலைவிலிருந்து அணுகவும்.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'பங்கேற்பு கண்காணிப்பு ஒன்று',
                        description: [
                            'ஊழியர்களின் நேரத்தைப் பற்றிய தெளிவுபடுத்துங்கள்',
                            'எவ்விதக் கூடுதல் நடவடிக்கையுடன் ஊழியர்களின் பங்கேற்பைச் சேமிக்கவும்.',
                            'உங்கள் ஊழியர்கள் நேரத்தில் வேலை இடத்திற்கு வந்ததையும், வேலை நேரத்தை முடித்ததையும் எளிதாகக் கண்காணிக்கவும்.',
                            'உங்கள் வேலை இடத்தை மேலும் அமைதியாகக் கொண்டிருக்கவும் மற்றும் மிகவும் பொறுப்புள்ள வேலை சூழலைப் பெற்றுக் கொள்ளவும்.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'பங்கேற்பு கண்காணிப்பு இரண்டு',
                        description: [
                            'பங்கேற்பு தரவுகளின் அறிவுத்திறன் பகுப்பாய்வு',
                            'முன்னணியிலிருந்து செல்லும், தாமதமாக வரும் மற்றும் குறைவாகக் கணக்கிடும் ஆகியவற்றைப் நீங்கள் தேர்வு செய்யக்கூடிய வடிவமைப்புகள்.',
                            'உங்கள் விருப்பமான தகவல்களை உடனடியாக உருவாக்க உதவுகின்றது.',
                            'நீங்கள் தினசரி அடிப்படையில் பங்கேற்பை கண்காணிக்கலாம் அல்லது நாட்காட்டியில் இருந்து ஒரு இடைவேளை தேர்வு செய்யலாம்.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'பங்கேற்பு கண்காணிப்பு மூன்று',
                        description: [
                            '100% தொடக்கமில்லாத முறையில் நேரடி பங்கேற்பை கண்காணிக்கும் மற்றும் கண்காணிக்கும் பாதுகாப்பான மற்றும் தெளிவான முறை.',
                            'எளிதான அமைப்பு',
                            'தானாகவே உள்நுழைவு மற்றும் வெளியே செல்லுதல் விவரமான அறிக்கைகள்',
                            'படங்கள் மற்றும் நேரக்காணிப்பு',
                            'செயல்பாட்டு நிலை பதிவேடுகள்',
                            'அப் மற்றும் இணையதளப் பயன்பாட்டு கண்காணிப்பு',
                        ],
                    },
                },
            },
            targetManagement: {
                featureHeader: 'இலக்கு மேலாண்மை',
                featureDescription:
                    'ஒவ்வொரு ஊழியருக்கும் அவர்களின் பாத்திரங்கள் மற்றும் திறன்களின் அடிப்படையில் தெளிவான மற்றும் அடையக்கூடிய இலக்குகளை அமைக்கவும்.',
                featureParagraph:
                    'நேர அட்டவணைகள், அதிகமான ஆவணங்கள் மற்றும் பல்வேறு ஆவணங்களைப் பார்க்கவேண்டிய தேவை இல்லை. WebWork Time Tracker உடன் பங்கேற்பு கண்காணிப்பு செயல்முறையை தானாகவே செயல் படுத்தி, உங்கள் ஊழியர்களின் வேலை நேரத்தை ஒழுங்கமைக்கப்பட்ட வடிவத்தில் காணுங்கள்.',
                fIIPoints: [
                    'தினசரி, வாராந்திர, மாதாந்திர இலக்குகளை அடையவும் மற்றும் உயர் நிலை புலனீக்கத்துடன் ஊழியர்களின் சாதனைகளைப் பதிவு செய்யவும்.',
                    'நேரடி முன்னேற்றம் (அடைக்கப்பட்ட இலக்குகளின் எண்ணிக்கை மற்றும் மீதமுள்ளவை), Check-in/Check-out நேரங்கள், ஒவ்வொரு பணிக்குமான மணிநேரங்கள் மற்றும் இடம் கண்காணிப்பு.',
                    'செயல்திறன் அளவீடுகள் (முடித்தளவுப் பங்கை, எடுத்த நேரம், வாடிக்கையாளர் திருப்தி மதிப்பீடுகள்), இலக்கங்களுக்கு எதிரான ஒப்பீடு (உண்மையான செயல்திறன் மற்றும் இலக்கு), வரலாற்று தரவுகள் (முந்தைய செயல்திறன் சுழற்சிகள்).',
                    'இலக்கு சாதனை எச்சரிக்கைகள், செயல்திறன் நினைவூட்டல்கள், காலக்கெடுகள் எச்சரிக்கைகள் ஆகியவற்றைப் பற்றிய ஊழியர்களுக்கு தானாகவே அறிவிப்புகளை அனுப்புங்கள்.',
                ],
            },
            invoiceManagement: {
                featureHeader: 'விலைப்பட்டியல் மேலாண்மை',
                featureDescription:
                    'தானாகவே எச்சரிக்கைகள் மற்றும் அறிவிப்புகளுடன், விலைப்பட்டியல் நிலையை மற்றும் செயலாக்கத்தை கண்காணிக்கவும்.',
                fIIPoints: [
                    'விலைப்பட்டியல் நிலையைப் கண்காணிக்கவும், தேவைப்படும் மற்றும் தாமதமாக உள்ள செலவுகளுக்கு தானாகவே நினைவூட்டல்கள் மற்றும் எச்சரிக்கைகளை அனுப்புங்கள்.',
                    'வாடிக்கையாளர் தகவல்கள், ஆர்டர் விவரங்கள், வரி, தள்ளுபடி, மற்றும் பணம் வழங்கல் நிபந்தனைகள் போன்ற அனைத்துப் போதுமான விவரங்களை உள்ளடக்கிய தரப்படுத்தப்பட்ட விலைப்பட்டியல் வடிவங்களை உருவாக்குங்கள்.',
                    'விலைப்பட்டியல் செயல்முறையின் ஒரு பகுதியாக டிஜிட்டல் கையொப்பங்கள் மற்றும் OTP அடிப்படையிலான அங்கீகாரங்களை சேகரிக்கவும்.',
                    'மைய அமைப்புடன் தானாகவே ஒத்திசைக்கவும் (மேக அடிப்படையிலான சேமிப்பு) மற்றும் விலைப்பட்டியல் நிலை மீது நேரடி புதுப்பிப்புகள்.',
                ],
            },
            distributorOrder: {
                featureHeader: 'வகுப்பித்தல் ஆர்டர்',
                featureDescription:
                    'பொருட்கள்/சேகரிப்புகளை நிர்வகிக்கவும் மற்றும் நேரடி புதுப்பிப்புகளுடன் தசவுபாட்டு முன்னுரிமைகளை கையாளவும்.',
                fIIPoints: [
                    'ஒவ்வொரு வகுப்புக்கான அனைத்து தொடர்புடைய விவரங்களுடன் ஆர்டர்களைப் படைக்கவும் மற்றும் செயல்படுத்துங்கள் மற்றும் கிடைக்கக்கூடியதற்கேற்ப பணிகளை ஒதுக்குங்கள்.',
                    'சரியான பொருட்களை ஒதுக்க மற்றும் நேரடி நிலைபடுத்தல்களை மேம்படுத்துவதற்காக இன்வென்டரி மேலாண்மையுடன் இணைக்கவும்.',
                    'ஆர்டர் செயல்முறை முக்கிய நிலைகளில் தானாகவே அறிவிப்புகளை அனுப்பும் அமைப்பை அமைக்கவும்.',
                    'ஊழியர்கள் ஆர்டர்களைப் பார்க்க, முன்னேற்றத்தை கண்காணிக்க மற்றும் தேவையான ஆவணங்களைப் பதிவிறக்க அடிப்படையில் டாஷ்போர்டை அணுகவும்.',
                ],
            },
            inventoryManagement: {
                featureHeader: 'ஆர்ட்டர் மேலாண்மை',
                featureDescription:
                    'சரியான சேகரிப்புகளின் சமநிலை, நேரடி புதுப்பிப்புகள் மற்றும் தரவுகளின் கண்காணிப்பு.',
                fIIPoints: [
                    'வரவேற்கின்ற மற்றும் விற்கப்படும் ஆர்டர்களுடன் ஒருங்கிணைத்தல் மூலம் சரியான காப்புப் நிலைகளை ஒத்திசைக்கவும்.',
                    'பல களங்கள், கடைகள் அல்லது இடங்களில் உள்ள இன்வென்டரியை கண்காணிக்கவும் மற்றும் மைய கட்டுப்பாட்டை அணுகவும், காப்பு பகிர்வு மற்றும் துல்லியத்தை மேம்படுத்தவும்.',
                ],
            },
            orderCollectionManagement: {
                featureHeader: 'ஆர்டர் / சேகரிப்பு மேலாண்மை',
                featureDescription:
                    'ஆர்டர்கள்/சேகரிப்பு செயல்பாடுகளை அளவீட்டு மற்றும் தானாகவே செய்யக்கூடிய தீர்வுடன் எளிதாக்குங்கள்.',
                fIIPoints: [
                    'ஆர்டர்களை நிர்வகிக்க, முன்னேற்றத்தை கண்காணிக்க மற்றும் நிலைகளை நேரடி புதுப்பிப்புகளில் புதுப்பிக்க மையமாகக் கொண்ட அமைப்பு.',
                    'தரவுகள் பாதுகாப்பு மற்றும் சரியான அணுகலை உறுதி செய்ய பயனர் பங்கு அடிப்படையில் அணுகல் மற்றும் அனுமதிகள்.',
                    'எல்லா ஆர்டர் தரவுகளும் அனைத்து சாதனங்கள் மற்றும் இடங்களில் நேரடி புதுப்பிப்புகளில் ஒருங்கிணைக்கப்படும் என்பதை உறுதி செய்யுங்கள்.',
                    'ஆன்லைன் மற்றும் ஆஃப்லைன் ஊழியர்களுக்கிடையிலான தொடர்புகளை உள்ளடக்கி, செயலியில் உள்ள தகவல்களையும் அறிவிப்புகளையும் வழிமொழியுங்கள்.',
                ],
            },
        },
    },
};
export default products;
