const features = {
    attendanceMonitoring: {
        featureSubcaption: 'الكفاءة في كل علامة - حلول مبتكرة لأنظمة إدارة الموظفين',
        featureHeader: 'مراقبة الحضور',
        featureDescription: 'معلومات الوقت الفعلي حول الحضور أو الغياب للأفراد داخل منطقة الجيولينك أو الفرع',
        featureParagraph:
            'لا مزيد من بطاقات الوقت أو الأعمال الورقية الزائدة أو الوثائق المختلفة لفحص حضور الموظفين. قم بأتمتة عملية مراقبة الحضور باستخدام WebWork Time Tracker وشاهد بداية ونهاية ساعات العمل لموظفيك بتنسيق منظم جيدًا',
        featureCaption: 'إدارة حضور فروع متعددة لموظفيك الميدانيين وموظفيك الموجودين في الموقع',
        keyPoints: [
            {
                header: 'الحضور في الوقت الفعلي',
                paragraph: 'معلومات الوقت الفعلي حول الحضور أو الغياب للأفراد داخل منطقة الجيولينك أو الفرع',
            },
            {
                header: 'حساب إجمالي ساعات العمل',
                paragraph: 'مراقبة ساعات العمل بناءً على يومي، أسبوعي وشهري',
            },
            {
                header: 'تقارير الحضور الوصول عن بُعد',
                paragraph: 'الوصول إلى تقارير الحضور لفروع متعددة عن بُعد',
            },
            {
                header: 'إدارة الحضور المركزية',
                paragraph: 'توحيد بيانات الحضور والعمليات والأنظمة في منصة أو نظام مركزي واحد',
            },
        ],
        softwareDetails: [
            {
                title: 'احصل على رؤى حول تتبع الحضور',
                description: [
                    'احصل على شفافية بشأن دقة الموظفين في مواعيدهم',
                    'وفر الوقت ونظم حضور الموظفين دون أي إجراء إضافي.',
                    'راقب بسهولة ما إذا كان موظفوك قد وصلوا إلى مكان العمل في الوقت المناسب وأكملوا ساعات العمل الخاصة بهم أم لا.',
                    'حافظ على عمليات مكان العمل منظمة أكثر وبيئة عمل ذات مسؤولية عالية.',
                ],
                sDKeys: [
                    'تسجيل تلقائي للدخول والخروج للموظفين الموجودين في الموقع عن طريق إعداد محيط الجيولينك.',
                    'تلقي تنبيهات إشعار للموظفين الذين ينسون أو يفشلون في تسجيل الدخول أو الخروج.',
                    'تحديد وتتبع حركات موظفيك الميدانيين في الوقت الفعلي فور تسجيل حضورهم.',
                ],
            },
            {
                title: 'احصل على تقارير مفصلة لمراقبة الحضور',
                description: [
                    'تحليل ذكي لبيانات الحضور',
                    'تصفيه حالات المغادرة المبكرة، والوصول المتأخر، والمتابعة الأقل التي يمكنك اختيارها من قائمة منسدلة.',
                    'سيساعدك ذلك في توليد المعلومات المفضلة لديك فورًا دون الحاجة إلى البحث عنها يدويًا.',
                    'يمكنك تتبع الحضور يوميًا أو يمكنك اختيار فترة من التقويم.',
                ],
                sDKeys: [
                    'احصل على ملخصات الحضور لمنظمتك.',
                    'توليد واستخدام التقارير لعرض الاتجاهات الحيوية داخل فريقك أو الأقسام أو الفروع.',
                    'تلقي تقارير على فترات منتظمة في بريدك الإلكتروني (وقت تسجيل الدخول، وقت تسجيل الخروج، إجمالي ساعات العمل، إجمالي الجلسات).',
                    'بيانات مثمرة لاتخاذ قرارات أفضل.',
                ],
            },
            {
                title: 'استكشف ميزات أخرى للوقت والإنتاجية.',
                description: [
                    'طريقة آمنة وشفافة لمراقبة وتتبع حضور الموظفين في الوقت الفعلي مع نظام بدون تلامس بنسبة 100%.',
                    'إعداد سهل',
                    'تقارير تسجيل دخول وخروج تلقائية مفصلة',
                    'لقطات الشاشة مع وقت التتبع',
                    'تسجيل مستوى النشاط',
                    'مراقبة استخدام التطبيقات والمواقع',
                ],
                sDKeys: [
                    'ثبت أن حضور الموظفين يساعد في إدارة الوقت وتحسين الإنتاجية.',
                    'إلغاء الأعمال الورقية وجمع سجلات حضور الموظفين بدقة.',
                ],
            },
        ],
    },
    leaveManagement: {
        featureHeader: 'إدارة الإجازات',
        feaatureSubcaption: 'تنسيق غيابات الموظفين مع الحفاظ على مستويات الإنتاجية واستمرارية العمليات.',
        featureDescription:
            'إدارة وتتبع الغيابات والحضور بكفاءة، وضمان توثيق صحيح، والالتزام بسياسات الشركة، والحفاظ على استمرارية تدفق العمل بشكل سلس.',
        featureParagraph:
            'لا مزيد من بطاقات الوقت أو الأعمال الورقية الزائدة أو الوثائق المختلفة لفحص حضور الموظفين. قم بأتمتة عملية مراقبة الحضور باستخدام WebWork Time Tracker وشاهد بداية ونهاية ساعات العمل لموظفيك بتنسيق منظم جيدًا.',
        featureCaption: 'ابق على اطلاع بخصوص الإجازات القادمة لموظفيك مسبقًا وخطط ليومك وفقًا لذلك',
        keyPoints: [
            {
                header: 'الحساب التلقائي لتراكمات الإجازات والأرصدة',
                paragraph:
                    'إدارة تراكمات الإجازات، وضمان أن الموظفين لا يتجاوزون المبالغ المخصصة، ومنع الأرصدة السلبية للإجازات.',
            },
            {
                header: 'سياسات الإجازات المرنة وسير العمل للموافقة',
                paragraph:
                    'قم بتكوين سياسات إجازات مرنة للشركة من خلال إعداد تقويم الإجازات. تمكين إعداد التسلسل الهرمي الموازي أو المتسلسل لطلبات الإجازات للموافقة أو الرفض.',
            },
            {
                header: 'الالتزام بقوانين العمل وسياسات الشركة',
                paragraph:
                    'ابق على اطلاع بقوانين العمل واللوائح المتعلقة باستحقاقات الإجازات. تأكد من أن سياسات إدارة الإجازات تتوافق مع سياسات الشركة.',
            },
            {
                header: 'خيارات الخدمة الذاتية',
                paragraph:
                    'وفر للموظفين خيارات الخدمة الذاتية، مما يتيح لهم التحقق من أرصدة إجازاتهم، وتقديم الطلبات، ومشاهدة حالة طلباتهم.',
            },
        ],
        softwareDetails: [
            {
                title: 'سياسات إجازات متعددة ومرنة لفريقك',
                description: [
                    'سياسات إجازات مرنة لتلبية احتياجاتك الدقيقة مع معلمات قابلة للتكوين',
                    'خطط وسياسات إجازات متعددة مصممة لمجموعات الموظفين المختلفة',
                    'دعم لأنواع الإجازات غير المحدودة ومعاملات الإجازات',
                ],
                sDKeys: [
                    'تشمل هذه السياسات أنواع الإجازات مثل الإجازة السنوية، إجازات المرض، إجازات الوالدين، إجازات العزاء، إلخ.',
                    'تحديد معايير الأهلية للسياسات',
                    'معدلات التراكم، قواعد الاستخدام، الوثائق المطلوبة',
                ],
            },
            {
                title: 'الخدمة الذاتية لتقديم طلبات الإجازة وسير عمل الموافقة',
                description: [
                    'الأتمتة تقلل من جهود الموظفين. يقدم الموظفون طلب الإجازة من خلال بوابة الويب أو تطبيق الهاتف المحمول',
                    'متعقب الإجازات لأرصدة الإجازات والمعاملات',
                    'عرض المدير لبيانات الإجازة الخاصة بالفريق وأنماط الإجازات للحصول على رؤى أفضل',
                    'سير العمل مع التصعيد يؤدي إلى نظام سريع وتجربة موظف رائعة',
                    'إشعارات البريد الإلكتروني والتنبيهات',
                ],
                sDKeys: [
                    'يقدم الموظفون طلب الإجازة من خلال بوابة الويب أو تطبيق الهاتف المحمول',
                    'متعقب الإجازات لأرصدة الإجازات والمعاملات',
                    'عرض المدير لبيانات الإجازة الخاصة بالفريق وأنماط الإجازات للحصول على رؤى أفضل',
                    'سير العمل مع التصعيد يؤدي إلى نظام سريع وتجربة موظف رائعة',
                    'إشعارات البريد الإلكتروني والتنبيهات',
                ],
            },
            {
                title: 'الامتثال لسياسات الشركة وقانون العمل',
                description: [
                    'إدارة إجازات الموظفين الفعالة لا تضمن فقط الامتثال لقوانين العمل ولكنها تساهم أيضًا في ثقافة مكان عمل إيجابية. من خلال تنفيذ سياسات إجازات فعالة واستخدام التكنولوجيا، يمكن للمؤسسات تبسيط العملية ودعم حاجة موظفيها للراحة.',
                ],
                sDKeys: [
                    'إدارة التزامات الإجازات بشكل فعال للميزانية وتقليل المخاطر المالية',
                    'توليد بيانات إحصائية للتقارير الفردية لتتبع الأداء على أساس شهري، ربع سنوي أو سنوي',
                    'ابق على اطلاع بخصوص تخطيط القوى العاملة، تخصيص الموارد واستراتيجيات مشاركة الموظفين',
                    'سجلات وتقارير مفصلة وفقًا لقواعد الحكومة',
                    'قائمة إجازات شاملة مخصصة لقوة عاملة متنوعة',
                ],
            },
        ],
    },
    shiftManagement: {
        featureHeader: 'إدارة الورديات',
        feaatureSubcaption:
            'جدولة القوى العاملة مع الأنشطة البيعية، وضمان مستوى الموظفين الأمثل لتلبية احتياجات العملاء وزيادة الإنتاجية',
        featureDescription:
            'تنظيم وجدولة ساعات العمل التي تحسن الإنتاجية، تلبي الاحتياجات التشغيلية، وتضمن التغطية عبر فترات زمنية مختلفة',
        featureParagraph:
            'لا مزيد من بطاقات الوقت أو الأعمال الورقية الزائدة أو الوثائق المختلفة لفحص حضور الموظفين. قم بأتمتة عملية مراقبة الحضور باستخدام WebWork Time Tracker وشاهد بداية ونهاية ساعات العمل لموظفيك بتنسيق منظم جيدًا',
        featureCaption: 'تتبع ورديات موظفيك بناءً على إجمالي ساعات العمل وجلسات متعددة مجمعة',
        keyPoints: [
            {
                header: 'إدارة جداول عمل الموظفين',
                paragraph: 'تطوير جدول ورديات واضح ومنظم بشكل جيد. تحديد أنواع مختلفة من الورديات بناءً على طبيعة عملك',
            },
            {
                header: 'تعريف الورديات، تعيين الأدوار، إعداد جداول متكررة أو مخصصة',
                paragraph: 'تحليل عبء العمل والمهام لتحديد عدد الموظفين المطلوبين خلال الورديات المختلفة',
            },
            {
                header: 'أدوات اتصال مدمجة',
                paragraph: 'منصة مركزية للموظفين للوصول إلى جداولهم، وإنشاء قناة اتصال موثوقة لأي تحديثات أو تغييرات',
            },
            {
                header: 'إدارة مركزية للورديات مع جداول متعددة المواقع',
                paragraph:
                    'الإشراف والتحكم المركزي في جداول الموظفين، مما يتيح للمديرين تخصيص الورديات بكفاءة، وتتبع الحضور، وإدارة موارد القوى العاملة عبر مواقع متعددة من منصة واحدة',
            },
        ],
        softwareDetails: [
            {
                title: 'أتمتة عملية الجدولة بناءً على الموقع والوردية',
                description: [
                    'تحليل عبء العمل والمهام لتحديد عدد الموظفين المطلوبين خلال الورديات المختلفة',
                    'اعتبار ساعات العمل الذروة، التغيرات الموسمية، وأي متطلبات محددة لكل وردية. قد يشمل ذلك الورديات اليومية، الورديات الليلية، الورديات الدوارة، الورديات المنقسمة، أو الجداول المرنة',
                ],
                sDKeys: [
                    'تبديل الورديات بين الموظفين',
                    'يمكن للموظفين تبادل أو تبديل وردياتهم مع موظفين آخرين باستخدام الميزة/الخيار المتاح',
                    'يمكن للموظفين اختيار وردياتهم وأوقات إجازاتهم التي تناسبهم',
                ],
            },
            {
                title: 'لوحة الورديات للجدولة الديناميكية للورديات',
                description: [
                    'ضمان الامتثال لقوانين العمل فيما يتعلق بفترات الاستراحة وفترات الراحة',
                    'بناء فترات استراحة ضمن جدول الورديات لمنع إرهاق الموظفين والحفاظ على الإنتاجية',
                    'تأكد من أن سياسات إدارة الورديات تتماشى مع المتطلبات القانونية',
                ],
                sDKeys: [
                    'إنشاء جداول عمل فعالة لفريقك باستخدام أدوات الجدولة سهلة الاستخدام',
                    'تخصيص الورديات لتناسب الطلبات التشغيلية لعملك وتوفر الموظفين',
                    'ضمان توزيع عادل للورديات مع مراعاة تفضيلات الموظفين وعبء العمل',
                ],
            },
            {
                title: 'تشجيع الموظفين على العمل في ساعات غير تقليدية مع بدلات الورديات',
                description: [
                    'دمج أنظمة إدارة الورديات مع عمليات الرواتب لضمان تعويض دقيق والخصومات بناءً على الورديات التي تم العمل بها',
                    'تعريف إجراءات العمل الإضافي والتأكد من أن سياسات العمل الإضافي تتوافق مع قوانين العمل',
                ],
                sDKeys: [
                    'الموافقة على طلبات الورديات الخاصة بالموظفين بنقرة واحدة',
                    'يمكن للموظفين اختيار ورديتهم الخاصة في بداية الأسبوع/الشهر وإرسال الطلبات من بوابتهم حسب راحتهم',
                    'حل مربح من خلال دفع بدلات الورديات بناءً على سعر الساعة أو سياسة الشركة',
                ],
            },
        ],
    },
    geoFenceBasedManagement: {
        featureHeader: 'الحضور بناءً على السياج الجغرافي',
        feaatureSubcaption: 'زيادة الدقة والمساءلة، تتبع وجود الموظفين ضمن الحدود المحددة',
        featureDescription:
            'تكنولوجيا تعتمد على الموقع لمراقبة والتحقق من وجود الموظفين ضمن الحدود الجغرافية المحددة مسبقًا',
        featureCaption:
            'تعتمد الحضور بناءً على السياج الجغرافي على دقة تتبع الحضور حيث يتم تسجيل حضور الموظفين فقط عندما يكونون في المنطقة المحددة',

        keyPoints: [
            {
                header: 'تحديد مواقع الموظفين بدقة ضمن المنطقة المحددة بالسياج الجغرافي',
                paragraph: 'سجلات حضور دقيقة لضمان وجود الموظفين في مواقع عملهم المحددة',
            },
            {
                header: 'مراقبة مواقع الموظفين في الوقت الحقيقي',
                paragraph: 'تنفيذ تتبع في الوقت الحقيقي لمراقبة مواقع الموظفين ضمن السياج الجغرافي',
            },
            {
                header: 'تحديد الحدود الجغرافية المحددة لتسجيل الدخول أو الخروج',
                paragraph: 'إنشاء حدود جغرافية محددة، تعرف بالسياج الجغرافي، حول مكان العمل أو مواقع الحضور المحددة',
            },
            {
                header: 'تنبيهات السياج الجغرافي',
                paragraph:
                    'إعداد تنبيهات أو إشعارات لإبلاغ المديرين أو موظفي الموارد البشرية عند دخول الموظفين أو مغادرتهم للحدود المحددة بالسياج الجغرافي',
            },
        ],
        softwareDetails: [
            {
                title: 'تقييد الموقع على تسجيل الحضور',
                description: [
                    'تحديد حدود جغرافية محددة باستخدام إحداثيات GPS أو تقنيات تعتمد على الموقع. هذه الحدود تمثل المناطق التي من المتوقع أن يتواجد فيها الموظفون للعمل.',
                ],
                sDKeys: [
                    'ميزة تقييد اختيارية للمستخدمين للسماح بتسجيل الحضور من عنوان IP محدد لضمان الحضور من بالقرب من المكتب.',
                    'سهولة التواصل مع المستخدمين المحددين أو جميع المستخدمين باستخدام ميزة الإشعارات الدفعية.',
                    'ربط المهام بالموظفين الحاضرين لعرض المهام والجداول اليومية.',
                ],
            },
            {
                title: 'تعيين قيود الحضور والتحقق البيومتري',
                description: [
                    'تنفيذ تتبع الموقع في الوقت الحقيقي لمراقبة مواقع الموظفين ضمن المناطق المحددة بالسياج الجغرافي. يتم استخدام هذه المعلومات لتأكيد الحضور وضمان أن الموظفين ضمن مواقع العمل المحددة خلال الساعات المجدولة.',
                ],
                sDKeys: [
                    'يمكن للموظفين تسجيل حضورهم فقط بعد وصولهم إلى موقع مهمتهم الأول.',
                    'يمكن للموظفين تسجيل حضورهم عن بُعد؛ لا مزيد من تسجيل الحضور المعيب أو السفر إلى المكتب يوميًا لتسجيل حضورهم.',
                    'يمكن للمديرين تتبع الموقع في الوقت الحقيقي واستلام التحديثات عن بُعد من خلال تطبيقات الهاتف أو الويب.',
                ],
            },
            {
                title: 'تعزيز الأمان والتفويض',
                description: [
                    'تنفيذ تدابير تحقق آمنة لمنع تسجيل الحضور الاحتيالي باستخدام رموز PIN، والتحقق البيومتري، والتعرف على الوجه.',
                    'إعداد إشعارات أو تنبيهات على الهاتف للموظفين، تذكيرهم بالتحقق عند دخولهم إلى المنطقة المحددة بالسياج الجغرافي. يضمن ذلك أن الموظفين على دراية بنظام تتبع الحضور.',
                ],
            },
        ],
    },
    multipleClockInOptions: {
        featureHeader: 'خيارات تسجيل الحضور المتعددة',
        feaatureSubcaption:
            'توفير المرونة والراحة، خيارات تسجيل الحضور المتعددة تمنح الموظفين القدرة على إدارة وقتهم بفعالية.',
        featureDescription:
            'توفير المرونة للموظفين لتسجيل ساعات عملهم باستخدام الماسحات البيومترية، تطبيقات الهواتف المحمولة، ساعات الحضور، ومنصات الويب.',
        featureCaption:
            'تسهيل استخدام الموظفين الذين يعملون عن بُعد وتمكينهم من تسجيل حضورهم في أوقات متعددة مع جلسات مختلفة',
        keyPoints: [
            {
                header: 'مناسب للمنظمات ذات المواقع المتعددة',
                paragraph: 'خيارات تسجيل الحضور المرنة لإدارة الحضور الميداني وغير الميداني لفروع مختلفة.',
            },
            {
                header: 'بوابة الخدمة الذاتية للموظفين',
                paragraph:
                    'توفير وصول الموظفين إلى بوابة الخدمة الذاتية لسجلات الحضور وإدارة تسجيلاتهم عند الدخول والخروج ضمن السياج الجغرافي.',
            },
            {
                header: 'خيارات متعددة لتسجيل الحضور',
                paragraph:
                    'تشمل خيارات تسجيل الحضور المرنة المسح البيومتري، تطبيقات الهواتف المحمولة، بطاقات RFID، والتعرف على الوجه، مما يضمن تتبع الوقت بسلاسة عبر بيئات العمل المختلفة.',
            },
            {
                header: 'تسجيل الحضور بناءً على الموقع الجغرافي بدقة',
                paragraph: 'تسجيل الحضور التلقائي للموظفين ضمن نطاق مباني المكتب مع إشعار تنبيه.',
            },
        ],
        softwareDetails: [
            {
                title: 'تسجيل الحضور في الموقع وخارجه',
                description: [
                    'تلبي احتياجات بيئات العمل المتنوعة، نظام إدارة حضور الموظفين لدينا يوفر خيارات متعددة لتسجيل الحضور.',
                    'سواء كان الموظفون في الموقع، في الميدان، أو يعملون عن بُعد، يمكنهم تسجيل وقتهم بسهولة عبر المسح البيومتري، التعرف على الوجه، وتسجيل الحضور بناءً على السياج الجغرافي.',
                ],
            },
            {
                title: 'بوابة الخدمة الذاتية للموظفين لمراجعة سجلاتهم',
                description: [
                    'توفر بوابة الخدمة الذاتية للموظفين السيطرة على تسجيلات الدخول والخروج المتعددة.',
                    'مع ميزات سهلة الاستخدام، يمكن للموظفين إدارة ساعات عملهم بسهولة سواء كانوا ينتقلون بين الوردية، المواقع، أو المهام.',
                    'يمنح هذا الموظفين مرونة مع الحفاظ على سجلات الوقت بدقة، مما يعزز الكفاءة، ويُبسط العمليات الإدارية.',
                ],
            },
            {
                title: 'تسجيل الحضور المتعدد للفروع المتعددة',
                description: [
                    'تلبية الاحتياجات الديناميكية لشركتك الممتدة عبر مواقع متعددة وضمان تتبع الحضور بدقة وكفاءة التشغيل.',
                ],
            },
        ],
    },
    attendanceReport: {
        featureHeader: 'تقارير الحضور',
        feaatureSubcaption: 'انعكاس ثاقب لالتزام الفريق ودقته، يتم التقاطه في تقرير واحد.',
        featureDescription:
            'تلخيص بيانات حضور الموظفين، ساعات العمل، الغيابات، التأخيرات، والمغادرات المبكرة ضمن إطار زمني محدد.',
        featureCaption:
            'توليد تقارير تلقائية للفروع المختلفة واستلامها على أساس يومي، أسبوعي، شهري مع أكثر من 40 قالبًا',
        keyPoints: [
            {
                header: 'تلقائيًا تفاصيل الحضور: وقت الدخول، وقت التأخير، إجمالي ساعات العمل، إجمالي الجلسات',
                paragraph:
                    'تقديم ملخص شامل للحضور، بما في ذلك إجمالي عدد أيام العمل، إجمالي الساعات التي عملت، وأي ساعات عمل إضافية.',
            },
            {
                header: 'تقارير الفروع المتعددة',
                paragraph: 'الوصول عن بُعد إلى تقارير الحضور للفروع المتعددة مع فئات قابلة للتخصيص.',
            },
            {
                header: 'الساعات الإضافية وأرصدة الإجازات',
                paragraph:
                    'عرض أرصدة الإجازات لكل موظف، بما في ذلك أيام الإجازات، إجازة المرض، وأي أنواع أخرى من الإجازات التي تم اكتسابها واستخدامها.',
            },
            {
                header: 'تقرير الحضور المخصص',
                paragraph:
                    'السماح للمستخدمين بتخصيص التقارير بناءً على معايير محددة، مثل القسم، الفريق، أو الموظف الفردي.',
            },
        ],
        softwareDetails: [
            {
                title: 'توليد التقارير بناءً على هيكل مؤسستك.',
                description: [
                    'يمكن أن توفر التقارير المنتظمة التي يتم توليدها وتحليلها رؤى قيمة لتحسين إدارة القوة العاملة، تحديد الاتجاهات، وضمان الامتثال للسياسات التنظيمية.',
                    'سيتوقف تفاصيل التقرير على احتياجات وهيكل المؤسسة.',
                ],
                sDKeys: [
                    'تُجمع المعلومات المجمعة من الأطراف المختلفة لتوفير تحليلات تلقائية، مما يقلل من الوقت والموارد التي تُنفق على توليد التقارير يدويًا.',
                    'يمكن للمستخدمين اختيار التحليلات المطلوبة بنقرات قليلة ويمكنهم الاشتراك فيها في أي وقت.',
                    'يمكن تنزيل تقرير تحليلي تجريبي ويمكن مشاهدته كتمثيل فعلي للتقرير المطلوب.',
                ],
            },
            {
                title: 'احصل على تقارير قابلة للتخصيص بسهولة.',
                description: [
                    'تحديد وتسليط الضوء على أي استثناءات أو مخالفات في الحضور، مثل الغيابات غير المصرح بها، التأخير المفرط، أو حالات العمل الإضافي غير المصرح به.',
                    'استخدام الرسوم البيانية أو المخططات لتمثيل اتجاهات ونماذج الحضور بصريًا. هذا يمكن أن يسهل التعرف على الاستثناءات والاتجاهات بسرعة.',
                ],
                sDKeys: [
                    'يمكن للمستخدم الاستفادة من رؤى البيانات التي تم إنشاؤها بطرق متنوعة مثل معالجة الرواتب، تقييمات الأداء، التعويض، إلخ.',
                    'يمكن مشاركة التقارير مع مستخدمين آخرين بناءً على التدفق الهيكلي للسلطة.',
                    'يمكن للشركة أن تكون على علم بحالة العمل وأداء فريقها بأبسط الطرق دون أي تدخل يدوي.',
                ],
            },
            {
                title: 'قلل من الوقت الذي تقضيه في التقارير اليدوية.',
                description: [
                    'تتضمن التفاصيل الأساسية مثل أسماء الموظفين، أرقام التعريف، والانتماءات إلى الأقسام لتوفير سياق لبيانات الحضور.',
                    'تحديد بوضوح نطاق التاريخ والوقت الذي يغطيه التقرير. يساعد ذلك المستخدمين في فهم نطاق البيانات الزمني.',
                    'توفير ملخص شامل للحضور، بما في ذلك إجمالي عدد أيام العمل، إجمالي الساعات التي عملت، وأي ساعات عمل إضافية.',
                ],
            },
        ],
    },
    workforceTracking: {
        featureHeader: 'تتبع القوة العاملة',
        featureDescription: 'المراقبة المنهجية وإدارة أنشطة الموظفين وإنتاجيتهم وحضورهم.',
        featureParagraph:
            'لا مزيد من بطاقات الوقت، الأوراق الزائدة، والمستندات المختلفة للتحقق من حضور الموظفين. قم بأتمتة عملية مراقبة الحضور باستخدام WebWork Time Tracker وشاهد بداية ونهاية ساعات العمل لموظفيك بتنسيق منظم جيدًا.',
        featureCaption:
            'مراقبة وإدارة جوانب مختلفة من القوة العاملة في المنظمة، بما في ذلك حضور الموظفين، ساعات العمل، الإنتاجية، والأداء.',
        keyPoints: [
            {
                header: 'تتبع الحضور والحركة بناءً على الزمن',
                paragraph:
                    'مراقبة الأحداث مثل تسجيل الدخول والخروج، وكذلك رؤى الموظفين حول عملياتهم ومؤشرات الأداء أو الأحداث.',
            },
            {
                header: 'نماذج مخصصة لإدارة البيانات',
                paragraph:
                    'لتوثيق محاضر الاجتماعات، وتخزين البيانات للاجتماعات والمهام لتتبع الاتجاهات واتخاذ القرارات بناءً على البيانات.',
            },
            {
                header: 'إدارة العملاء والقيادات',
                paragraph: 'تتبع الإنتاجية وقياس مؤشرات الأداء الرئيسية بناءً على كمية العمل المنجز من قبل الموظفين.',
            },
            {
                header: 'حساب المسافة الإجمالية لمطالبات التعويض الدقيقة',
                paragraph:
                    'تتبع المسافة الدقيقة التي قطعها الموظفون في العمل الميداني بناءً على مقاييس يومية، أسبوعية، وشهرية، وتعويض النفقات وفقًا للمسافة والأماكن التي تم السفر إليها.',
            },
        ],
        softwareDetails: [
            {
                title: 'تنفيذ أنظمة تتبع الوقت لمراقبة ساعات العمل.',
                description: [
                    'تنفيذ حلول تتبع الوقت لمراقبة الوقت الذي يقضيه الموظفون في المهام والمشروعات.',
                    'يمكن أن يساعد ذلك في فهم استخدام الموارد وتحسين إدارة الوقت.',
                    'استخدام أنظمة إدارة المشاريع لتتبع تقدم المشروع، وتعيين المهام، ومراقبة المواعيد النهائية.',
                    'يساعد ذلك في تحسين تدفقات العمل الخاصة بالمشاريع وضمان إكمالها في الوقت المحدد.',
                ],
            },
            {
                title: 'مراقبة مؤشرات الأداء الرئيسية ذات الصلة.',
                description: [
                    'تحديد وتتبع مؤشرات الأداء الرئيسية ذات الصلة بأهداف مؤسستك.',
                    'يمكن أن تشمل هذه المؤشرات أهداف المبيعات، معايير رضا العملاء، أو مؤشرات الأداء الأخرى.',
                ],
                sDKeys: [
                    'أداء المبيعات: إيرادات المبيعات الشهرية/ربع السنوية، عدد الصفقات المنجزة، معدل تحويل المبيعات، متوسط حجم الصفقة، قيمة خط أنابيب المبيعات.',
                    'خدمة العملاء: درجة رضا العملاء (CSAT)، صافي نقاط المروجين (NPS)، وقت الاستجابة الأول، متوسط وقت الحل، معدل الاحتفاظ بالعملاء، حجم التذاكر.',
                    'العمليات/الإنتاج: إنتاجية/تدفق الإنتاج، مؤشرات الجودة (معدل العيوب، معدل الأخطاء)، أداء التسليم في الوقت المحدد، معدل دوران المخزون، وقت تعطل المعدات، معدل استخدام الموظفين.',
                    'عام: إنتاجية الموظف (الإنتاجية لكل ساعة)، الحضور والانضباط، معدل تحقيق الأهداف، تقييمات الموظف والتقييمات.',
                ],
            },
            {
                title: 'توليد تقارير عن اتجاهات القوة العاملة.',
                description: [
                    'دمج ميزات التحليلات والتقارير للحصول على رؤى حول اتجاهات القوة العاملة والأداء والمجالات التي تحتاج إلى تحسين.',
                    'يمكن أن تساعد التقارير المخصصة في اتخاذ القرارات.',
                ],
                sDKeys: [
                    'مقارنة التركيب الديموغرافي بالقوة العاملة الإجمالية أو معايير الصناعة وتحديد أي اتجاهات أو تحولات ديموغرافية على مدى الزمن.',
                    'مقارنة مؤشرات الأداء عبر فرق ميدانية مختلفة، مناطق، أو فترات زمنية.',
                    'رسم خرائط جغرافية لنشاط القوة الميدانية لتصور التوزيع ومناطق التغطية.',
                    'تحليل أداء المبيعات أو الخدمة حسب المنطقة الجغرافية أو الإقليم. تحديد المناطق ذات الإمكانيات العالية للتوسع أو التحسين.',
                ],
            },
        ],
    },
    fieldSalesAnalytics: {
        featureHeader: 'تحليلات المبيعات الميدانية',
        featureDescription:
            'تتبع وتحليل المؤشرات الرئيسية لفريق المبيعات في الميدان مثل إيرادات المبيعات، التفاعلات مع العملاء، والاتجاهات الجغرافية.',
        featureParagraph:
            'لا مزيد من بطاقات الوقت، الأوراق الزائدة، والمستندات المختلفة للتحقق من حضور الموظفين. قم بأتمتة عملية مراقبة الحضور باستخدام WebWork Time Tracker وشاهد بداية ونهاية ساعات العمل لموظفيك بتنسيق منظم جيدًا.',
        featureCaption:
            'تحليل جمع البيانات وتحليلها وتفسيرها المتعلقة بأنشطة المبيعات الميدانية للحصول على رؤى واتخاذ قرارات مستنيرة.',
        keyPoints: [
            {
                header: 'مؤشر الأداء الرئيسي للمبيعات والعملاء المحتملين',
                paragraph:
                    'يمكن لمؤشر الأداء الرئيسي أن يساعد في اتخاذ قرارات مبنية على البيانات، مما يساعد في اتخاذ قرارات منتجة للمرات القادمة أو إعادة تصميم الاستراتيجية.',
            },
            {
                header: 'تقارير مخصصة',
                paragraph:
                    'يمكن الوصول إلى التقارير على أساس يومي، أسبوعي، أو شهري حول المبيعات، العملاء المحتملين، واكتساب العملاء. يمكن أن يساعد ذلك في زيادة إنتاجية وكفاءة الشخص.',
            },
            {
                header: 'التحقق من زيارة المبيعات وتنظيمها باستخدام الجغرافية',
                paragraph:
                    'التحقق من الزيارة باستخدام تسجيلات الدخول المستندة إلى الجغرافية بدقة. يمكن إرفاق إحداثيات الموقع بصورة كاميرا أثناء المشاركة.',
            },
            {
                header: 'قياس فعالية مسار الزيارة',
                paragraph:
                    'يمكن استخدام مسار الزيارة للتخطيط للزيارات أو خطط الزيارات للموظفين إلى العملاء. دمج خطة الزيارة في الخرائط مباشرة لتحسين التنقل.',
            },
        ],
        softwareDetails: [
            {
                title: 'رؤية يومية لأداء المبيعات بشكل أفضل.',
                description: [
                    'توليد تقارير إلكترونية متزامنة قائمة على تحليل البيانات، ميزة تقارير موثوقة وسهلة الاستخدام للوصول السريع والاستخدام.',
                    'كمدير مبيعات، سيكون لديك نظرة واضحة على تقارير متنوعة سيتم إرسالها إلى بريدك الإلكتروني أو يمكنك عرضها أيضًا في لوحة التحكم الخاصة بك.',
                    'بعض التقارير البارزة تشمل التقارير الشهرية، التقارير حسب موقع الفروع، التقارير الخاصة بالموظفين، وغيرها.',
                ],
            },
            {
                title: 'نظرة عامة على حضور العمل الميداني.',
                description: [
                    'نظرة عامة على حضور الموظفين الميدانيين والزيارات الميدانية.',
                    'الحصول على رؤية كاملة حول حضور القوة العاملة الميدانية والاجتماعات.',
                    'في تقرير الحضور الميداني، ستحصل على بيانات حول وقت تسجيل الحضور الميداني، الموقع، وقت الحضور المتأخر، ساعات العمل، وغيرها.',
                    'بنفس الطريقة، نقدم لك أيضًا تقارير تسجيل الدخول/الخروج للزيارات الميدانية. يتضمن ذلك بيانات متعلقة بالزيارات الميدانية مثل وقت تسجيل الدخول/الخروج، الموقع، المسافة المقطوعة، ملاحظات نتائج الاجتماعات، وغيرها.',
                ],
            },
            {
                title: 'تقرير مستند إلى تحليل البيانات',
                description: [
                    'اشترك وقم بتنزيل التقارير الإلكترونية الشهرية.',
                    'يمكنك الاشتراك وتنزيل التقارير الإلكترونية الشهرية في أي وقت تريده.',
                    'متاحة بصيغة PDF وExcel. مجموعة كاملة من التقارير جاهزة لك للحصول على معلومات مفصلة حول أفعال الموظف الخارجية خارج المنظمة، وهو ما يكون صعباً في الطريقة اليدوية.',
                ],
            },
        ],
    },
    locationBasedAttendance: {
        featureHeader: 'الحضور بناءً على الموقع',
        featureDescription:
            'حضور بناءً على سياج جغرافي لضمان أن الموظفين يمكنهم تسجيل الدخول أو الخروج فقط عندما يكونون ضمن الحدود الجغرافية المحددة مسبقًا.',
        featureCaption: 'الحضور بناءً على الموقع يضمن الدقة في مراقبة وتسجيل حضور الموظفين بناءً على موقعهم الفعلي',
        keyPoints: [
            {
                header: 'السياج الجغرافي',
                paragraph:
                    'تنفيذ حدود افتراضية محيطة بمواقع محددة من مباني المكتب. يساعد في أتمتة الحضور بناءً على دخول الموظف إلى أو مغادرته لمنطقة معينة.',
            },
            {
                header: 'المصادقة والأمان',
                paragraph:
                    'طرق المصادقة لضمان دقة وأمان بيانات الحضور باستخدام الأرقام السرية، البيومترية، أو الحضور بالوجه.',
            },
            {
                header: 'تحديثات في الوقت الفعلي',
                paragraph: 'ضمان تحديث سجلات الحضور على الفور، مما يتيح اتخاذ قرارات إدارية في الوقت المناسب.',
            },
            {
                header: 'اعتبارات العمل عن بُعد',
                paragraph: 'النظر في طرق بديلة لتتبع الحضور للموظفين عن بُعد.',
            },
        ],
        softwareDetails: [
            {
                title: 'وقت وموقع القوة العاملة.',
                description: [
                    'اعرف متى وأين يعمل موظفوك',
                    'اطلب من موظفيك منح الإذن على هواتفهم المحمولة حتى يتمكن من تتبع الوقت والموقع.',
                    'بمجرد منح الإذن، يمكن للتتبع أن يبدأ في العمل بضغطة.',
                ],
            },
            {
                title: 'التقارير الحية',
                description: [
                    'تنفيذ نظام حضور الموظفين بناءً على الموقع يمكن أن يعزز الدقة ويسهل إدارة الحضور. ومع ذلك، من الضروري موازنة الفوائد مع اعتبارات خصوصية الموظف والتواصل بشفافية حول تنفيذ واستخدام مثل هذه الأنظمة.',
                ],
            },
            {
                title: 'تنبيه بناءً على السياج الجغرافي',
                description: [
                    'إعداد تنبيهات تلقائية لأنماط الحضور غير المعتادة أو التباينات. يساعد ذلك في تحديد المشكلات المحتملة، مثل تسجيل الدخول أو الخروج غير المصرح به.',
                    'تنفيذ ميزات التقارير والتحليلات للحصول على رؤى حول أنماط الحضور، الاتجاهات، والاستثناءات.',
                ],
                sDKeys: [
                    'تلقي تنبيهات عند دخول الأصول القيمة مثل المركبات أو المعدات إلى أو مغادرتها المناطق المحددة، مما يساعد على منع السرقة أو الاستخدام غير المصرح به.',
                    'ضمان سلامة العمال الميدانيين من خلال إعداد سياجات جغرافية حول المناطق الخطرة أو مواقع العمل النائية، مما ينبه عند دخول الموظفين إلى المناطق المحظورة.',
                    'عند دخول جهاز إلى أو مغادرته منطقة محاطة بسياج جغرافي، يقوم النظام بتفعيل التنبيهات أو الإشعارات المحددة مسبقًا.',
                    'يواصل النظام مراقبة موقع الأجهزة المتعقبة في الوقت الفعلي ويتحقق مما إذا كانت تتداخل مع أي من السياجات الجغرافية المحددة.',
                ],
            },
        ],
    },
    liveReporting: {
        featureHeader: 'التقارير الحية',
        featureDescription:
            'التتبع والتحليل في الوقت الفعلي لمختلف جوانب العمل، مثل الحضور، المهام المكتملة أو تقدم المشاريع.',
        featureCaption:
            'ابقَ على اطلاع بمهمات الموظفين، دقائق الاجتماعات، تحقق من الزيارات واتخذ قرارات بناءً على البيانات',
        keyPoints: [
            {
                header: 'الإشعارات بناءً على الأحداث',
                paragraph:
                    'يمكن للمسؤول أو المدير الاشتراك في أو اختيار الإشعارات التي يرغبون في تلقيها. يساعد ذلك في التركيز على الإشعارات التي يريدونها حقًا.',
            },
            {
                header: 'ابقَ على اطلاع بالحضور والإجازات',
                paragraph:
                    'ابقَ خطوة واحدة للأمام وكن على علم بعدد الموظفين قبل ذلك حتى يكون تخطيط اليوم أكثر إنتاجية وكفاءة.',
            },
            {
                header: 'تتبع جميع الموافقات',
                paragraph: 'تتبع جميع الموافقات من التعويضات إلى الإجازات وعرضها لاحقًا.',
            },
            {
                header: 'ابقَ محدثًا بالنماذج ودقائق الاجتماعات',
                paragraph:
                    'يمكن أن تساعد النماذج المخصصة في الحصول على بيانات محددة لاجتماع يمكن الوصول إليها عن بُعد.',
            },
        ],
        softwareDetails: [
            {
                title: 'تحديد موقع القوة الميدانية في الوقت الفعلي.',
                description: [
                    'حدد موقع موظفيك الميدانيين في الوقت الفعلي.',
                    'احصل على تفاصيل الموقع الفورية للقوة العاملة الميدانية أثناء سفرهم في الوقت الفعلي.',
                    'تمكن Treant من تحديد موقع وتتبع موظفيك الميدانيين باستخدام GPS الهواتف المحمولة الخاصة بهم سواء عبر الإنترنت أو دون اتصال. ابقَ محدثًا بكل حركة موظفيك الميدانيين واحتفظ ببيئة عمل شفافة.',
                ],
            },
            {
                title: 'ابقَ على اطلاع بمعلومات موظفيك الميدانية.',
                description: [
                    'ابقَ على اطلاع بمعلومات موظفيك الميدانية.',
                    'سيتم مزامنة معلومات الموظفين الميدانيين تلقائيًا مع لوحة القيادة الخاصة بك.',
                    'ستتم إبلاغك على الفور في الوقت الفعلي بتفاصيل مثل نسبة البطارية، GPS، وقت تشغيل/إيقاف الإنترنت، والوقت المثالي الذي يقضونه في الميدان.',
                ],
            },
            {
                title: 'أتمتة بيانات التعويضات والبدلات للموظفين.',
                description: [
                    'تاريخ الموقع المدعوم بالذكاء الاصطناعي للحصول على بيانات دقيقة.',
                    'سيتم تزويد بيانات موقع موظفيك بواسطة الذكاء الاصطناعي لتقديم معلومات دقيقة عن المسار والتتبع. سيتم أتمتة بيانات التتبع الحية وسجلات الموقع الأخرى إلى صندوق بريدك بانتظام للتعمق في ما يحدث في الميدان وخارجه.',
                ],
            },
        ],
    },
    aIBasedOptimization: {
        featureHeader: 'التحسين المبني على الذكاء الاصطناعي',
        featureDescription:
            'تحسين وأتمتة تخطيط الطرق لممثلي الميدان أو موظفي المبيعات بناءً على مسار الذكاء الاصطناعي.',
        featureCaption:
            'قم بتحسين الطرق لليوم وقارن التقارير مع الموسم السابق باستخدام خوارزمية الذكاء الاصطناعي لدينا.',
        keyPoints: [
            {
                header: 'تكامل البيانات في الوقت الفعلي',
                paragraph:
                    'مصادر البيانات في الوقت الفعلي، مثل تحديثات حركة المرور المباشرة، ظروف الطقس، وإغلاقات الطرق، لتعديل الطرق ديناميكيًا بناءً على الظروف الحالية، مما يساعد في التخطيط اليومي بكفاءة.',
            },
            {
                header: 'واجهة المستخدم لتخطيط الطرق',
                paragraph:
                    'عرض الطرق المحسنة، وإجراء أي تعديلات ضرورية. يمكن أن يكون هذا جزءًا من تطبيق موبايل أو منصة قائمة على الويب.',
            },
            {
                header: 'مقاييس الإنتاجية',
                paragraph: 'نظام الذكاء الاصطناعي لأخذ الخصائص الفريدة للمسارات أو الطرق المختلفة في الاعتبار.',
            },
            {
                header: 'الخرائط والبيانات الجغرافية المكانية',
                paragraph:
                    'استخدام خدمات الخرائط والبيانات الجغرافية لفهم شبكة الطرق، ظروف حركة المرور، ومعلومات الموقع الأخرى.',
            },
        ],
        softwareDetails: [
            {
                title: 'تحسين الطرق مع التحديثات في الوقت الفعلي',
                description: [
                    'تحسين وتخطيط أكثر الطرق كفاءة للأفراد أو المركبات، مثل الشاحنات المخصصة للتوصيل، ممثلي المبيعات، أو الفنيين الميدانيين، الذين يتبعون مسارات محددة كجزء من أنشطتهم اليومية.',
                    'يمكن أن تساعد هذه التطبيقات في تحسين الكفاءة، تقليل وقت السفر، وتقليل التكاليف التشغيلية.',
                ],
                sDKeys: [
                    'جمع البيانات من مصادر متعددة، بما في ذلك أجهزة GPS، تقارير حركة المرور، تحديثات الطقس، وأجهزة استشعار المركبات.',
                    'تسريع أوقات الاستجابة والوصول في الوقت المحدد يعزز رضا العملاء، وهو أمر حاسم للأعمال التي تعتمد على الخدمات.',
                ],
            },
            {
                title: 'يتطلب اعتبارًا دقيقًا لقضايا الخصوصية والامتثال',
                description: [
                    'ضمان أن النظام المبني على الذكاء الاصطناعي يتوافق مع اللوائح المحلية، خاصةً تلك المتعلقة بالخصوصية، أمان البيانات، وقوانين النقل.',
                    'تحديث وتحسين خوارزميات الذكاء الاصطناعي بانتظام بناءً على البيانات الجديدة والتغذية الراجعة، بهدف تحسين مستمر لمسارات العمل.',
                ],
            },
            {
                title: 'الدمج مع الأنظمة الأخرى لكفاءة تخطيط الطرق',
                description: [
                    'استخدام خوارزميات التعلم الآلي لتحليل البيانات التاريخية وتوقع الأنماط المستقبلية.',
                    'يمكن أن يساعد ذلك في توقع ظروف حركة المرور، أوقات التسليم، والعوامل الأخرى التي تؤثر على تخطيط الطرق.',
                ],
            },
        ],
    },
    leadManagement: {
        featureHeader: 'إدارة العملاء المحتملين',
        featureDescription:
            'تلخيص بيانات حضور الموظفين، ساعات العمل، الغيابات، التأخيرات، والمغادرات المبكرة ضمن إطار زمني محدد.',
        featureCaption: 'إنشاء تقارير تلقائية لفروع مختلفة واستلامها يوميًا، أسبوعيًا، أو شهريًا مع أكثر من 40 قالبًا.',
        keyPoints: [
            {
                header: 'تفاصيل الحضور التلقائية: وقت تسجيل الدخول، وقت التأخير، إجمالي ساعات العمل، إجمالي الجلسات',
                paragraph:
                    'توفير ملخص شامل للحضور، بما في ذلك إجمالي عدد أيام العمل، إجمالي الساعات التي تم العمل بها، وأي ساعات إضافية.',
            },
            {
                header: 'تقارير الفروع المتعددة',
                paragraph: 'الوصول عن بُعد إلى تقارير حضور الفروع المتعددة مع فئات قابلة للتخصيص.',
            },
            {
                header: 'ساعات العمل الإضافية وأرصدة الإجازات',
                paragraph:
                    'عرض أرصدة الإجازات لكل موظف، بما في ذلك أيام الإجازة، الإجازة المرضية، وأي أنواع أخرى من الإجازات المتراكمة والمستخدمة.',
            },
            {
                header: 'تقرير الحضور المخصص',
                paragraph:
                    'تمكين المستخدمين من تخصيص التقارير بناءً على معايير محددة، مثل القسم، الفريق، أو الموظف الفردي.',
            },
        ],
        softwareDetails: [
            {
                title: 'إنشاء تقارير بناءً على هيكل منظمتك.',
                description: [
                    'يمكن أن توفر التقارير التي يتم إنشاؤها وتحليلها بانتظام رؤى قيمة لتحسين إدارة القوى العاملة، تحديد الاتجاهات، وضمان الامتثال لسياسات المنظمة.',
                    'ستعتمد تفاصيل التقرير على احتياجات وهيكل المنظمة.',
                ],
            },
            {
                title: 'الحصول بسهولة على تقارير قابلة للتخصيص.',
                description: [
                    'تحديد وتسليط الضوء على أي استثناءات أو شذوذات في الحضور، مثل الغيابات غير المصرح بها، التأخيرات المفرطة، أو حالات العمل الإضافي غير المصرح به.',
                    'استخدام الرسوم البيانية أو الرسوم التوضيحية لتمثيل الاتجاهات والأنماط في الحضور بصريًا. يمكن أن يسهل ذلك تحديد الاستثناءات والاتجاهات بسرعة.',
                ],
            },
            {
                title: 'تقليل الوقت المستغرق في التقارير اليدوية.',
                description: [
                    'تتضمن التفاصيل الأساسية مثل أسماء الموظفين، أرقام التعريف، والانتماءات القسمية لتوفير سياق لبيانات الحضور.',
                    'تحديد بوضوح نطاق التاريخ والوقت الذي يغطيه التقرير. يساعد ذلك المستخدمين على فهم نطاق البيانات الزمنية.',
                    'توفير ملخص شامل للحضور، بما في ذلك إجمالي عدد أيام العمل، إجمالي الساعات التي تم العمل بها، وأي ساعات إضافية.',
                ],
            },
        ],
    },
    taskManagement: {
        featureHeader: 'إدارة المهام',
        featureDescription: 'مراقبة وإدارة منهجية لأنشطة الموظفين، الإنتاجية، والحضور.',
        featureParagraph:
            'لا مزيد من بطاقات الوقت، الأوراق المفرطة، والمستندات المختلفة للتحقق من حضور الموظفين. قم بأتمتة عملية مراقبة الحضور باستخدام WebWork Time Tracker وشاهد بدء وانتهاء ساعات عمل موظفيك في تنسيق منظم.',
        featureCaption:
            'راقب واد جوانب مختلفة من القوى العاملة في المنظمة، بما في ذلك حضور الموظفين، ساعات العمل، الإنتاجية، والأداء.',
        keyPoints: [
            {
                header: 'راقب في الوقت الحقيقي',
                paragraph:
                    'راقب التقدم في الوقت الحقيقي من خلال عرض لوحات المهام، التحقق من حالة المهام، وتتبع الوقت المنقضي.',
            },
            {
                header: 'راقب جميع سير العمل في مكان واحد لجميع المنظمة',
                paragraph:
                    'ميزات التعاون في إدارة المهام للتواصل مع أعضاء الفريق في الوقت الحقيقي. قم بالتعليق على المهام، طرح الأسئلة، وتقديم التحديثات للحفاظ على إبلاغ الجميع.',
            },
            {
                header: 'راقب الأداء',
                paragraph:
                    'إدارة العملاء والعملاء المحتملين لتتبع الإنتاجية. يمكن قياس إنتاجية الموظفين ومؤشرات الأداء الرئيسية بناءً على كمية العمل المنجزة من قبل الموظفين.',
            },
            {
                header: 'توحيد تحديد الأهداف',
                paragraph:
                    'هيكل هرمي لتنظيم الأهداف: أهداف المشروع، الأهداف departmental، أهداف الأداء الفردي، المبادرات الاستراتيجية، إلخ.',
            },
        ],
        softwareDetails: [
            {
                title: 'تنفيذ أنظمة تتبع الوقت لمراقبة ساعات العمل.',
                description: [
                    'تنفيذ حلول تتبع الوقت لمراقبة الوقت الذي يقضيه الموظفون في المهام والمشاريع.',
                    'يمكن أن يساعد ذلك في فهم استخدام الموارد وتحسين إدارة الوقت.',
                    'استخدم أنظمة إدارة المشاريع لتتبع تقدم المشروع، تعيين المهام، ومراقبة المواعيد النهائية.',
                    'يساعد ذلك في تحسين سير العمل في المشروع وضمان إكماله في الوقت المحدد.',
                ],
            },
            {
                title: 'راقب مؤشرات الأداء الرئيسية ذات الصلة.',
                description: [
                    'تحديد وتتبع مؤشرات الأداء الرئيسية ذات الصلة بأهداف منظمتك.',
                    'يمكن أن تشمل هذه الأهداف أهداف المبيعات، مقاييس رضا العملاء، أو مؤشرات أداء أخرى.',
                ],
            },
            {
                title: 'إنشاء تقارير حول اتجاهات القوى العاملة لديك.',
                description: [
                    'دمج ميزات التحليل والتقارير للحصول على رؤى حول اتجاهات القوى العاملة، الأداء، والمجالات التي تحتاج إلى تحسين.',
                    'يمكن أن تساعد التقارير المخصصة في اتخاذ القرارات.',
                ],
            },
        ],
    },
    salesPipelineMonitoring: {
        featureHeader: 'مراقبة خط مبيعات',
        featureDescription:
            'تتبع وتحليل مؤشرات الأداء الرئيسية لفريق المبيعات في الميدان مثل إيرادات المبيعات، تفاعلات العملاء، والاتجاهات الجغرافية.',
        featureParagraph:
            'لا مزيد من بطاقات الوقت، الأوراق المفرطة، والمستندات المختلفة للتحقق من حضور الموظفين. قم بأتمتة عملية مراقبة الحضور باستخدام WebWork Time Tracker وشاهد بدء وانتهاء ساعات عمل موظفيك في تنسيق منظم.',
        featureCaption:
            'تحليل جمع البيانات، تحليلها، وتفسيرها المتعلقة بأنشطة المبيعات الميدانية للحصول على رؤى واتخاذ قرارات مستنيرة',
        keyPoints: [
            {
                header: 'أتمتة سير العمل',
                paragraph:
                    'استفد من النظام لتبسيط وتحسين المراحل المختلفة لعملية المبيعات مثل جمع العملاء المحتملين، التأهيل، الترويج، إدارة خطوط الأنابيب، تتبع الأنشطة، وإعداد التقارير.',
            },
            {
                header: 'التكامل مع CRM',
                paragraph:
                    'تسهيل إدارة العملاء المحتملين، تتبع الاتصالات، مراقبة الصفقات، وتنسيق البيانات بين المنصتين.',
            },
            {
                header: 'تسهيل التعاون والتواصل',
                paragraph:
                    'قنوات التواصل المركزية، جدولة اجتماعات المبيعات، مراجعات الصفقات التعاونية، توثيق العمليات، التعاون بين الوظائف، تعزيز التواصل المفتوح.',
            },
            {
                header: 'رؤى حول أداء المبيعات المستقبلية',
                paragraph: 'استخدام تحليل البيانات والنمذجة التنبؤية للتنبؤ بالنتائج المحتملة والاتجاهات.',
            },
        ],
        softwareDetails: [
            {
                title: 'رؤى يومية ميدانية لتحسين أداء المبيعات',
                description: [
                    'توليد تقارير إلكترونية متزامنة موجهة بالتحليل البياني. ميزة تقرير موثوقة وسهلة الاستخدام للوصول إليها واستخدامها.',
                    'بصفتك مدير مبيعات، سيكون لديك نظرة واضحة على تقارير مختلفة والتي سيتم إرسالها إلى عنوان بريدك الإلكتروني أو يمكنك أيضًا عرضها في لوحة المعلومات الخاصة بك.',
                    'بعض التقارير الملحوظة تشمل التقارير الشهرية، تقارير حسب موقع الفروع، تقارير محددة للموظفين، إلخ.',
                ],
            },
            {
                title: 'نظرة شاملة على حضور العمل الميداني',
                description: [
                    'نظرة شاملة على حضور الموظفين في الميدان وزيارات الميدان.',
                    'احصل على عرض شامل لحضور القوة العاملة في الميدان والاجتماعات.',
                    'في تقرير حضور الميدان، ستجد بيانات حول وقت تسجيل الحضور في الميدان، الموقع، وقت الحضور المتأخر، ساعات العمل، إلخ.',
                    'وبالمثل، تشمل تقارير تسجيل الدخول/الخروج لزيارات الميدان بيانات تتعلق بزيارات الميدان مثل وقت تسجيل الدخول/الخروج، وقت التحقق من الدخول/الخروج، موقع الاجتماعات، المسافة المقطوعة، ملاحظات نتائج الاجتماعات، إلخ.',
                ],
            },
            {
                title: 'توليد تقارير موجهة بالتحليل البياني',
                description: [
                    'اشترك وقم بتنزيل التقارير الإلكترونية الشهرية.',
                    'يمكنك الاشتراك وتنزيل التقارير الإلكترونية الشهرية متى شئت.',
                    'متاحة بتنسيق PDF و Excel. مجموعة كاملة من التقارير مرتبة لتزويدك بمعلومات مفصلة حول الإجراءات الخارجية للموظف خارج المنظمة، والتي تكون مهمة صعبة يدويًا.',
                ],
            },
        ],
    },
    workflowAutomation: {
        featureHeader: 'أتمتة سير العمل',
        featureDescription:
            'حضور قائم على السياج الجغرافي لضمان أن الموظفين يمكنهم تسجيل الدخول أو الخروج فقط عندما يكونون ضمن الحدود الجغرافية المحددة مسبقًا.',
        featureCaption: 'يضمن الحضور القائم على الموقع دقة في مراقبة وتسجيل حضور الموظفين بناءً على موقعهم الفعلي.',
        keyPoints: [
            {
                header: 'تعيين المهام تلقائيًا',
                paragraph:
                    'أتمتة سير العمل لتعيين المهام تلقائيًا إلى الأفراد الأكثر ملاءمة، مع مراعاة عوامل مثل مجموعة المهارات، التوفر، عبء العمل، ومستويات الأولوية.',
            },
            {
                header: 'توجيه وتأهيل وتصنيف العملاء المحتملين',
                paragraph:
                    'توجيه العملاء المحتملين، تأهيلهم، وتصنيفهم لتعيين العملاء المحتملين الواردين إلى مندوبي المبيعات المناسبين، وتقييم جودتهم، وتحديد أولويات إجراءات المتابعة.',
            },
            {
                header: 'التكامل مع الأنظمة الخارجية',
                paragraph: 'تسهيل التعاون السلس، تعزيز دقة البيانات، وضمان سير عمل متماسك عبر مختلف الوظائف التجارية.',
            },
            {
                header: 'تتبع الأداء وإعداد التقارير',
                paragraph:
                    'تتبع مؤشرات الأداء مثل أوقات إتمام العمليات، معدلات الأخطاء، استخدام الموارد، والإنتاجية العامة.',
            },
        ],
        softwareDetails: [
            {
                title: 'الوقت والموقع للقوة العاملة.',
                description: [
                    'اعرف متى وأين يعمل موظفوك',
                    'اطلب من موظفيك منح الإذن على هواتفهم المحمولة حتى يتمكن من تتبع الوقت والموقع.',
                    'بمجرد منح الإذن، يمكن أن يبدأ جهاز التتبع في العمل بنقرة.',
                ],
            },
            {
                title: 'التقارير الحية اثنان',
                description: [
                    'يمكن أن يعزز تنفيذ نظام حضور الموظفين القائم على الموقع الدقة ويعمل على تبسيط إدارة الحضور. ومع ذلك، من الضروري موازنة الفوائد مع اعتبارات خصوصية الموظفين والتواصل بشفافية حول تنفيذ واستخدام هذه الأنظمة.',
                ],
            },
            {
                title: 'تنبيه قائم على السياج الجغرافي',
                description: [
                    'إعداد تنبيهات تلقائية لأنماط الحضور غير المعتادة أو التباينات. يساعد ذلك في تحديد المشكلات المحتملة، مثل تسجيل الدخول أو الخروج غير المصرح به.',
                    'تنفيذ ميزات إعداد التقارير والتحليلات للحصول على رؤى حول أنماط الحضور، الاتجاهات، والاستثناءات.',
                ],
            },
        ],
    },
    aIBasedAssistance: {
        featureHeader: 'مساعدة قائمة على الذكاء الاصطناعي',
        featureDescription:
            'تتبع وتحليل في الوقت الفعلي لمختلف جوانب عملهم، مثل الحضور، المهام المنجزة أو تقدم المشاريع.',
        featureCaption:
            'ابق على اطلاع بمهمات الموظفين، محاضر الاجتماعات، التحقق من الزيارات، واتخذ القرارات بناءً على البيانات.',
        keyPoints: [
            {
                header: 'إشعارات قائمة على الأحداث',
                paragraph:
                    'يمكن للمسؤول أو المدير الاشتراك أو اختيار الإشعارات التي يرغبون في تلقيها. سيساعد ذلك في التركيز على الإشعارات التي يريدون تلقيها حقًا.',
            },
            {
                header: 'ابق على علم بالحضور والإجازات',
                paragraph:
                    'ابق على علم بالحضور والإجازات. كن على اطلاع مسبق واطلع على عدد الموظفين قبل بدء اليوم حتى يمكن التخطيط ليكون أكثر إنتاجية وكفاءة.',
            },
            {
                header: 'تتبع جميع الموافقات',
                paragraph: 'تتبع جميع الموافقات. تتبع جميع الموافقات من التعويضات إلى الإجازات وراجعها لاحقًا.',
            },
            {
                header: 'ابق على اطلاع بالنماذج ومحاضر الاجتماعات',
                paragraph:
                    'التتبع عن بُعد من خلال النماذج ومحاضر الاجتماعات. يمكن أن تساعد النماذج المخصصة في الحصول على بيانات محددة لاجتماع يمكن الوصول إليها عن بُعد.',
            },
        ],
        softwareDetails: [
            {
                title: 'تحديد موقع القوة العاملة في الوقت الفعلي.',
                description: [
                    'حدد موقع موظفيك في الوقت الفعلي',
                    'احصل على تفاصيل الموقع الفورية لقوة العمل الميدانية أثناء تنقلهم في الوقت الفعلي.',
                    'يتيح لك Treant تحديد موقع وتتبع موظفيك الميدانيين باستخدام GPS الخاص بهم على الهواتف المحمولة سواء عبر الإنترنت أو بدون اتصال. ابق على اطلاع بكل حركة لموظفيك الميدانيين وحافظ على بيئة عمل شفافة.',
                ],
            },
            {
                title: 'ابق على اطلاع بمعلومات موظفيك الميدانية',
                description: [
                    'ابق على اطلاع بمعلومات موظفيك الميدانية',
                    'سيتم مزامنة معلومات الموظفين الميدانية تلقائيًا مع لوحة المعلومات الخاصة بك.',
                    'سيتم إبلاغك على الفور بمعلومات مثل نسبة البطارية، GPS، حالة الإنترنت، ووقت التواجد المثالي في الميدان.',
                ],
            },
            {
                title: 'أتمتة بيانات تعويضات الموظفين وبدلاتهم.',
                description: [
                    'تاريخ الموقع المدعوم بالذكاء الاصطناعي لبيانات دقيقة',
                    'سيتم دعم بيانات موقع موظفيك بالذكاء الاصطناعي لتقديم معلومات دقيقة حول المسار والتتبع. سيتم أتمتة بيانات التتبع المباشرة وتواريخ الموقع الأخرى إلى صندوق بريدك على فترات منتظمة للحصول على نظرة أعمق لما يحدث في الميدان وخارجه.',
                ],
            },
        ],
    },
    digitizationOfOrder: {
        featureHeader: 'رقمنة الطلب',
        featureDescription:
            'تتبع وتحليل في الوقت الفعلي لمختلف جوانب عملهم، مثل الحضور، المهام المنجزة أو تقدم المشاريع.',
        featureCaption: 'تبسيط إدارة الطلبات من البداية للنهاية وتقليل الأخطاء اليدوية',
        keyPoints: [
            {
                header: 'التقارير والتحليلات',
                paragraph: 'قم بإنشاء تقارير وتحليلات مفصلة لمراقبة أداء معالجة الطلبات وإنتاجية الموظفين.',
            },
            {
                header: 'إدخال الطلبات التلقائي',
                paragraph: 'قم تلقائيًا بالتقاط والتحقق من تفاصيل الطلبات التي يدخلها الموظفون أو العملاء.',
            },
            {
                header: 'تتبع الطلبات في الوقت الفعلي وتحديثات الحالة',
                paragraph: 'قدم تحديثات فورية حول حالة الطلب وتتبع الشحنات للموظفين والعملاء.',
            },
            {
                header: 'أتمتة سير العمل وتعيين المهام',
                paragraph: 'قم بأتمتة عمليات الموافقة على الطلبات وتعيين المهام للموظفين المناسبين.',
            },
        ],
    },
    targetManagement: {
        featureHeader: 'إدارة الأهداف',
        featureDescription:
            'تتبع وتحليل في الوقت الفعلي لمختلف جوانب عملهم، مثل الحضور، المهام المنجزة أو تقدم المشاريع.',
        featureCaption:
            'ابقَ على اطلاع بمهام الموظفين، محاضر الاجتماعات، تحقق من الزيارات، واتخذ القرارات بناءً على البيانات.',
        keyPoints: [
            {
                header: 'الدمج مع تتبع الميدان',
                paragraph:
                    'تحقيق الأهداف اليومية والأسبوعية والشهرية واحتفظ بسجلات إنجازات الموظفين مع تتبع الميدان الحي.',
            },
            {
                header: 'تحديثات ومزامنة في الوقت الفعلي',
                paragraph:
                    'تقدم في الوقت الفعلي (عدد الأهداف المحققة مقابل المتبقية)، أوقات الدخول/الخروج، عدد الساعات (المدة) المستغرقة في كل مهمة، وتتبّع الموقع.',
            },
            {
                header: 'الأداء والتحليلات',
                paragraph:
                    'مؤشرات الأداء (معدل الإنجاز، الوقت المستغرق، درجات رضا العملاء)، المقارنة ضد الأهداف (الأداء الفعلي مقابل الهدف)، البيانات التاريخية (اتجاهات الأداء السابقة).',
            },
            {
                header: 'إشعارات وتنبيهات مؤتمتة',
                paragraph:
                    'إرسال إشعارات مؤتمتة لإبقاء الموظفين على اطلاع بتنبيهات تحقيق الأهداف، تذكيرات الأداء، وتحذيرات المواعيد النهائية.',
            },
        ],
    },
    invoiceManagement: {
        featureHeader: 'إدارة الفواتير',
        featureDescription:
            'تتبع وتحليل في الوقت الفعلي لمختلف جوانب عملهم، مثل الحضور، المهام المنجزة أو تقدم المشاريع.',
        featureCaption: 'إنشاء وتتبع حالة الفواتير في الوقت الفعلي ومعالجة الفواتير مع إشعارات وتنبيهات مؤتمتة.',
        keyPoints: [
            {
                header: 'إشعارات وتذكيرات مؤتمتة',
                paragraph:
                    'إعداد أنظمة لتتبع حالة الفواتير وإرسال تذكيرات وتنبيهات مؤتمتة بشأن المدفوعات المستحقة والمتأخرة.',
            },
            {
                header: 'قوالب الفواتير',
                paragraph:
                    'قوالب فواتير موحدة تتضمن جميع التفاصيل الضرورية مثل معلومات العميل، تفاصيل الطلب، الضرائب، الخصومات، وشروط الدفع.',
            },
            {
                header: 'التوقيع الرقمي وإثبات التسليم',
                paragraph: 'جمع التوقيعات الرقمية وإثبات التسليم المستند إلى OTP كجزء من عملية الفوترة.',
            },
            {
                header: 'مزامنة الفواتير وتحديثات في الوقت الفعلي',
                paragraph:
                    'مزامنة تلقائية مع النظام المركزي (التخزين السحابي) وتحديثات في الوقت الفعلي حول حالة الفواتير.',
            },
        ],
    },
    distributorOrder: {
        featureHeader: 'طلبات الموزعين',
        featureDescription:
            'تتبع وتحليل في الوقت الفعلي لمختلف جوانب عملهم، مثل الحضور، المهام المنجزة أو تقدم المشاريع.',
        featureCaption:
            'ابقَ على اطلاع بمهمات الموظفين، محاضر الاجتماعات، تحقق من الزيارات، واتخذ القرارات بناءً على البيانات.',
        keyPoints: [
            {
                header: 'معلومات التوزيع',
                paragraph:
                    'إنشاء ومعالجة الطلبات مع جميع التفاصيل ذات الصلة لكل توزيع وتعيين المهام بناءً على التوافر.',
            },
            {
                header: 'تكامل المخزون في الوقت الفعلي',
                paragraph: 'تكامل مع إدارة المخزون لضمان تخصيص دقيق للمخزون وتحديث مستويات المخزون في الوقت الفعلي.',
            },
            {
                header: 'أتمتة الإشعارات والتنبيهات',
                paragraph: 'تكوين النظام لإرسال إشعارات مؤتمتة في مراحل رئيسية من عملية الطلب.',
            },
            {
                header: 'لوحة المعلومات الخاصة بالموظف',
                paragraph:
                    'الوصول إلى لوحة المعلومات حيث يمكن للموظفين التحقق من الطلبات، تتبع التقدم، وتنزيل المستندات اللازمة.',
            },
        ],
    },
    inventoryManagement: {
        featureHeader: 'إدارة المخزون',
        featureDescription:
            'تتبع وتحليل في الوقت الفعلي لمختلف جوانب عملهم، مثل الحضور، المهام المنجزة أو تقدم المشاريع.',
        featureCaption: 'التوازن الصحيح للمخزون مع التحديثات في الوقت الفعلي وتتبع المخزون لضمان دقة البيانات.',
        keyPoints: [
            {
                header: 'التكامل مع إدارة الطلبات',
                paragraph:
                    'ضمان التكامل السلس بين نظام إدارة المخزون ونظام إدارة الطلبات لمزامنة مستويات المخزون مع الطلبات الواردة والصادرة.',
            },
            {
                header: 'إدارة متعددة المواقع',
                paragraph:
                    'تتبع المخزون عبر مستودعات متعددة أو متاجر أو مواقع والوصول إلى التحكم المركزي، وتحسين توزيع المخزون وتحسين الرؤية.',
            },
        ],
    },
    orderCollectionManagement: {
        featureHeader: 'إدارة الطلبات/التحصيل',
        featureDescription: 'تبسيط عمليات الطلبات/التحصيل من خلال حل قابل للتطوير وأتمتة.',
        featureCaption: 'تبسيط عمليات الطلبات/التحصيل من خلال حل قابل للتطوير وأتمتة.',
        keyPoints: [
            {
                header: 'نظام إدارة الطلبات الموحد',
                paragraph:
                    'نظام مركزي يمكن الوصول إليه من قبل الموظفين في الموقع ومن بعيد لإدارة الطلبات، تتبع التقدم، وتحديث الحالة في الوقت الفعلي.',
            },
            {
                header: 'التحكم في الوصول بناءً على الأدوار',
                paragraph:
                    'مستويات وصول وأذونات مختلفة بناءً على الأدوار (مثل: المديرين، الموظفين في الموقع، الموظفين عن بُعد) لضمان أمان البيانات والوصول المناسب.',
            },
            {
                header: 'المزامنة في الوقت الفعلي',
                paragraph:
                    'ضمان مزامنة جميع بيانات الطلبات في الوقت الفعلي عبر جميع الأجهزة والمواقع، بحيث يكون للجميع الوصول إلى أحدث المعلومات.',
            },
            {
                header: 'التواصل والتنسيق',
                paragraph:
                    'تسهيل التواصل بين الموظفين في الموقع والموظفين عن بُعد من خلال الرسائل والإشعارات داخل التطبيق.',
            },
        ],
    },
};

export default features;
