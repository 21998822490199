const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'તમારા સાથે વધતાં લવચીક યોજનાઓ',
    subFlexiblePlansThatGrowsWithYou:
        'તમારા વ્યવસાયની જરૂરિયાતોને પહોંચીવળતા પ્રાઇસિંગ પ્લાન પસંદ કરો. ટ્રેસફોર્સ ઑપ્ટિમલ કિંમતો પર ઉત્તમ મૂલ્ય સાથે પેકેજ ઓફર કરે છે.',
    pricingPlans: 'મૂલ્યયોજના',
    pricingThatWorksForEveryOne: 'પ્રતિશ્રુતિ એ તમામ માટે કાર્ય કરે છે',
    monthly: 'માસિક',
    annualy: 'વાર્ષિક',
    international: 'આંતરરાષ્ટ્રીય',
    domestic: 'ઘરે',
    month: 'માસ',
    benifits: 'લાભ',
    allPlanIncludeTheseBenifits: 'સઘળા યોજનાઓમાં આ લાભો સમાવેશ થાય છે',
    someBenfitsOfMonthlyYearlySubscription: 'માસિક/વાર્ષિક સબ્સ્ક્રિપ્શનના કેટલાક લાભો',
    benifitsArr: [
        {
            title: 'ટેકનિકલ સપોર્ટ',
            description: 'અમારી વ્યાવસાયિક ટેકનિકલ સપોર્ટ ટીમ તમારી દરેક પગલાંમાં મદદ કરશે.',
        },
        {
            title: 'ટેક્નોલોજી',
            description: 'પ્લેટફોર્મ પર શરૂ કરવા માટે વિશેષ તાલીમ.',
        },
        {
            title: 'વિકાસ વિશ્લેષણ',
            description: 'તમારા માસિક વિકાસને લઈને વ્યાપક ટીમ દ્વારા દૃષ્ટિ.',
        },
        {
            title: 'પ્રેમ',
            description: 'દરેક મહિને શ્રેષ્ઠ કામગીરી કરનારા ખાતા માટે વિશેષ ઇનામ.',
        },
    ],
    fAQs: 'સામાન્ય રીતે પૂછવામાં આવતા પ્રશ્નો',
    frequentlyAskedQuestions: 'સામાન્ય રીતે પૂછવામાં આવતા પ્રશ્નો',
    someBaTypeOfQuestions: 'અહીં અમારા ગ્રાહકો તરફથી કેટલીક સામાન્ય પ્રકારની પૂછપરછ છે',
    fAQArr: [
        {
            question: 'હું મારી કંપની માટે કેવી રીતે સાઇન અપ કરી શકું?',
            ans: 'હા, માર્કેટપ્લેસ લાઇસન્સ તમને આ થિમનો ઉપયોગ કોઈપણ અંતિમ ઉત્પાદનમાં કરવા દે છે. લાઇસન્સ વિશે વધુ માહિતી માટે, કૃપા કરીને માર્કેટપ્લેસ પર લાઇસન્સની શરતો તપાસો.',
        },
        {
            question: 'હું મારી કાર્યબળ-વાહન ક્યાં ઉમેરવા શકું?',
            ans: 'નહીં, આ એચટીએમએલ ટેમ્પલેટ છે. આ વર્ડપ્રેસ સાથે સીધા કાર્ય કરશે નહીં, પરંતુ તમે તેને વર્ડપ્રેસ-સંગત થિમમાં રૂપાંતરિત કરી શકો છો.',
        },
        {
            question: 'મારું કાર્યબળ ટ્રેસફોર્સમાં કેવી રીતે લોગ ઇન કરે છે?',
            ans: 'તમારા મુદ્દાઓ અથવા પ્રતિસાદો અમારા સમર્પિત સપોર્ટ ઈમેલ (support@coderthemes.com) પર મોકલો. અમે તમારી મદદ માટે કોઈપણ સમયે ઉપલબ્ધ છીએ.',
        },
        {
            question: 'હું મારા કાર્યબળને મોબાઇલ દ્વારા ટ્રેક કરી શકું?',
            ans: 'હા, અમે નિયમિતપણે પ્રમ્પ્ટ્સને અપડેટ કરીશું. તમામ ભવિષ્યના અપડેટ્સ મફત ઉપલબ્ધ થશે.',
        },
        {
            question: 'હાજરી કેવી રીતે નિશ્ચિત કરવી?',
            ans: 'હા, માર્કેટપ્લેસ લાઇસન્સ તમને આ થિમનો ઉપયોગ કોઈપણ અંતિમ ઉત્પાદનમાં કરવા દે છે. લાઇસન્સ વિશે વધુ માહિતી માટે, કૃપા કરીને માર્કેટપ્લેસ પર લાઇસન્સની શરતો તપાસો.',
        },
        {
            question: 'ડેવલપર વિકલ્પો કેવી રીતે નિશ્ચિત કરવી?',
            ans: 'નહીં, આ એચટીએમએલ ટેમ્પલેટ છે. આ વર્ડપ્રેસ સાથે સીધા કાર્ય કરશે નહીં, પરંતુ તમે તેને વર્ડપ્રેસ-સંગત થિમમાં રૂપાંતરિત કરી શકો છો.',
        },
        {
            question: 'ટ્રેસફોર્સ કઈ સુવિધાઓ ઓફર કરે છે?',
            ans: 'તમારા મુદ્દાઓ અથવા પ્રતિસાદો અમારા સમર્પિત સપોર્ટ ઈમેલ (support@coderthemes.com) પર મોકલો. અમે તમારી મદદ માટે કોઈપણ સમયે ઉપલબ્ધ છીએ.',
        },
        {
            question: 'શું મને ટ્રેસફોર્સ સોફ્ટવેર ઇન્સ્ટોલ કરવાની જરૂર છે?',
            ans: 'હા, અમે નિયમિતપણે પ્રમ્પ્ટ્સને અપડેટ કરીશું. તમામ ભવિષ્યના અપડેટ્સ મફત ઉપલબ્ધ થશે.',
        },
        {
            question: 'ટ્રેસફોર્સ સોફ્ટવેરનો અમલ કેવી રીતે થાય છે?',
            ans: 'હા, માર્કેટપ્લેસ લાઇસન્સ તમને આ થિમનો ઉપયોગ કોઈપણ અંતિમ ઉત્પાદનમાં કરવા દે છે. લાઇસન્સ વિશે વધુ માહિતી માટે, કૃપા કરીને માર્કેટપ્લેસ પર લાઇસન્સની શરતો તપાસો.',
        },
        {
            question: 'ટ્રેસફોર્સ સોફ્ટવેર સાથે કઈ તાલીમ અને સપોર્ટ વિકલ્પો ઉપલબ્ધ છે?',
            ans: 'નહીં, આ એચટીએમએલ ટેમ્પલેટ છે. આ વર્ડપ્રેસ સાથે સીધા કાર્ય કરશે નહીં, પરંતુ તમે તેને વર્ડપ્રેસ-સંગત થિમમાં રૂપાંતરિત કરી શકો છો.',
        },
        {
            question: 'શું ટ્રેસફોર્સ સોફ્ટવેરને અમારી ખાસ HR પ્રક્રિયાઓ અનુસાર વૈવિધ્યીકૃત કરી શકાય છે?',
            ans: 'તમારા मुद्दાઓ અથવા પ્રતિસાદો અમારા સમર્પિત સપોર્ટ ઈમેલ (support@coderthemes.com) પર મોકલો. અમે તમારી મદદ માટે કોઈપણ સમયે ઉપલબ્ધ છીએ.',
        },
        {
            question: 'HRMS સોફ્ટવેર પરિમાણ અને રિપોર્ટિંગમાં કેવી રીતે મદદ કરી શકે છે?',
            ans: 'હા, અમે નિયમિતપણે પ્રમ્પ્ટ્સને અપડેટ કરીશું. તમામ ભવિષ્યના અપડેટ્સ મફત ઉપલબ્ધ રહેશે.',
        },
        {
            question: 'શું HRMS સોફ્ટવેર દૂરસ્થ કાર્ય અને મોબાઇલ ઍક્સેસને સપોર્ટ કરે છે?',
            ans: 'હા, અમે નિયમિતપણે પ્રમ્પ્ટ્સને અપડેટ કરીશું. તમામ ભવિષ્યના અપડેટ્સ મફત ઉપલબ્ધ રહેશે.',
        },
    ],
};
export default pricingPage;
