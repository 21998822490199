const industries = {
    pharmaceutical: {
        industryHeading:
            'સુરક્ષા પ્રોટોકોલની મોનિટરિંગ, સમય, હાજરીને ટ્રેક કરવું અને સંવેદનશીલ વિસ્તારોમાં પ્રવેશ નિયંત્રિત કરવા માટે પ્રોજેક્ટ મેનેજમેન્ટ, ઓફ સાઇટ પ્રવૃત્તિઓ અને કાર્યક્ષમતાની મૂલ્યાંકન સુલભ બનાવવું.',
        featureHeader: 'ફાર્માસ્યુટિકલ્સ',
        featureParagraph:
            'ફાર્માસ્યુટિકલ/હેલ્થકેર કંપનીઓ માટે કર્મચારી પ્રારંભ અને ફીલ્ડ ટ્રેકિંગ સોફ્ટવેરથી workforce મેનેજમેન્ટ સરળ બનાવે છે જેમાં સમય ટ્રેકિંગ, ફીલ્ડ કર્મચારીઓ માટે મોબાઇલ ઍક્સેસિબિલિટી, ફીલ્ડ ઓપરેશન્સ અનુસરણ અહેવાલ, અને વેચાણ ઉત્પાદન માટે વિવિધ સિસ્ટમ્સ સાથે એકીકરણ જેવી ખાસિયતો હોય છે.',
        keyPoints: [
            {
                header: 'કર્મચારી ટ્રેકિંગ સિસ્ટમને HR સિસ્ટમ્સ સાથે એકીકૃત કરો જે કર્મચારી રેકોર્ડ, પેરોલ અને અનુસરણ અહેવાલ સાથે સંબંધિત છે.',
                paragraph: 'દૈનિક, સાપ્તાહિક અને માસિક આધારે કાર્ય કલાકોને મોનિટર કરો.',
            },
            {
                header: 'કર્મચારીના શિફ્ટ મેનેજમેન્ટ સિસ્ટમનો ઉપયોગ કરો workforce શિડ્યુલિંગને ઑપ્ટિમાઇઝ કરવા માટે',
                paragraph: 'બહુવિધ શાખાઓના હાજરી અહેવાલને દૂરથી ઍક્સેસ કરો.',
            },
            {
                header: 'તાલીમ અને ઉત્પાદન વિસ્તારોના શિડ્યુલને મોનિટર કરો જ્યાં સતત ઓપરેશન્સ મહત્વપૂર્ણ છે.',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, પ્રક્રિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
            {
                header: 'સમસ્ત કર્મચારી ટ્રેકિંગ સિસ્ટમો pharmaceutical ઉદ્યોગમાં લાગુ પડતી નિયમો અને ધોરણોને અનુસરે તે સુનિશ્ચિત કરો.',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, प्रक्रિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
        ],
    },
    education: {
        industryHeading:
            'અડમિશન્સ CRM માટે એક વ્યાપક ઉકેલ જે વિદ્યાર્થીઓની સહયોગ અને આશાવાદી વ્યવસ્થાપનને સરળ બનાવે છે',
        featureHeader: 'શિક્ષણ',
        featureParagraph:
            'વિદ્યાર્થી ભરતી અને પ્રવેશ \n વિદ્યાર્થીઓની જોડાણ અને રોકાણ \n અનેક સ્થાનો પર પ્રવેશ સંચાલિત કરો \n વ્યક્તિગત વિદ્યાર્થી સમર્થન',
        keyPoints: [
            {
                header: 'મુલાકાતના પ્રથમ બિંદુથી એપ્લિકેશન પ્રક્રિયા સુધીના સંભવિત વિદ્યાર્થીઓને ટ્રેક અને મેનેજ કરો.',
                paragraph: 'દૈનિક, સાપ્તાહિક અને માસિક આધારે કાર્ય કલાકોને મોનિટર કરો.',
            },
            {
                header: 'આમંત્રણમાં રસ દાખવતા સંભવિત વિદ્યાર્થીઓને ઓળખો અને મેનેજ કરો. પૂછપરછોને પ્રવેશિત વિદ્યાર્થીઓમાં રૂપાંતર કરવા માટે વાતચીત અને પ્રગતિને ટ્રેક કરો.',
                paragraph: 'બહુવિધ શાખાઓના હાજરી અહેવાલને દૂરથી ઍક્સેસ કરો.',
            },
            {
                header: 'વિદ્યાર્થી નોંધણી પ્રક્રિયાના વિવિધ તબક્કાઓને દૃશ્યમાન કરો અને મેનેજ કરો. નમૂના, એપ્લિકેશન, ઇન્ટરવ્યૂ, સ્વીકાર અને નોંધણી જેવા તબક્કાઓમાં અરજદારને આગળ વધારવામાં મદદ કરો.',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, પ્રક્રિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
            {
                header: 'વિકસિત ઓનલાઈન ફોર્મ્સ દ્વારા એપ્લિકેશન પ્રક્રિયાને સરળ બનાવો. સંભવિત વિદ્યાર્થીઓ પાસેથી જરૂરી માહિતી સંગ્રહિત કરો.',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, प्रक्रિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
        ],
    },
    fieldAndSales: {
        industryHeading:
            'સેલ્સ ટીમની કાર્યક્ષમતા વધારવી અને રિયલ ટાઇમ લોકેશન ટ્રેકિંગ, અસરકારક માર્ગ આયોજન અને સમયસર ગ્રાહક જોડાણ સુનિશ્ચિત કરવા માટે',
        featureHeader: 'ફીલ્ડ સેલ્સ',
        featureParagraph:
            'સાથે વિસ્તારો અને CRM ને મેનેજ કરો. TraceForce ફીલ્ડ સેલ્સ ટ્રેકિંગ અને લીડ્સ મેનેજમેન્ટને સરળ બનાવે છે.',
        keyPoints: [
            {
                header: 'રિયલ ટાઇમ હાજરી',
                paragraph: 'જિઓફેન્સ વિસ્તારમાં અથવા શાખામાં વ્યક્તિઓની હાજરી અથવા ગેરહાજરી અંગેની માહિતી.',
            },
            {
                header: 'કુલ કાર્ય કલાકોની ગણતરી',
                paragraph: 'દૈનિક, સાપ્તાહિક અને માસિક આધારે કાર્ય કલાકોને મોનિટર કરો.',
            },
            {
                header: 'દૂરસંવાદ ઉપસ્થિતિ અહેવાલો',
                paragraph: 'બહુવિધ શાખાઓના હાજરી અહેવાલને દૂરથી ઍક્સેસ કરો.',
            },
            {
                header: 'કেন্দ્રિત હાજરી વ્યવસ્થાપન',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, પ્રક્રિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
        ],
    },
    fmcg: {
        featureHeader: 'FMCG',
        industryHeading:
            'પ્રદર્શન મેટ્રિક્સ, ખર્ચ મેનેજમેન્ટ અને અનુસરણ ટ્રેકિંગ સેલ્સ ફોર્સ મેનેજમેન્ટ માટે અસરકારક, ગ્રાહક જોડાણને મકાન અને સહયોગ વિશેષતાઓ સાથે વધારવું.',
        featureParagraph:
            'રિયલ-ટાઇમ હાજરી ટ્રેકિંગ માર્કેટ ડિમાન્ડના આધારે સ્ટાફિંગ સ્તરોમાં ઝડપી સુધારા માટે, FMCG ઓપરેશન્સની ચપળતામાં સુધારણા કરે છે.',
        keyPoints: [
            {
                header: 'સચોટ હાજરી ટ્રેકિંગ',
                paragraph: 'જિઓફેન્સ વિસ્તારમાં અથવા શાખામાં વ્યક્તિઓની હાજરી અથવા ગેરહાજરી અંગેની માહિતી.',
            },
            {
                header: 'સચોટ પે રોલ પ્રોસેસિંગ, ભૂલો ઘટાડવું અને નાણાકીય તફાવત ઘટાડવો.',
                paragraph: 'દૈનિક, સાપ્તાહિક અને માસિક આધારે કાર્ય કલાકોને મોનિટર કરો.',
            },
            {
                header: 'શ्रम કાયદાઓ અને નિયમોનો અનુસરો',
                paragraph: 'બહુવિધ શાખાઓના હાજરી અહેવાલને દૂરથી ઍક્સેસ કરો.',
            },
            {
                header: 'પ્રભાવશાળી રજા વ્યવસ્થાપન સુનિશ્ચિત કરો, ઓછા સ્ટાફિંગના સમસ્યાઓને નિવારણ',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, પ્રક્રિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
            {
                header: 'અચૂક ઉત્પાદન અને વિતરણ સુનિશ્ચિત કરો.',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, પ્રક્રિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
            {
                header: 'સચોટ વળતર પ્રક્રિયાઓ સુનિશ્ચિત કરો.',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, પ્રક્રિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
            {
                header: 'ઓર્ડર્સને ટ્રેક કરો અને પૂર્ણતા.',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, પ્રક્રિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
        ],
    },
    services: {
        industryHeading:
            'પ્રોજેક્ટ અને કાર્ય સંચાલન સરળ બનાવવું, સચોટ સમય અને હાજરી રેકોર્ડને સુનિશ્ચિત કરીને સ્રોતોના વિતરણને ઑપ્ટિમાઇઝ કરવું, પે રોલ પ્રોસેસિંગ અને અનુસરણમાં મદદરૂપ થવું.',
        featureHeader: 'સેવામાં',
        featureParagraph:
            'ફીલ્ડ-ટેસ્ટેડ ફીચર્સ ઉત્પાદનક્ષમતા વધારાવે છે, જટિલતા ઘટાડે છે, અને કાર્યોને ઑટોમેટ કરે છે, તમારા બિઝનેસ માટે સમય અને સ્રોતો બચાવે છે.',
        keyPoints: [
            {
                header: 'રિયલ-ટાઇમ ફીલ્ડ સ્ટાફ GPS ટ્રેકિંગ',
                paragraph: 'જિઓફેન્સ વિસ્તારમાં અથવા શાખામાં વ્યક્તિઓની હાજરી અથવા ગેરહાજરી અંગેની માહિતી.',
            },
            {
                header: 'કસ્ટમ ફોર્મ અને જિઓ-ટેગડ ફોટા',
                paragraph: 'દૈનિક, સાપ્તાહિક અને માસિક આધારે કાર્ય કલાકોને મોનિટર કરો.',
            },
            {
                header: 'સહેલ ટેબલ અને ડાઉનલોડવાળા અહેવાલો',
                paragraph: 'બહુવિધ શાખાઓના હાજરી અહેવાલને દૂરથી ઍક્સેસ કરો.',
            },
            {
                header: 'સંગ્રહ અને મોનિટર હેન્ડલ કરો',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, પ્રક્રિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
        ],
    },
    fieldOperations: {
        industryHeading: 'ડેટા આધારિત નિર્ણયોને સુવિધા આપવી અને ફીલ્ડ ટીમો અને મુખ્યાલય વચ્ચે સંચાર સુધારવો.',
        featureHeader: 'ફીલ્ડ ઓપરેશન્સ',
        featureParagraph:
            'અસામાન્ય ફીલ્ડ ઓપરેશન્સ અને સર્વિસ મેનેજમેન્ટને TraceForce ના તમામ-મેળવણ ઉકેલ સાથે અલવિદા કહો. કાર્ય સંચાલન ઑપ્ટિમાઇઝ કરો, ગોઠવણ પર ટ્રેક કરો, AI આધારિત માર્ગ ઑપ્ટિમાઇઝેશન દ્વારા ઉત્પાદનક્ષમતા અને સેલ્સ આવક વધારવા માટે.',
        keyPoints: [
            {
                header: 'કાર્ય સીડ્યુલ અને મોનિટર કરો',
                paragraph: 'જિઓફેન્સ વિસ્તારમાં અથવા શાખામાં વ્યક્તિઓની હાજરી અથવા ગેરહાજરી અંગેની માહિતી.',
            },
            {
                header: 'ફીલ્ડ કર્મચારીઓના રિયલ-ટાઇમ સ્થાનને ટ્રેક કરો',
                paragraph: 'દૈનિક, સાપ્તાહિક અને માસિક આધારે કાર્ય કલાકોને મોનિટર કરો.',
            },
            {
                header: 'ખર્ચ ક્લેમ્સને માન્ય કરો અને પ્રોસેસ કરો',
                paragraph: 'બહુવિધ શાખાઓના હાજરી અહેવાલને દૂરથી ઍક્સેસ કરો.',
            },
            {
                header: 'SLA Targets સેટ કરો અને વિલંબને મોનિટર કરો',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, प्रक्रિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
            {
                header: 'સહેલ ડેશબોર્ડ અને ડાઉનલોડવાળા અહેવાલો',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, प्रक्रિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
            {
                header: 'OTP દ્વારા માન્ય થયેલી કાર્ય પૂર્ણતા',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, प्रक्रિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
        ],
    },
    logisticsAndTransportation: {
        industryHeading: 'ડેટા આધારિત નિર્ણયોને સુવિધા આપવી અને ફીલ્ડ ટીમો અને મુખ્યાલય વચ્ચે સંચાર સુધારવો.',
        featureHeader: 'લોજિસ્ટિક્સ અને ટ્રાન્સપોર્ટેશન',
        featureParagraph:
            'લોજિસ્ટિક્સ અને ડિલિવરીને વધુ સરળ રીતે ઑર્ગેનાઇઝ અને સરળ બનાવવું. વિશાળ workforce ની લોજિસ્ટિક્સ ઓપરેશન્સને તેની મજબૂત અને અદ્યતન વિશેષતાઓ સાથે મેનેજ કરો.',
        keyPoints: [
            {
                header: 'ડિલિવરી પર્સનની લોકેશનનું રિયલ ટાઇમ ટ્રેકિંગ મેળવો.',
                paragraph: 'જિઓફેન્સ વિસ્તારમાં અથવા શાખામાં વ્યક્તિઓની હાજરી અથવા ગેરહાજરી અંગેની માહિતી.',
            },
            {
                header: 'ક્રિયાવલીઓ અને વર્કફ્લોનો મોનિટરિંગ કરીને ઑપરેશનલ કાર્યક્ષમતા સુધારવું.',
                paragraph: 'દૈનિક, સાપ્તાહિક અને માસિક આધારે કાર્ય કલાકોને મોનિટર કરો.',
            },
            {
                header: 'કર્મચારીના કાર્ય વ્યવસ્થાપનને સરળ બનાવવું.',
                paragraph: 'બહુવિધ શાખાઓના હાજરી અહેવાલને દૂરથી ઍક્સેસ કરો.',
            },
            {
                header: 'ઇતિહાસક ડેટા અને રિયલ ટાઇમ ટ્રાફિક માહિતીનો વિશ્લેષણ કરીને ડિલિવરી માર્ગોને ઑપ્ટિમાઇઝ કરો.',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, प्रक्रિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
            {
                header: 'ઓટોમેટેડ વળતર અને પે રોલ પ્રોસેસિંગ.',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, प्रक्रિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
            {
                header: 'ઓટોમેટેડ ફીલ્ડ વર્ક સાથે વધુ ગ્રાહક સેવા.',
                paragraph:
                    'હાજરી સંબંધિત ડેટા, પ્રક્રિયાઓ અને સિસ્ટમોને એક જ, કેન્દ્રિત પ્લેટફોર્મ અથવા સિસ્ટમમાં સાંકળી લો.',
            },
        ],
    },
};
export default industries;
