const products = {
    attendanceManagement: {
        productName: 'Gestión de Asistencia',
        titleDescription: 'Configuración Fácil, Check In y Check Out Automatizados, Informes',
        productCards: [
            {
                title: 'Equilibrio entre Trabajo y Vida Personal',
                data: 'Cuida el equilibrio entre la vida laboral y personal de tus empleados con recordatorios de TraceForce sobre el trabajo y la vida personal.',
            },
            {
                title: 'Tipos de Miembros',
                data: 'Simplifica la gestión del espacio de trabajo con roles de miembros como gerente ejecutivo, gerente de proyectos, gerente de equipo y espectadores de proyectos.',
            },
            {
                title: 'Ayuda a Rastrear',
                data: 'Las hojas de tiempo inteligentes de TraceForce hacen que la gestión de tareas sea más sencilla al permitirte ver las horas activas, las horas inactivas y el desglose de horas productivas en un formato detallado de hoja.',
            },
        ],
        whyChooseSubCaption:
            'Transición hacia un entorno de trabajo altamente eficiente y experimenta una mayor productividad y colaboración. Centraliza los datos en las tarjetas.',
        headerCaption: 'Por qué elegir la Gestión de Asistencia de TraceForce',
        headerT1: 'Funciones de Rastreo de Asistencia Que Hacen Mucho Más',
        subCaptionT1:
            'Experimenta el rastreo de empleados, monitoreo de trabajo remoto, monitoreo de productividad, gestión de asistencia, hojas de tiempo y más — todo con una capa de funcionalidades avanzadas.',
        features: {
            attendanceMonitoring: {
                featureHeader: 'Monitoreo de Asistencia',
                featureDescription:
                    'Información en tiempo real sobre el check in/check out de la presencia o ausencia de individuos dentro del área de geovalla o sucursal.',
                featureParagraph:
                    'Olvídate de las tarjetas de tiempo, el papeleo excesivo y los diferentes documentos para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y visualiza el inicio y fin de las horas de trabajo de tus empleados en un formato bien estructurado.',
                fIIPoints: [
                    'Información en tiempo real sobre la presencia o ausencia de individuos dentro del área de geovalla o sucursal.',
                    'Monitorea las horas de trabajo basadas en el día, semana y mes.',
                    'Accede a informes de asistencia de múltiples sucursales de forma remota.',
                    'Consolidación de datos, procesos y sistemas relacionados con la asistencia en una sola plataforma o sistema centralizado.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Asistencia en Tiempo Real',
                        paragraph:
                            'Información en tiempo real sobre la presencia o ausencia de individuos dentro del área de geovalla o sucursal.',
                    },
                    totalWorkingHoursCalculation: {
                        header: 'Cálculo de Horas de Trabajo Totales',
                        paragraph: 'Monitorea las horas de trabajo basadas en el día, semana y mes.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Acceso Remoto a Informes de Asistencia',
                        paragraph: 'Accede a informes de asistencia de múltiples sucursales de forma remota.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Monitoreo de Asistencia Uno',
                        description: [
                            'Obtén transparencia sobre la puntualidad de los empleados.',
                            'Ahorra tiempo y organiza la asistencia de los empleados sin acciones adicionales.',
                            'Monitorea fácilmente si tus empleados llegaron a tiempo al lugar de trabajo y completaron sus horas laborales.',
                            'Mantén las operaciones de tu lugar de trabajo más organizadas y ten un entorno laboral altamente responsable.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Monitoreo de Asistencia Dos',
                        description: [
                            'Análisis Inteligente de Datos de Asistencia.',
                            'Filtra salidas tempranas, llegadas tardías y menor rastreo que puedes seleccionar desde la lista desplegable.',
                            'Esto te ayudará a generar instantáneamente la información preferida sin tener que buscarla manualmente.',
                            'Puedes rastrear la asistencia diariamente o elegir un intervalo desde el calendario.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Monitoreo de Asistencia Tres',
                        description: [
                            'Forma segura y transparente de monitorear y rastrear la asistencia de los empleados en tiempo real con un sistema 100% sin contacto.',
                            'Configuración Fácil',
                            'CHECK IN Y OUT AUTOMATIZADOS, INFORMES DETALLADOS',
                            'CAPTURAS DE PANTALLA CON TRACKINGTIME',
                            'REGISTRO DEL NIVEL DE ACTIVIDAD',
                            'MONITOREO DEL USO DE APPS Y SITIOS WEB',
                        ],
                    },
                },
            },
            shiftManagement: {
                featureHeader: 'Gestión de Turnos',
                featureDescription:
                    'Organiza y programa las horas de trabajo que optimizan la productividad, cumplen con las necesidades operativas y aseguran la cobertura en diferentes periodos de tiempo.',
                fIIPoints: [
                    'Desarrolla un horario de turnos claro y bien organizado. Define diferentes tipos de turnos según la naturaleza de tu negocio.',
                    'Analiza la carga de trabajo y las tareas para determinar el número de empleados necesarios en diferentes turnos.',
                    'Plataforma centralizada para que los empleados accedan a sus horarios y establezcan un canal de comunicación confiable para actualizaciones o cambios.',
                    'Supervisión centralizada y control de los horarios de los empleados, permitiendo a los gerentes asignar eficientemente turnos, rastrear asistencia y gestionar los recursos de la fuerza laboral en múltiples ubicaciones desde una única plataforma.',
                ],
            },
            geoFenceBasedManagement: {
                featureHeader: 'Gestión de Asistencia Basada en Geo Valla',
                featureDescription:
                    'Tecnología basada en la ubicación para monitorear y verificar la presencia de empleados dentro de los límites geográficos predefinidos.',
                fIIPoints: [
                    'Registros de asistencia precisos para garantizar que los empleados estén presentes en sus ubicaciones de trabajo designadas.',
                    'Implementa rastreo en tiempo real para monitorear las ubicaciones de los empleados dentro de la geo-valla.',
                    'Establece límites geográficos específicos, conocidos como geo-vallas, alrededor del lugar de trabajo o ubicaciones de asistencia designadas.',
                    'Configura alertas o notificaciones para informar a los gerentes o al personal de recursos humanos cuando los empleados entren o salgan de los límites de la geo-valla definida.',
                ],
            },
            multipleClockInOptions: {
                featureHeader: 'Múltiples Opciones de Check In',
                featureDescription:
                    'Proporciona flexibilidad a los empleados para registrar sus horas de trabajo utilizando escáneres biométricos, aplicaciones móviles, relojes de tiempo y plataformas basadas en la web.',
                fIIPoints: [
                    'Adecuado para organizaciones con múltiples ubicaciones. Opciones flexibles de check in para la gestión de asistencia tanto en campo como fuera de campo en diferentes sucursales.',
                    'Proporciona a los empleados acceso a un portal de autoservicio de registros de asistencia y gestiona sus check-ins y check-outs dentro de la geo-valla.',
                    'Opciones flexibles de check-in incluyen escáneres biométricos, aplicaciones móviles, tarjetas RFID y reconocimiento facial, asegurando un rastreo de tiempo sin problemas en diversos entornos laborales.',
                    'Marcado de asistencia automático para empleados dentro del rango de las instalaciones de la oficina con notificaciones de alerta.',
                ],
            },
            attendanceReport: {
                featureHeader: 'Informes de Asistencia',
                featureDescription:
                    'Resumir los datos de asistencia de los empleados, horas de trabajo, ausencias, llegadas tardías y salidas tempranas dentro de un período de tiempo específico.',
                fIIPoints: [
                    'Proporcionar un resumen general de la asistencia, incluido el número total de días laborables, total de horas trabajadas y cualquier hora extra.',
                    'Accede de forma remota a informes de asistencia de múltiples sucursales con categorías personalizables.',
                    'Mostrar saldos de días de licencia para cada empleado, incluidos los días de vacaciones, licencia por enfermedad y cualquier otro tipo de licencia acumulada y utilizada.',
                    'Permitir a los usuarios personalizar los informes en función de criterios específicos, como departamento, equipo o empleado individual.',
                ],
            },
        },
    },
    fieldTrackingManagement: {
        productName: 'Gestión de Fuerza de Campo',
        titleDescription: 'Operaciones de Ventas y Servicios en Campo con Informes en Tiempo Real y Ruta de Visitas',
        productCards: [
            {
                title: 'Reducción de Costos Operativos',
                data: 'Disfruta de la reducción de costos operativos automatizando los horarios de los empleados. Haz seguimiento del tiempo, tareas y gastos de los empleados para identificar áreas donde se pueda ahorrar dinero.',
            },
            {
                title: 'Gran Valor para el Cliente',
                data: 'Establece un horario garantizado y un servicio eficiente. Rastrea las interacciones con los clientes y los comentarios para identificar fácilmente las brechas y áreas de mejora.',
            },
            {
                title: 'Análisis de Rendimiento',
                data: 'Genera fácilmente informes completos en paneles de control unificados, proporcionando información valiosa sobre el rendimiento de tu fuerza laboral, cronograma de trabajo, satisfacción del cliente y áreas de mejora.',
            },
        ],
        whyChooseSubCaption:
            'Máxima Eficiencia con el Software de Gestión de Fuerza de Campo\nSubtítulo: Rastrear a tus empleados de campo en tiempo real, monitorear el progreso de tareas y obtener información sobre su ubicación, actividad y rendimiento. Mejora la responsabilidad y utiliza la toma de decisiones basada en datos.',
        headerCaption: 'Por Qué Elegir la Gestión de Fuerza de Campo de TraceForce',
        headerT1: 'Aspectos Destacados del Sistema de Gestión de Fuerza de Campo',
        subCaptionT1:
            'Aprovecha el rastreo en tiempo real de la ubicación de los empleados combinado con la optimización basada en IA para optimizar las operaciones, obtener informes en vivo para obtener información inmediata y utilizar los análisis de ventas en campo para mejorar el rendimiento. Asegura una asignación eficiente de recursos, mayor productividad y toma de decisiones basada en datos.',

        features: {
            workforceTracking: {
                featureHeader: 'Seguimiento de la Fuerza Laboral',
                featureDescription:
                    'Monitoreo y gestión sistemática de las actividades, productividad y asistencia de los empleados.',
                featureParagraph:
                    'No más tarjetas de tiempo, exceso de papeleo y diferentes documentos para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y ve el inicio y fin de las horas laborales de tus empleados en un formato bien estructurado.',
                fIIPoints: [
                    'Monitorea eventos como entrada y salida, y también obtiene información de los empleados sobre sus operaciones, métricas de rendimiento o eventos.',
                    'Almacena datos para reuniones y tareas para rastrear tendencias y toma de decisiones basada en datos para los minutos de la reunión.',
                    'Gestión de clientes y prospectos para rastrear la productividad. La productividad y los indicadores clave de rendimiento de los empleados se pueden medir según la cantidad de trabajo completado.',
                    'Rastrea con precisión la distancia recorrida por los empleados en el campo según un enfoque diario, semanal y mensual y reembolsa los gastos según la distancia y las ubicaciones recorridas.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Seguimiento en Línea de la Asistencia',
                        paragraph:
                            'Monitorea eventos de asistencia y movimiento, como entrada y salida, y obtén información sobre sus operaciones, métricas de rendimiento o eventos.',
                    },
                    customFormsforDataManagement: {
                        header: 'Formularios Personalizados para la Gestión de Datos',
                        paragraph: 'Monitorea las horas laborales diarias, semanales y mensuales.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Informes de Asistencia con Acceso Remoto',
                        paragraph: 'Accede a informes de asistencia de múltiples sucursales de forma remota.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Monitoreo de Asistencia Uno',
                        description: [
                            'Obtén transparencia sobre la puntualidad de los empleados.',
                            'Ahorra tiempo y organiza la asistencia de los empleados sin ninguna acción adicional.',
                            'Monitorea fácilmente si tus empleados han llegado a tiempo al lugar de trabajo y han completado sus horas laborales.',
                            'Mantén más organizadas las operaciones de tu lugar de trabajo y crea un ambiente laboral altamente responsable.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Monitoreo de Asistencia Dos',
                        description: [
                            'Análisis inteligente de los datos de asistencia.',
                            'Filtra salidas tempranas, llegadas tardías y menos seguimientos que puedes seleccionar de la lista desplegable.',
                            'Esto te ayudará a generar la información que prefieras de inmediato sin tener que buscarla manualmente.',
                            'Puedes rastrear la asistencia diariamente o elegir un intervalo desde el calendario.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Monitoreo de Asistencia Tres',
                        description: [
                            'Forma segura y transparente de monitorear y rastrear la asistencia de los empleados en tiempo real con un sistema 100% sin contacto.',
                            'Fácil configuración.',
                            'Entradas y salidas automáticas detalladas en los informes.',
                            'Capturas de pantalla con seguimiento de tiempo.',
                            'Registro de nivel de actividad.',
                            'Monitoreo del uso de aplicaciones y sitios web.',
                        ],
                    },
                },
            },
            fieldSalesAnalytics: {
                featureHeader: 'Análisis de Ventas en Campo',
                featureDescription:
                    'Rastrea y analiza métricas clave del equipo de ventas en campo, como ingresos por ventas, interacciones con clientes y tendencias geográficas.',
                featureParagraph:
                    'No más tarjetas de tiempo, exceso de papeleo y diferentes documentos para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y ve el inicio y fin de las horas laborales de tus empleados en un formato bien estructurado.',
                fIIPoints: [
                    'Los indicadores clave de rendimiento pueden ayudar a tomar decisiones basadas en datos, lo que puede ayudar a tomar decisiones productivas para la próxima vez o a mejorar la estrategia.',
                    'Los informes se pueden acceder diariamente, semanalmente o mensualmente sobre ventas, prospectos y adquisición de clientes. Esto puede ayudar a mejorar la productividad y eficiencia de la persona.',
                    'Valida la visita con entradas basadas en geocercas para mayor precisión. Las coordenadas de la ubicación se pueden adjuntar a una foto de cámara al compartir.',
                    'La Ruta de Visitas se puede utilizar para planificar visitas a clientes. Integra tu plan de visitas en los mapas directamente para optimizar la navegación.',
                ],
            },
            locationBasedAttendance: {
                featureHeader: 'Asistencia Basada en Ubicación',
                featureDescription:
                    'Asistencia basada en geocercas para garantizar que los empleados solo puedan marcar entrada o salida cuando estén dentro de los límites geográficos predefinidos.',
                fIIPoints: [
                    'Implementa límites virtuales basados en geocercas alrededor de ubicaciones específicas de las instalaciones de la oficina. Ayuda a automatizar la asistencia según la entrada o salida de un empleado de un área designada.',
                    'Métodos de autenticación para garantizar la precisión y seguridad de los datos de asistencia, utilizando PINs, biometría o reconocimiento facial.',
                    'Asegúrate de que los registros de asistencia se actualicen puntualmente, permitiendo decisiones de gestión oportunas.',
                    'Considera métodos alternativos para el seguimiento de asistencia para empleados remotos.',
                ],
            },
            liveReporting: {
                featureHeader: 'Informes en Vivo',
                featureDescription:
                    'Seguimiento y análisis en tiempo real de diversos aspectos del trabajo, como la asistencia, tareas completadas o el progreso del proyecto.',
                fIIPoints: [
                    'El administrador o gerente puede suscribirse u optar por las notificaciones que desea recibir. Esto ayudará a centrarse en las notificaciones que realmente desean recibir.',
                    'Mantente informado sobre la asistencia y las licencias. Mantente un paso adelante y conoce el número de empleados antes para planificar el día de manera más productiva y eficiente.',
                    'Haz un seguimiento de todas las aprobaciones, desde reembolsos hasta licencias, y revísalas más tarde.',
                    'Seguimiento remoto a través de formularios y minutos de reunión. Los formularios personalizados pueden ayudar a obtener datos específicos para una reunión, los cuales se pueden acceder de forma remota.',
                ],
            },
            aIBasedOptimization: {
                featureHeader: 'Optimización Basada en IA',
                featureDescription:
                    'Optimiza y automatiza la planificación de rutas para representantes de campo o personal de ventas utilizando la optimización basada en IA.',
                fIIPoints: [
                    'Fuentes de datos en tiempo real, como actualizaciones de tráfico en vivo, condiciones climáticas y cierres de carreteras, para ajustar dinámicamente las rutas según las circunstancias actuales, lo que ayuda en la planificación diaria eficiente.',
                    'Visualiza rutas optimizadas y realiza los ajustes necesarios. Esto puede ser parte de una aplicación móvil o de una plataforma basada en la web.',
                    'Sistema de IA para tener en cuenta las características únicas de diferentes rutas.',
                    'Utiliza servicios de mapeo y datos geoespaciales para comprender la red de carreteras, condiciones de tráfico y otra información relacionada con la ubicación.',
                ],
            },
            taskManagement: {
                featureHeader: 'Gestión de Tareas',
                featureDescription:
                    'Organizar, priorizar y ejecutar eficientemente actividades para lograr objetivos específicos.',
                featureParagraph:
                    'No más tarjetas de tiempo, exceso de papeleo y documentos diferentes para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y visualiza el inicio y fin de las horas laborales de tus empleados en un formato bien estructurado.',
                fIIPoints: [
                    'Monitorea el progreso en tiempo real visualizando tableros de tareas, verificando el estado de las tareas y rastreando el tiempo empleado.',
                    'Características de colaboración en la gestión de tareas para comunicarte con los miembros del equipo en tiempo real. Comenta sobre tareas, haz preguntas y proporciona actualizaciones para mantener a todos informados.',
                    'Gestión de clientes y prospectos para rastrear la productividad. La productividad de los empleados y los indicadores clave de rendimiento se pueden medir según la cantidad de trabajo completado por los empleados.',
                    'Estructura jerárquica para la organización de metas: metas del proyecto, objetivos departamentales, metas de rendimiento individual, iniciativas estratégicas, etc.',
                ],
            },
        },
    },
    payrollAndLeaveManagement: {
        productName: 'Gestión de Nómina y Permisos',
        titleDescription: 'Optimiza el procesamiento de nómina y la administración de permisos',
        headerCaption: 'Por qué elegir TraceForce Lead Management CRM',
        productCards: [
            {
                title: 'Facilidad de Gestión',
                data: 'Simplifica la gestión centralizando la información del cliente, automatizando tareas rutinarias y optimizando la comunicación.',
            },
            {
                title: 'Personalización',
                data: 'Personalización',
            },
            {
                title: 'Integraciones',
                data: 'Habilita integraciones para compartir datos, automatizar tareas y mejorar la colaboración entre equipos, lo que lleva a una implementación de CRM más eficiente y efectiva.',
            },
        ],
        whyChooseSubCaption:
            'Rastrea y gestiona eficientemente los leads, asegurando que no se pierda ninguna oportunidad y mejorando las tasas de conversión.',
        headerT1: 'Beneficios del Sistema de Gestión de Leads TraceForce',
        subCaptionT1:
            'Automatización de Captura y Distribución de Leads\nIntegración de Datos en Tiempo Real y Reportes\nAutomatización de Herramientas de Ventas y Marketing',

        features: {
            leadManagement: {
                featureHeader: 'Procesamiento de Nómina',
                featureDescription:
                    'Seguimientos oportunos, interacciones personalizadas y tasas de conversión maximizadas.',
                featureParagraph:
                    'No más tarjetas de tiempo, exceso de papeleo y diferentes documentos para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y visualiza el inicio y fin de las horas laborales de tus empleados en un formato bien estructurado.',
                fIIPoints: [
                    'Optimiza tu proceso de gestión de leads según el tiempo y ubicación de la fuerza de ventas, facilitando la consecución de metas en menos tiempo.',
                    'Usa historiales de contactos para crear segmentos basados en visitas al sitio web, interacciones por correo electrónico, y más. Segmenta la fuerza de ventas con estos datos para gestionar leads.',
                    'Utiliza puntajes de leads para asignar prospectos a tu equipo de ventas, o configura flujos de trabajo para notificar a los representantes cuando sus prospectos estén listos para la conversión.',
                    'Accede fácilmente al historial de cada lead, incluyendo la información de la empresa y una cronología de todos los puntos de contacto entre el contacto y tu negocio.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Seguimiento de asistencia basado en línea de tiempo',
                        paragraph:
                            'Monitorea eventos como entradas y salidas, además de proporcionar información sobre el rendimiento de los empleados, métricas de desempeño o eventos.',
                    },
                    customFormsforDataManagement: {
                        header: 'Formularios Personalizados para Gestión de Datos',
                        paragraph: 'Monitorea las horas de trabajo diarias, semanales y mensuales.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Acceso Remoto a Reportes de Asistencia',
                        paragraph: 'Accede a los reportes de asistencia de múltiples sucursales de forma remota.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Monitoreo de Asistencia Uno',
                        description: [
                            'Transparencia en la puntualidad de los empleados.',
                            'Ahorra tiempo y organiza la asistencia de los empleados sin ninguna acción adicional.',
                            'Monitorea fácilmente si tus empleados han llegado a tiempo al lugar de trabajo y si han completado sus horas laborales.',
                            'Mantén las operaciones de tu lugar de trabajo más organizadas y un ambiente laboral altamente responsable.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Monitoreo de Asistencia Dos',
                        description: [
                            'Análisis Inteligente de Datos de Asistencia.',
                            'Filtra salidas anticipadas, llegadas tardías y menos seguimiento que puedes seleccionar desde una lista desplegable.',
                            'Esto te ayudará a generar instantáneamente la información que prefieras sin tener que buscarla manualmente.',
                            'Puedes rastrear la asistencia de forma diaria o elegir un intervalo desde el calendario.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Monitoreo de Asistencia Tres',
                        description: [
                            'Método seguro y transparente para monitorear y rastrear la asistencia de los empleados en tiempo real con un sistema 100% sin contacto.',
                            'Fácil Configuración.',
                            'CHECK-IN Y OUTS AUTOMATIZADOS INFORMES DETALLADOS.',
                            'CAPTURAS DE PANTALLA CON TIEMPO DE SEGUIMIENTO.',
                            'REGISTRO DE NIVELES DE ACTIVIDAD.',
                            'MONITOREO DE USO DE APPS Y SITIOS WEB.',
                        ],
                    },
                },
            },
            leaveManagement: {
                featureHeader: 'Gestión de Permisos',
                featureDescription:
                    'Gestiona y rastrea eficientemente los recuentos de personal, asegurando la documentación adecuada, el cumplimiento de las políticas de la empresa y la continuidad fluida del flujo de trabajo.',
                featureParagraph:
                    'No más tarjetas de tiempo, exceso de papeleo y diferentes documentos para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y visualiza el inicio y fin de las horas laborales de tus empleados en un formato bien estructurado.',
                fIIPoints: [
                    'Gestiona los acumulados de vacaciones, asegurando que los empleados no superen las cantidades asignadas y evita saldos negativos de permisos.',
                    'Configura políticas flexibles de permisos de la organización creando el Calendario de Permisos. Habilita una configuración jerárquica paralela o secuencial para la aprobación o rechazo de solicitudes de permiso.',
                    'Mantente informado sobre las leyes laborales y regulaciones relacionadas con los derechos de permisos. Asegúrate de que tus políticas de gestión de permisos cumplan con las políticas de la empresa.',
                    'Ofrece a los empleados opciones de autoservicio, permitiéndoles consultar sus saldos de permisos, enviar solicitudes y ver el estado de sus solicitudes.',
                ],
            },
        },
    },
    orderManagement: {
        productName: 'Gestión de Pedidos',
        titleDescription: 'Simplifica las operaciones de pedidos/colección con una solución escalable y automatizada.',
        headerCaption: 'Por qué elegir TraceForce Lead Management CRM',
        productCards: [
            {
                title: 'Facilidad de Gestión',
                data: 'Simplifica la gestión centralizando la información del cliente, automatizando tareas rutinarias y optimizando la comunicación.',
            },
            {
                title: 'Personalización',
                data: 'Personalización',
            },
            {
                title: 'Integraciones',
                data: 'Habilita integraciones para compartir datos, automatizar tareas y mejorar la colaboración entre equipos, lo que lleva a una implementación de CRM más eficiente y efectiva.',
            },
        ],
        whyChooseSubCaption:
            'Rastrea y gestiona eficientemente los leads, asegurando que no se pierda ninguna oportunidad y mejorando las tasas de conversión.',
        headerT1: 'Beneficios del Sistema de Gestión de Leads TraceForce',
        subCaptionT1:
            'Automatización de Captura y Distribución de Leads\nIntegración de Datos en Tiempo Real y Reportes\nAutomatización de Herramientas de Ventas y Marketing',
        features: {
            digitizationOfOrder: {
                featureHeader: 'Digitalización de Pedidos',
                featureDescription: 'Optimiza la gestión de pedidos de principio a fin y reduce errores manuales.',
                featureParagraph:
                    'No más tarjetas de tiempo, exceso de papeleo y diferentes documentos para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y visualiza el inicio y fin de las horas laborales de tus empleados en un formato bien estructurado.',
                fIIPoints: [
                    'Genera reportes detallados y análisis para monitorear el rendimiento del procesamiento de pedidos y la productividad de los empleados.',
                    'Captura y valida automáticamente los detalles de los pedidos ingresados por empleados o clientes.',
                    'Proporciona actualizaciones en tiempo real sobre el estado del pedido y el seguimiento del envío a empleados y clientes.',
                    'Automatiza los procesos de aprobación de pedidos y asigna tareas a los empleados correspondientes.',
                ],
                keyPoints: {
                    realTimeAttendance: {
                        header: 'Seguimiento de asistencia basado en línea de tiempo',
                        paragraph:
                            'Monitorea eventos como entradas y salidas, además de proporcionar información sobre el rendimiento de los empleados, métricas de desempeño o eventos.',
                    },
                    customFormsforDataManagement: {
                        header: 'Formularios Personalizados para Gestión de Datos',
                        paragraph: 'Monitorea las horas de trabajo diarias, semanales y mensuales.',
                    },
                    remoteAccessAttendanceReports: {
                        header: 'Acceso Remoto a Reportes de Asistencia',
                        paragraph: 'Accede a los reportes de asistencia de múltiples sucursales de forma remota.',
                    },
                },
                softwareDetails: {
                    attendanceMonitoringOne: {
                        title: 'Monitoreo de Asistencia Uno',
                        description: [
                            'Transparencia en la puntualidad de los empleados.',
                            'Ahorra tiempo y organiza la asistencia de los empleados sin ninguna acción adicional.',
                            'Monitorea fácilmente si tus empleados han llegado a tiempo al lugar de trabajo y si han completado sus horas laborales.',
                            'Mantén las operaciones de tu lugar de trabajo más organizadas y un ambiente laboral altamente responsable.',
                        ],
                    },
                    attendanceMonitoringTwo: {
                        title: 'Monitoreo de Asistencia Dos',
                        description: [
                            'Análisis Inteligente de Datos de Asistencia.',
                            'Filtra salidas anticipadas, llegadas tardías y menos seguimiento que puedes seleccionar desde una lista desplegable.',
                            'Esto te ayudará a generar instantáneamente la información que prefieras sin tener que buscarla manualmente.',
                            'Puedes rastrear la asistencia de forma diaria o elegir un intervalo desde el calendario.',
                        ],
                    },
                    attendanceMonitoringThree: {
                        title: 'Monitoreo de Asistencia Tres',
                        description: [
                            'Método seguro y transparente para monitorear y rastrear la asistencia de los empleados en tiempo real con un sistema 100% sin contacto.',
                            'Fácil Configuración.',
                            'Check-In y Check-Out Automatizados, Informes Detallados.',
                            'Capturas de Pantalla con Seguimiento de Tiempo.',
                            'Registro de Niveles de Actividad.',
                            'Monitoreo del Uso de Aplicaciones y Sitios Web.',
                        ],
                    },
                },
            },
            targetManagement: {
                featureHeader: 'Gestión de Objetivos',
                featureDescription:
                    'Establece objetivos claros y alcanzables para cada empleado en función de su rol y capacidades.',
                featureParagraph:
                    'No más tarjetas de tiempo, exceso de papeleo y diferentes documentos para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y visualiza el inicio y fin de las horas laborales de tus empleados en un formato bien estructurado.',
                fIIPoints: [
                    'Alcanza objetivos diarios, semanales y mensuales y mantiene registros de los logros de los empleados con seguimiento en tiempo real.',
                    'Progreso en Tiempo Real (número de objetivos alcanzados vs. pendientes), Tiempos de Entrada/Salida, número de horas (duración) dedicadas a cada tarea, y seguimiento de ubicación.',
                    'Métricas de Desempeño (tasa de finalización, tiempo empleado, puntajes de satisfacción del cliente), Comparación Contra Objetivos (desempeño real vs. objetivo), Datos Históricos (tendencias de desempeño pasado).',
                    'Envía notificaciones automáticas para mantener a los empleados informados sobre alertas de logros de objetivos, recordatorios de desempeño y advertencias de plazos.',
                ],
            },
            invoiceManagement: {
                featureHeader: 'Gestión de Facturas',
                featureDescription:
                    'Crea y rastrea el estado y procesamiento de facturas en tiempo real con alertas y notificaciones automatizadas.',
                fIIPoints: [
                    'Configura sistemas para rastrear el estado de las facturas y envía recordatorios y alertas automáticas para pagos vencidos y pendientes.',
                    'Plantillas estandarizadas de facturas que incluyen todos los detalles necesarios como información del cliente, detalles del pedido, impuestos, descuentos y términos de pago.',
                    'Recoge firmas digitales y pruebas de entrega basadas en OTP como parte del proceso de facturación.',
                    'Sincronización automática con el sistema central (almacenamiento en la nube) y actualizaciones en tiempo real sobre el estado de la factura.',
                ],
            },
            distributorOrder: {
                featureHeader: 'Pedido de Distribución',
                featureDescription:
                    'Gestiona productos/colección y maneja la priorización de tareas con actualizaciones en tiempo real sobre el inventario.',
                fIIPoints: [
                    'Crea y procesa pedidos con todos los detalles relevantes para cada distribución y asigna tareas según la disponibilidad.',
                    'Integra con la gestión de inventarios para garantizar una asignación precisa de existencias y actualiza los niveles de inventario en tiempo real.',
                    'Configura el sistema para enviar notificaciones automáticas en etapas clave del proceso de pedidos.',
                    'Accede a un tablero donde los empleados pueden consultar pedidos, rastrear el progreso y descargar los documentos necesarios.',
                ],
            },
            inventoryManagement: {
                featureHeader: 'Gestión de Inventarios',
                featureDescription:
                    'Mantén el equilibrio adecuado de existencias con actualizaciones en tiempo real y seguimiento de inventario para obtener datos precisos.',
                fIIPoints: [
                    'Asegura una integración perfecta entre los sistemas de gestión de inventarios y pedidos para sincronizar los niveles de existencias con los pedidos entrantes y salientes.',
                    'Rastrea inventarios en múltiples almacenes, tiendas o ubicaciones y accede a un control centralizado, optimiza la distribución de existencias y mejora la visibilidad.',
                ],
            },
            orderCollectionManagement: {
                featureHeader: 'Gestión de Pedidos/Colecciones',
                featureDescription:
                    'Simplifica las operaciones de pedidos/colecciones con una solución escalable y automatizada.',
                fIIPoints: [
                    'Un sistema centralizado accesible tanto por empleados en sitio como fuera de sitio para gestionar pedidos, rastrear el progreso y actualizar el estado en tiempo real.',
                    'Diferentes niveles de acceso y permisos según los roles (por ejemplo, gerentes, empleados en sitio, empleados fuera de sitio) para garantizar la seguridad de los datos y el acceso adecuado.',
                    'Asegura que todos los datos de pedidos estén sincronizados en tiempo real en todos los dispositivos y ubicaciones, para que todos tengan acceso a la información más reciente.',
                    'Facilita la comunicación entre empleados en sitio y fuera de sitio a través de mensajería y notificaciones en la aplicación.',
                ],
            },
        },
    },
};
export default products;
