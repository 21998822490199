const features = {
    attendanceMonitoring: {
        featureSubcaption: 'Efficiency in every tick - Innovative solutions for employee management systems',
        featureHeader: 'Attendance Monitoring',
        featureDescription:
            'Real-time clock in/clock out information about the presence or absence of individuals within the Geofence area or Branch',
        featureParagraph:
            "No more timecards, excessive paperwork and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format",
        featureCaption: 'Manage your multiple branch attendance for your field employees and on-premise Employees',
        keyPoints: [
            {
                header: 'Real Time Attendance',
                paragraph:
                    'Real-time information about the presence or absence of individuals within the Geofence area or Branch',
            },
            {
                header: 'Total Working Hours Calculation',
                paragraph: 'Monitor working hours based on Daily, Weekly and Monthly',
            },
            {
                header: 'Remote Access Attendance Reports',
                paragraph: 'Access attendance Reports of Multiple Branches remotely',
            },
            {
                header: 'Centralized Attendance Management',
                paragraph:
                    'Consolidation of attendance-related data, processes, and systems into a single, centralized platform or system',
            },
        ],
        softwareDetails: [
            {
                title: 'Get insights on employee attendance tracking',
                description: [
                    "Gain Transparency of Employees' Punctuality",
                    'Save time and organize employee attendance without any additional action.',
                    'Easily monitor whether your employees have arrived at the workplace in time and completed their working hours or not.',
                    'Keep your workplace operations more organized and have a highly responsible working environment.',
                ],
                sDKeys: [
                    'Automatically log in and log out on premise staff by setting up geofence perimeter.',
                    'Receive notification alerts for employees who forgets or fails to log in or log out.',
                    'Locate and track your field staff’s movements in real-time right after they log in their attendance.',
                ],
            },
            {
                title: 'Get detailed attendance monitoring reports',
                description: [
                    'Intelligent Analysis of Attendance Data',
                    'Filters early leaving, late coming, and less tracking that you can select from the drop-down list.',
                    'This will help you to instantly generate your preferred information without having to search for it manually.',
                    'You can track attendance on a daily basis or you can choose an interval from the calendar.',
                ],
                sDKeys: [
                    'Get attendance summaries for your organization.',
                    'Generate and utilize reports to view viral trends within your team, departments or branches.',
                    'Receive reports at regular intervals in your mail box.(Log in time, log out time, total working hours, total sessions)',
                    'Insightful data for better decision making.',
                ],
            },
            {
                title: 'Explore other time and productivity features.',
                description: [
                    'Secure and transparent way to monitor and track employee attendance in real-time with a 100% touchless system.',
                    'Easy Setup',
                    'Automated check in and outs detailed reports',
                    'Screenshots with tracking time',
                    'Activity Level Recording',
                    'Apps and Website Usage Monitoring',
                ],
                sDKeys: [
                    'Employee attendance has been shown to assist manage time and improve productivity.',
                    'Eliminate paperwork and gather accurate employees attendance records.',
                ],
            },
        ],
    },
    leaveManagement: {
        featureHeader: 'Leave Management',
        feaatureSubcaption:
            'Coordinate staff absences while maintaining productivity levels and operational continuity.',
        featureDescription:
            'Efficiently handle and track absences and presences, ensuring proper documentation, adherence to company policies, and maintaining smooth workflow continuity.',
        featureParagraph:
            "No more timecards, excessive paperwork and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format.",
        featureCaption: 'Stay informed about your employees upcoming leaves in advance and plan your day accordingly',
        keyPoints: [
            {
                header: 'Automatic Calculation of Leave Accruals and Balances',
                paragraph:
                    "Manage vacation accruals, ensuring employees don't go over allotted amounts, and prevent negative leave balances.",
            },
            {
                header: 'Flexible Leave Policies, Approval Workflows',
                paragraph:
                    'Configure flexible leave policies of the organisation by setting up the leave calendar. Enable parallel or sequential hierarchy setup for leave requests approval or rejection.',
            },
            {
                header: 'Adherence to Labor Laws and Company Policies',
                paragraph:
                    'Stay informed about labor laws and regulations related to leave entitlements. Ensure that your leave management policies are compliant with company policies.',
            },
            {
                header: 'Self-Service Options',
                paragraph:
                    'Provide employees with self-service options, allowing them to check their leave balances, submit requests, and view the status of their requests.',
            },
        ],
        softwareDetails: [
            {
                title: 'Multiple and flexible leave policies for your team',
                description: [
                    'Flexible leave policies to meet your exact needs with configurable parameters',
                    'Multiple leave schemes and policies tailored for different employee groups',
                    'Unlimited leave types and leave transactions supported',
                ],
                sDKeys: [
                    'These policies include leaves type such as annual leave, sick leaves, parental leaves, bereavement leaves, etc.',
                    'Set eligibility criteria for policies',
                    'Accrual rates, usage rules, documentations required',
                ],
            },
            {
                title: 'Self service for leave application and approval workflow',
                description: [
                    'Automation reduces employee efforts. Employees apply for leave through web portal or mobile app',
                    'Leave tracker for leave balances and transactions',
                    'Manager view of team leave data & leave patterns for better insights',
                    'Workflows with escalations lead to a responsive system and a great employee experience',
                    'Email notifications and alerts',
                ],
                sDKeys: [
                    'Employees apply for leave through web portal or mobile app',
                    'Leave tracker for leave balances and transactions',
                    'Manager view of team leave data & leave patterns for better insights',
                    'Workflows with escalations lead to a responsive system and a great employee experience',
                    'Email notifications and alerts',
                ],
            },
            {
                title: 'Comply with company policies and labour law',
                description: [
                    "Effective employee leave management not only ensures compliance with labor laws but also contributes to a positive workplace culture. By implementing efficient leave policies and utilizing technology, organizations can streamline the process and support their employees' need for time off.",
                ],
                sDKeys: [
                    'Manage leave liabilities for budget effectively and minimize financial risks',
                    'Generate statistical data for individual reports to track performance of monthly, quarterly or yearly based',
                    'Stay informed about workforce planning, resource allocation and employee engagement strategies',
                    'Detailed records & reports as per government rules',
                    'Customised comprehensive holiday list for a diverse workforce',
                ],
            },
        ],
    },
    shiftManagement: {
        featureHeader: 'Shift Management',
        feaatureSubcaption:
            'Schedule workforce with sales activities, ensure optimal staffing level to meet customer demands and maximize productivity',
        featureDescription:
            'Organize and schedule work hours that optimizes productivity, meets operational needs, ensures coverage across different time periods',
        featureParagraph:
            "No more timecards, excessive paperwork and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format",
        featureCaption: 'Track your employees shift based on total working hours and multiple session aggregated',
        keyPoints: [
            {
                header: 'Manage Employee Work Schedules',
                paragraph:
                    'Develop a clear and well-organized shift schedule. Define different types of shifts based on the nature of your business',
            },
            {
                header: 'Defining Shifts, Assign Roles, Set Recurring or Custom Schedules',
                paragraph:
                    'Analyze the workload and tasks to determine the number of employees needed during different shifts',
            },
            {
                header: 'Built-In Communication Tools',
                paragraph:
                    'Centralized platform for employees to access their schedules, and establish a reliable communication channel for any updates or changes',
            },
            {
                header: 'Centralized Shift Management with Multiple Location Schedules',
                paragraph:
                    'Centralized oversight and control of employees schedules, allowing managers to efficiently allocate shifts, track attendance, and manage workforce resources across multiple locations from a single platform',
            },
        ],
        softwareDetails: [
            {
                title: 'Automate scheduling process based on location and shift',
                description: [
                    'Analyzing the workload and tasks to determine the number of employees needed during different shifts',
                    'Consider peak business hours, seasonal variations, and any specific requirements for each shift. This could include day shifts, night shifts, rotating shifts, split shifts, or flexible schedules',
                ],
                sDKeys: [
                    'Shift swapping among employees',
                    'Employees can exchange or swap their shifts with other employees with the available feature/option',
                    'Employees can pick their shift and time-offs that works best for them',
                ],
            },
            {
                title: 'Shift board for dynamic shift scheduling',
                description: [
                    'Ensure compliance with labor laws regarding breaks and rest periods',
                    'Build breaks into the shift schedule to prevent employee fatigue and maintain productivity',
                    'Ensure that your shift management policies align with legal requirements',
                ],
                sDKeys: [
                    'Effortlessly build efficient work schedules for your team using user-friendly scheduling tools',
                    "Tailor shifts to match your business's operational demands and employee availability",
                    'Ensure fair distribution of shifts while considering employee preferences and workload',
                ],
            },
            {
                title: 'Encourage employees with shift allowances for working at odd hours',
                description: [
                    'Integrate shift management systems with payroll processes to ensure accurate compensation and deductions based on shifts worked',
                    'Defining procedures for overtime and ensure that overtime policies comply with labor regulations',
                ],
                sDKeys: [
                    'Approve employees shift request in a single click',
                    'Employees can choose their own shift at the start of a week/month and send requests from their portal as per their convenience',
                    'Profitable solution by paying shift allowances based on hourly pay rate or companies policy',
                ],
            },
        ],
    },
    geoFenceBasedManagement: {
        featureHeader: 'Geo Fence Based Attendance',
        feaatureSubcaption:
            'Maximizing accuracy and accountability, track employee presence with designated boundaries',
        featureDescription:
            'location-based technology to monitor and verify employee presence within predefined geographical boundaries',
        featureCaption:
            'Geo Fence based attendance leverage the precision of your Attendance tracking as employees are marked present only when they are in the designated area',
        keyPoints: [
            {
                header: 'Accurately Locate Employees Within the Designated Geo-Fenced Area',
                paragraph:
                    'Accurate attendance records to ensure that employees are present at their designated work locations',
            },
            {
                header: 'Real-Time Monitoring of Employee Locations',
                paragraph: "Implement real-time tracking to monitor employees' locations within the geo-fence",
            },
            {
                header: 'Define Specific Geographical Boundaries for Clocking In or Out',
                paragraph:
                    'Establish specific geographic boundaries, known as geo-fences, around the workplace or designated attendance locations',
            },
            {
                header: 'Geofence Alerts',
                paragraph:
                    'Set up alerts or notifications to inform managers or HR personnel when employees enter or leave the defined geo-fence boundaries',
            },
        ],
        softwareDetails: [
            {
                title: 'Location restriction on attendance marking',
                description: [
                    'Establishing specific geographical boundaries using GPS coordinates or location-based technologies. These boundaries represent the areas where employees are expected to be present for work.',
                ],
                sDKeys: [
                    'Optional user restricting feature to allow attendance from a specific IP address to ensure attendance from the office vicinity.',
                    'Ease of communication with specific or all users using the Push Notifications Feature.',
                    'Link tasks with attendance employees to view daily tasks and schedules.',
                ],
            },
            {
                title: 'Set attendance restrictions and biometric authentication',
                description: [
                    "Implementing real-time location tracking to monitor employees' locations within the geo-fenced areas. This information is used to confirm attendance and ensure that employees are within the designated work locations during scheduled hours.",
                ],
                sDKeys: [
                    'Employees can only mark their attendance once they reach their first task location.',
                    'Employees can mark their attendance remotely; no more faulty attendance or travelling to the office daily just to mark their presence.',
                    'Managers can track real time location and receive update remotely through phone applications or web.',
                ],
            },
            {
                title: 'Enhance security and authorization',
                description: [
                    'Implement secure authentication measures to prevent fraudulent attendance entries by using PIN codes, biometric authentication and facial recognition.',
                    'Set up mobile notifications or alerts for employees, reminding them to check in when they enter the geo-fenced area. This ensures that employees are aware of the attendance tracking system.',
                ],
            },
        ],
    },
    multipleClockInOptions: {
        featureHeader: 'Multiple Clock In Options',
        feaatureSubcaption:
            'Providing flexibility and convenience, multiple clock in options empower employees to manage their time efficiently.',
        featureDescription:
            'Provide flexibility to employees to record their work hours using biometric scanners, mobile apps, time clocks and web-based platforms.',
        featureCaption:
            'Enable ease of use for employees who are working remotely and can clock multiple time with different session',
        keyPoints: [
            {
                header: 'Suitable for Organizations with Multiple Locations',
                paragraph:
                    'Flexible clock in options for on-field and off-field attendance management of different branches.',
            },
            {
                header: 'Employee Self-Service Portal',
                paragraph:
                    'Provide employees with access to a self-service portal of attendance records and manage their check-ins and check-outs within the geo-fence.',
            },
            {
                header: 'Multiple Options for Clocking In',
                paragraph:
                    'Flexible clock-in options include biometric scans, mobile apps, RFID cards, and facial recognition ensuring seamless time tracking across various work environments.',
            },
            {
                header: 'Accurate Geo-Based Clock In',
                paragraph:
                    'Auto Clock in attendance marking for employees within the range of office premises with alert notification.',
            },
        ],
        softwareDetails: [
            {
                title: 'On Site and Off Site Clock ins',
                description: [
                    'Catering to diverse work environments, our employee attendance management system offers multiple clock-in options.',
                    'Whether employees are on-site, in the field, or working remotely, they can conveniently log their time through biometric scans, Facial Recognition and geo fence based auto clock in.',
                ],
            },
            {
                title: 'Employee Self Service Portal to check their records',
                description: [
                    'Employee self-service portal provides control over multiple check-ins and check-outs.',
                    "With user-friendly features, employees can easily manage their work hours whether they're transitioning between shifts, locations, or tasks.",
                    'This empowers staff with flexibility while maintaining accurate time records, enhancing efficiency, and streamlining administrative processes.',
                ],
            },
            {
                title: 'Multiple Clock ins for Multiple Branches',
                description: [
                    'Accommodate the dynamic needs of your company spanning multiple locations and ensure accurate attendance tracking and operational efficiency.',
                ],
            },
        ],
    },
    attendanceReport: {
        featureHeader: 'Attendance Reports',
        feaatureSubcaption:
            'An insightful reflection of team commitments and punctuality, captured in a single report.',
        featureDescription:
            'Summarize employee attendance data, working hours, absences, late arrivals, and early departures within a specific timeframe.',
        featureCaption:
            'Generate automated reports for different branches and receive them on daily basis, weekly, monthly with over 40+ templates',
        keyPoints: [
            {
                header: 'Automate Attendance Details: Log-In Time, Late Time, Total Working Hours, Total Sessions',
                paragraph:
                    'Provide an overall summary of attendance, including the total number of working days, total hours worked, and any overtime hours.',
            },
            {
                header: 'Multiple Branches Reports',
                paragraph: 'Remotely access multiple branch attendance reports with customizable categories.',
            },
            {
                header: 'Overtime and Leave Balances',
                paragraph:
                    'Display leave balances for each employee, including vacation days, sick leave, and any other types of leave accrued and utilized.',
            },
            {
                header: 'Customized Attendance Report',
                paragraph:
                    'Allow users to customize reports based on specific criteria, such as department, team, or individual employee.',
            },
        ],
        softwareDetails: [
            {
                title: 'Generate reports based on your organization structure.',
                description: [
                    'Regularly generated and analyzed attendance reports can provide valuable insights for improving workforce management, identifying trends, and ensuring compliance with organizational policies.',
                    'The specifics of the report will depend on the needs and structure of organization.',
                ],
                sDKeys: [
                    'The information collected from various user-ends is collated to give automated analytics reducing the time and resources spent on generating reports manually.',
                    'Users can select their required analytics within a matter of a few clicks and can subscribe to it at any moment.',
                    'Sample analytics report can be downloaded and can be viewed as an actual representation of the desired report.',
                ],
            },
            {
                title: 'Easily gain customizable reports.',
                description: [
                    'Identify and highlight any exceptions or irregularities in attendance, such as unauthorized absences, excessive lateness, or instances of unapproved overtime.',
                    'Use charts or graphs to visually represent attendance trends and patterns. This can make it easier to identify outliers and trends at a glance.',
                ],
                sDKeys: [
                    'User can get benefit from the data insights generated in various ways such as payroll processing, performance appraisals, and evaluation, reimbursement, etc.',
                    'Reports be shared with other users based on the hierarchical flow of authority.',
                    'company can be collectively aware of the work status and performance of their team in the easiest way without any manual interventions',
                ],
            },
            {
                title: 'Reduce your time spent on manual reports.',
                description: [
                    'Includes fundamental details such as employee names, identification numbers, and department affiliations to provide context to the attendance data.',
                    'Clearly specifying the date and time range covered by the report. This helps users understand the temporal scope of the data.',
                    'Providing an overall summary of attendance, including the total number of working days, total hours worked, and any overtime hours.',
                ],
            },
        ],
    },
    workforceTracking: {
        featureHeader: 'Workforce Tracking',
        featureDescription:
            "Systematic monitoring and management of employees' activities, productivity, and attendance.",
        featureParagraph:
            "No more timecards, excessive paperwork, and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format.",
        featureCaption:
            "Monitor and manage various aspects of an organization's workforce, including employee attendance, work hours, productivity, and performance.",
        keyPoints: [
            {
                header: 'Time Line based Tracking of Attendance and Movement',
                paragraph:
                    "Monitor events like clock in and clock out and also employees' insights into their operations, performance metrics, or events.",
            },
            {
                header: 'Custom Forms for Data Management',
                paragraph:
                    'For minutes of meeting, store data for meetings and tasks to track trends and data-driven decision-making.',
            },
            {
                header: 'Customer and Lead Management',
                paragraph:
                    'Track productivity and measure key performance indicators based on the amount of work completed by employees.',
            },
            {
                header: 'Total Distance Calculations for Accurate Reimbursement Claims',
                paragraph:
                    "Track employees' accurate distance traveled for fieldwork based on daily, weekly, and monthly metrics, and reimburse expenses as per the distance and locations traveled.",
            },
        ],
        softwareDetails: [
            {
                title: 'Implement time tracking systems to monitor work hours.',
                description: [
                    'Implementing time tracking solutions to monitor the time employees spend on tasks and projects.',
                    'This can help in understanding resource utilization and improving time management.',
                    'Utilize project management systems to track project progress, assign tasks, and monitor deadlines.',
                    'This helps in optimizing project workflows and ensuring timely completion.',
                ],
            },
            {
                title: 'Monitor relevant key performance indicators.',
                description: [
                    "Defining and tracking key performance indicators relevant to your organization's goals.",
                    'This may include sales targets, customer satisfaction metrics, or other performance indicators.',
                ],
                sDKeys: [
                    'Sales Performance: Monthly/Quarterly Sales Revenue, number of Deals Closed, sales Conversion Rate, average Deal Size, sales Pipeline Value.',
                    'Customer Service: Customer satisfaction score (CSAT), net promoter score (NPS), first response time, average resolution time, customer retention Rate, ticket Volume.',
                    'Operations/Production: Production output/throughput, quality metrics (defect rate, error rate), On-Time Delivery performance, inventory turnover ratio, equipment downtime, employee utilization rate.',
                    'General: Employee productivity (output per hour), Attendance and punctuality, Goal achievement rate, Employee feedback and performance reviews.',
                ],
            },
            {
                title: 'Generate reports of your workforce trends.',
                description: [
                    'Integrating analytics and reporting features to gain insights into workforce trends, performance, and areas for improvement.',
                    'Customized reports can aid decision-making.',
                ],
                sDKeys: [
                    'Comparison of demographic composition to the overall workforce or industry benchmark and identification of any demographic trends or shifts over time.',
                    'Comparison of performance metrics across different field teams, regions, or time periods.',
                    'Geospatial mapping of field force activities to visualize distribution and coverage areas.',
                    'Analysis of sales or service performance by geographic region or territory. Identification of high-potential areas for expansion or optimization.',
                ],
            },
        ],
    },
    fieldSalesAnalytics: {
        featureHeader: 'Field Sales Analytics',
        featureDescription:
            'Track and analyze key metrics of sales team on field such as sales revenue, customer interactions, and geographical trends.',
        featureParagraph:
            "No more timecards, excessive paperwork, and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format.",
        featureCaption:
            'Analyse the collection, analysis, and interpretation of data related to field sales activities to gain insights and make informed decisions.',
        keyPoints: [
            {
                header: 'Key Performance Indicator for Sales and Leads',
                paragraph:
                    'Key Performance Indicator can help in making data-driven decisions which can help in making productive decisions for the next time or revamping the strategy.',
            },
            {
                header: 'Custom Reports',
                paragraph:
                    "Reports can be accessed on a daily, weekly, or monthly basis on Sales, Lead, and Customer Acquisition. This could help in the person's productivity and efficiency.",
            },
            {
                header: 'Validate Sales Visit and Streamline with Geofence',
                paragraph:
                    'Validate the visit with Geofence-based check-ins for accuracy. Coordinates of the location could be attached to a camera pic while sharing.',
            },
            {
                header: 'Measure Beat Route Effectiveness',
                paragraph:
                    'Beat Route could be used for planning visits or beat plans for employees to client visits. Integrate your beat plan in Maps directly to optimize navigation.',
            },
        ],
        softwareDetails: [
            {
                title: 'Everyday field insights for a better sales performance.',
                description: [
                    'Data analytic oriented concurrent e-report generation Reliable and user-friendly go-to report feature for your easy access and usage.',
                    'You as a sales manager will have a clear look over various reports which would be sent to your mail address or you can also view it in your dashboard insight.',
                    'Some of the notable reports are monthly reports, branch location-wise reports, employee-specific reports, etc.',
                ],
            },
            {
                title: 'Overall look on workfield attendance.',
                description: [
                    'Overall look through on employee field attendance and field visits',
                    'Have a whole lot of view on-field workforce attendance and meetings.',
                    'In the field attendance report, you will get data about field attendance marking time, location, late attendance time, working hours, etc.',
                    'In the same way, we also provide you with field visits check-in/out reports. It includes data related to field visits such as login/out time, check-in/out time, location of meetings, distance travelled, meeting results notes, etc.',
                ],
            },
            {
                title: 'Data analytic oriented report generated',
                description: [
                    'Subscribe and download monthly e-reports.',
                    'You can subscribe and download the monthly e-reports whenever you want.',
                    'Available in pdf and excel file format. A complete pile of reports lined up for you to have detailed information about an employee’s external action outside the organization which in manual tends to be a difficult task.',
                ],
            },
        ],
    },
    locationBasedAttendance: {
        featureHeader: 'Location Based Attendance',
        featureDescription:
            'Geo fence based attendance to ensure that employees can only clock in or out when they are within the predefined geographic boundaries.',
        featureCaption:
            'Location based attendance assures precision to monitor and record employee attendance based on their physical location',
        keyPoints: [
            {
                header: 'Geofencing',
                paragraph:
                    'Implement geofenced virtual boundaries around specific locations of office premises. Helping in automating attendance based on an employee entering or leaving a designated area.',
            },
            {
                header: 'Authentication and Security',
                paragraph:
                    'Authentication methods to ensure the accuracy and security of attendance data using PINs, biometric, or facial attendance.',
            },
            {
                header: 'Real-Time Updates',
                paragraph: 'Ensure attendance records are promptly updated, allowing for timely management decisions.',
            },
            {
                header: 'Remote Work Considerations',
                paragraph: 'Consider alternative methods for attendance tracking for remote employees.',
            },
        ],
        softwareDetails: [
            {
                title: 'Time and location of workforce.',
                description: [
                    'Know When and Where Your Employees Work',
                    'Instruct your employees to give permission on their mobile phones so that it can track time and location.',
                    'Once permission is granted, the tracker can start functioning with a tap.',
                ],
            },
            {
                title: 'Live Reporting Two',
                description: [
                    "Implementing a location-based employee attendance system can enhance accuracy and streamline attendance management. However, it's crucial to balance the benefits with employee privacy considerations and communicate transparently about the implementation and use of such systems.",
                ],
            },
            {
                title: 'Geo fence based alert',
                description: [
                    'Set up automated alerts for unusual attendance patterns or discrepancies. This helps in identifying potential issues, such as unauthorized check-ins or check-outs.',
                    'Implement reporting and analytics features to gain insights into attendance patterns, trends, and exceptions.',
                ],
                sDKeys: [
                    'Receive alerts when valuable assets such as vehicles or equipment enter or leave designated areas, helping to prevent theft or unauthorized use.',
                    'Ensure the safety of field workers by setting up geofences around hazardous areas or remote work sites, triggering alerts if employees enter restricted zones.',
                    'When a device enters or exits a geofenced area, the system triggers predefined alerts or notifications.',
                    'The system continuously monitors the location of tracked devices in real-time and checks whether they intersect with any defined geofences.',
                ],
            },
        ],
    },
    liveReporting: {
        featureHeader: 'Live Reporting',
        featureDescription:
            'Real-time tracking and analysis of various aspects of their work, such as attendance, tasks completed or project progress.',
        featureCaption:
            "Stay informed about employees' tasks, minutes of meeting, validate visits, and make decisions based on the data",
        keyPoints: [
            {
                header: 'Event Based Notification',
                paragraph:
                    'Admin or Manager can subscribe or opt to notifications they want to receive. This helps focus on the notifications they really want to receive.',
            },
            {
                header: 'Stay Notified about Attendance and Leave',
                paragraph:
                    'Stay a step ahead and know the headcount before so planning the day could be more productive and efficient.',
            },
            {
                header: 'Keep a Track for All Approvals',
                paragraph: 'Track all approvals from reimbursement to leave and view them later.',
            },
            {
                header: 'Stay Updated with Forms and MOM',
                paragraph:
                    'Custom forms can help in fetching specific data for a meeting which could be accessed remotely.',
            },
        ],
        softwareDetails: [
            {
                title: 'Locate field force in real time.',
                description: [
                    'Locate your field staff in real-time.',
                    'Get instant location details of your field workforce as they travel in real-time.',
                    'Treant enables you to locate and track your field employees using their mobile GPS both online and offline. Stay updated on your field staff’s every move and maintain a transparent work environment.',
                ],
            },
            {
                title: 'Stay up to date with your employee field information.',
                description: [
                    'Stay up to date with your employees’ field information.',
                    'Field staff’s on-field information will automatically be synced with your dashboard.',
                    'Your employees’ details like Battery percentage, GPS, internet on/off time visibility, ideal time that they spend on the field will be notified to you instantly in real-time.',
                ],
            },
            {
                title: 'Automate employees reimbursement and allowance data.',
                description: [
                    'AI powered location history for accurate data.',
                    'Location data of your employees will be powered by Artificial Intelligence to deliver accurate route and tracking information. Live tracking data and other location histories will be automated to your mailbox at regular intervals for a deeper dive into what’s happening on and off the field.',
                ],
            },
        ],
    },
    aIBasedOptimization: {
        featureHeader: 'AI Based Optimization',
        featureDescription:
            'Optimize and automate the planning of routes for field representatives or sales personnel based on AI beat route.',
        featureCaption:
            'Optimise the routes for the day and compare reports with your previous season with our AI based algorithm.',
        keyPoints: [
            {
                header: 'Real-Time Data Integration',
                paragraph:
                    'Real-time data sources, such as live traffic updates, weather conditions, and road closures, to dynamically adjust routes based on current circumstances helping in efficient daily planning.',
            },
            {
                header: 'User Interface for Route Planning',
                paragraph:
                    'View optimized routes, and make any necessary adjustments. This could be part of a mobile app or a web-based platform.',
            },
            {
                header: 'Productivity Metrics',
                paragraph: 'AI system to account for the unique characteristics of different beats or routes.',
            },
            {
                header: 'Mapping and Geospatial Data',
                paragraph:
                    'Utilize mapping services and geospatial data to understand the road network, traffic conditions, and other location-related information.',
            },
        ],
        softwareDetails: [
            {
                title: 'Route Optimization with Real-Time Updates',
                description: [
                    'Optimize and plan the most efficient routes for individuals or vehicles, such as delivery trucks, sales representatives, or field service technicians, who follow specific beats or routes as part of their regular activities.',
                    'This application can help in improving efficiency, reducing travel time, and minimizing operational costs.',
                ],
                sDKeys: [
                    'Collecting data from various sources, including GPS devices, traffic reports, weather updates, and vehicle sensors.',
                    'Faster response times and on-time arrivals enhance customer satisfaction, which is crucial for service-oriented businesses.',
                ],
            },
            {
                title: 'Necessitates Careful Consideration of Privacy Issues and Compliance',
                description: [
                    'Ensure that the AI-based system complies with local regulations, especially those related to privacy, data security, and transportation laws.',
                    'Regularly update and improve the AI algorithms based on new data and feedback, aiming for continuous optimization of beat routes.',
                ],
            },
            {
                title: 'Integration with Other Systems for Route Planning Efficiency',
                description: [
                    'Use machine learning algorithms to analyze historical data and predict future patterns.',
                    'This can help in anticipating traffic conditions, delivery times, and other factors that influence route planning.',
                ],
            },
        ],
    },
    leadManagement: {
        featureHeader: 'Lead Management',
        featureDescription:
            'Summarize employee attendance data, working hours, absences, late arrivals, and early departures within a specific timeframe.',
        featureCaption:
            'Generate automated reports for different branches and receive them daily, weekly, or monthly with over 40+ templates.',
        keyPoints: [
            {
                header: 'Automate Attendance Details: Log-In Time, Late Time, Total Working Hours, Total Sessions',
                paragraph:
                    'Provide an overall summary of attendance, including the total number of working days, total hours worked, and any overtime hours.',
            },
            {
                header: 'Multiple Branches Reports',
                paragraph: 'Remotely access multiple branch attendance reports with customizable categories.',
            },
            {
                header: 'Overtime and Leave Balances',
                paragraph:
                    'Display leave balances for each employee, including vacation days, sick leave, and any other types of leave accrued and utilized.',
            },
            {
                header: 'Customized Attendance Report',
                paragraph:
                    'Allow users to customize reports based on specific criteria, such as department, team, or individual employee.',
            },
        ],
        softwareDetails: [
            {
                title: 'Generate reports based on your organization structure.',
                description: [
                    'Regularly generated and analyzed attendance reports can provide valuable insights for improving workforce management, identifying trends, and ensuring compliance with organizational policies.',
                    'The specifics of the report will depend on the needs and structure of the organization.',
                ],
            },
            {
                title: 'Easily gain customizable reports.',
                description: [
                    'Identify and highlight any exceptions or irregularities in attendance, such as unauthorized absences, excessive lateness, or instances of unapproved overtime.',
                    'Use charts or graphs to visually represent attendance trends and patterns. This can make it easier to identify outliers and trends at a glance.',
                ],
            },
            {
                title: 'Reduce your time spent on manual reports.',
                description: [
                    'Includes fundamental details such as employee names, identification numbers, and department affiliations to provide context to the attendance data.',
                    'Clearly specifying the date and time range covered by the report. This helps users understand the temporal scope of the data.',
                    'Providing an overall summary of attendance, including the total number of working days, total hours worked, and any overtime hours.',
                ],
            },
        ],
    },
    taskManagement: {
        featureHeader: 'Task Management',
        featureDescription:
            "Systematic monitoring and management of employees' activities, productivity, and attendance.",
        featureParagraph:
            "No more timecards, excessive paperwork, and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format.",
        featureCaption:
            "Monitor and manage various aspects of an organization's workforce, including employee attendance, work hours, productivity, and performance.",
        keyPoints: [
            {
                header: 'Monitor in real time',
                paragraph:
                    'Monitor progress in real time by viewing task boards, checking task status, and tracking time spent.',
            },
            {
                header: 'Monitor all your workflows in one place for all organization',
                paragraph:
                    'Task management collaboration features to communicate with team members in real-time. Comment on tasks, ask questions, and provide updates to keep everyone informed.',
            },
            {
                header: 'Monitor performance',
                paragraph:
                    "Customer and Lead Management to track productivity. Employees' productivity and key performance indicators could be measured based on the amount of work completed by employees.",
            },
            {
                header: 'Standardize goal-setting',
                paragraph:
                    'Hierarchical structure for organizing goals: project goals, departmental objectives, individual performance goals, strategic initiatives, etc.',
            },
        ],
        softwareDetails: [
            {
                title: 'Implement time tracking systems to monitor work hours.',
                description: [
                    'Implementing time tracking solutions to monitor the time employees spend on tasks and projects.',
                    'This can help in understanding resource utilization and improving time management.',
                    'Utilize project management systems to track project progress, assign tasks, and monitor deadlines.',
                    'This helps in optimizing project workflows and ensuring timely completion.',
                ],
            },
            {
                title: 'Monitor relevant key performance indicators.',
                description: [
                    "Defining and tracking key performance indicators relevant to your organization's goals.",
                    'This may include sales targets, customer satisfaction metrics, or other performance indicators.',
                ],
            },
            {
                title: 'Generate reports of your workforce trends.',
                description: [
                    'Integrating analytics and reporting features to gain insights into workforce trends, performance, and areas for improvement.',
                    'Customized reports can aid decision-making.',
                ],
            },
        ],
    },
    salesPipelineMonitoring: {
        featureHeader: 'Sales Pipeline Monitoring',
        featureDescription:
            'Track and analyze key metrics of sales team on field such as sales revenue, customer interactions, and geographical trends.',
        featureParagraph:
            "No more timecards, excessive paperwork, and different documents to check employees' attendance. Automate the attendance monitoring process with WebWork Time Tracker and see the start and end of your employees' working hours in a well-structured format.",
        featureCaption:
            'Analyse the collection, analysis, and interpretation of data related to field sales activities to gain insights and make informed decisions',
        keyPoints: [
            {
                header: 'Automated Workflows',
                paragraph:
                    'Leverage system to streamline and optimize the various stages of the sales process such as lead capture, qualification, nurturing, pipeline management, activity tracking, and reporting.',
            },
            {
                header: 'Integration with CRM',
                paragraph:
                    'Streamline lead management, contact tracking, deal monitoring, and data synchronization between the two platforms.',
            },
            {
                header: 'Facilitate Collaboration and Communication',
                paragraph:
                    'Centralized communication channels, scheduling sales meetings, collaborative deal reviews, documenting processes, cross-functional collaboration, promoting open communication.',
            },
            {
                header: 'Insights into Future Sales Performance',
                paragraph: 'Leverage data analysis and predictive modeling to forecast potential outcomes and trends.',
            },
        ],
        softwareDetails: [
            {
                title: 'Everyday Field Insights for a Better Sales Performance',
                description: [
                    'Data analytic oriented concurrent e-report generation. Reliable and user-friendly go-to report feature for your easy access and usage.',
                    'As a sales manager, you will have a clear look over various reports which would be sent to your mail address or you can also view it in your dashboard insight.',
                    'Some of the notable reports are monthly reports, branch location-wise reports, employee-specific reports, etc.',
                ],
            },
            {
                title: 'Overall Look on Workfield Attendance',
                description: [
                    'Overall look through on employee field attendance and field visits.',
                    'Have a comprehensive view on-field workforce attendance and meetings.',
                    'In the field attendance report, you will get data about field attendance marking time, location, late attendance time, working hours, etc.',
                    'Similarly, field visits check-in/out reports include data related to field visits such as login/out time, check-in/out time, location of meetings, distance traveled, meeting results notes, etc.',
                ],
            },
            {
                title: 'Data Analytic Oriented Report Generation',
                description: [
                    'Subscribe and download monthly e-reports.',
                    'You can subscribe and download the monthly e-reports whenever you want.',
                    'Available in PDF and Excel file format. A complete pile of reports lined up for you to have detailed information about an employee’s external actions outside the organization, which tends to be a difficult task manually.',
                ],
            },
        ],
    },
    workflowAutomation: {
        featureHeader: 'Workflow Automation',
        featureDescription:
            'Geo fence based attendance to ensure that employees can only clock in or out when they are within the predefined geographic boundaries.',
        featureCaption:
            'Location based attendance assures precision to monitor and record employee attendance based on their physical location.',
        keyPoints: [
            {
                header: 'Automated Task Assignment',
                paragraph:
                    'Workflow automation to automatically assign tasks to the most appropriate individuals, considering factors such as skill sets, availability, workload, and priority levels.',
            },
            {
                header: 'Lead Routing, Qualification and Scoring',
                paragraph:
                    'Lead routing, qualification, scoring for assigning incoming leads to appropriate sales representatives, assessing their quality, and prioritizing follow-up actions.',
            },
            {
                header: 'Integration with External Systems',
                paragraph:
                    'Facilitate smoother collaboration, enhances data accuracy, and ensures a cohesive workflow across various business functions.',
            },
            {
                header: 'Performance Tracking and Reporting',
                paragraph:
                    'Track metrics such as process completion times, error rates, resource utilization, and overall productivity.',
            },
        ],
        softwareDetails: [
            {
                title: 'Time and location of workforce.',
                description: [
                    'Know When and Where Your Employees Work',
                    'Instruct your employees to give permission on their mobile phones so that it can track time and location.',
                    'Once permission is granted, the tracker can start functioning with a tap.',
                ],
            },
            {
                title: 'Live Reporting Two',
                description: [
                    "Implementing a location-based employee attendance system can enhance accuracy and streamline attendance management. However, it's crucial to balance the benefits with employee privacy considerations and communicate transparently about the implementation and use of such systems.",
                ],
            },
            {
                title: 'Geo fence based alert',
                description: [
                    'Set up automated alerts for unusual attendance patterns or discrepancies. This helps in identifying potential issues, such as unauthorized check-ins or check-outs.',
                    'Implement reporting and analytics features to gain insights into attendance patterns, trends, and exceptions.',
                ],
            },
        ],
    },
    aIBasedAssistance: {
        featureHeader: 'AI Based Assistance',
        featureDescription:
            'Real-time tracking and analysis of various aspects of their work, such as attendance, tasks completed or project progress.',
        featureCaption:
            "Stay informed about employees' tasks, Minutes of Meeting, validate visits, and make decisions based on the data.",
        keyPoints: [
            {
                header: 'Event Based Notification',
                paragraph:
                    'Admin or Manager can subscribe or opt to notifications they want to receive. This would help focusing in the notification they really want to receive.',
            },
            {
                header: 'Stay Notified about Attendance and Leave',
                paragraph:
                    'Stay Notified about Attendance and Leave. Stay a step ahead and know the headcount before so planning the day could be more productive and efficient.',
            },
            {
                header: 'Keep a Track for All Approvals',
                paragraph:
                    'Keep a Track of all Approval. Track all approval from reimbursement to leave and view them later.',
            },
            {
                header: 'Stay Updated with Forms and MOM',
                paragraph:
                    'Remote Tracking through Forms and Minutes of Meeting. Custom forms can help in fetching specific data for a meeting which could be accessed remotely.',
            },
        ],
        softwareDetails: [
            {
                title: 'Locate field force in real time.',
                description: [
                    'Locate your field staff in real-time',
                    'Get instant location details of your field workforce as they travel in real-time.',
                    'Treant enables you to locate and track your field employees using their mobile GPS both online and offline. Stay updated on your field staff’s every move and maintain a transparent work environment.',
                ],
            },
            {
                title: 'Stay up to date with your employee field information',
                description: [
                    'Stay up to date with your employees’ field information',
                    'Field staff’s on-field information will automatically be synced with your dashboard.',
                    'Your employees’ details like Battery percentage, GPS, internet on/off time visibility, ideal time that they spend on the field will be notified to you instantly in real-time.',
                ],
            },
            {
                title: 'Automate employees reimbursement and allowance data.',
                description: [
                    'AI powered location history for accurate data',
                    'Location data of your employees will be powered by Artificial Intelligence to deliver accurate route and tracking information. Live tracking data and other location histories will be automated to your mailbox at regular intervals for a deeper dive into what’s happening on and off the field.',
                ],
            },
        ],
    },
    digitizationOfOrder: {
        featureHeader: 'Digitization of Order',
        featureDescription:
            'Real-time tracking and analysis of various aspects of their work, such as attendance, tasks completed or project progress.',
        featureCaption: 'Streamline order management end to end and reduce manual errors',
        keyPoints: [
            {
                header: 'Reporting and Analytics',
                paragraph:
                    'Generate detailed reports and analytics to monitor order processing performance and employee productivity.',
            },
            {
                header: 'Automated Order Entry',
                paragraph: 'Automatically capture and validate order details entered by employees or customers.',
            },
            {
                header: 'Real-Time Order Tracking and Status Updates',
                paragraph:
                    'Provide real-time updates on order status and shipment tracking to employees and customers.',
            },
            {
                header: 'Automated Workflow and Task Assignment',
                paragraph: 'Automate order approval processes and assign tasks to appropriate employees.',
            },
        ],
    },
    targetManagement: {
        featureHeader: 'Target Management',
        featureDescription:
            'Real-time tracking and analysis of various aspects of their work, such as attendance, tasks completed or project progress.',
        featureCaption:
            "Stay informed about employees' tasks, minutes of meeting, validate visits, and take decisions based upon the data.",
        keyPoints: [
            {
                header: 'Integrate with Field Tracking',
                paragraph:
                    'Achieve daily, weekly, and monthly targets and keep records of employee achievement with live field tracking.',
            },
            {
                header: 'Real Time Updates and Sync',
                paragraph:
                    'Real-time progress (number of targets achieved vs. remaining), check-in/check-out times, number of hours (duration) spent on each task, and location tracking.',
            },
            {
                header: 'Performance and Analytics',
                paragraph:
                    'Performance metrics (completion rate, time taken, customer satisfaction scores), comparison against targets (actual performance vs. target), historical data (past performance trends).',
            },
            {
                header: 'Automated Notifications and Alerts',
                paragraph:
                    'Send automated notifications to keep employees informed about target achievement alerts, performance reminders, and deadline warnings.',
            },
        ],
    },
    invoiceManagement: {
        featureHeader: 'Invoice Management',
        featureDescription:
            'Real-time tracking and analysis of various aspects of their work, such as attendance, tasks completed or project progress.',
        featureCaption:
            'Create, track real-time invoice status and processing with automated alerts and notifications.',
        keyPoints: [
            {
                header: 'Automated Notifications and Reminders',
                paragraph:
                    'Set up systems to track invoice status and send automated reminders and alerts for due and overdue payments.',
            },
            {
                header: 'Invoice Templates',
                paragraph:
                    'Standardized invoice templates that include all necessary details such as customer information, order details, tax, discounts, and payment terms.',
            },
            {
                header: 'Digital Signature and Proof of Delivery',
                paragraph: 'Collect digital signatures and OTP-based proof of delivery as part of the invoice process.',
            },
            {
                header: 'Sync Invoices and Real Time Update',
                paragraph:
                    'Automatic sync with central system (cloud-based storage) and real-time updates on invoice status.',
            },
        ],
    },
    distributorOrder: {
        featureHeader: 'Distributor Order',
        featureDescription:
            'Real-time tracking and analysis of various aspects of their work, such as attendance, tasks completed or project progress.',
        featureCaption:
            'Stay informed about employees tasks, Minutes of meeting, validate visits, and take decision based upon the data',
        keyPoints: [
            {
                header: 'Distribution Information',
                paragraph:
                    'Create and process orders with all relevant details for each distribution and assign tasks based on availability.',
            },
            {
                header: 'Real Time Inventory Integration',
                paragraph:
                    'Integrate with inventory management to ensure accurate stock allocation and update inventory levels in real-time.',
            },
            {
                header: 'Automate Notifications and Alerts',
                paragraph: 'Configure the system to send automated notifications at key stages of the order process.',
            },
            {
                header: 'Employee Dashboard',
                paragraph:
                    'Access dashboard where employees can check orders, track progress, and download necessary documents.',
            },
        ],
    },
    inventoryManagement: {
        featureHeader: 'Inventory Management',
        featureDescription:
            'Real-time tracking and analysis of various aspects of their work, such as attendance, tasks completed or project progress.',
        featureCaption: 'Right balance of stock to real time updates and tracking of inventory for accurate data',
        keyPoints: [
            {
                header: 'Integration with Order Management',
                paragraph:
                    'Ensure seamless integration between inventory and order management systems to synchronize stock levels with incoming and outgoing orders.',
            },
            {
                header: 'Multi-Location Management',
                paragraph:
                    'Tracks inventory across multiple warehouses, stores, or locations and access centralized control, optimizes stock distribution and improves visibility.',
            },
        ],
    },
    orderCollectionManagement: {
        featureHeader: 'Order/Collection Management',
        featureDescription: 'Simplify orders/collection operations with a scalable and automated solution.',
        featureCaption: 'Simplify orders/collection operations with a scalable and automated solution.',
        keyPoints: [
            {
                header: 'Unified Order Management System',
                paragraph:
                    'A centralized system accessible by both on-site and off-site employees to manage orders, track progress, and update statuses in real-time.',
            },
            {
                header: 'Role-Based Access Control',
                paragraph:
                    'Different levels of access and permissions based on roles (e.g., managers, on-site employees, off-site employees) to ensure data security and appropriate access.',
            },
            {
                header: 'Real-Time Synchronization',
                paragraph:
                    'Ensure that all order data is synchronized in real-time across all devices and locations, so everyone has access to the latest information.',
            },
            {
                header: 'Communication and Coordination',
                paragraph:
                    'Facilitate communication between on-site and off-site employees through in-app messaging and notifications.',
            },
        ],
    },
};

export default features;
