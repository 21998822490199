const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'Des plans flexibles qui évoluent avec vous',
    subFlexiblePlansThatGrowsWithYou: `Choisissez le plan tarifaire qui répond aux besoins de votre entreprise. Traceforce propose des forfaits à des prix optimaux tout en offrant une valeur supérieure.`,
    pricingPlans: 'Plans tarifaires',
    pricingThatWorksForEveryOne: 'Tarification adaptée à tous',
    monthly: 'Mensuel',
    annualy: 'Annuel',
    international: 'International',
    domestic: 'National',
    month: 'Mois',
    benifits: 'Avantages',
    allPlanIncludeTheseBenifits: 'Tous les plans incluent ces avantages',
    someBenfitsOfMonthlyYearlySubscription: 'Certains avantages de l’abonnement mensuel/annuel',
    benifitsArr: [
        {
            title: 'Assistance technique',
            description: 'Notre équipe d’assistance technique professionnelle vous aidera à chaque étape',
        },
        {
            title: 'Technologie',
            description: 'Formation spéciale pour démarrer avec la plateforme par des professionnels',
        },
        {
            title: 'Analyse de croissance',
            description: 'Une équipe dédiée pour fournir des insights sur votre croissance mensuelle',
        },
        {
            title: 'Récompense',
            description: 'Une récompense spéciale pour le meilleur compte de performance chaque mois',
        },
    ],
    fAQs: 'Questions fréquentes',
    frequentlyAskedQuestions: 'Questions fréquemment posées',
    someBaTypeOfQuestions: 'Voici quelques types de questions courantes de nos clients',
    fAQArr: [
        {
            question: 'Comment puis-je m’inscrire pour ma société ?',
            ans: 'Oui, la licence de marché vous permet d’utiliser ce modèle dans n’importe quel produit final. Pour plus d’informations sur la licence, veuillez consulter les conditions de licence sur le marché.',
        },
        {
            question: 'Où puis-je ajouter mon véhicule dans la main-d’œuvre ?',
            ans: 'Non, il s’agit d’un modèle HTML. Il ne fonctionnera pas directement avec WordPress, mais vous pouvez le convertir en un thème compatible avec WordPress.',
        },
        {
            question: 'Comment mon personnel se connecte-t-il à Traceforce ?',
            ans: 'Envoyez vos problèmes ou commentaires à notre e-mail de support dédié (support@coderthemes.com). Nous sommes disponibles à tout moment pour vous aider.',
        },
        {
            question: 'Puis-je suivre mon personnel via un mobile ?',
            ans: 'Oui, nous mettrons régulièrement à jour les invites. Toutes les futures mises à jour seront disponibles gratuitement.',
        },
        {
            question: 'Comment marquer la présence ?',
            ans: 'Oui, la licence de marché vous permet d’utiliser ce modèle dans n’importe quel produit final. Pour plus d’informations sur la licence, veuillez consulter les conditions de licence sur le marché.',
        },
        {
            question: 'Comment désactiver les options pour développeurs ?',
            ans: 'Non, il s’agit d’un modèle HTML. Il ne fonctionnera pas directement avec WordPress, mais vous pouvez le convertir en un thème compatible avec WordPress.',
        },
        {
            question: 'Quelles fonctionnalités Traceforce propose-t-il ?',
            ans: 'Envoyez vos problèmes ou commentaires à notre e-mail de support dédié (support@coderthemes.com). Nous sommes disponibles à tout moment pour vous aider.',
        },
        {
            question: 'Ai-je besoin d’installer le logiciel Traceforce ?',
            ans: 'Oui, nous mettrons régulièrement à jour les invites. Toutes les futures mises à jour seront disponibles gratuitement.',
        },
        {
            question: 'Quel est le processus de mise en œuvre du logiciel Traceforce ?',
            ans: 'Oui, la licence de marché vous permet d’utiliser ce modèle dans n’importe quel produit final. Pour plus d’informations sur la licence, veuillez consulter les conditions de licence sur le marché.',
        },
        {
            question: 'Quels sont les options de formation et de support disponibles avec le logiciel Traceforce ?',
            ans: 'Non, il s’agit d’un modèle HTML. Il ne fonctionnera pas directement avec WordPress, mais vous pouvez le convertir en un thème compatible avec WordPress.',
        },
        {
            question: 'Le logiciel Traceforce peut-il être personnalisé selon nos processus RH spécifiques ?',
            ans: 'Envoyez vos problèmes ou commentaires à notre e-mail de support dédié (support@coderthemes.com). Nous sommes disponibles à tout moment pour vous aider.',
        },
        {
            question: 'Comment le logiciel HRMS peut-il aider à la conformité et aux rapports ?',
            ans: 'Oui, nous mettrons régulièrement à jour les invites. Toutes les futures mises à jour seront disponibles gratuitement.',
        },
        {
            question: 'Le logiciel HRMS prend-il en charge le travail à distance et l’accès mobile ?',
            ans: 'Oui, nous mettrons régulièrement à jour les invites. Toutes les futures mises à jour seront disponibles gratuitement.',
        },
    ],
};

export default pricingPage;
