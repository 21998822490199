import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import products from 'data/optimizedProducts.json';
import { addDoc, collection, deleteDoc, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { jobsAtTraceforce } from 'components/sliders/data';

const jobApplications = collection(firestore, 'applyjob');

const jobs = collection(firestore, 'traceForceJobs');

export const getAllJobs = createAsyncThunk('getAllJobs', async () => {
    return new Promise((resolve, reject) => {
        const fetchedJobs = [];

        const unsubscribe = onSnapshot(
            jobs,
            (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    fetchedJobs.push({ ...doc.data(), id: doc.id });
                });

                // Unsubscribe to stop listening for changes
                unsubscribe();

                resolve(fetchedJobs);
            },
            (error) => {
                // Unsubscribe in case of an error
                unsubscribe();
                reject(error);
            }
        );
    });
});

export const deleteJob = createAsyncThunk('deleteJob', async (jobId) => {
    const jobDoc = doc(firestore, 'traceForceJobs', jobId);

    try {
        await deleteDoc(jobDoc);
        return jobId; // Return the deleted jobId on successful deletion
    } catch (error) {
        throw error; // Throw the error to be handled in the rejection case
    }
});

export const deleteApplication = createAsyncThunk('deleteApplication', async (jobId) => {
    const jobDoc = doc(firestore, 'applyjob', jobId);

    try {
        await deleteDoc(jobDoc);
        return jobId; // Return the deleted jobId on successful deletion
    } catch (error) {
        throw error; // Throw the error to be handled in the rejection case
    }
});

export const getAllApplications = createAsyncThunk('getAllApplications', async () => {
    return new Promise((resolve, reject) => {
        const fetchedJobs = [];

        const unsubscribe = onSnapshot(
            jobApplications,
            (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    fetchedJobs.push({ ...doc.data(), id: doc.id });
                });

                // Sorting logic: First those with 'createdAt', then the rest
                const sortedJobs = fetchedJobs.sort((a, b) => {
                    // Check if 'createdAt' exists
                    const aHasDate = a.createdAt !== undefined;
                    const bHasDate = b.createdAt !== undefined;

                    // Sort logic
                    if (aHasDate && !bHasDate) return -1; // a comes first
                    if (!aHasDate && bHasDate) return 1; // b comes first
                    return 0; // maintain current order if both have or don't have 'createdAt'
                });

                // Unsubscribe to stop listening for changes
                unsubscribe();
                resolve(sortedJobs);
            },
            (error) => {
                // Unsubscribe in case of an error
                unsubscribe();
                reject(error);
            }
        );
    });
});

export const updateJob = createAsyncThunk('updateJob', async ({ jobId, newJobData }) => {
    const jobDoc = doc(firestore, 'traceForceJobs', jobId);

    try {
        await updateDoc(jobDoc, newJobData);
        return { jobId, newJobData }; // Return the updated jobId and new data on successful update
    } catch (error) {
        throw error; // Throw the error to be handled in the rejection case
    }
});

const uiSlice = createSlice({
    name: 'uiSlice',
    allJobs: [],
    allApplications: [],
    translatedFeature: '',
    openAddJobForm: false,
    selectedJob: null,
    initialState: {
        product: 'attendanceManagement',
        feature: products.attendanceManagement.features.attendanceMonitoring,
    },

    reducers: {
        setProduct(state, action) {
            if (action.payload === 'Attendance Management') {
                state.product = 'attendanceManagement';
            }
            if (action.payload === 'Field Force Management') {
                state.product = 'fieldTrackingManagement';
            }
            if (action.payload === 'Payroll And Leave Management') {
                state.product = 'payrollAndLeaveManagement';
            }
            if (action.payload === 'Order Management') {
                state.product = 'orderManagement';
            }
        },
        setFeature(state, action) {
            state.feature = action.payload;
        },
        setSelectedJob(state, action) {
            state.selectedJob = action.payload;
        },
        openAddJobForm(state, action) {
            state.openAddJobForm = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getAllApplications.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getAllApplications.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.allApplications = action.payload;
            })
            .addCase(getAllApplications.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(getAllJobs.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getAllJobs.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.allJobs = action.payload;
                console.log('actionPayloadall Jobs', action.payload);
            })
            .addCase(getAllJobs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(deleteJob.pending, (state) => {
                // Handle the pending state if needed
            })
            .addCase(deleteJob.fulfilled, (state, action) => {
                // Remove the deleted job from your state, if applicable
                state.status = 'succeeded';
                state.deletedJobId = action.payload;
            })
            .addCase(deleteJob.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { setProduct, setFeature, setSelectedJob, openAddJobForm } = uiSlice.actions;
export default uiSlice.reducer;

function addDummyData() {
    const promises = jobsAtTraceforce.map(async (job) => {
        try {
            const docRef = await addDoc(jobs, job);
            return docRef;
        } catch (error) {
            console.error('Error adding document in firebase: ', error);
            throw error; // Rethrow the error to stop Promise.all if there's an error
        }
    });

    // Wait for all promises to resolve
    return Promise.all(promises);
}

function addingdummy() {
    addDummyData()
        .then(() => {})
        .catch((error) => {
            console.error('Error adding documents: ', error);
        });
}
// addingdummy();
