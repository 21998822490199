const pricing = {
    attendance: {
        name: 'অ্যাটেনডেন্স',
        price: '৪০',
        subLine: 'কার্যঘণ্টা মনিটরের জন্য সেরা',
        features: [
            'বায়োমেট্রিক ভিত্তিক ক্লক ইন',
            'জিও ভিত্তিক ক্লক ইন',
            'অর্ধদিবসের বিকল্প',
            'অটো লগআউট',
            'লেট কাউন্টার',
            'গড় ক্লক ইন সময়',
            'মোট কার্যঘণ্টা',
            '১টি রিপোর্টের জন্য স্বয়ংক্রিয়ভাবে সাবস্ক্রাইব',
            'অ্যাটেনডেন্স, গড়, চেকইন/চেক আউট',
            'রিপোর্ট',
            'সব মৌলিক বৈশিষ্ট্যের অন্তর্ভুক্ত',
            'বায়োমেট্রিক',
            'ফেস এআই',
            'কিওস্ক',
            'বহু শাখার বিকল্প',
            'ক্লক ইন এবং ক্লক আউটের জন্য ফর্ম',
            'সব মৌলিক এবং প্রিমিয়াম বৈশিষ্ট্যগুলির অন্তর্ভুক্ত',
        ],
    },
    task: {
        name: 'টাস্ক',
        price: '১০০',
        subLine: 'টাস্ক পরিচালনার জন্য সেরা',
        features: [
            'ফিল্ড টাস্ক',
            'সার্ভিস টাস্ক',
            'জিওফেন্স ভিত্তিক টাস্ক ম্যানেজমেন্ট',
            'চেক ইন চেক আউট',
            'ছাড়ার সময়',
            'প্রিয়র নোটিফিকেশন',
        ],
    },
    tracking: {
        name: 'ট্র্যাকিং',
        price: '৩০০',
        subLine: 'রিয়েল-টাইম ট্র্যাকিংয়ের জন্য সেরা',
        features: [
            'ফিল্ড টাস্ক',
            'সার্ভিস টাস্ক',
            'জিওফেন্স ভিত্তিক টাস্ক ম্যানেজমেন্ট',
            'চেক ইন চেক আউট',
            'ছাড়ার সময়',
            'লাইভ ট্র্যাকিং',
        ],
    },
    customer: {
        name: 'কাস্টমার',
        price: '৮০',
        subLine: 'কাস্টমার সম্পর্ক ব্যবস্থাপনা',
        features: [
            'হোয়াটসঅ্যাপ, ইমেইল, এবং মেসেজ ব্যবহার করে যোগাযোগ করুন',
            'ট্যাগ অ্যাসাইন করুন',
            'ইন্টারভ্যাল ভিত্তিক রিমাইন্ডার',
            'জিও ফেন্স ভিত্তিক ভিজিট অ্যাসাইন করুন',
            'ভিজিটের ইতিহাস',
            'ব্যবসায়িক কার্ড শেয়ার করুন',
            'ঘোষণা',
            'সব মৌলিক বৈশিষ্ট্যের অন্তর্ভুক্ত',
            'এআই ভিত্তিক বিট রুট',
            'ব্যবসায়িক কার্ড স্ক্যানার',
            'যোগাযোগের জন্য পূর্বনির্ধারিত টেমপ্লেট',
            'রিমাইন্ডার পরিচালনা করুন',
            'হোয়াটসঅ্যাপ, ইমেইল, এবং মেসেজ ব্যবহার করে যোগাযোগ করুন',
            'ট্যাগ অ্যাসাইন করুন',
            'ইন্টারভ্যাল ভিত্তিক রিমাইন্ডার',
            'জিও ফেন্স ভিত্তিক ভিজিট অ্যাসাইন করুন',
            'ভিজিটের ইতিহাস',
            'ব্যবসায়িক কার্ড শেয়ার করুন',
            'ঘোষণা',
        ],
    },
    reimbursement: {
        name: 'রেইম্বার্সমেন্ট',
        price: '৩০',
        subLine: 'সঠিক ও সময়মত পেমেন্ট অটোমেট করুন',
        features: [
            'যানবাহনের দূরত্ব ভিত্তিক রেইম্বার্সমেন্ট',
            'অ্যানালিটিক্স (সাবপয়েন্ট)',
            'শাখার ভিত্তিতে',
            'মাসিক ভিত্তিতে',
            'বিভাগ',
        ],
    },
    leave: {
        name: 'লিভ',
        price: '৩০',
        subLine: 'ম্যানেজারদের সহজে অনুমোদন করার জন্য',
        features: [
            'এগিয়ে নিয়ে যাওয়া ছুটি',
            'ছুটির ব্যবস্থাপনা',
            'আক্রিত ছুটি চেক করুন',
            'অর্ধদিবস এবং বাড়ি থেকে কাজ',
            'ছুটি অনুমোদন করুন',
            'প্রত্যেক ব্যক্তির জন্য কাস্টমাইজ পলিসি',
        ],
    },
    payroll: {
        name: 'পেরোল',
        price: '৪০',
        subLine: 'সঠিক বেতন গণনা',
        features: [
            'পেরোল পলিসি',
            'অ্যাডভান্স স্যালারি ক্যালকুলেশন',
            'পেমেন্ট সারাংশ',
            '১-ক্লিক গণনা',
            'ঘণ্টাভিত্তিক ও মাসিক বেতন গণনা',
            'ওভারটাইম গণনা',
        ],
    },
    faceID: {
        name: 'ফেস আইডি',
        price: '২৫',
        subLine: 'সুরক্ষা উন্নত করার জন্য সেরা',
        features: ['অ্যাটেনডেন্সে ফেস আইডি যোগ করুন (অ্যাড অন)'],
    },
    forms: {
        name: 'ফর্মস',
        price: '৩০',
        subLine: 'ডিজিটাল ফর্ম তৈরি ও পরিচালনা করুন',
        features: [
            'ডাইনামিক ফর্মস',
            'কাস্টম ফর্ম তৈরি',
            'ফর্মস ভিত্তিক অ্যানালিটিক্স',
            'ইভেন্টের জন্য ফর্মস',
            'ফর্মের মাধ্যমে পেমেন্ট',
            'ব্যক্তিগতকৃত ফর্ম তৈরি করুন',
        ],
    },
};
export default pricing;
