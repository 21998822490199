const features = {
    attendanceMonitoring: {
        featureSubcaption: 'Eficiencia en cada momento: Soluciones innovadoras para sistemas de gestión de empleados',
        featureHeader: 'Monitoreo de Asistencia',
        featureDescription:
            'Información en tiempo real sobre el registro de entrada/salida de la presencia o ausencia de individuos dentro del área del Geofence o sucursal',
        featureParagraph:
            'No más tarjetas de tiempo, papeleo excesivo y documentos diferentes para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y observa el inicio y fin de las horas laborales de tus empleados en un formato bien estructurado',
        featureCaption:
            'Gestiona la asistencia de múltiples sucursales para tus empleados de campo y empleados en el lugar',
        keyPoints: [
            {
                header: 'Asistencia en Tiempo Real',
                paragraph:
                    'Información en tiempo real sobre la presencia o ausencia de individuos dentro del área del Geofence o sucursal',
            },
            {
                header: 'Cálculo de Horas Laborales Totales',
                paragraph: 'Monitorea las horas laborales basadas en Diarias, Semanales y Mensuales',
            },
            {
                header: 'Informes de Asistencia Accesibles Remotamente',
                paragraph: 'Accede a los informes de asistencia de Múltiples Sucursales de forma remota',
            },
            {
                header: 'Gestión Centralizada de Asistencia',
                paragraph:
                    'Consolidación de datos, procesos y sistemas relacionados con la asistencia en una única plataforma o sistema centralizado',
            },
        ],
        softwareDetails: [
            {
                title: 'Obtén información sobre el seguimiento de asistencia de empleados',
                description: [
                    'Obtén Transparencia sobre la Puntualidad de los Empleados',
                    'Ahorra tiempo y organiza la asistencia de los empleados sin ninguna acción adicional.',
                    'Monitorea fácilmente si tus empleados han llegado al lugar de trabajo a tiempo y han completado sus horas laborales.',
                    'Mantén tus operaciones en el lugar de trabajo más organizadas y con un entorno laboral altamente responsable.',
                ],
                sDKeys: [
                    'Registra automáticamente la entrada y salida del personal en el lugar configurando el perímetro del geofence.',
                    'Recibe alertas de notificación para empleados que olvidan o no logran registrar la entrada o salida.',
                    'Ubica y rastrea los movimientos de tu personal de campo en tiempo real justo después de registrar su asistencia.',
                ],
            },
            {
                title: 'Obtén informes detallados de monitoreo de asistencia',
                description: [
                    'Análisis Inteligente de los Datos de Asistencia',
                    'Filtros de salida temprana, llegada tarde y menos seguimiento que puedes seleccionar desde la lista desplegable.',
                    'Esto te ayudará a generar instantáneamente la información preferida sin tener que buscarla manualmente.',
                    'Puedes rastrear la asistencia a diario o elegir un intervalo desde el calendario.',
                ],
                sDKeys: [
                    'Obtén resúmenes de asistencia para tu organización.',
                    'Genera y utiliza informes para ver tendencias virales dentro de tu equipo, departamentos o sucursales.',
                    'Recibe informes en intervalos regulares en tu correo (Hora de entrada, hora de salida, horas laborales totales, sesiones totales)',
                    'Datos perspicaces para una mejor toma de decisiones.',
                ],
            },
            {
                title: 'Explora otras características de tiempo y productividad.',
                description: [
                    'Forma segura y transparente de monitorear y rastrear la asistencia de los empleados en tiempo real con un sistema 100% sin contacto.',
                    'Configuración Fácil',
                    'Informes detallados de entradas y salidas automatizados',
                    'Capturas de pantalla con seguimiento del tiempo',
                    'Registro de Nivel de Actividad',
                    'Monitoreo de Uso de Aplicaciones y Sitios Web',
                ],
                sDKeys: [
                    'Se ha demostrado que la asistencia de los empleados ayuda a gestionar el tiempo y mejorar la productividad.',
                    'Elimina el papeleo y recopila registros precisos de asistencia de los empleados.',
                ],
            },
        ],
    },
    leaveManagement: {
        featureHeader: 'Gestión de Permisos',
        feaatureSubcaption:
            'Coordina las ausencias del personal mientras mantienes niveles de productividad y continuidad operativa.',
        featureDescription:
            'Gestiona y rastrea eficientemente las ausencias y presencias, asegurando la documentación adecuada, el cumplimiento de las políticas de la empresa y manteniendo una continuidad fluida del flujo de trabajo.',
        featureParagraph:
            'No más tarjetas de tiempo, papeleo excesivo y documentos diferentes para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y observa el inicio y fin de las horas laborales de tus empleados en un formato bien estructurado.',
        featureCaption:
            'Mantente informado sobre las próximas ausencias de tus empleados con anticipación y planifica tu día en consecuencia',
        keyPoints: [
            {
                header: 'Cálculo Automático de Acumulaciones y Saldos de Permisos',
                paragraph:
                    'Gestiona las acumulaciones de vacaciones, asegurando que los empleados no excedan las cantidades asignadas y previene saldos de permisos negativos.',
            },
            {
                header: 'Políticas de Permisos Flexibles, Flujos de Aprobación',
                paragraph:
                    'Configura políticas de permisos flexibles para la organización mediante la configuración del calendario de permisos. Habilita la configuración jerárquica paralela o secuencial para la aprobación o rechazo de solicitudes de permisos.',
            },
            {
                header: 'Cumplimiento de Leyes Laborales y Políticas de la Empresa',
                paragraph:
                    'Mantente informado sobre las leyes laborales y regulaciones relacionadas con los derechos de permisos. Asegúrate de que tus políticas de gestión de permisos cumplan con las políticas de la empresa.',
            },
            {
                header: 'Opciones de Autoservicio',
                paragraph:
                    'Proporciona a los empleados opciones de autoservicio, permitiéndoles consultar sus saldos de permisos, enviar solicitudes y ver el estado de sus solicitudes.',
            },
        ],
        softwareDetails: [
            {
                title: 'Políticas de permisos múltiples y flexibles para tu equipo',
                description: [
                    'Políticas de permisos flexibles para satisfacer tus necesidades exactas con parámetros configurables',
                    'Esquemas y políticas de permisos múltiples adaptados para diferentes grupos de empleados',
                    'Tipos de permisos ilimitados y transacciones de permisos soportadas',
                ],
                sDKeys: [
                    'Estas políticas incluyen tipos de permisos como permisos anuales, permisos por enfermedad, permisos parentales, permisos por luto, etc.',
                    'Establece criterios de elegibilidad para las políticas',
                    'Tasas de acumulación, reglas de uso, documentación requerida',
                ],
            },
            {
                title: 'Autoservicio para solicitud de permisos y flujo de aprobación',
                description: [
                    'La automatización reduce el esfuerzo de los empleados. Los empleados solicitan permisos a través del portal web o la aplicación móvil',
                    'Rastreador de permisos para saldos y transacciones de permisos',
                    'Vista del gerente de datos de permisos del equipo y patrones de permisos para mejores ideas',
                    'Los flujos de trabajo con escalaciones conducen a un sistema receptivo y una excelente experiencia para el empleado',
                    'Notificaciones y alertas por correo electrónico',
                ],
                sDKeys: [
                    'Los empleados solicitan permisos a través del portal web o la aplicación móvil',
                    'Rastreador de permisos para saldos y transacciones de permisos',
                    'Vista del gerente de datos de permisos del equipo y patrones de permisos para mejores ideas',
                    'Los flujos de trabajo con escalaciones conducen a un sistema receptivo y una excelente experiencia para el empleado',
                    'Notificaciones y alertas por correo electrónico',
                ],
            },
            {
                title: 'Cumple con las políticas de la empresa y la ley laboral',
                description: [
                    'Una gestión efectiva de permisos de empleados no solo asegura el cumplimiento de las leyes laborales, sino que también contribuye a una cultura laboral positiva. Al implementar políticas de permisos eficientes y utilizar tecnología, las organizaciones pueden agilizar el proceso y apoyar la necesidad de tiempo libre de sus empleados.',
                ],
                sDKeys: [
                    'Gestiona las responsabilidades de permisos para el presupuesto de manera efectiva y minimiza los riesgos financieros',
                    'Genera datos estadísticos para informes individuales para rastrear el rendimiento mensual, trimestral o anual',
                    'Mantente informado sobre la planificación de la fuerza laboral, la asignación de recursos y las estrategias de compromiso de los empleados',
                    'Registros e informes detallados según las reglas gubernamentales',
                    'Lista de vacaciones personalizada y completa para una fuerza laboral diversa',
                ],
            },
        ],
    },
    shiftManagement: {
        featureHeader: 'Gestión de Turnos',
        feaatureSubcaption:
            'Programa la fuerza laboral con actividades de ventas, asegura un nivel óptimo de personal para satisfacer la demanda de los clientes y maximiza la productividad',
        featureDescription:
            'Organiza y programa las horas de trabajo para optimizar la productividad, satisfacer las necesidades operativas y asegurar cobertura a lo largo de diferentes períodos de tiempo',
        featureParagraph:
            'No más tarjetas de tiempo, papeleo excesivo y documentos diferentes para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y observa el inicio y fin de las horas laborales de tus empleados en un formato bien estructurado.',
        featureCaption:
            'Rastrea los turnos de tus empleados basándote en las horas laborales totales y múltiples sesiones agregadas',
        keyPoints: [
            {
                header: 'Gestiona los Horarios de Trabajo de los Empleados',
                paragraph:
                    'Desarrolla un horario de turnos claro y bien organizado. Define diferentes tipos de turnos según la naturaleza de tu negocio.',
            },
            {
                header: 'Definición de Turnos, Asignación de Roles, Establecimiento de Horarios Recurridos o Personalizados',
                paragraph:
                    'Analiza la carga de trabajo y las tareas para determinar el número de empleados necesarios durante diferentes turnos.',
            },
            {
                header: 'Herramientas de Comunicación Integradas',
                paragraph:
                    'Plataforma centralizada para que los empleados accedan a sus horarios y establezcan un canal de comunicación confiable para cualquier actualización o cambio.',
            },
            {
                header: 'Gestión Centralizada de Turnos con Horarios en Múltiples Ubicaciones',
                paragraph:
                    'Supervisión y control centralizados de los horarios de los empleados, permitiendo a los gerentes asignar turnos de manera eficiente, rastrear la asistencia y gestionar los recursos de la fuerza laboral en múltiples ubicaciones desde una sola plataforma.',
            },
        ],
        softwareDetails: [
            {
                title: 'Automatiza el proceso de programación basado en ubicación y turno',
                description: [
                    'Analiza la carga de trabajo y las tareas para determinar el número de empleados necesarios durante diferentes turnos',
                    'Considera las horas pico del negocio, las variaciones estacionales y cualquier requisito específico para cada turno. Esto puede incluir turnos diurnos, nocturnos, rotativos, partidos o horarios flexibles.',
                ],
                sDKeys: [
                    'Intercambio de turnos entre empleados',
                    'Los empleados pueden intercambiar o cambiar sus turnos con otros empleados con la opción disponible',
                    'Los empleados pueden elegir sus turnos y tiempos libres que les convengan mejor',
                ],
            },
            {
                title: 'Tablero de Turnos para la Programación Dinámica de Turnos',
                description: [
                    'Asegura el cumplimiento de las leyes laborales relacionadas con los descansos y períodos de reposo',
                    'Incorpora descansos en el horario de turnos para prevenir la fatiga de los empleados y mantener la productividad',
                    'Asegúrate de que tus políticas de gestión de turnos estén alineadas con los requisitos legales',
                ],
                sDKeys: [
                    'Construye horarios de trabajo eficientes para tu equipo utilizando herramientas de programación fáciles de usar',
                    'Adapta los turnos a las demandas operativas de tu negocio y la disponibilidad de los empleados',
                    'Asegura una distribución justa de los turnos mientras consideras las preferencias de los empleados y la carga de trabajo',
                ],
            },
            {
                title: 'Incentiva a los empleados con asignaciones de turno por trabajar en horas inusuales',
                description: [
                    'Integra los sistemas de gestión de turnos con los procesos de nómina para asegurar una compensación y deducciones precisas basadas en los turnos trabajados',
                    'Define procedimientos para horas extra y asegura que las políticas de horas extra cumplan con las regulaciones laborales',
                ],
                sDKeys: [
                    'Aprueba las solicitudes de turnos de los empleados con un solo clic',
                    'Los empleados pueden elegir su propio turno al inicio de una semana/mes y enviar solicitudes desde su portal según su conveniencia',
                    'Solución rentable al pagar asignaciones de turno basadas en la tarifa por hora o la política de la empresa',
                ],
            },
        ],
    },
    geoFenceBasedManagement: {
        featureHeader: 'Asistencia Basada en Geocercas',
        feaatureSubcaption:
            'Maximiza la precisión y la responsabilidad, rastrea la presencia de los empleados con límites designados',
        featureDescription:
            'Tecnología basada en la ubicación para monitorear y verificar la presencia de los empleados dentro de límites geográficos predefinidos',
        featureCaption:
            'La asistencia basada en geocercas aprovecha la precisión de tu seguimiento de asistencia, ya que los empleados se marcan como presentes solo cuando están en el área designada',
        keyPoints: [
            {
                header: 'Localiza Precisamente a los Empleados Dentro del Área de Geocerca Designada',
                paragraph:
                    'Registros de asistencia precisos para asegurar que los empleados estén presentes en sus ubicaciones de trabajo designadas.',
            },
            {
                header: 'Monitoreo en Tiempo Real de las Ubicaciones de los Empleados',
                paragraph:
                    'Implementa el seguimiento en tiempo real para monitorear las ubicaciones de los empleados dentro de la geocerca.',
            },
            {
                header: 'Define Límites Geográficos Específicos para Marcar Entrada o Salida',
                paragraph:
                    'Establece límites geográficos específicos, conocidos como geocercas, alrededor del lugar de trabajo o ubicaciones de asistencia designadas.',
            },
            {
                header: 'Alertas de Geocerca',
                paragraph:
                    'Configura alertas o notificaciones para informar a los gerentes o al personal de RRHH cuando los empleados entren o salgan de los límites definidos de la geocerca.',
            },
        ],
        softwareDetails: [
            {
                title: 'Restricción de Ubicación en la Marcación de Asistencia',
                description: [
                    'Establecimiento de límites geográficos específicos utilizando coordenadas GPS o tecnologías basadas en la ubicación. Estos límites representan las áreas donde se espera que los empleados estén presentes para trabajar.',
                ],
                sDKeys: [
                    'Función opcional para restringir a los usuarios permitiendo asistencia desde una dirección IP específica para asegurar la asistencia desde la cercanía de la oficina.',
                    'Facilidad de comunicación con usuarios específicos o todos usando la función de Notificaciones Push.',
                    'Vincula tareas con los empleados de asistencia para ver tareas diarias y horarios.',
                ],
            },
            {
                title: 'Establece Restricciones de Asistencia y Autenticación Biométrica',
                description: [
                    'Implementación de seguimiento de ubicación en tiempo real para monitorear las ubicaciones de los empleados dentro de las áreas de geocerca. Esta información se utiliza para confirmar la asistencia y asegurar que los empleados estén dentro de las ubicaciones de trabajo designadas durante las horas programadas.',
                ],
                sDKeys: [
                    'Los empleados solo pueden marcar su asistencia una vez que lleguen a su primera ubicación de tarea.',
                    'Los empleados pueden marcar su asistencia de forma remota; no más asistencia incorrecta o viaje diario a la oficina solo para marcar su presencia.',
                    'Los gerentes pueden rastrear la ubicación en tiempo real y recibir actualizaciones de forma remota a través de aplicaciones móviles o web.',
                ],
            },
            {
                title: 'Mejora la Seguridad y la Autorización',
                description: [
                    'Implementa medidas de autenticación seguras para prevenir entradas fraudulentas de asistencia mediante el uso de códigos PIN, autenticación biométrica y reconocimiento facial.',
                    'Configura notificaciones móviles o alertas para los empleados, recordándoles que marquen su entrada cuando ingresen al área de geocerca. Esto asegura que los empleados estén al tanto del sistema de seguimiento de asistencia.',
                ],
            },
        ],
    },

    multipleClockInOptions: {
        featureHeader: 'Opciones Múltiples de Marcación de Entrada',
        feaatureSubcaption:
            'Brindando flexibilidad y conveniencia, múltiples opciones de marcación de entrada permiten a los empleados gestionar su tiempo de manera eficiente.',
        featureDescription:
            'Ofrece flexibilidad a los empleados para registrar sus horas de trabajo utilizando escáneres biométricos, aplicaciones móviles, relojes de tiempo y plataformas basadas en la web.',
        featureCaption:
            'Facilita el uso para empleados que trabajan de forma remota y pueden registrar múltiples entradas con diferentes sesiones.',
        keyPoints: [
            {
                header: 'Adecuado para Organizaciones con Múltiples Ubicaciones',
                paragraph:
                    'Opciones flexibles de marcación para la gestión de asistencia en el campo y fuera del campo de diferentes sucursales.',
            },
            {
                header: 'Portal de Autoservicio para Empleados',
                paragraph:
                    'Proporciona a los empleados acceso a un portal de autoservicio para registros de asistencia y gestionar sus entradas y salidas dentro de la geocerca.',
            },
            {
                header: 'Múltiples Opciones para Marcar Entrada',
                paragraph:
                    'Las opciones flexibles de marcación incluyen escaneos biométricos, aplicaciones móviles, tarjetas RFID y reconocimiento facial, asegurando un seguimiento del tiempo sin problemas en diversos entornos de trabajo.',
            },
            {
                header: 'Marcación de Entrada Basada en Geolocalización',
                paragraph:
                    'Marcación automática de asistencia para empleados dentro del rango de las instalaciones de la oficina con notificación de alerta.',
            },
        ],
        softwareDetails: [
            {
                title: 'Marcaciones en el Sitio y Fuera del Sitio',
                description: [
                    'Atendiendo a diversos entornos laborales, nuestro sistema de gestión de asistencia de empleados ofrece múltiples opciones de marcación de entrada.',
                    'Ya sea que los empleados estén en el sitio, en el campo o trabajando de forma remota, pueden registrar su tiempo convenientemente a través de escaneos biométricos, reconocimiento facial y marcación automática basada en geocercas.',
                ],
            },
            {
                title: 'Portal de Autoservicio para que los Empleados Consulten sus Registros',
                description: [
                    'El portal de autoservicio para empleados proporciona control sobre múltiples entradas y salidas.',
                    'Con características amigables para el usuario, los empleados pueden gestionar fácilmente sus horas de trabajo, ya sea que estén cambiando entre turnos, ubicaciones o tareas.',
                    'Esto otorga flexibilidad al personal mientras mantiene registros de tiempo precisos, mejora la eficiencia y agiliza los procesos administrativos.',
                ],
            },
            {
                title: 'Múltiples Entradas para Múltiples Sucursales',
                description: [
                    'Acomoda las necesidades dinámicas de tu empresa que abarca múltiples ubicaciones y asegura un seguimiento preciso de la asistencia y eficiencia operativa.',
                ],
            },
        ],
    },
    attendanceReport: {
        featureHeader: 'Informes de Asistencia',
        feaatureSubcaption:
            'Una reflexión perspicaz de los compromisos y la puntualidad del equipo, capturada en un solo informe.',
        featureDescription:
            'Resume los datos de asistencia de los empleados, horas de trabajo, ausencias, llegadas tarde y salidas anticipadas dentro de un marco de tiempo específico.',
        featureCaption:
            'Genera informes automáticos para diferentes sucursales y recíbelos diariamente, semanalmente o mensualmente con más de 40 plantillas.',
        keyPoints: [
            {
                header: 'Automatiza los Detalles de Asistencia: Hora de Entrada, Hora de Llegada Tarde, Total de Horas Trabajadas, Total de Sesiones',
                paragraph:
                    'Proporciona un resumen general de la asistencia, incluyendo el número total de días laborables, horas trabajadas totales y cualquier hora extra.',
            },
            {
                header: 'Informes de Múltiples Sucursales',
                paragraph:
                    'Accede remotamente a informes de asistencia de múltiples sucursales con categorías personalizables.',
            },
            {
                header: 'Horas Extras y Saldos de Licencia',
                paragraph:
                    'Muestra los saldos de licencia para cada empleado, incluyendo días de vacaciones, licencias por enfermedad y otros tipos de licencia acumulados y utilizados.',
            },
            {
                header: 'Informe de Asistencia Personalizado',
                paragraph:
                    'Permite a los usuarios personalizar informes basados en criterios específicos, como departamento, equipo o empleado individual.',
            },
        ],
        softwareDetails: [
            {
                title: 'Genera informes basados en la estructura de tu organización.',
                description: [
                    'Los informes de asistencia generados y analizados regularmente pueden proporcionar información valiosa para mejorar la gestión de la fuerza laboral, identificar tendencias y asegurar el cumplimiento de las políticas organizacionales.',
                    'Los detalles del informe dependerán de las necesidades y la estructura de la organización.',
                ],
                sDKeys: [
                    'La información recopilada de varios usuarios se compila para ofrecer análisis automatizados, reduciendo el tiempo y los recursos gastados en la generación manual de informes.',
                    'Los usuarios pueden seleccionar sus análisis requeridos con unos pocos clics y suscribirse a ellos en cualquier momento.',
                    'Se puede descargar un informe de análisis de muestra y verlo como una representación real del informe deseado.',
                ],
            },
            {
                title: 'Obtén informes personalizables fácilmente.',
                description: [
                    'Identifica y resalta cualquier excepción o irregularidad en la asistencia, como ausencias no autorizadas, llegadas tardías excesivas o instancias de horas extras no aprobadas.',
                    'Utiliza gráficos o tablas para representar visualmente las tendencias y patrones de asistencia. Esto puede facilitar la identificación de anomalías y tendencias de un vistazo.',
                ],
                sDKeys: [
                    'El usuario puede beneficiarse de los datos generados de diversas maneras, como procesamiento de nómina, evaluaciones de desempeño y evaluación, reembolsos, etc.',
                    'Los informes se pueden compartir con otros usuarios según el flujo jerárquico de autoridad.',
                    'La empresa puede estar colectivamente al tanto del estado de trabajo y del desempeño de su equipo de la manera más fácil sin intervenciones manuales.',
                ],
            },
            {
                title: 'Reduce el tiempo dedicado a informes manuales.',
                description: [
                    'Incluye detalles fundamentales como nombres de empleados, números de identificación y afiliaciones departamentales para proporcionar contexto a los datos de asistencia.',
                    'Especifica claramente el rango de fechas y horas cubierto por el informe. Esto ayuda a los usuarios a entender el alcance temporal de los datos.',
                    'Proporciona un resumen general de la asistencia, incluyendo el número total de días laborables, horas trabajadas totales y cualquier hora extra.',
                ],
            },
        ],
    },
    workforceTracking: {
        featureHeader: 'Seguimiento de la Fuerza Laboral',
        featureDescription:
            'Monitoreo y gestión sistemática de las actividades, productividad y asistencia de los empleados.',
        featureParagraph:
            'No más tarjetas de tiempo, exceso de papeleo y diferentes documentos para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y visualiza el inicio y fin de las horas de trabajo de tus empleados en un formato bien estructurado.',
        featureCaption:
            'Monitorea y gestiona varios aspectos de la fuerza laboral de una organización, incluyendo la asistencia de los empleados, horas de trabajo, productividad y rendimiento.',
        keyPoints: [
            {
                header: 'Seguimiento Basado en Línea de Tiempo de Asistencia y Movimiento',
                paragraph:
                    'Monitorea eventos como la entrada y salida, así como las percepciones de los empleados sobre sus operaciones, métricas de rendimiento o eventos.',
            },
            {
                header: 'Formularios Personalizados para Gestión de Datos',
                paragraph:
                    'Para actas de reuniones, almacena datos de reuniones y tareas para rastrear tendencias y tomar decisiones basadas en datos.',
            },
            {
                header: 'Gestión de Clientes y Prospectos',
                paragraph:
                    'Rastrea la productividad y mide indicadores clave de rendimiento basados en la cantidad de trabajo completado por los empleados.',
            },
            {
                header: 'Cálculos Totales de Distancia para Reembolsos Precisos',
                paragraph:
                    'Rastrea la distancia precisa recorrida por los empleados para trabajo de campo basado en métricas diarias, semanales y mensuales, y reembolsa gastos según la distancia y ubicaciones recorridas.',
            },
        ],
        softwareDetails: [
            {
                title: 'Implementa sistemas de seguimiento de tiempo para monitorear horas de trabajo.',
                description: [
                    'Implementación de soluciones de seguimiento de tiempo para monitorear el tiempo que los empleados dedican a tareas y proyectos.',
                    'Esto puede ayudar a comprender la utilización de recursos y mejorar la gestión del tiempo.',
                    'Utiliza sistemas de gestión de proyectos para rastrear el progreso del proyecto, asignar tareas y monitorear los plazos.',
                    'Esto ayuda a optimizar los flujos de trabajo del proyecto y asegurar la finalización oportuna.',
                ],
            },
            {
                title: 'Monitorea los indicadores clave de rendimiento relevantes.',
                description: [
                    'Definir y rastrear los indicadores clave de rendimiento relevantes para los objetivos de tu organización.',
                    'Esto puede incluir objetivos de ventas, métricas de satisfacción del cliente u otros indicadores de rendimiento.',
                ],
                sDKeys: [
                    'Rendimiento de Ventas: Ingresos de ventas mensuales/trimestrales, número de ventas cerradas, tasa de conversión de ventas, tamaño promedio de transacción, valor del pipeline de ventas.',
                    'Servicio al Cliente: Puntaje de satisfacción del cliente (CSAT), puntaje de promotor neto (NPS), tiempo de primera respuesta, tiempo promedio de resolución, tasa de retención de clientes, volumen de tickets.',
                    'Operaciones/Producción: Producción/producción, métricas de calidad (tasa de defectos, tasa de errores), rendimiento de entrega a tiempo, relación de rotación de inventario, tiempo de inactividad del equipo, tasa de utilización de empleados.',
                    'General: Productividad de los empleados (producción por hora), asistencia y puntualidad, tasa de logro de objetivos, retroalimentación de empleados y revisiones de desempeño.',
                ],
            },
            {
                title: 'Genera informes sobre las tendencias de tu fuerza laboral.',
                description: [
                    'Integración de funciones de análisis e informes para obtener información sobre las tendencias de la fuerza laboral, el rendimiento y las áreas de mejora.',
                    'Los informes personalizados pueden ayudar en la toma de decisiones.',
                ],
                sDKeys: [
                    'Comparación de la composición demográfica con la fuerza laboral general o referencia de la industria e identificación de tendencias o cambios demográficos con el tiempo.',
                    'Comparación de métricas de rendimiento entre diferentes equipos de campo, regiones o períodos de tiempo.',
                    'Mapeo geoespacial de las actividades del personal de campo para visualizar la distribución y áreas de cobertura.',
                    'Análisis del rendimiento de ventas o servicio por región geográfica o territorio. Identificación de áreas de alto potencial para expansión u optimización.',
                ],
            },
        ],
    },
    fieldSalesAnalytics: {
        featureHeader: 'Análisis de Ventas en el Campo',
        featureDescription:
            'Rastrea y analiza métricas clave del equipo de ventas en el campo, como ingresos de ventas, interacciones con clientes y tendencias geográficas.',
        featureParagraph:
            'No más tarjetas de tiempo, papeleo excesivo y diferentes documentos para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y visualiza el inicio y fin de las horas de trabajo de tus empleados en un formato bien estructurado.',
        featureCaption:
            'Analiza la recolección, análisis e interpretación de datos relacionados con las actividades de ventas en el campo para obtener información y tomar decisiones informadas.',
        keyPoints: [
            {
                header: 'Indicadores Clave de Rendimiento para Ventas y Leads',
                paragraph:
                    'Los Indicadores Clave de Rendimiento pueden ayudar en la toma de decisiones basadas en datos, lo que puede contribuir a decisiones productivas para la próxima vez o a la reestructuración de la estrategia.',
            },
            {
                header: 'Informes Personalizados',
                paragraph:
                    'Los informes pueden ser accedidos de manera diaria, semanal o mensual sobre Ventas, Leads y Adquisición de Clientes. Esto puede ayudar en la productividad y eficiencia de la persona.',
            },
            {
                header: 'Validación de Visitas de Ventas y Optimización con Geocerca',
                paragraph:
                    'Valida la visita con check-ins basados en geocerca para mayor precisión. Las coordenadas de la ubicación pueden ser adjuntadas a una foto mientras se comparte.',
            },
            {
                header: 'Medir la Efectividad de la Ruta de Visitas',
                paragraph:
                    'La Ruta de Visitas puede ser utilizada para planificar visitas o planes de visitas para empleados a clientes. Integra tu plan de visitas directamente en los Mapas para optimizar la navegación.',
            },
        ],
        softwareDetails: [
            {
                title: 'Perspectivas diarias del campo para un mejor rendimiento en ventas.',
                description: [
                    'Generación de informes electrónicos en tiempo real orientada a datos analíticos. Función de informe confiable y fácil de usar para tu acceso y uso.',
                    'Como gerente de ventas, tendrás una visión clara de varios informes que se enviarán a tu dirección de correo electrónico o que también podrás ver en el panel de control.',
                    'Algunos de los informes notables son informes mensuales, informes por ubicación de sucursales, informes específicos de empleados, etc.',
                ],
            },
            {
                title: 'Visión general de la asistencia en el campo.',
                description: [
                    'Visión general de la asistencia de empleados en el campo y visitas de campo.',
                    'Obtén una visión completa de la asistencia de la fuerza laboral en el campo y reuniones.',
                    'En el informe de asistencia en el campo, obtendrás datos sobre la hora de marcación de asistencia, ubicación, hora de llegada tarde, horas trabajadas, etc.',
                    'De la misma manera, también te proporcionamos informes de check-in/out de visitas de campo. Incluye datos relacionados con visitas de campo como hora de inicio/finalización, hora de check-in/out, ubicación de reuniones, distancia recorrida, notas de resultados de reuniones, etc.',
                ],
            },
            {
                title: 'Informe generado orientado a datos analíticos.',
                description: [
                    'Suscríbete y descarga informes electrónicos mensuales.',
                    'Puedes suscribirte y descargar los informes electrónicos mensuales siempre que lo desees.',
                    'Disponibles en formato pdf y excel. Un conjunto completo de informes organizados para que tengas información detallada sobre las acciones externas de un empleado fuera de la organización, lo que tiende a ser una tarea difícil manualmente.',
                ],
            },
        ],
    },
    locationBasedAttendance: {
        featureHeader: 'Asistencia Basada en Ubicación',
        featureDescription:
            'Asistencia basada en geocerca para garantizar que los empleados solo puedan marcar entrada o salida cuando se encuentren dentro de los límites geográficos predefinidos.',
        featureCaption:
            'La asistencia basada en ubicación asegura precisión para monitorear y registrar la asistencia de los empleados en función de su ubicación física.',
        keyPoints: [
            {
                header: 'Geocerca',
                paragraph:
                    'Implementa límites virtuales geocercados alrededor de ubicaciones específicas de las instalaciones de la oficina. Ayuda a automatizar la asistencia basada en la entrada o salida de un empleado en un área designada.',
            },
            {
                header: 'Autenticación y Seguridad',
                paragraph:
                    'Métodos de autenticación para asegurar la precisión y seguridad de los datos de asistencia utilizando PINs, biometría o reconocimiento facial.',
            },
            {
                header: 'Actualizaciones en Tiempo Real',
                paragraph:
                    'Asegura que los registros de asistencia se actualicen de manera oportuna, permitiendo decisiones de gestión rápidas.',
            },
            {
                header: 'Consideraciones para Trabajo Remoto',
                paragraph: 'Considera métodos alternativos para el seguimiento de asistencia de empleados remotos.',
            },
        ],
        softwareDetails: [
            {
                title: 'Tiempo y ubicación de la fuerza laboral.',
                description: [
                    'Conoce Cuándo y Dónde Trabajan tus Empleados',
                    'Instruye a tus empleados para que otorguen permiso en sus teléfonos móviles para que se pueda rastrear el tiempo y la ubicación.',
                    'Una vez concedido el permiso, el rastreador puede comenzar a funcionar con un toque.',
                ],
            },
            {
                title: 'Informe en Vivo Dos',
                description: [
                    'Implementar un sistema de asistencia basado en ubicación puede mejorar la precisión y agilizar la gestión de asistencia. Sin embargo, es crucial equilibrar los beneficios con las consideraciones de privacidad de los empleados y comunicarse de manera transparente sobre la implementación y uso de tales sistemas.',
                ],
            },
            {
                title: 'Alerta Basada en Geocerca',
                description: [
                    'Configura alertas automatizadas para patrones de asistencia inusuales o discrepancias. Esto ayuda a identificar posibles problemas, como entradas o salidas no autorizadas.',
                    'Implementa características de informes y análisis para obtener información sobre patrones de asistencia, tendencias y excepciones.',
                ],
                sDKeys: [
                    'Recibe alertas cuando activos valiosos como vehículos o equipos entren o salgan de áreas designadas, ayudando a prevenir robos o usos no autorizados.',
                    'Asegura la seguridad de los trabajadores de campo configurando geocercas alrededor de áreas peligrosas o sitios de trabajo remotos, activando alertas si los empleados ingresan a zonas restringidas.',
                    'Cuando un dispositivo entra o sale de un área geocercada, el sistema activa alertas o notificaciones predefinidas.',
                    'El sistema monitorea continuamente la ubicación de los dispositivos rastreados en tiempo real y verifica si intersectan con alguna de las geocercas definidas.',
                ],
            },
        ],
    },
    liveReporting: {
        featureHeader: 'Informes en Tiempo Real',
        featureDescription:
            'Seguimiento y análisis en tiempo real de varios aspectos del trabajo, como asistencia, tareas completadas o progreso de proyectos.',
        featureCaption:
            'Mantente informado sobre las tareas de los empleados, actas de reuniones, valida visitas y toma decisiones basadas en los datos.',
        keyPoints: [
            {
                header: 'Notificaciones Basadas en Eventos',
                paragraph:
                    'El administrador o gerente puede suscribirse o elegir las notificaciones que desea recibir. Esto ayuda a concentrarse en las notificaciones que realmente quiere recibir.',
            },
            {
                header: 'Mantente Notificado sobre Asistencia y Permisos',
                paragraph:
                    'Mantente un paso adelante y conoce el número de empleados antes para que la planificación del día pueda ser más productiva y eficiente.',
            },
            {
                header: 'Rastrea Todas las Aprobaciones',
                paragraph: 'Rastrea todas las aprobaciones, desde reembolsos hasta permisos, y visualízalas más tarde.',
            },
            {
                header: 'Mantente Actualizado con Formularios y Actas de Reuniones',
                paragraph:
                    'Los formularios personalizados pueden ayudar a obtener datos específicos para una reunión, los cuales pueden ser accedidos de manera remota.',
            },
        ],
        softwareDetails: [
            {
                title: 'Localiza al personal de campo en tiempo real.',
                description: [
                    'Localiza a tu personal de campo en tiempo real.',
                    'Obtén detalles instantáneos sobre la ubicación de tu fuerza laboral en el campo mientras viajan en tiempo real.',
                    'Treant te permite localizar y rastrear a tus empleados de campo usando el GPS móvil, tanto en línea como fuera de línea. Mantente actualizado sobre cada movimiento de tu personal de campo y mantén un entorno de trabajo transparente.',
                ],
            },
            {
                title: 'Mantente al día con la información de campo de tus empleados.',
                description: [
                    'Mantente al día con la información de campo de tus empleados.',
                    'La información de campo del personal se sincronizará automáticamente con tu panel de control.',
                    'Detalles de tus empleados como el porcentaje de batería, GPS, visibilidad del tiempo de encendido/apagado de internet y el tiempo ideal que pasan en el campo se notificarán instantáneamente en tiempo real.',
                ],
            },
            {
                title: 'Automatiza los datos de reembolsos y asignaciones de los empleados.',
                description: [
                    'Historial de ubicación impulsado por IA para datos precisos.',
                    'Los datos de ubicación de tus empleados serán impulsados por Inteligencia Artificial para proporcionar información precisa sobre rutas y seguimiento. Los datos de seguimiento en vivo y otros historiales de ubicación se automatizarán en tu correo electrónico a intervalos regulares para un análisis más profundo de lo que sucede dentro y fuera del campo.',
                ],
            },
        ],
    },
    aIBasedOptimization: {
        featureHeader: 'Optimización Basada en IA',
        featureDescription:
            'Optimiza y automatiza la planificación de rutas para representantes de campo o personal de ventas basado en rutas inteligentes impulsadas por IA.',
        featureCaption:
            'Optimiza las rutas para el día y compara informes con la temporada anterior con nuestro algoritmo basado en IA.',
        keyPoints: [
            {
                header: 'Integración de Datos en Tiempo Real',
                paragraph:
                    'Fuentes de datos en tiempo real, como actualizaciones de tráfico en vivo, condiciones meteorológicas y cierres de carreteras, para ajustar dinámicamente las rutas según las circunstancias actuales, ayudando en una planificación diaria eficiente.',
            },
            {
                header: 'Interfaz de Usuario para Planificación de Rutas',
                paragraph:
                    'Visualiza rutas optimizadas y realiza los ajustes necesarios. Esto podría ser parte de una aplicación móvil o una plataforma basada en web.',
            },
            {
                header: 'Métricas de Productividad',
                paragraph:
                    'Sistema de IA para tener en cuenta las características únicas de diferentes rutas o recorridos.',
            },
            {
                header: 'Datos Cartográficos y Geoespaciales',
                paragraph:
                    'Utiliza servicios de mapeo y datos geoespaciales para comprender la red vial, las condiciones de tráfico y otra información relacionada con la ubicación.',
            },
        ],
        softwareDetails: [
            {
                title: 'Optimización de Rutas con Actualizaciones en Tiempo Real',
                description: [
                    'Optimiza y planifica las rutas más eficientes para individuos o vehículos, como camiones de entrega, representantes de ventas o técnicos de servicio en campo, que siguen rutas específicas como parte de sus actividades regulares.',
                    'Esta aplicación puede ayudar a mejorar la eficiencia, reducir el tiempo de viaje y minimizar los costos operativos.',
                ],
                sDKeys: [
                    'Recopilación de datos de diversas fuentes, incluidos dispositivos GPS, informes de tráfico, actualizaciones meteorológicas y sensores de vehículos.',
                    'Los tiempos de respuesta más rápidos y las llegadas a tiempo mejoran la satisfacción del cliente, lo cual es crucial para los negocios orientados al servicio.',
                ],
            },
            {
                title: 'Consideraciones Cuidadosas sobre Privacidad y Cumplimiento',
                description: [
                    'Asegúrate de que el sistema basado en IA cumpla con las regulaciones locales, especialmente aquellas relacionadas con privacidad, seguridad de datos y leyes de transporte.',
                    'Actualiza y mejora regularmente los algoritmos de IA basados en nuevos datos y comentarios, con el objetivo de optimizar continuamente las rutas.',
                ],
            },
            {
                title: 'Integración con Otros Sistemas para Eficiencia en la Planificación de Rutas',
                description: [
                    'Utiliza algoritmos de aprendizaje automático para analizar datos históricos y predecir patrones futuros.',
                    'Esto puede ayudar a anticipar condiciones de tráfico, tiempos de entrega y otros factores que influyen en la planificación de rutas.',
                ],
            },
        ],
    },
    leadManagement: {
        featureHeader: 'Gestión de Leads',
        featureDescription:
            'Resume los datos de asistencia de los empleados, las horas de trabajo, las ausencias, las llegadas tardías y las salidas anticipadas dentro de un período específico.',
        featureCaption:
            'Genera informes automatizados para diferentes sucursales y recíbelos diariamente, semanalmente o mensualmente con más de 40 plantillas.',
        keyPoints: [
            {
                header: 'Automatiza los Detalles de Asistencia: Hora de Entrada, Hora de Retraso, Horas Totales Trabajadas, Total de Sesiones',
                paragraph:
                    'Proporciona un resumen general de la asistencia, incluyendo el número total de días laborables, las horas totales trabajadas y cualquier hora extra.',
            },
            {
                header: 'Informes de Múltiples Sucursales',
                paragraph:
                    'Accede remotamente a informes de asistencia de múltiples sucursales con categorías personalizables.',
            },
            {
                header: 'Saldos de Horas Extra y Licencias',
                paragraph:
                    'Muestra los saldos de licencia de cada empleado, incluyendo días de vacaciones, licencias por enfermedad y cualquier otro tipo de licencia acumulada y utilizada.',
            },
            {
                header: 'Informe de Asistencia Personalizado',
                paragraph:
                    'Permite a los usuarios personalizar informes basados en criterios específicos, como departamento, equipo o empleado individual.',
            },
        ],
        softwareDetails: [
            {
                title: 'Genera informes basados en la estructura de tu organización.',
                description: [
                    'Los informes de asistencia generados y analizados regularmente pueden proporcionar información valiosa para mejorar la gestión de la fuerza laboral, identificar tendencias y garantizar el cumplimiento de las políticas organizacionales.',
                    'Los detalles del informe dependerán de las necesidades y la estructura de la organización.',
                ],
            },
            {
                title: 'Obtén informes personalizables fácilmente.',
                description: [
                    'Identifica y resalta cualquier excepción o irregularidad en la asistencia, como ausencias no autorizadas, retrasos excesivos o casos de horas extra no aprobadas.',
                    'Utiliza gráficos o tablas para representar visualmente las tendencias y patrones de asistencia. Esto puede facilitar la identificación de anomalías y tendencias de un vistazo.',
                ],
            },
            {
                title: 'Reduce el tiempo dedicado a informes manuales.',
                description: [
                    'Incluye detalles fundamentales como nombres de empleados, números de identificación y afiliaciones departamentales para proporcionar contexto a los datos de asistencia.',
                    'Especifica claramente el rango de fechas y horas cubierto por el informe. Esto ayuda a los usuarios a entender el alcance temporal de los datos.',
                    'Proporciona un resumen general de la asistencia, incluyendo el número total de días laborables, las horas totales trabajadas y cualquier hora extra.',
                ],
            },
        ],
    },
    taskManagement: {
        featureHeader: 'Gestión de Tareas',
        featureDescription:
            'Monitoreo y gestión sistemática de las actividades, productividad y asistencia de los empleados.',
        featureParagraph:
            'Olvídate de las tarjetas de tiempo, el papeleo excesivo y los diferentes documentos para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y visualiza el inicio y fin de las horas de trabajo de tus empleados en un formato bien estructurado.',
        featureCaption:
            'Monitorea y gestiona varios aspectos de la fuerza laboral de una organización, incluyendo la asistencia de los empleados, las horas de trabajo, la productividad y el rendimiento.',
        keyPoints: [
            {
                header: 'Monitoreo en tiempo real',
                paragraph:
                    'Monitorea el progreso en tiempo real viendo tableros de tareas, revisando el estado de las tareas y rastreando el tiempo dedicado.',
            },
            {
                header: 'Monitorea todos tus flujos de trabajo en un solo lugar para toda la organización',
                paragraph:
                    'Funciones de colaboración en la gestión de tareas para comunicarte con los miembros del equipo en tiempo real. Comenta en las tareas, haz preguntas y proporciona actualizaciones para mantener a todos informados.',
            },
            {
                header: 'Monitorea el rendimiento',
                paragraph:
                    'Gestión de Clientes y Leads para rastrear la productividad. La productividad de los empleados y los indicadores clave de rendimiento pueden medirse en función de la cantidad de trabajo completado por los empleados.',
            },
            {
                header: 'Estandariza la definición de objetivos',
                paragraph:
                    'Estructura jerárquica para organizar objetivos: metas del proyecto, objetivos departamentales, metas de rendimiento individuales, iniciativas estratégicas, etc.',
            },
        ],
        softwareDetails: [
            {
                title: 'Implementa sistemas de seguimiento de tiempo para monitorear las horas de trabajo.',
                description: [
                    'Implementar soluciones de seguimiento de tiempo para monitorear el tiempo que los empleados dedican a tareas y proyectos.',
                    'Esto puede ayudar a entender la utilización de recursos y mejorar la gestión del tiempo.',
                    'Utiliza sistemas de gestión de proyectos para rastrear el progreso del proyecto, asignar tareas y monitorear los plazos.',
                    'Esto ayuda a optimizar los flujos de trabajo del proyecto y asegurar una finalización oportuna.',
                ],
            },
            {
                title: 'Monitorea los indicadores clave de rendimiento relevantes.',
                description: [
                    'Definir y rastrear los indicadores clave de rendimiento relevantes para los objetivos de tu organización.',
                    'Esto puede incluir objetivos de ventas, métricas de satisfacción del cliente u otros indicadores de rendimiento.',
                ],
            },
            {
                title: 'Genera informes sobre las tendencias de tu fuerza laboral.',
                description: [
                    'Integrar características de análisis e informes para obtener información sobre las tendencias de la fuerza laboral, el rendimiento y las áreas de mejora.',
                    'Los informes personalizados pueden ayudar en la toma de decisiones.',
                ],
            },
        ],
    },
    salesPipelineMonitoring: {
        featureHeader: 'Monitoreo del Pipeline de Ventas',
        featureDescription:
            'Rastrea y analiza métricas clave del equipo de ventas en el campo, como los ingresos por ventas, las interacciones con clientes y las tendencias geográficas.',
        featureParagraph:
            'Olvídate de las tarjetas de tiempo, el papeleo excesivo y los diferentes documentos para verificar la asistencia de los empleados. Automatiza el proceso de monitoreo de asistencia con WebWork Time Tracker y visualiza el inicio y fin de las horas de trabajo de tus empleados en un formato bien estructurado.',
        featureCaption:
            'Analiza la recopilación, análisis e interpretación de datos relacionados con las actividades de ventas en el campo para obtener información y tomar decisiones informadas.',
        keyPoints: [
            {
                header: 'Flujos de Trabajo Automatizados',
                paragraph:
                    'Aprovecha el sistema para agilizar y optimizar las diversas etapas del proceso de ventas, como la captura de leads, calificación, nutrición, gestión del pipeline, seguimiento de actividades e informes.',
            },
            {
                header: 'Integración con CRM',
                paragraph:
                    'Agiliza la gestión de leads, el seguimiento de contactos, el monitoreo de acuerdos y la sincronización de datos entre las dos plataformas.',
            },
            {
                header: 'Facilita la Colaboración y Comunicación',
                paragraph:
                    'Canales de comunicación centralizados, programación de reuniones de ventas, revisiones colaborativas de acuerdos, documentación de procesos, colaboración interdisciplinaria, promoción de la comunicación abierta.',
            },
            {
                header: 'Perspectivas sobre el Rendimiento de Ventas Futuro',
                paragraph:
                    'Aprovecha el análisis de datos y la modelización predictiva para prever resultados y tendencias potenciales.',
            },
        ],
        softwareDetails: [
            {
                title: 'Perspectivas Diarias del Campo para un Mejor Rendimiento de Ventas',
                description: [
                    'Generación de informes e-report orientada a datos analíticos. Función de informe confiable y fácil de usar para tu acceso y uso.',
                    'Como gerente de ventas, tendrás una visión clara de varios informes que se enviarán a tu dirección de correo o también podrás verlos en tu panel de control.',
                    'Algunos de los informes notables son informes mensuales, informes por ubicación de sucursales, informes específicos de empleados, etc.',
                ],
            },
            {
                title: 'Visión General sobre la Asistencia en el Campo',
                description: [
                    'Visión general sobre la asistencia de empleados en el campo y las visitas de campo.',
                    'Obtén una vista completa sobre la asistencia de la fuerza laboral en el campo y las reuniones.',
                    'En el informe de asistencia en el campo, obtendrás datos sobre la hora de marcaje de asistencia, ubicación, tiempo de asistencia tardía, horas de trabajo, etc.',
                    'De manera similar, los informes de entrada/salida de visitas de campo incluyen datos relacionados con las visitas de campo, como hora de inicio/finalización, hora de entrada/salida, ubicación de las reuniones, distancia recorrida, notas de resultados de reuniones, etc.',
                ],
            },
            {
                title: 'Generación de Informes Orientada a Datos Analíticos',
                description: [
                    'Suscríbete y descarga informes e-report mensuales.',
                    'Puedes suscribirte y descargar los informes mensuales siempre que lo desees.',
                    'Disponibles en formato PDF y Excel. Un completo conjunto de informes alineados para ofrecerte información detallada sobre las acciones externas de un empleado fuera de la organización, lo cual suele ser una tarea difícil manualmente.',
                ],
            },
        ],
    },
    workflowAutomation: {
        featureHeader: 'Automatización de Flujos de Trabajo',
        featureDescription:
            'Asistencia basada en geocercas para asegurar que los empleados solo puedan registrar entrada o salida cuando estén dentro de los límites geográficos predefinidos.',
        featureCaption:
            'La asistencia basada en ubicación asegura precisión para monitorear y registrar la asistencia de los empleados según su ubicación física.',
        keyPoints: [
            {
                header: 'Asignación Automatizada de Tareas',
                paragraph:
                    'Automatización de flujos de trabajo para asignar automáticamente tareas a los individuos más apropiados, considerando factores como habilidades, disponibilidad, carga de trabajo y niveles de prioridad.',
            },
            {
                header: 'Enrutamiento, Calificación y Puntuación de Leads',
                paragraph:
                    'Enrutamiento, calificación y puntuación de leads para asignar los leads entrantes a los representantes de ventas adecuados, evaluar su calidad y priorizar las acciones de seguimiento.',
            },
            {
                header: 'Integración con Sistemas Externos',
                paragraph:
                    'Facilita una colaboración más fluida, mejora la precisión de los datos y asegura un flujo de trabajo cohesivo a través de diversas funciones empresariales.',
            },
            {
                header: 'Seguimiento y Reporte de Desempeño',
                paragraph:
                    'Rastrea métricas como los tiempos de finalización de procesos, tasas de errores, utilización de recursos y productividad general.',
            },
        ],
        softwareDetails: [
            {
                title: 'Tiempo y Ubicación de la Fuerza Laboral',
                description: [
                    'Conoce Cuándo y Dónde Trabajan Tus Empleados',
                    'Instruye a tus empleados para que otorguen permisos en sus teléfonos móviles para que se pueda rastrear el tiempo y la ubicación.',
                    'Una vez concedido el permiso, el rastreador puede comenzar a funcionar con un toque.',
                ],
            },
            {
                title: 'Reportes en Vivo',
                description: [
                    'Implementar un sistema de asistencia de empleados basado en la ubicación puede mejorar la precisión y agilizar la gestión de asistencia. Sin embargo, es crucial equilibrar los beneficios con consideraciones de privacidad de los empleados y comunicar de manera transparente sobre la implementación y uso de dichos sistemas.',
                ],
            },
            {
                title: 'Alertas Basadas en Geocercas',
                description: [
                    'Configura alertas automáticas para patrones de asistencia inusuales o discrepancias. Esto ayuda a identificar problemas potenciales, como registros de entrada o salida no autorizados.',
                    'Implementa funciones de reporte y análisis para obtener información sobre patrones de asistencia, tendencias y excepciones.',
                ],
            },
        ],
    },
    aIBasedAssistance: {
        featureHeader: 'Asistencia Basada en IA',
        featureDescription:
            'Seguimiento y análisis en tiempo real de diversos aspectos de su trabajo, como asistencia, tareas completadas o progreso de proyectos.',
        featureCaption:
            'Mantente informado sobre las tareas de los empleados, Minutas de Reuniones, valida visitas y toma decisiones basadas en los datos.',
        keyPoints: [
            {
                header: 'Notificaciones Basadas en Eventos',
                paragraph:
                    'El administrador o gerente puede suscribirse o elegir las notificaciones que desean recibir. Esto ayudaría a centrarse en las notificaciones que realmente quieren recibir.',
            },
            {
                header: 'Mantente Notificado sobre Asistencia y Ausencias',
                paragraph:
                    'Mantente notificado sobre la asistencia y las ausencias. Mantente un paso adelante y conoce el número de empleados antes de planificar el día para que la planificación sea más productiva y eficiente.',
            },
            {
                header: 'Rastrea Todas las Aprobaciones',
                paragraph:
                    'Rastrea todas las aprobaciones. Realiza un seguimiento de todas las aprobaciones, desde reembolsos hasta licencias, y revísalas más tarde.',
            },
            {
                header: 'Mantente Actualizado con Formularios y Minutas de Reuniones',
                paragraph:
                    'Seguimiento Remoto a través de Formularios y Minutas de Reuniones. Los formularios personalizados pueden ayudar a obtener datos específicos para una reunión, que se pueden acceder de forma remota.',
            },
        ],
        softwareDetails: [
            {
                title: 'Ubica la fuerza laboral en tiempo real.',
                description: [
                    'Ubica a tu personal de campo en tiempo real.',
                    'Obtén detalles instantáneos de la ubicación de tu fuerza laboral mientras viajan en tiempo real.',
                    'Treant te permite ubicar y rastrear a tus empleados de campo usando el GPS de sus móviles, tanto en línea como fuera de línea. Mantente actualizado sobre cada movimiento de tu personal de campo y mantén un entorno de trabajo transparente.',
                ],
            },
            {
                title: 'Mantente actualizado con la información de campo de tus empleados',
                description: [
                    'Mantente actualizado con la información de campo de tus empleados.',
                    'La información de campo del personal se sincronizará automáticamente con tu panel de control.',
                    'Detalles de tus empleados como el porcentaje de batería, visibilidad del GPS, tiempo de conexión/desconexión de internet, y el tiempo ideal que pasan en el campo te serán notificados instantáneamente en tiempo real.',
                ],
            },
            {
                title: 'Automatiza los datos de reembolsos y asignaciones de empleados.',
                description: [
                    'Historial de ubicación impulsado por IA para datos precisos.',
                    'Los datos de ubicación de tus empleados estarán impulsados por Inteligencia Artificial para proporcionar información precisa sobre rutas y rastreo. Los datos de seguimiento en vivo y otros historiales de ubicación se automatizarán a tu correo electrónico a intervalos regulares para un análisis más profundo de lo que está sucediendo dentro y fuera del campo.',
                ],
            },
        ],
    },
    digitizationOfOrder: {
        featureHeader: 'Digitalización de Pedidos',
        featureDescription:
            'Seguimiento y análisis en tiempo real de diversos aspectos de su trabajo, como asistencia, tareas completadas o progreso de proyectos.',
        featureCaption: 'Optimiza la gestión de pedidos de principio a fin y reduce los errores manuales.',
        keyPoints: [
            {
                header: 'Informes y Análisis',
                paragraph:
                    'Genera informes detallados y análisis para monitorear el rendimiento del procesamiento de pedidos y la productividad de los empleados.',
            },
            {
                header: 'Ingreso de Pedidos Automatizado',
                paragraph:
                    'Captura y valida automáticamente los detalles del pedido ingresados por empleados o clientes.',
            },
            {
                header: 'Seguimiento de Pedidos en Tiempo Real y Actualizaciones de Estado',
                paragraph:
                    'Proporciona actualizaciones en tiempo real sobre el estado de los pedidos y el seguimiento de los envíos a empleados y clientes.',
            },
            {
                header: 'Automatización del Flujo de Trabajo y Asignación de Tareas',
                paragraph:
                    'Automatiza los procesos de aprobación de pedidos y asigna tareas a los empleados adecuados.',
            },
        ],
    },
    targetManagement: {
        featureHeader: 'Gestión de Objetivos',
        featureDescription:
            'Seguimiento y análisis en tiempo real de diversos aspectos de su trabajo, como asistencia, tareas completadas o progreso de proyectos.',
        featureCaption:
            'Mantente informado sobre las tareas de los empleados, minutos de reunión, valida visitas y toma decisiones basadas en los datos.',
        keyPoints: [
            {
                header: 'Integración con Seguimiento de Campo',
                paragraph:
                    'Alcanza objetivos diarios, semanales y mensuales y mantiene registros de los logros de los empleados con seguimiento en campo en tiempo real.',
            },
            {
                header: 'Actualizaciones y Sincronización en Tiempo Real',
                paragraph:
                    'Progreso en tiempo real (número de objetivos alcanzados vs. restantes), tiempos de check-in/check-out, número de horas (duración) dedicadas a cada tarea y seguimiento de ubicación.',
            },
            {
                header: 'Desempeño y Análisis',
                paragraph:
                    'Métricas de desempeño (tasa de finalización, tiempo tomado, puntuaciones de satisfacción del cliente), comparación con objetivos (desempeño real vs. objetivo), datos históricos (tendencias de desempeño pasadas).',
            },
            {
                header: 'Notificaciones y Alertas Automatizadas',
                paragraph:
                    'Envía notificaciones automatizadas para mantener a los empleados informados sobre alertas de logros de objetivos, recordatorios de desempeño y advertencias de plazos.',
            },
        ],
    },
    invoiceManagement: {
        featureHeader: 'Gestión de Facturas',
        featureDescription:
            'Seguimiento y análisis en tiempo real de diversos aspectos de su trabajo, como asistencia, tareas completadas o progreso de proyectos.',
        featureCaption:
            'Crea, sigue el estado de las facturas en tiempo real y procesa con alertas y notificaciones automatizadas.',
        keyPoints: [
            {
                header: 'Notificaciones y Recordatorios Automatizados',
                paragraph:
                    'Configura sistemas para rastrear el estado de las facturas y enviar recordatorios y alertas automatizadas para pagos pendientes y vencidos.',
            },
            {
                header: 'Plantillas de Factura',
                paragraph:
                    'Plantillas de factura estandarizadas que incluyen todos los detalles necesarios, como información del cliente, detalles del pedido, impuestos, descuentos y términos de pago.',
            },
            {
                header: 'Firma Digital y Prueba de Entrega',
                paragraph:
                    'Recoge firmas digitales y pruebas de entrega basadas en OTP como parte del proceso de facturación.',
            },
            {
                header: 'Sincronización de Facturas y Actualización en Tiempo Real',
                paragraph:
                    'Sincronización automática con el sistema central (almacenamiento en la nube) y actualizaciones en tiempo real sobre el estado de las facturas.',
            },
        ],
    },
    distributorOrder: {
        featureHeader: 'Orden de Distribuidor',
        featureDescription:
            'Seguimiento y análisis en tiempo real de diversos aspectos de su trabajo, como asistencia, tareas completadas o progreso de proyectos.',
        featureCaption:
            'Mantente informado sobre las tareas de los empleados, minutos de reunión, valida visitas y toma decisiones basadas en los datos.',
        keyPoints: [
            {
                header: 'Información de Distribución',
                paragraph:
                    'Crea y procesa pedidos con todos los detalles relevantes para cada distribución y asigna tareas según la disponibilidad.',
            },
            {
                header: 'Integración de Inventario en Tiempo Real',
                paragraph:
                    'Integra con la gestión de inventario para garantizar una asignación precisa de existencias y actualizar los niveles de inventario en tiempo real.',
            },
            {
                header: 'Notificaciones y Alertas Automatizadas',
                paragraph:
                    'Configura el sistema para enviar notificaciones automatizadas en etapas clave del proceso de pedido.',
            },
            {
                header: 'Panel de Control del Empleado',
                paragraph:
                    'Accede a un panel donde los empleados pueden verificar pedidos, rastrear el progreso y descargar documentos necesarios.',
            },
        ],
    },
    inventoryManagement: {
        featureHeader: 'Gestión de Inventario',
        featureDescription:
            'Seguimiento y análisis en tiempo real de diversos aspectos de su trabajo, como asistencia, tareas completadas o progreso de proyectos.',
        featureCaption:
            'El equilibrio adecuado del stock con actualizaciones en tiempo real y seguimiento del inventario para datos precisos.',
        keyPoints: [
            {
                header: 'Integración con la Gestión de Pedidos',
                paragraph:
                    'Asegura una integración fluida entre los sistemas de inventario y gestión de pedidos para sincronizar los niveles de stock con los pedidos entrantes y salientes.',
            },
            {
                header: 'Gestión Multilocalización',
                paragraph:
                    'Rastrea el inventario en múltiples almacenes, tiendas o ubicaciones y accede a un control centralizado, optimiza la distribución del stock y mejora la visibilidad.',
            },
        ],
    },
    orderCollectionManagement: {
        featureHeader: 'Gestión de Pedidos/Recogidas',
        featureDescription:
            'Simplifique las operaciones de pedidos/recogidas con una solución escalable y automatizada.',
        featureCaption: 'Simplifique las operaciones de pedidos/recogidas con una solución escalable y automatizada.',
        keyPoints: [
            {
                header: 'Sistema de Gestión de Pedidos Unificado',
                paragraph:
                    'Un sistema centralizado accesible tanto para empleados en el sitio como fuera del sitio para gestionar pedidos, rastrear el progreso y actualizar estados en tiempo real.',
            },
            {
                header: 'Control de Acceso Basado en Roles',
                paragraph:
                    'Niveles diferentes de acceso y permisos basados en roles (por ejemplo, gerentes, empleados en el sitio, empleados fuera del sitio) para garantizar la seguridad de los datos y el acceso apropiado.',
            },
            {
                header: 'Sincronización en Tiempo Real',
                paragraph:
                    'Asegúrese de que todos los datos de pedidos se sincronicen en tiempo real en todos los dispositivos y ubicaciones, para que todos tengan acceso a la información más reciente.',
            },
            {
                header: 'Comunicación y Coordinación',
                paragraph:
                    'Facilite la comunicación entre empleados en el sitio y fuera del sitio a través de mensajes en la aplicación y notificaciones.',
            },
        ],
    },
};

export default features;
