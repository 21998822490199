const joinUsPage = {
    joinUsString: 'हमसे जुड़ें',
    joinUsHeroPara:
        'TraceForce की टीम का हिस्सा बनें! रोमांचक अवसरों का अन्वेषण करें जहाँ टीम वर्क और रचनात्मकता मार्गदर्शन करते हैं। जानें कि आप हमारी मिशन में कैसे योगदान कर सकते हैं जो सशक्तिकरण सॉफ्टवेयर समाधान के माध्यम से व्यवसायों को सशक्त बनाता है। एक सहायक वातावरण में अपने करियर को आगे बढ़ाएँ जो आपकी अनूठी प्रतिभाओं और विचारों की कदर करता है।',
    ourRecruitmentProcess: 'हमारी भर्ती प्रक्रिया',
    ourBenifitsString: 'हमारे लाभ',
    ourRecruitmentProcessObjects: [
        {
            title: 'तकनीकी साक्षात्कार',
            description:
                'यह साक्षात्कार आपके तकनीकी कौशल, समस्या-समाधान क्षमताओं, और वास्तविक दुनिया की स्थितियों पर आपकी जानकारी के उपयोग का आकलन करने के लिए डिज़ाइन किया गया है। हम आपकी तकनीकी दक्षता, कोडिंग क्षमताओं, और चुनौतियों का सामना करने के तरीके को समझना चाहते हैं। यह चरण मीट/स्काइप कॉल के माध्यम से किया जाता है।',
        },
        {
            title: 'प्रैक्टिस टेस्ट (योग्यता)',
            description:
                'हम आपके कोडिंग लॉजिक और कोड अनुकूलन का मूल्यांकन करते हैं, पहले साक्षात्कार के आधार पर एक असाइनमेंट भेजते हैं। असाइनमेंट को निर्दिष्ट समयसीमा के भीतर पूरा करना होगा।',
        },
        {
            title: 'प्रबंधन साक्षात्कार',
            description:
                'हमारी प्रबंधन टीम के साथ एक त्वरित बातचीत, जिसमें आपकी करियर दृष्टि और हमारे संगठन में सांस्कृतिक रूप से फिट होने की समझ होती है। हम एक ठोस विषय विशेषज्ञता और मजबूत कार्य नैतिकता वाले व्यक्ति की तलाश में रहते हैं।',
        },
        {
            title: 'अंतिम चयन',
            description:
                'अंतिम चयन HR टीम द्वारा किया जाएगा। साक्षात्कार के सभी चरणों के गहन समेकन के आधार पर अंतिम निर्णय लिया जाएगा और शामिल होने की तिथि की पुष्टि की जाएगी।',
        },
    ],
    ourBenifitsObjects: [
        {
            name: 'कहीं से भी काम करें!',
            description:
                "आपका घर का कार्यालय शायद ज्यादा पालतू-मित्रवत है, लेकिन हम आपको हर दिन देखना पसंद करते हैं, इसलिए हम 'कहीं से भी काम' की पेशकश नहीं करते।",
        },
        {
            name: 'वैकल्पिक सप्ताहांत',
            description:
                'जब आपके सप्ताहांत आपके मूड के जैसे बदलते हैं। एक सप्ताह आप मौज-मस्ती की योजना बनाते हैं, और अगले सप्ताह आप समयसीमा और ईमेल से निपटते हैं। यह काम और मौज का संतुलन है।',
        },
        {
            name: 'स्वास्थ्य बीमा',
            description:
                "उन सभी 'मुझे डॉक्टर की ज़रूरत हो सकती है' क्षणों के लिए। व्यापक कवरेज के साथ, आपको 'क्या होगा' की चिंता करने की ज़रूरत नहीं है।",
        },
        {
            name: 'रेफरल बोनस',
            description:
                'क्या आपके पास दोस्त हैं? बोनस कमाएं! आपके दोस्तों को नौकरी दिलाने और अपने लिए बोनस पाने का शानदार मौका।',
        },
        {
            name: 'कर्मचारी किट',
            description: 'आपको काम के लिए आवश्यक सभी चीज़ें - स्टेशनरी से लेकर प्रेरणादायक चीज़ों तक।',
        },
        {
            name: 'लचीले काम के घंटे',
            description: 'अब आप आराम से अपने पसंदीदा शो देख सकते हैं और काम के साथ संतुलन बना सकते हैं।',
        },
        {
            name: 'सैबेटिकल छुट्टी',
            description: 'सैबेटिकल छुट्टी',
        },
        {
            name: 'पितृत्व/मातृत्व अवकाश',
            description: 'पितृत्व/मातृत्व अवकाश',
        },
        {
            name: 'कार्य-जीवन संतुलन',
            description: 'कार्य-जीवन संतुलन',
        },
        {
            name: 'ऑफिस समारोह',
            description: 'ऑफिस समारोह',
        },
    ],
    jobsAtTraceForce: 'ट्रेसफोर्स में नौकरियां',
};
export default joinUsPage;
