const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'உங்களுடன் வளரும் தானமாக அமைக்க கூடிய திட்டங்கள்',
    subFlexiblePlansThatGrowsWithYou:
        'உங்கள் வணிக தேவைகளை பூர்த்தி செய்யும் விலையில் திட்டத்தைத் தேர்ந்தெடுக்கவும். டிரேஸ்‌போர்ஸ் அதிகமான மதிப்புடன் ஏற்ற விலையில் தொகுப்புகளை வழங்குகிறது.',
    pricingPlans: 'விலை திட்டங்கள்',
    pricingThatWorksForEveryOne: 'எல்லோருக்கும் வேலை செய்யும் விலை',
    monthly: 'மாதாந்திர',
    annualy: 'வருடாந்திர',
    international: 'அந்தராஷ்டிர',
    domestic: 'உள்ளூரா',
    month: 'மாதம்',
    benifits: 'சலுகைகள்',
    allPlanIncludeTheseBenifits: 'அனைத்து திட்டங்களிலும் இந்த சலுகைகள் அடங்கும்',
    someBenfitsOfMonthlyYearlySubscription: 'மாதாந்திர/வருடாந்திர சந்தாதாரர்களுக்கான சில சலுகைகள்',
    benifitsArr: [
        {
            title: 'தொழில்நுட்ப ஆதரவு',
            description: 'எங்கள் தொழில்முறை தொழில்நுட்ப ஆதரவு குழு உங்களுக்கு ஒவ்வொரு கட்டமும் உதவியாக இருக்கும்.',
        },
        {
            title: 'தொழில்நுட்பம்',
            description: 'தொழில்முறையிலிருந்து துவங்க சிறப்பு பயிற்சி.',
        },
        {
            title: 'வளர்ச்சி பகுப்பாய்வு',
            description: 'உங்கள் மாதாந்திர வளர்ச்சிக்கு உள்ள நிச்சயிக்கப்பட்ட குழு.',
        },
        {
            title: 'பரிசு',
            description: 'ஒவ்வொரு மாதமும் சிறந்த செயல்பாட்டின் கணக்குக்கு ஒரு சிறப்பு பரிசு.',
        },
    ],
    fAQs: 'அடிக்கடி கேட்கப்படும் கேள்விகள்',
    frequentlyAskedQuestions: 'அடிக்கடி கேட்கப்படும் கேள்விகள்',
    someBaTypeOfQuestions: 'எங்கள் வாடிக்கையாளர்களிடமிருந்து சில பொதுவான கேள்விகள்',
    fAQArr: [
        {
            question: 'என் நிறுவனத்திற்கு எப்படி பதிவு செய்யலாம்?',
            ans: 'ஆம், சந்தை அனுமதி உங்களுக்கு இந்த தீமையை எந்தவொரு இறுதி தயாரிப்பிலும் பயன்படுத்த அனுமதிக்கிறது. அனுமதி பற்றி மேலும் தகவலுக்கு, சந்தை பத்திரிகைகளைப் பரிசீலிக்கவும்.',
        },
        {
            question: 'எங்கு என் படைத்தொழிலாளி வாகனத்தைச் சேர்க்கலாம்?',
            ans: 'இல்லை, இது ஒரு HTML வார்ப்புரு ஆகும். இது நேரடியாக WordPress உடன் வேலை செய்யாது, ஆனால் இதை WordPress-க்கு ஏற்படக்கூடிய தீமையாக மாற்றலாம்.',
        },
        {
            question: 'என் படைத்தொழிலாளர்கள் Traceforce-க்கு எவ்வாறு உள்நுழைகிறார்கள்?',
            ans: 'உங்கள் சிக்கல்கள் அல்லது கருத்துக்களை எங்கள் நிச்சயிக்கப்பட்ட ஆதரவு மின்னஞ்சல் (support@coderthemes.com) க்கு அனுப்பவும். நாங்கள் எப்போதும் உங்களுக்கு உதவ தயாராக உள்ளோம்.',
        },
        {
            question: 'எனது படைத்தொழிலாளர்களை மொபைல் மூலம் கண்காணிக்க முடியுமா?',
            ans: 'ஆம், நாங்கள் முறைப்படி புதிய விகிதங்களை புதுப்பிப்போம். அனைத்து எதிர்கால புதுப்பிப்புகளும் இலவசமாக கிடைக்கும்.',
        },
        {
            question: 'தாராளத்தை எவ்வாறு குறிக்கலாம்?',
            ans: 'ஆம், சந்தை அனுமதி உங்களுக்கு இந்த தீமையை எந்தவொரு இறுதி தயாரிப்பிலும் பயன்படுத்த அனுமதிக்கிறது. அனுமதி பற்றி மேலும் தகவலுக்கு, சந்தை பத்திரிகைகளைப் பரிசீலிக்கவும்.',
        },
        {
            question: 'உருவாக்குனர் விருப்பங்களை எவ்வாறு முடக்கலாம்?',
            ans: 'இல்லை, இது ஒரு HTML வார்ப்புரு ஆகும். இது நேரடியாக WordPress உடன் வேலை செய்யாது, ஆனால் இதை WordPress-க்கு ஏற்படக்கூடிய தீமையாக மாற்றலாம்.',
        },
        {
            question: 'Traceforce என்ன அம்சங்களை வழங்குகிறது?',
            ans: 'உங்கள் சிக்கல்கள் அல்லது கருத்துக்களை எங்கள் நிச்சயிக்கப்பட்ட ஆதரவு மின்னஞ்சல் (support@coderthemes.com) க்கு அனுப்பவும். நாங்கள் எப்போதும் உங்களுக்கு உதவ தயாராக உள்ளோம்.',
        },
        {
            question: 'நான் Traceforce மென்பொருளை நிறுவ வேண்டுமா?',
            ans: 'ஆம், நாங்கள் முறைப்படி புதிய விகிதங்களை புதுப்பிப்போம். அனைத்து எதிர்கால புதுப்பிப்புகளும் இலவசமாக கிடைக்கும்.',
        },
        {
            question: 'Traceforce மென்பொருளின் செயலாக்க முறை என்ன?',
            ans: 'ஆம், சந்தை அனுமதி உங்களுக்கு இந்த தீமையை எந்தவொரு இறுதி தயாரிப்பிலும் பயன்படுத்த அனுமதிக்கிறது. அனுமதி பற்றி மேலும் தகவலுக்கு, சந்தை பத்திரிகைகளைப் பரிசீலிக்கவும்.',
        },
        {
            question: 'Traceforce மென்பொருளுடன் எந்த பயிற்சிகள் மற்றும் ஆதரவு விருப்பங்கள் உள்ளன?',
            ans: 'இல்லை, இது ஒரு HTML வார்ப்புரு ஆகும். இது நேரடியாக WordPress உடன் வேலை செய்யாது, ஆனால் இதை WordPress-க்கு ஏற்படக்கூடிய தீமையாக மாற்றலாம்.',
        },
        {
            question: 'Traceforce மென்பொருள் நமது குறிப்பிட்ட HR செயல்முறைகளுக்கு ஏற்ப தனிப்பயனாக்கப்பட முடியுமா?',
            ans: 'உங்கள் சிக்கல்கள் அல்லது கருத்துக்களை எங்கள் நிச்சயிக்கப்பட்ட ஆதரவு மின்னஞ்சல் (support@coderthemes.com) க்கு அனுப்பவும். நாங்கள் எப்போதும் உங்களுக்கு உதவ தயாராக உள்ளோம்.',
        },
        {
            question: 'HRMS மென்பொருள் எப்படி ஒப்புதல் மற்றும் அறிக்கைகளுடன் உதவக்கூடும்?',
            ans: 'ஆம், நாங்கள் முறைப்படி புதிய விகிதங்களை புதுப்பிப்போம். அனைத்து எதிர்கால புதுப்பிப்புகளும் இலவசமாக கிடைக்கும்.',
        },
        {
            question: 'HRMS மென்பொருள் தொலைதூர வேலை மற்றும் மொபைல் அணுகலை ஆதரிக்குமா?',
            ans: 'ஆம், நாங்கள் முறைப்படி புதிய விகிதங்களை புதுப்பிப்போம். அனைத்து எதிர்கால புதுப்பிப்புகளும் இலவசமாக கிடைக்கும்.',
        },
    ],
};
export default pricingPage;
