const footer = {
    requestACallBack: 'طلب معاودة الاتصال',
    freeAccountMessage: 'أنشئ حسابك المجاني لمدة 14 يومًا الآن',
    footerMessage:
        'إحداث ثورة في إدارة الموظفين وعمليات المبيعات من خلال أتمتة المهام الإدارية وتبسيط العمليات وتعزيز مشاركة الموظفين. ميزاتنا بسيطة وقوية وسهلة التكيف مع أي صناعة لزيادة إنتاجية المنظمة.',
    address: `922، الجناح A، نياتي إمبريس،
    بجوار شركة باكر آند جيجز الهندية الخاصة المحدودة،
    بجانب طريق فيمان ناغار، فيمان ناغار، 411014، بوني.`,
    rightsReserved: 'تريس فورس جميع الحقوق محفوظة',
};
export default footer;
