const features = {
    attendanceMonitoring: {
        featureSubcaption: 'প্রতি সেকেন্ডে দক্ষতা - কর্মচারী ব্যবস্থাপনা সিস্টেমের জন্য উদ্ভাবনী সমাধান',
        featureHeader: 'উপস্থিতি পর্যবেক্ষণ',
        featureDescription:
            'জিওফেন্স এলাকা বা শাখার মধ্যে ব্যক্তিদের উপস্থিতি বা অনুপস্থিতির সম্পর্কে বাস্তব-সময়ে ক্লক ইন/ক্লক আউট তথ্য',
        featureParagraph:
            'আর সময়কার্ড, অতিরিক্ত কাগজপত্র এবং বিভিন্ন নথি নেই যা কর্মচারীদের উপস্থিতি চেক করতে। WebWork টাইম ট্র্যাকার ব্যবহার করে উপস্থিতি পর্যবেক্ষণ প্রক্রিয়া স্বয়ংক্রিয় করুন এবং আপনার কর্মচারীদের কাজের ঘন্টাগুলি একটি সুসংগঠিত ফরম্যাটে দেখুন',
        featureCaption: 'আপনার ক্ষেত্রের কর্মচারী এবং স্থানীয় কর্মচারীদের জন্য একাধিক শাখার উপস্থিতি পরিচালনা করুন',
        keyPoints: [
            {
                header: 'বাস্তব সময় উপস্থিতি',
                paragraph:
                    'জিওফেন্স এলাকা বা শাখার মধ্যে ব্যক্তিদের উপস্থিতি বা অনুপস্থিতির সম্পর্কে বাস্তব-সময়ে তথ্য',
            },
            {
                header: 'মোট কাজের ঘন্টা গণনা',
                paragraph: 'প্রতিদিন, সপ্তাহে এবং মাসে কাজের ঘন্টা পর্যবেক্ষণ করুন',
            },
            {
                header: 'দূরবর্তী প্রবেশাধিকার উপস্থিতি রিপোর্ট',
                paragraph: 'দূরবর্তীভাবে একাধিক শাখার উপস্থিতি রিপোর্ট অ্যাক্সেস করুন',
            },
            {
                header: 'কেন্দ্রীভূত উপস্থিতি ব্যবস্থাপনা',
                paragraph:
                    'উপস্থিতি সম্পর্কিত ডেটা, প্রক্রিয়া এবং সিস্টেমগুলির একক, কেন্দ্রীভূত প্ল্যাটফর্ম বা সিস্টেমে সম্মিলন',
            },
        ],
        softwareDetails: [
            {
                title: 'কর্মচারী উপস্থিতি ট্র্যাকিং সম্পর্কে অন্তর্দৃষ্টি পান',
                description: [
                    'কর্মচারীদের সময়মত উপস্থিতির স্বচ্ছতা অর্জন করুন',
                    'অতিরিক্ত কোনও পদক্ষেপ ছাড়াই কর্মচারীদের উপস্থিতি সংগঠিত করুন এবং সময় সাশ্রয় করুন।',
                    'সহজেই পর্যবেক্ষণ করুন যে আপনার কর্মচারীরা সময়মতো কর্মস্থলে উপস্থিত হয়েছে এবং তাদের কাজের ঘন্টা সম্পন্ন করেছে কিনা।',
                    'আপনার কর্মস্থলের কার্যক্রম আরও সংগঠিত রাখুন এবং একটি উচ্চ দায়িত্বশীল কাজের পরিবেশ রাখুন।',
                ],
                sDKeys: [
                    'জিওফেন্স পরিমাপ সেটআপ করে প্রাঙ্গণের স্টাফদের স্বয়ংক্রিয়ভাবে লগ ইন এবং লগ আউট করুন।',
                    'যে কর্মচারীরা লগ ইন বা লগ আউট করতে ভুলে যায় বা ব্যর্থ হয় তাদের জন্য বিজ্ঞপ্তি এলার্টগুলি পান।',
                    'লগ ইন করার পরে আপনার ক্ষেত্রের কর্মীদের আন্দোলনগুলিকে বাস্তব-সময়ে সনাক্ত করুন এবং ট্র্যাক করুন।',
                ],
            },
            {
                title: 'বিস্তারিত উপস্থিতি পর্যবেক্ষণ রিপোর্ট পান',
                description: [
                    'উপস্থিতি ডেটার বুদ্ধিমান বিশ্লেষণ',
                    'ম্যাচিন, দেরি আসা, এবং কম ট্র্যাকিং-এর জন্য ফিল্টারগুলি যা আপনি ড্রপ-ডাউন তালিকা থেকে নির্বাচন করতে পারেন।',
                    'এটি আপনাকে ম্যানুয়ালি অনুসন্ধান করতে না গিয়ে আপনার পছন্দসই তথ্য তাত্ক্ষণিকভাবে তৈরি করতে সহায়তা করবে।',
                    'আপনি দৈনিক ভিত্তিতে উপস্থিতি ট্র্যাক করতে পারেন অথবা আপনি ক্যালেন্ডার থেকে একটি সময়সূচি বেছে নিতে পারেন।',
                ],
                sDKeys: [
                    'আপনার সংগঠনের জন্য উপস্থিতি সারাংশ পান।',
                    'আপনার দলের, বিভাগের বা শাখার মধ্যে ভাইরাল প্রবণতা দেখতে রিপোর্টগুলি তৈরি করুন এবং ব্যবহার করুন।',
                    'নিয়মিত সময়ে আপনার মেইলে রিপোর্টগুলি পান। (লগ ইন সময়, লগ আউট সময়, মোট কাজের ঘন্টা, মোট সেশন)',
                    'উন্নত সিদ্ধান্ত গ্রহণের জন্য অন্তর্দৃষ্টিপূর্ণ তথ্য।',
                ],
            },
            {
                title: 'অন্যান্য সময় এবং উত্পাদনশীলতা বৈশিষ্ট্যগুলি অন্বেষণ করুন।',
                description: [
                    'একটি 100% টাচলেস সিস্টেমের সাথে বাস্তব-সময়ে কর্মচারী উপস্থিতি পর্যবেক্ষণ এবং ট্র্যাক করার একটি নিরাপদ এবং স্বচ্ছ উপায়।',
                    'সহজ সেটআপ',
                    'স্বয়ংক্রিয় চেক ইন এবং চেক আউটের বিস্তারিত রিপোর্ট',
                    'ট্র্যাকিং সময় সহ স্ক্রীনশট',
                    'ক্রিয়াকলাপ স্তরের রেকর্ডিং',
                    'অ্যাপস এবং ওয়েবসাইট ব্যবহারের মনিটরিং',
                ],
                sDKeys: [
                    'কর্মচারীর উপস্থিতি সময় পরিচালনা এবং উত্পাদনশীলতা উন্নত করতে সহায়তা করেছে।',
                    'কাগজপত্র বাদ দিন এবং সঠিক কর্মচারী উপস্থিতি রেকর্ডগুলি সংগ্রহ করুন।',
                ],
            },
        ],
    },
    leaveManagement: {
        featureHeader: 'ছুটির ব্যবস্থাপনা',
        feaatureSubcaption: 'কর্মীদের অনুপস্থিতি সমন্বয় করুন, উৎপাদনশীলতা স্তর এবং অপারেশনাল ধারাবাহিকতা বজায় রেখে।',
        featureDescription:
            'অনুপস্থিতি এবং উপস্থিতি দক্ষতার সাথে পরিচালনা করুন এবং ট্র্যাক করুন, যথাযথ ডকুমেন্টেশন, কোম্পানির নীতি মেনে চলা এবং মসৃণ কাজের প্রবাহ ধারাবাহিকতা বজায় রাখা নিশ্চিত করুন।',
        featureParagraph:
            'আর সময়কার্ড, অতিরিক্ত কাগজপত্র এবং বিভিন্ন নথি নেই যা কর্মচারীদের উপস্থিতি চেক করতে। WebWork টাইম ট্র্যাকার ব্যবহার করে উপস্থিতি পর্যবেক্ষণ প্রক্রিয়া স্বয়ংক্রিয় করুন এবং আপনার কর্মচারীদের কাজের ঘন্টাগুলি একটি সুসংগঠিত ফরম্যাটে দেখুন।',
        featureCaption: 'আপনার কর্মচারীদের আসন্ন ছুটির বিষয়ে আগে থেকেই অবহিত থাকুন এবং আপনার দিন পরিকল্পনা করুন',
        keyPoints: [
            {
                header: 'ছুটির হিসাব এবং ব্যালেন্সের স্বয়ংক্রিয় গণনা',
                paragraph:
                    'ছুটি সংক্রান্ত হিসাব পরিচালনা করুন, নিশ্চিত করুন যে কর্মচারীরা বরাদ্দকৃত পরিমাণ অতিক্রম না করে, এবং নেতিবাচক ছুটির ব্যালেন্স প্রতিরোধ করুন।',
            },
            {
                header: 'লচনীয় ছুটির নীতি, অনুমোদন ওয়ার্কফ্লো',
                paragraph:
                    'ছুটির ক্যালেন্ডার সেটআপ করে সংস্থার লচনীয় ছুটির নীতি কনফিগার করুন। ছুটির অনুরোধের অনুমোদন বা প্রত্যাখ্যানের জন্য প্যারালেল বা সিকোয়েন্সিয়াল হায়ারার্কি সেটআপ সক্ষম করুন।',
            },
            {
                header: 'শ্রম আইন এবং কোম্পানির নীতির সাথে সামঞ্জস্য',
                paragraph:
                    'ছুটি প্রাপ্যতার সাথে সম্পর্কিত শ্রম আইন এবং বিধিমালা সম্পর্কে অবহিত থাকুন। আপনার ছুটির ব্যবস্থাপনা নীতিগুলি কোম্পানির নীতির সাথে সামঞ্জস্যপূর্ণ তা নিশ্চিত করুন।',
            },
            {
                header: 'স্ব-সেবা বিকল্প',
                paragraph:
                    'কর্মচারীদের স্ব-সেবা বিকল্প প্রদান করুন, তাদের ছুটির ব্যালেন্স চেক করতে, অনুরোধ জমা দিতে এবং তাদের অনুরোধের অবস্থা দেখতে।',
            },
        ],
        softwareDetails: [
            {
                title: 'আপনার দলের জন্য বহু এবং লচনীয় ছুটির নীতি',
                description: [
                    'আপনার নির্দিষ্ট প্রয়োজনীয়তাগুলি পূরণের জন্য লচনীয় ছুটির নীতি কনফিগারযোগ্য প্যারামিটারগুলির সাথে',
                    'বিভিন্ন কর্মচারী গ্রুপের জন্য উপযুক্ত বহু ছুটির স্কিম এবং নীতি',
                    'অসীম ছুটির প্রকার এবং ছুটি লেনদেন সমর্থিত',
                ],
                sDKeys: [
                    'এই নীতিগুলিতে বার্ষিক ছুটি, অসুস্থ ছুটি, মাতৃত্বকালীন ছুটি, শোকের ছুটি ইত্যাদি অন্তর্ভুক্ত রয়েছে।',
                    'নীতির জন্য যোগ্যতার মানদণ্ড সেট করুন',
                    'অ্যাক্রুয়াল হার, ব্যবহার নিয়ম, প্রয়োজনীয় ডকুমেন্টেশন',
                ],
            },
            {
                title: 'ছুটির আবেদন এবং অনুমোদন ওয়ার্কফ্লো জন্য স্ব-সেবা',
                description: [
                    'স্বয়ংক্রিয়করণ কর্মচারীর প্রচেষ্টাকে কমিয়ে দেয়। কর্মচারীরা ওয়েব পোর্টাল বা মোবাইল অ্যাপের মাধ্যমে ছুটির জন্য আবেদন করে',
                    'ছুটির ব্যালেন্স এবং লেনদেনের জন্য ছুটি ট্র্যাকার',
                    'বেটার ইনসাইটসের জন্য টিমের ছুটির তথ্য এবং ছুটির প্যাটার্নের ম্যানেজার ভিউ',
                    'ওয়ার্কফ্লো সহ ত্বরিত সিস্টেম এবং একটি চমৎকার কর্মচারী অভিজ্ঞতা',
                    'ইমেইল বিজ্ঞপ্তি এবং এলার্ট',
                ],
                sDKeys: [
                    'কর্মচারীরা ওয়েব পোর্টাল বা মোবাইল অ্যাপের মাধ্যমে ছুটির জন্য আবেদন করে',
                    'ছুটির ব্যালেন্স এবং লেনদেনের জন্য ছুটি ট্র্যাকার',
                    'বেটার ইনসাইটসের জন্য টিমের ছুটির তথ্য এবং ছুটির প্যাটার্নের ম্যানেজার ভিউ',
                    'ওয়ার্কফ্লো সহ ত্বরিত সিস্টেম এবং একটি চমৎকার কর্মচারী অভিজ্ঞতা',
                    'ইমেইল বিজ্ঞপ্তি এবং এলার্ট',
                ],
            },
            {
                title: 'কোম্পানির নীতি এবং শ্রম আইন মেনে চলুন',
                description:
                    'কার্যকর কর্মচারী ছুটির ব্যবস্থাপনা শুধুমাত্র শ্রম আইন মেনে চলা নিশ্চিত করে না বরং একটি ইতিবাচক কর্মস্থল সংস্কৃতিতেও অবদান রাখে। দক্ষ ছুটির নীতি বাস্তবায়ন এবং প্রযুক্তি ব্যবহার করে, সংস্থাগুলি প্রক্রিয়া সরলীকরণ করতে এবং তাদের কর্মচারীদের সময় off প্রয়োজনকে সমর্থন করতে পারে।',
                sDKeys: [
                    'বাজেটের জন্য ছুটির দায়িত্বগুলি কার্যকরভাবে পরিচালনা করুন এবং আর্থিক ঝুঁকি কমান',
                    'মাসিক, ত্রৈমাসিক বা বার্ষিক ভিত্তিতে পারফরম্যান্স ট্র্যাক করতে ব্যক্তিগত রিপোর্টের জন্য পরিসংখ্যানগত তথ্য তৈরি করুন',
                    'শ্রমশক্তি পরিকল্পনা, সম্পদ বরাদ্দ এবং কর্মচারী সম্পৃক্ততার কৌশল সম্পর্কে অবহিত থাকুন',
                    'সরকারি নিয়ম অনুযায়ী বিস্তারিত রেকর্ড এবং রিপোর্ট',
                    'বৈচিত্র্যময় কর্মশক্তির জন্য কাস্টমাইজড বিস্তৃত ছুটির তালিকা',
                ],
            },
        ],
    },
    shiftManagement: {
        featureHeader: 'শিফট ব্যবস্থাপনা',
        feaatureSubcaption:
            'কর্মশক্তি বিক্রয় কার্যক্রমের সাথে সময়সূচী করুন, গ্রাহকের চাহিদা পূরণের জন্য সর্বোত্তম স্টাফিং স্তর নিশ্চিত করুন এবং উৎপাদনশীলতা সর্বাধিক করুন',
        featureDescription:
            'কর্মঘণ্টাগুলি সংগঠিত এবং সময়সূচী করুন যা উৎপাদনশীলতা অপ্টিমাইজ করে, অপারেশনাল প্রয়োজনীয়তা পূরণ করে, বিভিন্ন সময়কালের মধ্যে কভারেজ নিশ্চিত করে',
        featureParagraph:
            'আর সময়কার্ড, অতিরিক্ত কাগজপত্র এবং বিভিন্ন নথি নেই যা কর্মচারীদের উপস্থিতি চেক করতে। WebWork টাইম ট্র্যাকার ব্যবহার করে উপস্থিতি পর্যবেক্ষণ প্রক্রিয়া স্বয়ংক্রিয় করুন এবং আপনার কর্মচারীদের কাজের ঘন্টাগুলি একটি সুসংগঠিত ফরম্যাটে দেখুন।',
        featureCaption: 'মোট কাজের ঘন্টা এবং একাধিক সেশন সম্মিলিত ভিত্তিতে আপনার কর্মচারীদের শিফট ট্র্যাক করুন',
        keyPoints: [
            {
                header: 'কর্মচারীদের কাজের সময়সূচী পরিচালনা করুন',
                paragraph:
                    'একটি পরিষ্কার এবং সুসংগঠিত শিফট সময়সূচী তৈরি করুন। আপনার ব্যবসার প্রকৃতির উপর ভিত্তি করে বিভিন্ন ধরনের শিফট নির্ধারণ করুন।',
            },
            {
                header: 'শিফট নির্ধারণ, ভূমিকা বরাদ্দ, পুনরাবৃত্ত বা কাস্টম সময়সূচী সেট করুন',
                paragraph:
                    'বিভিন্ন শিফটে প্রয়োজনীয় কর্মচারীর সংখ্যা নির্ধারণ করতে কাজের লোড এবং কাজগুলি বিশ্লেষণ করুন।',
            },
            {
                header: 'নির্মিত কমিউনিকেশন টুলস',
                paragraph:
                    'কর্মচারীদের তাদের সময়সূচীতে প্রবেশের জন্য কেন্দ্রীভূত প্ল্যাটফর্ম এবং যে কোনও আপডেট বা পরিবর্তনের জন্য একটি নির্ভরযোগ্য যোগাযোগ চ্যানেল প্রতিষ্ঠা করুন।',
            },
            {
                header: 'মাল্টি লোকেশন সময়সূচীর সাথে কেন্দ্রীভূত শিফট ব্যবস্থাপনা',
                paragraph:
                    'একই প্ল্যাটফর্ম থেকে একাধিক অবস্থানে কর্মচারীদের সময়সূচীর কেন্দ্রীভূত তত্ত্বাবধান এবং নিয়ন্ত্রণ, ম্যানেজারদের দক্ষতার সাথে শিফট বরাদ্দ করতে, উপস্থিতি ট্র্যাক করতে এবং কর্মশক্তির সম্পদ পরিচালনা করতে সক্ষম করুন।',
            },
        ],
        softwareDetails: [
            {
                title: 'অবস্থান এবং শিফটের ভিত্তিতে সময়সূচী প্রক্রিয়া স্বয়ংক্রিয় করুন',
                description: [
                    'বিভিন্ন শিফটে প্রয়োজনীয় কর্মচারীর সংখ্যা নির্ধারণ করতে কাজের লোড এবং কাজগুলি বিশ্লেষণ করুন',
                    'পিক বিজনেস আওয়ারস, মৌসুমি পরিবর্তন এবং প্রতিটি শিফটের জন্য বিশেষ প্রয়োজনীয়তাগুলি বিবেচনায় নিন। এর মধ্যে দিন শিফট, রাত শিফট, ঘূর্ণায়মান শিফট, বিভক্ত শিফট বা লচনীয় সময়সূচী অন্তর্ভুক্ত থাকতে পারে।',
                ],
                sDKeys: [
                    'কর্মচারীদের মধ্যে শিফট পরিবর্তন',
                    'কর্মচারীরা উপলব্ধ বৈশিষ্ট্য/বিকল্পের মাধ্যমে তাদের শিফট পরিবর্তন বা এক্সচেঞ্জ করতে পারে',
                    'কর্মচারীরা তাদের জন্য সেরা কাজ করে এমন শিফট এবং সময় অফ চয়ন করতে পারে',
                ],
            },
            {
                title: 'ডায়নামিক শিফট সময়সূচীর জন্য শিফট বোর্ড',
                description: [
                    'বিরতিতে এবং বিশ্রামের সময় সম্পর্কে শ্রম আইনের সাথে সম্মতি নিশ্চিত করুন',
                    'কর্মচারী ক্লান্তি প্রতিরোধ এবং উৎপাদনশীলতা বজায় রাখতে শিফট সময়সূচীতে বিরতি তৈরি করুন',
                    'আপনার শিফট ব্যবস্থাপনার নীতিগুলি আইনি প্রয়োজনীয়তার সাথে সঙ্গতিপূর্ণ কিনা তা নিশ্চিত করুন',
                ],
                sDKeys: [
                    'ব্যবহারকারী-বান্ধব সময়সূচী টুল ব্যবহার করে আপনার দলের জন্য দক্ষ কাজের সময়সূচী নির্মাণ করুন',
                    'আপনার ব্যবসার অপারেশনাল চাহিদা এবং কর্মচারীদের উপলভ্যতার সাথে মিলিয়ে শিফটগুলি কাস্টমাইজ করুন',
                    'কর্মচারীর পছন্দ এবং কাজের লোড বিবেচনায় রেখে শিফটগুলির ন্যায্য বিতরণ নিশ্চিত করুন',
                ],
            },
            {
                title: 'অদ্ভুত সময়ে কাজ করার জন্য কর্মচারীদের শিফট ভাতা উৎসাহিত করুন',
                description: [
                    'শিফট ব্যবস্থাপনা সিস্টেমগুলি পে-রোল প্রক্রিয়ার সাথে একীভূত করুন যাতে সঠিক ক্ষতিপূরণ এবং কেটে নেওয়ার ব্যবস্থা নিশ্চিত হয়',
                    'অভারটাইমের জন্য প্রক্রিয়া নির্ধারণ করুন এবং নিশ্চিত করুন যে ওভারটাইম নীতিগুলি শ্রম বিধিমালার সাথে মেলে',
                ],
                sDKeys: [
                    'একটি ক্লিকে কর্মচারীদের শিফট অনুরোধ অনুমোদন করুন',
                    'কর্মচারীরা সপ্তাহ/মাসের শুরুতে তাদের নিজস্ব শিফট চয়ন করতে পারে এবং তাদের পোর্টাল থেকে সুবিধামত অনুরোধ পাঠাতে পারে',
                    'ঘন্টাভিত্তিক বেতন বা কোম্পানির নীতির ভিত্তিতে শিফট ভাতা প্রদান করে লাভজনক সমাধান',
                ],
            },
        ],
    },
    geoFenceBasedManagement: {
        featureHeader: 'জিও ফেন্স ভিত্তিক উপস্থিতি',
        feaatureSubcaption:
            'সঠিকতা এবং দায়বদ্ধতা সর্বাধিক করা, নির্ধারিত সীমানার মধ্যে কর্মচারীদের উপস্থিতি ট্র্যাক করুন',
        featureDescription:
            'নির্ধারিত ভৌগোলিক সীমানার মধ্যে কর্মচারীদের উপস্থিতি পর্যবেক্ষণ এবং যাচাই করতে অবস্থানভিত্তিক প্রযুক্তি',
        featureCaption:
            'জিও ফেন্স ভিত্তিক উপস্থিতি আপনার উপস্থিতি ট্র্যাকিং এর সঠিকতা উন্নত করে, কারণ কর্মচারীরা কেবলমাত্র নির্ধারিত এলাকায় থাকলেই উপস্থিত হিসেবে চিহ্নিত হয়',
        keyPoints: [
            {
                header: 'নির্ধারিত জিও-ফেন্সড এলাকায় কর্মচারীদের সঠিকভাবে অবস্থান নির্ধারণ করুন',
                paragraph: 'কর্মচারীরা তাদের নির্ধারিত কর্মস্থলে উপস্থিত কিনা তা নিশ্চিত করতে সঠিক উপস্থিতি রেকর্ড।',
            },
            {
                header: 'কর্মচারীদের অবস্থানের রিয়েল-টাইম পর্যবেক্ষণ',
                paragraph:
                    'জিও-ফেন্সের মধ্যে কর্মচারীদের অবস্থান পর্যবেক্ষণের জন্য রিয়েল-টাইম ট্র্যাকিং বাস্তবায়ন করুন।',
            },
            {
                header: 'ঘন্টার জন্য নির্দিষ্ট ভৌগোলিক সীমানা নির্ধারণ করুন',
                paragraph:
                    'কর্মস্থল বা নির্ধারিত উপস্থিতির স্থানগুলির চারপাশে নির্দিষ্ট ভৌগোলিক সীমানা, যা জিও-ফেন্স নামে পরিচিত, প্রতিষ্ঠা করুন।',
            },
            {
                header: 'জিওফেন্স অ্যালার্ট',
                paragraph:
                    'কর্মচারীরা যখন নির্ধারিত জিও-ফেন্স সীমানা প্রবেশ বা ত্যাগ করে, তখন ম্যানেজার বা এইচআর কর্মীদের জানাতে সতর্কতা বা নোটিফিকেশন সেট করুন।',
            },
        ],
        softwareDetails: [
            {
                title: 'উপস্থিতি চিহ্নিতকরণে অবস্থান সীমাবদ্ধতা',
                description: [
                    'জিপিএস কোঅর্ডিনেট বা অবস্থানভিত্তিক প্রযুক্তি ব্যবহার করে নির্দিষ্ট ভৌগোলিক সীমানা প্রতিষ্ঠা। এই সীমানাগুলি এমন এলাকা উপস্থাপন করে যেখানে কর্মচারীদের কাজের জন্য উপস্থিত থাকার প্রত্যাশা করা হয়।',
                ],
                sDKeys: [
                    'নির্দিষ্ট আইপি ঠিকানা থেকে উপস্থিতি অনুমোদন করতে অপশনাল ব্যবহারকারী সীমাবদ্ধতা বৈশিষ্ট্য।',
                    'পুশ নোটিফিকেশন বৈশিষ্ট্য ব্যবহার করে নির্দিষ্ট বা সমস্ত ব্যবহারকারীর সাথে যোগাযোগ সহজতর।',
                    'দিনের কাজ এবং সময়সূচী দেখতে উপস্থিতি কর্মচারীদের সাথে কাজ লিঙ্ক করুন।',
                ],
            },
            {
                title: 'উপস্থিতি সীমাবদ্ধতা এবং বায়োমেট্রিক প্রমাণীকরণ সেট করুন',
                description: [
                    'জিও-ফেন্সড এলাকাগুলির মধ্যে কর্মচারীদের অবস্থান পর্যবেক্ষণ করতে রিয়েল-টাইম অবস্থান ট্র্যাকিং বাস্তবায়ন। এই তথ্য উপস্থিতি নিশ্চিত করতে এবং নিশ্চিত করতে ব্যবহৃত হয় যে কর্মচারীরা নির্ধারিত কর্মস্থলে নির্ধারিত সময়ে রয়েছে।',
                ],
                sDKeys: [
                    'কর্মচারীরা কেবলমাত্র তাদের প্রথম কাজের স্থানে পৌঁছানোর পর উপস্থিতি চিহ্নিত করতে পারে।',
                    'কর্মচারীরা দূরবর্তীভাবে উপস্থিতি চিহ্নিত করতে পারে; আর ভুল উপস্থিতি বা অফিসে প্রতিদিন যাওয়ার প্রয়োজন নেই।',
                    'ম্যানেজাররা রিয়েল-টাইম অবস্থান ট্র্যাক করতে পারে এবং ফোন অ্যাপ্লিকেশন বা ওয়েবের মাধ্যমে দূরবর্তীভাবে আপডেট গ্রহণ করতে পারে।',
                ],
            },
            {
                title: 'নিরাপত্তা এবং অনুমোদন উন্নত করুন',
                description: [
                    'পিন কোড, বায়োমেট্রিক প্রমাণীকরণ এবং মুখের স্বীকৃতি ব্যবহার করে জাল উপস্থিতি প্রবেশ প্রতিরোধ করতে নিরাপদ প্রমাণীকরণ ব্যবস্থা বাস্তবায়ন করুন।',
                    'কর্মচারীদের মনে করিয়ে দেওয়ার জন্য মোবাইল নোটিফিকেশন বা অ্যালার্ট সেট আপ করুন যখন তারা জিও-ফেন্সড এলাকায় প্রবেশ করে। এটি নিশ্চিত করে যে কর্মচারীরা উপস্থিতি ট্র্যাকিং সিস্টেম সম্পর্কে সচেতন।',
                ],
            },
        ],
    },
    multipleClockInOptions: {
        featureHeader: 'বহু ঘড়ি ইন অপশন',
        feaatureSubcaption:
            'ফ্লেক্সিবিলিটি এবং সুবিধা প্রদান করা, বহু ঘড়ি ইন অপশন কর্মচারীদের সময় দক্ষতার সাথে পরিচালনা করতে সক্ষম করে।',
        featureDescription:
            'কর্মচারীদের বায়োমেট্রিক স্ক্যানার, মোবাইল অ্যাপ, টাইম ক্লক এবং ওয়েব-ভিত্তিক প্ল্যাটফর্ম ব্যবহার করে তাদের কাজের সময় রেকর্ড করার জন্য ফ্লেক্সিবিলিটি প্রদান করুন।',
        featureCaption:
            'যারা দূরবর্তীভাবে কাজ করছেন তাদের জন্য সহজ ব্যবহারের সুবিধা প্রদান করুন এবং বিভিন্ন সেশন সহ একাধিক সময় চিহ্নিত করুন।',
        keyPoints: [
            {
                header: 'বহু অবস্থানের সাথে সংস্থার জন্য উপযুক্ত',
                paragraph: 'বিভিন্ন শাখার জন্য অন-ফিল্ড এবং অফ-ফিল্ড উপস্থিতি ব্যবস্থাপনার জন্য নমনীয় ঘড়ি ইন অপশন।',
            },
            {
                header: 'কর্মচারী স্ব-পরিসেবা পোর্টাল',
                paragraph:
                    'কর্মচারীদের জিও-ফেন্সের মধ্যে তাদের চেক-ইন এবং চেক-আউট পরিচালনা করতে উপস্থিতি রেকর্ডগুলির একটি স্ব-পরিসেবা পোর্টালে প্রবেশাধিকার প্রদান করুন।',
            },
            {
                header: 'ঘড়ি ইন এর জন্য বহু অপশন',
                paragraph:
                    'বায়োমেট্রিক স্ক্যান, মোবাইল অ্যাপ, আরএফআইডি কার্ড এবং মুখ শনাক্তকরণের মতো নমনীয় ঘড়ি ইন অপশন নিশ্চিত করে বিভিন্ন কর্মপরিবেশে সময়ের সঠিক ট্র্যাকিং।',
            },
            {
                header: 'সঠিক জিও-ভিত্তিক ঘড়ি ইন',
                paragraph:
                    'অফিস প্রাঙ্গণের পরিসরে কর্মচারীদের স্বয়ংক্রিয়ভাবে উপস্থিতি চিহ্নিত করার জন্য অ্যালার্ট নোটিফিকেশন সহ।',
            },
        ],
        softwareDetails: [
            {
                title: 'অন-সাইট এবং অফ-সাইট ঘড়ি ইন',
                description: [
                    'বিভিন্ন কর্মপরিবেশের জন্য, আমাদের কর্মচারী উপস্থিতি ব্যবস্থাপনা সিস্টেম একাধিক ঘড়ি ইন অপশন প্রদান করে।',
                    'কর্মচারীরা অন-সাইট, ফিল্ডে বা দূরবর্তীভাবে কাজ করুক, তারা বায়োমেট্রিক স্ক্যান, মুখ শনাক্তকরণ এবং জিও-ফেন্স ভিত্তিক স্বয়ংক্রিয় ঘড়ি ইন দ্বারা সহজে তাদের সময় লগ করতে পারে।',
                ],
            },
            {
                title: 'কর্মচারী স্ব-পরিসেবা পোর্টাল তাদের রেকর্ড চেক করার জন্য',
                description: [
                    'কর্মচারী স্ব-পরিসেবা পোর্টাল একাধিক চেক-ইন এবং চেক-আউট এর উপর নিয়ন্ত্রণ প্রদান করে।',
                    'ব্যবহারকারী-বান্ধব বৈশিষ্ট্যগুলির সাথে, কর্মচারীরা সহজেই তাদের কাজের সময় পরিচালনা করতে পারে, তা শিফট, অবস্থান বা কাজের মধ্যে পরিবর্তন হোক।',
                    'এটি কর্মচারীদের নমনীয়তা প্রদান করে এবং সময়ের সঠিক রেকর্ড বজায় রাখে, দক্ষতা বৃদ্ধি করে এবং প্রশাসনিক প্রক্রিয়াগুলিকে সহজ করে।',
                ],
            },
            {
                title: 'বহু শাখার জন্য একাধিক ঘড়ি ইন',
                description: [
                    'বহু অবস্থান জুড়ে আপনার সংস্থার গতিশীল প্রয়োজনগুলি পূরণ করুন এবং সঠিক উপস্থিতি ট্র্যাকিং এবং অপারেশনাল দক্ষতা নিশ্চিত করুন।',
                ],
            },
        ],
    },
    attendanceReport: {
        featureHeader: 'উপস্থিতি রিপোর্ট',
        feaatureSubcaption: 'একটি রিপোর্টে দলের প্রতিশ্রুতি এবং সময়ানুবর্তিতার একটি অন্তর্দৃষ্টিপূর্ণ প্রতিফলন।',
        featureDescription:
            'নির্দিষ্ট সময়সীমার মধ্যে কর্মচারীর উপস্থিতি ডেটা, কাজের ঘণ্টা, অনুপস্থিতি, দেরি আসা এবং আগে চলে যাওয়া সারসংক্ষেপ করুন।',
        featureCaption:
            'বিভিন্ন শাখার জন্য স্বয়ংক্রিয় রিপোর্ট তৈরি করুন এবং দৈনিক, সাপ্তাহিক, মাসিক ভিত্তিতে ৪০+ টেমপ্লেটের সাথে পান।',
        keyPoints: [
            {
                header: 'স্বয়ংক্রিয় উপস্থিতি বিবরণ: লগ-ইন সময়, দেরি সময়, মোট কাজের ঘণ্টা, মোট সেশন',
                paragraph:
                    'মোট কর্মদিবস, মোট ঘণ্টা কাজ এবং অতিরিক্ত সময় সহ উপস্থিতির একটি সামগ্রিক সারসংক্ষেপ প্রদান করুন।',
            },
            {
                header: 'বহু শাখার রিপোর্ট',
                paragraph:
                    'বিভিন্ন শাখার উপস্থিতি রিপোর্ট দূরবর্তীভাবে অ্যাক্সেস করুন এবং কাস্টমাইজেবল বিভাগে প্রবেশ করুন।',
            },
            {
                header: 'অতিরিক্ত সময় এবং ছুটির ব্যালেন্স',
                paragraph:
                    'প্রত্যেক কর্মচারীর জন্য ছুটির ব্যালেন্স প্রদর্শন করুন, যেমন ছুটির দিন, অসুস্থতা ছুটি এবং অন্যান্য ধরনের ছুটি যা অর্জিত এবং ব্যবহার করা হয়েছে।',
            },
            {
                header: 'কাস্টমাইজড উপস্থিতি রিপোর্ট',
                paragraph:
                    'নির্দিষ্ট মানদণ্ড অনুযায়ী রিপোর্ট কাস্টমাইজ করার সুযোগ প্রদান করুন, যেমন বিভাগ, দল, বা ব্যক্তিগত কর্মচারী।',
            },
        ],
        softwareDetails: [
            {
                title: 'আপনার সংস্থার কাঠামোর ভিত্তিতে রিপোর্ট তৈরি করুন।',
                description: [
                    'নিয়মিতভাবে তৈরি এবং বিশ্লেষিত উপস্থিতি রিপোর্ট মূল্যবান অন্তর্দৃষ্টি প্রদান করতে পারে, যা কর্মী ব্যবস্থাপনা উন্নত করতে, প্রবণতা চিহ্নিত করতে এবং সংস্থার নীতির সাথে সামঞ্জস্য নিশ্চিত করতে সহায়ক।',
                    'রিপোর্টের বিশদগুলি সংস্থার প্রয়োজন এবং কাঠামোর উপর নির্ভর করবে।',
                ],
                sDKeys: [
                    'বিভিন্ন ব্যবহারকারীর পক্ষ থেকে সংগৃহীত তথ্য স্বয়ংক্রিয় বিশ্লেষণ দিতে সংকলিত হয়, যা রিপোর্ট ম্যানুয়ালি তৈরি করার সময় এবং সম্পদ কমায়।',
                    'ব্যবহারকারীরা কয়েকটি ক্লিকের মধ্যে তাদের প্রয়োজনীয় বিশ্লেষণ নির্বাচন করতে পারে এবং যে কোনও সময়ে সাবস্ক্রাইব করতে পারে।',
                    'একটি নমুনা বিশ্লেষণ রিপোর্ট ডাউনলোড করা যেতে পারে এবং কাঙ্খিত রিপোর্টের একটি বাস্তব উপস্থাপনায় দেখা যেতে পারে।',
                ],
            },
            {
                title: 'সহজে কাস্টমাইজেবল রিপোর্ট পান।',
                description: [
                    'অননুমোদিত অনুপস্থিতি, অতিরিক্ত দেরি, বা অনুমোদিত অতিরিক্ত সময়ের মত যে কোনও ব্যতিক্রম বা অনিয়ম চিহ্নিত এবং হাইলাইট করুন।',
                    'উপস্থিতি প্রবণতা এবং প্যাটার্নগুলি চিত্র বা গ্রাফের মাধ্যমে দৃশ্যমানভাবে উপস্থাপন করুন। এটি আউটলায়ার এবং প্রবণতাগুলি দ্রুত চিহ্নিত করতে সহায়ক হতে পারে।',
                ],
                sDKeys: [
                    'ব্যবহারকারী বিভিন্ন উপায়ে ডেটা অন্তর্দৃষ্টি থেকে উপকার পেতে পারে যেমন পেরোল প্রক্রিয়াকরণ, কর্মক্ষমতা মূল্যায়ন, মূল্যায়ন, ক্ষতিপূরণ ইত্যাদি।',
                    'রিপোর্টগুলি কর্তৃপক্ষের স্তরের উপর ভিত্তি করে অন্যান্য ব্যবহারকারীদের সাথে ভাগ করা যেতে পারে।',
                    'সংস্থা সহজে কোনো ম্যানুয়াল হস্তক্ষেপ ছাড়াই তাদের দলের কাজের অবস্থা এবং কর্মক্ষমতার বিষয়ে একত্রিতভাবে সচেতন হতে পারে।',
                ],
            },
            {
                title: 'ম্যানুয়াল রিপোর্টে আপনার সময় কমান।',
                description: [
                    'কর্মচারীদের নাম, পরিচয় নম্বর এবং বিভাগীয় সম্পর্কিত মৌলিক বিবরণ অন্তর্ভুক্ত করুন, যা উপস্থিতির ডেটাকে প্রসঙ্গ প্রদান করে।',
                    'রিপোর্টের দ্বারা আচ্ছাদিত তারিখ এবং সময়ের পরিসর পরিষ্কারভাবে নির্ধারণ করুন। এটি ব্যবহারকারীদের ডেটার সময়সীমা বুঝতে সহায়ক।',
                    'মোট কর্মদিবস, মোট ঘণ্টা কাজ এবং অতিরিক্ত সময় সহ উপস্থিতির একটি সামগ্রিক সারসংক্ষেপ প্রদান করুন।',
                ],
            },
        ],
    },
    workforceTracking: {
        featureHeader: 'কর্মশক্তি ট্র্যাকিং',
        featureDescription: 'কর্মচারীদের কার্যক্রম, উৎপাদনশীলতা এবং উপস্থিতি সিস্টেম্যাটিক মনিটরিং এবং ব্যবস্থাপনা।',
        featureParagraph:
            'আর কোন টাইমকার্ড, অতিরিক্ত কাগজপত্র, এবং বিভিন্ন নথি কর্মচারীদের উপস্থিতি যাচাই করার জন্য। WebWork টাইম ট্র্যাকার দিয়ে উপস্থিতি মনিটরিং প্রক্রিয়া অটোমেট করুন এবং আপনার কর্মচারীদের কাজের ঘণ্টাগুলির শুরু এবং শেষ একটি সু-সংগঠিত ফরম্যাটে দেখুন।',
        featureCaption:
            'একটি সংস্থার কর্মশক্তির বিভিন্ন দিক মনিটর এবং পরিচালনা করুন, যার মধ্যে কর্মচারীর উপস্থিতি, কাজের ঘণ্টা, উৎপাদনশীলতা এবং কর্মক্ষমতা অন্তর্ভুক্ত।',
        keyPoints: [
            {
                header: 'টাইমলাইন ভিত্তিক উপস্থিতি এবং আন্দোলনের ট্র্যাকিং',
                paragraph:
                    'ঘড়ি ইন এবং ঘড়ি আউটের মত ঘটনাগুলি মনিটর করুন এবং কর্মচারীদের তাদের অপারেশন, কর্মক্ষমতা মেট্রিক্স বা ঘটনার অন্তর্দৃষ্টি প্রদান করুন।',
            },
            {
                header: 'ডেটা ব্যবস্থাপনার জন্য কাস্টম ফর্ম',
                paragraph:
                    'মিটিংয়ের মিনিটের জন্য ডেটা সংরক্ষণ করুন এবং প্রবণতা এবং ডেটা-চালিত সিদ্ধান্ত গ্রহণ ট্র্যাক করতে মিটিং এবং টাস্কের ডেটা ট্র্যাক করুন।',
            },
            {
                header: 'গ্রাহক এবং লিড ব্যবস্থাপনা',
                paragraph:
                    'কর্মচারীদের দ্বারা সম্পন্ন কাজের পরিমাণের ভিত্তিতে উৎপাদনশীলতা ট্র্যাক করুন এবং মূল পারফরম্যান্স ইন্ডিকেটরগুলি পরিমাপ করুন।',
            },
            {
                header: '正確 ক্ষতিপূরণ দাবি করার জন্য মোট দূরত্বের হিসাব',
                paragraph:
                    'দৈনিক, সাপ্তাহিক এবং মাসিক মেট্রিক্সের ভিত্তিতে মাঠের কাজের জন্য কর্মচারীদের সঠিকভাবে traveled দূরত্ব ট্র্যাক করুন এবং যাত্রা এবং অবস্থান অনুযায়ী খরচ পরিশোধ করুন।',
            },
        ],
        softwareDetails: [
            {
                title: 'কাজের ঘণ্টা মনিটর করার জন্য সময় ট্র্যাকিং সিস্টেম প্রয়োগ করুন।',
                description: [
                    'কর্মচারীরা কাজ এবং প্রকল্পগুলিতে ব্যয় করা সময় মনিটর করার জন্য সময় ট্র্যাকিং সমাধানগুলি প্রয়োগ করা।',
                    'এটি সম্পদ ব্যবহারের বোধগম্যতা এবং সময় ব্যবস্থাপনা উন্নত করতে সহায়ক হতে পারে।',
                    'প্রকল্প ব্যবস্থাপনা সিস্টেম ব্যবহার করে প্রকল্পের অগ্রগতি ট্র্যাক করুন, কাজ বরাদ্দ করুন এবং ডেডলাইন মনিটর করুন।',
                    'এটি প্রকল্প কর্মপ্রবাহগুলি অপ্টিমাইজ করতে এবং সময়মতো সম্পন্ন নিশ্চিত করতে সহায়ক।',
                ],
            },
            {
                title: 'প্রাসঙ্গিক মূল কর্মক্ষমতা সূচক মনিটর করুন।',
                description: [
                    'আপনার সংস্থার লক্ষ্য অনুযায়ী মূল কর্মক্ষমতা সূচকগুলি সংজ্ঞায়িত এবং ট্র্যাক করা।',
                    'এটি বিক্রয় লক্ষ্য, গ্রাহক সন্তুষ্টি মেট্রিক্স, বা অন্যান্য কর্মক্ষমতা সূচক অন্তর্ভুক্ত করতে পারে।',
                ],
                sDKeys: [
                    'বিক্রয় কর্মক্ষমতা: মাসিক/ত্রৈমাসিক বিক্রয় রাজস্ব, বন্ধ হওয়া চুক্তির সংখ্যা, বিক্রয় রূপান্তর হার, গড় চুক্তির আকার, বিক্রয় পাইপলাইন মান।',
                    'গ্রাহক সেবা: গ্রাহক সন্তুষ্টি স্কোর (CSAT), নিট প্রোমোটার স্কোর (NPS), প্রথম প্রতিক্রিয়া সময়, গড় সমাধান সময়, গ্রাহক ধরে রাখার হার, টিকিট ভলিউম।',
                    'অপারেশন/উত্পাদন: উৎপাদন আউটপুট/থ্রুপুট, গুণমানের মেট্রিক্স (ত্রুটি হার, ত্রুটি হার), সময়মত ডেলিভারি কর্মক্ষমতা, ইনভেন্টরি টার্নওভার রেশিও, যন্ত্রপাতির ডাউনটাইম, কর্মচারী ব্যবহার হার।',
                    'সাধারণ: কর্মচারী উৎপাদনশীলতা (প্রতি ঘন্টায় আউটপুট), উপস্থিতি এবং সময়ানুবর্তিতা, লক্ষ্য অর্জনের হার, কর্মচারীর প্রতিক্রিয়া এবং কর্মক্ষমতা পর্যালোচনা।',
                ],
            },
            {
                title: 'আপনার কর্মশক্তির প্রবণতার রিপোর্ট তৈরি করুন।',
                description: [
                    'কর্মশক্তি প্রবণতা, কর্মক্ষমতা এবং উন্নতির ক্ষেত্রগুলি নিয়ে অন্তর্দৃষ্টি লাভ করতে বিশ্লেষণ এবং রিপোর্টিং বৈশিষ্ট্যগুলি সংহত করা।',
                    'কাস্টমাইজড রিপোর্টগুলি সিদ্ধান্ত গ্রহণে সহায়ক হতে পারে।',
                ],
                sDKeys: [
                    'জনসংখ্যার রচনার তুলনা সম্পূর্ণ কর্মশক্তি বা শিল্পের বेंচমার্কের সাথে এবং সময়ের সাথে কোনও জনসংখ্যার প্রবণতা বা পরিবর্তন চিহ্নিত করা।',
                    'বিভিন্ন মাঠের দলের, অঞ্চলের বা সময়ের ভিত্তিতে কর্মক্ষমতা মেট্রিক্সের তুলনা।',
                    'মাঠের শক্তি কার্যক্রমের ভূ-স্থানিক মানচিত্রণ বিতরণ এবং কভারেজ এলাকা দৃশ্যমান করতে।',
                    'ভৌগলিক অঞ্চল বা অঞ্চল দ্বারা বিক্রয় বা সেবা কর্মক্ষমতার বিশ্লেষণ। সম্প্রসারণ বা অপ্টিমাইজেশনের জন্য উচ্চ-পটেনশিয়াল এলাকা চিহ্নিত করা।',
                ],
            },
        ],
    },
    fieldSalesAnalytics: {
        featureHeader: 'ফিল্ড সেলস অ্যানালিটিক্স',
        featureDescription:
            'বিক্রয় দলের মাঠে প্রধান মেট্রিকগুলি যেমন বিক্রয় রাজস্ব, গ্রাহক ইন্টারঅ্যাকশন এবং ভূগোলগত প্রবণতাগুলি ট্র্যাক এবং বিশ্লেষণ করুন।',
        featureParagraph:
            'আর কোন টাইমকার্ড, অতিরিক্ত কাগজপত্র, এবং বিভিন্ন নথি কর্মচারীদের উপস্থিতি যাচাই করার জন্য। WebWork টাইম ট্র্যাকার দিয়ে উপস্থিতি মনিটরিং প্রক্রিয়া অটোমেট করুন এবং আপনার কর্মচারীদের কাজের ঘণ্টাগুলির শুরু এবং শেষ একটি সু-সংগঠিত ফরম্যাটে দেখুন।',
        featureCaption:
            'মাঠ বিক্রয় কার্যক্রম সম্পর্কিত ডেটার সংগ্রহ, বিশ্লেষণ এবং ব্যাখ্যার বিশ্লেষণ করুন যাতে অন্তর্দৃষ্টি লাভ করতে এবং সুসংগত সিদ্ধান্ত নিতে সাহায্য করে।',
        keyPoints: [
            {
                header: 'বিক্রয় এবং লিডের জন্য মূল কর্মক্ষমতা সূচক',
                paragraph:
                    'মূল কর্মক্ষমতা সূচকগুলি ডেটা-চালিত সিদ্ধান্ত নিতে সহায়ক হতে পারে যা পরবর্তী সময়ে উৎপাদনশীল সিদ্ধান্ত নেওয়ার জন্য বা কৌশল পুনর্নির্মাণের জন্য সাহায্য করবে।',
            },
            {
                header: 'কাস্টম রিপোর্ট',
                paragraph:
                    'বিক্রয়, লিড এবং গ্রাহক অর্জনের উপর দৈনিক, সাপ্তাহিক বা মাসিক ভিত্তিতে রিপোর্ট অ্যাক্সেস করা যেতে পারে। এটি ব্যক্তির উৎপাদনশীলতা এবং দক্ষতায় সাহায্য করতে পারে।',
            },
            {
                header: 'জিওফেন্সের মাধ্যমে বিক্রয় ভিজিট যাচাই করুন এবং স্ট্রিমলাইন করুন',
                paragraph:
                    'নির্ভুলতার জন্য জিওফেন্স ভিত্তিক চেক-ইন দিয়ে ভিজিট যাচাই করুন। শেয়ার করার সময় ক্যামেরা ছবির সাথে অবস্থানের কোঅর্ডিনেটগুলি সংযুক্ত করা যেতে পারে।',
            },
            {
                header: 'বিট রুট কার্যকারিতা মাপুন',
                paragraph:
                    'কর্মচারীদের ক্লায়েন্ট ভিজিটের জন্য পরিকল্পনা ভিজিট বা বিট পরিকল্পনার জন্য বিট রুট ব্যবহার করা যেতে পারে। নেভিগেশন অপ্টিমাইজ করতে সরাসরি ম্যাপে আপনার বিট পরিকল্পনাটি ইন্টিগ্রেট করুন।',
            },
        ],
        softwareDetails: [
            {
                title: 'উন্নত বিক্রয় পারফরম্যান্সের জন্য প্রতিদিনের ফিল্ড অন্তর্দৃষ্টি।',
                description: [
                    'ডেটা বিশ্লেষণ ভিত্তিক সমসাময়িক ই-রিপোর্ট তৈরির বৈশিষ্ট্য বিশ্বাসযোগ্য এবং ব্যবহারকারী-বান্ধব সহজ অ্যাক্সেস এবং ব্যবহারের জন্য।',
                    'একজন বিক্রয় ব্যবস্থাপক হিসেবে আপনি বিভিন্ন রিপোর্টগুলির উপর একটি পরিষ্কার নজর পাবেন যা আপনার ইমেইল ঠিকানায় পাঠানো হবে অথবা আপনি আপনার ড্যাশবোর্ড অন্তর্দৃষ্টিতে দেখতে পারবেন।',
                    'কিছু উল্লেখযোগ্য রিপোর্ট হল মাসিক রিপোর্ট, শাখার অবস্থান ভিত্তিক রিপোর্ট, কর্মচারী-নির্দিষ্ট রিপোর্ট ইত্যাদি।',
                ],
            },
            {
                title: 'কর্মক্ষেত্রের উপস্থিতি সম্পর্কে সামগ্রিক দৃশ্য',
                description: [
                    'কর্মচারীর মাঠে উপস্থিতি এবং মাঠ ভিজিটের উপর সামগ্রিক দৃশ্য',
                    'মাঠে কর্মশক্তির উপস্থিতি এবং মিটিংয়ের উপর সম্পূর্ণ নজর।',
                    'ফিল্ড উপস্থিতি রিপোর্টে, আপনি মাঠের উপস্থিতি মার্কিং সময়, অবস্থান, দেরি উপস্থিতি সময়, কাজের ঘণ্টা ইত্যাদি সম্পর্কে ডেটা পাবেন।',
                    'একইভাবে, আমরা আপনাকে মাঠ ভিজিট চেক-ইন/আউট রিপোর্টও সরবরাহ করি। এতে মাঠের ভিজিট সম্পর্কিত ডেটা যেমন লগইন/আউট সময়, চেক-ইন/আউট সময়, মিটিংয়ের অবস্থান, যাতায়াতের দূরত্ব, মিটিং ফলাফল নোট ইত্যাদি অন্তর্ভুক্ত থাকে।',
                ],
            },
            {
                title: 'ডেটা বিশ্লেষণ ভিত্তিক রিপোর্ট প্রস্তুত করা',
                description: [
                    'মাসিক ই-রিপোর্ট সাবস্ক্রাইব করুন এবং ডাউনলোড করুন।',
                    'আপনি যখন চান তখন মাসিক ই-রিপোর্ট সাবস্ক্রাইব এবং ডাউনলোড করতে পারেন।',
                    'পিডিএফ এবং এক্সেল ফাইল ফরম্যাটে উপলব্ধ। একটি সম্পূর্ণ রিপোর্টের স্তূপ আপনার জন্য উপলব্ধ যা একটি কর্মচারীর বাহ্যিক ক্রিয়াকলাপ সম্পর্কে বিস্তারিত তথ্য সরবরাহ করে যা ম্যানুয়ালভাবে একটি কঠিন কাজ হতে পারে।',
                ],
            },
        ],
    },
    locationBasedAttendance: {
        featureHeader: 'অবস্থান ভিত্তিক উপস্থিতি',
        featureDescription:
            'জিওফেন্স ভিত্তিক উপস্থিতি নিশ্চিত করে যে কর্মচারীরা কেবলমাত্র পূর্বনির্ধারিত ভূগোলগত সীমানার মধ্যে থাকা অবস্থায় কপিক করতে বা আউট করতে পারে।',
        featureCaption:
            'অবস্থান ভিত্তিক উপস্থিতি শারীরিক অবস্থান অনুযায়ী কর্মচারীদের উপস্থিতি পর্যবেক্ষণ এবং রেকর্ড করার জন্য সঠিকতা নিশ্চিত করে।',
        keyPoints: [
            {
                header: 'জিওফেন্সিং',
                paragraph:
                    'নির্দিষ্ট অফিস স্থানের চারপাশে জিওফেন্স ভিত্তিক ভার্চুয়াল সীমানা বাস্তবায়ন করুন। একটি নির্ধারিত এলাকায় প্রবেশ বা প্রস্থান করার ভিত্তিতে উপস্থিতি অটোমেট করার ক্ষেত্রে সহায়তা করবে।',
            },
            {
                header: 'প্রমাণীকরণ এবং নিরাপত্তা',
                paragraph:
                    'পিন, বায়োমেট্রিক, বা মুখাবয়ব উপস্থিতি ব্যবহার করে উপস্থিতি ডেটার সঠিকতা এবং নিরাপত্তা নিশ্চিত করতে প্রমাণীকরণ পদ্ধতি।',
            },
            {
                header: 'রিয়েল-টাইম আপডেট',
                paragraph: 'উপস্থিতি রেকর্ড দ্রুত আপডেট নিশ্চিত করুন, সময়মতো ব্যবস্থাপনা সিদ্ধান্তের অনুমতি দেয়।',
            },
            {
                header: 'রিমোট কাজের বিবেচনাগুলি',
                paragraph: 'রিমোট কর্মচারীদের জন্য উপস্থিতি ট্র্যাকিংয়ের বিকল্প পদ্ধতিগুলি বিবেচনা করুন।',
            },
        ],
        softwareDetails: [
            {
                title: 'কর্মশক্তির সময় এবং অবস্থান',
                description: [
                    'আপনার কর্মচারীরা কখন এবং কোথায় কাজ করে তা জানুন',
                    'আপনার কর্মচারীদের তাদের মোবাইল ফোনে অনুমতি দেওয়ার নির্দেশ দিন যাতে এটি সময় এবং অবস্থান ট্র্যাক করতে পারে।',
                    'একবার অনুমতি দেওয়া হলে, ট্র্যাকার একটি ট্যাপ দিয়ে কাজ শুরু করতে পারে।',
                ],
            },
            {
                title: 'লাইভ রিপোর্টিং টু',
                description: [
                    'অবস্থান ভিত্তিক কর্মচারী উপস্থিতি সিস্টেম বাস্তবায়ন সঠিকতা উন্নত করতে এবং উপস্থিতি ব্যবস্থাপনা সহজ করতে পারে। তবে, কর্মচারীদের গোপনীয়তার বিবেচনাগুলি সঙ্গে উপকারিতা সামঞ্জস্য করা গুরুত্বপূর্ণ এবং সিস্টেমের বাস্তবায়ন ও ব্যবহারের বিষয়ে স্বচ্ছভাবে যোগাযোগ করা উচিত।',
                ],
            },
            {
                title: 'জিওফেন্স ভিত্তিক সতর্কতা',
                description: [
                    'অস্বাভাবিক উপস্থিতি প্যাটার্ন বা অমিলের জন্য স্বয়ংক্রিয় সতর্কতা সেট আপ করুন। এটি অনুমোদিত চেক-ইন বা চেক-আউটের মতো সম্ভাব্য সমস্যা চিহ্নিত করতে সহায়ক।',
                    'উপস্থিতি প্যাটার্ন, প্রবণতা এবং ব্যতিক্রমের অন্তর্দৃষ্টি লাভ করতে রিপোর্টিং এবং বিশ্লেষণ বৈশিষ্ট্যগুলি বাস্তবায়ন করুন।',
                ],
                sDKeys: [
                    'মূল্যবান সম্পত্তি যেমন যানবাহন বা যন্ত্রপাতি নির্ধারিত এলাকায় প্রবেশ বা প্রস্থান করলে সতর্কতা পান, চুরি বা অনুমোদিত ব্যবহার প্রতিরোধ করতে সহায়ক।',
                    'মাঠের কর্মীদের নিরাপত্তা নিশ্চিত করতে বিপজ্জনক এলাকাগুলির বা দূরবর্তী কাজের সাইটগুলির চারপাশে জিওফেন্স সেট আপ করুন, কর্মচারীরা নিষিদ্ধ অঞ্চলে প্রবেশ করলে সতর্কতা ট্রিগার করুন।',
                    'একটি ডিভাইস যখন একটি জিওফেন্স করা এলাকায় প্রবেশ বা প্রস্থান করে, সিস্টেম পূর্বনির্ধারিত সতর্কতা বা বিজ্ঞপ্তি ট্রিগার করে।',
                    'সিস্টেম বাস্তব সময়ে ট্র্যাক করা ডিভাইসগুলির অবস্থান পর্যবেক্ষণ করে এবং চেক করে যে তারা কোনও নির্ধারিত জিওফেন্সের সাথে অতিক্রম করে কিনা।',
                ],
            },
        ],
    },
    liveReporting: {
        featureHeader: 'লাইভ রিপোর্টিং',
        featureDescription:
            'উপস্থিতি, সম্পন্ন টাস্ক অথবা প্রকল্পের অগ্রগতি সহ বিভিন্ন কাজের দিকের রিয়েল-টাইম ট্র্যাকিং এবং বিশ্লেষণ।',
        featureCaption:
            'কর্মচারীদের কাজ, মিটিং এর মিনিট, ভিজিট বৈধতা সম্পর্কে জানতে থাকুন এবং ডেটার ভিত্তিতে সিদ্ধান্ত নিন।',
        keyPoints: [
            {
                header: 'ইভেন্ট ভিত্তিক বিজ্ঞপ্তি',
                paragraph:
                    'অ্যাডমিন বা ম্যানেজার তাদের প্রাপ্ত বিজ্ঞপ্তিগুলি সাবস্ক্রাইব বা বেছে নিতে পারেন। এটি তাদের প্রকৃতপক্ষে যা প্রাপ্ত করতে চান সে সম্পর্কে মনোনিবেশ করতে সাহায্য করে।',
            },
            {
                header: 'উপস্থিতি এবং ছুটি সম্পর্কে অবহিত থাকুন',
                paragraph: 'এক ধাপ এগিয়ে থাকুন এবং দিনের পরিকল্পনা করতে সহায়ক হবার জন্য হেডকাউন্ট জানুন।',
            },
            {
                header: 'সব অনুমোদন ট্র্যাক করুন',
                paragraph:
                    'ছুটি থেকে শুরু করে পুনরায় অর্থপ্রদান পর্যন্ত সব অনুমোদন ট্র্যাক করুন এবং পরে সেগুলি দেখুন।',
            },
            {
                header: 'ফর্ম এবং MOM এর সাথে আপডেটেড থাকুন',
                paragraph:
                    'কাস্টম ফর্মগুলি একটি মিটিংয়ের জন্য নির্দিষ্ট ডেটা বের করতে সাহায্য করতে পারে যা দূরবর্তীভাবে অ্যাক্সেস করা যেতে পারে।',
            },
        ],
        softwareDetails: [
            {
                title: 'রিয়েল-টাইমে ফিল্ড ফোর্স লোকেট করুন।',
                description: [
                    'আপনার ফিল্ড স্টাফকে রিয়েল-টাইমে লোকেট করুন।',
                    'আপনার ফিল্ড কর্মচারীদের রিয়েল-টাইমে চলার সময় তাত্ক্ষণিক অবস্থান বিবরণ পান।',
                    'Treant আপনাকে আপনার ফিল্ড কর্মচারীদের মোবাইল GPS ব্যবহার করে অনলাইন এবং অফলাইনে লোকেট এবং ট্র্যাক করতে সক্ষম করে। আপনার ফিল্ড স্টাফের প্রতিটি পদক্ষেপে আপডেটেড থাকুন এবং একটি স্বচ্ছ কাজের পরিবেশ বজায় রাখুন।',
                ],
            },
            {
                title: 'আপনার কর্মচারীদের ফিল্ড তথ্যের সাথে আপডেটেড থাকুন।',
                description: [
                    'আপনার কর্মচারীদের ফিল্ড তথ্যের সাথে আপডেটেড থাকুন।',
                    'ফিল্ড স্টাফের ফিল্ড তথ্য স্বয়ংক্রিয়ভাবে আপনার ড্যাশবোর্ডের সাথে সিঙ্ক হবে।',
                    'আপনার কর্মচারীদের বিবরণ যেমন ব্যাটারি শতাংশ, GPS, ইন্টারনেট অন/অফ সময়ের দৃশ্যমানতা, মাঠে তাদের কাটানো আদর্শ সময় আপনাকে তাত্ক্ষণিকভাবে রিয়েল-টাইমে জানানো হবে।',
                ],
            },
            {
                title: 'কর্মচারীদের পুনরায় অর্থপ্রদান এবং ভাতা ডেটা অটোমেট করুন।',
                description: [
                    '正確 ডেটার জন্য AI চালিত অবস্থান ইতিহাস।',
                    'আপনার কর্মচারীদের অবস্থান ডেটা কৃত্রিম বুদ্ধিমত্তার দ্বারা চালিত হবে যাতে সঠিক রুট এবং ট্র্যাকিং তথ্য সরবরাহ করা যায়। লাইভ ট্র্যাকিং ডেটা এবং অন্যান্য অবস্থান ইতিহাস নিয়মিত অন্তরালে আপনার মেইলবক্সে অটোমেট করা হবে যাতে মাঠের ওপর এবং বাইরে কী ঘটছে তা গভীরভাবে দেখা যায়।',
                ],
            },
        ],
    },
    aIBasedOptimization: {
        featureHeader: 'AI ভিত্তিক অপ্টিমাইজেশন',
        featureDescription:
            'ফিল্ড প্রতিনিধিরা বা বিক্রয় কর্মীদের জন্য রুট পরিকল্পনা স্বয়ংক্রিয় এবং অপ্টিমাইজ করুন AI বিট রুট ভিত্তিতে।',
        featureCaption:
            'AI ভিত্তিক অ্যালগরিদম দিয়ে দিনের জন্য রুটগুলি অপ্টিমাইজ করুন এবং আপনার পূর্ববর্তী সিজনের রিপোর্টের সাথে তুলনা করুন।',
        keyPoints: [
            {
                header: 'রিয়েল-টাইম ডেটা ইন্টিগ্রেশন',
                paragraph:
                    'লাইভ ট্রাফিক আপডেট, আবহাওয়ার পরিস্থিতি এবং রাস্তার বন্ধের মতো রিয়েল-টাইম ডেটা সোর্সগুলি ব্যবহার করে বর্তমান পরিস্থিতির উপর ভিত্তি করে রুটগুলি গতিশীলভাবে সমন্বয় করুন, যা কার্যকর দৈনিক পরিকল্পনায় সহায়ক।',
            },
            {
                header: 'রুট পরিকল্পনার জন্য ব্যবহারকারী ইন্টারফেস',
                paragraph:
                    'অপ্টিমাইজড রুটগুলি দেখুন এবং প্রয়োজনীয় সামঞ্জস্য করুন। এটি একটি মোবাইল অ্যাপ্লিকেশন বা ওয়েব-ভিত্তিক প্ল্যাটফর্মের অংশ হতে পারে।',
            },
            {
                header: 'পণ্যের মেট্রিক্স',
                paragraph: 'AI সিস্টেম বিভিন্ন বিট বা রুটের বিশেষ বৈশিষ্ট্যগুলি বিবেচনায় নেয়।',
            },
            {
                header: 'ম্যাপিং এবং জিওস্পেশিয়াল ডেটা',
                paragraph:
                    'রাস্তা নেটওয়ার্ক, ট্রাফিক পরিস্থিতি এবং অন্যান্য অবস্থান সম্পর্কিত তথ্য বুঝতে ম্যাপিং পরিষেবা এবং জিওস্পেশিয়াল ডেটা ব্যবহার করুন।',
            },
        ],
        softwareDetails: [
            {
                title: 'রিয়েল-টাইম আপডেটের সাথে রুট অপ্টিমাইজেশন',
                description: [
                    'ব্যক্তি বা যানবাহনের জন্য সবচেয়ে কার্যকর রুটগুলি অপ্টিমাইজ এবং পরিকল্পনা করুন, যেমন ডেলিভারি ট্রাক, বিক্রয় প্রতিনিধি, বা ফিল্ড সার্ভিস প্রযুক্তিবিদ, যারা তাদের নিয়মিত কার্যক্রমের অংশ হিসেবে নির্দিষ্ট বিট বা রুট অনুসরণ করে।',
                    'এই অ্যাপ্লিকেশনটি দক্ষতা বৃদ্ধি, ভ্রমণ সময় হ্রাস এবং অপারেশনাল খরচ কমাতে সাহায্য করতে পারে।',
                ],
                sDKeys: [
                    'GPS ডিভাইস, ট্রাফিক রিপোর্ট, আবহাওয়ার আপডেট এবং যানবাহন সেন্সর সহ বিভিন্ন উৎস থেকে ডেটা সংগ্রহ করা।',
                    'দ্রুত প্রতিক্রিয়া সময় এবং সময়মতো আগমনের সাথে গ্রাহক সন্তুষ্টি বাড়ানো, যা সেবা-ভিত্তিক ব্যবসায়ের জন্য গুরুত্বপূর্ণ।',
                ],
            },
            {
                title: 'গোপনীয়তার বিষয়গুলি এবং মেনে চলার প্রতি যত্নশীল মনোযোগ প্রয়োজন',
                description: [
                    'নিশ্চিত করুন যে AI-ভিত্তিক সিস্টেম স্থানীয় বিধিমালা মেনে চলে, বিশেষ করে গোপনীয়তা, ডেটা সুরক্ষা এবং পরিবহন আইন সম্পর্কিত।',
                    'নতুন ডেটা এবং প্রতিক্রিয়া ভিত্তিতে AI অ্যালগরিদমগুলি নিয়মিত আপডেট এবং উন্নত করুন, বিট রুটগুলির ক্রমাগত অপ্টিমাইজেশনের লক্ষ্য রাখুন।',
                ],
            },
            {
                title: 'রুট পরিকল্পনার দক্ষতার জন্য অন্যান্য সিস্টেমের সাথে একীকরণ',
                description: [
                    'ঐতিহাসিক ডেটা বিশ্লেষণ এবং ভবিষ্যতের প্যাটার্নগুলির পূর্বাভাস দিতে মেশিন লার্নিং অ্যালগরিদম ব্যবহার করুন।',
                    'এটি ট্রাফিক পরিস্থিতি, ডেলিভারি সময় এবং অন্যান্য কারণগুলির পূর্বাভাস দিতে সাহায্য করতে পারে যা রুট পরিকল্পনায় প্রভাব ফেলে।',
                ],
            },
        ],
    },
    leadManagement: {
        featureHeader: 'লিড ম্যানেজমেন্ট',
        featureDescription:
            'নির্দিষ্ট সময়ের মধ্যে কর্মচারীর উপস্থিতি ডেটা, কাজের ঘণ্টা, অনুপস্থিতি, দেরি করে আগমন এবং প্রারম্ভিক প্রস্থান সারাংশ করুন।',
        featureCaption:
            'বিভিন্ন শাখার জন্য স্বয়ংক্রিয় রিপোর্ট তৈরি করুন এবং দৈনিক, সাপ্তাহিক, বা মাসিকভাবে ৪০+ টেমপ্লেটের সাহায্যে প্রাপ্ত করুন।',
        keyPoints: [
            {
                header: 'স্বয়ংক্রিয় উপস্থিতির বিস্তারিত: লগ-ইন সময়, দেরি সময়, মোট কাজের ঘণ্টা, মোট সেশন',
                paragraph:
                    'উপস্থিতির সামগ্রিক সারাংশ প্রদান করুন, যার মধ্যে মোট কাজের দিন, মোট কাজের ঘণ্টা এবং অতিরিক্ত সময় অন্তর্ভুক্ত।',
            },
            {
                header: 'বিভিন্ন শাখার রিপোর্ট',
                paragraph:
                    'অনুকূলকরণযোগ্য ক্যাটাগরির সঙ্গে দূর থেকে বিভিন্ন শাখার উপস্থিতি রিপোর্টে প্রবেশাধিকার লাভ করুন।',
            },
            {
                header: 'অতিরিক্ত সময় এবং ছুটির ব্যালান্স',
                paragraph:
                    'প্রতিটি কর্মচারীর জন্য ছুটির ব্যালান্স প্রদর্শন করুন, যার মধ্যে ছুটির দিন, অসুস্থতার ছুটি এবং অন্যান্য ধরনের ছুটি অন্তর্ভুক্ত।',
            },
            {
                header: 'স্বনির্ধারিত উপস্থিতির রিপোর্ট',
                paragraph: 'ব্যবহারকারীদের বিভাগ, টিম বা ব্যক্তিগত কর্মচারী অনুযায়ী রিপোর্ট কাস্টমাইজ করার সুযোগ দিন।',
            },
        ],
        softwareDetails: [
            {
                title: 'আপনার সংগঠনের কাঠামোর ভিত্তিতে রিপোর্ট তৈরি করুন।',
                description: [
                    'নিয়মিতভাবে তৈরি এবং বিশ্লেষণ করা উপস্থিতির রিপোর্টগুলি কর্মী ব্যবস্থাপনা উন্নত করার জন্য মূল্যবান অন্তর্দৃষ্টি প্রদান করতে পারে, প্রবণতা চিহ্নিত করতে পারে এবং সংগঠনের নীতির সঙ্গে সামঞ্জস্য নিশ্চিত করতে সাহায্য করতে পারে।',
                    'রিপোর্টের বিস্তারিতগুলি সংস্থার প্রয়োজন এবং কাঠামোর উপর নির্ভর করবে।',
                ],
            },
            {
                title: 'সহজে কাস্টমাইজযোগ্য রিপোর্ট পান।',
                description: [
                    'অননুমোদিত অনুপস্থিতি, অতিরিক্ত দেরি, বা অনুমোদিত অতিরিক্ত সময়ের উদাহরণ যেমন উপস্থিতির ব্যতিক্রম বা অস্বাভাবিকতা চিহ্নিত করুন এবং হাইলাইট করুন।',
                    'উপস্থিতির প্রবণতা এবং প্যাটার্নগুলি দৃশ্যমানভাবে উপস্থাপন করতে চার্ট বা গ্রাফ ব্যবহার করুন। এটি সহজেই অস্বাভাবিকতা এবং প্রবণতাগুলি চিহ্নিত করতে সহায়ক হতে পারে।',
                ],
            },
            {
                title: 'ম্যানুয়াল রিপোর্টে সময় কমান।',
                description: [
                    'কর্মচারীর নাম, পরিচয় নম্বর এবং বিভাগীয় সম্পর্কিত মৌলিক বিশদগুলি অন্তর্ভুক্ত করুন যাতে উপস্থিতি ডেটার প্রেক্ষাপট প্রদান করা যায়।',
                    'রিপোর্ট দ্বারা কভার করা তারিখ এবং সময়ের পরিসর স্পষ্টভাবে নির্ধারণ করা। এটি ব্যবহারকারীদের ডেটার কালগত পরিধি বুঝতে সাহায্য করবে।',
                    'মোট কাজের দিন, মোট ঘণ্টা কাজ, এবং অতিরিক্ত সময় সহ উপস্থিতির সামগ্রিক সারাংশ প্রদান করুন।',
                ],
            },
        ],
    },
    taskManagement: {
        featureHeader: 'টাস্ক ম্যানেজমেন্ট',
        featureDescription: 'কর্মচারীদের কার্যক্রম, উৎপাদনশীলতা এবং উপস্থিতির সুশৃঙ্খল পর্যবেক্ষণ এবং পরিচালনা।',
        featureParagraph:
            'আরও সময়কার্ড, অতিরিক্ত কাগজপত্র এবং বিভিন্ন নথি প্রয়োজন নেই কর্মচারীদের উপস্থিতি পরীক্ষা করতে। WebWork টাইম ট্র্যাকার দিয়ে উপস্থিতির পর্যবেক্ষণ প্রক্রিয়া স্বয়ংক্রিয় করুন এবং আপনার কর্মচারীদের কাজের সময় শুরু এবং শেষ একটি সুসংগঠিত ফরম্যাটে দেখুন।',
        featureCaption:
            'একটি সংস্থার কর্মশক্তির বিভিন্ন দিক মনিটর এবং পরিচালনা করুন, যার মধ্যে কর্মচারী উপস্থিতি, কাজের সময়, উৎপাদনশীলতা এবং পারফরম্যান্স অন্তর্ভুক্ত।',
        keyPoints: [
            {
                header: 'বাস্তব সময়ে মনিটর করুন',
                paragraph:
                    'টাস্ক বোর্ডগুলি দেখার মাধ্যমে, টাস্ক স্ট্যাটাস চেক করার মাধ্যমে এবং সময় ব্যবহারের ট্র্যাকিং করার মাধ্যমে অগ্রগতি বাস্তব সময়ে মনিটর করুন।',
            },
            {
                header: 'সমস্ত কর্মপ্রবাহ এক জায়গায় মনিটর করুন',
                paragraph:
                    'টাস্ক ম্যানেজমেন্ট সহযোগিতা বৈশিষ্ট্যগুলি দলের সদস্যদের সাথে বাস্তব সময়ে যোগাযোগ করতে। টাস্কে মন্তব্য করুন, প্রশ্ন করুন এবং আপডেট প্রদান করুন যাতে সবার সাথে জানানো থাকে।',
            },
            {
                header: 'পারফরম্যান্স মনিটর করুন',
                paragraph:
                    'কাস্টমার এবং লিড ম্যানেজমেন্টের মাধ্যমে উৎপাদনশীলতা ট্র্যাক করুন। কর্মচারীদের উৎপাদনশীলতা এবং মূল পারফরম্যান্স সূচকগুলি কর্মচারীদের দ্বারা সম্পন্ন কাজের পরিমাণের ভিত্তিতে পরিমাপ করা যেতে পারে।',
            },
            {
                header: 'লক্ষ্য নির্ধারণের মানকরণ করুন',
                paragraph:
                    'লক্ষ্য সংবিধানের জন্য একটি উচ্চতর কাঠামো: প্রকল্প লক্ষ্য, বিভাগীয় উদ্দেশ্য, ব্যক্তিগত কর্মক্ষমতা লক্ষ্য, কৌশলগত উদ্যোগ ইত্যাদি।',
            },
        ],
        softwareDetails: [
            {
                title: 'কর্মঘণ্টা মনিটর করতে সময় ট্র্যাকিং সিস্টেম প্রয়োগ করুন।',
                description: [
                    'কর্মচারীরা টাস্ক এবং প্রকল্পগুলিতে কত সময় ব্যয় করছেন তা মনিটর করতে সময় ট্র্যাকিং সমাধানগুলি প্রয়োগ করুন।',
                    'এটি সম্পদ ব্যবহার বোঝাতে এবং সময় ব্যবস্থাপনা উন্নত করতে সহায়ক হতে পারে।',
                    'প্রকল্প ব্যবস্থাপনা সিস্টেমগুলি ব্যবহার করুন প্রকল্পের অগ্রগতি ট্র্যাক করতে, টাস্ক বরাদ্দ করতে এবং ডেডলাইন মনিটর করতে।',
                    'এটি প্রকল্পের কর্মপ্রবাহ অপটিমাইজ করতে এবং সময়মতো সম্পন্ন করতে সহায়ক।',
                ],
            },
            {
                title: 'প্রাসঙ্গিক মূল পারফরম্যান্স সূচকগুলি মনিটর করুন।',
                description: [
                    'আপনার সংস্থার লক্ষ্যগুলির জন্য প্রাসঙ্গিক মূল পারফরম্যান্স সূচকগুলি সংজ্ঞায়িত এবং ট্র্যাক করুন।',
                    'এটি বিক্রয় লক্ষ্য, গ্রাহক সন্তুষ্টি মেট্রিকস, বা অন্যান্য পারফরম্যান্স সূচকগুলি অন্তর্ভুক্ত করতে পারে।',
                ],
            },
            {
                title: 'আপনার কর্মশক্তির প্রবণতাগুলির রিপোর্ট তৈরি করুন।',
                description: [
                    'কর্মশক্তির প্রবণতা, পারফরম্যান্স এবং উন্নতির ক্ষেত্রগুলির অন্তর্দৃষ্টি লাভের জন্য বিশ্লেষণ এবং রিপোর্টিং বৈশিষ্ট্যগুলি সংহত করুন।',
                    'কাস্টমাইজড রিপোর্টগুলি সিদ্ধান্ত গ্রহণে সহায়ক হতে পারে।',
                ],
            },
        ],
    },
    salesPipelineMonitoring: {
        featureHeader: 'বিক্রয় পাইপলাইন মনিটরিং',
        featureDescription:
            'বিক্রয় দলের ক্ষেত্রের মূল মেট্রিকগুলি ট্র্যাক এবং বিশ্লেষণ করুন, যেমন বিক্রয় রাজস্ব, গ্রাহক ইন্টারঅ্যাকশন এবং ভৌগলিক প্রবণতাগুলি।',
        featureParagraph:
            'আরও সময়কার্ড, অতিরিক্ত কাগজপত্র এবং বিভিন্ন নথি প্রয়োজন নেই কর্মচারীদের উপস্থিতি পরীক্ষা করতে। WebWork টাইম ট্র্যাকার দিয়ে উপস্থিতির পর্যবেক্ষণ প্রক্রিয়া স্বয়ংক্রিয় করুন এবং আপনার কর্মচারীদের কাজের সময় শুরু এবং শেষ একটি সুসংগঠিত ফরম্যাটে দেখুন।',
        featureCaption:
            'ক্ষেত্রের বিক্রয় কার্যক্রমের সাথে সম্পর্কিত ডেটার সংগ্রহ, বিশ্লেষণ এবং ব্যাখ্যা বিশ্লেষণ করুন, যাতে অন্তর্দৃষ্টি অর্জন করতে এবং সঠিক সিদ্ধান্ত নিতে সহায়তা করে।',
        keyPoints: [
            {
                header: 'স্বয়ংক্রিয় কাজের প্রবাহ',
                paragraph:
                    'বিক্রয় প্রক্রিয়ার বিভিন্ন স্তর যেমন লিড ক্যাপচার, যোগ্যতা যাচাই, পরিচর্যা, পাইপলাইন ম্যানেজমেন্ট, কার্যকলাপ ট্র্যাকিং এবং রিপোর্টিংকে স্ট্রিমলাইন এবং অপ্টিমাইজ করতে সিস্টেম ব্যবহার করুন।',
            },
            {
                header: 'CRM এর সাথে ইন্টিগ্রেশন',
                paragraph:
                    'লিড ম্যানেজমেন্ট, যোগাযোগ ট্র্যাকিং, চুক্তি মনিটরিং, এবং দুই প্ল্যাটফর্মের মধ্যে ডেটা সিঙ্ক্রোনাইজেশন স্ট্রিমলাইন করুন।',
            },
            {
                header: 'সহযোগিতা এবং যোগাযোগকে সহজতর করুন',
                paragraph:
                    'কেন্দ্রীভূত যোগাযোগ চ্যানেল, বিক্রয় মিটিং সময়সূচী, সহযোগী চুক্তি পর্যালোচনা, প্রক্রিয়া ডকুমেন্টিং, ক্রস-ফাংশনাল সহযোগিতা, ওপেন কমিউনিকেশন প্রচার করুন।',
            },
            {
                header: 'ভবিষ্যতের বিক্রয় পারফরম্যান্সে অন্তর্দৃষ্টি',
                paragraph:
                    'পটেনশিয়াল আউটকাম এবং প্রবণতা ভবিষ্যদ্বাণী করতে ডেটা বিশ্লেষণ এবং প্রেডিকটিভ মডেলিং ব্যবহার করুন।',
            },
        ],
        softwareDetails: [
            {
                title: 'প্রতিদিনের ক্ষেত্রের অন্তর্দৃষ্টি উন্নত বিক্রয় পারফরম্যান্সের জন্য',
                description: [
                    'ডেটা বিশ্লেষণমুখী চলমান ই-রিপোর্ট জেনারেশন। সহজলভ্য এবং ব্যবহারকারী-বান্ধব রিপোর্ট বৈশিষ্ট্য আপনার সহজ অ্যাক্সেস এবং ব্যবহারের জন্য।',
                    'একজন বিক্রয় ব্যবস্থাপক হিসেবে, আপনি বিভিন্ন রিপোর্টের স্পষ্ট দৃষ্টিভঙ্গি পাবেন যা আপনার মেইল ঠিকানায় পাঠানো হবে অথবা আপনি আপনার ড্যাশবোর্ড অন্তর্দৃষ্টিতে এটি দেখতে পারবেন।',
                    'মাসিক রিপোর্ট, শাখার অবস্থান অনুযায়ী রিপোর্ট, কর্মচারী-বিশেষ রিপোর্ট ইত্যাদি কিছু উল্লেখযোগ্য রিপোর্ট।',
                ],
            },
            {
                title: 'কর্মক্ষেত্রের উপস্থিতি সম্পর্কে মোট নজর',
                description: [
                    'কর্মচারী ক্ষেত্রের উপস্থিতি এবং ক্ষেত্রের সফর সম্পর্কে মোট নজর।',
                    'কর্মক্ষেত্রের কর্মশক্তির উপস্থিতি এবং মিটিংগুলির ব্যাপক দৃষ্টিভঙ্গি পান।',
                    'ক্ষেত্রের উপস্থিতি রিপোর্টে, আপনি ক্ষেত্রের উপস্থিতি চিহ্নিত করার সময়, অবস্থান, দেরিতে উপস্থিতি সময়, কাজের ঘণ্টা ইত্যাদি সম্পর্কে তথ্য পাবেন।',
                    'একইভাবে, ক্ষেত্রের সফরের চেক-ইন/চেক-আউট রিপোর্টগুলিতে ক্ষেত্রের সফরের সাথে সম্পর্কিত ডেটা অন্তর্ভুক্ত থাকে যেমন লগইন/লগআউট সময়, চেক-ইন/চেক-আউট সময়, মিটিংয়ের অবস্থান, সফরের দূরত্ব, মিটিং ফলাফল নোটস ইত্যাদি।',
                ],
            },
            {
                title: 'ডেটা বিশ্লেষণমুখী রিপোর্ট তৈরি',
                description: [
                    'মাসিক ই-রিপোর্ট সাবস্ক্রাইব এবং ডাউনলোড করুন।',
                    'আপনি চাইলে যে কোনো সময় মাসিক ই-রিপোর্ট সাবস্ক্রাইব এবং ডাউনলোড করতে পারেন।',
                    'PDF এবং Excel ফাইল ফর্ম্যাটে উপলভ্য। একটি সম্পূর্ণ রিপোর্টের পাইল আপনার জন্য সংরক্ষিত যাতে কর্মচারীর বাইরের কার্যক্রম সম্পর্কে বিস্তারিত তথ্য পাওয়া যায়, যা ম্যানুয়ালি করা একটি কঠিন কাজ।',
                ],
            },
        ],
    },
    workflowAutomation: {
        featureHeader: 'ওয়ার্কফ্লো অটোমেশন',
        featureDescription:
            'জিও ফেন্স ভিত্তিক উপস্থিতি নিশ্চিত করে যে কর্মচারীরা শুধুমাত্র পূর্বনির্ধারিত ভৌগলিক সীমানার মধ্যে থাকলে ক্লক ইন বা আউট করতে পারে।',
        featureCaption:
            'অবস্থান ভিত্তিক উপস্থিতি নিশ্চিত করে কর্মচারীদের উপস্থিতি মনিটর এবং রেকর্ড করার নির্ভুলতা তাদের শারীরিক অবস্থান অনুযায়ী।',
        keyPoints: [
            {
                header: 'স্বয়ংক্রিয় কাজের নিয়োগ',
                paragraph:
                    'ওয়ার্কফ্লো অটোমেশন স্বয়ংক্রিয়ভাবে সবচেয়ে উপযুক্ত ব্যক্তিদের কাছে কাজগুলি নিয়োগ করতে সহায়তা করে, দক্ষতা সেট, উপলভ্যতা, কর্মভার এবং অগ্রাধিকারের স্তরগুলির মতো উপাদানগুলি বিবেচনায় রেখে।',
            },
            {
                header: 'লিড রাউটিং, যোগ্যতা এবং স্কোরিং',
                paragraph:
                    'লিড রাউটিং, যোগ্যতা, স্কোরিং Incoming লিডগুলি উপযুক্ত বিক্রয় প্রতিনিধিদের কাছে নিয়োগ করতে, তাদের গুণমান মূল্যায়ন করতে এবং ফলো-আপ কর্মসূচী অগ্রাধিকার দেওয়ার জন্য।',
            },
            {
                header: 'বাহ্যিক সিস্টেমের সাথে ইন্টিগ্রেশন',
                paragraph:
                    'সুবিধাজনক সহযোগিতা সহজতর করুন, ডেটার সঠিকতা উন্নত করুন, এবং বিভিন্ন ব্যবসায়িক কার্যক্রমে একটি সংহত ওয়ার্কফ্লো নিশ্চিত করুন।',
            },
            {
                header: 'পারফরম্যান্স ট্র্যাকিং এবং রিপোর্টিং',
                paragraph:
                    'প্রক্রিয়ার সম্পCompletion সময়, ত্রুটি হার, সম্পদ ব্যবহারের হার, এবং সামগ্রিক উত্পাদনশীলতা সহ মেট্রিকগুলি ট্র্যাক করুন।',
            },
        ],
        softwareDetails: [
            {
                title: 'কর্মশক্তির সময় এবং অবস্থান।',
                description: [
                    'আপনার কর্মচারীরা কখন এবং কোথায় কাজ করে তা জানুন।',
                    'আপনার কর্মচারীদের তাদের মোবাইল ফোনে অনুমতি দেওয়ার জন্য নির্দেশ দিন যাতে এটি সময় এবং অবস্থান ট্র্যাক করতে পারে।',
                    'একবার অনুমতি granted দেওয়া হলে, ট্র্যাকার একটি ট্যাপ দিয়ে কাজ করতে শুরু করতে পারে।',
                ],
            },
            {
                title: 'লাইভ রিপোর্টিং দুই',
                description: [
                    'অবস্থান ভিত্তিক কর্মচারী উপস্থিতি সিস্টেম বাস্তবায়ন নির্ভুলতা উন্নত করতে এবং উপস্থিতি ব্যবস্থাপনাকে সহজতর করতে সহায়ক হতে পারে। তবে, এটি কর্মচারীদের গোপনীয়তার বিবেচনাগুলির সাথে উপকারিতা ভারসাম্য করা গুরুত্বপূর্ণ এবং এই ধরনের সিস্টেমের বাস্তবায়ন এবং ব্যবহারের বিষয়ে স্বচ্ছভাবে যোগাযোগ করা আবশ্যক।',
                ],
            },
            {
                title: 'জিও ফেন্স ভিত্তিক সতর্কতা',
                description: [
                    'অস্বাভাবিক উপস্থিতি ধরণ বা অমিলগুলির জন্য স্বয়ংক্রিয় সতর্কতা সেট আপ করুন। এটি অপ্রমাণিত চেক-ইন বা চেক-আউটের মতো সম্ভাব্য সমস্যা চিহ্নিত করতে সহায়ক।',
                    'উপস্থিতি ধরণ, প্রবণতা এবং ব্যতিক্রমগুলির উপর অন্তর্দৃষ্টি অর্জন করতে রিপোর্টিং এবং বিশ্লেষণ বৈশিষ্ট্য বাস্তবায়ন করুন।',
                ],
            },
        ],
    },
    aIBasedAssistance: {
        featureHeader: 'এআই ভিত্তিক সহায়তা',
        featureDescription:
            'কার্যক্রমের বিভিন্ন দিক, যেমন উপস্থিতি, সম্পন্ন কাজ বা প্রকল্পের অগ্রগতি, বাস্তব-সময়ের ট্র্যাকিং এবং বিশ্লেষণ।',
        featureCaption: 'কর্মচারীদের কাজ, সভার মিনিট, ভিজিটগুলি যাচাই করুন এবং ডেটার উপর ভিত্তি করে সিদ্ধান্ত নিন।',
        keyPoints: [
            {
                header: 'ইভেন্ট ভিত্তিক নোটিফিকেশন',
                paragraph:
                    'অ্যাডমিন বা ম্যানেজার যে নোটিফিকেশনগুলি পেতে চান তাতে সাবস্ক্রাইব বা অপ্ট করতে পারেন। এটি তাদের জন্য আসলেই প্রয়োজনীয় নোটিফিকেশনগুলিতে মনোনিবেশ করতে সহায়ক হবে।',
            },
            {
                header: 'উপস্থিতি এবং ছুটি সম্পর্কে অবগত থাকুন',
                paragraph:
                    'উপস্থিতি এবং ছুটি সম্পর্কে অবগত থাকুন। আগে থেকেই কর্মশক্তির সংখ্যা জানুন যাতে দিনের পরিকল্পনা আরও কার্যকরী এবং দক্ষ হতে পারে।',
            },
            {
                header: 'সকল অনুমোদনের ট্র্যাক রাখুন',
                paragraph:
                    'সকল অনুমোদনের ট্র্যাক রাখুন। প্রতিস্থাপন থেকে শুরু করে ছুটি পর্যন্ত সমস্ত অনুমোদন ট্র্যাক করুন এবং পরে তাদের দেখুন।',
            },
            {
                header: 'ফর্ম এবং মোমের সাথে আপডেট থাকুন',
                paragraph:
                    'ফর্ম এবং সভার মিনিটের মাধ্যমে দূরবর্তী ট্র্যাকিং। কাস্টম ফর্মগুলি বৈঠকের জন্য নির্দিষ্ট ডেটা সংগ্রহ করতে সহায়ক হতে পারে যা দূরবর্তীভাবে অ্যাক্সেসযোগ্য।',
            },
        ],
        softwareDetails: [
            {
                title: 'বাস্তব সময়ে ফিল্ড ফোর্স অবস্থান চিহ্নিত করুন।',
                description: [
                    'আপনার মাঠ কর্মীদের বাস্তব সময়ে চিহ্নিত করুন।',
                    'আপনার মাঠ কর্মীদের চলাচলের সাথে সাথে তাত্ক্ষণিক অবস্থান তথ্য পান।',
                    'ট্রেন্ট আপনাকে আপনার মাঠ কর্মীদের তাদের মোবাইল GPS ব্যবহার করে অনলাইন এবং অফলাইনে চিহ্নিত করতে সক্ষম করে। আপনার মাঠ কর্মীদের প্রতিটি পদক্ষেপে আপডেট থাকুন এবং একটি স্বচ্ছ কাজের পরিবেশ বজায় রাখুন।',
                ],
            },
            {
                title: 'আপনার কর্মচারীর মাঠের তথ্যের সাথে আপডেট থাকুন',
                description: [
                    'আপনার কর্মচারীদের মাঠের তথ্যের সাথে আপডেট থাকুন।',
                    'মাঠ কর্মীদের মাঠের তথ্য স্বয়ংক্রিয়ভাবে আপনার ড্যাশবোর্ডের সাথে সিঙ্ক হয়ে যাবে।',
                    'আপনার কর্মচারীদের বিস্তারিত যেমন ব্যাটারি শতাংশ, GPS, ইন্টারনেট চালু/বন্ধ সময়ের দৃশ্যমানতা, এবং তারা মাঠে যে সময় ব্যয় করে তা বাস্তব সময়ে আপনাকে জানানো হবে।',
                ],
            },
            {
                title: 'কর্মচারীদের প্রতিস্থাপন এবং ভাতার তথ্য স্বয়ংক্রিয় করুন।',
                description: [
                    'সঠিক ডেটার জন্য এআই চালিত অবস্থান ইতিহাস',
                    'আপনার কর্মচারীদের অবস্থান ডেটা কৃত্রিম বুদ্ধিমত্তা দ্বারা চালিত হবে যাতে সঠিক রুট এবং ট্র্যাকিং তথ্য সরবরাহ করা হয়। লাইভ ট্র্যাকিং ডেটা এবং অন্যান্য অবস্থান ইতিহাসগুলি নিয়মিত ব্যবধানে আপনার মেইলবক্সে স্বয়ংক্রিয়ভাবে প্রেরিত হবে যাতে মাঠে এবং বাইরে যা ঘটছে তার গভীর বিশ্লেষণ করা যায়।',
                ],
            },
        ],
    },
    digitizationOfOrder: {
        featureHeader: 'অর্ডারের ডিজিটাইজেশন',
        featureDescription:
            'বাস্তব-সময়ের ট্র্যাকিং এবং কাজের বিভিন্ন দিক যেমন উপস্থিতি, সম্পন্ন কাজ বা প্রকল্পের অগ্রগতি বিশ্লেষণ।',
        featureCaption: 'অর্ডার ব্যবস্থাপনাকে সম্পূর্ণভাবে স্বয়ংক্রিয় করুন এবং ম্যানুয়াল ত্রুটিগুলি কমান।',
        keyPoints: [
            {
                header: 'রিপোর্টিং এবং বিশ্লেষণ',
                paragraph:
                    'অর্ডার প্রক্রিয়াকরণের পারফরম্যান্স এবং কর্মচারীদের উৎপাদনশীলতা মনিটর করার জন্য বিস্তারিত রিপোর্ট এবং বিশ্লেষণ তৈরি করুন।',
            },
            {
                header: 'স্বয়ংক্রিয় অর্ডার এন্ট্রি',
                paragraph:
                    'কর্মচারী বা গ্রাহক দ্বারা প্রবেশিত অর্ডার বিস্তারিত স্বয়ংক্রিয়ভাবে ক্যাপচার এবং যাচাই করুন।',
            },
            {
                header: 'বাস্তব-সময়ের অর্ডার ট্র্যাকিং এবং স্ট্যাটাস আপডেট',
                paragraph:
                    'অর্ডারের স্ট্যাটাস এবং শিপমেন্ট ট্র্যাকিং নিয়ে কর্মচারী এবং গ্রাহকদের বাস্তব-সময়ের আপডেট প্রদান করুন।',
            },
            {
                header: 'স্বয়ংক্রিয় ওয়ার্কফ্লো এবং টাস্ক অ্যাসাইনমেন্ট',
                paragraph: 'অর্ডার অনুমোদন প্রক্রিয়া স্বয়ংক্রিয় করুন এবং উপযুক্ত কর্মচারীদের টাস্ক নির্ধারণ করুন।',
            },
        ],
    },
    targetManagement: {
        featureHeader: 'লক্ষ্য ব্যবস্থাপনা',
        featureDescription:
            'বাস্তব-সময়ের ট্র্যাকিং এবং কাজের বিভিন্ন দিক যেমন উপস্থিতি, সম্পন্ন কাজ বা প্রকল্পের অগ্রগতি বিশ্লেষণ।',
        featureCaption: 'কর্মচারীদের কাজ, মিটিংয়ের মিনিটস, ভিজিট যাচাই করুন এবং ডেটার ভিত্তিতে সিদ্ধান্ত নিন।',
        keyPoints: [
            {
                header: 'ফিল্ড ট্র্যাকিং-এর সাথে একীকরণ',
                paragraph:
                    'দৈনিক, সাপ্তাহিক, এবং মাসিক লক্ষ্যমাত্রা পূরণ করুন এবং লাইভ ফিল্ড ট্র্যাকিংয়ের মাধ্যমে কর্মচারীদের অর্জনের রেকর্ড রাখুন।',
            },
            {
                header: 'বাস্তব সময় আপডেট এবং সিঙ্ক',
                paragraph:
                    'বাস্তব সময়ের অগ্রগতি (লক্ষ্য অর্জিত সংখ্যা বনাম বাকি), চেক-ইন/চেক-আউট সময়, প্রতিটি কাজে ব্যয়িত ঘণ্টার সংখ্যা (স্থায়িত্ব), এবং অবস্থান ট্র্যাকিং।',
            },
            {
                header: 'পারফরম্যান্স এবং বিশ্লেষণ',
                paragraph:
                    'পারফরম্যান্স মেট্রিক্স (সম্পন্ন হারের হার, সময় নেয়া, গ্রাহক সন্তুষ্টি স্কোর), লক্ষ্যমাত্রার বিরুদ্ধে তুলনা (বাস্তব পারফরম্যান্স বনাম লক্ষ্য), ঐতিহাসিক তথ্য (গত পারফরম্যান্স প্রবণতা)।',
            },
            {
                header: 'স্বয়ংক্রিয় বিজ্ঞপ্তি এবং সতর্কতা',
                paragraph:
                    'স্বয়ংক্রিয় বিজ্ঞপ্তি পাঠান যাতে কর্মচারীরা লক্ষ্য অর্জন সতর্কতা, পারফরম্যান্স রিমাইন্ডার, এবং সময়সীমার সতর্কতা সম্পর্কে জানতে পারে।',
            },
        ],
    },
    invoiceManagement: {
        featureHeader: 'চালান ব্যবস্থাপনা',
        featureDescription:
            'বাস্তব-সময়ের ট্র্যাকিং এবং কাজের বিভিন্ন দিক যেমন উপস্থিতি, সম্পন্ন কাজ বা প্রকল্পের অগ্রগতি বিশ্লেষণ।',
        featureCaption: 'স্বয়ংক্রিয় সতর্কতা এবং বিজ্ঞপ্তি সহ চালান তৈরি, ট্র্যাকিং এবং প্রসেসিং করুন।',
        keyPoints: [
            {
                header: 'স্বয়ংক্রিয় বিজ্ঞপ্তি এবং রিমাইন্ডার',
                paragraph:
                    'চালানের অবস্থান ট্র্যাক করতে এবং মেয়াদোত্তীর্ণ ও অনাদায়ী পেমেন্টের জন্য স্বয়ংক্রিয় রিমাইন্ডার এবং সতর্কতা পাঠানোর জন্য সিস্টেম স্থাপন করুন।',
            },
            {
                header: 'চালান টেমপ্লেট',
                paragraph:
                    'স্ট্যান্ডার্ডাইজড চালান টেমপ্লেট যা গ্রাহক তথ্য, অর্ডার বিবরণ, ট্যাক্স, ছাড় এবং পেমেন্ট শর্তাবলী সহ সমস্ত প্রয়োজনীয় বিবরণ অন্তর্ভুক্ত করে।',
            },
            {
                header: 'ডিজিটাল সই এবং ডেলিভারি প্রমাণ',
                paragraph: 'চালান প্রক্রিয়ার অংশ হিসেবে ডিজিটাল সই এবং OTP-ভিত্তিক ডেলিভারি প্রমাণ সংগ্রহ করুন।',
            },
            {
                header: 'চালান সিঙ্ক এবং বাস্তব সময় আপডেট',
                paragraph:
                    'কেন্দ্রীয় সিস্টেম (ক্লাউড-ভিত্তিক স্টোরেজ) এর সাথে স্বয়ংক্রিয় সিঙ্ক এবং চালানের অবস্থার উপর বাস্তব সময়ের আপডেট।',
            },
        ],
    },
    distributorOrder: {
        featureHeader: 'বিতরণকারী অর্ডার',
        featureDescription:
            'বাস্তব-সময়ের ট্র্যাকিং এবং কাজের বিভিন্ন দিক যেমন উপস্থিতি, সম্পন্ন কাজ বা প্রকল্পের অগ্রগতি বিশ্লেষণ।',
        featureCaption: 'কর্মীদের কাজ, সভার মিনিট, ভিজিট যাচাই এবং তথ্যের ভিত্তিতে সিদ্ধান্ত গ্রহণের জন্য অবহিত থাকুন।',
        keyPoints: [
            {
                header: 'বিতরণ তথ্য',
                paragraph:
                    'প্রত্যেক বিতরণের জন্য সমস্ত প্রাসঙ্গিক বিবরণ সহ অর্ডার তৈরি এবং প্রক্রিয়া করুন এবং প্রাপ্যতা ভিত্তিতে কাজ বরাদ্দ করুন।',
            },
            {
                header: 'বাস্তব সময়ের ইনভেন্টরি ইন্টিগ্রেশন',
                paragraph:
                    'নির্ভুল স্টক বরাদ্দ নিশ্চিত করতে এবং বাস্তব সময়ে ইনভেন্টরি স্তর আপডেট করতে ইনভেন্টরি ব্যবস্থাপনার সাথে সংযুক্ত করুন।',
            },
            {
                header: 'স্বয়ংক্রিয় বিজ্ঞপ্তি এবং সতর্কতা',
                paragraph:
                    'অর্ডার প্রক্রিয়ার গুরুত্বপূর্ণ পর্যায়ে স্বয়ংক্রিয় বিজ্ঞপ্তি পাঠানোর জন্য সিস্টেম কনফিগার করুন।',
            },
            {
                header: 'কর্মচারী ড্যাশবোর্ড',
                paragraph:
                    'একটি ড্যাশবোর্ডে অ্যাক্সেস করুন যেখানে কর্মচারীরা অর্ডার চেক করতে, অগ্রগতি ট্র্যাক করতে এবং প্রয়োজনীয় ডকুমেন্টগুলি ডাউনলোড করতে পারে।',
            },
        ],
    },
    inventoryManagement: {
        featureHeader: 'ইনভেন্টরি ব্যবস্থাপনা',
        featureDescription:
            'বাস্তব-সময়ের ট্র্যাকিং এবং কাজের বিভিন্ন দিক যেমন উপস্থিতি, সম্পন্ন কাজ বা প্রকল্পের অগ্রগতি বিশ্লেষণ।',
        featureCaption: 'সঠিক স্টক ব্যালেন্স, বাস্তব সময়ের আপডেট এবং সঠিক তথ্যের জন্য ইনভেন্টরি ট্র্যাকিং',
        keyPoints: [
            {
                header: 'অর্ডার ব্যবস্থাপনার সাথে ইন্টিগ্রেশন',
                paragraph:
                    'স্টক স্তরগুলিকে আসা এবং যাওয়া অর্ডারের সাথে সিঙ্ক্রোনাইজ করার জন্য ইনভেন্টরি এবং অর্ডার ব্যবস্থাপনা সিস্টেমের মধ্যে সুনির্দিষ্ট ইন্টিগ্রেশন নিশ্চিত করুন।',
            },
            {
                header: 'বহু-স্থান ব্যবস্থাপনা',
                paragraph:
                    'বিভিন্ন গুদাম, দোকান বা অবস্থানের মধ্যে ইনভেন্টরি ট্র্যাক করে কেন্দ্রীভূত নিয়ন্ত্রণের অ্যাক্সেস প্রদান করুন, স্টক বিতরণ অপটিমাইজ করুন এবং দৃশ্যমানতা উন্নত করুন।',
            },
        ],
    },
    orderCollectionManagement: {
        featureHeader: 'অর্ডার/সংগ্রহ ব্যবস্থাপনা',
        featureDescription: 'একটি স্কেলযোগ্য এবং স্বয়ংক্রিয় সমাধানের মাধ্যমে অর্ডার/সংগ্রহ অপারেশনগুলি সহজ করুন।',
        featureCaption: 'একটি স্কেলযোগ্য এবং স্বয়ংক্রিয় সমাধানের মাধ্যমে অর্ডার/সংগ্রহ অপারেশনগুলি সহজ করুন।',
        keyPoints: [
            {
                header: 'একক অর্ডার ব্যবস্থাপনা সিস্টেম',
                paragraph:
                    'একটি কেন্দ্রীভূত সিস্টেম যা অন-সাইট এবং অফ-সাইট উভয় কর্মচারী দ্বারা অ্যাক্সেসযোগ্য, অর্ডার পরিচালনা, অগ্রগতি ট্র্যাক এবং রিয়েল-টাইমে স্ট্যাটাস আপডেট করার জন্য।',
            },
            {
                header: 'ভূমিকা ভিত্তিক অ্যাক্সেস নিয়ন্ত্রণ',
                paragraph:
                    'ডেটা সুরক্ষা এবং যথাযথ অ্যাক্সেস নিশ্চিত করতে ভূমিকার ভিত্তিতে বিভিন্ন স্তরের অ্যাক্সেস এবং অনুমতি (যেমন, ব্যবস্থাপক, অন-সাইট কর্মচারী, অফ-সাইট কর্মচারী) প্রদান।',
            },
            {
                header: 'বাস্তব-সময়ের সিঙ্ক্রোনাইজেশন',
                paragraph:
                    'সর্বশেষ তথ্য পাওয়ার জন্য সমস্ত ডিভাইস এবং অবস্থানে অর্ডার ডেটার বাস্তব-সময়ের সিঙ্ক্রোনাইজেশন নিশ্চিত করুন।',
            },
            {
                header: 'যোগাযোগ এবং সমন্বয়',
                paragraph:
                    'অ্যাপ-ভিত্তিক মেসেজিং এবং নোটিফিকেশনের মাধ্যমে অন-সাইট এবং অফ-সাইট কর্মচারীদের মধ্যে যোগাযোগ সহজ করুন।',
            },
        ],
    },
};

export default features;
