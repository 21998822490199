import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
import Root from './Root';
import PrivateRoute from './PrivateRoute';
// import Admin from 'pages/other/admin/Admin';

// lazy load all the views

// home
const Home = React.lazy(() => import('../pages/Home'));
const Admin = React.lazy(() => import('../pages/other/Admin'));
const Blog = React.lazy(() => import('../pages/other/Blog'));
const BlogPost = React.lazy(() => import('../pages/other/BlogPost'));
// landings
const IndustriesPage = React.lazy(() => import('../pages/landings/industries'));
const ProductsPage = React.lazy(() => import('../pages/landings/products'));
const FeaturesPage = React.lazy(() => import('../pages/landings/features'));
const AboutUs = React.lazy(() => import('../pages/other/AboutUs'));
const JoinUs = React.lazy(() => import('../pages/other/JoinUs'));
const Contact = React.lazy(() => import('../pages/other/Contact'));
const Pricing = React.lazy(() => import('../pages/other/PricingNew'));
const AI = React.lazy(() => import('../pages/other/AI'));
const TermsAndConditions = React.lazy(() => import('../pages/other/tearmsAndConditions'));
const PrivacyPolicies = React.lazy(() => import('../pages/other/privacyPolicy'));
const Faqs = React.lazy(() => import('../pages/other/faqs'));
const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        // {
        //     // root route
        //     path: '/',
        //     element: <Root />,
        // },
        {
            // public routes
            path: '/',
            children: [
                {
                    index: true,
                    element: <LoadComponent component={Home} />,
                },
                {
                    path: '/:deleteparam',
                    element: <LoadComponent component={Home} />,
                },
                {
                    path: 'secretadmintraceforce',
                    element: <LoadComponent component={Admin} />,
                },

                {
                    path: 'landing',
                    children: [{ path: 'features/:featureName', element: <LoadComponent component={FeaturesPage} /> }],
                },
                {
                    path: 'field',
                    children: [
                        { path: 'industries/:industryName', element: <LoadComponent component={IndustriesPage} /> },
                    ],
                },
                {
                    path: 'pages',
                    children: [
                        { path: 'blog', element: <LoadComponent component={Blog} /> },
                        { path: 'blog/:postid', element: <LoadComponent component={BlogPost} /> },
                        { path: 'contact', element: <LoadComponent component={Contact} /> },
                        { path: 'about-us', element: <LoadComponent component={AboutUs} /> },
                        { path: 'join-us', element: <LoadComponent component={JoinUs} /> },
                        { path: 'pricing/:industryName', element: <LoadComponent component={Pricing} /> },
                        { path: 'termsAndConditions', element: <LoadComponent component={TermsAndConditions} /> },
                        { path: 'privacyPolicies', element: <LoadComponent component={PrivacyPolicies} /> },
                        { path: 'products/:productName', element: <LoadComponent component={ProductsPage} /> },
                        { path: 'faqs', element: <LoadComponent component={Faqs} /> },
                        { path: 'ai', element: <LoadComponent component={AI} /> },
                    ],
                },
            ],
        },
        {
            // protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} />,
            children: [
                {
                    path: 'pages',
                    children: [
                        {
                            path: 'account',
                            children: [],
                        },
                    ],
                },
            ],
        },
    ]);
};

export default AllRoutes;
