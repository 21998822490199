const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'خطط مرنة تنمو معك',
    subFlexiblePlansThatGrowsWithYou:
        'اختر خطة التسعير التي تلبّي احتياجات عملك. تقدم Traceforce باقات بأسعار مثالية ولكن بقيمة فائقة.',
    pricingPlans: 'خطط الأسعار',
    pricingThatWorksForEveryOne: 'أسعار تناسب الجميع',
    monthly: 'شهريًا',
    annualy: 'سنويًا',
    international: 'دولي',
    domestic: 'محلي',
    month: 'شهر',
    benifits: 'الفوائد',
    benifitsArr: [
        {
            title: 'الدعم الفني',
            description: 'فريق الدعم الفني المتخصص لدينا سيساعدك في كل خطوة',
        },
        {
            title: 'التكنولوجيا',
            description: 'تدريب خاص للبدء في استخدام المنصة بإشراف متخصصين',
        },
        {
            title: 'تحليل النمو',
            description: 'فريق مخصص لتقديم رؤى حول نموك كل شهر',
        },
        {
            title: 'المكافآت',
            description: 'مكافأة خاصة لأكثر الحسابات أداءً كل شهر',
        },
    ],
    allPlanIncludeTheseBenifits: 'جميع الخطط تشمل هذه الفوائد',
    someBenfitsOfMonthlyYearlySubscription: 'بعض فوائد الاشتراك الشهري/السنوي',
    fAQs: 'الأسئلة المتكررة',
    frequentlyAskedQuestions: 'الأسئلة الشائعة',
    someBaTypeOfQuestions: 'إليك بعض الأنواع الأساسية من الأسئلة لعملائنا',
    fAQArr: [
        {
            question: 'كيف يمكنني تسجيل شركتي؟',
            ans: 'نعم، يسمح لك ترخيص السوق باستخدام هذا القالب في أي منتجات نهائية. لمزيد من المعلومات حول التراخيص، يرجى الرجوع إلى شروط الترخيص على السوق.',
        },
        {
            question: 'أين يمكنني إضافة القوى العاملة والمركبات الخاصة بي؟',
            ans: 'لا، هذا قالب HTML. لن يعمل مباشرة مع WordPress، ولكن يمكنك تحويله إلى قالب متوافق مع WordPress.',
        },
        {
            question: 'كيف تسجل القوى العاملة الخاصة بي في Traceforce؟',
            ans: 'استخدم بريد الدعم المخصص لدينا (support@coderthemes.com) لإرسال مشكلاتك أو ملاحظاتك. نحن هنا للمساعدة في أي وقت.',
        },
        {
            question: 'هل يمكنني تتبع القوى العاملة الخاصة بي عبر الهاتف المحمول؟',
            ans: 'نعم، سنقوم بتحديث Prompt بانتظام. ستكون جميع التحديثات المستقبلية متاحة بدون أي تكلفة.',
        },
        {
            question: 'كيف يمكنني تسجيل الحضور؟',
            ans: 'نعم، يسمح لك ترخيص السوق باستخدام هذا القالب في أي منتجات نهائية. لمزيد من المعلومات حول التراخيص، يرجى الرجوع إلى شروط الترخيص على السوق.',
        },
        {
            question: 'كيف يمكن تعطيل خيارات المطور؟',
            ans: 'لا، هذا قالب HTML. لن يعمل مباشرة مع WordPress، ولكن يمكنك تحويله إلى قالب متوافق مع WordPress.',
        },
        {
            question: 'ما هي الميزات التي يقدمها Traceforce؟',
            ans: 'استخدم بريد الدعم المخصص لدينا (support@coderthemes.com) لإرسال مشكلاتك أو ملاحظاتك. نحن هنا للمساعدة في أي وقت.',
        },
        {
            question: 'هل أحتاج إلى تثبيت برنامج Traceforce؟',
            ans: 'نعم، سنقوم بتحديث Prompt بانتظام. ستكون جميع التحديثات المستقبلية متاحة بدون أي تكلفة.',
        },
        {
            question: 'ما هي عملية تنفيذ برنامج Traceforce؟',
            ans: 'نعم، يسمح لك ترخيص السوق باستخدام هذا القالب في أي منتجات نهائية. لمزيد من المعلومات حول التراخيص، يرجى الرجوع إلى شروط الترخيص على السوق.',
        },
        {
            question: 'ما هي خيارات التدريب والدعم المتاحة مع برنامج Traceforce؟',
            ans: 'لا، هذا قالب HTML. لن يعمل مباشرة مع WordPress، ولكن يمكنك تحويله إلى قالب متوافق مع WordPress.',
        },
        {
            question: 'هل يمكن تخصيص برنامج Traceforce لعمليات الموارد البشرية الخاصة بنا؟',
            ans: 'استخدم بريد الدعم المخصص لدينا (support@coderthemes.com) لإرسال مشكلاتك أو ملاحظاتك. نحن هنا للمساعدة في أي وقت.',
        },
        {
            question: 'كيف يمكن أن يساعد برنامج HRMS في الامتثال وإعداد التقارير؟',
            ans: 'نعم، سنقوم بتحديث Prompt بانتظام. ستكون جميع التحديثات المستقبلية متاحة بدون أي تكلفة.',
        },
        {
            question: 'هل يدعم برنامج HRMS العمل عن بُعد والوصول عبر الهاتف المحمول؟',
            ans: 'نعم، سنقوم بتحديث Prompt بانتظام. ستكون جميع التحديثات المستقبلية متاحة بدون أي تكلفة.',
        },
    ],
};
export default pricingPage;
